import React, { useState, useEffect } from "react";
import PagingTable from "../../components/Table/PagingTable";
import Permissions from "../../components/Security/Permissions";

import { v4 as uuidv4 } from "uuid";
/**
 * The page that allows an Admin to manage their environment. The Admin can give access to things throughout the entire environment.
 * @param {}
 * @constructor
 */
const EnvironmentPage = ({ _environment }) => {
	//state variables
	let [environment, setEnvironment] = useState(
		_environment || {
			roles: [{ title: "Admin" }, { title: "Developer" }, { title: "User" }],
			permissions: ["Read", "Write"],
		}
	);

	//Other Methods
	const attributes = [
		{ title: "Title", value: "title" },
		{
			title: "Admin",
			value: (
				<Permissions
					permissions={environment.permissions.map((permission) => {
						permission = { uuid: uuidv4(), title: permission, allowed: Math.random() < 0.5 };
						return permission;
					})}
				/>
			),
		},
		{
			title: "Developer",
			value: (
				<Permissions
					permissions={environment.permissions.map((permission) => {
						permission = { uuid: uuidv4(), title: permission, allowed: Math.random() < 0.5 };
						return permission;
					})}
				/>
			),
		},
		{
			title: "User",
			value: (
				<Permissions
					permissions={environment.permissions.map((permission) => {
						permission = { uuid: uuidv4(), title: permission, allowed: Math.random() < 0.5 };
						return permission;
					})}
				/>
			),
		},
	];

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		if (environment.roles) {
			environment.roles.forEach((role) => {
				attributes.push({
					title: role.title,
					value: (
						<Permissions
							permissions={environment.permissions.map((permission) => {
								permission = { uuid: uuidv4(), title: permission, allowed: Math.random() < 0.5 };
								return permission;
							})}
						/>
					),
				});
			});
		}
	}, [environment]);

	useEffect(() => {
		document.title = "Environment";
	});

	return (
		<div className="container-fluid">
			<h3 className="text-dark mb-4">Environment</h3>
			<div className="row mb-3">
				<div className="col-lg-8">
					<div className="card shadow mb-3">
						<div className="card-header py-3">
							<p className="text-primary m-0 fw-bold">Settings</p>
						</div>
						<div className="card-body">
							<form>
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label className="form-label" htmlFor="first_name">
												<strong>Name</strong>
											</label>
											<input
												className="form-control"
												type="text"
												id="name"
												placeholder="Environment"
												name="name"
											/>
										</div>
									</div>
									<div className="col">
										<div className="mb-3">
											<label className="form-label" htmlFor="setup_date">
												<strong>Setup Date</strong>
											</label>
											<input
												className="form-control"
												type="text"
												id="setup_date"
												placeholder="12/05/2021"
												name="setup_date"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label className="form-label" htmlFor="email">
												<strong>Status</strong>
											</label>
											<select className="form-select">
												<optgroup label="Status">
													<option value="active" selected="">
														Active
													</option>
													<option value="terminated">Terminated</option>
												</optgroup>
											</select>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="mt-2 mb-2">
											<button className="btn btn-success btn-sm form-control" type="submit">
												Save Environment Settings
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<PagingTable title={"Matrix"} attributes={attributes} rows={environmentItems} />
		</div>
	);
};

export default EnvironmentPage;

const environmentItems = [
	{
		id: 1,
		title: "Eurovan",
	},
	{
		id: 2,
		title: "Jetta",
	},
	{
		id: 3,
		title: "Rally Wagon 3500",
	},
	{
		id: 4,
		title: "Skylark",
	},
	{
		id: 5,
		title: "9-3",
	},
	{
		id: 6,
		title: "Murciélago",
	},
	{
		id: 7,
		title: "Sierra 2500",
	},
	{
		id: 8,
		title: "Trooper",
	},
	{
		id: 9,
		title: "S10",
	},
	{
		id: 10,
		title: "Ram Van 3500",
	},
	{
		id: 11,
		title: "Grand Am",
	},
	{
		id: 12,
		title: "Phantom",
	},
	{
		id: 13,
		title: "DB9",
	},
	{
		id: 14,
		title: "Flex",
	},
	{
		id: 15,
		title: "DBS",
	},
	{
		id: 16,
		title: "STS-V",
	},
	{
		id: 17,
		title: "Malibu",
	},
	{
		id: 18,
		title: "Tacoma",
	},
	{
		id: 19,
		title: "Expedition",
	},
	{
		id: 20,
		title: "Caliber",
	},
	{
		id: 21,
		title: "Econoline E350",
	},
	{
		id: 22,
		title: "Aerio",
	},
	{
		id: 23,
		title: "Express 2500",
	},
	{
		id: 24,
		title: "1500",
	},
	{
		id: 25,
		title: "RDX",
	},
	{
		id: 26,
		title: "Bonneville",
	},
	{
		id: 27,
		title: "CTS-V",
	},
	{
		id: 28,
		title: "240SX",
	},
	{
		id: 29,
		title: "Galant",
	},
	{
		id: 30,
		title: "Escalade",
	},
	{
		id: 31,
		title: "Grand Marquis",
	},
	{
		id: 32,
		title: "Yukon XL 1500",
	},
	{
		id: 33,
		title: "M3",
	},
	{
		id: 34,
		title: "MX-5",
	},
	{
		id: 35,
		title: "S-Class",
	},
	{
		id: 36,
		title: "500SEL",
	},
	{
		id: 37,
		title: "900",
	},
	{
		id: 38,
		title: "Ram 1500",
	},
	{
		id: 39,
		title: "Town Car",
	},
	{
		id: 40,
		title: "Avenger",
	},
	{
		id: 41,
		title: "Type 2",
	},
	{
		id: 42,
		title: "Sable",
	},
	{
		id: 43,
		title: "Tundra",
	},
	{
		id: 44,
		title: "300M",
	},
	{
		id: 45,
		title: "Monterey",
	},
	{
		id: 46,
		title: "Ram 3500",
	},
	{
		id: 47,
		title: "3500",
	},
	{
		id: 48,
		title: "5000S",
	},
	{
		id: 49,
		title: "Continental",
	},
	{
		id: 50,
		title: "Tercel",
	},
	{
		id: 51,
		title: "FX",
	},
	{
		id: 52,
		title: "Summit",
	},
	{
		id: 53,
		title: "9-7X",
	},
	{
		id: 54,
		title: "RAV4",
	},
	{
		id: 55,
		title: "LR3",
	},
	{
		id: 56,
		title: "Cavalier",
	},
	{
		id: 57,
		title: "Cressida",
	},
	{
		id: 58,
		title: "Econoline E150",
	},
	{
		id: 59,
		title: "E150",
	},
	{
		id: 60,
		title: "Versa",
	},
	{
		id: 61,
		title: "Frontier",
	},
	{
		id: 62,
		title: "Quattroporte",
	},
	{
		id: 63,
		title: "Shadow",
	},
	{
		id: 64,
		title: "Taurus",
	},
	{
		id: 65,
		title: "928",
	},
	{
		id: 66,
		title: "Legacy",
	},
	{
		id: 67,
		title: "Sable",
	},
	{
		id: 68,
		title: "Alpina B7",
	},
	{
		id: 69,
		title: "Cherokee",
	},
	{
		id: 70,
		title: "RDX",
	},
	{
		id: 71,
		title: "Torrent",
	},
	{
		id: 72,
		title: "M3",
	},
	{
		id: 73,
		title: "C-Class",
	},
	{
		id: 74,
		title: "Th!nk",
	},
	{
		id: 75,
		title: "V40",
	},
	{
		id: 76,
		title: "MKS",
	},
	{
		id: 77,
		title: "LX",
	},
	{
		id: 78,
		title: "H2",
	},
	{
		id: 79,
		title: "MX-6",
	},
	{
		id: 80,
		title: "5000CS",
	},
	{
		id: 81,
		title: "V50",
	},
	{
		id: 82,
		title: "Intrigue",
	},
	{
		id: 83,
		title: "MKS",
	},
	{
		id: 84,
		title: "SC",
	},
	{
		id: 85,
		title: "F350",
	},
	{
		id: 86,
		title: "Cooper",
	},
	{
		id: 87,
		title: "Civic",
	},
	{
		id: 88,
		title: "M-Class",
	},
	{
		id: 89,
		title: "Lanos",
	},
	{
		id: 90,
		title: "G",
	},
	{
		id: 91,
		title: "STS",
	},
	{
		id: 92,
		title: "Cube",
	},
	{
		id: 93,
		title: "XF",
	},
	{
		id: 94,
		title: "Prelude",
	},
	{
		id: 95,
		title: "Envoy XL",
	},
	{
		id: 96,
		title: "Tiguan",
	},
	{
		id: 97,
		title: "Ram Van 2500",
	},
	{
		id: 98,
		title: "MX-5",
	},
	{
		id: 99,
		title: "745",
	},
	{
		id: 100,
		title: "CC",
	},
];
