import React, { useState, useEffect } from "react";
import { DynamicPanel } from "./Panel";
import ReactGrid from "../../ReactGrid/ReactGrid";
import { getSmallRef } from "../../../utils/StringUtils";
import { buildPanelLocation, PANEL_CONTENT_TYPES } from "../../../utils/PanelTypes";
import { useDispatch, useTrackedState } from "../../../utils/store";
import PanelOverlay from "../../PanelOverlay/PanelOverlay";
import { v4 as uuidv4 } from "uuid";
import { TogglesIcon } from "../../BootstrapComponents/Icons/Icons";
import { UBM_MASTER_FILE_INDEX_ITEM } from "../../../utils/StandardObject";
import { getSingleLevelObjectMfi } from "../../../utils/ApiUtils";

/**
 * Renders the destination model with a visual rep for objects clicked within the destination model. Currently this is the panel on the far left
 * @param
 * @constructor
 */
const DestinationObjectAndVisualRepPanel = ({
	panelId,
	addChangedRows,
	panelGroup,
	mfiReference = "",
	panelTitle = "Object Master File Index",
	allowResizing,
}) => {
	const [destination, setDestination] = useState({});
	const [mfi, setMfi] = useState([]);
	const [layout, setLayout] = useState([]);
	const [showVisualRep, setShowVisualRep] = useState(false);

	//Global state used to store the existing objects that can be shared across components
	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	const ObjectMasterFileIndexWithoutPanel = PANEL_CONTENT_TYPES.OBJECT_MASTER_FILE_INDEX_WITHOUT_PANEL;
	const VisualRepWithoutPanel = PANEL_CONTENT_TYPES.VISUALREP_WITHOUT_PANEL;

	useEffect(() => {
		let { contextDestinationObject: destinationObject } = sharedState;

		getDestinationObject(destinationObject?.uuid, destinationObject?.versionUuid);
	}, [sharedState.contextDestinationObject?.uuid, sharedState.contextDestinationObject?.versionUuid]);

	useEffect(() => {
		setLayout([
			buildPanelLocation("OBJECT_MASTER_FILE_INDEX_PANEL", ObjectMasterFileIndexWithoutPanel.uuid, {
				w: ObjectMasterFileIndexWithoutPanel.w * 2,
			}),
		]);
	}, []);

	useEffect(() => {
		if (showVisualRep) {
			setLayout([
				buildPanelLocation("OBJECT_MASTER_FILE_INDEX_PANEL", ObjectMasterFileIndexWithoutPanel.uuid),
				buildPanelLocation("VISUALREP_PANEL", VisualRepWithoutPanel.uuid),
			]);
		} else {
			setLayout([
				buildPanelLocation("OBJECT_MASTER_FILE_INDEX_PANEL", ObjectMasterFileIndexWithoutPanel.uuid, {
					w: ObjectMasterFileIndexWithoutPanel.w * 2,
				}),
			]);
		}
	}, [showVisualRep]);

	/**
	 * Gets the destination object associated with the uuid and version passed in if it hasn't already been loaded
	 * @param uuid
	 * @param versionUuid
	 * @returns {Promise<void>}
	 */
	const getDestinationObject = async (uuid, versionUuid) => {
		let mfi;

		//I'm not sure if I can do this, but I'll assume that the destination object will always be a top that resides in the reference manual, so no attachment point or anything
		if (uuid && versionUuid && destination?.uuid !== uuid && destination?.versionUuid !== versionUuid)
			mfi = await getSingleLevelObjectMfi(uuid, versionUuid);
		else {
			let { contextDestiationObject: destinationObject } = sharedState;

			//If we have a new destination grab the mfi and update the state
			if (
				destinationObject?.uuid !== destination?.uuid ||
				destinationObject?.versionUuid !== destination?.versionUuid
			)
				mfi = await getSingleLevelObjectMfi(destinationObject.uuid, destinationObject.versionUuid);
		}

		if (mfi) {
			setMfi(mfi);
			setDestination(mfi[0]);
			dispatch({ type: "SET_CONTEXT_OR_MFI", data: { destinationObject: mfi[0], destinationMfi: mfi } });
		}
	};

	const handleDrop = (event) => {
		let dragObj;
		try {
			dragObj = JSON.parse(event.dataTransfer.getData("text"));
		} catch (e) {
			return;
		}

		if (dragObj.origin === "models-panel" && !dragObj[UBM_MASTER_FILE_INDEX_ITEM])
			getDestinationObject(dragObj.standardObjectUuid, dragObj.standardObjectVersionUuid);
	};

	let fullTitle = "",
		smallTitle = "",
		reference = "";

	if (destination) {
		if (destination.mfiReference) reference = destination.mfiReference;
		else if (destination.reference) reference = destination.reference;

		if (destination.title && reference) {
			smallTitle = `${getSmallRef(reference)} - ${destination.title}`;
			fullTitle = `${reference} - ${destination.title}`;
		} else if (destination.title) {
			fullTitle = destination.title;
			smallTitle = destination.title;
		}
	}

	return (
		<PanelOverlay elementId={panelId} getMessage={() => "Drop to load Object MFI"} handleDrop={handleDrop}>
			<DynamicPanel
				// panelTitle={panelTitle}
				panelTitle={
					<>
						<div>{panelTitle}</div>
						<div title={fullTitle}>{smallTitle}</div>
					</>
				}
				hoverPanelTitle={
					<>
						<div>{panelTitle}</div>
						<div>{fullTitle}</div>
					</>
				}
				menuItems={[
					{
						id: "destination-and-visual-rep-hid-show-visual-rep",
						label: showVisualRep ? "Hide Visual Rep" : "Show Visual Rep",
						icon: <TogglesIcon />,
						onClick: () => setShowVisualRep((prev) => !prev),
					},
				]}
				panelId={panelId}
				panelGroup={panelGroup}
				allowResizeToggle={false}
			>
				<ReactGrid
					layoutId={panelId}
					layout={layout}
					dynamicHeight={true}
					allowResize={allowResizing}
					draggableHandle={`.${panelGroup}-subPanel`}
					draggable={allowResizing}
				>
					<div key={ObjectMasterFileIndexWithoutPanel.uuid}>
						{ObjectMasterFileIndexWithoutPanel.Render(
							{
								panelId,
								mfi,
								addChangedRows,
								panelGroup: panelGroup + "-subPanel",
								panelTitle,
								allowResizeToggle: false,
								noPanelHeader: true,
							},
							sharedState,
							dispatch
						)}
					</div>
					<div key={VisualRepWithoutPanel.uuid} className={showVisualRep ? "" : "d-none"}>
						{VisualRepWithoutPanel.Render(
							{
								panelId,
								uuid: destination?.uuid,
								versionUuid: destination?.versionUuid,
								panelGroup: panelGroup + "-subPanel",
								noPanelHeader: true,
								panelTitle: "Visual Presentations",
							},
							sharedState,
							dispatch
						)}
					</div>
				</ReactGrid>
			</DynamicPanel>
		</PanelOverlay>
	);
};

export default DestinationObjectAndVisualRepPanel;
