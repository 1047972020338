import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import stylesModule from "./AppSearchBar.module.scss";
import "./AppSearchBar.scss";

/**
 * A search bar for an app that can handle searching functionality for you
 * @param
 * @constructor
 */
const AppSearchBar = ({
	objects,
	attributes,
	addBlankRow,
	parentCallback,
	changes,
	resolveChanges,
	noRows,
	searchMfiForObjects = false,
	loaded,
	styles = {},
	filterAttributes,
}) => {
	//state variables
	const [lod, setLod] = useState("full");
	const [hover, setHover] = useState(false);
	const searching = useRef(false);
	const [value, setValue] = useState("");

	//A variable to keep track of when we can search, based off of the user not typing anything for 100 ms
	const searchKeyTimeout = useRef();

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		if (objects) {
			setValue("");
		}
	}, [objects, objects?.length]);

	//Other Methods
	/**
	 * Searches an array of object for matching text, searches the attributes passed in
	 * @param objects, attributes, value, level
	 */
	const searchArray = (arr, attributes, value, level) => {
		let objs;
		if (filterAttributes) {
			objs = arr.filter((row) => {
				return Object.keys(filterAttributes).some((key) => {
					if (Array.isArray(filterAttributes[key])) return filterAttributes[key].includes(row[key]);
					else return row === filterAttributes[key];
				});
			});
		} else if (searchMfiForObjects)
			objs = arr.filter((row) => row.standardObjectUuid && row.standardObjectVersionUuid);
		else objs = arr;
		let lvl = level || lod;
		if (lvl !== "full") {
			objs = objs.filter((obj) => checkReference(obj.reference, level));
		}

		//Convert to lowercase so that case doesn't matter
		value = value.trim().toLowerCase();

		//If value contains a ',' split it by this and check for each bucket in the array
		if (value.includes(",")) {
			value = value.split(",");

			return objs.filter((item) => {
				return attributes.some((key) =>
					value.some((v) => v.trim() && (item[key] || "").toLowerCase().includes(v))
				);
			});
		} else
			return objs.filter((item) => {
				return attributes.some((key) => (item[key] || "").toLowerCase().includes(value));
			});
	};

	/**
	 * Checks an object's reference to see if it matches the level in the state
	 * @param reference, lod
	 */
	const checkReference = (reference, lod) => {
		return lod >= (reference.match(/\./g) || []).length + 1;
	};

	const startSearchKeyTimeout = (searchTerm) => {
		clearTimeout(searchKeyTimeout.current);

		searchKeyTimeout.current = setTimeout(() => {
			let matches = searchArray(objects, attributes, searchTerm);
			searching.current = true;
			parentCallback(matches);
		}, 250);
	};

	const clearSearchTimeout = () => {
		clearTimeout(searchKeyTimeout.current);
	};

	return (
		<div className={"searchbar-div"} style={{ ...styles }}>
			{changes ? (
				<div className={"flex"} style={{ marginBottom: "15px", justifyContent: "around" }}>
					<Button
						variant={"outlined"}
						style={{ marginRight: "15px" }}
						color="primary"
						onClick={() => resolveChanges(true)}
					>
						Save
					</Button>
					<Button variant={"outlined"} color="secondary" onClick={() => resolveChanges(false)}>
						Discard
					</Button>
				</div>
			) : (
				""
			)}
			{noRows && addBlankRow ? (
				<Button
					variant={"outlined"}
					color={"primary"}
					className={noRows ? "" : "hidden"}
					style={{ marginBottom: "15px" }}
					onClick={addBlankRow}
				>
					Add Row
				</Button>
			) : (
				""
			)}
			<AppBar position="static" className={`app-bar ${hover ? "hover" : ""}`}>
				<Toolbar style={{ paddingLeft: "0px" }}>
					<div className={stylesModule.search} style={{ width: "100%" }}>
						<div className={stylesModule.searchIcon}>
							<SearchIcon />
						</div>
						<InputBase
							placeholder="Search…"
							classes={{
								root: stylesModule.inputRoot,
								input: stylesModule.inputInput,
							}}
							value={value}
							onChange={(e, val) => setValue(val)}
							fullWidth={true}
							inputProps={{ "aria-label": "search" }}
							onKeyUp={(e) => {
								if (e.keyCode === 13 && e.target.value.length > 0) {
									clearSearchTimeout();
									let matches = searchArray(objects, attributes, e.target.value);
									searching.current = true;
									parentCallback(matches);
								} else if (searching.current && (e.keyCode = (8 || 46) && e.target.value === "")) {
									clearSearchTimeout();
									searching.current = false;
									parentCallback(undefined);
								} else {
									if (e.target.value.trim() !== "") startSearchKeyTimeout(e.target.value);
									else clearSearchTimeout();
								}
							}}
							className={"inputDiv searchInputDiv"}
							style={{ width: "100%", paddingTop: "3px" }}
							onFocus={() => setHover(true)}
							onBlur={() => setHover(false)}
						/>
					</div>
					<ButtonGroup
						variant="contained"
						color="primary"
						aria-label="contained primary button group"
					></ButtonGroup>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default AppSearchBar;
