import { useEffect, useState } from "react";
import stylesModule from "./OnlineStatus.module.scss";

// Interval: The time in seconds to check for network connectivity
const OnlineStatus = ({ interval = 10 }) => {
	const [online, setOnline] = useState("Checking");

	const checkOnlineStatus = async () => {
		setOnline("Checking");
		try {
			const online = await fetch(process.env.REACT_APP_REPORT_GENERATION_SERVICE);
			setOnline(online.status >= 200 && online.status < 300 ? "Online" : "Offline"); // either true or false
		} catch (err) {
			setOnline("Offline");
		}
	};

	useEffect(() => {
		const checkInterval = setInterval(() => {
			checkOnlineStatus();
		}, interval * 1000);

		return () => clearInterval(checkInterval);
	}, []);

	return (
		<div
			className={`${stylesModule[online + "-color"]} ${stylesModule.onlineIndicator}`}
			title={`Connectivity Status: ${online}`}
		>
			<sup>⬤</sup>
		</div>
	);
};

export default OnlineStatus;
