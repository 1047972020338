import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import { getCall, getUrl, urls } from "../../../utils/ApiUtils";
import { getObjectMfi } from "../../../utils/ApiUtils";
import { useDispatch, useTrackedState } from "../../../utils/store";
import { createNewMfiRow } from "../../../utils/StandardObject";
import { sortByReference } from "../../../utils/Referencing";
import { Radio } from "@mui/material";
import { dataObjects } from "../../../utils/DataObject";
import DatePicker from "@mui/lab/DatePicker";
import "./ChangeRequest.module.scss";

export const newChangeRequest = () => {
	return {
		uuid: uuidv4(),
		ubmItem: {
			uuid: uuidv4(),
		},
	};
};

//These are specific to the Approval Status of a change request
export const changeRequestApprovalStages = {
	PROPOSED: "Proposed",
	ACCEPTED: "Accepted",
	HELDFORFUTURE: "Held for Future",
	REJECTED: "Rejected",
};

export const releaseStages = {
	ALPHA: "Alpha",
	BETA: "Beta",
	PRODUCTION: "Production",
};

//Contains all of the stages of a change request, fix packet, patch packet, and release packet
export const stages = {
	DRAFT: "Draft",
	...changeRequestApprovalStages,
	APPROVED: "Approved",
	DEV: "Development",
	REVIEW: "Development Review",
	...releaseStages,
};

export const objectStates = {
	UNDERCONSTRUCTION: "Under Construction",
	CURRENTOBJECT: "Current Object",
	SUGGESTEDCHANGE: "Suggested Changes",
	PROPOSED: "Proposed",
	DEVELOPMENT: "Development",
	ALPHA: "Alpha",
	BETA: "Beta",
	PRODUCTION: "Production",
};
export const objectStatesAbbr = {
	UNDERCONSTRUCTION: "UC",
	CURRENTOBJECT: "CO",
	SUGGESTEDCHANGE: "SC",
	PROPOSED: "Prop",
	DEVELOPMENT: "Dev",
	ALPHA: "Alpha",
	BETA: "Beta",
	PRODUCTION: "Prod",
};

/**
 * A component that allows a user to build a list of arbitrary fields (defaults) with values[
 * @param { changeRequest: _changeRequest, saveChangeRequest }
 * @constructor
 */
const ChangeRequestForm = ({
	changeRequest: _changeRequest,
	sourceObjectTemplateMfi,
	suggestedChangeMfi: _suggestedChangeMfi,
	shouldSave,
	resetSaveClicked,
	objectChangeData,
	...other
}) => {
	//state variables
	const [changeRequest, setChangeRequest] = useState({ ...newChangeRequest() });
	const [originalObject, setOriginalObject] = useState(null);
	const [currentMfi, setCurrentMfi] = useState([]);
	const [suggestedChangeMfi, setSuggestedChangeMfi] = useState([]);
	const generalTypes = useRef();

	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		if (_changeRequest) {
			if (!_changeRequest?.ubmItem) _changeRequest.ubmItem = {};
			updateChangeRequest(_changeRequest);
			setSuggestedChangeMfi(_suggestedChangeMfi);
			if (!_changeRequest.submitterChangeRequestPacketNumber) getSubmitterChangeRequestNumber();
			getOriginalObject();
		}
	}, [_changeRequest, sourceObjectTemplateMfi, _suggestedChangeMfi]);

	useEffect(() => {
		setCurrentMfi(sourceObjectTemplateMfi?.sort(sortByReference));
		setSuggestedChangeMfi(_suggestedChangeMfi?.sort(sortByReference));

		async function getTypes() {
			let types = await getCall(urls["getGeneralTypes"]);

			dispatch({ type: "SET_GENERAL_TYPES", data: types });
			generalTypes.current = types;
		}

		if (sharedState.generalTypes === undefined || sharedState.generalTypes.length < 1) getTypes();
		else generalTypes.current = sharedState.generalTypes;
	}, []);

	const updateChangeRequest = (data) => {
		setChangeRequest(data);
		let viewTitle = sharedState.openView.title;
		if (!objectChangeData.current[viewTitle]) {
			objectChangeData.current[viewTitle] = {};
		}

		objectChangeData.current[viewTitle][dataObjects.CHANGE_REQUEST.title + "-changed"] = data;
		objectChangeData.current[viewTitle][dataObjects.STANDARD_OBJECT_GIT.title + "-changed"] = {
			currentMfi: sourceObjectTemplateMfi,
			suggestedChangeMfi: _suggestedChangeMfi,
		};
		dispatch({ type: "TOGGLE_CHANGES", data: true });
	};

	//Other Methods
	const onInputChange = (event, name) => {
		updateChangeRequest({ ...changeRequest, [name]: event.target.value });
	};

	const onDateInputChange = (date, name, ubmItem) => {
		if (ubmItem) {
			updateChangeRequest({
				...changeRequest,
				ubmItem: {
					...changeRequest.ubmItem,
					[name]: date,
				},
			});
		} else {
			updateChangeRequest({ ...changeRequest, [name]: date });
		}
	};

	const ubmItemInputChnage = (event, name) => {
		updateChangeRequest({
			...changeRequest,
			ubmItem: {
				...changeRequest.ubmItem,
				[name]: event.target.value,
			},
		});
	};

	const onInputBlur = (event, name) => {
		// saveChangeRequest(changeRequest, name);
	};

	const getSubmitterChangeRequestNumber = async () => {
		let submitterChangeRequestPacketNumber = await getCall(
			getUrl("getNextSubmitterChangeRequestNumber", [sharedState.currentUser.uuid])
		);
		updateChangeRequest({
			...changeRequest,
			..._changeRequest,
			submitterChangeRequestPacketNumber: submitterChangeRequestPacketNumber,
			ubmItem: {
				...changeRequest.ubmItem,
				..._changeRequest.ubmItem,
			},
		});
	};

	const getOriginalObject = async () => {
		if (_changeRequest.standardObjectUuid && _changeRequest.standardObjectVersionUuid) {
			let originalObject = await getCall(
				getUrl("getUpstreamObject", [
					_changeRequest.standardObjectUuid,
					_changeRequest.standardObjectVersionUuid,
				])
			);
			setOriginalObject(originalObject);
		}
	};

	const getSourceObjectTemplateInfo = async () => {
		//The source object template should already be pulled up, we may not need to make a call to the database
		//Go through the object and pull the pieces of the object (Legal Entity, CCODE, etc.)
		let sourceObjectInformation = await getItemChangeRequestInformation(
			sourceObjectTemplateMfi,
			sharedState.currentUser
		);
		//Set the changeRequest to match the data given
		updateChangeRequest({
			...changeRequest,
			ubmItem: {
				...changeRequest.ubmItem,
				...sourceObjectInformation,
			},
		});
		setCurrentMfi(sourceObjectTemplateMfi);
	};

	const getOriginalObjectInfo = async () => {
		//The original object is accessible through the git fork table
		//We should have a call that gets a list of the objects based on the git fork table
		//For now get the latest object, potentially in the future have the button pull up a dropdown list of the objects
		//that are upstream. Go through the object and get the information needed for the change request form
		let originalObjectMfi = await getObjectMfi(originalObject.uuid, originalObject.versionUuid);
		if (originalObjectMfi.length == 0) return;

		setCurrentMfi(originalObjectMfi);

		let originalObjectInformation = await getItemChangeRequestInformation(
			originalObjectMfi,
			sharedState.currentUser
		);
		updateChangeRequest({ ...changeRequest, ...originalObjectInformation });
	};

	return (
		<Grid container className={`${"contentEnd"}`}>
			<Grid container columns={{ xs: 12 }} className={""} style={{}}>
				<Grid container columns={{ xs: 12 }} className={""} style={{}}>
					<Typography paragraph={false} variant={"body1"}>
						Red fields are - Office Use Only Fields
					</Typography>
				</Grid>
				<Grid container columns={{ xs: 12 }} className={"border"} style={{}}>
					<Grid container columns={{ xs: 12 }} className={"border heading"} style={{}}>
						<Typography paragraph={false} className={"bold"} variant={"body1"}>
							Requested By:
						</Typography>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<Grid container columns={{ xs: 1 }} className={""} style={{}}></Grid>
						<Grid container columns={{ xs: 11 }} className={""} style={{}}>
							<Grid container columns={{ xs: 4 }} className={"border"} style={{}}>
								<TextField
									label={"Name:"}
									value={changeRequest.requestedByName}
									onChange={(e) => onInputChange(e, "requestedByName")}
									onBlur={(e) => onInputBlur(e, "requestedByName")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container columns={{ xs: 5 }} className={"border"} style={{}}>
								<TextField
									label={"Company:"}
									value={changeRequest.requestedByCompany}
									onChange={(e) => onInputChange(e, "requestedByCompany")}
									onBlur={(e) => onInputBlur(e, "requestedByCompany")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container columns={{ xs: 3 }} className={"border"} style={{}}>
								<DatePicker
									autoOk
									variant="inline"
									label="Date:"
									format="MM/DD/yyyy"
									value={changeRequest.requestedDate}
									InputAdornmentProps={{ position: "start" }}
									className={"full-width"}
									onChange={(date) => onDateInputChange(date, "requestedDate")}
								/>
							</Grid>
							<Grid container columns={{ xs: 4 }} className={"border"} style={{}}>
								<TextField
									label={"Email:"}
									value={changeRequest.requestedByEmail}
									onChange={(e) => onInputChange(e, "requestedByEmail")}
									onBlur={(e) => onInputBlur(e, "requestedByEmail")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container columns={{ xs: 5 }} className={"border"} style={{}}>
								<TextField
									label={"CCODE:"}
									value={changeRequest.requestedByCcode}
									onChange={(e) => onInputChange(e, "requestedByCcode")}
									onBlur={(e) => onInputBlur(e, "requestedByCcode")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container columns={{ xs: 3 }} className={"border"} style={{}}>
								<TextField
									label={"Contact #:"}
									value={changeRequest.requestedByContactNumber}
									onChange={(e) => onInputChange(e, "requestedByContactNumber")}
									onBlur={(e) => onInputBlur(e, "requestedByContactNumber")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container columns={{ xs: 12 }} className={""} style={{}}>
					<Grid container item xs={12} className={"border"} style={{}}>
						<Grid container columns={{ xs: 7 }} className={"border heading"} style={{}}>
							<Typography paragraph={false} className={"bold"} variant={"body1"}>
								Item to be changed:
							</Typography>
							<Button
								onClick={getSourceObjectTemplateInfo}
								disabled={!sourceObjectTemplateMfi}
								className={"smallButton"}
							>
								Source Object Template
							</Button>
							<Button
								onClick={getOriginalObjectInfo}
								disabled={!originalObject}
								className={"smallButton"}
							>
								Original Object
							</Button>
						</Grid>
						<Grid container columns={{ xs: 5 }} className={"border heading"} style={{}}>
							<Typography paragraph={false} className={"bold"} variant={"body1"}>
								UBM (If Applicable)
							</Typography>
						</Grid>
						<Grid container columns={{ xs: 1 }} className={""} style={{}}></Grid>
						<Grid container columns={{ xs: 6 }} className={""} style={{}}>
							<Grid container columns={{ xs: 12 }} className={"border"} style={{}}>
								<TextField
									label={"Legal Entity:"}
									value={changeRequest.ubmItem.legalEntity}
									onChange={(e) => ubmItemInputChnage(e, "legalEntity")}
									onBlur={(e) => onInputBlur(e, "legalEntity")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container columns={{ xs: 12 }} className={"border"} style={{}}>
								<TextField
									label={"Model Title:"}
									value={changeRequest.ubmItem.modelTitle}
									onChange={(e) => ubmItemInputChnage(e, "modelTitle")}
									onBlur={(e) => onInputBlur(e, "modelTitle")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container columns={{ xs: 12 }} className={"border"} style={{}}>
								<TextField
									label={"App Title:"}
									value={changeRequest.ubmItem.appTitle}
									onChange={(e) => ubmItemInputChnage(e, "appTitle")}
									onBlur={(e) => onInputBlur(e, "appTitle")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<TextField
									label={"Item Title:"}
									value={changeRequest.ubmItem.itemTitle}
									onChange={(e) => ubmItemInputChnage(e, "itemTitle")}
									onBlur={(e) => onInputBlur(e, "itemTitle")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container item xs={12} className={"border heading"} style={{}}>
								<Typography paragraph={false} className={"bold"} variant={"body1"}>
									BM (If Applicable)
								</Typography>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<Grid container item xs={5} className={"border"} style={{}}>
									<TextField
										label={"App/Model #:"}
										value={changeRequest.ubmItem.bmAppModelNumber}
										onChange={(e) => ubmItemInputChnage(e, "bmAppModelNumber")}
										onBlur={(e) => onInputBlur(e, "bmAppModelNumber")}
										className={"full-width"}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid container item xs={4} className={"border"} style={{}}>
									<TextField
										label={"Version #:"}
										value={changeRequest.ubmItem.bmAppModelVersionNumber}
										onChange={(e) => ubmItemInputChnage(e, "bmAppModelVersionNumber")}
										onBlur={(e) => onInputBlur(e, "bmAppModelVersionNumber")}
										className={"full-width"}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid container item xs={3} className={"border"} style={{}}>
									<TextField
										label={"Volume #:"}
										value={changeRequest.ubmItem.bmAppModelVolumeNumber}
										onChange={(e) => ubmItemInputChnage(e, "bmAppModelVolumeNumber")}
										onBlur={(e) => onInputBlur(e, "bmAppModelVolumeNumber")}
										className={"full-width"}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<TextField
									label={"Document Ref Manual MFI#:"}
									value={changeRequest.ubmItem.bmDocumentRefManualMfiNumber}
									onChange={(e) => ubmItemInputChnage(e, "bmDocumentRefManualMfiNumber")}
									onBlur={(e) => onInputBlur(e, "bmDocumentRefManualMfiNumber")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<TextField
									label={"Document Version #:"}
									value={changeRequest.ubmItem.bmDocumentVersionNumber}
									onChange={(e) => ubmItemInputChnage(e, "bmDocumentVersionNumber")}
									onBlur={(e) => onInputBlur(e, "bmDocumentVersionNumber")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<DatePicker
									autoOk
									variant="inline"
									label="Document Revision Date:"
									format="MM/DD/yyyy"
									value={changeRequest.ubmItem.bmDocumentRevisionDate}
									InputAdornmentProps={{ position: "start" }}
									className={"full-width"}
									onChange={(date) => onDateInputChange(date, "bmDocumentRevisionDate", true)}
								/>
							</Grid>
						</Grid>
						<Grid container item xs={5} className={""} style={{}}>
							<Grid container item xs={12} className={""} style={{}}>
								<Grid container item xs={6} className={"border"} style={{}}>
									<TextField
										label={"App/Model #:"}
										value={changeRequest.ubmItem.ubmAppModelNumber}
										onChange={(e) => ubmItemInputChnage(e, "ubmAppModelNumber")}
										onBlur={(e) => onInputBlur(e, "ubmAppModelNumber")}
										className={"full-width"}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid container item xs={3} className={"border"} style={{}}>
									<TextField
										label={"Version #:"}
										value={changeRequest.ubmItem.ubmAppModelVersionNumber}
										onChange={(e) => ubmItemInputChnage(e, "ubmAppModelVersionNumber")}
										onBlur={(e) => onInputBlur(e, "ubmAppModelVersionNumber")}
										className={"full-width"}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid container item xs={3} className={"border"} style={{}}>
									<TextField
										label={"Volume #:"}
										value={changeRequest.ubmItem.ubmAppModelVolumeNumber}
										onChange={(e) => ubmItemInputChnage(e, "ubmAppModelVolumeNumber")}
										onBlur={(e) => onInputBlur(e, "ubmAppModelVolumeNumber")}
										className={"full-width"}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<TextField
									label={"Document Ref Manual MFI #:"}
									value={changeRequest.ubmItem.ubmDocumentRefManualMfiNumber}
									onChange={(e) => ubmItemInputChnage(e, "ubmDocumentRefManualMfiNumber")}
									onBlur={(e) => onInputBlur(e, "ubmDocumentRefManualMfiNumber")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<TextField
									label={"Document Version #:"}
									value={changeRequest.ubmItem.ubmDocumentVersionNumber}
									onChange={(e) => ubmItemInputChnage(e, "ubmDocumentVersionNumber")}
									onBlur={(e) => onInputBlur(e, "ubmDocumentVersionNumber")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<DatePicker
									autoOk
									variant="inline"
									label="Document Revision Date:"
									format="MM/DD/yyyy"
									value={changeRequest.ubmItem.ubmDocumentRevisionDate}
									InputAdornmentProps={{ position: "start" }}
									className={"full-width"}
									onChange={(date) => onDateInputChange(date, "ubmDocumentRevisionDate", true)}
								/>
							</Grid>
							<Grid container item xs={12} className={"border heading"} style={{}}>
								<Typography paragraph={false} className={"bold"} variant={"body1"}>
									App (If Applicable)
								</Typography>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<Grid container item xs={6} className={"border"} style={{}}>
									<TextField
										label={"App/Model #:"}
										value={changeRequest.ubmItem.appAppModelNumber}
										onChange={(e) => ubmItemInputChnage(e, "appAppModelNumber")}
										onBlur={(e) => onInputBlur(e, "appAppModelNumber")}
										className={"full-width"}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid container item xs={3} className={"border"} style={{}}>
									<TextField
										label={"Version #:"}
										value={changeRequest.ubmItem.appAppModelVersionNumber}
										onChange={(e) => ubmItemInputChnage(e, "appAppModelVersionNumber")}
										onBlur={(e) => onInputBlur(e, "appAppModelVersionNumber")}
										className={"full-width"}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid container item xs={3} className={"border"} style={{}}>
									<TextField
										label={"Volume #:"}
										value={changeRequest.ubmItem.appAppModelVolumeNumber}
										onChange={(e) => ubmItemInputChnage(e, "appAppModelVolumeNumber")}
										onBlur={(e) => onInputBlur(e, "appAppModelVolumeNumber")}
										className={"full-width"}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<TextField
									label={"Document Ref Manual MFI#:"}
									value={changeRequest.ubmItem.appDocumentRefManualMfiNumber}
									onChange={(e) => ubmItemInputChnage(e, "appDocumentRefManualMfiNumber")}
									onBlur={(e) => onInputBlur(e, "appDocumentRefManualMfiNumber")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<TextField
									label={"Document Version #:"}
									value={changeRequest.ubmItem.appDocumentVersionNumber}
									onChange={(e) => ubmItemInputChnage(e, "appDocumentVersionNumber")}
									onBlur={(e) => onInputBlur(e, "appDocumentVersionNumber")}
									className={"full-width"}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container item xs={12} className={"border"} style={{}}>
								<DatePicker
									autoOk
									variant="inline"
									label="Document Revision Date:"
									format="MM/DD/yyyy"
									value={changeRequest.ubmItem.appDocumentRevisionDate}
									InputAdornmentProps={{ position: "start" }}
									className={"full-width"}
									onChange={(date) => onDateInputChange(date, "appDocumentRevisionDate", true)}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container columns={{ xs: 12 }} className={"border"} style={{}}>
					<Grid container columns={{ xs: 12 }} className={"border heading"} style={{}}>
						<Typography paragraph={false} className={"bold"} variant={"body1"}>
							Description of Change to be made:
						</Typography>
					</Grid>
					<Grid container columns={{ xs: 1 }} className={""} style={{}}></Grid>
					<Grid container columns={{ xs: 11 }} className={""} style={{}}>
						<Grid container item xs={12} className={"border"} style={{}}>
							<TextField
								label={""}
								value={changeRequest.description}
								onChange={(e) => onInputChange(e, "description")}
								onBlur={(e) => onInputBlur(e, "description")}
								className={"full-width"}
								multiline
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
						<Grid container columns={{ xs: 12 }} className={""} style={{}}></Grid>
						<Grid container columns={{ xs: 12 }} className={""} style={{}}></Grid>
						<Grid item xs={3} className={"border"} style={{}}>
							<TextField
								label={"Submitter Change Request Packet#:"}
								value={changeRequest.submitterChangeRequestPacketNumber}
								onChange={(e) => onInputChange(e, "submitterChangeRequestPacketNumber")}
								onBlur={(e) => onInputBlur(e, "submitterChangeRequestPacketNumber")}
								className={"full-width"}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
						<Grid item xs={9} className={"border"} style={{}}>
							<TextField
								label={
									"Change dependencies (this change is only valid if the following changes are accepted):"
								}
								value={changeRequest.changeDependencies}
								onChange={(e) => onInputChange(e, "changeDependencies")}
								onBlur={(e) => onInputBlur(e, "changeDependencies")}
								className={"full-width"}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid container columns={{ xs: 12 }} className={"border"} style={{}}>
					<Grid container columns={{ xs: 4 }} className={""} style={{}}>
						<Grid container columns={{ xs: 12 }} className={"border heading"} style={{}}>
							<Typography paragraph={false} className={"bold"} variant={"body1"}>
								Approval Level Required (Initial)
							</Typography>
						</Grid>
						<Grid container columns={{ xs: 1 }} className={""} style={{}}></Grid>
						<Grid container columns={{ xs: 11 }} className={""} style={{}}></Grid>
					</Grid>
					<Grid container columns={{ xs: 8 }} className={""} style={{}}>
						<Grid container columns={{ xs: 12 }} className={"border heading"} style={{}}>
							<Typography paragraph={false} className={"bold"} variant={"body1"}>
								Change Type
							</Typography>
						</Grid>
						<Grid container columns={{ xs: 9 }} className={""} style={{}}></Grid>
						<Grid container columns={{ xs: 3 }} className={""} style={{}}></Grid>
					</Grid>
				</Grid>
				<Grid container columns={{ xs: 12 }} className={"border"} style={{}}>
					<Grid container columns={{ xs: 12 }} className={"border heading redText"} style={{}}>
						<Typography paragraph={false} className={"bold"} variant={"body1"}>
							Approved By
						</Typography>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<Grid container columns={{ xs: 1 }} className={""} style={{}}></Grid>
						<Grid container item xs={11} className={""} style={{}}>
							<Grid item xs={4} className={"border"} style={{}}>
								<TextField
									label={"Name:"}
									value={changeRequest.approvedByName}
									onChange={(e) => onInputChange(e, "approvedByName")}
									onBlur={(e) => onInputBlur(e, "approvedByName")}
									className={"full-width"}
									disabled={!changeRequest.reviewer}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item xs={5} className={"border"} style={{}}>
								<TextField
									label={"Signature:"}
									value={changeRequest.approvedBySignature}
									onChange={(e) => onInputChange(e, "approvedBySignature")}
									onBlur={(e) => onInputBlur(e, "approvedBySignature")}
									className={"full-width"}
									disabled={!changeRequest.reviewer}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item xs={3} className={"border"} style={{}}>
								<DatePicker
									autoOk
									variant="inline"
									label="Date:"
									format="MM/DD/yyyy"
									value={changeRequest.approvedByDate}
									disabled={!changeRequest.reviewer}
									InputAdornmentProps={{ position: "start" }}
									className={"full-width"}
									onChange={(date) => onDateInputChange(date, "approvedByDate")}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container columns={{ xs: 12 }} className={"border redText"} style={{}}>
					<Grid container columns={{ xs: 12 }} className={"border heading redText"} style={{}}>
						<Typography paragraph={false} className={"bold"} variant={"body1"}>
							Change Task Assigned To
						</Typography>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<Grid container columns={{ xs: 1 }} className={""} style={{}}></Grid>
						<Grid container columns={{ xs: 11 }} className={""} style={{}}>
							<Grid item xs={4} className={"border"} style={{}}>
								<TextField
									label={"Name:"}
									value={changeRequest.changeTaskAssignedToName}
									onChange={(e) => onInputChange(e, "changeTaskAssignedToName")}
									onBlur={(e) => onInputBlur(e, "changeTaskAssignedToName")}
									className={"full-width"}
									disabled={!changeRequest.reviewer}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container columns={{ xs: 8 }} className={""} style={{}}>
								<Grid container columns={{ xs: 5 }} className={""} style={{}}>
									<Radio
										checked={changeRequest.status === changeRequestApprovalStages.ACCEPTED}
										onChange={(e) => onInputChange(e, "status")}
										value={changeRequestApprovalStages.ACCEPTED}
										name={"approval-status"}
										inputProps={{ "aria-label": "Approved Next Version" }}
										disabled={!changeRequest.reviewer}
									/>
									<Typography paragraph={false} variant={"body1"}>
										Approved Next Version
									</Typography>
								</Grid>
								<Grid container columns={{ xs: 5 }} className={""} style={{}}>
									<Radio
										checked={changeRequest.status === changeRequestApprovalStages.HELDFORFUTURE}
										onChange={(e) => onInputChange(e, "status")}
										value={changeRequestApprovalStages.HELDFORFUTURE}
										name={"approval-status"}
										inputProps={{ "aria-label": "Approved Future Version" }}
										disabled={!changeRequest.reviewer}
									/>
									<Typography paragraph={false} variant={"body1"}>
										Approved Future Version
									</Typography>
								</Grid>
								<Grid container columns={{ xs: 2 }} className={""} style={{}}>
									<Radio
										checked={changeRequest.status === changeRequestApprovalStages.REJECTED}
										onChange={(e) => onInputChange(e, "status")}
										value={changeRequestApprovalStages.REJECTED}
										name={"approval-status"}
										inputProps={{ "aria-label": "Rejected" }}
										disabled={!changeRequest.reviewer}
									/>
									<Typography paragraph={false} variant={"body1"}>
										Rejected
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={4} className={"border"} style={{}}>
								<TextField
									label={"Company:"}
									value={changeRequest.changeTaskAssignedToCompany}
									onChange={(e) => onInputChange(e, "changeTaskAssignedToCompany")}
									onBlur={(e) => onInputBlur(e, "changeTaskAssignedToCompany")}
									className={"full-width"}
									disabled={!changeRequest.reviewer}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid container columns={{ xs: 5 }} className={"border bold redText"} style={{}}>
								<Grid container columns={{ xs: 5 }} className={""} style={{}}></Grid>
								<Grid container columns={{ xs: 4 }} className={""} style={{}}></Grid>
								<Grid container columns={{ xs: 3 }} className={""} style={{}}></Grid>
							</Grid>
							<Grid item xs={3} className={"border bold redText"} style={{}}>
								<TextField
									label={"Change Request Packet #:"}
									value={changeRequest.changeRequestPacketNumber}
									onChange={(e) => onInputChange(e, "changeRequestPacketNumber")}
									onBlur={(e) => onInputBlur(e, "changeRequestPacketNumber")}
									className={"full-width"}
									disabled={!changeRequest.reviewer}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container columns={{ xs: 12 }} className={""} style={{}}>
					<Typography paragraph={false} variant={"body1"}>
						Note: Redline/highlight your suggested changes using the below legend; label the document
						"Suggested Change"; and attach it behind this form. <br />
						Red= Delete existing Item
						<br />
						Yellow Highlight= Change
						<br />
						Under Construction = UC
						<br />
						Put the current document behind the suggested change document and label it as "Current
						Document".
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ChangeRequestForm;

export const getItemChangeRequestInformation = (standardObjectMfi) => {
	//Go through the object and pull the pieces of the object (Legal Entity, CCODE, etc.)
	let topObject = standardObjectMfi[0];
	if (!topObject) return {};
	let modelOwnerLegalEntities = standardObjectMfi.filter((row) => row.title == "Model Owner Legal Entity");

	let changeRequestInformation = {
		standardObjectUuid: topObject.uuid,
		standardObjectVersionUuid: topObject.versionUuid,
		itemTitle: topObject.title,
		ubmDocumentRefManualMfiNumber: topObject.mfiReference,
		ubmDocumentVersionNumber:
			topObject.versionControl?.objectVersionNumber + "-" + topObject.versionControl?.objectState,
		legalEntity: modelOwnerLegalEntities.length > 0 ? modelOwnerLegalEntities[0].value : undefined,
	};

	return changeRequestInformation;
};

/**
 * Create a database migration creating a change request folder attached to zero row
 * Create a constant pointing to the change request row
 * Create a rest call to get the Change Requests in controller with BMCL-data-warehouse
 *
 * Get the change request MFI (possibly just get the last change request so I know the reference and change request number)
 * Call create new row (move into a utility class)
 * Update row information
 * Add the 2 children rows See getContextMenuItems
 * Save
 *
 */

export const createNewFixPacketMfi = async (fixPacketFolder, changeNumber, changeRequestMfi, currentUser) => {
	let fixPacketMfi = [];
	let fixPacketRow = createNewMfiRow(
		fixPacketFolder.reference + "." + changeNumber < 10 ? "0" + changeNumber : changeNumber,
		"Fix Packet-" + changeNumber,
		fixPacketFolder.uuid,
		currentUser.uuid
	);

	fixPacketMfi = [fixPacketRow];

	let defaultRows = defaultFixPacketMfiRows(fixPacketRow.uuid);
	defaultRows.forEach((row) => {
		let mfiRow = createNewMfiRow(row.reference, row.title, row.parentUuid, currentUser.uuid);
		mfiRow.uuid = row.uuid;
		mfiRow.included = row.included;
		fixPacketMfi.push(mfiRow);
	});

	if (changeRequestMfi) {
		let changeRequestSection = fixPacketMfi[fixPacketMfi.length - 1];
		let topChangeRequestRow = changeRequestMfi[0];
		changeRequestMfi.forEach((changeRequestMfiRow, index) => {
			if (index == 0) {
				topChangeRequestRow.reference = changeRequestSection.reference + ".01";
				topChangeRequestRow.referenceNo = 1;
				topChangeRequestRow.parentUuid = changeRequestSection.uuid;
			} else {
				changeRequestMfiRow.reference = topChangeRequestRow.reference + "." + changeRequestMfiRow.reference;
			}
			fixPacketMfi.push(changeRequestMfiRow);
		});
		// defaultRows.push(...)
	}

	let fixPacketObject = {
		mfiRows: fixPacketMfi,
		newVersionRow: fixPacketMfi.find((row) => row.title === "New Version"),
		currentObjectsRow: fixPacketMfi.find((row) => row.title === "Current Objects"),
	};

	return fixPacketObject;
};

export const createNewChangeRequestMfi = async (changeRequestFolder, changeRequestNumber, currentUser) => {
	let changeRequestMfi = [];
	let changeRequestRow = createNewMfiRow(
		changeRequestFolder.reference + "." + changeRequestNumber,
		"Change Request-" + changeRequestNumber,
		changeRequestFolder.uuid,
		currentUser.uuid
	);

	changeRequestMfi = [changeRequestRow];

	let defaultRows = defaultChangeRequestMfiRows(changeRequestRow.uuid);
	defaultRows.forEach((row) => {
		let mfiRow = createNewMfiRow(row.reference, row.title, row.parentUuid, currentUser.uuid);
		mfiRow.uuid = row.uuid;
		mfiRow.included = row.included;
		changeRequestMfi.push(mfiRow);
	});

	let changeRequestObject = {
		mfiRows: changeRequestMfi,
		suggestedChangesRow: changeRequestMfi.find((row) => row.title === "Suggested Changes"),
		currentObjectsRow: changeRequestMfi.find((row) => row.title === "Current Objects"),
	};

	return changeRequestObject;
};

export const defaultChangeRequestMfiRows = (parentUuid) => {
	let newUuidArray = [];
	for (let i = 0; i < 10; i++) {
		newUuidArray.push(uuidv4());
	}

	let changeRequestMfi = [
		{
			uuid: newUuidArray[0],
			parentUuid: parentUuid,
			included: false,
			reference: "A",
			referenceNo: 1,
			title: "Creation",
		},
		{
			uuid: newUuidArray[1],
			parentUuid: newUuidArray[0],
			included: false,
			reference: "A.01",
			referenceNo: 1,
			title: "Flowchart",
		},
		{
			uuid: newUuidArray[2],
			parentUuid: newUuidArray[0],
			included: false,
			reference: "A.02",
			referenceNo: 2,
			title: "Change Request Packet Checklist",
		},
		{
			uuid: newUuidArray[3],
			parentUuid: parentUuid,
			included: false,
			reference: "B",
			referenceNo: 2,
			title: "Control Section",
		},
		{
			uuid: newUuidArray[4],
			parentUuid: newUuidArray[3],
			included: false,
			reference: "B.01",
			referenceNo: 1,
			title: "Open Points / Change Request",
		},
		{
			uuid: newUuidArray[5],
			parentUuid: newUuidArray[3],
			included: false,
			reference: "B.02",
			referenceNo: 2,
			title: "Notes to Reviewer",
		},
		{
			uuid: newUuidArray[6],
			parentUuid: parentUuid,
			included: false,
			reference: "C",
			referenceNo: 3,
			title: "Change Request",
		},
		{
			uuid: newUuidArray[7],
			parentUuid: newUuidArray[6],
			included: false,
			reference: "C.01",
			referenceNo: 1,
			title: "Change Request Form",
		},
		{
			uuid: newUuidArray[8],
			parentUuid: parentUuid,
			included: false,
			reference: "D",
			referenceNo: 4,
			title: "Suggested Changes",
		},
		{
			uuid: newUuidArray[9],
			parentUuid: parentUuid,
			included: false,
			reference: "E",
			referenceNo: 5,
			title: "Current Objects",
		},
	];

	return changeRequestMfi;
};

export const defaultFixPacketMfiRows = (parentUuid) => {
	let newUuidArray = [];
	for (let i = 0; i < 11; i++) {
		newUuidArray.push(uuidv4());
	}

	let fixPacketMfi = [
		{
			uuid: newUuidArray[0],
			parentUuid: parentUuid,
			included: false,
			reference: "A",
			referenceNo: 1,
			title: "Creation",
		},
		{
			uuid: newUuidArray[1],
			parentUuid: newUuidArray[0],
			included: false,
			reference: "A.01",
			referenceNo: 1,
			title: "Flowchart",
		},
		{
			uuid: newUuidArray[2],
			parentUuid: newUuidArray[0],
			included: false,
			reference: "A.02",
			referenceNo: 2,
			title: "Fix Packet Checklist",
		},
		{
			uuid: newUuidArray[3],
			parentUuid: parentUuid,
			included: false,
			reference: "B",
			referenceNo: 2,
			title: "Control Section",
		},
		{
			uuid: newUuidArray[4],
			parentUuid: newUuidArray[3],
			included: false,
			reference: "B.01",
			referenceNo: 1,
			title: "Open Points / Action Items / Change Request",
		},
		{
			uuid: newUuidArray[5],
			parentUuid: newUuidArray[3],
			included: false,
			reference: "B.02",
			referenceNo: 2,
			title: "Notes to Reviewer",
		},
		{
			uuid: newUuidArray[6],
			parentUuid: parentUuid,
			included: false,
			reference: "C",
			referenceNo: 3,
			title: "Fix",
		},
		{
			uuid: newUuidArray[7],
			parentUuid: newUuidArray[6],
			included: false,
			reference: "C.01",
			referenceNo: 1,
			title: "Version Control Log",
		},
		{
			uuid: newUuidArray[8],
			parentUuid: parentUuid,
			included: false,
			reference: "D",
			referenceNo: 4,
			title: "New Version",
		},
		{
			uuid: newUuidArray[9],
			parentUuid: parentUuid,
			included: false,
			reference: "E",
			referenceNo: 5,
			title: "Current Objects",
		},
		{
			uuid: newUuidArray[10],
			parentUuid: parentUuid,
			included: false,
			reference: "F",
			referenceNo: 6,
			title: "Change Request Packets",
		},
	];

	return fixPacketMfi;
};
