import { useEffect } from "react";

const PricingPage = () => {
	useEffect(() => {
		document.title = "Pricing";
	});

	return (
		<div className="container">
			<div className="watermark">Unofficial</div>
			<div className="row mt-3">
				<div className="col text-center">
					<h1>Pricing</h1>
				</div>
			</div>
			<div className="row mt-5">
				<div className="col text-center">
					<h2>Operations</h2>
					<span className="fs-6 text-dark">$x / User / Month</span>
					<div className="card mx-auto mt-2" style={{ width: "18rem" }}>
						<div className="card-body" style={{ height: "20rem" }}>
							<p className="card-text fs-4 ">Operate in an existing business model</p>
							<hr />
							<ul className="text-left fs-5">
								<li>Enter Data</li>
								<li>Run Reports</li>
								<li>Complete Checklists</li>
								<li>Install Applications</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="col text-center">
					<h2>Model/App Development</h2>
					<span className="fs-6 text-dark">$x / User / Month</span>
					<div className="card mx-auto mt-2" style={{ width: "18rem" }}>
						<div className="card-body" style={{ height: "20rem" }}>
							<p className="card-text fs-4 ">Create a business model and develop applications</p>
							<hr />
							<ul className="text-left fs-5">
								<li>Create a business model</li>
								<li>Develop applications</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="col text-center">
					<h2>Object Development</h2>
					<span className="fs-6 text-dark">$x / User / Month</span>
					<div className="card mx-auto mt-2" style={{ width: "18rem" }}>
						<div className="card-body" style={{ height: "20rem" }}>
							<p className="card-text fs-4 ">Create new objects, reports, etc...</p>
							<hr />
							<ul className="text-left fs-5">
								<li>Create new objects for use in your models/apps</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PricingPage;
