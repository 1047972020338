import { getReferenceNo } from "../../utils/Referencing";
import stylesModule from "./Window.module.scss";

/**
 * A component for rendering what we call a window, which is a bootstrap card with a header and children
 * @param {windowHeader}
 * @constructor
 */
const Window = ({
	windowHeader,
	windowUuid,
	topNodeUuid,
	open,
	notFoundMessage,
	breadCrumbs = [],
	activeIds,
	cardStyle = {},
	cardBodyStyles = {},
	innerCard = true,
	...other
}) => {
	//Other Methods

	return (
		<div style={{ width: "100%" }}>
			<div className={`card ${cardStyle.noCardTopMargin ? "" : "mt-4"}`}>
				<div className={`card-body ${stylesModule.cardShadow}`}>
					{open ? <button className="btn-close float-end" onClick={() => open({}, true, windowUuid)} /> : ""}
					<h4 className="card-title">{windowHeader}</h4>
					<h6 className="text-muted card-subtitle mb-2" />
					<hr />
					<div className={innerCard ? "card" : ""}>
						<div className="card-body" style={{ ...cardBodyStyles }}>
							{breadCrumbs.length > 0 ? (
								<>
									<ol className="breadcrumb">
										{breadCrumbs.map((breadCrumb, index) => {
											let pointerEvents = "auto";
											let active = false;
											if (!open || index === breadCrumbs.length - 1) {
												active = true;
												pointerEvents = "none";
											}

											let display = "";
											if (breadCrumb.uuid === topNodeUuid) display = `${breadCrumb.title}`;
											else
												display = `${getReferenceNo(breadCrumb.reference)}.${breadCrumb.title}`;

											return (
												<li
													key={breadCrumb.uuid + "-window-" + index + "-" + windowUuid}
													className={`breadcrumb-item`}
													style={{ paddingRight: "0px", paddingLeft: "0px" }}
												>
													<a
														className={`${stylesModule.cardLocationText} ${
															active ? stylesModule.activeBreadcrumb : ""
														}`}
														style={{
															paddingLeft: "5px",
															paddingRight: "10px",
															pointerEvents,
														}}
														href="#"
														onClick={() => {
															if (open) open(breadCrumb, true, windowUuid);
														}}
													>
														<span>{display}</span>
													</a>
												</li>
											);
										})}
									</ol>
									<hr />
								</>
							) : notFoundMessage ? (
								<li className={`breadcrumb-item ${stylesModule.breadCrumbItemHolder}`}>
									<a className={`${stylesModule.cardLocationText}`} href="#">
										<span>{notFoundMessage}</span>
									</a>
								</li>
							) : (
								""
							)}
							{/*<form className={'test'} onSubmit={'return false;'}>*/}
							<div className="d-flex flex-wrap flex-row" style={{ width: "100%" }}>
								{other.children}
							</div>
							{/*</form>*/}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Window;
