//Place the following line with the other state variables in your React Component
import DraggableDialog from "../Dialog/DraggableDialog/Dialog";
import Comparison from "../VersionControl/Comparison/Comparison";
import { useTrackedState } from "../../utils/store";
import React, { useEffect, useRef, useState } from "react";
import styles from "./comparison.module.scss";
import TitleAutocomplete from "../Autocomplete/TitleAutocomplete";
import { getObjectsComputerVersions } from "../../utils/StandardObject";
import { getSingleLevelObjectMfi } from "../../utils/ApiUtils";
import InlineEditableElement from "../InlineEditableElement/InlineEditableElement";

export const ObjectComparisonDialog = ({
	oldObject: _oldObject,
	oldObjectMfi: _oldObjectMfi = [],
	currentObject: _currentObject,
	currentObjectMfi: _currentObjectMfi = [],
	newVersion,
	handleItemUpdate,
	showMfis = false,
}) => {
	const [showObjectComparisonDialog, setShowObjectComparisonDialog] = useState(false);
	const [oldObject, setOldObject] = useState(_oldObject);
	const [oldObjectMfi, setOldObjectMfi] = useState(_oldObjectMfi);
	const [currentObject, setCurrentObject] = useState(_currentObject);
	// const [currentObjectMfi, setCurrentObjectMfi] = useState(_currentObjectMfi);
	const [objectsVersions, setObjectsVersions] = useState([]);

	const SOURCE = "Source";
	const OLD_VERSION = "Old Version";
	const OTHER = "Other";
	//Options are Source, Old Version, Other
	const [compareWith, setCompareWith] = useState(OLD_VERSION);
	const [versionToCompare, setVersionToCompare] = useState(null);

	const currentObjectMfi = useRef(_currentObjectMfi);

	const sharedState = useTrackedState();

	// useEffect(() => {
	//     if(!oldObject) {
	//         oldObject
	//     }
	// }, [oldObject]);

	useEffect(() => {
		if (sharedState.openObjectComparisonDialog) setShowObjectComparisonDialog(true);
	}, [sharedState.openObjectComparisonDialog]);

	useEffect(() => {
		if (compareWith === OLD_VERSION && currentObject) {
			getObjectsComputerVersions(currentObject.versionUuid).then((result) => {
				setObjectsVersions(result);
				if (result.length > 1) {
					setVersionToCompare(result.length - 1);
				}
			});
		}
	}, [compareWith, currentObject]);

	useEffect(() => {
		setOldObject(_oldObject);
	}, [_oldObject?.uuid]);
	useEffect(() => {
		setOldObjectMfi(_oldObjectMfi);
	}, [_oldObjectMfi?.length]);
	useEffect(() => {
		setCurrentObject(_currentObject);
		currentObjectMfi.current = _currentObjectMfi;
	}, [_currentObject?.uuid]);

	const retrieveMfi = async (objectRecord, version) => {
		if (!objectRecord) return;

		if (version < 1) version = 1;
		else if (objectsVersions.length < version) version = objectsVersions?.length || objectRecord.computerVersion;

		return await getSingleLevelObjectMfi(objectRecord.uuid, objectRecord.versionUuid, null, version, null);
	};

	const loadOldObject = async (version) => {
		setOldObjectMfi([]);
		retrieveMfi(currentObject, version).then((result) => {
			setOldObjectMfi(result);
		});
	};

	useEffect(() => {
		if (versionToCompare) loadOldObject(versionToCompare);
	}, [versionToCompare]);

	if (!showObjectComparisonDialog) return <></>;

	const handleSave = () => {
		setShowObjectComparisonDialog(false);
	};

	//If there is no mfi passed in retrieve the mfi
	if (!currentObjectMfi.current?.length > 0 && currentObject) {
		retrieveMfi(currentObject).then((result) => {
			currentObjectMfi.current = result;
			// setCurrentObjectMfi(result);
		});
	}
	//If there is no oldMfi passed in retrieve the object's mfi
	if (!oldObjectMfi && oldObject) {
		retrieveMfi(oldObject).then((result) => {
			setOldObjectMfi(result);
		});
	}

	return (
		//Place the following at the bottom of your Functional Component's Return
		<DraggableDialog
			id={"object-comparison-dialog"}
			style={{ minHeight: "600px", minWidth: "1200px" }}
			showDialog={showObjectComparisonDialog}
			handleClose={handleSave}
			handleSave={handleSave}
			header={"Object Comparison"}
			cancelButton={false}
			saveButtonText={"Close"}
			PaperProps={{ style: { maxWidth: "900px" } }}
		>
			<div className="btn-group" role="group" aria-label="Basic radio toggle button group">
				<input
					type="radio"
					className={`${styles.btnCheck}`}
					name="comparingobject"
					id="source-radio"
					autoComplete="off"
					checked={compareWith === SOURCE}
					onClick={() => setCompareWith(SOURCE)}
				/>
				<label className={`${styles.btn} ${styles.btnOutlinePrimary}`} htmlFor="source-radio">
					Source
				</label>

				<input
					type="radio"
					className={`${styles.btnCheck}`}
					name="comparingobject"
					id="old-version-radio"
					autoComplete="off"
					checked={compareWith === OLD_VERSION}
					onClick={() => setCompareWith(OLD_VERSION)}
				/>
				<label className={`${styles.btn} ${styles.btnOutlinePrimary}`} htmlFor="old-version-radio">
					Old Version
				</label>

				<input
					type="radio"
					className={`${styles.btnCheck}`}
					name="comparingobject"
					id="other-radio"
					autoComplete="off"
					checked={compareWith === OTHER}
					onClick={() => setCompareWith(OTHER)}
				/>
				<label className={`${styles.btn} ${styles.btnOutlinePrimary}`} htmlFor="other-radio">
					Other
				</label>
			</div>
			{compareWith === SOURCE ? "Source" : ""}
			{compareWith === OLD_VERSION ? (
				<InlineEditableElement
					text={versionToCompare}
					type="number"
					tooltip={
						"Available Version(s): " +
						(objectsVersions.length > 1 ? "1-" + (objectsVersions.length - 1) : 1)
					}
					style={{ padding: "0px 12px", fontWeight: "700", color: "blue" }}
				>
					<input
						type="number"
						name="computerVersion"
						value={versionToCompare}
						placeholder={"Available Versions 1-" + (objectsVersions.length - 1)}
						onChange={(e) => {
							const { value } = e.target;
							setVersionToCompare(value);
						}}
						//Whenever the input loses focus call the method updating the state.
						onBlur={(e) => loadOldObject(versionToCompare)}
						autoFocus
					/>
				</InlineEditableElement>
			) : (
				""
			)}
			{compareWith === OTHER ? "Other" : ""}
			<Comparison
				original={oldObjectMfi}
				suggestedChange={currentObjectMfi.current}
				newVersion={newVersion}
				updateMethod={handleItemUpdate}
				showMfis={showMfis}
			/>
		</DraggableDialog>
	);
};
