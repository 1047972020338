import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "@mui/material/Input";
import EditableLabel from "../EditableLabel/EditableLabel";
import { CloseIcon } from "../BootstrapComponents/Icons/Icons";
import { _updateRow } from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";

export const TagsInputType = ({ uuid, label, value: _value = "", updateRow }) => {
	const [value, setValue] = useState("");
	const [tagFocus, setTagFocus] = useState({});

	useEffect(() => {
		if (Array.isArray(_value)) setValue(_value.join(","));
		else setValue(_value);
	}, []);

	const update = (index, val) => {
		let splitVal = [];
		if (value) splitVal = value.split(",");

		if (index === -1) splitVal.push(val);
		else if (!val) splitVal.splice(index, 1);
		else splitVal[index] = val;

		let newVal = splitVal
			.map((str) => str.trim())
			.filter((item, index, self) => self.indexOf(item) === index)
			.join(",");
		setValue(newVal);

		updateRow(newVal);
		// _updateRow(dispatch, uuid, "objectTags", newVal);
	};

	return (
		<div className="d-flex flex-row flex-wrap">
			<span style={{ paddingRight: "12px", margin: "auto 0" }} className={""}>
				{label}
			</span>
			{value
				? value.split(",").map((tag, index) => (
						<>
							<div
								key={uuid + index}
								className={"flex"}
								style={{
									margin: "auto 0",
									padding: "2px 2px",
									marginRight: "12px",
									display: "inline-flex",
									alignContent: "center",
									justifyContent: "center",
									paddingLeft: "4px",
									paddingRight: "4px",
									borderStyle: "solid",
									borderWidth: "1px",
									borderRadius: "3px",
									fontSize: "12px",
									lineHeight: "1.84615385",
									textDecoration: "none",
									verticalAlign: "middle",
									whiteSpace: "nowrap",
									borderColor: "transparent",
									backgroundColor: "#e1ecf4",
									color: "#39739d",
									// minWidth: `${tag.length * 10}px`,
								}}
							>
								<EditableLabel
									initialValue={`${tag}`}
									changed={false}
									onFocus={(value) =>
										value
											? setTagFocus({
													uuid,
													index,
											  })
											: setTagFocus({})
									}
									onBlur={(value) => {
										update(index, value);
										setTagFocus({});
									}}
									uuid={uuid !== "emptyId" ? uuid : undefined}
									style={{
										display: "inline-flex",
										alignContent: "center",
										justifyContent: "center",
										minWidth: "0",
										paddingLeft: "4px",
										paddingRight: "4px",
										borderStyle: "solid",
										borderWidth: "1px",
										borderRadius: "3px",
										fontSize: "12px",
										lineHeight: "1.84615385",
										textDecoration: "none",
										verticalAlign: "middle",
										whiteSpace: "nowrap",
										borderColor: "transparent",
										backgroundColor: "#e1ecf4",
										color: "#39739d",
									}}
								/>

								{/*X Button*/}
								<button
									className="btn btn-link p-0"
									onClick={(e) => {
										e.stopPropagation();
										update(index, "");
									}}
								>
									<CloseIcon color="gray" />
								</button>
							</div>
						</>
				  ))
				: ""}
			<div style={{ margin: "auto 0" }}>
				<EditableLabel
					initialValue={``}
					changed={false}
					onBlur={(value) => {
						update(-1, value);
					}}
					uuid={-1}
					style={{ color: "rgb(86 86 86 / 65%)", fontSize: "14px" }}
					labelPlaceholder={"(Double click to add new)"}
				/>
			</div>
		</div>
	);
};
