/**
 * A 3 horizontal bar menu svg icon styled with bootstrap
 * @param {onClick}
 * @constructor
 */
const PlusIcon = ({ onClick }) => {
	return (
		<span className="">
			<a className={`icon-link`} style={{ textDecoration: "none", color: "#808080" }} href="#" onClick={onClick}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					fill="currentColor"
					className="bi bi-file-plus"
					viewBox="0 0 16 16"
				>
					<path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z" />
					<path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
				</svg>
			</a>
		</span>
	);
};

export default PlusIcon;
