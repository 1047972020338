import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useTrackedState } from "../../utils/store";
import { getObjectsVersion, getPossibleSourceObjects } from "../VersionControl/VersionUtils";
import DraggableDialog from "./DraggableDialog/Dialog";
import Version from "../VersionControl/Version";
import { getTopMostObject } from "../ReactGridComponents/Body/CreatorPanel/CreatorPanel";

import "./ChangeRequestSelectionDialog.scss";
import { CopyIcon, DownArrowIcon, ExclamationTriangle } from "../BootstrapComponents/Icons/Icons";
import { getSmallRef } from "../../utils/StringUtils";
import { getObjectIdAndVersionUuid, ZERO_ROW_UUID } from "../../utils/StandardObject";
import { _updateRow } from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import SelectionDialog from "./DraggableDialog/SelectionDialog/SelectionDialog";
import { getObjectGitRecord, getSingleLevelObjectMfi } from "../../utils/ApiUtils";
import { CardSelector } from "../ItemSelector/CardSelector";
import Checkbox from "@mui/material/Checkbox";
import { useClickHandler } from "../../utils/ClickHandler";
import { CHANGE_REQUEST, ChangeRequestForm } from "../ChangeRequestForm/ChangeRequestForm";

export const ChangeRequestSelectionDialog = ({ changeRequestSourceSelected }) => {
	const [possibleInstances, setPossibleInstances] = useState([]);
	const [possibleTemplates, setPossibleTemplates] = useState([]);
	const [otherOptions, setOtherOptions] = useState([]);
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(null);
	const [showDataWarehouse, setShowDataWarehouse] = useState(false);
	const [showChangeRequestForm, setShowChangeRequestForm] = useState(false);

	const topObject = useRef({});
	const changeRequestFormMfi = useRef([]);
	const changedRows = useRef([]);
	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		topObject.current = getTopMostObject(sharedState);
		//get the possible sources
		loadSources();
	}, [sharedState.contextTop.uuid, sharedState.contextTop.versionUuid]);

	useEffect(() => {
		if (sharedState.changeRequestSelectionDialog) setOpen((prev) => !prev);
	}, [sharedState.changeRequestSelectionDialog]);

	useEffect(() => {}, [showChangeRequestForm]);

	const handleSave = async (object) => {
		if (!object && selected?.value === "Data Warehouse") {
			setShowDataWarehouse(true);
			// setOpen(false);
			return;
		}
		// if (!showChangeRequestForm) {
		// 	await getChangeRequestMfi();
		// 	setShowChangeRequestForm(true);
		// 	return;
		// }
		changeRequestSourceSelected(object || selected, [changeRequestFormMfi.current[0]], changedRows.current);
		handleClose();
	};

	const { handleClick } = useClickHandler(
		(row) => {
			setSelected(row);
		},
		(row) => handleSave(row)
	);

	let getChangeRequestMfi = async () => {
		if (changeRequestFormMfi.current.length == 0) {
			dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
			changeRequestFormMfi.current = await getSingleLevelObjectMfi(
				sharedState.dbConstants.changeRequestForm?.referenceUuid,
				null,
				null,
				null
			);
			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			defaultChangeRequest();
		}
	};

	const loadSources = async () => {
		let { instances, templates } = await getPossibleSourceObjects(sharedState);
		let others = [];

		instances = instances.map((instance) => {
			instance.onClick = handleClick;
			if (instance.newerVersion)
				instance.secondaryClick = (e) => reviewLatestVersionClicked(e, instance.newerVersion);
			return instance;
		});
		templates = templates.map((template) => {
			template.onClick = handleClick;
			if (template.newerVersion)
				template.secondaryClick = (e) => reviewLatestVersionClicked(e, template.newerVersion);
			return template;
		});

		//Add the two additional options for
		others.push({
			hover: "Search for an object in the Data Warehouse",
			title: "An Object in the Data Warehouse",
			value: "Data Warehouse",
			onClick: (row) => {
				setShowDataWarehouse(true);
			},
		});

		setPossibleTemplates(templates);
		setPossibleInstances(instances);
		setOtherOptions(others);
	};

	const handleClose = () => {
		setShowDataWarehouse(false);
		setShowChangeRequestForm(false);
		setOpen(false);
		changeRequestFormMfi.current = [];
		changedRows.current = [];
	};

	const reviewLatestVersionClicked = (e, newerVersion) => {
		e.stopPropagation();
		let url = `?uuid=${newerVersion.uuid}&versionUuid=${newerVersion.versionUuid}${
			newerVersion.objectHierarchy[0] &&
			newerVersion.objectHierarchy[0].ancestorStandardObjectUuid !== ZERO_ROW_UUID
				? `&path=${newerVersion.objectHierarchy[0].pathEnum}`
				: ""
		}`;

		//Open the newer version in a new tab
		window.open(url, "_blank");
	};

	const defaultChangeRequest = () => {
		let changeRequestRow = changeRequestFormMfi.current[0];
		let ancestorUuidAndVersion = getObjectIdAndVersionUuid(changeRequestRow);

		//Set the change request title
		let row = retrieveItemFromChangeRequest(CHANGE_REQUEST.DOCUMENT_TITLE_ORIGINATION);
		row.value = selected.title;
		row.ancestorUuidAndVersion = ancestorUuidAndVersion;
		updateChangeRequestForm(row);

		//Set the item's reference manual number
		row = retrieveItemFromChangeRequest(CHANGE_REQUEST.DOCUMENT_REFERENCE_MANUAL_MFI);
		row.value = selected.mfiReference || selected.reference;
		row.ancestorUuidAndVersion = ancestorUuidAndVersion;
		updateChangeRequestForm(row);

		//Set the item's version number
		row = retrieveItemFromChangeRequest(CHANGE_REQUEST.DOCUMENT_VERSION_NUMBER);
		row.value = getObjectsVersion(selected);
		row.ancestorUuidAndVersion = ancestorUuidAndVersion;
		updateChangeRequestForm(row);

		//Set the item's revision date
		row = retrieveItemFromChangeRequest(CHANGE_REQUEST.DOCUMENT_REVISION_DATE);
		row.value = selected.logRecord?.createdAt;
		row.ancestorUuidAndVersion = ancestorUuidAndVersion;
		updateChangeRequestForm(row);

		//Set the item's opened by
		row = retrieveItemFromChangeRequest(CHANGE_REQUEST.OPENED_REQUESTED_BY);
		row.value = (sharedState.currentUser?.firstName + " " || "") + (sharedState.currentUser?.lastName || "");
		row.ancestorUuidAndVersion = ancestorUuidAndVersion;
		updateChangeRequestForm(row);

		//Set the item's date opened
		row = retrieveItemFromChangeRequest(CHANGE_REQUEST.DATE_OPENED);
		row.value = Date.now();
		row.ancestorUuidAndVersion = ancestorUuidAndVersion;
		updateChangeRequestForm(row);

		//Set the item's email
		row = retrieveItemFromChangeRequest(CHANGE_REQUEST.EMAIL);
		row.value = sharedState.currentUser.email;
		row.ancestorUuidAndVersion = ancestorUuidAndVersion;
		updateChangeRequestForm(row);
	};

	const retrieveItemFromChangeRequest = (attribute) => {
		return changeRequestFormMfi.current.find((row) => row.title === attribute);
	};

	const updateChangeRequestForm = (row) => {
		let list = changedRows.current;
		// Check if the object is already in the list
		const index = list.findIndex((obj) => obj.uuid === row.uuid);

		if (index !== -1) {
			// Object is in the list, update it
			list[index] = { ...list[index], ...row };
		} else {
			// Object isn't in the list, add it
			list.push(row);
		}

		changedRows.current = list;
	};

	//Add search for data warehouse object
	//Add a general option

	return (
		<>
			{!showDataWarehouse && !showChangeRequestForm ? (
				<DraggableDialog
					style={{ minHeight: "600px", minWidth: "1200px" }}
					PaperProps={{ style: { minWidth: "900px", width: "75%" } }}
					header={"Suggest a Change to"}
					showDialog={open}
					handleClose={handleClose}
					handleSave={handleSave}
					saveButtonText={"Choose"}
					disableSave={!selected}
				>
					{/*<div style={{ display: "flex", justifyContent: "start" }}>*/}
					{/*{possibleTemplates.length > 1 ? <label><Checkbox/>Template</label> : ''}*/}
					{possibleInstances.length > 0 ? (
						<>
							<p>Open Objects</p>
							<CardSelector items={possibleInstances} />
						</>
					) : (
						""
					)}
					{possibleInstances.length > 0 && (possibleTemplates.length > 0 || otherOptions.length > 0) ? (
						<hr />
					) : (
						""
					)}
					{possibleTemplates.length > 0 ? (
						<>
							<p>Template</p>
							<CardSelector items={possibleTemplates} />
						</>
					) : (
						""
					)}
					{possibleTemplates.length > 0 && otherOptions.length > 0 ? <hr /> : ""}
					{otherOptions.length > 0 && (possibleTemplates.length > 0 || possibleInstances.length > 0) ? (
						<p>Other</p>
					) : (
						""
					)}
					{otherOptions.length > 0 ? <CardSelector items={otherOptions} /> : ""}
					{/*</div>*/}
				</DraggableDialog>
			) : showDataWarehouse && !showChangeRequestForm ? (
				<SelectionDialog
					dialogTitle={"Suggest a change to an object in the data warehouse"}
					treeData={sharedState.destinationModel}
					treeTitle={"Data Warehouse"}
					open={open}
					submitButtonText={"Choose"}
					rowSelected={async (row) => {
						if (!row) return;

						//Verify we have a standardObjectUuid and standardObjectVersionUuid
						if (row?.uuid && row?.standardObjectUuid && row?.standardObjectVersionUuid) {
							setSelected(row);
						}

						row = await getObjectGitRecord(row.standardObjectUuid, row.standardObjectVersionUuid);

						handleSave(row);
					}}
				/>
			) : (
				<DraggableDialog
					style={{ minHeight: "600px", minWidth: "1200px" }}
					PaperProps={{ style: { minWidth: "900px", width: "75%" } }}
					header={"Describe your suggested change"}
					showDialog={open}
					handleClose={handleClose}
					handleSave={handleSave}
					saveButtonText={"Create"}
					disableSave={!selected}
				>
					<ChangeRequestForm
						updateRow={updateChangeRequestForm}
						changeRequestFormMfi={changeRequestFormMfi.current}
					/>
				</DraggableDialog>
			)}
		</>
	);
};
