import { useState, useEffect, useRef, forwardRef, useCallback, useMemo } from "react";
import ReactGridLayout from "react-grid-layout";
import { withSize } from "react-sizeme";
import "./ReactGrid.scss";
import { useArray } from "../../utils/useStickyArray";
import { useTrackedState } from "../../utils/store";

export const gridColumns = 840;

/**
 * Renders a react grid with children
 * @param {layout: _layout = [], cols = 1000, onNestedLayoutChange: _onNestedLayoutChange, itemKey, size, dynamicHeight, ...other}
 * @constructor
 */
const ReactGrid = forwardRef(
	(
		{
			layout: _layout = [],
			onNestedLayoutChange: _onNestedLayoutChange,
			itemKey,
			size,
			dynamicHeight,
			staticY = false,
			allowResize: _allowResize,
			styling = {},
			layoutId,
			id,
			...other
		},
		ref
	) => {
		//state variables
		const { array: layout, set: setLayout } = useArray([]);
		const layoutPropMap = useRef(new Map());
		const [gridWidth, setGridWidth] = useState(0);
		// const [allowResize, setAllowResize] = useState(false);

		const allowResize = useRef(false);
		const margin = useRef([0, 0]);
		const gridStyling = useRef({ height: "100%", width: "100%", ...styling });
		const layoutAndSizeRef = useRef({});

		const sharedState = useTrackedState();

		//TODO: In a ref variable I need to store everything so I can verify if something really changed that makes so I need to recalculate the layout

		useEffect(() => {
			if (_allowResize !== undefined) allowResize.current = _allowResize;
			else allowResize.current = sharedState.resizePanels;

			if (allowResize.current) {
				let newLayout = layout.map((item) => ({
					...item,
					isResizable: layoutPropMap.current.get(item.i)?.resizable,
				}));
				setLayout([...newLayout]);
			} else {
				let newLayout = layout.map((item) => ({ ...item, isResizable: false }));
				setLayout(newLayout);
			}
		}, [_allowResize, sharedState.resizePanels]);

		//Other Methods
		useEffect(() => {
			let newLayout = buildLayout(_layout);
			if (newLayout) setLayout(newLayout);
		}, [_layout]);

		useEffect(() => {
			setGridWidth(size.width);
			let gridItems = [...layoutPropMap.current.values()];
			let newLayout = buildLayout(gridItems);
			if (newLayout) setLayout(newLayout);
		}, [size.height, size.width]);

		const cacheLayout = (layout) => {
			// if(allowResize)
			if (layoutId) localStorage[`grid-layout-${layoutId}`] = JSON.stringify(layout);
			else localStorage[`grid-layout-${layout.map((item) => item.i).join(",")}`] = JSON.stringify(layout);
		};

		const getCachedLayout = () => {
			let layoutString;
			if (layoutId) layoutString = localStorage[`grid-layout-${layoutId}`];
			else layoutString = localStorage[`grid-layout-${_layout.map((item) => item.i).join(",")}`];

			if (layoutString) return JSON.parse(layoutString);
			else return undefined;
		};

		const updateLayoutPropMap = (layout) => {
			layoutPropMap.current = new Map();
			layout.forEach((item, index) => {
				layoutPropMap.current.set(item.i, { ...item, index });
			});
		};

		const layoutItemDiff = (a, b) => {
			if (!a || !b) return true;
			if (a.x !== b.x) return true;
			else if (a.y !== b.y) return true;
			else if (a.w !== b.w) return true;
			else if (a.h !== b.h) return true;
			return false;
		};

		const compareLayouts = (layout1, layout2) => {
			//Might be better to convert to a map? That way the order wouldn't matter?
			if (layout1.length === layout2.length) {
				let sameLayouts = true;
				for (let i in layout1) {
					let item = layout1[i];
					let diff = layoutItemDiff(item, layout2[i]);
					if (diff) {
						return false;
					}
				}

				if (sameLayouts && gridWidth === size.width) return true;
			}

			return false;
		};

		const resizableNotSet = (layout) => {
			let difference = false;
			for (let i in layout) {
				let item = layout[i];
				let resizable = allowResize.current && layoutPropMap.current.get(item.i)?.resizable;
				if (item.isResizable !== resizable) {
					difference = true;
					break;
				}
			}
			return difference;
		};

		/**
		 * Builds the new layout from the one passed in taking into account dynamic grid items
		 * @param _layout
		 */
		const buildLayout = (_layout) => {
			//Check if we have a valid layout
			let arr = [];
			if (_layout && _layout.length > 0) {
				// if (_layout && _layout.length > 0 && size.height) {
				//Check against the ref and if everything is the same don't return a new layout
				if (
					layoutAndSizeRef.current.height === size.height &&
					layoutAndSizeRef.current.width === size.width &&
					layoutAndSizeRef.current.layoutLength === _layout.length
				) {
					let sameLayouts = true;
					for (let i in _layout) {
						let item = _layout[i];
						let diff = layoutItemDiff(item, layoutPropMap.current.get(item.i));
						if (diff) {
							sameLayouts = false;
							break;
						}
					}

					if (sameLayouts && gridWidth === size.width) {
						// console.log("allowResize", allowResize, 'allowResize.current', allowResize.current);
						//Check if allowResize changed
						// if(resizableNotSet(layout))
						// {
						// 	// layout.forEach(item => arr.push({...item, isResizable: allowResize.current && layoutPropMap.current.get(item.i).resizable}));
						// 	// return arr;
						// }
						// else
						return undefined;
					}
				}

				layoutAndSizeRef.current = { ...size };
				layoutAndSizeRef.current.layoutLength = _layout.length;
				updateLayoutPropMap(_layout);

				let cachedLayout = getCachedLayout();
				if (cachedLayout) {
					//This should only return true if the length is the same and there is a one to one
					if (cachedLayout.length === _layout.length) {
						//Sometimes a grid item bugs out and its size turns to 1 when it shouldn't, if the cached layout has this, rebuild the layout rather than using the cached one.
						let buggedGridItems = cachedLayout.filter((row) => row.w === 1 && row.h === 1);
						let matchingLayouts = compareLayouts(cachedLayout, _layout);

						if (buggedGridItems.length < 1 && matchingLayouts) {
							if (allowResize.current)
								cachedLayout.forEach(
									(item) => (item.isResizable = layoutPropMap.current.get(item.i).resizable)
								);
							else cachedLayout.forEach((item) => (item.isResizable = false));
							return cachedLayout;
						}
					}
				}

				//TODO: I'll assume that the height is always calculated as a fraction of the available height, should I adjust dynamic siblings as i change?
				//If any siblings are dynamic, do I need to update their height as any element changes? Even the ones that aren't dynamic?

				//Search cachedlayout for a match for each _layout item
				// cachedLayout.forEach(layoutElement => {
				//     let matchingElement = _layout.find(i => i.i === layoutElement.i);
				//     if (matchingElement) {
				//         matchingElement.x = layoutElement.x;
				//         matchingElement.y = layoutElement.y;
				//         // matchingElement.h = layoutElement.h;
				//         matchingElement.w = layoutElement.w;
				//     }
				// });

				//Array of items we need to calculate later
				let dynamicItems = [];
				let availableHeightForDynamicItems = size.height;
				//Each item's height and y properties will be a percentage, we need to convert it to the correct # of pixels on the screen
				_layout.forEach((item, index) => {
					//Check if we have this item in the cache, use it if we do.
					let cacheMatch = cachedLayout?.find((row) => row.i === item.i);
					if (cacheMatch && cacheMatch.w !== 0 && cacheMatch.h !== 0) {
						arr.push({
							x: cacheMatch.x,
							y: cacheMatch.y,
							h: cacheMatch.h,
							w: cacheMatch.w,
							i: cacheMatch.i,
							isResizable: (allowResize.current && item.resizable) || false,
						});
					}
					//Otherwise calculate the dimensions
					else {
						let y, height;
						if (dynamicHeight) {
							height = size.height * item.h;
							if (!item.staticY) {
								y = size.height * item.y;
							}
						} else if (item.dynamicHeight) {
							dynamicItems.push({ ...item, index });
							return;
						} else {
							height = item.h;
							y = item.y;
							availableHeightForDynamicItems -= height;
						}

						if (item.w !== 0 && item.h !== 0)
							arr.push({
								x: item.x,
								y,
								h: height,
								w: item.w || gridColumns,
								i: item.i,
								isResizable: (allowResize.current && item.resizable) || false,
							});
					}
				});

				//If there are any dynamic items, calculate what their height would be.
				if (dynamicItems.length > 0) {
					//Iterate over each dynamic item an calculate what its height would be in respect to the available height
					dynamicItems.forEach((item) => {
						//How do I calculate the height for each item?
						//Height would be equal to the fraction * available height - any gaps that are there. How do I calculate the gap heights?
						//For now assume that the layout will be structured a certain way:
						//(If the body section contains multiple grids to stuff inside it that are next to each other, assume there will be a body grid item that be a grid itself holding these grid items).
						//May be able to change this later, but just do this for now.

						//If y is really static I should be able to go off of that and wouldn't even need the previous sibling to figure it out.
						//My height would be the
						let height = availableHeightForDynamicItems * item.h;
						let itemY = item.y;
						if (!item.staticY) {
							itemY = availableHeightForDynamicItems * item.y;
						}
						layoutPropMap.current.set(item.i, { ...item, calculatedHeight: height });
						arr.push({
							x: item.x,
							y: itemY,
							h: height,
							w: item.w,
							i: item.i,
							isResizable: item.resizable || false,
						});
					});
				}
			}
			return arr;
		};

		/**
		 * Triggered when an element is dragged to a different spot
		 * @param layout, oldItem, newItem,
		 */
		const onDragStop = useCallback((layout, oldItem, newItem) => {
			let arr = [];

			let itemInMap = layoutPropMap.current.get(newItem.i);
			let y;
			//Do I need to do anything here? The things changing are the x and y values. Y is only dynamic if dynamicHeight is passed in
			if (dynamicHeight) {
				y = newItem.y;
				if (!oldItem.staticY) {
					y = newItem.y / size.height;
				}
				itemInMap.calculatedY = y;
			} else y = newItem.y;

			layoutPropMap.current.set(newItem.i, { ...itemInMap, x: newItem.x, y });

			arr.push({
				uuid: newItem.i,
				y,
				x: newItem.x,
				updateMultipleSubAttributes: true,
			});

			let buffer = new ArrayBuffer(layout.length);
			let data = {
				eventType: "PUT",
				buffer,
				update: arr,
			};

			//I think we only needed this for the library we were using in the workspace?
			// window.top.postMessage(data, "*", [data.buffer]);

			setLayout(layout);
			cacheLayout(layout);
		}, []);

		/**
		 *
		 * @param layout, oldItem, newItem, placeholder, e
		 */
		const onResizeStop = useCallback((layout, oldItem, newItem, placeholder, e) => {
			let arr = [];
			//Array to store the new data
			let itemInMap = layoutPropMap.current.get(newItem.i);

			//Calculate the new items height and y as a percentage:
			let height;
			//Can I just rely on its height in relation to full height available?
			if (dynamicHeight) {
				//Do I need to update the height for siblings as well?
				height = newItem.h / size.height;
			} else if (itemInMap.dynamicHeight) {
				//Get the items
				let items = [...layoutPropMap.current.values()];

				//Calculate the heights of the static items and dynamic items to see how much height is available for the dynamic items
				let staticHeights = items.filter((row) => !row.dynamicHeight);
				let dynamicHeights = items.filter((row) => row.dynamicHeight && row.i !== newItem.i);

				let availableDynamicHeight = size.height;
				staticHeights.forEach((staticItem) => (availableDynamicHeight -= staticItem.h));

				let availableHeightForThisItem = availableDynamicHeight;
				dynamicHeights.forEach(
					(dynamicItem) => (availableHeightForThisItem -= dynamicItem.h * availableDynamicHeight)
				);

				//Calculate the percentage based on the height that is available for this item.
				height = newItem.h / availableHeightForThisItem;
			} else height = newItem.h;

			layoutPropMap.current.set(newItem.i, { ...itemInMap, w: newItem.w, h: height });

			arr.push({
				uuid: newItem.i,
				width: newItem.w,
				height,
				updateMultipleSubAttributes: true,
			});

			let buffer = new ArrayBuffer(layout.length);
			let data = {
				eventType: "PUT",
				buffer,
				update: arr,
			};

			//I think we only needed this for the library we were using in the workspace?
			// window.top.postMessage(data, "*", [data.buffer]);

			setLayout(layout);
			cacheLayout(layout);
		}, []);

		/**
		 * Triggered when the layout changes
		 * @param newLayout
		 */
		const onLayoutChanged = useCallback((newLayout) => {
			if (!newLayout || newLayout.length < 1) return;

			// setLayout(newLayout);
			// if (_onNestedLayoutChange) {
			//     _onNestedLayoutChange(newLayout, itemKey);
			// }
		}, []);

		return (
			<ReactGridLayout
				className="layout"
				layout={layout}
				onLayoutChange={onLayoutChanged}
				onDragStop={onDragStop}
				onResizeStop={onResizeStop}
				useCSSTransforms={true}
				measureBeforeMount={true}
				allowOverlap={false}
				preventCollision={true}
				rowHeight={1}
				cols={gridColumns}
				rows={6}
				autoSize={true}
				margin={margin.current}
				width={gridWidth}
				resizeHandles={["e", "se", "s"]}
				draggableHandle={".panelHeader"}
				{...other}
				isDraggable={other.draggable || false}
				isResizable={other.resizable || false}
				style={gridStyling.current}
				ref={ref}
				compactType={null}
			>
				{other.children}
			</ReactGridLayout>
		);
	}
);

export default withSize({ monitorHeight: true })(ReactGrid);
