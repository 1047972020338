import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { postCall, getUrl, getCall } from "./ApiUtils";
import { Navigate } from "react-router-dom";
//Retrieves the access cookie, and refresh cookie
export const getAccessToken = () => Cookies.get("access-token");

//If there is a result returned from the getAccessToken we are authenticated
export const isAuthenticated = () => !!getAccessToken();

//If there is a refresh token, try getting a new access token
// export const authenticate = async () => {
//     if(getAccessToken()) {
//         try {
//             const tokens = await refreshTokens(); //call an API, returns tokens
//
//             setTokenCookie(tokens);
//
//             return true;
//         } catch (error) {
//             return false;
//         }
//     }
// }

export function setTokenCookie({ token }) {
	if (!token) Cookies.remove("access-token");
	else Cookies.set("access-token", token, { expires: getTokenExpirationDate(token) });
}

export async function getUserInfo() {
	let email = getTokenEmail();
	if (!email) return undefined;

	let userInfo = await getCall(getUrl("getUserInfo", [email]));
	return userInfo;
}

export const getToken = async (email, password) => {
	return postCall(getUrl("login"), { email, password })
		.then((result) => {
			return result.token;
		})
		.catch((e) => {
			throw new Error(e.message);
		});
};

export const clearTokenCookie = () => {
	setTokenCookie({});
	window.location.assign("/");
};

export const register = async (email, password, firstName, lastName) => {
	await postCall(getUrl("register"), { email, password, firstName, lastName }, true)
		.then((result) => {
			setTokenCookie(result);
		})
		.catch((e) => {
			throw new Error(e.message);
		});
};

const updatePassword = async (email, oldPassword, newPassword) => {
	await postCall(getUrl("updatePassword"), { email, oldPassword, newPassword }).then((result) => {
		setTokenCookie(result);
	});
};

const requestResetToken = async (email) => {
	return await postCall(getUrl("forgotPassword"), { email }, true);
};

const sendPasswordReset = async (email, resetToken, newPassword) => {
	return await postCall(getUrl("resetPassword"), { email, resetToken, newPassword }, true);
};

const tokenIsValid = (token) => {
	const tokenExpirationDate = getTokenExpirationDate(token);
	if (tokenExpirationDate === undefined) {
		return false;
	}
	return tokenExpirationDate.valueOf() > new Date().valueOf();
};

export const RequireAuth = ({ children, redirectTo }) => {
	return isAuthenticated() ? children : <Navigate to={redirectTo} />;
};

//Decode the token and return the email
const getTokenEmail = () => {
	const decodedToken = jwt_decode(getAccessToken());
	const tokenEmail = decodedToken.sub;
	if (tokenEmail && tokenEmail.length !== 0) {
		return tokenEmail;
	}

	return "";
};

//Decode the token and return the expiration date
const getTokenExpirationDate = (token) => {
	const decodedJWT = jwt_decode(token);

	if (decodedJWT.exp === undefined || !decodedJWT.exp) {
		return null;
	}

	const date = new Date(0);
	date.setUTCSeconds(decodedJWT.exp);
	return date;
};
