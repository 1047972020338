import React, { useState, useEffect, useRef } from "react";
import Panel from "../../Panel/Panel";
import ReactGrid from "../../../ReactGrid/ReactGrid";
import {
	getCall,
	getDataWarehouseAndSpecial,
	getObjectMfi,
	getObjectsOpenPoints,
	getSingleLevelObjectMfi,
	getUrl,
	getObjectDTOByUuidAndVersion,
	saveOpenPoints,
	getObjectGitRecord,
	repairObjectReferences,
} from "../../../../utils/ApiUtils";
import { convertListToLeavesWithOwner, findOwner, listToTree, uuidToAncestors } from "../../../../utils/TreeUtils";
import { useDispatch, useTrackedState } from "../../../../utils/store";
import { getUntrackedObject } from "react-tracked";
import { useIndexedDB } from "@slnsw/react-indexed-db";
import {
	addChangesToMfi,
	checkForSubObject,
	getObjectIdAndVersionUuid,
	loadSubObject,
	updateRowsThatReferenceThisRow,
	ZERO_ROW_UUID,
	getChangesForObject,
	getStateChanges,
	getGlobalObjectHierarchy,
	linkAttributeName,
	loadRelatedObject,
	getAssociatedObjectType,
	buildHierarchyRecordFromPath,
	FILTERED_OBJECT_GENERAL_TYPE,
} from "../../../../utils/StandardObject";
import { objectWorkspacePanels } from "../../../../utils/Panel";
import RecursiveTreeView from "../../../Tree/Tree";
import { INPUT_FIELD_SOURCES, INPUT_SOURCE_ATTRIBUTE, SETUP_TYPES } from "../../../../utils/SetupTypes";
import { Breadcrumbs } from "../../SetupSheetWindow/SetupSheetWindow";
import VisualRepPanel from "../../VisualRepPanel/VisualRepPanel";
import { BinderIcon, PlusIcon, SuggestionIcon } from "../../../BootstrapComponents/Icons/Icons";
import DraggableDialog from "../../../Dialog/DraggableDialog/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { dataObjects } from "../../../../utils/DataObject";
import { checkErrors } from "../../../../utils/ObjectProperties";
import Reference from "../../../Reference/Reference";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import OpenPoints from "../../../OpenPointForm/OpenPoints";
import { _removeOverlay } from "../../../PanelOverlay/PanelOverlay";
import SetupSheetPanel from "../SetupSheetPanel/SetupSheetPanel";
import Version from "../../../VersionControl/Version";

import {
	allowedToEditHarvestedObjects,
	checkIfUuidAndVersionIsAlreadyLoaded,
	getPacketObjectDbConst,
	getTopMostObject,
	getUrlParams,
} from "../CreatorPanel/CreatorPanel";
import "./NewModifiedWorkspacePanel.scss";
import { useSearchParams } from "react-router-dom";
import DiffTree from "../../../Tree/DiffTree";
import { getChangeRequestPacketConstUuid, getPacketConstUuid } from "../../../../utils/DbConstantsUtils";
/**
 * Renders a panel with a grid inside of it
 * @param
 * @constructor
 */
const NewModifiedWorkspacePanel = ({ params }) => {
	//state variables
	//TODO: I think a lot of this should be separated out into separate components, we can do this later after all the functionality is here
	//The selected row in the tree, used to populate properties form on the right
	const [top, setTop] = useState({});
	const [diff, setDiff] = useState();

	//Reference Variables
	const packetObjectUuid = useRef();
	const changeRequestObjectUuid = useRef();
	const oldMfiRef = useRef([]);
	//TODO: I don't think we need this anymore? Doesn't look like it changes anything

	//Global state used to store the existing objects that can be shared across components
	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	const [queryParams, setQueryParams] = useSearchParams();
	const { getAll: getHistory, add: addToHistory, deleteRecord: deleteFromHistory } = useIndexedDB("history");
	const {
		getAll: getAttachmentPoints,
		add: addAttachmentPoint,
		clear: clearAttachmentPoints,
		update: updateAttachmentPoint,
		deleteRecord: deleteAttachmentPoint,
	} = useIndexedDB("attachmentPoints");

	//useEffects(Lifecycle Methods)
	useEffect(() => {
		getChangeRequestPacketConstUuid(sharedState, dispatch).then((res) => (changeRequestObjectUuid.current = res));
		getPacketConstUuid(sharedState, dispatch).then((res) => (packetObjectUuid.current = res));
	}, []);

	useEffect(() => {
		let { contextTop, contextMfi } = sharedState;
		//If there is a top run the diff
		if (contextTop?.uuid) {
			if (contextTop.compareWithUuid && contextTop.compareWithVersionUuid)
				calculateDiff(contextTop.compareWithUuid, contextTop.compareWithVersionUuid, contextMfi);
			else if (contextTop.uuid && contextTop.versionUuid)
				calculateDiff(contextTop.uuid, contextTop.versionUuid, contextMfi);
		} else {
			oldMfiRef.current = [];
			setDiff(undefined);
		}

		setTop(sharedState.contextTop);
	}, [sharedState.contextMfiVersion, sharedState.contextTop?.uuid, sharedState.contextTop?.versionUuid]);

	//Other Methods
	const calculateDiff = async (uuid, versionUuid, currentMfi) => {
		if (
			!oldMfiRef.current ||
			oldMfiRef.current.length < 1 ||
			oldMfiRef.current[0].uuid !== uuid ||
			oldMfiRef.current[0].versionUuid !== versionUuid
		)
			oldMfiRef.current = await getSingleLevelObjectMfi(uuid, versionUuid);

		//Remove the preprended reference if this is a sub-object
		let topRef = sharedState.contextTop.reference;
		let newCurrent = [];
		if (topRef && topRef !== "0") {
			currentMfi.forEach((row) =>
				newCurrent.push({ ...row, reference: row.reference.replace(topRef + ".", "") })
			);
			newCurrent[0].reference = "0";
		} else newCurrent = currentMfi;
		let diff = DiffTree(newCurrent, oldMfiRef.current);
		setDiff(diff);
	};

	//Version Control stuff
	const loadPreviousVersion = async () => {
		let { contextTop: top } = sharedState;

		dispatch({
			type: "SET_LOAD_OBJECT",
			data: { uuid: top.uuid, versionUuid: top.versionUuid, computerVersion: top.computerVersion - 1 },
		});
	};

	const loadNextVersion = async () => {
		let { contextTop: top } = sharedState;

		dispatch({
			type: "SET_LOAD_OBJECT",
			data: { uuid: top.uuid, versionUuid: top.versionUuid, computerVersion: top.computerVersion + 1 },
		});
	};

	const loadSpecificVersion = async (version) => {
		let { contextTop: top } = sharedState;

		dispatch({
			type: "SET_LOAD_OBJECT",
			data: { uuid: top.uuid, versionUuid: top.versionUuid, computerVersion: version },
		});
	};

	/**
	 * When a breadcrumb is clicked I'm back up the Object Hierarchy, I need to grab the MFI for the object clicked,
	 *  grab previous changes that need applied to it, set it as the context and MFI.
	 *  Ensure the fullObjectHierarchy is updated correctly.
	 * @param uuid
	 * @returns {Promise<void>}
	 */
	const breadcrumbClicked = async (uuid) => {
		let { contextAncestorObjects: ancestorObjects } = sharedState;
		//Base everything off of Model, App, Object Workspace IDE view. This will need to change once we are doing things from multiple views
		let view = sharedState.openView.title;
		let standardObjectTitle = dataObjects.STANDARD_OBJECT_GIT.title;
		let topUuidAndVersion = getObjectIdAndVersionUuid(ancestorObjects[0]);

		//Get the corresponding row from the sharedState's loaded ancestors
		let ancestorRow = ancestorObjects.find((row) => row.uuid === uuid);
		let ancestorIndex = ancestorObjects.findIndex((row) => row.uuid === uuid);
		let ancestorRowUuidAndVersion = getObjectIdAndVersionUuid(ancestorRow);

		let body;

		let hierarchyRecord;
		if (sharedState.contextFullObjectHierarchy && sharedState.contextFullObjectHierarchy.length > 0) {
			hierarchyRecord = checkForSubObject(ancestorRow, sharedState.contextFullObjectHierarchy);
			if (hierarchyRecord && hierarchyRecord.ancestorStandardObjectUuid !== ZERO_ROW_UUID)
				body = { ...ancestorRow, objectHierarchy: [hierarchyRecord] };
		}

		if (!hierarchyRecord) {
			let attachmentPoints = await getAttachmentPoints();
			if (attachmentPoints.length > 0) {
				let attachmentPoint = attachmentPoints.find((row) => row.objectId === uuid);

				if (attachmentPoint) {
					let { path } = getUrlParams(queryParams);
					//Remove extra part of path
					let split = path.split(".");
					let indexToEndAt = split.findIndex(
						(row) =>
							row ===
							getObjectIdAndVersionUuid({
								uuid: attachmentPoint.uuid,
								versionUuid: attachmentPoint.versionUuid,
							})
					);
					split = split.slice(0, indexToEndAt + 1);
					hierarchyRecord = buildHierarchyRecordFromPath(split.join("."));
					body = {
						...ancestorRow,
						...attachmentPoint,
						objectHierarchy: [hierarchyRecord],
					};
				}
			}
		}

		//Grab the mfi for the uuid clicked
		let mfi = await getSingleLevelObjectMfi(ancestorRow.uuid, ancestorRow.versionUuid, dispatch, null, body);

		let changes;
		let referenceToPrepend;

		//Check if we have made any changes to any rows stored in the state
		if (
			sharedState.changedData &&
			sharedState.changedData[view] &&
			sharedState.changedData[view][standardObjectTitle] &&
			sharedState.changedData[view][standardObjectTitle][topUuidAndVersion]
		) {
			changes =
				sharedState.changedData[view][standardObjectTitle][topUuidAndVersion]?.objects[
					ancestorRowUuidAndVersion
				];
			if (changes) {
				Object.values(changes.mfi).forEach((row) => {
					if (body) referenceToPrepend = body.reference;
				});
			}
		}

		mfi = addChangesToMfi(mfi, changes, referenceToPrepend);

		//Update the ancestorObjects array to end right before the object we clicked on
		ancestorObjects = ancestorObjects.slice(0, ancestorIndex);

		//Get the changed hierarchygetStateChanges
		let { objectHierarchy: hierarchyChanges } = getStateChanges(sharedState, topUuidAndVersion);

		//Adding the new object's hierarchy (an object not yet saved) to the returned object's object hierarchy
		//When you have added a new sub-object it creates a new object hierarchy record (not in the database)
		//When you navigate into the sub-object and go back to the parent, we need to re-add it to the call's objectHierarchy list
		if (hierarchyChanges?.length > 0) {
			let directDescendants = hierarchyChanges.filter(
				(row) =>
					row.ancestorStandardObjectUuid === mfi[0].uuid &&
					row.ancestorStandardObjectVersionUuid === mfi[0].versionUuid
			);
			let tempObjectHierarchy = {};
			if (mfi[0].objectHierarchy && mfi[0].objectHierarchy.length > 0) {
				//Convert to a map to remove duplicates
				mfi[0].objectHierarchy.forEach((row) => (tempObjectHierarchy[row.uuid] = row));
				directDescendants.forEach((row) => (tempObjectHierarchy[row.uuid] = row));
				tempObjectHierarchy = Object.values(tempObjectHierarchy);
			} else tempObjectHierarchy = directDescendants;

			mfi[0].objectHierarchy = tempObjectHierarchy;
		}

		//Update the current related object
		let currentRelatedObject;
		let relatedObjects = ancestorObjects.filter(
			(row) => row.generalTypeUuid === getAssociatedObjectType(sharedState)
		);
		if (relatedObjects.length > 0) currentRelatedObject = relatedObjects[relatedObjects.length - 1];

		//Set the mfi in the sharedState
		dispatch({
			type: "SET_CONTEXT_OR_MFI",
			data: {
				top: mfi[0],
				mfi,
				ancestorObjects,
				currentRelatedObject,
				resetContextMfiVersion: true,
			},
		});

		//Update the queryParams
		let top = mfi[0];
		let update = {
			uuid: top.uuid,
			versionUuid: top.versionUuid,
		};
		if (hierarchyRecord && hierarchyRecord.ancestorStandardObjectUuid !== ZERO_ROW_UUID) {
			update.path = hierarchyRecord?.pathEnum;
			update.ref = top.reference;
		}
		setQueryParams(update);
	};

	const layoutWithObjectMFI = [
		//Object Mfi Panel
		{
			x: 0,
			y: 0,
			h: 1,
			w: 233,
			i: "8dac42e4-31be-4967-9a93-56f9a3d3ca93",
			dynamicHeight: true,
			resizable: true,
			isBounded: true,
		},
		//Setup Sheet panel
		{
			x: 234,
			y: 0,
			h: 1,
			w: 371,
			i: "6031cf44-4369-4731-9301-d2bbfdf6209d",
			dynamicHeight: true,
			resizable: true,
			isBounded: true,
		},
		//Visual Presentation Panel
		{
			x: 605,
			y: 0,
			h: 1,
			w: 235,
			i: "ecb44f34-a589-4d86-878d-419afb517051",
			dynamicHeight: true,
			resizable: true,
			isBounded: true,
		},
	];

	const layoutWithoutObjectMFI = [
		//Object Mfi Panel
		{
			x: 0,
			y: 0,
			h: 0,
			w: 0,
			i: "8dac42e4-31be-4967-9a93-56f9a3d3ca93",
			dynamicHeight: true,
			resizable: true,
			isBounded: true,
		},
		//Setup Sheet panel
		{
			x: 0,
			y: 0,
			h: 1,
			w: 500,
			i: "6031cf44-4369-4731-9301-d2bbfdf6209d",
			dynamicHeight: true,
			resizable: true,
			isBounded: true,
		},
		//Visual Presentation Panel
		{
			x: 501,
			y: 0,
			h: 1,
			w: 339,
			i: "ecb44f34-a589-4d86-878d-419afb517051",
			dynamicHeight: true,
			resizable: true,
			isBounded: true,
		},
	];

	const initiateChangeRequest = (initialObject) => {
		//Open the initial change request dialog
		//Until the dialog is here we will skip it
		// newObjTransaction.current = createChangeRequestPacket(
		// 	sharedState,
		// 	sharedState.contextTop.standardObjectUuid,
		// 	sharedState.contextTop.standardObjectVersionUuid
		// );
	};

	const createNewChangeRequest = (obj) => {};

	let workspaceMfiPanel = sharedState.openView.openPanels.find(
		(panel) => panel.id === objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE.id
	);
	let breadcrumbs = [];
	const packetTypeUuid = sharedState.dbConstants?.packetObject?.referenceUuid;

	//Add the ancestors to the breadcrumbs
	if (sharedState.contextAncestorObjects?.length > 0) {
		let { contextAncestorObjects: ancestorObjects } = sharedState;
		if (sharedState.packetMode) {
			//Filter out the packet objects
			let packetObjects = ancestorObjects.filter((row) => row.objectTypeUuid === packetTypeUuid);

			//If there are any packet objects the creator breadcrumbs will start at the first ancestor object and stop at the last packet object
			if (packetObjects.length > 0) {
				const loadedPacket = sharedState.contextPacket;
				let index;
				if (loadedPacket?.uuid) index = ancestorObjects.findIndex((row) => row.uuid === loadedPacket.uuid);
				//Find the index of the last packet object, that will be where we caught off these creator breadcrumbs
				else {
					let indexOfChFixOrPatchPacket = packetObjects.findIndex(
						(row) =>
							row.standardObjectUuid === sharedState.dbConstants.changeRequestPacket.referenceUuid ||
							row.standardObjectUuid === sharedState.dbConstants.fixPacket.referenceUuid ||
							row.standardObjectUuid === sharedState.dbConstants.patchPacket.referenceUuid
					);

					if (indexOfChFixOrPatchPacket > -1) index = indexOfChFixOrPatchPacket;
					else
						index = ancestorObjects.findIndex(
							(row) => row.uuid === packetObjects[packetObjects.length - 1].uuid
						);
				}

				breadcrumbs = ancestorObjects.slice(index + 1, ancestorObjects.length).map((row) => ({
					key: row.uuid,
					text: row.title + (row.readonly ? " - READ ONLY" : ""),
					version: row.computerVersion,
					versionUuid: row.versionUuid,
				}));
			} else
				breadcrumbs = ancestorObjects.map((row) => ({
					key: row.uuid,
					text: row.title + (row.readonly ? " - READ ONLY" : ""),
					version: row.computerVersion,
					versionUuid: row.versionUuid,
				}));
		} else {
			breadcrumbs = ancestorObjects.map((row) => ({
				key: row.uuid,
				text: row.title + (row.readonly ? " - READ ONLY" : ""),
				version: row.computerVersion,
				versionUuid: row.versionUuid,
			}));
		}
	}

	//Add the open object to the breadcrumbs, we want to add it if its type is not a packet or if we're in editPacketMode
	if (top.uuid) {
		breadcrumbs.push({
			key: top.uuid,
			text: top.title + (top.readonly ? " - READ ONLY" : ""),
			version: top.computerVersion,
			versionUuid: top.versionUuid,
		});
	}

	// if(sharedState.contextAncestorObjects?.length > 0 && !sharedState.editPacketMode)
	//     breadcrumbs = [...sharedState.contextAncestorObjects?.filter(row => row.objectTypeUuid !== packetObjectUuid.current).map(row => ({key: row.uuid, text: row.title, version: row.computerVersion, versionUuid: row.versionUuid}))];
	// else if(sharedState.contextAncestorObjects?.length > 0)
	//     breadcrumbs = [...sharedState.contextAncestorObjects.map(row => ({key: row.uuid, text: row.title, version: row.computerVersion, versionUuid: row.versionUuid}))];
	//
	// if(sharedState.contextTop.objectTypeUuid !== packetObjectUuid.current || sharedState.editPacketMode)
	//     breadcrumbs.push({key: sharedState.contextTop.uuid, text: sharedState.contextTop.title, version: sharedState.contextTop.computerVersion, versionUuid: sharedState.contextTop.versionUuid});
	let layoutToShow;
	if (
		sharedState.contextTop?.generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE &&
		!allowedToEditHarvestedObjects(sharedState, true)
	)
		layoutToShow = layoutWithoutObjectMFI;
	else layoutToShow = layoutWithObjectMFI;

	let versionControlObject = {
		...top.versionControl,
		objectsComputerVersions: [],
		computerVersion: top.computerVersion,
		modifiedAt: top.logRecord?.createdAt,
		loadPreviousVersion,
		loadNextVersion,
		loadSpecificVersion,
	};

	if (!sharedState.contextAncestorObjects || sharedState.contextAncestorObjects?.length < 1)
		versionControlObject.objectsComputerVersions = sharedState.contextVersions;

	return (
		<Panel
			panelTitle={"Object Creation, Edit, Setup Workspace"}
			panelContentTitle={
				breadcrumbs.length > 0 ? (
					<Breadcrumbs breadcrumbs={breadcrumbs} breadcrumbClicked={breadcrumbClicked} />
				) : (
					""
				)
			}
			panelStockNumber={
				top?.title ? (
					<ObjectHeader
						objectTitle={""}
						reference={
							sharedState.contextPacket?.uuid
								? top?.reference
								: top?.mfiReference
								? top.mfiReference
								: top.reference
						}
						referenceType={sharedState.contextPacket?.uuid ? "PKT" : "DW"}
						stockNumber={top?.stockNo}
						version={
							versionControlObject
							// !sharedState.packetMode || !sharedState.contextPacket?.uuid ? versionControlObject : {}
						}
					/>
				) : (
					""
				)
			}
			menuItems={[]}
			panelGroup={"creator"}
			id={workspaceMfiPanel.paperId}
			quickActionItems={
				top.objectTypeUuid === packetObjectUuid.current && sharedState.contextPacket?.uuid !== top.uuid
					? [
							{
								onClick: () => {
									let hierarchy;
									let hierarchyRecord = sharedState.contextObjectHierarchy.find(
										(row) => row.descendantStandardObjectUuid === top.uuid
									);
									if (hierarchyRecord) hierarchy = [hierarchyRecord];
									dispatch({
										type: "SET_CONTEXT_OR_MFI",
										data: { top: {}, mfi: [], packet: { ...top, objectHierarchy: hierarchy } },
									});
								},
								icon: <BinderIcon />,
								title: "View in Packet Mode",
								id: "new-modified-panel-view-packet-mode",
							},
					  ]
					: []
			}
		>
			<ReactGrid
				layout={layoutToShow}
				dynamicHeight={true}
				draggableHandle={".panelHeader"}
				draggable={sharedState.resizePanels && !sharedState.fullScreenVisualRep}
			>
				{/*Object MFI Panel*/}
				<div key={"8dac42e4-31be-4967-9a93-56f9a3d3ca93"}>
					<ObjectMfiPanel
						style={{
							display:
								sharedState.contextTop?.generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE &&
								!allowedToEditHarvestedObjects(sharedState, true)
									? "none"
									: "",
						}}
						diff={diff}
					/>
				</div>
				<div key={"6031cf44-4369-4731-9301-d2bbfdf6209d"}>
					<SetupSheetPanel />
				</div>
				<div key={"ecb44f34-a589-4d86-878d-419afb517051"}>
					<MainVisualRepPanel />
				</div>
			</ReactGrid>

			<div
				id="overall-workspace-overlay"
				className="fade d-none"
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					height: "100%",
					width: "100%",
					pointerEvents: "none",
					backgroundColor: "rgb(219 237 255)",
					fontSize: "26px",
					textAlign: "center",
					paddingTop: " 15%",
					zIndex: 99,
				}}
			></div>
		</Panel>
	);
};

export default NewModifiedWorkspacePanel;

const MainVisualRepPanel = ({}) => {
	const sharedState = useTrackedState();

	return (
		<VisualRepPanel
			panelTitle={"Visual Presentation Views"}
			uuid={sharedState.contextTop.uuid}
			versionUuid={sharedState.contextTop.versionUuid}
			context={sharedState.contextTop}
			panelContentTitle={sharedState.contextTop.title}
			reference={sharedState.contextTop.reference}
			includeObjectHierarchy={true}
			sendGlobalChanges={true}
		></VisualRepPanel>
	);
};

//Sends an update to the change data
export const updateChangeData = async (
	dispatch,
	{ objectToUpdate, subObjectToUpdate, mfiRows, objectRows, deletedRows, objectHierarchy, reset, filter, primusRows },
	allowThisChange = false,
	currentUser
) => {
	//if allowThisChange is not true, and the current user is different than the object owner warn the user they arent the owner of the object and they need to make an open point instead of making this change
	// if (!allowThisChange && currentUser?.uuid !== objectToUpdate.createdByUuid && !isLocalhost) {
	// 	//Warn user that they are not allowed to make change and to make an open point instead
	// 	dispatch({
	// 		type: "SHOW_ERROR_MESSAGE",
	// 		data: "You are not the owner of this object, therefore this change will not be applied. You may make your own copy and submit a change request or you may submit an open point to the object owner.",
	// 	});
	// 	return;
	// }

	if (objectToUpdate?.readonly || subObjectToUpdate?.readonly) {
		dispatch({
			type: "SHOW_ERROR_MESSAGE",
			data: "This object is marked as read-only",
		});
		return;
	}

	return await dispatch({
		type: "UPDATE_CHANGED_ROWS",
		data: {
			//How do I get the object to update? it would need to be passed in from the Body Component probably
			objectToUpdate,
			subObjectToUpdate,
			changedMfiRows: mfiRows,
			changedObjectRows: objectRows,
			deletedRows,
			objectHierarchy,
			reset,
			filter,
			primusRows,
		},
	});
};

export const ObjectHeader = ({
	objectTitle: _objectTitle,
	reference,
	referenceType,
	stockNumber,
	object,
	version,
	style = {},
}) => {
	return (
		<div style={{ textAlign: "left", ...style }}>
			<div style={{ whiteSpace: "nowrap", color: "black" }}>
				{referenceType || ""} Reference:
				<span className="ps-1" style={{ color: "red" }}>
					{reference}
				</span>
			</div>
			<div style={{ whiteSpace: "nowrap", color: "black" }}>
				{stockNumber && (
					<>
						Stock #:
						<span className="ps-1" style={{ color: "#2c49a0" }}>
							{stockNumber}
						</span>
					</>
				)}
				{version && (
					<Version
						version={version}
						tooltip={
							<>
								Available Version(s): 1
								{version?.objectsComputerVersions?.length > 1 && (
									<>-{version?.objectsComputerVersions.length}</>
								)}
								<br />
								Date Updated: {version?.modifiedAt}
							</>
						}
					/>
				)}
			</div>
		</div>
	);
};

/**
 * Handles all of the open point dialog stuff
 * @returns {*}
 * @constructor
 */
export const OpenPointDialog = () => {
	const [showOpenPointFormDialog, setShowOpenPointFormDialog] = useState(false);
	const [openPoints, setOpenPoints] = useState([]);
	const sharedState = useTrackedState();

	useEffect(() => {
		if (sharedState.contextTop?.uuid) getObjectsOpenPoints().then((ops) => setOpenPoints(ops));
	}, [sharedState.contextTop?.uuid]);

	useEffect(() => {
		if (sharedState.openOpenPointDialog) setShowOpenPointFormDialog(true);
	}, [sharedState.openOpenPointDialog]);

	const handleOpenPointSave = (event) => {
		saveOpenPoints(openPoints);
		setShowOpenPointFormDialog(false);
		// setShowOpenPointFormDialog(false);
	};

	return (
		<DraggableDialog
			id={"open-point-form-dialog"}
			style={{ width: "100%" }}
			maxWidth={"100%"}
			// size={'xl'}
			showDialog={showOpenPointFormDialog}
			handleClose={(e) => setShowOpenPointFormDialog(false)}
			handleSave={handleOpenPointSave}
			header={"Open Points"}
			PaperProps={{ style: { maxWidth: "3160px" } }}
		>
			<OpenPoints
				// openPoint={{title: contextRef.current.context ? contextRef.current.context.title : ''}}
				openPoints={openPoints}
				updateOpenPoints={setOpenPoints}
				itemToBeChanged={sharedState.contextTop}
			/>
			{/*saveOpenPoint={handleOpenPointSave}*/}
		</DraggableDialog>
	);
};

const ObjectMfiPanel = ({ style = {}, diff }) => {
	const [selectedRow, setSelectedRow] = useState({});
	const [data, setData] = useState([]);

	const ownerToDescendantMap = useRef(new Map());
	const ranSelect = useRef("");
	const sharedState = useTrackedState();
	const dispatch = useDispatch();
	const [queryParams, setQueryParams] = useSearchParams();

	const {
		getAll: getAttachmentPoints,
		add: addAttachmentPoint,
		clear: clearAttachmentPoints,
		update: updateAttachmentPoint,
		deleteRecord: deleteAttachmentPoint,
	} = useIndexedDB("attachmentPoints");

	//Selected workspace row can get set from the Setup Sheet Panel and we want the tree to match
	useEffect(() => {
		if (sharedState.selectedWorkspaceRow?.uuid && sharedState.selectedWorkspaceRow.uuid !== selectedRow.uuid) {
			setSelectedRow(sharedState.selectedWorkspaceRow);
			rowClicked(sharedState.selectedWorkspaceRow, false, false, null, true, false);
		}
	}, [sharedState.selectedWorkspaceRow]);

	//Everytime the mfi changes update the onwerToDescendantMap
	useEffect(() => {
		ownerToDescendantMap.current = convertListToLeavesWithOwner(
			sharedState.contextMfi,
			sharedState.contextMfi,
			sharedState.contextTop
		);
		//Update the MFI Reference for the top
		let match = sharedState.destinationModel.find(
			(row) =>
				row.standardObjectUuid === sharedState.contextTop.uuid &&
				row.standardObjectVersionUuid === sharedState.contextTop.versionUuid
		);
		if (match) sharedState.contextTop.mfiReference = match.reference;
		setData(sharedState.contextMfi);
	}, [sharedState.contextMfiVersion, sharedState.contextTop?.uuid]);

	//We want to be able to update a row from anywhere not just here so listen to that from the global state
	useEffect(() => {
		let { uuid, field, value } = sharedState.rowToUpdate;

		if (uuid && field) updateRow(uuid, field, value);
	}, [sharedState.rowToUpdate, sharedState.rowToUpdate.uuid]);

	/**
	 * Updates the row in the global state
	 */
	const updateRow = (rowId, fieldTitle, newValue, reRender = true) => {
		//Get the row being changed from the state
		let rowBeingChanged;
		let changes = [];
		let { contextTop: top, contextMfi: mfi } = sharedState;
		let updateTopRow = false;
		if (rowId !== top.uuid) rowBeingChanged = mfi.find((row) => row.uuid === rowId);
		else {
			rowBeingChanged = top;
			updateTopRow = true;
		}

		if (!rowBeingChanged) {
			console.error("Could not find row", rowId, "to update field", fieldTitle, "to value", newValue);
			return;
		}

		//TODO: Alot of this code is assuming that the global state is updated because of pointers, verify this

		//Check if the field is being proxied
		let unproxiedValue = getUntrackedObject(newValue);
		if (unproxiedValue) newValue = unproxiedValue;

		switch (fieldTitle) {
			//If the field being updated is the object type, update the objectTypeUuid and if it is set to the setup sheet type, update the setup sheet map stuff
			case "objectType":
				rowBeingChanged.objectTypeUuid = newValue.uuid;

				//If we have a new setup sheet update the setup sheet tree
				if (newValue.uuid === sharedState.dbConstants.setupSheetType.referenceUuid)
					console.log("I have a new **Setup Sheet** How should we update this?");
				break;

			//Else if the value field is being updated, we need to also update any rows that reference this row, and if the sourceType is set to 'Object Owner Title' we need to update the object owner title as well
			case "value":
				//There can potentially be multiple rows to update
				let rowsToUpdate = [];
				//We are currently limiting links to setup attributes so we only need to check for rows that reference this row if it's a setup row
				if (rowBeingChanged.setupAttribute) {
					updateRowsThatReferenceThisRow(rowId, newValue, mfi, rowsToUpdate);

					changes = [...changes, ...rowsToUpdate];
				}

				//Check if there is anything that is pulling its value from this row
				if (
					(!rowBeingChanged.inputSource && rowBeingChanged.setupType === SETUP_TYPES.OBJECT_TITLE.value) ||
					rowBeingChanged.inputSource === INPUT_FIELD_SOURCES.OBJECT_OWNER_TITLE.value
				) {
					let owner = findOwner(rowBeingChanged, rowBeingChanged, mfi, top);
					owner.title = newValue;
					changes.push(owner);

					//If we're updating the top row, update the context as well
					if (owner.uuid === top.uuid) {
						top.title = newValue;
					}
				}

				//If this row had a link type of 'alias', change it to 'value'
				if (rowBeingChanged.setupLinkType === INPUT_FIELD_SOURCES.OBJECT_FIELD.value) {
					rowBeingChanged.setupValue = newValue;
					rowBeingChanged.setupLinkType = INPUT_FIELD_SOURCES.HARD_CODED_VALUE.value;
				}
				// setReRenderWorkspace(!reRenderWorkspace);

				break;
			default:
				console.error("Invalid Field Title");
		}

		//TODO: We may want to have this call this method for each field it wants to update so that it can do the special stuff it needs to on value, ttile, objectType change, etc.
		//If the fieldTitle is 'multi', which means we want to update multiple fields, update each field and reRender. We are assuming that none of the special fields will be updated this way, i.e. value, objectType, etc.
		//Update the row
		if (fieldTitle === "multi") {
			Object.keys(newValue).forEach((key) => (rowBeingChanged[key] = newValue[key]));
			// setReRenderWorkspace(!reRenderWorkspace);
		} else rowBeingChanged[fieldTitle] = newValue;

		changes.push(rowBeingChanged);

		//Update the state with these changes, if the top row was changed update the context
		if (updateTopRow) {
			//Do I need to call the dispatch to update it? Or can I just update it? Depends on whether or not I have a pointer
			dispatch({
				type: "SET_CONTEXT_OR_MFI",
				data: {
					top: rowBeingChanged,
				},
			});
		}

		//If the title field is being updated, check to see if it owns any rows that pull the title as its value
		if (fieldTitle === "title") {
			//If the rowBeingChanged is an owner with descendants, check for a setup row that needs to be updated to match
			if (ownerToDescendantMap.current.has(rowBeingChanged.uuid)) {
				let leaves = ownerToDescendantMap.current.get(rowBeingChanged.uuid).leaves;
				let rowsBasedOnOwnerTitle = leaves.filter(
					(row) =>
						(!row[INPUT_SOURCE_ATTRIBUTE] && row.setupType === SETUP_TYPES.OBJECT_TITLE.value) ||
						row[INPUT_SOURCE_ATTRIBUTE] === INPUT_FIELD_SOURCES.OBJECT_OWNER_TITLE.value
				);
				rowsBasedOnOwnerTitle.forEach((row) => {
					row.value = newValue;
					updateRowsThatReferenceThisRow(row.uuid, newValue, mfi, changes);
				});
				if (rowsBasedOnOwnerTitle.length > 0) changes = [...changes, ...rowsBasedOnOwnerTitle];
			}
		}

		//Pass the changed rows to the state
		updateChangeData(
			dispatch,
			{ objectToUpdate: getTopMostObject(sharedState), objectRows: changes },
			false,
			sharedState.currentUser
		);

		//If the title or value field is being updated and reRender is true, trigger template re-rendering.
		if (fieldTitle === "title" || (fieldTitle === "value" && reRender))
			//Trigger a template re-render and trigger a tree re-render?
			dispatch({ type: "TRIGGER_GENERATE", data: "template" });

		dispatch({ type: "UPDATE_ROW", data: {} });
	};

	const addDeletedRows = (deletedRows) => {
		updateChangeData(
			dispatch,
			{
				objectToUpdate: getTopMostObject(sharedState),
				deletedRows,
			},
			false,
			sharedState.currentUser
		);
	};

	const addChanges = (data) => {
		_removeOverlay(document);
		updateChangeData(
			dispatch,
			{
				objectToUpdate: getTopMostObject(sharedState),
				mfiRows: data.filter((row) => row.mfiRow),
				objectRows: data.filter((row) => !row.mfiRow),
				objectHierarchy: data.objectHierarchy,
			},
			false,
			sharedState.currentUser
		);
	};

	/**
	 * Set the selected row, triggered from the row selected event in the Tree Component
	 * @param row
	 */
	const rowSelected = (row, multiClick = false, replaceUuid) => {
		//If the row has children set the window id to the row's id, if it doesn't have children set it to the row's parent id
		let windowUuid = row.parentUuid;
		let updatedOpenWindows = sharedState.openPropertyWindows;

		if (row.uuid === sharedState.contextTop.uuid && !updatedOpenWindows.includes("0")) windowUuid = ZERO_ROW_UUID;

		if (multiClick) {
			if (replaceUuid) {
				const index = updatedOpenWindows.indexOf(replaceUuid);
				if (windowUuid && !updatedOpenWindows.includes(windowUuid))
					updatedOpenWindows.splice(index, 1, windowUuid);
				else updatedOpenWindows.splice(index, 1);
			} else if (!updatedOpenWindows.includes(windowUuid))
				updatedOpenWindows = [...updatedOpenWindows, windowUuid];
		} else updatedOpenWindows = [windowUuid];

		dispatch({ type: "UPDATE_OPEN_PROPERTY_WINDOWS", data: updatedOpenWindows });
	};

	const rowClicked = async (selected, metaKey, shiftKey, doubleClick, selectRow = true, loadObject = true) => {
		let hierarchyRecord = checkForSubObject(selected, sharedState.contextFullObjectHierarchy);
		if (!hierarchyRecord) hierarchyRecord = checkForSubObject(selected, sharedState.contextObjectHierarchy);
		let child = sharedState.contextMfi.find((row) => row.parentUuid === selected.uuid);

		let { selectedWorkspaceRow } = sharedState;

		let associatedObjectType = sharedState.dbConstants.associatedObjectType.referenceUuid;

		if (selectedWorkspaceRow.uuid !== selected.uuid)
			await dispatch({ type: "SET_SELECTED_WORKSPACE_ROW", data: selected });
		else return;

		//TODO: It appears that the related objects are being updated in the global state but this isn't catching the updates
		if (selected.generalTypeUuid === associatedObjectType && selected.uuid !== selectedWorkspaceRow.uuid) {
			hierarchyRecord = sharedState.contextObjectHierarchy.find(
				(row) =>
					row.descendantStandardObjectUuid === selected[linkAttributeName] &&
					//I will assume we don't need to check the linkToObjectVersionUuid?
					// row.descendantStandardObjectVersionUuid === row &&
					row.hierarchyTypeUuid === associatedObjectType
			);
			loadRelatedObject(
				hierarchyRecord,
				selected,
				sharedState,
				dispatch,
				false,
				{
					addAttachmentPoint,
					getAttachmentPoints,
					updateAttachmentPoint,
					deleteAttachmentPoint,
				},
				setQueryParams
			);
			return;
		}
		//If there is a hierarchy record that means its a sub-object that we have not loaded. Do I also need to change the handle drop so it doesnt let you drop on sub-objects if they haven't been loaded yet?
		else if (hierarchyRecord && !child && selected.uuid !== selectedWorkspaceRow.uuid) {
			//Base everything off of Model, App, Object Workspace IDE view. This will need to change once we are doing things from multiple views
			let topUuidAndVersion, ancestorRow;

			if (sharedState.contextAncestorObjects && sharedState.contextAncestorObjects.length > 0) {
				topUuidAndVersion = getObjectIdAndVersionUuid(sharedState.contextAncestorObjects[0]);
				ancestorRow = sharedState.contextAncestorObjects.find((row) => row.uuid === selected.uuid);
			} else topUuidAndVersion = getObjectIdAndVersionUuid(sharedState.contextTop);

			if (!ancestorRow) ancestorRow = selected;

			let { objects: changes, objectHierarchy } = getStateChanges(sharedState, topUuidAndVersion);
			let hierarchy = getGlobalObjectHierarchy(sharedState);

			//Check if we have made any changes to any rows stored in the state
			if (changes) changes = getChangesForObject(changes, ancestorRow, hierarchy, true);
			else changes = null;

			//TODO: This is causing random issues, this was so we could set certain objects to load into the current MFI rather than replacing the current one. We don't really use this functionality right now so comment for now
			// selectRow = selected.relationshipTypeUuid === sharedState.dbConstants.objDefRel.referenceUuid

			loadSubObject(
				selected,
				hierarchyRecord,
				sharedState,
				dispatch,
				false,
				{
					addAttachmentPoint,
					getAttachmentPoints,
					updateAttachmentPoint,
					deleteAttachmentPoint,
				},
				changes,
				setQueryParams
			);
			return;
		}

		if (selectRow) rowSelected(selected, metaKey || shiftKey);
		else {
			dispatch({ type: "SET_SELECTED_WORKSPACE_ROW", data: {} });
			dispatch({ type: "UPDATE_OPEN_PROPERTY_WINDOWS", data: [] });
		}
	};

	return (
		<Panel
			panelTitle={"Object MFI View"}
			menuItems={[
				{
					id: "object-mfi-view-add-panel",
					label: "Add Panel",
					icon: <PlusIcon />,
					onClick: "",
				},
			]}
			style={style}
		>
			<RecursiveTreeView
				//If we are filtering the Object Workspace MFI, filter it by items that are showing up on either setup form
				data={sharedState.contextMfi}
				diff={diff}
				treeTitle={sharedState.contextTop ? sharedState.contextTop.title : "Object MFI"}
				emptyMessage="Drag an object from the Data Warehouse to get started."
				addChangedRows={addChanges}
				addDeleteRows={addDeletedRows}
				rowSelected={rowClicked}
				topNode={sharedState.contextTop}
				topNodeId={sharedState.contextTop?.uuid}
				droppable={true}
				draggable={true}
				editable={true}
				className={`tree`}
				origin={"workspace-panel"}
				updateRow={updateRow}
				getObjMfiOnDrop={true}
				setupSheetMap={null}
				showErrors={true}
				showWarnings={true}
				rowToSelect={selectedRow}
				updateMfiInState={true}
			/>
		</Panel>
	);
};

export const DebugDialog = ({ deleteItemDescendants }) => {
	const [showObjectPropertiesDialog, setShowObjectPropertiesDialog] = useState(false);
	const [errors, setErrors] = useState({});
	const [createdFromObject, setCreatedFromObject] = useState();
	const triggerOpenCount = useRef(0);

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		if (sharedState.triggerOpenDebugDialog && triggerOpenCount.current !== sharedState.triggerOpenDebugDialog) {
			triggerOpenCount.current = sharedState.triggerOpenDebugDialog;
			openObjectPropertiesDialog();
			getCreatedFromTitle();
		}
	}, [sharedState.triggerOpenDebugDialog]);

	/**
	 * Here is the method the button calls to open the dialog
	 */
	const openObjectPropertiesDialog = async () => {
		//Opens the Object Properties Dialog
		setShowObjectPropertiesDialog(true);
		if (!sharedState.contextMfi || sharedState.contextMfi.length === 0) {
			let topObject = getTopMostObject(sharedState);
			if (!topObject) {
				return;
			}
			setErrors(checkErrors(await getSingleLevelObjectMfi(topObject.uuid, topObject.versionUuid, dispatch)));
		} else {
			setErrors(checkErrors(sharedState.contextMfi));
		}
	};

	const getCreatedFromTitle = async () => {
		if (sharedState.contextTop) {
			let sourceObject = sharedState.sourceObject;
			if (!sourceObject?.uuid) {
				sourceObject = await getObjectDTOByUuidAndVersion(
					sharedState.contextTop.standardObjectUuid,
					sharedState.contextTop.standardObjectVersionUuid
				);
				dispatch({ type: "SET_SOURCE_OBJECT", data: sourceObject });
			}
			setCreatedFromObject(sourceObject);
		}
	};

	const repairReferences = () => {
		let { uuid, versionUuid } = sharedState.contextTop;

		//How would we wait for the response before repairing and then reloading?
		// dispatch({type: 'SHOW_WARNING_MESSAGE', data: {text: 'This will reload the page. Continue?', prompt: true}})
		repairObjectReferences(uuid, versionUuid).then(() => window.location.reload());
	};

	return (
		<DraggableDialog
			id={"object-properties"}
			style={{ minHeight: "600px", minWidth: "1200px" }}
			showDialog={showObjectPropertiesDialog}
			handleClose={(e) => {
				setShowObjectPropertiesDialog(false);
			}}
			header={"Object Debug Info"}
			cancelButton={false}
			saveButtonText={"Close"}
			PaperProps={{ style: { maxWidth: "1300px" } }}
		>
			<Grid container>
				<Grid container className={""} style={{}}>
					{" "}
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Title: </strong> <span style={{}}>{errors.object?.title}</span>
						</p>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Data Warehouse Reference: </strong>{" "}
							<Reference reference={errors.object?.mfiReference} />
						</p>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>UUID/Version: </strong>{" "}
							<span style={{}}>
								{errors.object?.uuid}/{errors.object?.versionUuid}
							</span>
							<button
								style={{ marginLeft: "30px" }}
								className={"btn btn-primary"}
								onClick={repairReferences}
							>
								Repair Object MFI References
							</button>
						</p>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Created By: </strong> {errors.object?.createdByUuid}
						</p>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Created From: </strong>{" "}
							<a
								href={`/object-workspace?uuid=${createdFromObject?.uuid}&versionUuid=${createdFromObject?.versionUuid}`}
								target="_blank"
							>
								{createdFromObject?.title}
							</a>
							<Version version={{ computerVersion: createdFromObject?.computerVersion }} />
						</p>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Created From Reference: </strong>{" "}
							<Reference reference={createdFromObject?.mfiReference} />{" "}
							<span style={{ color: "#2c49a0" }}>{createdFromObject?.stockNo}</span>{" "}
							{createdFromObject?.uuid}/{createdFromObject?.versionUuid}
						</p>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Object Size: </strong> {errors.objectSize}
						</p>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Top Records: </strong>
						</p>
						<List>
							{errors.topRecords?.length > 0 ? (
								errors.topRecords.map((topRecord) => {
									return (
										<ListItem key={topRecord.uuid} className={"font-red"}>
											{topRecord.label}
											{errors.topRecords.length > 1 ? (
												<button
													className="btn btn-danger"
													onClick={(e) => deleteItemDescendants(topRecord)}
												>
													Remove erroneous top record
												</button>
											) : (
												""
											)}
										</ListItem>
									);
								})
							) : (
								<ListItem>No Records Found</ListItem>
							)}
						</List>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Duplicate References: </strong>
						</p>
						<List>
							{errors.duplicateReferences?.length > 0 ? (
								errors.duplicateReferences.map((duplicateReference) => {
									return (
										<ListItem key={duplicateReference.uuid} className={"font-red"}>
											{duplicateReference}
										</ListItem>
									);
								})
							) : (
								<ListItem>No Issues Found</ListItem>
							)}
						</List>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Missing Parents: </strong>
						</p>
						<List>
							{errors.missingParents?.length > 0 ? (
								errors.missingParents.map((missingParent) => {
									return (
										<ListItem key={missingParent.label}>
											{missingParent.label}
											{!missingParent.label.startsWith("0 -") ? (
												<button
													className="btn btn-danger"
													onClick={(e) => deleteItemDescendants(missingParent)}
												>
													Remove orphan record
												</button>
											) : (
												""
											)}
										</ListItem>
									);
								})
							) : (
								<ListItem>No Issues Found</ListItem>
							)}
						</List>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Duplicate Rows: </strong>
						</p>
						<List>
							{errors.duplicateRows?.length > 0 ? (
								errors.duplicateRows.map((duplicateRow) => {
									return <ListItem key={duplicateRow.uuid}>{duplicateRow}</ListItem>;
								})
							) : (
								<ListItem>No Issues Found</ListItem>
							)}
						</List>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Types Found: </strong>
						</p>
						<List>
							{errors.types?.length > 0 ? (
								errors.types.map((type) => {
									return (
										<ListItem key={type.reference + type.uuid}>
											<Typography className={"font-red"}>
												{type.reference} - {type.title}:{" "}
											</Typography>
											{type.type}
										</ListItem>
									);
								})
							) : (
								<ListItem>No Types Found</ListItem>
							)}
						</List>
					</Grid>
					<Grid container columns={{ xs: 12 }} className={""} style={{}}>
						<p>
							<strong>Tagged Rows: </strong>
						</p>
						<List>
							{errors.tags?.length > 0 ? (
								errors.tags.map((tag) => {
									return (
										<ListItem key={tag.reference + tag.uuid}>
											<Typography className={"font-red"}>
												{tag.reference} - {tag.title}:{" "}
											</Typography>
											{tag.tags}
										</ListItem>
									);
								})
							) : (
								<ListItem>No Tags Found</ListItem>
							)}
						</List>
					</Grid>
				</Grid>
			</Grid>
		</DraggableDialog>
	);
};

export const ObjectHierarchyTree = ({ mfi, objectHierarchy }) => {
	const context = useRef({});

	useEffect(() => {
		if (mfi && mfi.length > 0) context.current = mfi[0];
	}, [mfi]);
	return (
		<div style={{ height: "100%", width: "100%" }}>
			<RecursiveTreeView
				//If we are filtering the Object Workspace MFI, filter it by items that are showing up on either setup form
				data={mfi}
				visibleData={() => {
					let objectHierarchyList = [
						...mfi.filter((row) => {
							return objectHierarchy?.find((obj) => obj.descendantStandardObjectUuid === row.uuid);
						}),
					];
					objectHierarchyList = objectHierarchyList.map((obj) => {
						return {
							...obj,
							parentUuid:
								objectHierarchy.find((row) => row.descendantStandardObjectUuid === obj.uuid)
									?.ancestorStandardObjectUuid || obj.parentUuid,
						};
					});

					let objectHierarchyTree = listToTree(
						objectHierarchyList,
						objectHierarchyList.length > 0 ? objectHierarchyList[0].parentUuid : undefined
					);
					if (objectHierarchyTree) return objectHierarchyTree;
					else return [];
				}}
				visibleDataIsTree={true}
				treeTitle={context.current.title || "Object Hierarchy MFI"}
				//TODO: I don't think we want this tree to be able to make changes
				// addChangedRows={addChangedRows}
				// addDeleteRows={addDeleteRows}
				//TODO: Do we need this on this tree?
				// rowSelected={rowSelected}
				// dropEventHandler={removeOverlay}
				// updateSetupInfo={updateSetupInfo}
				topNode={context.current}
				topNodeId={context.current.uuid}
				droppable={true}
				// draggable={true}
				// editable={true}
				className={`tree`}
				origin={"layout-panel"}
			/>
		</div>
	);
};

export const _updateRow = (dispatch, uuid, field, value) => {
	dispatch({ type: "UPDATE_ROW", data: { uuid, field, value } });
};

export const getDataWarehouse = async (dispatch, sharedState) => {
	if (sharedState?.dataWarehouse && sharedState?.dataWarehouse.length > 0) return sharedState.dataWarehouse;

	let mfi = await getCall(getUrl("getUbmDataWarehouse"));
	if (dispatch) dispatch({ type: "SET_DATA_WAREHOUSE_MFI", data: mfi });
	return mfi;
};

export const getExistingObjects = async (dataWarehouseUuid, sharedState, dispatch) => {
	if (sharedState.objects && sharedState.objects.length > 0) return sharedState.objects;

	if (!dataWarehouseUuid) return [];

	let mfi = await getDataWarehouseAndSpecial(dataWarehouseUuid);
	if (mfi?.length > 0 && dispatch) dispatch({ type: "SET_EXISTING_OBJECTS", data: mfi });
	return mfi;
};
