import { PlusIcon } from "../../BootstrapComponents/Icons/Icons";
import BasicTreeWithSearch from "../../Tree/BasicTreeWithSearch";
import { updateChangeData } from "../Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { copyStandardObject, ZERO_ROW_UUID } from "../../../utils/StandardObject";
import { DynamicPanel } from "./Panel";

const DataWarehousePanel = ({ sharedState, dispatch, mfi, panelId, panelGroup, rowSelected }) => {
	const addChangedRows = (data) => {
		//TODO: VERIFY
		updateChangeData(dispatch, {
			mfiRows: data.filter((row) => row.mfiRow),
			objectRows: data.filter((row) => !row.mfiRow),
		});
	};

	const getObjectCopyToPutInDataWarehouse = (existingObject, newMfiRecord, objectType) => {
		//Create a copy of the existing object and it's MFI
		let newObject = copyStandardObject(existingObject, "0", ZERO_ROW_UUID, sharedState.currentUser?.uuid, {
			newStockNumber: true,
			newVersion: true,
		});
		// newObject.standardObjectUuid = newObject.uuid;
		// newObject.standardObjectVersionUuid = newObject.versionUuid;
		// newObject.generalTypeUuid = objectType.referenceUuid;
		if (newObject.stockNumber && newObject.stockNumber.uuid) {
			dispatch({ type: "UPDATE_CHANGED_STOCK_NUMBERS", data: newObject.stockNumber });
		}

		// //Copy existing MFI
		// let existingObjectMfi = sharedState.contextMfi.filter(item => item.uuid !== existingObject.uuid && item.reference.startsWith(existingObject.reference));
		//
		// let copyMfi = [];
		//
		// if(existingObjectMfi.length > 0) {
		//     copyMfi = copyObjectMfi(existingObjectMfi, existingObject.uuid, newObject.uuid, '0', sharedState.currentUser?.uuid);
		// }

		//Update the existingObject and newMfiRecord to point to the copy
		newMfiRecord.standardObjectUuid = newObject.uuid;
		newMfiRecord.standardObjectVersionUuid = newObject.versionUuid;
		newMfiRecord.versionControl = newObject.versionControl;

		existingObject.standardObjectUuid = newObject.uuid;
		existingObject.standardObjectVersionUuid = newObject.versionUuid;

		//Update the global state
		updateChangeData(
			dispatch,
			{
				objectToUpdate: newObject,
				objectRows: [newObject],
				// objectRows: [newObject, ...copyMfi]
			},
			false,
			sharedState.currentUser
		);
		return [existingObject];
	};

	return (
		<>
			<DynamicPanel
				panelTitle={"Data Warehouse"}
				menuItems={[
					{
						id: "data-warehouse-add-panel",
						label: "Add Panel",
						icon: <PlusIcon />,
						// onClick: (dispatch) => addPanel(dispatch, panelId),
					},
				]}
				panelId={panelId}
				panelGroup={panelGroup}
			>
				<BasicTreeWithSearch
					data={mfi}
					rowSelected={rowSelected}
					treeTitle={"Data Warehouse"}
					treeProps={{
						addChangedRows,
						draggable: true,
						droppable: false,
						getObjectCopyToPutInDataWarehouse,
						getObjMfiOnDrop: true,
						origin: "datawarehouse-panel",
						showVersions: true,
					}}
				/>
			</DynamicPanel>
		</>
	);
};

export default DataWarehousePanel;
