import React, { useEffect, useState } from "react";
import DraggableDialog from "./DraggableDialog/Dialog";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";

/**
 *
 * @constructor
 */
export const FileUploadDialog = ({ show, setShow, submitLayout, uploadUsingUrl = true }) => {
	const [showFileUploaderDialog, setShowFileUploaderDialog] = useState(false);
	const [fileUploaded, setFileUploaded] = useState(false);
	const [fileUploadedClass, setFileUploadedClass] = useState("");
	const [file, setFile] = useState();

	useEffect(() => {
		setShowFileUploaderDialog(show);
	}, [show]);

	useEffect(() => {
		if (file) setFileUploaded(true);
		else setFileUploaded(false);
	}, [file]);

	return (
		<DraggableDialog
			id={"upload-file-dialog"}
			size={"small"}
			showDialog={showFileUploaderDialog}
			handleClose={(e) => {
				setShow(false);
				setFile();
			}}
			handleSave={() => {
				submitLayout(file);
				window.location.reload();
			}}
			header={"File Uploader"}
			cancelButton={true}
			saveButtonText={"Submit"}
			PaperProps={{ style: { maxWidth: "900px" } }}
		>
			{INPUT_FIELD_TYPES.FILE_UPLOAD.render({
				label: "Upload Layout",
				accept: "application/json",
				submitFile: (file) => {
					setFile(file);
				},
				fileUploaded,
				setFileUploaded,
				fileUploadedClass,
				setFileUploadedClass,
				fileLinkClicked: () => {},
				uploadUsingUrl,
			})}
		</DraggableDialog>
	);
};
