import React, { useEffect, useRef } from "react";

function Iframe({ content, url, name, className, style, data = [], id, ...props }) {
	const iframe = useRef({});
	useEffect(() => {
		//Send the data to the iframe
		if (data.length > 0 && content.length > 0) {
			iframe.current.onload = function () {
				//Build script tag
				const script = document.createElement("script");
				script.type = "text/javascript";
				script.innerHTML = `var data = ${JSON.stringify(data)};`;

				//Add script to iframe
				window.frames[id].contentWindow.document.getElementsByTagName("head")[0].appendChild(script);
			};
		}
	}, [data.length, content]);

	return (
		<iframe
			id={id}
			title={id}
			ref={iframe}
			src={url}
			style={style}
			className={className}
			name={name}
			srcDoc={content}
		></iframe>
	);
}

export default Iframe;
