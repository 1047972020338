import React, { useEffect, useRef, useState } from "react";
import { useTrackedState } from "../../utils/store";
import UserSetupFormWindow from "../SetupFormWindow/UserSetupFormWindow";
import DeveloperSetupFormWindow from "../SetupFormWindow/DeveloperSetupFormWindow";
import { objectWorkspacePanels } from "../../utils/Panel";
import { ZERO_ROW_UUID } from "../../utils/StandardObject";

const SetupForms = ({ developer, ...props }) => {
	const [openWindows, setOpenWindows] = useState([]);

	const [dataMap, setDataMap] = useState(new Map());

	const openObject = useRef("");

	const sharedState = useTrackedState();

	useEffect(() => {
		let map = new Map();
		sharedState.contextMfi.forEach((row) => {
			map.set(row.uuid, row);
		});

		if (openObject.current !== sharedState.contextTop.uuid) openObject.current = sharedState.contextTop.uuid;

		setDataMap(map);
	}, [sharedState.contextMfiVersion, sharedState.contextTop?.uuid]);

	useEffect(() => {
		if (openObject.current !== sharedState.contextTop.uuid) openObject.current = sharedState.contextTop.uuid;
		else return;

		setOpenWindows([]);
	}, [sharedState.contextMfiVersion, sharedState.contextTop?.uuid]);

	useEffect(() => {
		let newSelected = [];
		let parentUuid;
		if (sharedState.selectedWorkspaceRow.uuid === sharedState.contextTop.uuid) parentUuid = ZERO_ROW_UUID;
		else parentUuid = sharedState.selectedWorkspaceRow?.parentUuid;
		// if (sharedState.openPropertyWindows.length > 0) newSelected = sharedState.openPropertyWindows;
		// else
		if (parentUuid) {
			newSelected = [parentUuid];
		} else newSelected = [];

		//Verify newSelected is different than the current selected before passing in a new array
		//To test equality, json.stringify the new and current and if they are different after than then set it.
		if (JSON.stringify(newSelected) !== JSON.stringify(openWindows)) setOpenWindows(newSelected);
	}, [
		sharedState.selectedWorkspaceRow.uuid,
		sharedState.selectedWorkspaceRow.parentUuid,
		sharedState.openPropertyWindows.length,
	]);

	return (
		<div>
			{openWindows.map((uuid) => {
				if (developer)
					return (
						<>
							{/*<div>Developer Setup Form Window</div>*/}
							<DeveloperSetupFormWindow
								key={uuid}
								topNode={sharedState.contextTop}
								uuid={uuid}
								data={sharedState.contextMfi}
								dataMap={dataMap}
								datawarehouse={sharedState.dataWarehouse}
								// updateRow={updateRow}
							/>
						</>
					);
				else
					return (
						<UserSetupFormWindow
							key={uuid}
							topNode={sharedState.contextTop}
							uuid={uuid}
							data={sharedState.contextMfi}
							dataMap={dataMap.current}
							datawarehouse={sharedState.dataWarehouse}
							// updateRow={updateRow}
						/>
					);
			})}
			<div
				className={openWindows.length > 0 ? "d-none" : ""}
				style={{
					fontSize: "40px",
					textAlign: "center",
					lineHeight: "60px",
					color: "rgb(181, 181, 181)",
					fontWeight: "200",
					margin: "100px auto",
					height: "200px",
					width: "90%",
					position: "relative",
				}}
			>
				Click on a tree node in the <br />{" "}
				<span style={{ textDecoration: "underline" }}>
					{objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE.title}
				</span>
			</div>
		</div>
	);
};

export default React.memo(SetupForms);
