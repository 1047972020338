import { useEffect } from "react";

const SupportPage = () => {
	useEffect(() => {
		document.title = "Support";
	});

	return (
		<div className="container">
			<div className="row mt-3">
				<div className="col"></div>
				<div className="col">
					<h1>BMCL Support</h1>
					<div className="mx-auto pt-3">
						<h2>We are happy to help!</h2>
						<h3>Contact us by phone or email:</h3>
						<p>
							<strong>Phone:</strong> <a href="tel:801-448-6367">(801) 448-6367</a>
						</p>
						<p>
							<strong>Email:</strong> <a href="email:ubmoffice9@gmail.com">ubmoffice9@gmail.com</a>
						</p>
					</div>
				</div>
				<div className="col"></div>
			</div>
			<div className="row">
				<div className="col"></div>
				<div className="col">
					<h2>Hours of Operation</h2>
					<h4>9-5 Weekdays</h4>
					<p>Closed on the following holidays:</p>
					<ul>
						<li>
							<strong>New Years Day</strong> January 1
						</li>
						<li>
							<strong>Memorial Day</strong> last Monday in May
						</li>
						<li>
							<strong>Independence Day</strong> July 4
						</li>
						<li>
							<strong>Pioneer Day</strong> July 24
						</li>
						<li>
							<strong>Labor Day</strong> 1st Monday in September
						</li>
						<li>
							<strong>Thanksgiving Day</strong> 4th Thursday in November
						</li>
						<li>
							<strong>Christmas Day</strong> December 25
						</li>
					</ul>
				</div>
				<div className="col"></div>
			</div>
		</div>
	);
};

export default SupportPage;
