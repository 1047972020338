const EmptyPanelMessage = ({ message }) => {
	return (
		<div
			style={{
				fontSize: "40px",
				textAlign: "center",
				color: "rgb(181, 181, 181)",
				fontWeight: "200",
				margin: "100px auto",
			}}
		>
			{message} <br />
		</div>
	);
};
export default EmptyPanelMessage;
