import React, { useState, useEffect } from "react";
import { EditSquareIcon, PlusSquareDottedIcon, CheckSquareIcon } from "../../BootstrapComponents/Icons/Icons";

/**
 *
 * @param headings
 * @param children
 * @constructor
 */
const HorizontalTabs = ({
	headings = ["Tab 1", "Tab 2", "Tab 3"],
	children = [],
	tabClicked,
	activeTab: _activeTab,
	allowEditMode = false,
	toggleEditMode,
	allowAddMode = false,
	addNewTab,
	classes,
	...other
}) => {
	//state variables
	const [activeTab, setActiveTab] = useState(0);
	const [showEditMode, setShowEditMode] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	useEffect(() => {
		if (_activeTab) setActiveTab(_activeTab);
	}, [_activeTab]);

	useEffect(() => {
		if (tabClicked) tabClicked(activeTab);
	}, [activeTab]);

	//Other Methods

	return (
		<div
			className={`${classes}`}
			style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}
		>
			<nav>
				<div className="nav nav-tabs" id="nav-tab" role="tablist" style={{ flexWrap: "nowrap" }}>
					{headings.map((heading, index) => (
						<div
							onClick={() => {
								setActiveTab(index);
								setShowLoading(false);
							}}
							key={index}
							className={`nav-link ${activeTab === index ? "active" : ""}`}
							type="button"
							role="tab"
						>
							{heading}
							{activeTab === index && showLoading ? (
								<div
									style={{ marginLeft: "10px" }}
									className="spinner-border spinner-border-sm text-primary"
									role="status"
								>
									<span className="sr-only">Loading...</span>
								</div>
							) : (
								<>
									{activeTab === index && allowEditMode ? (
										<>
											<button
												onClick={(e) => {
													// setShowLoading(true);
													e.preventDefault();
													e.stopPropagation();
													setShowEditMode((prev) => !prev);
													toggleEditMode();
													// setShowLoading(false);
												}}
												style={{
													display: "inline-block",
													cursor: "pointer",
													background: "transparent",
													border: "none",
													color: "#6666ff",
													marginLeft: "5px",
												}}
											>
												{showEditMode ? (
													<span title="Done">
														<CheckSquareIcon />
													</span>
												) : (
													<span title="Edit Title">
														<EditSquareIcon />
													</span>
												)}
											</button>
											{allowAddMode ? (
												<button
													onClick={(e) => {
														// setShowLoading(true);
														e.preventDefault();
														e.stopPropagation();
														setShowEditMode(true);
														addNewTab(activeTab);
														// setShowLoading(false);
													}}
													title="Copy Tab"
													style={{
														cursor: "pointer",
														background: "transparent",
														border: "none",
														color: "blue",
														marginRight: "-5px",
														marginLeft: "-3px",
													}}
												>
													<PlusSquareDottedIcon />
												</button>
											) : (
												""
											)}
										</>
									) : (
										""
									)}
								</>
							)}
						</div>
					))}
				</div>
			</nav>
			<div className="tab-content px-3" style={{ height: "calc(100% - 55px)", width: "100%", flex: "1" }}>
				{headings.map((heading, index) =>
					index === activeTab ? (
						<div
							className={`tab-panel ${activeTab === index ? "show active" : "d-none"}`}
							key={index}
							role="tabpanel"
							style={{
								height: "100%",
								width: "100%",
							}}
						>
							{Array.isArray(children) ? children[index] || `${heading} Contents` : children}
						</div>
					) : (
						""
					)
				)}
			</div>
		</div>
	);
};

export default HorizontalTabs;
