import React, { useState, useEffect, useRef } from "react";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";

import { SharedHistoryContext } from "./context/SharedHistoryContext";
import { SharedAutocompleteContext } from "./context/SharedAutocompleteContext";

import PlaygroundNodes from "./nodes/PlaygroundNodes";

import BlurPlugin from "./plugins/BlurPlugin";
import ClickableLinkPlugin from "./plugins/ClickableLinkPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import EquationsPlugin from "./plugins/EquationsPlugin";
import HorizontalRulePlugin from "./plugins/HorizontalRulePlugin";
import ImagesPlugin from "./plugins/ImagesPlugin";
import TabFocusPlugin from "./plugins/TabFocusPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";

import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";

import ErrorBoundary from "./ui/ErrorBoundary";
import Placeholder from "./ui/Placeholder";
import ContentEditable from "./ui/ContentEditable";

import "./editor.scss";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
/**
 * 116-511210.228.RCFNCMPT.415101: 0 - React Functional Component
 * Renders a rich text editor, we will assume the value is always in json format
 * @param value: _value
 * @constructor
 */
const RichTextEditor = ({ label, value: _value, handleChange, handleBlur, focus = false, help }) => {
	const [value, setValue] = useState("");

	useEffect(() => {
		if (_value && _value !== value) setValue(_value);
	}, [_value]);

	const placeholder = <Placeholder>Enter text...</Placeholder>;

	const editorStateRef = useRef();

	//Other Methods
	const onChange = (editorState) => {
		editorStateRef.current = editorState;
		// setValue(JSON.stringify(editorState));
	};

	const getEditorValue = () => {
		return JSON.stringify(editorStateRef.current);
	};

	const inputBlurred = () => {
		let newValue = getEditorValue();
		if (newValue !== value) handleBlur(newValue);
	};

	const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

	const onRef = (_floatingAnchorElem) => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	const initialConfig = {
		namespace: "MyEditor",
		theme: PlaygroundEditorTheme,
		nodes: [...PlaygroundNodes],
		onError: (error) => {
			throw error;
		},
		editorState: getValue(value),
	};

	return (
		<>
			{label}
			<LexicalComposer initialConfig={initialConfig}>
				<SharedHistoryContext>
					<SharedAutocompleteContext>
						<div className="editor-shell">
							<ToolbarPlugin />
							<div className="editor-container">
								<RichTextPlugin
									contentEditable={
										<div className="editor-scroller">
											<div className="editor" ref={onRef}>
												<ContentEditable />
											</div>
										</div>
									}
									placeholder={placeholder}
									ErrorBoundary={ErrorBoundary}
								/>
								<AutoLinkPlugin />
								<CodeHighlightPlugin />
								<ListPlugin />
								{focus && <AutoFocusPlugin />}
								<CheckListPlugin />
								<ImagesPlugin />
								<LinkPlugin />
								<ClickableLinkPlugin />
								<HorizontalRulePlugin />
								<EquationsPlugin />
								<TabFocusPlugin />
								<OnChangePlugin onChange={onChange} />
								<HistoryPlugin />
								<BlurPlugin handleBlur={inputBlurred} />
								<UpdateValuePlugin value={value} />
							</div>
						</div>
					</SharedAutocompleteContext>
				</SharedHistoryContext>
			</LexicalComposer>
		</>
	);
};

export default RichTextEditor;

const getValue = (value) => {
	try {
		JSON.parse(value);
		return value;
	} catch {
		return JSON.stringify({
			root: {
				children: [
					{
						children: [
							{
								detail: 0,
								format: 0,
								mode: "normal",
								style: "",
								text: `${value}`,
								type: "text",
								version: 1,
							},
						],
						direction: "ltr",
						format: "",
						indent: 0,
						type: "paragraph",
						version: 1,
					},
				],
				direction: "ltr",
				format: "",
				indent: 0,
				type: "root",
				version: 1,
			},
		});
	}
};

const UpdateValuePlugin = ({ value }) => {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (!value) return;

		/**
		 * As of now, the editor is only out of date when generating the default. In this case the value should be the string that we want to be in the Rich Text Editor
		 * We try parsing the value and only on error we try to update the state because otherwise the value is valid JSON state and we don't have an instance where it
		 * is valid JSON string and out of sync
		 */
		try {
			let parsed = JSON.parse(value);
			if (JSON.stringify(editor.getEditorState()) !== value) editor.setEditorState(parsed);
		} catch (e) {
			if (JSON.stringify(editor.getEditorState()) !== value) {
				const editorState = editor.parseEditorState(getValue(value));
				editor.setEditorState(editorState);
			}
		}
	}, [value]);
};
