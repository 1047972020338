import { useState } from "react";
import Grid from "@mui/material/Grid";
import LeftHeader from "./LeftHeader";
import CenterHeader from "./CenterHeader";
import RightHeader from "./RightHeader";
import "../ReferencingProtocol.css";

/**
 * A React Component for rendering a UBM Header
 * @param { uuid, _updateHeader }
 * @constructor
 */
const Header = ({ uuid, _updateHeader }) => {
	//state variables
	const [pageNumber, setPageNumber] = useState(0);
	const [leftHeader, setLeftHeader] = useState({});
	const [centerHeader, setCenterHeader] = useState({});
	const [rightHeader, setRightHeader] = useState({});

	//Other Methods
	/**
	 * Accepts the header type (left, center, right) the field to update and the value to set it to. Updates the header
	 * @param header, field, value
	 */
	const updateHeader = (header, field, value) => {
		//TODO: May be able to just call the post from here rather than relying on parent to update header info
		if (header === "leftHeader") {
			leftHeader[field] = value;
			setLeftHeader(leftHeader);
			_updateHeader({ leftHeader });
		} else if (header === "centerHeader") {
			centerHeader[field] = value;
			setCenterHeader(centerHeader);
			_updateHeader({ centerHeader });
		} else if (header === "rightHeader") {
			rightHeader[field] = value;
			setRightHeader(rightHeader);
			_updateHeader({ rightHeader });
		}
	};

	/**
	 * Submits the header changes to the database
	 * @param
	 */
	const saveHeader = () => {};

	/**
	 * Reverts the changes made to the header
	 * @param
	 */
	const discardChanges = () => {};

	return (
		<Grid container columns={{ xs: 12 }} className={"ubm-header"} style={{ padding: "15px 0px" }}>
			<Grid container item xs={12} className={""} style={{}} justifyContent={"space-between"}>
				<Grid item xs={4} className={""} style={{}}>
					<LeftHeader leftHeader={leftHeader} updateHeader={updateHeader} />
				</Grid>
				<Grid item xs={4} className={""} style={{}}>
					<CenterHeader centerHeader={centerHeader} updateHeader={updateHeader} />
				</Grid>
				<Grid item xs={4} className={""} style={{}}>
					<RightHeader rightHeader={rightHeader} pageNumber={pageNumber} updateHeader={updateHeader} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Header;
