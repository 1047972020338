import { v4 as uuidv4 } from "uuid";
import { getReferenceNo } from "./Referencing";

export const zeroRowUuid = "d63076c3-77b0-464f-aced-69cc79fa2cfe";

export const createMasterFileIndexRow = (ref) => {
	let reference = ref || "";
	let referenceNo = getReferenceNo(reference);
	return {
		uuid: uuidv4(),
		parentUuid: zeroRowUuid,
		objectPacketUuid: "",
		modelPositionUuid: "",
		reference,
		referenceNo,
		included: false,
		title: "",
		description: "",
		value: "",
		location: "",
		typeUuid: "",
		relationshipTypeUuid: "",
		appPositionUuid: "",
		appPacketUuid: "",
		modelUuid: "",
		tags: "",
	};
};
