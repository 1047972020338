export const removeDuplicateMatches = (array) => {
	let check = new Set();
	return array.filter((obj) => !check.has(obj[0]) && check.add(obj[0]));
};

export const removeDuplicates = (array, field) => {
	let check = new Set();
	return array.filter((obj) => !check.has(obj[field]) && check.add(obj[field]));
};

export const sortByTitle = (a, b) => {
	if (a.title < b.title) return -1;
	if (a.title > b.title) return 1;
	return 0;
};
