import { React, useRef } from "react";

/**
 * Renders a component that basically would handle all the drag over and drop functionality. Places an overlay with a message
 * @param elementId
 * @param other
 * @constructor
 */
const PanelOverlay = ({ elementId, getMessage, handleDrop: _handleDrop, ...other }) => {
	// const enteredPanel = useRef(false);
	// const leftPanel = useRef(false);

	const containerRef = useRef();
	const dragTimeout = useRef({});
	const runningTimeout = useRef(false);

	const startTimeout = () => {
		if (runningTimeout.current) return false;

		runningTimeout.current = true;

		dragTimeout.current = setTimeout(() => {
			runningTimeout.current = false;
		}, 1000);
		return true;
	};

	/**
	 * This is called when something is dropped onto an element.
	 * I am assuming that this will only be called when I want it to,
	 * which, as of now, is only when something is dropped onto the template
	 * or workspace panel.
	 * @param event
	 * @param panel
	 */
	const handleDrop = (event) => {
		removeOverlay();
		_handleDrop(event, elementId);
	};

	/**
	 * If we are dragging over a panel and it is the template or workspace panel, stop event from stopping itself
	 * This allows the handleDrop event to trigger in those 2 cases. Otherwise if we are not dragging over a panel
	 * or the panel is not one of those 2, let the event's default behavior cancel it out and the onDrop event will
	 * not fire
	 * @param event
	 * @param panel
	 */
	const handleDragOver = (event, panel) => {
		// if(panel && (panel === 'template-panel' || panel === 'workspace-panel' || panel === 'warehouse-panel')) {
		//     event.preventDefault();
		// }
		// else
		//     return;
		event.preventDefault();
	};

	const handleDragEnter = (event) => {
		let starting = startTimeout();
		if (!starting) return;

		//Get the message that needs displayed
		let dragMessage = getMessage(event, elementId);
		if (dragMessage) createNewOverlay(containerRef.current, dragMessage);
		else removeOverlay();
	};

	const handleDragLeave = (event) => {
		event.stopPropagation();

		//If the target of the event is inside the panel, don't remove the overlay
		// if(event.clientX > element.clientX && )
		if (document.getElementById(elementId).contains(event.target)) return;

		removeOverlay();
	};

	const handleDragEnd = (event) => {
		//Remove the overlay if there is one
		removeOverlay();
	};

	const createNewOverlay = (element, message) => {
		if (otherOverlayPresent) removeOverlay();
		else if (myOverlayPresent()) return;

		//Build the overlay
		let overlay = document.createElement("div");
		overlay.id = "overlay";
		overlay.innerHTML = message;
		overlay.style.zIndex = 99;
		overlay.style.top = "50%";
		overlay.style.left = 0;
		overlay.style.pointerEvents = "none";
		overlay.style.margin = "0 15px";
		overlay.classList.add(elementId);

		//Append the overlay to the element
		containerRef.current.classList.add("fading");
		containerRef.current.appendChild(overlay);

		//Should I remove the fade class from all elements that have it?
		// let fadeElements = document.findByCl
	};

	const otherOverlayPresent = () => {
		let element = document.getElementById("overlay");
		return !!(element && !element.classList.contains(elementId));
	};

	const myOverlayPresent = () => {
		let element = document.getElementById("overlay");
		return !!(element && element.classList.contains(elementId));
	};

	const removeOverlay = () => {
		_removeOverlay(document);
	};

	return (
		<div
			id={elementId}
			ref={containerRef}
			onDrop={handleDrop}
			onDragEnter={handleDragEnter}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDragEnd={handleDragEnd}
			style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}
		>
			{other.children}
		</div>
	);
};

export default PanelOverlay;

export const _removeOverlay = (document) => {
	let overlay = document.getElementById("overlay");
	if (overlay) overlay.remove();

	//Find all elements with fade and remove the class?
	let fadeElements = document.getElementsByClassName("fading");
	if (fadeElements && fadeElements.length > 0) {
		for (const key in fadeElements) {
			if (fadeElements[key].classList) fadeElements[key].classList.remove("fading");
		}
	}
};
