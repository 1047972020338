import React, { useEffect, useState } from "react";
import { useDispatch, useTrackedState } from "../../utils/store";
import { PlusIcon } from "../BootstrapComponents/Icons/Icons";
import {
	copyStandardObject,
	findObjectWithMatchingReleaseVersionInDestinationModel,
	FILTERED_OBJECT_GENERAL_TYPE,
	createObjectHierarchyRecord,
} from "../../utils/StandardObject";
import { deleteRow, getNextRef, sortByReference } from "../../utils/Referencing";
import { createMatchingHierarchyRecordAndUpdateState } from "../ReactGridComponents/SetupSheetWindow/SetupField";
import { SETUP_SHEET_OUTPUT_FIELD_TYPES } from "../../utils/SetupTypes";
import { updateChangeData } from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { getTopMostObject } from "../ReactGridComponents/Body/CreatorPanel/CreatorPanel";
import { getObjectGitRecord } from "../../utils/ApiUtils";

const ListInputFieldType = ({
	label,
	data,
	field,
	children = [],
	updateAttachableTypeMfi,
	setShowObjectSelectDialog,
	updateFieldToAttachTo,
	objectClicked,
	updateRow,
	plusClick: _plusClick,
	plus = true,
	editTitles = true,
	sendUpdates,
	removeObject,
	hierarchy,
}) => {
	// const [children, setChildren] = useState([]);
	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	// useEffect(() => {
	// 	setChildren(_children);
	// }, [_children?.length])

	const plusClick = async () => {
		//If we're passed a plus click, use that instead
		if (_plusClick) {
			_plusClick();
			return;
		}

		let dwRecord;
		//If we already know the type of object we need to make a copy of, grab it and make a copy
		//NEW WAY we tie the field directly to a uuid and version
		if (field.linkToObjectUuid && field.linkToObjectVersionUuid) {
			dwRecord = await findObjectWithMatchingReleaseVersionInDestinationModel(
				field.linkToObjectUuid,
				field.linkToObjectVersionUuid,
				sharedState.destinationModel
			);
		}
		//OLD WAY Links directly to an MFI record
		else if (field.setupListObjectMfiUuid)
			dwRecord = sharedState.destinationModel.find((row) => row.uuid === field.setupListObjectMfiUuid);
		else {
			updateFieldToAttachTo(field);

			//There is no object selected to copy from the data warehouse, so check the attachable types for this field
			if (field.attachableTypes && field.attachableTypes.length > 0) {
				let list = new Map();
				field.attachableTypes.forEach((objectAndVersion) => {
					//Get the object uuid and the version
					let [objectUuid, versionUuid] = objectAndVersion.split(",");
					let matchingRows = sharedState.destinationModel.filter(
						(row) =>
							row.standardObjectUuid === objectUuid ||
							row.sourceObjectTemplateUuid === objectUuid ||
							row.objectTypeUuid === objectUuid
					);
					matchingRows.forEach((row) => list.set(row.uuid, { ...row, parentUuid: "0" }));
				});

				updateAttachableTypeMfi([...list.values()].sort(sortByReference));
			}

			setShowObjectSelectDialog(true);
			return;
		}

		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

		if (!dwRecord) {
			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			return;
		}

		//Get the reference this object should have
		let attachTo = field;
		let lastChild = children[children.length - 1];

		let rowCopy = {},
			newRef = "";
		if (!lastChild) {
			newRef = attachTo.reference + ".01";
			rowCopy = copyStandardObject(attachTo, newRef, attachTo.uuid, sharedState.currentUser?.uuid, {
				newStockNumber: true,
				newVersion: true,
				resetLinkToFields: true,
			});
			rowCopy.inputType = "";
		} else {
			newRef = getNextRef(lastChild.reference).reference;
			rowCopy = copyStandardObject(lastChild, newRef, attachTo.uuid, sharedState.currentUser?.uuid, {
				newStockNumber: true,
				newVersion: true,
				resetLinkToFields: true,
			});
		}

		rowCopy.standardObjectUuid = dwRecord.standardObjectUuid;
		rowCopy.standardObjectVersionUuid = dwRecord.standardObjectVersionUuid;
		rowCopy.title = "(Edit Title)";
		rowCopy.reference = newRef;
		rowCopy.isObject = true;
		//Data Warehouse records now store the object generalTypeUuid as the 'generalTypeUuid' and MFI generalTypeUuid as 'mfiGeneralTypeUuid'
		rowCopy.generalTypeUuid = dwRecord.generalTypeUuid;
		rowCopy.objectTypeUuid = dwRecord.objectTypeUuid;
		//Mark it as a new object
		rowCopy.new = true;

		//Update the children array
		children.push(rowCopy);

		let newHierarchyRecord;
		//If data is passed in, assume the first entry is the ancestor of this new sub-object
		if (data?.length > 0) {
			//Get the hierarchy record from data
			let ancestorHierarchyRecord = data[0].objectHierarchy.find(
				(row) =>
					row.descendantStandardObjectUuid === data[0].uuid &&
					row.descendantStandardObjectVersionUuid === data[0].versionUuid
			);
			newHierarchyRecord = createObjectHierarchyRecord(ancestorHierarchyRecord, { ...rowCopy });
		} else {
			//Create the hierarchy record, add it and the new row to the global changes
			newHierarchyRecord = createMatchingHierarchyRecordAndUpdateState(
				dwRecord.standardObjectUuid,
				dwRecord.standardObjectVersionUuid,
				rowCopy,
				sharedState
			);
		}

		if (rowCopy.stockNumber && rowCopy.stockNumber.uuid)
			dispatch({ type: "UPDATE_CHANGED_STOCK_NUMBERS", data: rowCopy.stockNumber });

		let update = {
			objectRows: [rowCopy],
			objectHierarchy: [newHierarchyRecord],
		};
		sendUpdates(update);

		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	};

	return (
		<>
			{label}
			{plus && (
				<button
					className={"btn btn-outline-secondary"}
					style={{ padding: "5px 10px", margin: "5px 0px" }}
					onClick={plusClick}
				>
					<PlusIcon />
				</button>
			)}
			{children.map((child, index) => {
				if (child.generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE) {
					return SETUP_SHEET_OUTPUT_FIELD_TYPES.HARVESTED_OBJECT_VIEW.render({
						object: child,
						hierarchyRecord: data[0].objectHierarchy.find(
							(row) =>
								row.descendantStandardObjectUuid === child.uuid &&
								row.descendantStandardObjectVersionUuid === child.versionUuid
						),
						nodeClicked: () => objectClicked(child.uuid),
						updateRow: (newTitle) => updateRow(child.uuid, "title", newTitle),
						removeObject: removeObject,
					});
				}

				return SETUP_SHEET_OUTPUT_FIELD_TYPES.IS_OBJECT.render({
					child,
					folderIconClicked: () => objectClicked(child.uuid),
					handleBlur: editTitles
						? (val) => {
								child.title = val;
								updateRow(child.uuid, "title", val);
						  }
						: undefined,
					removeChild: removeObject,
					lastChild: index !== children.length - 1,
				});
			})}
		</>
	);
};

export default React.memo(ListInputFieldType, (prevProps, nextProps) => {
	let { field: prevField, children: prevChildren } = prevProps;
	let { field: nextField, children: nextChildren } = nextProps;

	return !(
		prevField.title !== nextField.title ||
		prevField.value !== nextField.value ||
		prevField.inputType !== nextField.inputType ||
		prevChildren?.length !== nextChildren?.length
	);
});
