import React, { useState, useEffect } from "react";
import Iframe from "../Iframe/Iframe";
import LoaderForm from "./LoaderForm";
import { useStickyState } from "../../utils/StickyState";
import { wopiHost, wopiClientHost } from "../../utils/WopiServiceUtils";
import { useTrackedState } from "../../utils/store";

const Wopi = ({ location }) => {
	const sharedState = useTrackedState();

	const [loading, setLoading] = useState(false);
	const [wopiObject, setWopiObject] = useState(false);
	const [wopiFileUrl, setWopiFileUrl] = useState(false);

	const [wopiUrl, setWopiUrl] = useStickyState("", "wopiUrl");
	const [wopiToken, setWopiToken] = useStickyState("", "wopiToken");
	const wopiSrc = `${location}?UserFriendlyName=${
		(sharedState.currentUser?.firstName || "Anonymous") + (sharedState.currentUser?.lastName || "Otter")
	}&UserId=${sharedState.currentUser?.uuid}`;

	useEffect(() => {
		if (location && location.split("/").pop()) {
			if (wopiUrl && wopiToken) {
				setWopiFileUrl(`${wopiUrl}WOPISrc=${wopiSrc}`);
				setLoading(true);
			} else {
				getWopiUrl();
			}
			setWopiObject(true);
		} else {
			setWopiObject(false);
		}
	}, [location]);

	const getWopiUrl = () => {
		fetch(`${wopiHost()}/collaboraUrl?server=${wopiClientHost()}`)
			.then((response) => response.json())
			.then((data) => {
				setWopiToken(data.token);
				setWopiUrl(data.url);

				setWopiFileUrl(`${data.url}WOPISrc=${wopiSrc}`);
				setLoading(true);
			})
			.catch((err) => {
				console.error(err);
				let doc = document.getElementById("collabora-online-viewer").contentWindow.document;
				doc.open().write(
					'<h4 style="color:red">WOPI Host not responding. Contact your administrator, or check the console logs for more information.</h4>'
				);
				doc.close();
			});
	};

	return (
		<>
			{loading ? <LoaderForm url={wopiFileUrl} token={wopiToken} setLoading={setLoading} /> : ""}
			{wopiObject ? (
				<Iframe
					id={"collabora-online-viewer"}
					name={"collabora-online-viewer"}
					style={{ height: "100%", width: "100%" }}
				/>
			) : (
				""
			)}
		</>
	);
};

export default Wopi;
