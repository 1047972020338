import { useEffect } from "react";
import { Utilities } from "../../utils/StaticData";
import { useDispatch, useTrackedState } from "../../utils/store";
import Tooltip from "@mui/material/Tooltip";

/**
 *
 * @param
 * @constructor
 */
const AppHeaderBreadcrumbs = () => {
	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	const updateEnvironment = ({ userRole, company, utility }) => {
		dispatch({ type: "UPDATE_ENVIRONMENT", data: { userRole, company, utility } });
	};

	useEffect(() => {
		// If there is no set utility, default to the first one the user has access to
		if (!sharedState.environment.utility) {
			updateEnvironment({ utility: Utilities[0] });
		}
	}, []);

	return (
		<Tooltip title={`The currently selected utility is ${sharedState.environment.utility?.title}`}>
			<div
				style={{
					width: "50%",
					marginLeft: "auto",
					marginRight: "auto",
					textAlign: "center",
					fontWeight: "600",
					fontSize: "24px",
					paddingTop: "7px", // Centers the text, feel free to change if you know of a better way
				}}
				// title={`The currently selected utility is ${sharedState.environment.utility?.title}`}
			>
				{sharedState.environment.utility?.title}
				<sup style={{ fontSize: "10px", color: "red", position: "absolute", top: "inherit" }}>
					(Under Construction)
				</sup>
			</div>
		</Tooltip>
	);
};

export default AppHeaderBreadcrumbs;
