import React, { useState, useEffect, useRef } from "react";
import ReactGrid from "../../ReactGrid/ReactGrid";
import { buildPanelLocation, PANEL_CONTENT_TYPES } from "../../../utils/PanelTypes";
import { useDispatch, useTrackedState } from "../../../utils/store";
import {
	copyStandardObject,
	createMapToSaveNewObject,
	createNewObject,
	saveNewObject,
} from "../../../utils/StandardObject";

/**
 * Renders the destination model with a visual rep for things clicked on. Currently this is the panel on the far left
 * @param
 * @constructor
 */
const DestinationAndVisualRepPanel = ({ panelId, panelGroup, allowResizing }) => {
	const [uuidAndVersion, setUuidAndVersion] = useState({});
	const [layout, setLayout] = useState([]);
	const object = useRef({});

	//Global state used to store the existing objects that can be shared across components
	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	const _dataWarehousePanel = PANEL_CONTENT_TYPES.DESTINATION_MODEL_PANEL;
	const _objectPanel = PANEL_CONTENT_TYPES.OBJECT_MASTER_FILE_INDEX_PANEL;

	useEffect(() => {
		let dataWarehouseLocation = buildPanelLocation("DESTINATION_MODEL_PANEL", _dataWarehousePanel.uuid, { x: 0 });
		let objectLocation = buildPanelLocation("OBJECT_MASTER_FILE_INDEX_PANEL", _objectPanel.uuid, { x: 420 });
		setLayout([dataWarehouseLocation, objectLocation]);
	}, []);

	useEffect(() => {
		if (sharedState.destinationModel) setUuidAndVersion({});
	}, [sharedState.destinationModel, sharedState.destinationModel?.length]);

	const rowSelected = (row) => {
		if (
			row.standardObjectUuid &&
			row.standardObjectVersionUuid &&
			uuidAndVersion.uuid !== row.standardObjectUuid &&
			uuidAndVersion.versionUuid !== row.standardObjectVersionUuid
		) {
			object.current = { title: row.title, reference: row.reference };
			setUuidAndVersion({ uuid: row.standardObjectUuid, versionUuid: row.standardObjectVersionUuid });
		} else {
			object.current = {};
			setUuidAndVersion({});
		}
	};

	const newObjectDropOnDestination = async (draggedRow, changesBasedOnDrop) => {
		//Create a new object based on the dragged row
		let newObj = await createNewObject({ object: draggedRow });
		//When the tree creates the row that goes in the destination model mfi, it doesn't set the standardObjectUuid and version correctly so we need to update
		const match = changesBasedOnDrop.find(
			(row) =>
				row.standardObjectUuid === draggedRow.uuid && row.standardObjectVersionUuid === draggedRow.versionUuid
		);
		match.standardObjectUuid = newObj.uuid;
		saveNewObject(newObj, { mfiChanges: changesBasedOnDrop });
	};

	return (
		<ReactGrid
			layoutId={panelId}
			layout={layout}
			dynamicHeight={true}
			allowResize={allowResizing}
			draggableHandle={`.${panelGroup}-subPanel`}
			draggable={allowResizing}
		>
			<div key={PANEL_CONTENT_TYPES.DESTINATION_MODEL_PANEL.uuid}>
				{_dataWarehousePanel.Render(
					{
						panelId,
						panelGroup: panelGroup + "-subPanel",
						panelTitle: "Data Warehouse",
						rowSelected,
						allowDrop: true,
						handleTreeDrop: newObjectDropOnDestination,
					},
					sharedState,
					dispatch
				)}
			</div>
			<div key={PANEL_CONTENT_TYPES.OBJECT_MASTER_FILE_INDEX_PANEL.uuid}>
				{_objectPanel.Render(
					{
						panelId,
						panelGroup: panelGroup + "-subPanel",
						...uuidAndVersion,
						...object.current,
						panelTitle: "Preview",
						origin: "template-panel",
						referenceType: "DW",
					},
					sharedState,
					dispatch
				)}
			</div>
		</ReactGrid>
	);
};

export default DestinationAndVisualRepPanel;
