import { objectWorkspacePanels } from "./Panel";
import { v4 as uuidv4 } from "uuid";
import { createNewMfiRow, getObjectIdAndVersionUuid, updateStandardObjectVersion } from "./StandardObject";
import { getCall, getUrl, postCall } from "./ApiUtils";
import {
	createNewChangeRequestMfi,
	objectStates,
	stages,
} from "../components/VersionControl/ChangeRequest/ChangeRequestForm";
import { viewTitles } from "./View";

export const dataObjects = {
	MASTER_FILE_INDEX_ROW: {
		getUrl: "getUbmDatawarehouse",
		setUrl: "updateMfi",
		defaultPanel: objectWorkspacePanels.DATA_WAREHOUSE,
		title: "Master File Index Row",
	},
	STANDARD_OBJECT_GIT: {
		getUrl: "getObjectMfi",
		setUrl: "saveObjectRows",
		defaultPanel: objectWorkspacePanels.DATA_WAREHOUSE,
		title: "Standard Object Git",
	},
	OPEN_POINT: {
		getUrl: "getOpenPoints",
		setUrl: "saveOpenPoints",
		defaultPanel: objectWorkspacePanels.DATA_WAREHOUSE,
		title: "Open Point",
	},
	OBJECT_HIERARCHY: {
		getUrl: "",
		setUrl: "",
		defaultPanel: objectWorkspacePanels.OBJECT_HIERARCHY_PANEL,
		title: "Object Hierarchy",
	},
	CHANGE_REQUEST: {
		getUrl: "getChangeRequest",
		setUrl: "saveChangeRequest",
		defaultPanel: objectWorkspacePanels.DATA_WAREHOUSE,
		title: "Change Request",
		save: async function (objectChangeData, view, currentUser, callback) {
			let changeRequest = objectChangeData.current[view][this.title + "-changed"];
			let currentMfi =
				objectChangeData.current[view][dataObjects.STANDARD_OBJECT_GIT.title + "-changed"].currentMfi;
			let suggestedChangeMfi =
				objectChangeData.current[view][dataObjects.STANDARD_OBJECT_GIT.title + "-changed"].suggestedChangeMfi;
			let newVersionMfi =
				objectChangeData.current[view][dataObjects.STANDARD_OBJECT_GIT.title + "-changed"].newVersionMfi;
			let changeRequestMfi = objectChangeData.current[view][dataObjects.MASTER_FILE_INDEX_ROW.title + "-changed"];

			/*
			 * On save we need to create a master file index for the change request
			 * We need to copy the current object mfi and the suggested change mfi and attach it to the change request master file index
			 * In the future we would only store the parts that matter
			 *
			 * To create a new copy we would copy them exactly and save it with a new version
			 * Current Object would have a version of ChRq#-Current Document
			 * Suggested Changes would have a version of ChRq#-Suggested Changes
			 *
			 */
			const handleChangeRequestSave = async (draft) => {
				changeRequest.requestedDate = new Date(changeRequest.requestedDate);

				if (draft) {
					changeRequest.status = stages.DRAFT;
				} else if (!changeRequest.status) {
					changeRequest.status = stages.PROPOSED;
				} else if (view === viewTitles.REVIEW_CHANGE_REQUEST_GATEKEEPER) {
					changeRequest.status = stages.APPROVED;
				}

				let currentMfiToSave = [...currentMfi];

				if (!changeRequest.uuid) changeRequest.uuid = uuidv4();

				if (changeRequest.ubmItemUuid == null) {
					let ubmItemUuid = uuidv4();
					changeRequest.ubmItemUuid = ubmItemUuid;
					changeRequest.ubmItem.uuid = ubmItemUuid;
					changeRequest.ubmItem.itemUuid = currentMfiToSave[0].uuid;
					changeRequest.ubmItem.itemVersionUuid = currentMfiToSave[0].versionUuid;
				}

				let suggestedChangeMfiToSave = suggestedChangeMfi ? [...suggestedChangeMfi] : undefined;

				if (currentMfiToSave) {
					if (view === viewTitles.CREATE_CHANGE_REQUEST)
						currentMfiToSave[0] = updateStandardObjectVersion(
							{ ...currentMfiToSave[0] },
							currentMfiToSave[0].versionControl.objectVersionNumber,
							objectStates.CURRENTOBJECT
						);
					currentMfiToSave[0].parentUuid = null;
				}

				if (suggestedChangeMfiToSave) {
					if (view === viewTitles.CREATE_CHANGE_REQUEST)
						suggestedChangeMfiToSave[0] = updateStandardObjectVersion(
							{ ...suggestedChangeMfiToSave[0] },
							suggestedChangeMfiToSave[0].versionControl.objectVersionNumber,
							objectStates.SUGGESTEDCHANGE
						);
					//We need to unset the parentUuid as it was only set to
					suggestedChangeMfiToSave[0].parentUuid = null;
				}

				let newVersionMfiToSave;
				if (newVersionMfi) {
					newVersionMfiToSave = [...newVersionMfi];
				}

				await saveChangeRequest(changeRequest, currentMfiToSave, suggestedChangeMfiToSave, newVersionMfiToSave);
				// setChangeRequest({...newChangeRequest()});
				// resetSaveClicked();
				// setShowOpenPointFormDialog(false);
			};

			const saveChangeRequest = async (
				changeRequest,
				currentMfiToSave,
				suggestedChangeMfiToSave,
				newVersionMfiToSave
			) => {
				// let currentUser = sharedState.currentUser;
				//We only need to create everything on a new change request, in that case there will be no change request master file index
				if (!changeRequest.masterFileIndexUuid) {
					let changeRequestFolderRow = await getCall(getUrl("getChangeRequestMfiRow"));

					let nextChangeRequestPacketNumber = await getCall(getUrl("getNextChangeRequestNumber"));
					changeRequestMfi = await createNewChangeRequestMfi(
						changeRequestFolderRow,
						nextChangeRequestPacketNumber,
						currentUser
					);
					changeRequest.masterFileIndexUuid = changeRequestMfi.mfiRows[0].uuid;

					let currentObjectRow = createNewMfiRow(
						changeRequestMfi.currentObjectsRow.reference + ".01",
						topCurrentObjectMfiRow.title,
						changeRequestMfi.currentObjectsRow.uuid,
						currentUser.uuid
					);
					let topCurrentObjectMfiRow = currentMfiToSave[0];
					currentObjectRow.standardObjectUuid = topCurrentObjectMfiRow.uuid;
					currentObjectRow.standardObjectVersionUuid = topCurrentObjectMfiRow.versionUuid;

					changeRequestMfi.mfiRows.push(currentObjectRow);

					let suggestedChangeRow = createNewMfiRow(
						changeRequestMfi.suggestedChangesRow.reference + ".01",
						topSuggestedChangeMfiRow.title,
						changeRequestMfi.suggestedChangesRow.uuid,
						currentUser.uuid
					);
					let topSuggestedChangeMfiRow = suggestedChangeMfiToSave[0];
					suggestedChangeRow.standardObjectUuid = topSuggestedChangeMfiRow.uuid;
					suggestedChangeRow.standardObjectVersionUuid = topSuggestedChangeMfiRow.versionUuid;
					changeRequestMfi.mfiRows.push(suggestedChangeRow);

					changeRequest.changeRequestPacketNumber = nextChangeRequestPacketNumber;
				}

				if ((currentMfiToSave && suggestedChangeMfiToSave) || (currentMfiToSave && newVersionMfiToSave)) {
					let mfiUpdate = {
						objectRowsToUpdate: {},
					};

					let currentKey = getObjectIdAndVersionUuid(currentMfiToSave[0]);
					mfiUpdate.objectRowsToUpdate[currentKey] = currentMfiToSave;

					if (suggestedChangeMfiToSave) {
						let suggestedChangeKey = getObjectIdAndVersionUuid(suggestedChangeMfiToSave[0]);
						mfiUpdate.objectRowsToUpdate[suggestedChangeKey] = suggestedChangeMfiToSave;
					}

					if (newVersionMfiToSave) {
						let newVersionKey = getObjectIdAndVersionUuid(suggestedChangeMfiToSave[0]);
						mfiUpdate.objectRowsToUpdate[newVersionKey] = newVersionMfiToSave;
					}

					let url = getUrl("saveObjectRows");
					await postCall(url, mfiUpdate);
				}

				if (changeRequestMfi) {
					/*
					 * The change request mfi has 2 possible organizations
					 * {mfiRows: [{mfi row}, {mfi row}], ...} and [{mfi row}, {mfi row}]
					 */
					let mfiUpdate = {
						mfiRowsToUpdate: changeRequestMfi.mfiRows || changeRequestMfi,
					};
					let url = getUrl("updateMfi");
					await postCall(url, mfiUpdate);
				}

				changeRequest.createdBy = currentUser.uuid;
				changeRequest.createdAt = Date.now();

				await postCall(getUrl("saveChangeRequest"), changeRequest);
			};

			await handleChangeRequestSave(false);
		},
	},
};
