import React, { useEffect, useRef } from "react";

const LoaderForm = ({ url, token, setLoading }) => {
	const formRef = useRef(null);
	useEffect(() => {
		formRef.current.submit();
		setLoading(false);
	});
	return (
		<div style={{ display: "none" }}>
			<form
				ref={formRef}
				action={url}
				encType="multipart/form-data"
				method="post"
				target="collabora-online-viewer"
				id="collabora-submit-form"
			>
				<input name="access_token" value={token} type="hidden" id="access-token" />
				<input
					name="css_variables"
					value="--color-main-background=#f3f7fd;--color-main-text=#000"
					type="hidden"
				/>
				<input
					name="ui_defaults"
					value="SpreadsheetSidebar=false;PresentationSidebar=false;TextSidebar=false"
					type="hidden"
				/>
				<input type="submit" value="" />
			</form>
		</div>
	);
};

export default LoaderForm;
