function checkDuplicateReferences(list) {
	let references = list.map((row) => row.reference);
	return references.filter((item, index) => references.indexOf(item) !== index);
}

function checkMissingParent(list) {
	let uuids = list.map((row) => row.uuid);
	return list
		.filter((row) => !uuids.includes(row.parentUuid))
		.map((row) => {
			return { ...row, label: `${row.reference} - ${row.title}` };
		});
}

function getTopRecords(list) {
	return list.filter((row) => row.label.startsWith("0 -") || row.label.startsWith(" -"));
}

function checkDuplicateRows(list) {
	let uuids = list.map((row) => row.uuid);
	return uuids.filter((item, index) => uuids.indexOf(item) !== index);
}

function getObjectSize(list) {
	// Rough Size https://stackoverflow.com/a/11900218/3727991
	let objectList = [];
	let stack = [list];
	let bytes = 0;

	while (stack.length) {
		let value = stack.pop();

		if (typeof value === "boolean") {
			bytes += 4;
		} else if (typeof value === "string") {
			bytes += value.length * 2;
		} else if (typeof value === "number") {
			bytes += 8;
		} else if (typeof value === "object" && objectList.indexOf(value) === -1) {
			objectList.push(value);

			for (let i in value) {
				stack.push(value[i]);
			}
		}
	}
	return bytes;
}

function getTypes(list) {
	let objects_with_types = list.filter((row) => row.objectType?.title);
	return objects_with_types.map((row) => ({
		reference: row.reference,
		type: row.objectType.title,
		title: row.title,
	}));
}

function getTags(list) {
	let objects_with_tags = list.filter((row) => row.objectTags);
	return objects_with_tags.map((row) => ({ reference: row.reference, tags: row.objectTags, title: row.title }));
}

function numberWithCommas(x) {
	// https://stackoverflow.com/a/2901298/3727991
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function checkErrors(list) {
	let duplicateReferences = checkDuplicateReferences(list);
	let missingParents = checkMissingParent(list);
	let topRecords = getTopRecords(missingParents);
	let duplicateRows = checkDuplicateRows(list);
	let types = getTypes(list);
	let tags = getTags(list);

	return {
		duplicateReferences,
		missingParents,
		topRecords,
		duplicateRows,
		types,
		tags,
		object: list[0],
		objectSize: `~${numberWithCommas(getObjectSize(list))} Bytes`,
	};
}
