import React, { useState } from "react";
import "./ChangeSummary.css";
import ChangeItem from "./ChangeItem";
import Comparison from "../Comparison/Comparison";
import ChangeDetail from "./ChangeDetail";
import { ChevronLeft, ChevronRight } from "@mui/icons-material"; // Import the CSS file for styling

const ChangeSummary = ({ changes, additions, deletions }) => {
	const [updateDetail, setUpdateDetail] = useState();

	return (
		<div className="change-summary">
			{changes && changes.size > 0 && (
				<div>
					<h3>Changed: {changes.size}</h3>
					{updateDetail ? (
						<>
							<ul>
								<ChangeItem
									title={updateDetail.itemChanging?.title}
									additions={updateDetail.added?.length}
									changes={updateDetail.modified?.length}
									deletions={updateDetail.deleted?.length}
									onClick={(e) => setUpdateDetail(null)}
									left={true}
								/>
							</ul>
							<ChangeDetail
								additions={updateDetail.added}
								changes={updateDetail.modified}
								deletions={updateDetail.deleted}
							/>
						</>
					) : (
						<ul>
							{[...changes.values()].map((change, index) => (
								<ChangeItem
									key={"change-" + index}
									title={change.itemChanging?.title}
									additions={change.added?.length}
									changes={change.modified?.length}
									deletions={change.deleted?.length}
									onClick={(e) => {
										console.log("clicked", change);
										setUpdateDetail(change);
									}}
								/>
							))}
						</ul>
					)}
				</div>
			)}

			{additions.length > 0 && (
				<div>
					<h3>Newly Added: {additions.length}</h3>
					<ul>
						{additions.map((addition, index) => (
							<li key={"addition-" + index}>
								{addition.descendantStandardObjectUuid}/{addition.descendantStandardObjectVersionUuid}
							</li>
						))}
					</ul>
				</div>
			)}

			{deletions.length > 0 && (
				<div>
					<h3>Sub-objects Deleted: {deletions.length}</h3>
					<ul>
						{deletions.map((deletion, index) => (
							<li key={"deletion-" + index}>
								{deletion.descendantStandardObjectUuid}/{deletion.descendantStandardObjectVersionUuid}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default ChangeSummary;
