import React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { v4 as uuidv4 } from "uuid";

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog(props) {
	//If the value passed in is text, get it from the options list
	const [value, setValue] = React.useState(
		props.options && props.options.length > 0 && typeof props.value != "object"
			? props.options.find((o) => {
					if (o.code == props.value) {
						props.setState(o);
						return true;
					}
					return false;
			  })
			: props.value
	);
	const [open, toggleOpen] = React.useState(false);

	const handleClose = () => {
		setDialogValue({
			code: "",
			name: "",
		});

		toggleOpen(false);
	};

	const [dialogValue, setDialogValue] = React.useState({
		code: "",
		name: "",
	});

	/*TODO we need to make so this actually adds it to the list when I add a new option,
       this entails adding it to the list of items that populates the select,
       on a cancel of creating the stock number it will not create the row, on a submit it will create the row
       Not completely correct, it will only create the new row on commit changes
     */
	const handleSubmit = (event) => {
		event.preventDefault();

		//Creates a new item, if the code field is supposed to be a string, don't parse it, otherwise parse it to an integer
		var newItem = {
			uuid: uuidv4(),
			code: typeof props.codeFieldType == "string" ? dialogValue.code : parseInt(dialogValue.code, 10),
			name: dialogValue.name,
		};
		setValue(newItem);
		props.addItem(newItem);
		props.setState(newItem);

		handleClose();
	};

	return (
		<React.Fragment>
			<Autocomplete
				value={
					props.options && props.options.length > 0 && typeof value != "object"
						? props.options.find((o) => {
								if (o.code == value) {
									props.setState(o);
									return true;
								}
								return false;
						  })
						: value
				}
				onChange={(event, newValue) => {
					if (typeof newValue === "string") {
						// timeout to avoid instant validation of the dialog's form.
						setTimeout(() => {
							toggleOpen(true);
							setDialogValue({
								code: "",
								name: newValue,
							});
						});
					} else if (newValue && newValue.inputValue) {
						toggleOpen(true);
						var value = newValue.inputValue.trimEnd();
						var code = "";
						var name = "";
						if (!isNaN(value)) code = value;
						else if (!isNaN(parseInt(value.split(" ")[0]))) {
							code = value.split(" ")[0];
							name = value.split(" ").length > 1 ? value.substring(value.indexOf(" ")).trimStart() : "";
						} else name = value;

						setDialogValue({ code: code, name: name });
					} else if (!newValue) {
						newValue = { code: "", name: "" };
						setValue(newValue);
						props.setState(newValue);
					} else {
						setValue(newValue);
						props.setState(newValue);
					}
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);

					if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
						filtered.push({
							inputValue: params.inputValue,
							name: `Add "${params.inputValue}"`,
						});
					}

					return filtered;
				}}
				id={props.id}
				options={props.options}
				getOptionLabel={(option) => {
					// e.g value selected with enter, right from the input
					//Adding a '' makes so when it's a number it returns as a string
					if (typeof option === "string" || typeof option === "number") {
						return option + "";
					}
					if (option.code) {
						return (option.code || "") + " " + (option.name || "");
					}
					return option.name;
				}}
				selectOnFocus
				clearOnBlur
				autoHighlight
				autoComplete
				handleHomeEndKeys
				// renderOption={(props, option, state) => {
				// 	console.log("rendering option...", option);
				// 	return [props, ((option.code || "") + " " + (option.name || "")), state.index]
				// }}
				style={{ width: 300 }}
				freeSolo
				renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
			/>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<form onSubmit={handleSubmit}>
					<DialogTitle id="form-dialog-title">Add a new {props.label}</DialogTitle>
					<DialogContent>
						<DialogContentText>{props.message}</DialogContentText>
						<TextField
							autoFocus
							margin="dense"
							id="code"
							value={dialogValue.code}
							onChange={(event) => setDialogValue({ ...dialogValue, code: event.target.value })}
							label={props.codeField}
							type={props.codeFieldType}
						/>
						<TextField
							margin="dense"
							id="name"
							value={dialogValue.name}
							onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
							label="Name"
							type="text"
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancel
						</Button>
						<Button type="submit" color="primary">
							Add
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
}
