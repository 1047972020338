import React from "react";

function useStickyState(defaultValue, key) {
	const [value, setValue] = React.useState(() => {
		const stickyValue = window.localStorage.getItem(key);
		return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
	});
	React.useEffect(() => {
		window.localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);
	return [value, setValue];
}

function useStickyValue(defaultValue, key) {
	const getValue = () => {
		const stickyValue = window.localStorage.getItem(key);
		return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
	};

	const setValue = (newValue) => {
		window.localStorage.setItem(key, JSON.stringify(newValue));
	};

	return [getValue(), setValue];
}

export { useStickyState, useStickyValue };
