import { useStickyState } from "./StickyState";
import { useState } from "react";

const useStickyArray = (defaultValue, id) => {
	const [array, setArray] = useStickyState(defaultValue, id);

	const push = (element) => setArray((a) => [...a, element]);
	const filter = (callback) => setArray((a) => a.filter(callback));
	const update = (index, newElement) =>
		setArray((a) => [...a.slice(0, index), newElement, ...a.slice(index + 1, a.length)]);
	const remove = (index) => setArray((a) => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
	const clear = () => setArray([]);

	return { array, set: setArray, push, filter, update, remove, clear };
};

export default useStickyArray;

export const useArray = (defaultValue, id) => {
	const [array, setArray] = useState(defaultValue, id);

	const push = (element) => setArray((a) => [...a, element]);
	const filter = (callback) => setArray((a) => a.filter(callback));
	const update = (index, newElement) =>
		setArray((a) => [...a.slice(0, index), newElement, ...a.slice(index + 1, a.length)]);
	const remove = (index) => setArray((a) => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
	const clear = () => setArray([]);

	return { array, set: setArray, push, filter, update, remove, clear };
};
