import { objectWorkspacePanels } from "./Panel";
import { dataObjects } from "./DataObject";
import { DIALOGS } from "./DialogUtilty";

const defaultPanels = [
	objectWorkspacePanels.SOURCE_PANEL,
	objectWorkspacePanels.DATA_WAREHOUSE,
	objectWorkspacePanels.SOURCE_OBJECT_TEMPLATE_PANEL,
	objectWorkspacePanels.WORKSPACE_PANEL,
	objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE,
	objectWorkspacePanels.SETUP_FORMS,
	objectWorkspacePanels.VISUAL_REP,
	objectWorkspacePanels.MODELS_PANEL,
	objectWorkspacePanels.DESTINATION_PANEL,
];

const defaultDialogs = [DIALOGS.UPLOAD_DW_DIALOG];

export const viewTitles = {
	DEFAULT: "Model, App, Object Creator IDE",
	CREATE_CHANGE_REQUEST: "Version Control Utility",
	REVIEW_CHANGE_REQUEST_RECEIVER: "Review Change Request (Receiver of Suggested Changes)",
	REVIEW_CHANGE_REQUEST_GATEKEEPER: "Review Change Request (Gatekeeper)",
	CREATE_FIX_PACKET: "Create Fix Packet",
	REVIEW_FIX_PACKET: "Review Fix Packet",
	PATCH: "Patch",
	RELEASE: "Release",
	UPDATE: "Update",
	OPEN_POINT: "Open Point Utility",
};

export const Views = (view) => {
	const createChangeRequestView = {
		title: viewTitles.CREATE_CHANGE_REQUEST,
		openPanels: [
			{ ...objectWorkspacePanels.UBM_FORM, title: "Change Request Form" },
			{ ...objectWorkspacePanels.SOURCE_OBJECT_TEMPLATE_PANEL, title: "Current Object MFI" },
			{ ...objectWorkspacePanels.COMPARISON, title: "Suggested Changes" },
			{ ...objectWorkspacePanels.WORKSPACE_PANEL, title: "Suggested Change" },
			{ ...objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE, title: "Suggested Change MFI" },
			objectWorkspacePanels.SETUP_FORMS,
			objectWorkspacePanels.VISUAL_REP,
		],
		dialogs: [],
		save: async function (objectChangeData, view, currentUser, callback) {
			await dataObjects.CHANGE_REQUEST.save(objectChangeData, view, currentUser, callback);
			return false;
		},
	};

	const reviewChangeRequestReceiverView = {
		title: viewTitles.REVIEW_CHANGE_REQUEST_RECEIVER,
		openPanels: [
			{ ...objectWorkspacePanels.TABLE_OF_CONTENTS, title: "Suggested Changes MFI" },
			{ ...objectWorkspacePanels.UBM_FORM, title: "Change Request Form" },
			{ ...objectWorkspacePanels.COMPARISON, title: "Suggested Changes" },
			{ ...objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE, title: "Suggested Change MFI" },
			objectWorkspacePanels.SETUP_FORMS,
			objectWorkspacePanels.VISUAL_REP,
		],
		dialogs: [],
		save: async function (objectChangeData, view, currentUser, callback) {
			await dataObjects.CHANGE_REQUEST.save(objectChangeData, view, currentUser, callback);
			return false;
		},
	};

	const reviewChangeRequestGatekeeper = {
		title: viewTitles.REVIEW_CHANGE_REQUEST_GATEKEEPER,
		openPanels: [
			{ ...objectWorkspacePanels.TABLE_OF_CONTENTS, title: "Change Request TOC" },
			{ ...objectWorkspacePanels.UBM_FORM, title: "Change Request Form" },
			{ ...objectWorkspacePanels.COMPARISON, title: "Suggested Changes" },
			{ ...objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE, title: "Suggested Change MFI" },
			objectWorkspacePanels.SETUP_FORMS,
			objectWorkspacePanels.VISUAL_REP,
		],
		dialogs: [],
		save: async function (objectChangeData, view, currentUser, callback) {
			await dataObjects.CHANGE_REQUEST.save(objectChangeData, view, currentUser, callback);
			return false;
		},
	};

	const createFixPacketView = {
		title: viewTitles.CREATE_FIX_PACKET,
		openPanels: [
			{ ...objectWorkspacePanels.TABLE_OF_CONTENTS, title: "Fix Packet TOC" },
			{ ...objectWorkspacePanels.UBM_FORM, title: "Version Control Log" },
			{ ...objectWorkspacePanels.COMPARISON, title: "New Version" },
			{ ...objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE, title: "New Version MFI" },
			objectWorkspacePanels.SETUP_FORMS,
			objectWorkspacePanels.VISUAL_REP,
		],
		save: async function (objectChangeData, view, currentUser, callback) {
			await dataObjects.CHANGE_REQUEST.save(objectChangeData, view, currentUser, callback);
			return false;
		},
	};

	const reviewFixPacketView = {
		title: viewTitles.REVIEW_FIX_PACKET,
		openPanels: [
			{ ...objectWorkspacePanels.TABLE_OF_CONTENTS, title: "Fix Packet TOC" },
			{ ...objectWorkspacePanels.UBM_FORM, title: "Version Control Log" },
			{ ...objectWorkspacePanels.COMPARISON, title: "New Version" },
			{ ...objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE, title: "New Version MFI" },
			objectWorkspacePanels.SETUP_FORMS,
			objectWorkspacePanels.VISUAL_REP,
		],
		dialogs: [],
		save: async function (objectChangeData, view, currentUser, callback) {
			await dataObjects.CHANGE_REQUEST.save(objectChangeData, view, currentUser, callback);
			return false;
		},
	};

	const patchView = {
		title: viewTitles.PATCH,
	};

	const releaseView = {
		title: viewTitles.RELEASE,
	};

	const updateView = {
		title: viewTitles.UPDATE,
		openPanels: [
			// objectWorkspacePanels.DATA_WAREHOUSE,
			// objectWorkspacePanels.SOURCE_OBJECT_TEMPLATE_PANEL,
			{ ...objectWorkspacePanels.COMPARISON, title: "New Version" },
			objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE,
			objectWorkspacePanels.SETUP_FORMS,
			objectWorkspacePanels.VISUAL_REP,
		],
		dialogs: [],
		save: function () {
			return true;
		},
	};

	const openPointView = {
		title: viewTitles.OPEN_POINT,
		openPanels: [
			{ ...objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE, title: "MFI" },
			objectWorkspacePanels.CHECKLIST_PANEL,
			objectWorkspacePanels.OPEN_POINTS_PANEL,
			objectWorkspacePanels.VISUAL_REP,
			{ ...objectWorkspacePanels.UBM_FORM, title: "Change Request Form" },
		],
		dialogs: [],
		save: function () {
			return true;
		},
	};

	const defaultView = {
		title: viewTitles.DEFAULT,
		openPanels: [...defaultPanels],
		dialogs: [...defaultDialogs],
		save: function (mfiRowsToudpate, objRowsToUpdate, callback) {
			//save logic, create whatever structure based on parameters
			return true;
			//postcall
		},
	};

	switch (view) {
		case "CREATE_CHANGE_REQUEST":
			return createChangeRequestView;
		case "REVIEW_CHANGE_REQUEST_RECEIVER":
			return reviewChangeRequestReceiverView;
		case "REVIEW_CHANGE_REQUEST_GATEKEEPER":
			return reviewChangeRequestGatekeeper;
		case "CREATE_FIX_PACKET":
			return createFixPacketView;
		case "REVIEW_FIX_PACKET":
			return reviewFixPacketView;
		case "PATCH":
			return patchView;
		case "RELEASE":
			return releaseView;
		case "UPDATE":
			return updateView;
		case "OPEN_POINT":
			return openPointView;
		default:
			return defaultView;
	}
};
