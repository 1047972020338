import { useState, useEffect, useRef } from "react";
import CompanyWithLogo from "../ReactGridComponents/CompanyWithLogo/CompanyWithLogo";
import AppVersion from "../ReactGridComponents/AppVersion/AppVersion";
import AppHeaderBreadcrumbs from "./AppHeaderBreadcrumbs";
import BootstrapSelect from "../BootstrapComponents/Select/BootstrapSelect";
import {
	BellIcon,
	BriefcaseIcon,
	BullhornIcon,
	GlobeIcon,
	PersonIcon,
	SettingsOutlineIcon,
} from "../BootstrapComponents/Icons/Icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useTrackedState } from "../../utils/store";
import { FileUploadDialog } from "../Dialog/FileUploadDialog";
import { UserRoles, Companies } from "../../utils/StaticData";
import { clearTokenCookie } from "../../utils/Authentication";
import { Utilities } from "../../utils/StaticData";
import OnlineStatus from "../OnlineStatus/OnlineStatus";
import { useIndexedDB } from "@slnsw/react-indexed-db";
import Feedback from "../Feedback/Feedback";
import Notifications from "../Notifications/Notifications";
import Notification from "../Notifications/Notification";
import { createNotification } from "../../utils/ApiUtils";
import { getDbConst } from "../../utils/DbConstantsUtils";

/**
 * Renders our app header
 * @param
 * @constructor
 */
const AppHeader = ({}) => {
	//state variables
	const [loggedIn, setLoggedIn] = useState(false);

	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	const updateEnvironment = ({ userRole, company, utility }) => {
		dispatch({ type: "UPDATE_ENVIRONMENT", data: { userRole, company, utility } });
	};

	useEffect(() => {
		if (!sharedState.environment.company) {
			updateEnvironment({ company: Companies[0] });
		}
	}, []);

	useEffect(() => {
		setLoggedIn(sharedState.currentUser?.uuid !== undefined);
	}, [sharedState.currentUser, sharedState.currentUser?.uuid]);

	// This makes the max width of the header the width of a single screen
	// If you browser zoom in on multiple screens, the header will go across multiple screens and look bad
	const [windowWidth, setWindowWidth] = useState(window.screen.availWidth);

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.screen.availWidth);
		window.addEventListener("resize", handleResize);

		// Cleanup event listener on unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []); // Empty array ensures this runs on mount and unmount only

	//Other Methods
	return (
		<>
			<div className="row mx-auto" style={{ backgroundColor: "white", maxWidth: `${windowWidth}px` }}>
				<div className="col m-2">
					<Link to="/" className="removeLinkStyles">
						<span style={{ fontSize: "16px", fontWeight: "bold" }}>Automated UBM Software</span>
					</Link>
					<OnlineStatus />
					<br />
					<AppVersion />
				</div>
				<div className="col text-center">
					{loggedIn && (
						<CompanyWithLogo
							companyName={sharedState.environment?.company?.ccode}
							logoUrl={sharedState.environment?.company?.image}
							showCompanyName={!sharedState.environment?.company?.logoIncludesName}
						/>
					)}
				</div>
				<div className="col">{loggedIn && <UserMenu />}</div>
			</div>
			{loggedIn ? <SubHeader /> : ""}
		</>
	);
};

export default AppHeader;

export const SubHeader = () => {
	return (
		<div className="row bg-white border p-1">
			<div className="col">
				<UserTypeDropdown />
			</div>
			<div className="col">
				<AppHeaderBreadcrumbs />
			</div>
			<div className="col"></div>
		</div>
	);
};

const UserTypeDropdown = ({}) => {
	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	const updateEnvironment = ({ userRole, company, utility }) => {
		dispatch({ type: "UPDATE_ENVIRONMENT", data: { userRole, company, utility } });
	};

	const handleUserRoleChange = (index) => {
		updateEnvironment({ userRole: UserRoles[index] });
	};

	useEffect(() => {
		// If there is no set userRole, check local storage, if nothing than default to the first one the user has access to
		if (!sharedState.environment.userRole) {
			if (localStorage.userRole) {
				updateEnvironment({ userRole: localStorage.userRole });
			} else {
				updateEnvironment({ userRole: UserRoles[UserRoles.length - 1] });
			}
		}
	}, []);

	// This is the only place to change a user role, so it is the only place to store it in local storage
	useEffect(() => {
		if (sharedState.environment.userRole) localStorage.setItem("userRole", sharedState.environment.userRole);
	}, [sharedState.environment.userRole]);

	return (
		<BootstrapSelect
			label={""}
			style={{ width: "200px" }}
			value={UserRoles.indexOf(sharedState.environment.userRole)}
			optionGroups={[
				{
					title: "Select a user type",
					options: UserRoles.map((role, index) => ({
						value: index,
						label: role,
					})),
				},
			]}
			handleChange={handleUserRoleChange}
			surroundingDivClasses={""}
		/>
	);
};

const UserMenu = () => {
	const [showUserDropdown, setShowUserDropdown] = useState(false);
	const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
	const [showUtilityDropdown, setShowUtilityDropdown] = useState(false);
	const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
	const [showUploadDialog, setShowUploadDialog] = useState(false);
	const [showCaptureFeedback, setShowCaptureFeedback] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);
	const [notifications, setNotifications] = useState([]);

	const inProcessType = useRef("");
	const sentForReviewType = useRef("");
	const sharedState = useTrackedState();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const updateEnvironment = ({ userRole, company, utility }) => {
		dispatch({ type: "UPDATE_ENVIRONMENT", data: { userRole, company, utility } });
	};

	const dropdowns = document.getElementsByClassName("navbar-list");

	document.addEventListener("click", (event) => {
		let isClickInside;
		if (dropdowns && dropdowns.length > 0) {
			isClickInside = [...dropdowns].filter((dropdown) => dropdown.contains(event.target)).length;
		}

		if (!isClickInside) {
			setShowUtilityDropdown(false);
			setShowUserDropdown(false);
			setShowSettingsDropdown(false);
			setShowCompanyDropdown(false);
			setShowNotifications(false);
		}
	});

	const submitLayout = (file) => {
		setShowUploadDialog(false);

		if (!file) return;

		let reader = new FileReader();
		reader.onload = function (e) {
			let contents = e.target.result;
			let layout = JSON.parse(contents);

			//First reset the layout
			resetLayout();

			Object.keys(layout)
				.filter((key) => key.startsWith("grid-layout-"))
				.forEach((key) => {
					localStorage[key] = layout[key];
				});
		};
		reader.readAsText(file);
	};

	const logout = () => {
		dispatch({ type: "SET_USER", data: undefined });
		dispatch({ type: "UPDATE_ENVIRONMENT", data: undefined });
		clearTokenCookie();
	};

	const resetLayout = () => {
		let keys = Object.keys(localStorage);
		keys = keys.filter((key) => key.startsWith("grid-layout"));
		keys.forEach((key) => {
			delete localStorage[key];
		});
		window.location.reload();
	};

	/**
	 * Iterate over list of notifications and get all needed data inferences for each notification
	 * @param list
	 */
	const receiveNotifications = async (list) => {
		if (!inProcessType.current)
			inProcessType.current = await getDbConst("IPFLD", "inProcessFolderType", sharedState, dispatch);

		if (!sentForReviewType.current)
			sentForReviewType.current = await getDbConst("SNTREVFLD", "sentForReviewFolderType", sharedState, dispatch);
		setNotifications(
			list
				.map((notification) => {
					let notificationType = "";
					if (notification.typeUuid === inProcessType.current) notificationType = "Object Reviewed";
					else if (notification.typeUuid === sentForReviewType.current) notificationType = "Ready for Review";

					return { ...notification, notificationType };
				})
				.sort((a, b) => {
					let aDate = new Date(a.createdAt),
						bDate = new Date(b.createdAt);
					return bDate - aDate;
				})
		);
	};

	const updateNotification = (uuid, attribute, newValue) => {
		let match = notifications.find((row) => row.uuid === uuid);
		match[attribute] = newValue;
		createNotification(match);
	};

	const { clear: clearAttachmentPoints } = useIndexedDB("attachmentPoints");
	const { clear: clearHistory } = useIndexedDB("history");
	const { clear: clearLastOpenedObject } = useIndexedDB("lastOpenedObject");

	return (
		<div className="iq-top-navbar" style={{ height: "77px", boxShadow: "none" }}>
			<ul className="navbar-list">
				<li tabIndex="2" style={{ padding: 0 }}>
					<button
						className="text-white btn btn-link mx-1"
						title="Send Feedback"
						onClick={() => setShowCaptureFeedback(true)}
					>
						<BullhornIcon width="20px" height="20px" color="#0084ff" />
					</button>
				</li>
				<li tabIndex="2" style={{ padding: 0 }} className={showCompanyDropdown ? "iq-show" : ""}>
					<button
						className="text-white btn btn-link mx-1"
						title="Companies"
						onClick={() => {
							setShowCompanyDropdown((prev) => !prev);
							setShowUserDropdown(false);
							setShowSettingsDropdown(false);
							setShowUtilityDropdown(false);
							setShowNotifications(false);
						}}
					>
						<GlobeIcon width="20px" height="20px" color="#0084ff" />
					</button>
					<div className="iq-sub-dropdown">
						<div className="iq-card shadow-none m-0">
							<div className="iq-card-body p-0 ">
								<div className="p-3 border-bottom">
									<h5 className="mb-0 text-black line-height">Companies</h5>
								</div>
								{Companies.map((company) => {
									return (
										<button
											className={"iq-sub-card iq-bg-primary-success-hover btn btn-link text-left"}
											style={{ textDecoration: "none", color: "black" }}
											onClick={() => {
												setShowCompanyDropdown(false);
												updateEnvironment({ company });
											}}
											key={company.legalName}
										>
											<div className="">{company.legalName}</div>
										</button>
									);
								})}
							</div>
						</div>
					</div>
				</li>
				<li tabIndex="2" style={{ padding: 0 }} className={showUtilityDropdown ? "iq-show" : ""}>
					<button
						className="text-white btn btn-link mx-1"
						title="Utilities"
						onClick={() => {
							setShowUtilityDropdown((prev) => !prev);
							setShowUserDropdown(false);
							setShowSettingsDropdown(false);
							setShowCompanyDropdown(false);
							setShowNotifications(false);
						}}
					>
						<BriefcaseIcon width="20px" height="20px" color="#0084ff" />
					</button>
					<div className="iq-sub-dropdown">
						<div className="iq-card shadow-none m-0">
							<div className="iq-card-body p-0 ">
								<div className="p-3 border-bottom">
									<h5 className="mb-0 text-black line-height">Utilities</h5>
								</div>
								{Utilities.map((utility) => {
									return (
										<Link
											to={utility.url}
											className={`iq-sub-card iq-bg-primary-success-hover btn btn-link text-left`}
											onClick={(e) => {
												setShowUtilityDropdown((prev) => !prev);
												updateEnvironment({ utility });
											}}
											key={utility.title}
										>
											{utility.title}
										</Link>
									);
								})}
							</div>
						</div>
					</div>
				</li>
				<li tabIndex="1" style={{ padding: 0 }} className={showSettingsDropdown ? "iq-show" : ""}>
					<button
						className="text-white btn btn-link mx-1"
						title="Settings"
						onClick={() => {
							setShowSettingsDropdown((prev) => !prev);
							setShowUtilityDropdown(false);
							setShowUserDropdown(false);
							setShowCompanyDropdown(false);
							setShowNotifications(false);
						}}
					>
						<SettingsOutlineIcon width="20px" height="20px" color="#0084ff" />
					</button>
					<div className="iq-sub-dropdown">
						<div className="iq-card shadow-none m-0">
							<div className="iq-card-body p-0 ">
								<div className="p-3 border-bottom">
									<h5 className="mb-0 text-black line-height">Settings</h5>
								</div>
								<button
									className={"iq-sub-card iq-bg-primary-success-hover btn btn-link text-left"}
									style={{ textDecoration: "none", color: "black" }}
									onClick={() => {
										setShowSettingsDropdown(false);
										clearAttachmentPoints().then(() => {
											clearHistory().then(() => {
												clearLastOpenedObject().then(() => {
													navigate("#");
													window.location.reload();
												});
											});
										});
									}}
								>
									<div className="">Clear Workspace</div>
								</button>
								<button
									className={"iq-sub-card iq-bg-primary-success-hover btn btn-link text-left"}
									style={{ textDecoration: "none", color: "black" }}
									onClick={() => {
										setShowSettingsDropdown(false);
										dispatch({ type: "TOGGLE_RESIZE_PANELS" });
									}}
								>
									<div className="">Toggle Panel Resizing</div>
								</button>
								<button
									className={"iq-sub-card iq-bg-primary-success-hover btn btn-link text-left"}
									style={{ textDecoration: "none", color: "black" }}
									onClick={() => {
										setShowSettingsDropdown(false);

										//Get the grid layouts from local storage
										let keys = Object.keys(localStorage);
										keys = keys.filter((key) => key.startsWith("grid-layout"));
										let layout = {};

										//update layout
										keys.forEach((key) => {
											layout[key] = localStorage[key];
										});

										//Download json file of this layout
										let dataStr =
											"data:text/json;charset=utf-8," +
											encodeURIComponent(JSON.stringify(layout));
										let dlAnchorElem = document.getElementById("downloadAnchorElem");
										dlAnchorElem.setAttribute("href", dataStr);
										dlAnchorElem.setAttribute("download", "workspace-layout.json");
										dlAnchorElem.click();
									}}
								>
									<div className="">Download Layout</div>
								</button>
								<button
									className={"iq-sub-card iq-bg-primary-success-hover btn btn-link text-left"}
									style={{ textDecoration: "none", color: "black" }}
									onClick={() => {
										setShowSettingsDropdown(false);
										setShowUploadDialog(true);
										//Show upload dialog, have it update local storage with the json file uploaded
									}}
								>
									<div className="">Upload Layout</div>
								</button>
								<button
									className={"iq-sub-card iq-bg-primary-success-hover btn btn-link text-left"}
									style={{ textDecoration: "none", color: "black" }}
									onClick={() => {
										//Delete the grid-layouts in localStorage
										setShowSettingsDropdown(false);
										resetLayout();
									}}
								>
									<div className="">Reset Layout</div>
								</button>
							</div>
						</div>
					</div>
				</li>
				<li tabIndex="0" style={{ padding: 0 }} className={showNotifications ? "iq-show" : ""}>
					<button
						className="text-white btn btn-link mx-1"
						title="Notifications"
						onClick={() => {
							setShowNotifications((prev) => !prev);
							setShowUtilityDropdown(false);
							setShowUserDropdown(false);
							setShowSettingsDropdown(false);
							setShowCompanyDropdown(false);
						}}
					>
						<BellIcon width="20px" height="20px" color="#0084ff" />
						<Notifications sendNotifications={receiveNotifications} />
					</button>
					<div className="iq-sub-dropdown" style={{ width: "600px" }}>
						<div className="iq-card shadow-none m-0">
							<div className="iq-card-body p-0 ">
								<div className="p-3 border-bottom">
									<h5 className="mb-0 text-black line-height">Notifications</h5>
								</div>
								<div style={{ overflowY: "auto", height: "800px" }}>
									{notifications.map((notification) => {
										return (
											<Notification
												key={notification.uuid}
												uuid={notification.uuid}
												createdAt={notification.createdAt}
												notificationType={notification.notificationType}
												message={notification.message}
												objectTitle={notification.objectTitle}
												objectUuid={notification.objectUuid}
												objectVersionUuid={notification.objectVersionUuid}
												read={notification.read}
												updateNotification={updateNotification}
											/>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</li>
				<li tabIndex="0" style={{ padding: 0 }} className={showUserDropdown ? "iq-show" : ""}>
					<button
						className="text-white btn btn-link mx-1"
						title="User"
						onClick={() => {
							setShowUserDropdown((prev) => !prev);
							setShowUtilityDropdown(false);
							setShowSettingsDropdown(false);
							setShowCompanyDropdown(false);
							setShowNotifications(false);
						}}
					>
						<PersonIcon color="#0084ff" width="20px" height="20px" />
					</button>
					<div className="iq-sub-dropdown">
						<div className="iq-card shadow-none m-0">
							<div className="iq-card-body p-0 ">
								<div className="p-3 border-bottom">
									<h5 className="mb-0 text-black line-height">
										User Profile -{" "}
										<span className="fs-6">
											{sharedState.currentUser?.firstName +
												" " +
												sharedState.currentUser?.lastName}
										</span>
									</h5>
								</div>
								<Link
									to={"/profile"}
									className={"iq-sub-card iq-bg-primary-success-hover btn btn-link text-left"}
									style={{ textDecoration: "none" }}
									onClick={() => {
										setShowUserDropdown((prev) => !prev);
									}}
								>
									<div>My Account</div>
								</Link>
								<Link
									to={"/help"}
									className={"iq-sub-card iq-bg-primary-success-hover btn btn-link text-left"}
									style={{ textDecoration: "none" }}
									onClick={() => {
										setShowUserDropdown((prev) => !prev);
									}}
								>
									<div>Help</div>
								</Link>
								<Link
									to={""}
									className={"iq-sub-card iq-bg-primary-success-hover btn btn-link text-left"}
									style={{ textDecoration: "none" }}
									onClick={() => {}}
								>
									<div>Notifications</div>
								</Link>
								<Link
									to={"/"}
									className={"iq-sub-card iq-bg-primary-success-hover btn btn-link text-left"}
									style={{ textDecoration: "none", color: "red" }}
									onClick={() => {
										logout();
									}}
								>
									<div>Logout</div>
								</Link>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<FileUploadDialog
				show={showUploadDialog}
				uploadUsingUrl={false}
				setShow={setShowUploadDialog}
				submitLayout={submitLayout}
			/>
			{showCaptureFeedback && <Feedback setShowFeedback={setShowCaptureFeedback} />}
		</div>
	);
};
