import React from "react";
import "./ChangeItem.css";
import { ChevronLeft, ChevronRight } from "@mui/icons-material"; // Import the CSS file for styling

const ChangeItem = ({ title, additions, changes, deletions, left = false, ...other }) => {
	// const truncatedTitle = title.slice(0, Math.ceil(title.length / 2));

	return (
		<div className="change-item effect-honey" {...other}>
			{left ? (
				<div className="eh-item-left" style={{ color: "#0e1278" }}>
					<ChevronLeft />
				</div>
			) : (
				""
			)}
			<div className="item-title">{title}</div>
			<div className="change-counts">
				<span className="addition-count">+{additions}</span>
				<span className="change-count">~{changes}</span>
				<span className="deletion-count">-{deletions}</span>
			</div>
			{!left ? (
				<div className="eh-item-right" style={{ color: "#0e1278" }}>
					<ChevronRight />
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default ChangeItem;
