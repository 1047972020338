/**
 * Takes in a reference and displays it in the requested format
 * @param { ref, header = false, tree = false }
 * @constructor
 */
const Reference = ({ reference, header = false, tree = false, unstyled = false, ...other }) => {
	//If tree is true return a red faded number
	//If header is true, return Reference: with bold red underlined text
	//Else just return a span with the reference
	return (
		<>
			{tree ? (
				<span
					style={{
						color: "rgb(255 0 0 / 54%)",
						paddingRight: "5px",
						margin: "auto 0",
						// height: '100%'
					}}
				>
					{`${!reference || reference === "0" ? "" : reference} - `}
				</span>
			) : (
				<>
					{header ? (
						<h3 {...other}>
							Reference:
							<u>
								<span style={{ paddingRight: "10px", color: "red" }}>{reference}</span>
							</u>
						</h3>
					) : (
						<span {...other}>{reference}</span>
					)}
				</>
			)}
		</>
	);
};

export default Reference;
