import PagingTable from "../../components/Table/PagingTable";

/**
 * The page for the BMCL Admin to allow users access, the page that allows different environments to manage the users and the access they have to their environment
 * @param {}
 * @constructor
 */
const UsersPage = ({}) => {
	//Other Methods
	let attributes = [
		{ title: "", value: <input type="checkbox" /> },
		{ title: "Name", value: "name" },
		{ title: "Email", value: "email" },
		{ title: "Phone", value: "phone_number" },
		{ title: "Birth Date", value: "birth_date" },
		{ title: "Signup Date", value: "signup_date" },
		{ title: "Status", value: "status" },
		{ title: "Environments", value: "environments" },
	];

	const sortAlphabetical = (item1, item2) => {
		return item1.name.compareTo(item2.name);
	};

	return (
		<div>
			<PagingTable rows={users} attributes={attributes} title={"Users"} sort={sortAlphabetical} />
		</div>
	);
};

export default UsersPage;

const users = [
	{
		id: 1,
		name: "Ronnica Badder",
		email: "rbadder0@51.la",
		phone_number: "(580) 3591172",
		birth_date: "08/14/1991",
		signup_date: "11/16/2020",
		status: "Active",
		environments: "Stan(User)",
	},
	{
		id: 2,
		name: "Davon Dealy",
		email: "ddealy1@prlog.org",
		phone_number: "(417) 5203750",
		birth_date: "09/28/1989",
		signup_date: "02/22/2021",
		status: "Disabled",
		environments: "BMCL(Admin)",
	},
	{
		id: 3,
		name: "Jena Jiruca",
		email: "jjiruca2@over-blog.com",
		phone_number: "(702) 9170378",
		birth_date: "03/01/1943",
		signup_date: "05/02/2021",
		status: "Disabled",
		environments: "Stan(User)",
	},
	{
		id: 4,
		name: "Beaufort Golda",
		email: "bgolda3@jiathis.com",
		phone_number: "(261) 8510294",
		birth_date: "06/18/1922",
		signup_date: "02/28/2021",
		status: "Active",
		environments: "STAN(Admin)",
	},
	{
		id: 5,
		name: "Lisle Kershaw",
		email: "lkershaw4@craigslist.org",
		phone_number: "(703) 8113928",
		birth_date: "01/10/1930",
		signup_date: "10/13/2020",
		status: "Active",
		environments: "Stan(User)",
	},
	{
		id: 6,
		name: "Cyndia Varnes",
		email: "cvarnes5@mozilla.org",
		phone_number: "(581) 8517440",
		birth_date: "11/03/1922",
		signup_date: "10/17/2021",
		status: "Pending",
		environments: "STAN(Admin)",
	},
	{
		id: 7,
		name: "Hattie Anslow",
		email: "hanslow6@mysql.com",
		phone_number: "(796) 8993780",
		birth_date: "04/20/1921",
		signup_date: "05/05/2021",
		status: "Active",
		environments: "ADS(User)",
	},
	{
		id: 8,
		name: "Morrie Dowdam",
		email: "mdowdam7@aboutads.info",
		phone_number: "(131) 2908206",
		birth_date: "04/07/1961",
		signup_date: "09/05/2021",
		status: "Pending",
		environments: "Stan(User)",
	},
	{
		id: 9,
		name: "Gardie Abadam",
		email: "gabadam8@europa.eu",
		phone_number: "(156) 1131748",
		birth_date: "08/27/1941",
		signup_date: "10/25/2020",
		status: "Active",
		environments: "STAN(Admin)",
	},
	{
		id: 10,
		name: "Whitby De Caville",
		email: "wde9@bloglovin.com",
		phone_number: "(545) 6621616",
		birth_date: "07/20/1953",
		signup_date: "04/16/2020",
		status: "Pending",
		environments: "Stan(User)",
	},
	{
		id: 11,
		name: "Robert Possel",
		email: "rpossela@phoca.cz",
		phone_number: "(873) 5708302",
		birth_date: "05/20/1984",
		signup_date: "10/11/2020",
		status: "Pending",
		environments: "BMCL(Admin)",
	},
	{
		id: 12,
		name: "Galvan Serotsky",
		email: "gserotskyb@reuters.com",
		phone_number: "(500) 9454953",
		birth_date: "10/16/1956",
		signup_date: "10/23/2020",
		status: "Disabled",
		environments: "ADS(Admin)",
	},
	{
		id: 13,
		name: "Cash Eles",
		email: "celesc@spiegel.de",
		phone_number: "(746) 7903825",
		birth_date: "08/25/1974",
		signup_date: "07/22/2020",
		status: "Pending",
		environments: "ADS(User)",
	},
	{
		id: 14,
		name: "Berta Guyan",
		email: "bguyand@digg.com",
		phone_number: "(913) 3684602",
		birth_date: "01/26/1927",
		signup_date: "09/29/2021",
		status: "Active",
		environments: "ADS(User)",
	},
	{
		id: 15,
		name: "Job Klugel",
		email: "jklugele@oaic.gov.au",
		phone_number: "(892) 2237976",
		birth_date: "10/19/1967",
		signup_date: "01/26/2021",
		status: "Active",
		environments: "ADS(User)",
	},
	{
		id: 16,
		name: "Phylys Coultass",
		email: "pcoultassf@dyndns.org",
		phone_number: "(678) 9373532",
		birth_date: "08/02/1948",
		signup_date: "02/14/2021",
		status: "Disabled",
		environments: "STAN(Admin)",
	},
	{
		id: 17,
		name: "Nanny Flag",
		email: "nflagg@bizjournals.com",
		phone_number: "(973) 5806709",
		birth_date: "02/03/1919",
		signup_date: "10/26/2021",
		status: "Active",
		environments: "BMCL(User)",
	},
	{
		id: 18,
		name: "Marya Wincott",
		email: "mwincotth@ft.com",
		phone_number: "(436) 3496785",
		birth_date: "10/07/1958",
		signup_date: "11/23/2021",
		status: "Active",
		environments: "BMCL(User)",
	},
	{
		id: 19,
		name: "Kevon Regelous",
		email: "kregelousi@cbc.ca",
		phone_number: "(949) 7098206",
		birth_date: "12/20/1975",
		signup_date: "05/07/2020",
		status: "Active",
		environments: "Stan(User)",
	},
	{
		id: 20,
		name: "Bertina Quant",
		email: "bquantj@google.es",
		phone_number: "(700) 6013926",
		birth_date: "11/13/1906",
		signup_date: "11/11/2020",
		status: "Pending",
		environments: "STAN(Admin)",
	},
	{
		id: 21,
		name: "Moises Aronovich",
		email: "maronovichk@rediff.com",
		phone_number: "(677) 2350065",
		birth_date: "12/08/1947",
		signup_date: "03/18/2020",
		status: "Disabled",
		environments: "ADS(User)",
	},
	{
		id: 22,
		name: "Carlos Klyn",
		email: "cklynl@mapy.cz",
		phone_number: "(732) 5100896",
		birth_date: "02/17/1953",
		signup_date: "09/16/2020",
		status: "Active",
		environments: "BMCL(Admin)",
	},
	{
		id: 23,
		name: "Zuzana Raspin",
		email: "zraspinm@merriam-webster.com",
		phone_number: "(204) 7808967",
		birth_date: "07/18/1927",
		signup_date: "03/16/2021",
		status: "Pending",
		environments: "BMCL(User)",
	},
	{
		id: 24,
		name: "Shelden Alvarado",
		email: "salvaradon@admin.ch",
		phone_number: "(671) 2622403",
		birth_date: "03/21/1989",
		signup_date: "04/20/2020",
		status: "Active",
		environments: "ADS(User)",
	},
	{
		id: 25,
		name: "Jeri Szantho",
		email: "jszanthoo@com.com",
		phone_number: "(317) 3969958",
		birth_date: "03/05/1924",
		signup_date: "09/13/2020",
		status: "Disabled",
		environments: "ADS(Admin)",
	},
	{
		id: 26,
		name: "Kerrill Leggatt",
		email: "kleggattp@naver.com",
		phone_number: "(799) 5763659",
		birth_date: "09/18/1920",
		signup_date: "12/22/2020",
		status: "Pending",
		environments: "BMCL(Admin)",
	},
	{
		id: 27,
		name: "Poul Pauli",
		email: "ppauliq@hp.com",
		phone_number: "(543) 2443238",
		birth_date: "12/16/1963",
		signup_date: "05/18/2021",
		status: "Pending",
		environments: "BMCL(User)",
	},
	{
		id: 28,
		name: "Gilberte Vasin",
		email: "gvasinr@multiply.com",
		phone_number: "(851) 8285999",
		birth_date: "04/20/1957",
		signup_date: "07/29/2020",
		status: "Disabled",
		environments: "BMCL(User)",
	},
	{
		id: 29,
		name: "Jackie Deeves",
		email: "jdeevess@51.la",
		phone_number: "(852) 7125759",
		birth_date: "12/14/1905",
		signup_date: "08/25/2021",
		status: "Active",
		environments: "STAN(Admin)",
	},
	{
		id: 30,
		name: "Harwilll Spatig",
		email: "hspatigt@abc.net.au",
		phone_number: "(605) 9981955",
		birth_date: "09/04/1917",
		signup_date: "10/13/2021",
		status: "Pending",
		environments: "ADS(User)",
	},
	{
		id: 31,
		name: "Samantha Raggitt",
		email: "sraggittu@dailymail.co.uk",
		phone_number: "(665) 1978814",
		birth_date: "09/11/1937",
		signup_date: "04/14/2021",
		status: "Active",
		environments: "ADS(User)",
	},
	{
		id: 32,
		name: "Barby Pepis",
		email: "bpepisv@nydailynews.com",
		phone_number: "(827) 1807436",
		birth_date: "10/03/1959",
		signup_date: "12/09/2020",
		status: "Disabled",
		environments: "Stan(User)",
	},
	{
		id: 33,
		name: "Nobe Bexon",
		email: "nbexonw@ibm.com",
		phone_number: "(465) 3753387",
		birth_date: "09/13/1912",
		signup_date: "05/21/2020",
		status: "Active",
		environments: "BMCL(User)",
	},
	{
		id: 34,
		name: "Margie Proudlove",
		email: "mproudlovex@ucoz.com",
		phone_number: "(178) 7876843",
		birth_date: "01/31/1954",
		signup_date: "04/11/2020",
		status: "Active",
		environments: "Stan(User)",
	},
	{
		id: 35,
		name: "Jimmy Jarlmann",
		email: "jjarlmanny@merriam-webster.com",
		phone_number: "(960) 8947908",
		birth_date: "10/16/1941",
		signup_date: "11/27/2021",
		status: "Disabled",
		environments: "BMCL(Admin)",
	},
	{
		id: 36,
		name: "Chickie Mincher",
		email: "cmincherz@jigsy.com",
		phone_number: "(489) 3311435",
		birth_date: "05/31/1994",
		signup_date: "11/02/2021",
		status: "Disabled",
		environments: "STAN(Admin)",
	},
	{
		id: 37,
		name: "Eldredge Rosa",
		email: "erosa10@goo.ne.jp",
		phone_number: "(103) 7875735",
		birth_date: "11/20/1929",
		signup_date: "11/07/2021",
		status: "Active",
		environments: "STAN(Admin)",
	},
	{
		id: 38,
		name: "Dalston Goligly",
		email: "dgoligly11@skype.com",
		phone_number: "(814) 1125253",
		birth_date: "11/23/1953",
		signup_date: "10/10/2020",
		status: "Disabled",
		environments: "ADS(User)",
	},
	{
		id: 39,
		name: "Jamesy Greiswood",
		email: "jgreiswood12@shutterfly.com",
		phone_number: "(919) 5722485",
		birth_date: "07/31/1964",
		signup_date: "05/19/2020",
		status: "Active",
		environments: "ADS(Admin)",
	},
	{
		id: 40,
		name: "Rubin Gasson",
		email: "rgasson13@ibm.com",
		phone_number: "(349) 8221469",
		birth_date: "04/09/1905",
		signup_date: "11/07/2020",
		status: "Disabled",
		environments: "BMCL(Admin)",
	},
	{
		id: 41,
		name: "Vitoria Swetman",
		email: "vswetman14@paginegialle.it",
		phone_number: "(219) 2527365",
		birth_date: "03/16/1956",
		signup_date: "10/22/2020",
		status: "Pending",
		environments: "STAN(Admin)",
	},
	{
		id: 42,
		name: "Lew Tolcharde",
		email: "ltolcharde15@wikispaces.com",
		phone_number: "(368) 8661999",
		birth_date: "01/05/1922",
		signup_date: "02/24/2021",
		status: "Active",
		environments: "ADS(Admin)",
	},
	{
		id: 43,
		name: "Joshia Sterre",
		email: "jsterre16@shinystat.com",
		phone_number: "(244) 3584624",
		birth_date: "01/12/2003",
		signup_date: "04/02/2021",
		status: "Active",
		environments: "ADS(User)",
	},
	{
		id: 44,
		name: "Cornell Mocher",
		email: "cmocher17@topsy.com",
		phone_number: "(786) 5746186",
		birth_date: "07/22/1983",
		signup_date: "03/15/2020",
		status: "Pending",
		environments: "STAN(Admin)",
	},
	{
		id: 45,
		name: "Gerry Langtree",
		email: "glangtree18@myspace.com",
		phone_number: "(379) 2064909",
		birth_date: "04/06/1918",
		signup_date: "11/24/2020",
		status: "Disabled",
		environments: "Stan(User)",
	},
	{
		id: 46,
		name: "Godfry Botterell",
		email: "gbotterell19@cbsnews.com",
		phone_number: "(920) 5809524",
		birth_date: "08/29/1932",
		signup_date: "05/04/2021",
		status: "Pending",
		environments: "ADS(Admin)",
	},
];
