export const getSmallRef = (reference) => {
	if (!reference) return "";
	let splitRef = reference.split(".");
	if (splitRef.length > 2) return `...${splitRef[splitRef.length - 2]}.${splitRef[splitRef.length - 1]}`;
	else return reference;
};

export const camelize = (str) =>
	(str || "").toLowerCase().replace(/(?:^\w|[A-Z]|\b\w|#|\s+)/g, (match, index) => {
		if (+match === 0) {
			return ""; // Remove leading numbers
		}
		return index === 0 ? match.toLowerCase() : match.toUpperCase();
	});

export const camelizeAndRemoveSpecialCharacters = (str) => {
	if (!str) return "";
	return camelize(str).replace(/[^a-z0-9]+/gi, "");
};

export const replaceBetween = (original, startIndex, endIndex, insertion) => {
	return original.substring(0, startIndex) + insertion + original.substring(endIndex);
};

export const checkIfValidUUID = (str) => {
	// Regular expression to check if string is a valid UUID
	const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

	return regexExp.test(str);
};

export const titleize = (str) => {
	if (!str) return "";
	return str.replace(/(?:^|\s|-)\S/g, (c) => c.toUpperCase());
};

export const humanize = (str) => {
	if (!str) return "";
	return str
		.replace(/([A-Z])/g, " $1")
		.replace(/^[\s_]+|[\s_]+$/g, "")
		.replace(/[_\s]+/g, " ")
		.replace(/^[a-z]/, function (m) {
			return m.toUpperCase();
		});
};
