import React, { useEffect, useState } from "react";
import { useDispatch, useTrackedState } from "../../utils/store";
import { copyStandardObject } from "../../utils/StandardObject";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";

export default function ObjectSetupForm({ updateObject, object }) {
	const dispatch = useDispatch();
	const sharedState = useTrackedState();
	const [obj, setObj] = useState(object || { title: "", description: "" });

	useEffect(() => {}, [object]);

	const handleChange = (event, fieldTitle, newValue) => {
		let newObj = {};
		if (newValue && newValue.uuid) {
			newObj = copyStandardObject(newValue, "0", undefined, sharedState.currentUser?.uuid, {
				newStockNumber: true,
				newVersion: true,
			});

			dispatch({ type: "UPDATE_CHANGED_STOCK_NUMBERS", data: newObj.stockNumber });
		} else if (newValue) newObj[fieldTitle] = newValue;
		else if (event) newObj[fieldTitle] = event.target.value;

		//just setting the rows field to the new value and then calling setRow(row) isn't seen as a state change, so I need to set the row to a new object
		newObj = { ...obj, ...newObj };
		setObj(newObj);
		updateObject(newObj);
	};

	return (
		<div>
			<div style={{ margin: "8px", width: "" }} className={"flex-fill"}>
				{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
					label: "Title",
					value: obj.title,
					description: obj.description,
					handleChange: (e) => {},
					handleBlur: (newVal) => handleChange(null, "title", newVal),
				})}
			</div>
			<div style={{ margin: "8px", width: "" }} className={"flex-fill"}>
				{INPUT_FIELD_TYPES.TEXT_AREA.render({
					label: "Description",
					value: obj.description,
					description: obj.description,
					handleChange: (e) => {},
					handleBlur: (newVal) => handleChange(null, "description", newVal),
				})}
			</div>
		</div>
	);
}
