import { useState, useEffect, useRef } from "react";

/**
 *
 * @param { label, value, placeholder, onChange, onBlur }
 * @constructor
 */
const BootstrapTextarea = ({
	disabled = false,
	label,
	value,
	placeholder,
	handleChange,
	handleBlur,
	help,
	divStyles = {},
	allowTab = false,
	focus = false,
	invalid = false,
	rows = "2",
	classes,
	...other
}) => {
	//state variables
	const [val, setVal] = useState("");
	const textareaRef = useRef();
	const pendingCaret = useRef(-1);

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		setVal(value);
	}, [value]);

	useEffect(() => {
		if (focus) textareaRef.current.focus();
	}, [focus]);

	useEffect(() => {
		if (pendingCaret.current > -1) {
			setCaretPosition(pendingCaret.current);
			pendingCaret.current = -1;
		}
	}, [val]);

	useEffect(() => {
		if (other?.className?.includes("fill-cell")) {
			textareaRef.current.style.height = "";
			textareaRef.current.style.height = textareaRef.current?.scrollHeight + "px";
		}
	}, [textareaRef.current?.scrollHeight]);

	//Other Methods
	const getCaretPosition = () => {
		let textarea = textareaRef.current;
		return textarea.selectionStart;
	};

	const setCaretPosition = (position) => {
		//change the caret position of the textarea
		let textarea = textareaRef.current;
		textarea.selectionStart = position;
		textarea.selectionEnd = position;
		textarea.focus();
	};

	const keyDown = (e) => {
		if (!allowTab) return;
		//support tab on textarea
		if (e.keyCode === 9) {
			//tab was pressed
			e.preventDefault(); //Prevent tabbing out of field
			pendingCaret.current = getCaretPosition() + "    ".length;
			setVal(val.substring(0, getCaretPosition()) + "    " + val.substring(getCaretPosition(), val.length));
		}
		if (e.keyCode === 8) {
			//backspace
			if (val.substring(getCaretPosition() - 4, getCaretPosition()) === "    ") {
				//it's a tab space
				e.preventDefault();

				pendingCaret.current = getCaretPosition() - 3;
				let newVal = val.substring(0, getCaretPosition() - 3) + val.substring(getCaretPosition(), val.length);
				setVal(newVal);
			}
		}
		if (e.keyCode === 37) {
			//left arrow
			if (val.substring(getCaretPosition() - 4, getCaretPosition()) === "    ") {
				//it's a tab space
				let newCaretPosition = getCaretPosition() - 3;
				setCaretPosition(newCaretPosition);
			}
		}
		if (e.keyCode === 39) {
			//right arrow
			if (val.substring(getCaretPosition() + 4, getCaretPosition()) === "    ") {
				//it's a tab space
				let newCaretPosition = getCaretPosition() + 3;
				setCaretPosition(newCaretPosition);
			}
		}
	};

	return (
		<div>
			{label}
			<textarea
				className={`form-control ${invalid ? "is-invalid" : ""} ${classes}`}
				disabled={disabled}
				type="text"
				placeholder={placeholder}
				value={val}
				style={{ padding: "12px" }}
				rows={rows}
				onChange={(e) => {
					let newVal = e.currentTarget.value;
					setVal(newVal);
					if (handleChange) handleChange(newVal);
				}}
				onBlur={() => handleBlur(val)}
				ref={textareaRef}
				onKeyDown={keyDown}
				{...other}
			/>
		</div>
	);
};

export default BootstrapTextarea;
