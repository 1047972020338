import { createToken, Lexer } from "chevrotain";

const tokenVocabulary = {};
const AdditionOperator = createToken({
	name: "AdditionOperator",
	pattern: Lexer.NA,
});
const Plus = createToken({
	name: "Plus",
	pattern: /\+/,
	categories: AdditionOperator,
});
const Minus = createToken({
	name: "Minus",
	pattern: /-/,
	categories: AdditionOperator,
});
const MultiplicationOperator = createToken({
	name: "MultiplicationOperator",
	pattern: Lexer.NA,
});
const Multiply = createToken({
	name: "Multiply",
	pattern: /\*/,
	categories: MultiplicationOperator,
});
const Divide = createToken({
	name: "Divide",
	pattern: /\//,
	categories: MultiplicationOperator,
});
const Power = createToken({ name: "Power", pattern: /\^/ });
const FunctionName = createToken({
	name: "FunctionName",
	pattern: /[A-Z]+/,
});
const LParen = createToken({ name: "LParen", pattern: /\(/ });
const RParen = createToken({ name: "RParen", pattern: /\)/ });
const JavaScriptObject = createToken({
	name: "JavaScriptObject",
	pattern: /{.*}/,
});
const JavaScriptArray = createToken({
	name: "JavaScriptArray",
	pattern: /\[.*]/,
});
const NumberLiteral = createToken({
	name: "NumberLiteral",
	pattern: /-?(0|[1-9]\d*)(\.\d+)?([eE][+-]?\d+)?/,
});
const BooleanLiteral = createToken({
	name: "BooleanLiteral",
	pattern: /true|false/,
});
const Comma = createToken({ name: "Comma", pattern: /,/ });
const StringLiteral = createToken({
	name: "StringLiteral",
	pattern: /"(?:[^\\"]|\\(?:[bfnrtv"\\\/]|u[0-9a-fA-F]{4}))*"/,
});
const WhiteSpace = createToken({
	name: "WhiteSpace",
	pattern: /\s+/,
	group: Lexer.SKIPPED,
});
// whitespace is normally very common so it is placed first to speed up the lexer
const allTokens = [
	WhiteSpace,
	FunctionName,
	StringLiteral,
	NumberLiteral,
	BooleanLiteral,
	LParen,
	RParen,
	Comma,
	Plus,
	Minus,
	Multiply,
	Divide,
	Power,
	AdditionOperator,
	MultiplicationOperator,
	JavaScriptObject,
	JavaScriptArray,
];
const ExpressionLexer = new Lexer(allTokens);
allTokens.forEach((tokenType) => {
	tokenVocabulary[tokenType.name] = tokenType;
});
const lex = (inputText) => {
	const lexingResult = ExpressionLexer.tokenize(inputText);
	if (lexingResult.errors.length > 0) {
		throw Error("Sad Sad Panda, lexing errors detected", lexingResult);
	}
	return lexingResult;
};
export { tokenVocabulary, lex };
