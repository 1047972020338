import { useEffect } from "react";

const SolutionsPage = () => {
	useEffect(() => {
		document.title = "Solutions";
	});

	return (
		<div className="container">
			<div className="row mt-3">
				<div className="col"></div>
				<div className="col text-center">
					<h1>Solutions</h1>
					<div className="mx-auto">
						<ul className="list-unstyled mt-3">
							<li>Powerful business management software</li>
							<li>Business startup consulting</li>
							<li>Existing business consulting</li>
						</ul>
					</div>
				</div>
				<div className="col"></div>
			</div>
		</div>
	);
};

export default SolutionsPage;
