import React from "react";

/**
 * Renders the app version in format of 'Environment', i.e. Development, Alpha, Beta, Prod and the version #
 * @param
 * @constructor
 */
const AppVersion = ({ stage = "Alpha", versionNumber = "0.1.0" }) => {
	return (
		<span
			style={{
				display: "inline-block",
				fontWeight: "400",
				fontStyle: "normal",
				fontSize: "14px",
				lineHeight: "1.0",
				color: "#777d74",
			}}
		>
			Version: {stage}{" "}
			<span style={{ marginTop: "1px", borderLeft: "solid 2px", marginRight: "4px", marginLeft: "2px" }}></span> v
			{versionNumber}
		</span>
	);
};

export default AppVersion;
