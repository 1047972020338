import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Clock, ExclamationTriangle, GraphUpArrow } from "../../components/BootstrapComponents/Icons/Icons";

const LandingPage = () => {
	useEffect(() => {
		document.title = "BMCL | UBM Software";
	}, []);

	return (
		<div className="container">
			<div className="row" style={{ marginTop: "200px" }}>
				<div className="col">
					<h1 style={{ fontSize: "100px" }}>The UBM has arrived.</h1>
					<p style={{ fontSize: "70px" }}>
						Manage your business more effectively than ever, with more insight and less overhead.
					</p>
				</div>
				<div className={`col`}>
					<iframe
						src="https://player.vimeo.com/video/114805444?h=4b32b30004&title=0&byline=0&portrait=0"
						width="100%"
						height="100%"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
						title="vimeo"
					></iframe>
					<script src="https://player.vimeo.com/api/player.js"></script>
				</div>
			</div>
			<div className="row my-3">
				<div className="col">
					<Link className="btn btn-primary btn-lg" to="/register">
						Get a free trial
					</Link>
				</div>
				<div className="col"></div>
				<div className="col"></div>
			</div>
			<div className="row" style={{ marginTop: "8em" }}>
				<div className="col text-left" style={{ color: "#194fa8", textAlign: "center" }}>
					<GraphUpArrow width={"60%"} height={"60%"} />
					<br />
					<h2 className="mt-3">Work more efficiently</h2>
				</div>
				<div className="col text-left" style={{ color: "#194fa8", textAlign: "center" }}>
					<Clock width={"60%"} height={"60%"} />
					<br />
					<h2 className="mt-3">Build a stronger team faster</h2>
				</div>
				<div className="col text-left" style={{ color: "#194fa8", textAlign: "center" }}>
					<ExclamationTriangle width={"60%"} height={"60%"} />
					<br />
					<h2 className="mt-3">Reduce risk and cost</h2>
				</div>
			</div>
		</div>
	);
};

export default LandingPage;
