import { useState } from "react";

/**
 * A 3 horizontal bar menu svg icon styled with bootstrap
 * @param {onClick}
 * @constructor
 */
const CheckCircleToXIcon = ({ onXClick, iconStyles }) => {
	//state variables
	const [remove, setRemove] = useState(false);

	return (
		<span className="">
			{!remove ? (
				<a
					className={`icon-link`}
					style={{ textDecoration: "none", color: "green", ...iconStyles }}
					href="#"
					onMouseEnter={() => setRemove(true)}
					onClick={() => {}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						fill="currentColor"
						className="bi bi-check-circle-fill"
						viewBox="0 0 16 16"
					>
						<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
					</svg>
				</a>
			) : (
				<a
					className={`${"xIcon"}`}
					style={{ textDecoration: "none", color: "red", ...iconStyles }}
					href="#"
					onMouseLeave={() => setRemove(false)}
					onClick={(event) => {
						event.stopPropagation();
						onXClick();
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						fill="currentColor"
						className="bi bi-x-circle-fill"
						viewBox="0 0 16 16"
					>
						<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
					</svg>
				</a>
			)}
		</span>
	);
};

export default CheckCircleToXIcon;
