import { useEffect } from "react";
import PagingTable from "../../components/Table/PagingTable";
import { useTrackedState } from "../../utils/store";

/**
 * Displays a user profile. Allows a user to update and view their profile information, allows an admin to modify the user's access to their environment.
 * @param {}
 * @constructor
 */
const UserProfilePage = ({}) => {
	const sharedState = useTrackedState();

	useEffect(() => {
		document.title = "Profile";
	});

	return (
		<div className="container-fluid">
			<h3 className="text-dark mb-4">Profile</h3>
			<div className="row mb-3">
				<div className="col-lg-4">
					<div className="card mb-3">
						<div className="card-body text-center shadow">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="1em"
								height="1em"
								viewBox="0 0 16 16"
								fill="currentColor"
								className="bi bi-person-circle mb-3 mt-4"
								style={{ fontSize: "150px" }}
							>
								<path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"></path>
								<path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
								<path
									fill-rule="evenodd"
									d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
								></path>
							</svg>
							<div className="mb-3">
								<button className="btn btn-primary btn-sm" type="button">
									Change Photo
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-8">
					<div className="card shadow mb-3">
						<div className="card-header py-3">
							<p className="text-primary m-0 fw-bold">User Settings</p>
						</div>
						<div className="card-body">
							<form>
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label className="form-label" htmlFor="first_name">
												<strong>First Name</strong>
											</label>
											<input
												className="form-control"
												type="text"
												id="first_name"
												placeholder="John"
												name="first_name"
												value={sharedState.currentUser.firstName}
											/>
										</div>
									</div>
									<div className="col">
										<div className="mb-3">
											<label className="form-label" htmlFor="last_name">
												<strong>Last Name</strong>
											</label>
											<input
												className="form-control"
												type="text"
												id="last_name"
												placeholder="Doe"
												name="last_name"
												value={sharedState.currentUser.lastName}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label className="form-label" htmlFor="email">
												<strong>Email Address</strong>
											</label>
											<input
												className="form-control"
												type="email"
												id="email"
												placeholder="user@example.com"
												name="email"
												value={sharedState.currentUser.email}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label className="form-label" htmlFor="last_name">
												<strong>Birth Date</strong>
												<br />
											</label>
											<input
												className="form-control"
												type="text"
												id="birthdate"
												placeholder="Birth Date"
												name="last_name"
											/>
										</div>
									</div>
									<div className="col">
										<div className="mb-3">
											<label className="form-label" htmlFor="last_name">
												<strong>Signup Date</strong>
												<br />
											</label>
											<input
												className="form-control"
												type="text"
												id="signup_date"
												placeholder="Signup Date"
												name="last_name"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label className="form-label" htmlFor="last_name">
												<strong>Status</strong>
												<br />
											</label>
											<select className="form-select">
												<optgroup label="Status">
													<option value="pending" selected="">
														Pending
													</option>
													<option value="active">Active</option>
													<option value="terminated">Terminated</option>
												</optgroup>
											</select>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="mt-2 mb-2">
											<button className="btn btn-success btn-sm form-control" type="submit">
												Save User Settings
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
					<PagingTable
						title={"Environment Access"}
						rows={environments}
						attributes={[
							{ title: "Environment", value: "title" },
							{ title: "Access Level", value: "access_level" },
						]}
					/>
				</div>
			</div>
		</div>
	);
};

const environments = [
	{
		id: 1,
		title: "Quamba",
		access_level: "Developer Admin",
	},
	{
		id: 2,
		title: "Aibox",
		access_level: "App Writer Admin",
	},
	{
		id: 3,
		title: "Eire",
		access_level: "User",
	},
];

export default UserProfilePage;
