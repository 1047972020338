import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW } from "lexical";
import { useEffect } from "react";

const BlurPlugin = ({ handleBlur }) => {
	const [editor] = useLexicalComposerContext();

	useEffect(() => editor.registerCommand(BLUR_COMMAND, () => handleBlur(), COMMAND_PRIORITY_LOW), []);

	return null;
};

export default BlurPlugin;
