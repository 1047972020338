import bmclLogo from "../images/bmcl-logo-glow-brighter.png";

export const UserRoles = [
	"BMCL Admin",
	"BMCL User",
	"Developer Admin",
	"Developer User",
	"Appwriter Admin",
	"Appwriter User",
	"Admin",
	"User",
];

export const Utilities = [
	{
		title: "Object Creator IDE",
		url: "/object-workspace",
	},
	{
		title: "Master File Index",
		url: "/mfi",
	},
];

export const Companies = [
	{
		ccode: "BMCL",
		legalName: "Business Model Consulting",
		image: bmclLogo,
		logoIncludesName: false,
	},
	{
		ccode: "STAN",
		legalName: "Standard Restaurant Supply",
		// image: 'https://scontent.fslc3-2.fna.fbcdn.net/v/t1.6435-1/91276003_3041778712554703_8156008213519532032_n.jpg?stp=dst-jpg_p148x148&_nc_cat=111&ccb=1-7&_nc_sid=1eb0c7&_nc_ohc=O3uuyv4FLVwAX-KW09I&_nc_ht=scontent.fslc3-2.fna&oh=00_AT_ncIKpHV6wH7A3vioG2LdKCu0LsKWxSvVwOOos3XOk7w&oe=62E8E19A'
		image: "https://standardrestaurant.com/media/logo/stores/1/LOGO_Vector_new_1_.png",
		logoIncludesName: true,
	},
	{
		ccode: "DTA",
		legalName: "Desert Tech",
		image: "https://deserttech.com/images/timeline/dt-logo.png",
		logoIncludesName: true,
	},
];

export const permissions = ["edit-harvested-creator-objects", "create-data-warehouse"];

export const matrix = [
	{ "edit-harvested-creator-objects": true, "create-data-warehouse": true },
	{ "edit-harvested-creator-objects": true, "create-data-warehouse": false },
	{ "edit-harvested-creator-objects": false, "create-data-warehouse": false },
	{ "edit-harvested-creator-objects": false, "create-data-warehouse": false },
	{ "edit-harvested-creator-objects": false, "create-data-warehouse": false },
	{ "edit-harvested-creator-objects": false, "create-data-warehouse": false },
	{ "edit-harvested-creator-objects": false, "create-data-warehouse": false },
	{ "edit-harvested-creator-objects": false, "create-data-warehouse": false },
];

export const checkPermissions = (roleIndex, permissionIndex) => {
	if (roleIndex === undefined || roleIndex < 0 || permissionIndex === undefined || permissionIndex < 0) return false;
	//Get the corresponding thing in the matrix
	let authorizations = matrix[roleIndex];
	let permission = permissions[permissionIndex];

	return authorizations[permission];
};
