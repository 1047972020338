import React, { useEffect, useRef, useState } from "react";
import PencilIcon from "../Icons/PencilIcon";
import TitleAutoComplete from "../../Autocomplete/TitleAutocomplete";
import { useStickyState } from "../../../utils/StickyState";
import stylesModule from "./BootstrapAccordion.module.scss";
import { INPUT_FIELD_TYPES } from "../../../utils/SetupTypes";
import { XIcon } from "../Icons/Icons";
/**
 * An accordion styled by bootstrap
 * @param {titles}
 * @constructor
 */
const BootstrapAccordion = ({
	titles: _titles,
	children,
	editTitleButton,
	titleButtons,
	titleOptions,
	handleBlur,
	activeIndex: _activeIndex,
	object,
	titleLink = false,
	titleLinkClicked,
	deleteClicked,
	divStyles = {},
}) => {
	const [activeIndex, setActiveIndex] = useStickyState(0, "accordion-" + _titles.length);
	const [editTitle, setEditTitle] = useState(-1);
	const [titles, setTitles] = useState([]);
	const [reRender, setReRender] = useState(false);

	const selectedObject = useRef({});

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		setTitles(_titles);
	}, [_titles, _titles.length]);

	useEffect(() => {
		if (_activeIndex) setActiveIndex(_activeIndex);
		else setActiveIndex(-1);
	}, [_activeIndex]);

	//Other Methods
	const linkClicked = (e) => {
		if (titleLinkClicked) titleLinkClicked(e);
	};

	const deleteButtonClicked = (e) => {
		e.stopPropagation();
		deleteClicked();
	};

	return (
		<div className="accordion" style={divStyles}>
			{titles.map((title, index) => {
				const active = index === activeIndex;
				return (
					<div className="accordion-item" key={index + "-accordion"}>
						<h2 className="accordion-header">
							<button
								className={`accordion-button ${!active ? "collapsed" : ""} ${
									stylesModule.accordionButton
								}`}
								type="button"
								aria-expanded={active}
								onClick={() => {
									if (index === activeIndex) setActiveIndex(-1);
									else setActiveIndex(index);
								}}
								style={{
									backgroundColor: "rgb(245, 245, 245)",
									color: "inherit",
								}}
								disabled={editTitle === index}
							>
								{deleteClicked ? (
									<button
										style={{ margin: "0px", padding: "0px 5px 0px 0px" }}
										className={"btn btn-link"}
										onClick={deleteButtonClicked}
										title={`Delete this object`}
									>
										<XIcon height={16} width={16} color={"red"} />
									</button>
								) : (
									""
								)}
								{editTitle === index ? (
									INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
										label: `${object.reference}. `,
										value: object.title,
										handleChange: (newVal) => {
											let newTitles = [...titles];
											newTitles[index] = `${object.reference} - ${newVal}`;
											setTitles(newTitles);
										},
										handleBlur: (newVal) => {
											handleBlur(newVal);
											setEditTitle(false);
										},
										focus: true,
									})
								) : (
									// <TitleAutoComplete
									// 	name={"title"}
									// 	label={"Title"}
									// 	value={title}
									// 	options={titleOptions[index]}
									// 	objectOptions
									// 	handleChange={(e, name, value) => {
									// 		if (!e && (!value || !value.uuid)) return;
									//
									// 		if (value.uuid) {
									// 			selectedObject.current = value;
									// 		} else {
									// 			titles[index] = value;
									// 			setReRender((prev) => !prev);
									// 		}
									// 	}}
									// 	handleBlur={(e) => {
									// 		//The value was set to
									// 		if (selectedObject.current.uuid) handleBlur(index, selectedObject.current);
									// 		else handleBlur(index, titles[index]);
									//
									// 		selectedObject.current = {};
									// 		setEditTitle(false);
									// 	}}
									// 	allowNew={false}
									// 	clearOnBlur={false}
									// 	// error={!objectTypeUuid + ""}
									// 	className={stylesModule.formField}
									// 	style={{ width: "100%" }}
									// 	// helperText={!objectTypeUuid ? 'You must choose a type from the list or leave it empty' : ''}
									// />
									<>
										<button
											className={titleLink ? "btn btn-link" : "btn"}
											style={{
												padding: "0px",
												color: titleLink ? "#0d6efd" : "",
											}}
											onClick={linkClicked}
										>
											<span
												style={{
													fontWeight: "600",
													paddingRight: "0px",
													pointerEvents: "none",
												}}
											>
												{title}
											</span>
										</button>
										{editTitleButton ? (
											<PencilIcon
												onClick={(e) => {
													e.stopPropagation();
													setEditTitle(index);
												}}
											/>
										) : (
											""
										)}
									</>
								)}
							</button>
						</h2>
						<div className={`accordion-collapse collapse ${active ? " show" : ""}`}>
							<div className="accordion-body">{children[index]}</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default BootstrapAccordion;
