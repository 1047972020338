export const formatDate = (date, yearFirst = false) => {
	if (!date) date = new Date();
	let dateToParse = date;
	if (typeof date == "string") dateToParse = date.replaceAll("-", "/");
	try {
		let d = new Date(dateToParse);
		if (d.toString() === "Invalid Date") d = new Date(date);

		let month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		if (yearFirst) return [year, month, day].join("-");
		else return [month, day, year].join("/");
	} catch (e) {
		console.error("Invalid date", date);
		return date;
	}
};
