import React, { Component } from "react";
import InlineEditableElement from "../../components/InlineEditableElement/InlineEditableElement";
import Grid from "@mui/material/Grid";

export default class LeftHeader extends Component {
	constructor(props) {
		super();

		this.state = {
			changed: false,
			leftHeader: props.leftHeader,
			title: props.title,
		};
	}

	render() {
		const { leftHeader } = this.props;

		return (
			<div style={{ textAlign: "left" }}>
				<Grid container direction={"column"}>
					<Grid item>
						<span className="bold header-label">Model</span>
					</Grid>
					<Grid item>
						<span className="header-label">Type: </span>
						<InlineEditableElement text={leftHeader.modelTypeTitle} type="text">
							<input
								type="text"
								name="modelTypeTitle"
								value={leftHeader.modelTypeTitle}
								onChange={(e) => {
									const { name, value } = e.target;
									leftHeader.modelTypeTitle === value ||
										this.props.updateHeader("leftHeader", name, value);
								}}
								//Whenever the input loses focus call the method updating the state.
								onBlur={(e) => {
									const { name, value } = e.target;
									leftHeader.modelTypeTitle === value ||
										this.props.updateHeader("leftHeader", name, value);
								}}
								autoFocus
							/>
						</InlineEditableElement>
					</Grid>
					<Grid item>
						<span className="header-label">LE: </span>
						<InlineEditableElement text={leftHeader.legalEntityTitle} type="text">
							<input
								type="text"
								name="legalEntityTitle"
								value={leftHeader.legalEntityTitle}
								onChange={(e) => {
									const { name, value } = e.target;
									leftHeader.legalEntityTitle === value ||
										this.props.updateHeader("leftHeader", name, value);
								}}
								//Whenever the input loses focus call the method updating the state.
								onBlur={(e) => {
									const { name, value } = e.target;
									leftHeader.legalEntityTitle === value ||
										this.props.updateHeader("leftHeader", name, value);
								}}
								autoFocus
							/>
						</InlineEditableElement>
					</Grid>
					<Grid item>
						<span className="header-label">PS: </span>
						<InlineEditableElement text={leftHeader.modelPositionTitle} type="text">
							<input
								type="text"
								name="modelPositionTitle"
								value={leftHeader.modelPositionTitle}
								onChange={(e) => {
									const { name, value } = e.target;
									leftHeader.modelPositionTitle === value ||
										this.props.updateHeader("leftHeader", name, value);
								}}
								//Whenever the input loses focus call the method updating the state.
								onBlur={(e) => {
									const { name, value } = e.target;
									leftHeader.modelPositionTitle === value ||
										this.props.updateHeader("leftHeader", name, value);
								}}
								autoFocus
							/>
						</InlineEditableElement>
					</Grid>
				</Grid>
			</div>
		);
	}
}
