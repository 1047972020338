import { getAccessToken } from "./Authentication";
import { sortByReference } from "./Referencing";
import { ZERO_ROW_UUID } from "./StandardObject";
// import {api_host} from "../env/ApiHost";

/**
 * This is where we manage our api calls,
 * ALL API calls should come through this class
 * @param props
 * @constructor
 */

//TODO: Make so we don't have to change this everytime we pull down and push up
// let api_host = process.env.REACT_APP_HOST;

//URL for development on localhost
let api_host = process.env.REACT_APP_API_ENDPOINT;

let report_host = process.env.REACT_APP_REPORT_GENERATION_SERVICE;

//URL to connect to the gateway
let gateway = process.env.REACT_APP_API_GATEWAY;
//URL to connect to the mfi service in prod
// let api_host = gateway + 'mfi/';

export const urls = {
	getGeneralTypes: api_host + "general-type/",
	getTopMfi: api_host + "master-file-index/top",
	// http://localhost:8080/master-file-index/' + this.ancestor + '/level-of-detail/' + this.level
	//Level of detail -1: Entire MFI with top row, 0: Entire MFI without top row
	getMfi: [api_host + "master-file-index/", "/level-of-detail/"],
	getHeader: api_host + "header/",
	updateMfi: api_host + "master-file-index/rows",
	updateStockNumber: api_host + "stock-number/multi/",
	getVolumes: api_host + "volume/",
	getObjectClasses: api_host + "object-class/",
	getLanguageFrameworks: api_host + "language-framework/",
	getObjectMfi: [api_host + "standard-object/object-master-file-index/", "/"],
	getObjectMfiBySubObject: [api_host + "standard-object/master-file-index-by-subobject/", "/"],
	getObjectMfiRow: [report_host + "standard-object/", "/", "/", "/all"],
	getSingleLevelObjectMfi: [api_host + "standard-object/object-master-file-index/single-level/", "/"],
	getSingleLevelObjectMfiWithTopAncestor: [
		api_host + "standard-object/object-master-file-index/single-level/",
		"/",
		"/with-top-ancestor/",
		"/",
	],
	getSingleLevelObjectMfiSpecificVersion: [
		api_host + "standard-object/object-master-file-index/single-level/",
		"/",
		"/",
	],
	getLatestSingleLevelObjectMfi: [api_host + "standard-object/object-master-file-index/"],
	copySingleLevelObjectMfi: [api_host + "standard-object/copy-master-file-index/single-level/", "/"],
	getStandardObjectGit: [api_host + "standard-object-git/", "/"],
	getStandardObjectGitByUuid: [api_host + "standard-object-git/", "/latest"],
	getLatestObjectMfi: [api_host + "standard-object/object-master-file-index/"],
	// 'getObjectMfi' : ['http://localhost:8081/' + 'standard-object-row/', '/'],
	getObjectsHierarchy: [api_host + "standard-object/object-hierarchy/", "/"],
	getObjectsChangeRequests: [api_host + "change-request/summary/"],
	getObjectVersionsChangeRequests: [api_host + "change-request/summary/", "/"],
	getObjectsUpdates: [api_host + "standard-object/", "/", "/updates"],
	getAvailableUpdates: [api_host + "version-control-log/update/", "/"],
	getObjectsOpenPoints: api_host + "open-points/",
	getOpenPointsBySheet: api_host + "open-point-json/on-sheet/",
	getObjectsComputerVersions: [api_host + "version-control-log/"],
	getUpstreamObject: [api_host + "git-fork/upstream/standard-object/", "/version/"],
	updateVolumes: api_host + "volume/multi/",
	updateObjectClasses: api_host + "object-class/multi/",
	updateLanguageFrameworks: api_host + "language-framework/multi/",
	submitNewObject: api_host + "standard-object",
	saveObjectRows: api_host + "standard-object/rows",
	saveOpenPoints: api_host + "open-points/",
	saveRelationships: api_host + "object-relationship/rows",
	getRelatedObjects: [api_host + "standard-object-git/", "/related-to"],
	getObjectRelationships: [api_host + "object-relationship/related-to/", ""],
	getExistingObjects: api_host + "standard-object",
	getDataWarehouseAndSpecial: api_host + "standard-object/special-and-data-warehouse/",
	getUbmDataWarehouse: api_host + "master-file-index/ubm-data-warehouse",
	getDestinationModels: api_host + "master-file-index/destination-models",
	getAvailableTemplates: api_host + "standard-object/available-templates",
	getObject: api_host + "standard-object/",
	getObjectDataWarehouseSource: [api_host + "standard-object/", "/", "/data-warehouse-source"],
	getObjectsNotInWarehouse: api_host + "/standard-object/not-in-warehouse",
	getObjectSetupSheet: api_host + "setup-sheet/object/",
	getDefaultClasses: api_host + "default-class",
	saveDefaultClasses: api_host + "default-class/list",
	getDefaultFields: api_host + "default-field",
	saveDefaultFields: api_host + "default-field/list",
	saveSetupRows: api_host + "setup-sheet/list",
	getObjectSetupRow: api_host + "setup-sheet/object/row/",
	getObjectSetupForms: api_host + "setup-form/",
	getDbConstByRef: api_host + "db-constants/ref/",
	login: gateway + "login",
	register: gateway + "register",
	updatePassword: gateway + "/user/update-password",
	forgotPassword: gateway + "/user/forgot-password",
	resetPassword: gateway + "/user/reset-password",
	getUserInfo: gateway + "user-info/",
	saveChangeRequest: api_host + "change-request/",
	getNextChangeRequestNumber: api_host + "master-file-index/change-request-next-number",
	getNextSubmitterChangeRequestNumber: api_host + "change-request/submitter-next-number/",
	getChangeRequestMfiRow: api_host + "master-file-index/change-request-folder-row",
	saveFixPacket: api_host + "fix-packet/",
	getNextFixPacketNumber: api_host + "master-file-index/fix-packet-next-number",
	getFixPacketMfiRow: api_host + "master-file-index/fix-packet-folder-row",
	// 'getVolumes': 'http://localhost:8080/volume/',
	// 'getObjectClasses': 'http://localhost:8080/object-class/',
	// 'getLanguageFrameworks': 'http://localhost:8080/language-framework/',
	getCommonValues: [api_host + "standard-object/common-values", "/title/"],
	getRecentValues: [api_host + "standard-object/recent-values", "/title/"],
	generateSourceCode: [report_host + "generate/source-code/", "/", "/"],
	getDestinationModelObjectTypes: api_host + "standard-object/destination-model-objects",
	getChecklistTasks: [report_host + "generate/standard-object/", "/", "/checklist"],
	getObjectDTOByUuidAndVersion: [api_host + "standard-object-git/", "/"],
	getObjectsOfTypeInDwWithFilter: [report_host + "generate/standard-object/", "/in-data-warehouse/", "/filter"],
	getFilter: api_host + "filter/",
	getSingleLevelObjectMfiFromBeginningAndOfPath:
		api_host + "standard-object/object-master-file-index/single-level/from-beginning-and-end-of-path",
	getSingleLevelObjectMfiFromPath: api_host + "standard-object/object-master-file-index/single-level/from-path",
	repairObjectMfiReferences: [api_host + "standard-object-git/repair-standard-object-references/", "/"],
	createObjectStatusRecord: api_host + "object-status/",
	getStatusUpdatesForObject: api_host + "object-status/object/",
	getStatusUpdatesForDataWarehouse: api_host + "object-status/data-warehouse/",
	createNotification: api_host + "notification/",
	getUserNotifications: api_host + "notification/",
	getUserRoles: api_host + "role/",
	filterObjects: report_host + "standard-object/filter-objects",
	releaseObjects: api_host + "standard-object-git/release-objects",
	getLatestEnvironmentReleaseVersion: [api_host + "release-version/", "/latest/by-environment/"],
};

export const getUrl = (urlName, args = []) => {
	// const [arg1, arg2, ...rest] = args;
	let urlPieces = urls[urlName];
	let url = "";
	if (urlPieces instanceof Array && (urlPieces.length === args.length || urlPieces.length === args.length + 1)) {
		urlPieces.forEach((piece, i) => {
			if (args[i]) url += piece + args[i];
			else url += piece;
		});
	} else if (!(urlPieces instanceof Array) && args.length === 1) url += urlPieces + args[0];
	else url += urlPieces;
	return url;
};

/**
 * Makes a get request
 * @param urls
 * @returns {Promise<Response | never>}
 */
export const getCall = async (url, allAccess = false) => {
	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + getAccessToken(),
		"X-Content-Type-Options": "nosniff",
		Accept: "application/json",
		"Accept-Encoding": "gzip, deflate, br",

		"Accept-Language": "en-US,en;q=0.9",
		Connection: "keep-alive",
		Host: "localhost:8080",
		Origin: "http://localhost:3000",
		Referer: "http://localhost:3000/",
		"Sec-Fetch-Dest": "empty",
		"Sec-Fetch-Mode": "cors",
		"Sec-Fetch-Site": "same-site",
		"User-Agent":
			"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.5060.134 Safari/537.36 Edg/103.0.1264.71",
		"sec-ch-ua-mobile": "?0",
		"sec-ch-ua-platform": "macOS",
	};
	if (allAccess) delete headers["Authorization"];

	return await fetch(url, {
		headers: headers,
	})
		.then(function (response) {
			return response.json();
		})
		.catch(function (err) {
			if (err.message.includes("Unexpected end of JSON input")) console.error("No response");
			else {
				console.error("unable to retrieve the data because of: " + err);
				throw err;
			}
		});
};

/**
 * Makes a post request
 * @param url
 * @param requestBody
 * @returns {Promise<Response>}
 */
export const postCall = async (url, requestBody, allAccess = false) => {
	let headers = { "Content-Type": "application/json", Authorization: "Bearer " + getAccessToken() };
	if (allAccess) delete headers["Authorization"];

	return await fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(requestBody),
	})
		.then(function (response) {
			if (!response.ok) throw new Error(response.status);
			return response
				.json()
				.then(function (responseBody) {
					return responseBody;
				})
				.catch(function (err) {
					// console.error(err);
					return response;
				});
		})
		.catch(function (err) {
			throw err;
		});
};

export const getChecklistTasks = async (uuid, versionUuid, changes) => {
	let url = getUrl("getChecklistTasks", [uuid, versionUuid]);
	return await postCall(url, changes);
};

export const generateSourceCode = async (uuid, version, type = "template", changes) => {
	try {
		return await postCall(getUrl("generateSourceCode", [uuid, version, type]), changes);
	} catch (e) {
		console.error("Unable to generate source code.", e);
	}
};

export const getDestinationModelObjectTypes = async () => {
	let url = getUrl("getDestinationModelObjectTypes");
	return await getCall(url);
};

export const getDestinationModels = async () => {
	return await getCall(getUrl("getDestinationModels"));
};

export const getMasterFileIndex = async (uuid) => {
	return await getCall(getUrl("getMfi", [uuid, -1]));
};

export const getObjectDTOByUuidAndVersion = async (uuid, versionUuid) => {
	return await getCall(getUrl("getObjectDTOByUuidAndVersion", [uuid, versionUuid]));
};

export const getDataWarehouseAndSpecial = async (dataWarehouseUuid) => {
	return await getCall(getUrl("getDataWarehouseAndSpecial", [dataWarehouseUuid]));
};

export const getGeneralTypes = async () => {
	return await getCall(getUrl("getGeneralTypes"));
};

export const saveRelationships = async (relationships) => {
	return await postCall(getUrl("saveRelationships"), relationships);
};

export const getRelatedObjectsDtos = async (objectUuid) => {
	return await getCall(getUrl("getRelatedObjects", [objectUuid]));
};

export const getObjectRelationships = async (objectUuid) => {
	return await getCall(getUrl("getObjectRelationships", [objectUuid]));
};

export const getObjectsChangeRequests = async (objectUuid, objectVersionUuid) => {
	if (!objectUuid) return [];

	let rows = await getCall(getUrl("getObjectsChangeRequests", [objectUuid]));

	return rows || [];
};

export const getObjectsUpdates = async (objectUuid, objectVersionUuid) => {
	if (!objectUuid) return [];

	let rows = await getCall(getUrl("getObjectsUpdates", [objectUuid, objectVersionUuid, ""]));

	return rows || [];
};

export const getObjectsOpenPoints = async (objectUuid = "") => {
	let rows = await getCall(getUrl("getObjectsOpenPoints") + objectUuid);

	return rows || [];
};

export const getSheetOpenPoints = async (sheetUuid = "") => {
	let rows = await getCall(getUrl("getOpenPointsBySheet") + sheetUuid);

	return rows || [];
};

export const saveOpenPoints = async (openPoints) => {
	if (!openPoints || openPoints.length < 1) {
		return;
	}

	let url = getUrl("saveOpenPoints");
	return await postCall(url, openPoints);
};
/**
 * Retrieves the object's master file index
 * @param objectUuid
 * @returns {Promise<*>}
 */
export const getObjectMfi = async (objectUuid, objectVersionUuid, dispatch, computerVersion) => {
	//Verify we got a valid objectUuid
	if (!objectUuid) return [];

	if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

	//Get the object's mfi
	let rows = [];
	let url = "";
	try {
		if (computerVersion)
			url = getUrl("getObjectMfiSpecificVersion", [objectUuid, objectVersionUuid, computerVersion]);
		else if (objectVersionUuid) url = getUrl("getObjectMfi", [objectUuid, objectVersionUuid]);
		else url = getUrl("getLatestObjectMfi", [objectUuid]);

		rows = await getCall(url, false);
		if (rows.error) throw Error(rows.error);

		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });

		if (rows.length > 0) return rows.sort(sortByReference);
		else return rows;
	} catch (e) {
		console.error("Error when getting object MFI: ", e);
		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	}
};

/**
 * Retrieves the object's master file index
 * @param objectUuid
 * @returns {Promise<*>}
 */
export const getSingleLevelObjectMfi = async (
	objectUuid,
	objectVersionUuid,
	dispatch,
	computerVersion,
	body,
	topAncestorUuid,
	topAncestorVersionUuid
) => {
	//Verify we got a valid objectUuid
	if (!objectUuid) return [];

	if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

	//Get the object's mfi
	let rows = [];
	let url = "";
	try {
		if (computerVersion)
			url = getUrl("getSingleLevelObjectMfiSpecificVersion", [objectUuid, objectVersionUuid, computerVersion]);
		else if (objectVersionUuid && topAncestorUuid)
			url = getUrl("getSingleLevelObjectMfiWithTopAncestor", [
				objectUuid,
				objectVersionUuid,
				topAncestorUuid,
				topAncestorVersionUuid,
			]);
		else if (objectVersionUuid) url = getUrl("getSingleLevelObjectMfi", [objectUuid, objectVersionUuid]);
		else {
			// if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			// return;
			url = getUrl("getSingleLevelObjectMfi", [objectUuid]);
		}

		if (body?.reference === "0") body.reference = "";

		rows = await postCall(url, body);
		if (rows.error) throw Error(rows.error);

		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });

		if (rows.length > 0) {
			if (!rows[0].parentUuid) rows[0].parentUuid = ZERO_ROW_UUID;

			return rows.sort(sortByReference);
		} else return rows;
	} catch (e) {
		console.error("Error when getting single level object MFI: ", e);
		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	}
};

/**
 * Retrieves a copy of the object's master file index
 * @param objectUuid
 * @returns {Promise<*>}
 */
export const copySingleLevelObjectMfi = async (objectUuid, objectVersionUuid, dispatch, computerVersion, body) => {
	//Verify we got a valid objectUuid
	if (!objectUuid) return [];

	if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

	//Get the object's mfi
	let rows = [];
	let url = "";
	try {
		// if (computerVersion)
		// 	url = getUrl("getSingleLevelObjectMfiSpecificVersion", [objectUuid, objectVersionUuid, computerVersion]);
		// else if (objectVersionUuid && topAncestorUuid)
		// 	url = getUrl("getSingleLevelObjectMfiWithTopAncestor", [
		// 		objectUuid,
		// 		objectVersionUuid,
		// 		topAncestorUuid,
		// 		topAncestorVersionUuid,
		// 	]);
		// else if (objectVersionUuid) url = getUrl("getSingleLevelObjectMfi", [objectUuid, objectVersionUuid]);
		// else {
		// 	// if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
		// 	// return;
		// 	url = getUrl("getSingleLevelObjectMfi", [objectUuid]);
		// }

		if (objectVersionUuid) url = getUrl("copySingleLevelObjectMfi", [objectUuid, objectVersionUuid]);
		else url = getUrl("copySingleLevelObjectMfi", [objectUuid]);

		if (body?.reference === "0") body.reference = "";

		rows = await postCall(url, body);
		if (rows.error) throw Error(rows.error);

		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });

		if (rows.length > 0) {
			if (!rows[0].parentUuid) rows[0].parentUuid = ZERO_ROW_UUID;

			return rows.sort(sortByReference);
		} else return rows;
	} catch (e) {
		console.error("Error when getting single level object MFI: ", e);
		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	}
};

/**
 * Retrieves the object's master file index
 * @param objectUuid
 * @returns {Promise<*>}
 */
export const getObjectMfiRow = async (objectUuid, objectVersionUuid, reference, dispatch) => {
	//Verify we got a valid objectUuid
	if (!objectUuid) return [];

	if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

	//Get the object's mfi
	let rows = [];
	let url = "";
	try {
		if (objectVersionUuid) url = getUrl("getObjectMfiRow", [objectUuid, objectVersionUuid, reference]);

		rows = await getCall(url, false);
		if (rows.error) throw Error(rows.error);

		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });

		//Because we don't know what we are doing, we are going to loop over the array and make it not so stupid
		rows = rows.map((row) => row.value);

		if (rows.length > 0) return rows.sort(sortByReference);
		else return rows;
	} catch (e) {
		console.error("Error when getting object MFI: ", e);
		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	}
};

export const getObjectsOfTypeInDataWarehouseByFilter = async (objectTypeUuid, dataWarehouseUuid, filter = {}) => {
	if (!objectTypeUuid || !dataWarehouseUuid) {
		console.error(
			"Attempting to load the objects by type and filter in Data Warehouse \nSomething is not right: Object Type Uuid",
			objectTypeUuid,
			"Data Warehouse Uuid",
			dataWarehouseUuid
		);
		return [];
	}

	let url = getUrl("getObjectsOfTypeInDwWithFilter", [objectTypeUuid, dataWarehouseUuid]);
	return await postCall(url, filter);
	// standard-object/:uuid/in-data-warehouse/:dataWarehouseUuid/filter
};

export const getFilter = async (uuid) => {
	return await getCall(urls.getFilter + uuid);
};

export const getSingleLevelObjectMfiFromBeginningAndEndOfPath = async (row) => {
	let url = getUrl("getSingleLevelObjectMfiFromBeginningAndOfPath");
	return await postCall(url, row);
};

export const getSingleLevelObjectMfiFromPath = async (row) => {
	let url = getUrl("getSingleLevelObjectMfiFromPath");
	return await postCall(url, row);
};

export const getObjectGitRecord = async (uuid, versionUuid) => {
	//If there is no version passed in go get the latest version of the object
	if (!versionUuid) return await getCall(getUrl("getStandardObjectGitByUuid", [uuid]));

	return await getCall(getUrl("getStandardObjectGit", [uuid, versionUuid]));
};

export const getObjectDataWarehouseSource = async (uuid, versionUuid) => {
	return await getCall(getUrl("getObjectDataWarehouseSource", [uuid, versionUuid]));
};

export const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.0/8 are considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const isTest = Boolean(window.location.hostname.match(/^test\./));

export const repairObjectReferences = async (uuid, versionUuid) => {
	let url = getUrl("repairObjectMfiReferences", [uuid, versionUuid]);
	await postCall(url);
};

export const createObjectStatus = async (status) => {
	let url = getUrl("createObjectStatusRecord");
	postCall(url, status);
};

export const getStatusUpdatesForObject = async (objectUuid) => {
	let url = getUrl("getStatusUpdatesForObject", [objectUuid]);
	return await getCall(url);
};

export const getStatusUpdatesForDataWarehouse = async (uuid) => {
	let url = getUrl("getStatusUpdatesForDataWarehouse", [uuid]);
	return await getCall(url);
};

export const createNotification = async (notification) => {
	let url = getUrl("createNotification");
	postCall(url, notification);
};

export const getUserNotifications = async (userUuid) => {
	let url = getUrl("getUserNotifications", [userUuid]);
	return await getCall(url);
};

export const getUserRoles = async (userUuid) => {
	let url = getUrl("getUserRoles", [userUuid]);
	return await getCall(url);
};

export const getObjectHierarchy = async ({ uuid, versionUuid }) => {
	let objectHierarchy = await getCall(getUrl("getObjectsHierarchy", [uuid, versionUuid]));

	return objectHierarchy || [];
};

/**
 * Retrieves the object's master file index
 * @param objectUuid
 * @returns {Promise<*>}
 */
export const getObjectMfiBySubObject = async (objectUuid, objectVersionUuid, dispatch, computerVersion) => {
	//Verify we got a valid objectUuid
	if (!objectUuid) return [];

	if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

	//Get the object's mfi
	let rows = [];
	let url = "";
	try {
		if (computerVersion)
			url = getUrl("getObjectMfiSpecificVersion", [objectUuid, objectVersionUuid, computerVersion]);
		else if (objectVersionUuid) url = getUrl("getObjectMfiBySubObject", [objectUuid, objectVersionUuid]);
		else url = getUrl("getLatestObjectMfi", [objectUuid]);

		rows = await getCall(url, false);
		if (rows.error) throw Error(rows.error);

		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });

		if (rows.length > 0) return rows.sort(sortByReference);
		else return rows;
	} catch (e) {
		console.error("Error when getting object MFI: ", e);
		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	}
};

export const filterObjects = async (objects) => {
	let url = getUrl("filterObjects");
	return await postCall(url, objects);
};

export const releaseObjects = async (objects) => {
	let url = getUrl("releaseObjects");
	return await postCall(url, objects);
};

export const getLatestEnvironmentReleaseVersion = async (uuid, env) => {
	let url = getUrl("getLatestEnvironmentReleaseVersion", [uuid, env]);
	return await getCall(url);
};
