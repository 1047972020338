import { useEffect } from "react";
import { useParams } from "react-router";
import ObjectWorkspaceBody from "../../components/ReactGridComponents/Body/Body";
import "./Workspace.scss";

/**
 * Testing the new Object Workspace Layout
 * @param {}
 * @constructor
 */
const ObjectWorkspaceTest = () => {
	let params = useParams();
	useEffect(() => {
		document.title = "Object Workspace";
	});
	return (
		<div style={{ height: "93%", paddingTop: "5px", backgroundColor: "#f3f7fd" }}>
			<ObjectWorkspaceBody params={params} />
			<a id="downloadAnchorElem" style={{ display: "none" }}></a>
		</div>
	);
};

export default ObjectWorkspaceTest;
