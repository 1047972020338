import { getNextRef, getParentRef, sortByReference } from "./Referencing";
import { convertListToRealJsMap } from "./StandardObject";

export function listToTree(list, topNodeId, createCopy = false) {
	if (!list || !list.length) return undefined;
	let map = {},
		node,
		roots = [],
		i;
	let tempList = [...list];

	let listMap = new Map();
	list.forEach((row) => listMap.set(row.uuid, row));

	/**
	 * If there is not a top node id, I should just figure it out. How can I do this?
	 * Sort by reference, if the data is correct either the top row will be the parent of the tree
	 * Or it will have siblings and top row will not be in the tree
	 */
	if (!topNodeId) {
		topNodeId = getTopNodeId(list);
	}

	for (i = 0; i < list.length; i += 1) {
		map[list[i].uuid] = i; // initialize the map
		tempList[i] = {
			data: createCopy ? { ...list[i] } : list[i],
			children: [],
			ancestors: [],
		};
	}

	for (i = 0; i < tempList.length; i += 1) {
		node = tempList[i];
		if (topNodeId && node.data.parentUuid !== topNodeId) {
			// if you have dangling branches check that map[node.parentId] exists
			if (tempList[map[node.data.parentUuid]]) {
				let ancestors = tempList[map[node.data.uuid]].ancestors.concat(
					tempList[map[node.data.parentUuid]].ancestors
				);
				tempList[map[node.data.parentUuid]].children.push(node);
				tempList[map[node.data.uuid]].ancestors = ancestors;

				tempList[map[node.data.parentUuid]].data.hasChildren = true;

				listMap.get(node.data.uuid).ancestors = ancestors;
			}
			if (tempList[map[node.data.uuid]]) {
				tempList[map[node.data.uuid]].ancestors.push(node.data.parentUuid);
			}
		} else {
			roots.push(node);
		}
	}

	return roots;
}

/**
 * Builds a tree containing only the nodes with the passed in type and their descendants
 *  If the descendants contain another object it will treat that object the same and only grab that objects Model MFI View rather than its object MFI
 * @param data
 * @param topNode
 * @param type
 */
export const buildTypeOfTreeList = (data, topNode, type) => {
	let dataMap = new Map();
	data.forEach((row) => dataMap.set(row.uuid, row));

	//Get all the nodes of the type
	let nodes = data.filter((row) => row.objectTypeUuid === type);
	let nodeMap = new Map();

	//Create a list of owners that I can use to tie to each other later
	let ownerList = [];

	//Create a list of the node uuids I've already hit, I don't want to hit nested MFI Views twice
	let usedUuids = [];

	//Iterate over each node, build a map tying each node to its descendants and owner
	nodes.forEach((node) => {
		if (usedUuids.includes(node.uuid)) return;
		else usedUuids.push(node.uuid);

		let owner = findOwnerByMap(node, node, dataMap, topNode);
		let descendants = data.filter((row) => row.reference.startsWith(node.reference) && row.uuid !== node.uuid);

		//Check if any of the descendants are an object, if they are we don't want that objects descendants we want that objects mfi view.
		// I'm hoping we can rely on the versionControl attribute? I think that will only be set for objects
		let descendantObjects = descendants.filter((desc) => desc.versionControl);

		//Iterate over each descendant object and build that descendants tree
		descendantObjects.forEach((row) => {
			usedUuids.push(row.uuid);
			//Build the type of tree list for this object
			let subDescendants = descendants.filter(
				(item) => item.reference.startsWith(row.reference) && row.uuid !== item.uuid
			);

			//Get the ids of the sub descendants and we will remove them from the array of descendants
			let subUuids = subDescendants.map((item) => item.uuid);
			descendants = descendants.filter((item) => !subUuids.includes(item.uuid));

			let subMfiView = buildTypeOfTreeList(subDescendants, row, type);
			descendants = [...descendants, ...subMfiView];
		});

		//Look at the descendants and find any objects (How do I tell if they are an object? Probably check their children
		ownerList.push(owner);

		//Add the node to the map by its owners id
		if (!nodeMap.has(owner.uuid)) nodeMap.set(owner.uuid, { setOfDescendants: [], me: owner });

		nodeMap.get(owner.uuid).setOfDescendants.push(descendants);
	});

	let list = [];
	//Iterate over entries in map, tie them to the nearest owner, add to list, and build tree
	[...nodeMap.entries()].forEach(([ownerUuid, { setOfDescendants, me }]) => {
		setOfDescendants.forEach((set) => {
			let [child, ...rest] = set.sort(sortByReference);

			let children = rest.filter((row) => row.parentUuid === child.parentUuid);
			let notChildren = rest.filter((row) => row.parentUuid !== child.parentUuid);
			[child, ...children].forEach((row) => {
				let copy = { ...row };
				copy.parentUuid = ownerUuid;
				list.push(copy);
			});

			notChildren.forEach((row) => list.push(row));
		});

		//Get the nearest owner to me
		let ancestors = ownerList.filter((owner) => me.uuid !== owner.uuid && me.reference.startsWith(owner.reference));
		let meCopy = { ...me };

		//Find the nearest ancestor
		let nearestAncestor = ancestors[0];
		if (nearestAncestor) {
			ancestors.forEach((ancestor) => {
				if (
					me.reference.length - ancestor.reference.length <
					me.reference.length - nearestAncestor.reference.length
				)
					nearestAncestor = ancestor;
			});
			meCopy.parentUuid = nearestAncestor.uuid;
		}
		//If there isn't an ancestor set the ancestor to the top node
		else meCopy.parentUuid = topNode.uuid;

		list.push(meCopy);
	});

	return list.sort(sortByReference);
};

/**
 * Recursive function that returns a list with a reference to each node in the tree (I'm hoping these are references rather than copies)
 * @param topNode
 */
export const getMapOfTreeNodes = (tree) => {
	let map = new Map();

	if (!tree || tree.length < 1) return map;

	//Check if topNode is an array or object
	if (tree.length) {
		tree.forEach((node) => {
			map.set(node.uuid || node.data.uuid, node);
			//Base case, if there are no children return the node
			if (node.children.length < 1) return map;

			node.children.forEach((child) => (map = new Map([...map, ...getMapOfTreeNodes(child)])));
		});
	} else {
		map.set(tree.uuid || tree.data.uuid, tree);
		//Base case, if there are no children return the node
		if (tree.children.length < 1) return map;

		tree.children.forEach((child) => (map = new Map([...map, ...getMapOfTreeNodes(child)])));
	}
	return map;
};

/**
 * Gets the ancestors for the row with a corresponding uuid
 */
export const getAncestors = (uuid, list) => {
	//Convert list to Map?
	let map = new Map();
	list.forEach((row) => map.set(row.uuid, row));
	let ancestors = [];
	getAncestorRecursively(uuid, map, ancestors);

	return ancestors;
};

const getAncestorRecursively = (uuid, map, ancestors) => {
	let row = map.get(uuid);
	if (row) {
		ancestors.push(row);
		getAncestorRecursively(row.parentUuid, map, ancestors);
	}
};

/**
 * Returns the closest ancestor that is an object
 * @param reference
 * @param list
 * @param map
 */
export const findObjectAncestorUsingReference = (reference, list, map) => {
	//Build a map by reference, this will allow us to look up really fast especially if we are far down the tree
	if (!map) {
		map = new Map();
		list.forEach((row) => map.set(row.reference, row));
	}

	let currentRow = map.get(reference);

	//If we didn't find a row, just return the top row? We will assume that is the ancestor
	if (!currentRow) return list[0];

	if (currentRow.isObject) return currentRow;
	else {
		//If the reference doesn't include a '.' we're at the top level and we can just return the very top
		if (!reference.includes(".")) return list[0];

		return findObjectAncestorUsingReference(getParentRef(reference), list, map);
	}
};

/**
 * Adds the ancestors to each node in the list of nodes
 * @param list
 * @returns {*}
 */
export const uuidToAncestors = (list) => {
	if (list.length < 1) return list;
	let map = {},
		node,
		i;

	for (i = 0; i < list.length; i += 1) {
		map[list[i].uuid] = []; // initialize the map
	}

	for (i = 0; i < list.length; i += 1) {
		node = list[i];

		if (map[node.parentUuid]) {
			map[node.uuid] = map[node.uuid].concat(map[node.parentUuid]);
		}
		if (map[node.uuid]) map[node.uuid].push(node.parentUuid);
	}

	return map;
};

/**
 * Get the top node in the tree, sort the list and return the parentUuid of the first record, if it doesn't have a parentUuid return the first records uuid
 * @param list
 * @returns {*}
 */
export function getTopNodeId(list) {
	//Sort by the reference
	let tempList = [...list];
	tempList.sort((a, b) => a.reference.split(".").length - b.reference.split(".").length);
	if (tempList.length < 1) return undefined;

	let id = tempList[0].parentUuid;
	if (!id) id = tempList[0].uuid;
	return id;
}

/**
 * Converts list to a tree based on ancestorUuid, whereas listToTree builds tree based on parentUuid
 * @param list
 * @param topNodeId
 * @returns {*}
 */
export function listToTreeAncestor(list, topNodeId, ancestorField = "ancestorUuid") {
	if (!list || !list.length) return undefined;
	let map = {},
		node,
		roots = [],
		i;
	let tempList = [...list];

	for (i = 0; i < list.length; i += 1) {
		map[list[i].uuid] = i; // initialize the map
		tempList[i] = {
			data: list[i],
			children: [],
		};
	}

	for (i = 0; i < tempList.length; i += 1) {
		node = tempList[i];
		if (topNodeId && node.data.uuid !== topNodeId) {
			// if you have dangling branches check that map[node.parentId] exists
			if (tempList[map[node.data[ancestorField] || node.data.parentUuid]]) {
				tempList[map[node.data[ancestorField] || node.data.parentUuid]].hasChildren = true;
				tempList[map[node.data[ancestorField] || node.data.parentUuid]].children.push(node);
			}
			// if(tempList[map[node.uuid]])
			//     tempList[map[node.uuid]].children.push(node.ancestorUuid);
		} else {
			roots.push(node);
		}
	}

	return roots;
}

export const convertTreeToList = (roots) => {
	let stack = [],
		array = [],
		hashMap = {};

	if (!roots || roots.length < 1) return undefined;

	roots.forEach((root) => {
		if (root.children.length > 0) root.data.hasChildren = true;
		stack.push(root);

		while (stack.length !== 0) {
			let node = stack.pop();
			visitNode(node, hashMap, array);
			if (!node.children || node.children.length < 1) {
			} else {
				node.data.hasChildren = true;
				for (let i = node.children.length - 1; i >= 0; i--) {
					stack.push(node.children[i]);
				}
			}
		}
	});

	return array;
};

/**
 * Returns a list of the tree nodes, preserving order and removing duplicates
 * @param root
 * @returns {Array}
 */
export const convertTreetoListOfLeaves = (root) => {
	let stack = [],
		array = [],
		hashMap = {};
	stack.push(root);

	while (stack.length !== 0) {
		let node = stack.pop();
		if (!node.children || node.children.length < 1) {
			visitNode(node, hashMap, array);
		} else {
			for (let i = node.children.length - 1; i >= 0; i--) {
				stack.push(node.children[i]);
			}
		}
	}

	return array;
};

/**
 * Checks if we have already added the node to the list, if not add it, if so skip over it.
 * @param node
 * @param hashMap
 * @param array
 */
function visitNode(node, hashMap, array) {
	//TODO: I'm going to change this so it retains its pointer rather than creating a copy, verify this won't break anything
	// let data;
	// if(node.data)
	//     data = { ...node.data };
	// else
	//     data = {...node, children: []};

	if (!hashMap[node.uuid || node.data.uuid]) {
		hashMap[node.uuid || node.data.uuid] = true;
		array.push(node.data ? node.data : node);
	}
}

/**
 * Get the tree leaves from the data array
 * @param data: A filtered part of the mfi array (can be the same)
 * @param mfi: The entire list of tree nodes
 * @returns {*}
 */
export const getLeaves = (data, mfi) => {
	if (!data || data.length < 1 || mfi.length < 1) return [];

	let parentIds = mfi.map((row) => row.parentUuid);
	return data.filter((row) => (row.row ? !parentIds.includes(row.row.uuid) : !parentIds.includes(row.uuid)));
};

//TODO: Change to use the object type field rather than the standard object mfi
export const standardObjectFields = [
	"Identification",
	"Control",
	"Legal",
	"Components",
	"Methods",
	"Attachment",
	"Relationships",
	"Setup",
	"Reference Manual",
	"Views",
	"Validation",
	"Security",
	"Data Sources",
	"Hierarchy & Non-Hierarchy Object Definitions",
	"Design Specific Documents",
];

/**
 *
 * @param data: a filtered list of the mfi (although can be the same as mfi), used in the setup sheets this is the rows that appear on a specific setup sheet
 * @param mfi: the entire mfi
 * @param top: the top of the tree
 */
export const convertListToLeavesWithOwner = (data, mfi, top) => {
	//Build map of data
	let dataMap = new Map();
	mfi.forEach((row) => dataMap.set(row.uuid, row));

	let leaves = getLeaves(data, mfi);

	let objToLeaves = new Map();

	leaves.forEach((leaf) =>
		leaf.row
			? buildObjectToLeafMap(leaf, leaf.row, dataMap, objToLeaves, top)
			: buildObjectToLeafMap(leaf, leaf, dataMap, objToLeaves, top)
	);
	return objToLeaves;
};

/**
 * Maps an object and it's leaves together
 * @param leaf
 * @param parent
 * @param data
 * @param map
 * @param top
 */
const buildObjectToLeafMap = (leaf, parent, dataMap, map, top) => {
	//Get the leaf's owner
	let owner = findOwnerByMap(leaf, parent, dataMap, top);

	if (!owner) return;

	if (!map.has(owner.uuid)) map.set(owner.uuid, { parent: { ...owner }, leaves: [] });

	map.get(owner.uuid).leaves.push(leaf);
};

/**
 * Accepts a leaf, parent, list of rows, and a map
 * Recursively navigates the tree upwards looking for the object
 * that owns the leaf. Adds it to the map when it finds it
 **/
export const findOwner = (leaf, parent, data, top, parentField = "parentUuid") => {
	let newParent = data.find((obj) => obj.uuid === parent[parentField]);
	//If there is not a newParent assume we have hit the top and that the top object
	//  owns this leaf
	if (!newParent) {
		if (parent[parentField] === top.uuid) parent = { ...top };

		return parent;
	}

	if (newParent.uuid === top.uuid) return { ...top };

	// if(objectTypeUuid)
	// {
	//     if(newParent.objectType && newParent.objectType.uuid === objectTypeUuid)
	//         return newParent;
	// }
	if (standardObjectFields.includes(newParent.title)) {
		let object = data.find((obj) => obj.reference === getParentRef(newParent.reference));
		if (!object && newParent[parentField] === top.uuid) object = { ...top };

		return object;
	}

	return findOwner(leaf, newParent, data, top);
};

/**
 * Accepts a leaf, parent, list of rows, and a map
 * Recursively navigates the tree upwards looking for the object
 * that owns the leaf. Adds it to the map when it finds it
 **/
export const findOwnerByMap = (leaf, parent, dataMap, top) => {
	parent = parent.data ? { ...parent.data } : { ...parent };
	if (standardObjectFields.includes(parent.title)) {
		let object = dataMap.get(parent.parentUuid);
		if (!object && parent.parentUuid === top.uuid) object = { ...top };

		return object;
	}
	let newParent = dataMap.get(parent.parentUuid);
	//If there is not a newParent assume we have hit the top and that the top object
	//  owns this leaf
	if (!newParent) {
		if (parent.parentUuid === top.uuid) parent = { ...top };

		return parent;
	}

	return findOwnerByMap(leaf, newParent, dataMap, top);
};

/**
 * Accepts a leaf, parent, list of rows, and a map
 * Recursively navigates the tree upwards looking for the object
 * that owns the leaf. Adds it to the map when it finds it
 **/
export const findSetupSheetOwner = (leaf, dataMap, setupSheetType) => {
	let newParent = dataMap.get(leaf.parentUuid);
	//If there is not a newParent assume we have hit the top and that the top object
	//  owns this leaf
	if (!newParent) {
		return undefined;
	} else if (newParent.objectTypeUuid === setupSheetType) {
		return newParent;
	}

	return findSetupSheetOwner(newParent, dataMap, setupSheetType);
};

/**
 * Takes in the setupTrees and the subSetupTrees and merges them together
 * @param setupTrees
 * @param subSetupTrees
 */
export const mergeSetupTrees = (setupTrees, subSetupTrees) => {
	//Convert each setup tree to a list that we can use to find where each sub tree needs to attach
	let setupTreeLists = new Map();
	[...setupTrees.keys()].forEach((key) => setupTreeLists.set(key, convertTreeToList(setupTrees.get(key))));

	//Merge the subSetupTrees
	[...subSetupTrees.keys()].forEach((key) => {
		//For each key check if it already exists within the setupTrees
		if (!setupTrees.has(key)) {
			setupTrees.set(key, subSetupTrees.get(key));
			return;
		}

		//If the key already exists in setupTrees, figure out where the sub tree attaches and call updateTree
		let list = setupTreeLists.get(key);

		//Get the top node of the subSetupTree at this key
		let { data: top } = subSetupTrees.get(key)[0];

		let setupAncestors = list
			.filter((row) => top.ancestors.includes(row.uuid))
			.sort((a, b) => {
				return top.reference.length - a.reference.length - (top.reference.length - b.reference.length);
			});
		//Because of the previous sort, the nearest ancestor should be the first one
		let nearestAncestor = setupAncestors[0];

		updateTree(setupTrees.get(key), subSetupTrees.get(key), nearestAncestor.uuid, true, {
			refField: `setup${key}Reference`,
			reRefSubTree: true,
		});
	});
	return setupTrees;
};

/**
 * Takes in the tree and merges the subTree into it
 *
 * @param tree
 * @param subTree
 * @param uuid: The uuid to attach to
 * @param addToTree: if this is true it will add the subtree, otherwise it will attempt to remove it.
 */
export const updateTree = (
	tree,
	subTree,
	uuid,
	addToTree,
	{ refField = "reference", reRefSubTree = false, setupSheet }
) => {
	//Recursively iterate the tree untli we find the parent node of the sub tree and just stick it in
	//Get the uuid of the node the subTree needs to attach to
	if (!tree || tree.length < 1) return subTree;

	if (!subTree || subTree.length < 1) return tree;

	let parentNode;
	//Iterate over the tree until we find a node with that uuid
	if (tree.length === 1) parentNode = findNodeInTree(tree[0], uuid);
	else {
		for (const node of tree) {
			parentNode = findNodeInTree(node, uuid);
			if (parentNode) break;
		}
	}

	if (parentNode && (addToTree || reRefSubTree)) {
		subTree.forEach((node) => {
			let refToPrepend = getNextRef(parentNode.children[parentNode.children.length - 1].data[refField]);
			recursivelyUpdateRefInTree(node, refToPrepend.reference, refField);
			parentNode.children.push(node);
		});
	} else if (!addToTree) {
		if (parentNode) {
			let idsToRemove = subTree.map((node) => node.uuid);
			parentNode.children = parentNode.children.filter(
				(child) => !idsToRemove.includes(child.uuid || child.data.uuid)
			);
		}
		//Otherwise check if the node is in the tree
		else {
			//At this point is it worth rebuilding the whole tree?
			let subList = convertTreeToList(subTree);
			let subIds = subList.map((row) => row.uuid);
			let list = convertTreeToList(tree);
			let filtered = list.filter((row) => !subIds.includes(row.uuid));
			if (filtered.length < 1) return tree;

			return listToTree(filtered, tree[0].data.uuid);
		}
	}
};

/**
 * Recursively iterates over the tree until it finds the node that has the passed in uuid
 */
const findNodeInTree = (node, uuid) => {
	if (!node || (!node.uuid && !node.data.uuid) || !uuid) return;

	if ((node.uuid || node.data.uuid) === uuid) return node;

	if (node.children?.length > 0) {
		for (const child of node.children) {
			let newNode = findNodeInTree(child, uuid);
			if (newNode) return newNode;
		}
	}
};

/**
 * Recursively iterates over each node in the tree and its chidren and prepends the passed in reference onto its reference
 */
const recursivelyUpdateRefInTree = (tree, referenceToPrepend, refField = "reference") => {
	if (!tree || tree.length < 1 || !referenceToPrepend) return;

	if (tree.length) {
		tree.forEach((node) => {
			let data = node;
			if (data.data) data = data.data;

			data[refField] = referenceToPrepend + "." + node[refField];

			node.children.forEach((child) => recursivelyUpdateRefInTree(child, referenceToPrepend, refField));
		});
	} else {
		let data = tree;
		if (data.data) data = data.data;

		if (data[refField] !== "0") data[refField] = referenceToPrepend + "." + data[refField];
		else data[refField] = referenceToPrepend;

		tree.children.forEach((child) => recursivelyUpdateRefInTree(child, referenceToPrepend, refField));
	}
};

/**
 * Takes in an array of trees, searches for the node with the uuid and updates the field passed in.
 *
 * @param trees
 * @param nodeUuid
 * @param field
 * @param value
 */
export const prependReferences = (tree, refToPrepend, refField) => {};

//Recursively searches through a list and returns an item with all of it's children
export const getDescendantsRecursively = (uuid, sourceList, returnList) => {
	let children = sourceList.filter((record) => record.parentUuid == uuid);
	if (children.length === 0) return;

	returnList.push(...children);
	children.forEach((child) => {
		getDescendantsRecursively(child.uuid, sourceList, returnList);
	});
};
