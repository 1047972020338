import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const ActivateAccount = () => {
	const [searchParams] = useSearchParams();
	const queryParams = Object.fromEntries(searchParams);
	// get the first and last three characters of the uuid separated by a dash
	const account_code = `${queryParams.uuid.slice(0, 3)}-${queryParams.uuid.slice(-3)}`;

	useEffect(() => {
		document.title = "Activate";
	});

	return (
		<div className="container">
			<div className="row mt-3">
				<h1 className="text-center mb-3">Activate Account</h1>
				<p className="text-center">
					To activate your account, please contact sales at: <a href="tel:8014486376">(801)-448-6376</a>.
				</p>
				<p className="text-center m-0">Your account code is:</p>
				{/* First and last 3 of users UUID, to find them easy in the database TODO */}
				<h2 className="text-center">{account_code}</h2>
			</div>
		</div>
	);
};
export default ActivateAccount;
