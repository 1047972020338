import React from "react";
import { MenuButtonIcon } from "../../BootstrapComponents/Icons/Icons";
import { useDispatch } from "../../../utils/store";
import "./VerticalMenu";
/**
 * A menu that has list items that are vertically visible on hover
 * @param menuItems: menu items have to be an array where each entry has the attributes label and onClick
 * @constructor
 */
const VerticalMenu = ({ menuItems = [], ...other }) => {
	const dispatch = useDispatch();

	const getMenuItemElement = (item) => (
		<ul
			key={item.id}
			style={{ marginTop: "-40px", marginLeft: "100%" }}
			className="iq-submenu collapse"
			data-parent="#iq-sidebar-toggle"
		>
			{item.children.map((child) => (
				<li key={child.id} className={child.className} data-id={child.id}>
					<a
						href="#"
						onClick={() => child.onClick(dispatch)}
						style={{ textDecoration: "none" }}
						className={child.className}
					>
						{child.icon}
						<span style={{ paddingLeft: "10px" }}>{child.label}</span>
						{child.children ? (
							<i className={`iq-arrow-right`}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className="bi bi-caret-right-fill"
									viewBox="0 0 16 16"
								>
									<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
								</svg>
							</i>
						) : (
							""
						)}
					</a>
					{child.children ? getMenuItemElement(child) : ""}
				</li>
			))}
		</ul>
	);

	return (
		<div
			className="iq-menu-horizontal"
			style={{
				...other.style,
			}}
		>
			<nav className="iq-sidebar-menu">
				<ul id="iq-sidebar-toggle2" className="iq-menu d-flex">
					<li className={"softbox-menu"}>
						<a href="#" className="collapsed" data-toggle="collapse" aria-expanded="false">
							<button type="button" className="btn" style={{ padding: "5px", border: "none" }}>
								<MenuButtonIcon style={{ marginLeft: "8px" }} />
							</button>
						</a>
						<ul
							className="iq-submenu collapse"
							style={{
								...other.subMenuStyle,
							}}
							data-parent="#iq-sidebar-toggle2"
						>
							{menuItems.map((item) => (
								<li key={item.id} className={item.className} data-id={item.id}>
									<a
										href="#"
										onClick={() => item.onClick(dispatch)}
										style={{ textDecoration: "none" }}
										className={item.className}
									>
										{item.label == "Add Panel" ? (
											<>
												{item.icon}
												<span style={{ paddingLeft: "10px" }}>
													{item.label}
													<span style={{ color: "red", float: "none" }}>
														&nbsp;(under construction)
													</span>
												</span>
											</>
										) : (
											<>
												{item.icon}
												<span style={{ paddingLeft: "10px" }}>{item.label}</span>
												{item.children ? (
													<i className={`iq-arrow-right`}>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="16"
															height="16"
															fill="currentColor"
															className="bi bi-caret-right-fill"
															viewBox="0 0 16 16"
														>
															<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
														</svg>
													</i>
												) : (
													""
												)}
											</>
										)}
									</a>
									{item.children ? getMenuItemElement(item) : ""}
								</li>
							))}
						</ul>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default VerticalMenu;
