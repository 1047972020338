import { v4 as uuidv4 } from "uuid";
import { fixReferences, getNextRef, getReferenceNo, sortByReference } from "./Referencing";
import {
	copySingleLevelObjectMfi,
	getCall,
	getObjectGitRecord,
	getSingleLevelObjectMfi,
	getUrl,
	postCall,
} from "./ApiUtils";
import { convertTreeToList, findOwnerByMap, getLeaves, listToTree, listToTreeAncestor } from "./TreeUtils";
import { INPUT_FIELD_SOURCES, INPUT_FIELD_TYPES, SETUP_TYPES } from "./SetupTypes";
import { camelizeAndRemoveSpecialCharacters } from "./StringUtils";
import evaluateExpression from "./ExpressionUtils/ExpressionUtil";
import { zeroRowUuid } from "./MfiUtils";
import { objectStates } from "../components/VersionControl/ChangeRequest/ChangeRequestForm";
import { dataObjects } from "./DataObject";
import { viewTitles } from "./View";

export const ZERO_ROW_UUID = "00000000-0000-0000-0000-000000000000";
export const ASSOCIATED_OBJECT_GENERAL_TYPE_UUID = "c196f15c-4171-4d34-b483-123b95344558";
export const FILTERED_OBJECT_GENERAL_TYPE = "c59b0987-0bcc-4c67-bd79-99e1097f891c";
export const FUNCTION_OBJECT_TYPE = "f8c7eb25-8494-44f9-9a18-ce9bd3052886";
export const OPEN_POINT_OBJECT_TYPE = "aa95025c-8895-4d85-a925-bad434c134f8";
export const CLASS_OBJECT_GENERAL_TYPE = "3692e6c5-8ab1-4c3b-af01-9d4c80fbb20e";
export const INSTANCE_OBJECT_GENERAL_TYPE = "161dff06-0b72-4585-ac9c-7e24cf2b046d";
export const oldComponentsNodeTitle = "Components";
export const componentsNodeTitle = "Object Creator Defined Attributes";
export const setupNodeTitle = "Setup";
export const relationshipNodeTitle = "Relationships";
export const OBJECT_RECORD_NAME = "primus";
export const defaultMasterFileIndexChangeTitle = "Master File Index Row";
export const defaultStandardObjectGitChangeTitle = "Standard Object Git";

//Used so we dont commit changes to ubm rows that we pulled up in another destination;
export const UBM_MASTER_FILE_INDEX_ITEM = "UBM_ROW_DONT_CHANGE";

//Designate the 3rd section of a packet as the section products go
export const packetProductsSectionReference = "04.03";

//The name of the field we use to link to other stuff, fields, related objects, etc.
export const linkAttributeName = "linkToObjectUuid";
export const linkVersionAttributeName = "linkToObjectVersionUuid";

export const createNewMfiRow = (reference, title, parentUuid, userUuid) => {
	return {
		uuid: uuidv4(),
		reference,
		referenceNo: getReferenceNo(reference),
		title,
		parentUuid,
		included: false,
		location: "",
		createdBy: userUuid,
	};
};

export const createNewStandardObject = ({
	title = "",
	reference,
	parentUuid,
	setupForms = [],
	objTpConst,
	value = "",
	createdByUuid,
}) => {
	let uuid = uuidv4();

	return {
		uuid,
		title,
		description: "",
		reference: reference,
		referenceNo: getReferenceNo(reference),
		parentUuid: parentUuid,
		standardObjectUuid: uuid,
		objectType: {},
		stockNumber: {},
		setupForms: setupForms.map((setupForm) => setupForm.uuid),
		generalTypeUuid: objTpConst?.referenceUuid,
		value,
		createdByUuid,
		developerSetupForm: true,
		userSetupForm: true,
	};
};

//Takes in a standard object, creates a copy of the standard object and increments the version
//Version should be in the format of Major.Minor.Patch 1.7.14
//State should be based on the objectStates exported from the ChangeRequest
export const updateStandardObjectVersion = (object, version, state) => {
	//There are three options to increment the version
	//Option 1: Pass in the new version and the new state of the object, sets it to the passed in version
	let versionUuid = uuidv4();
	if (object && version && state) {
		object.versionControl = {
			uuid: versionUuid,
			objectVersionNumber: version,
			objectState: state,
			standardObjectGitUuid: object.uuid,
			objectTitle: object.title,
			createdAt: Date.now(),
		};
		object.versionUuid = versionUuid;
		return object;
	}
	//Option 2: Increments the next number version
	//Option 3: Increments the state of the object
};

/**
 * Creates a relationship between the two objects
 */
export const buildRelationship = (object, relatedObject, rowToAttachTo, destinationMfi, associatedObjectTypeUuid) => {
	let ancestorHierarchyRecord = object.objectHierarchy[0];
	if (!ancestorHierarchyRecord) ancestorHierarchyRecord = destinationMfi[0].objectHierarchy[0];

	//Check if the object is already related, if so we don't need to relate it again
	let relatedRow = destinationMfi.find((row) => row.uuid === relatedObject.uuid);
	if (relatedRow) return {};

	let hierarchyRecord = createObjectHierarchyRecord(ancestorHierarchyRecord, relatedObject);
	hierarchyRecord.hierarchyTypeUuid = associatedObjectTypeUuid;

	//Get the reference for the row that will hold the relationship
	let children = destinationMfi.filter((row) => row.parentUuid === rowToAttachTo.uuid);
	let newRef = getNextRef(`${rowToAttachTo?.reference}.${children.length}`);

	//Create the new row that will hold the relationship
	let newRow = {
		...relatedObject,
		...newRef,
		parentUuid: rowToAttachTo.uuid,
	};

	newRow.inputSource = INPUT_FIELD_SOURCES.OBJECT_RELATIONSHIP.value;
	newRow.object = true;
	// newRow[linkAttributeName] = ;

	return {
		newRow,
		hierarchyRecord,
	};
};

export const copyStandardObject = (
	obj,
	reference,
	parentUuid,
	createdByUuid,
	{
		newStockNumber = false,
		newVersion = false,
		newFork = false,
		newObject = false,
		releaseVersion = false,
		resetInputType = false,
		resetLinkToFields = false,
	} = {}
) => {
	//If we are copying for a releaseVersion we want to keep the same uuid
	let uuid = releaseVersion ? obj.uuid : uuidv4();
	let versionUuid = uuidv4();

	if (!parentUuid) parentUuid = ZERO_ROW_UUID;

	let copy = JSON.parse(JSON.stringify(obj));

	//Update the setupRow
	if (copy.setupRow) {
		copy.setupRow.uuid = uuidv4();
		copy.setupRow.objectUuid = uuid;
	}

	if (newStockNumber && copy.stockNumber && copy.stockNumber.uuid) {
		copy.stockNumber.uuid = uuidv4();
	}

	//Remove the computer version from the copy
	if (copy.versionControl && copy.versionControl.computerVersion) {
		delete copy.versionControl.computerVersion;
	}

	if (newObject) {
		copy.standardObjectUuid = copy.uuid;
		copy.standardObjectVersionUuid = copy.versionUuid;
		copy.new = true;

		//If the object being copied is class and newObject is true this is an instance with the class of the object being copied
		if (obj.roleTypeUuid === CLASS_OBJECT_GENERAL_TYPE) {
			copy.classObjectUuid = obj.uuid;
			copy.classObjectVersionUuid = obj.versionUuid;
			copy.roleTypeUuid = INSTANCE_OBJECT_GENERAL_TYPE;
		}
		//Else if the obj being copied is anything else? just copy it (We may not need to do anything here, it might copy by default)
		else if (obj.roleTypeUuid === "") {
		}
	}

	//TODO Consider how to do different object states right
	//ENUM on backend and pull it
	//ENUM on backend and front-end
	//Create a table for it
	//Create a db const that ties to the states
	//Add records in the type with a subtype of state
	if (newVersion) {
		let releaseVersionUuid = uuidv4();
		copy.versionControl = {
			uuid: releaseVersionUuid,
			objectVersionNumber: "0.0.1",
			objectState: objectStates.UNDERCONSTRUCTION,
			standardObjectGitUuid: uuid,
			objectTitle: copy.title,
		};
		// copy.versionUuid = releaseVersionUuid;

		if (newFork) {
			copy.gitFork = {
				uuid: uuidv4(),
				originalStandardObjectUuid: obj.uuid,
				originalVersionUuid: obj.versionUuid,
				derivativeStandardObjectUuid: uuid,
				derivativeVersionUuid: releaseVersionUuid,
			};
		}

		if (!releaseVersion) copy.versionUuid = versionUuid;
		copy.new = true;
	}

	if (resetInputType) copy.inputType = "";
	if (resetLinkToFields) {
		copy.linkToObjectUuid = "";
		copy.linkToObjectVersionUuid = "";
	}
	//Can I get the default value here?

	return {
		...copy,
		uuid,
		// title: obj.title,
		// description: obj.description,
		reference,
		referenceNo: getReferenceNo(reference),
		parentUuid,
		// standardObjectUuid: obj.uuid,
		// referenceObjectTitle: obj.title,
		// stockNo: obj.stockNo,
		// stockNumber: obj.stockNumber || {},
		// location: obj.location,
		objectType: {},
		//TODO: I think there are other fields that we need to reset here
		mfiGeneralTypeUuid: "",
		createdByUuid,
		// value: '',
	};
};

/**
 * Copy list of standard objects, retaining their hierarchy.
 * THIS ONLY WORKS IF THE TOP OBJECT WITH A REFERENCE OF '0' IS IN THE LIST
 * @param objects
 * @param parentObj
 * @param newReference
 */
export const copyObjectMfi = (objects, oldAncestorId, newAncestorId, newAncestorRef, createdByUuid, props) => {
	let { sameTree, updateStandardObjectUuid } = props || {};
	let mfi = [];
	//Create a map that can be used to store the old to new uuid
	let oldToNewParentMap = new Map();

	objects.sort(sortByReference);
	let oldAncestorRef = objects[0].reference;

	if (!oldAncestorRef) oldAncestorRef = "0";

	//This is cheating, I am attaching the objectHierarchy from the first row to the array that is returned
	mfi.objectHierarchy = objects[0].objectHierarchy;

	//Remove the top object as we copy that somewhere else
	objects.splice(0, 1);

	//Add the parent object's old and new uuid to the map
	oldToNewParentMap.set(oldAncestorId, newAncestorId);

	//Create a new ID for each row
	objects.forEach((obj) => oldToNewParentMap.set(obj.uuid, uuidv4()));

	//Add each of the parentIds to the map
	// let ids = objects.map()

	//For each row in the object drag object's mfi we need to create a new row that is a copy of the row with a new uuid and parentUuid
	objects.forEach((obj) => {
		//Stringify then parse the object to get a copy of the object rather than a reference.
		let newDescendant = JSON.parse(JSON.stringify(obj));

		//Set the new ref, if the old ref is '0', we don't need to replace any part of the reference just
		if (oldAncestorRef === "0") newDescendant.reference = `${newAncestorRef}.${newDescendant.reference}`;
		else newDescendant.reference = newDescendant.reference.replace(oldAncestorRef, newAncestorRef);

		//We changed the top nodes reference to be '0' and don't want to display it so remove it from the descendants
		if (newAncestorRef === "0" && newDescendant.reference.startsWith("0."))
			newDescendant.reference = newDescendant.reference.replace("0.", "");

		if (updateStandardObjectUuid) {
			newDescendant.standardObjectUuid = obj.uuid;
			newDescendant.standardObjectVersionUuid = obj.versionUuid;
		}

		/**
		 * Everything in the newDescendant will be the same as the object except for the uuid, parentUuid, and reference
		 * The uuid will be a new one and the parentUuid will be the new parent.
		 * In order to get the new parent uuid I will create a map and as I create a new reference row I will store the old id to the new one,
		 *  that way when I am setting the new parent I can just say give the new parent from the old parent
		 * I will need to replace the reference
		 */
		let newId = oldToNewParentMap.get(newDescendant.uuid);

		//If the old parentUuid is in the map, get the new parentUuid
		if (oldToNewParentMap.has(newDescendant.parentUuid))
			newDescendant.parentUuid = oldToNewParentMap.get(newDescendant.parentUuid);
		//This should never happen, the old parent id should always be in the map
		else
			console.error(
				"The old parentUuid was not in the map, so I was not able to get the new parentUuid",
				newDescendant
			);

		newDescendant.uuid = newId;
		newDescendant.createdByUuid = createdByUuid;

		mfi.push(newDescendant);
	});

	//We need to do this after getting a new uuid for each row to ensure we have a new uuid for each one
	mfi.forEach((row) => {
		//Update the row's setup information
		if (row[linkAttributeName]) {
			let newUuid = oldToNewParentMap.get(row[linkAttributeName]);

			if (newUuid) {
				row[linkAttributeName] = oldToNewParentMap.get(row[linkAttributeName]);
				row.setupValue = getLinkSetupValueRecursive(row[linkAttributeName], mfi);
			} else if (!sameTree) row[linkAttributeName] = "";

			//TODO: What was this trying to accomplish
			// if(row[linkObjectName])
			// {
			//     newUuid = oldToNewParentMap.get(row[linkObjectName]);
			//
			//     if(newUuid)
			//     {
			//         row[linkObjectName] = oldToNewParentMap.get(row[linkObjectName]);
			//         row.setupValue = getLinkSetupValueRecursive(row[linkObjectName], mfi);
			//     }
			//     else if(!sameTree)
			//         row[linkObjectName] = '';
			// }
		} else if (row.setupType === "value" && row.setupValue) row.value = row.setupValue;
		else if (row.setupType === "link" && !row[linkAttributeName]) {
			/**
			 * TODO: If we don't do this, clicking the setup sheet plus won't link setup attributes.
			 *
			 */
			//Find the nearest setup sheet attribute with the same title as this one
			// let nearestSetupAttribute = findNearestSetupAttributeWithTitle(rowDroppedOn, row.title, newAncestorId, {data, setupSheetMap});
			// if(nearestSetupAttribute.uuid)
			// {
			//     row.setupLinkUuid = nearestSetupAttribute.uuid;
			//     row.setupLinkType = 'alias';
			//     row.setupValue = nearestSetupAttribute.value;
			//     row.value = nearestSetupAttribute.value;
			//
			//     rowsWithUpdatedLinks.push(row.uuid);
			// }
		}
		//I don't think this will ever happen anymore?
		else if (
			(row.setupType === SETUP_TYPES.DROP_DOWN.value || row.inputType === INPUT_FIELD_TYPES.DROP_DOWN.value) &&
			row.setupListObjectMfiUuid
		) {
			if (row.value) {
				row.value = oldToNewParentMap.get(row.value);
				updateRowsThatReference(row.uuid, mfi);
			}
		}

		//Update objects that specify a location a another of the objects
		let newLocationUuid = oldToNewParentMap.get(row.setupListObjectLocationUuid);
		if (newLocationUuid) row.setupListObjectLocationUuid = newLocationUuid;
		else row.setupListObjectLocationUuid = "";
	});

	return mfi;
};

/**
 * Creates a copy of the master file index rows, leaves the reference the same reference number
 * @param rows
 * @param oldAncestorUuid
 * @param oldAncestorRef
 * @param newAncestorUuid
 * @param newAncestorRef
 */
export const copyMasterFileIndexRows = (rows, oldAncestorUuid, newAncestorUuid, newAncestorRef) => {
	let copies = [];
	let oldToNewMap = new Map();
	oldToNewMap.set(oldAncestorUuid, newAncestorUuid);

	rows.forEach((row) => {
		let copy = { ...row };

		//Add parent to map
		if (!oldToNewMap.has(copy.parentUuid)) oldToNewMap.set(copy.parentUuid, uuidv4());

		copy.parentUuid = oldToNewMap.get(copy.parentUuid);

		//Add child to map
		oldToNewMap.set(copy.uuid, uuidv4());
		copy.uuid = oldToNewMap.get(copy.uuid);

		//Update the reference
		let newRef = getNextRef(newAncestorRef + "." + (copy.referenceNo - 1));
		copy.reference = newRef.reference;
		copies.push(copy);
	});
	return copies;
};

/**
 * Takes in a list of standard object rows and creates a master file index row that points back to the standard object row
 * @param objects
 * @param oldAncestorId
 * @param newAncestorId
 * @param newAncestorRef
 * @param createdByUuid
 */
export const createMasterFileIndexRowsFromStandardObjectRows = (
	objectRows,
	oldAncestorId,
	newAncestorId,
	newAncestorRef,
	createdByUuid
) => {
	//Do we need to remove the top row like the copy method above does?
	//Create a new uuid for each objectRow
	//Set the old
};

const getLinkSetupValueRecursive = (linkUuid, mfi) => {
	let link = mfi.find((row) => row.uuid === linkUuid);
	if (!link) return "";

	//Do I need to check for a link type of 'alias'?
	if (!link[linkAttributeName]) return link.value;

	return getLinkSetupValueRecursive(link[linkAttributeName], mfi);
};

export const getObjectsComputerVersions = async (objectVersionUuid) => {
	if (!objectVersionUuid) return [];

	let rows = await getCall(getUrl("getObjectsComputerVersions", [objectVersionUuid]));

	return rows || [];
};

export const updateRowsThatReferenceThisRow = (rowId, newValue, data, rowsToUpdate) => {
	let filtered = data.filter((row) => row[linkAttributeName] === rowId);
	filtered.forEach((row) => {
		row.value = newValue;
		row.setupValue = newValue;

		rowsToUpdate.push(row);

		updateRowsThatReferenceThisRow(row.uuid, newValue, data, rowsToUpdate);
	});
};

/**
 * Searches for an entry in data with a duplicate title, and if it finds one it links that row to it
 * @param rows: The new rows, the ones that need to be linked
 * @param data: The rows that will be searched and linked to the rows (above) if a setup attribute with same title is found
 * @param attributeToStartAt: The attribute to start searching for a matching setup attribute to tie the "rows" to, basically the "rows" attachment point
 * @param setupSheetMap: A map of all the setup sheets and their leaves
 */
export const linkSetupAttributes = (rows, data, attributeToStartAt, setupSheetMap) => {
	let rowsToUpdateLinks = rows.filter(
		(row) =>
			(row.setupType === SETUP_TYPES.LINK.value || row.inputSource === INPUT_FIELD_SOURCES.OBJECT_FIELD.value) &&
			!row[linkAttributeName]
	);
	rowsToUpdateLinks.sort((a, b) => a.reference.length - b.reference.length);
	rowsToUpdateLinks.forEach((row) => {
		//Find the nearest setup sheet attribute with the same title as this one
		let nearestSetupAttribute = findNearestSetupAttributeWithTitle(row, row.title, data[0].uuid, {
			data,
			setupSheetMap,
			attributeToStartAt,
		});
		if (nearestSetupAttribute.uuid) {
			row[linkAttributeName] = nearestSetupAttribute.uuid;
			row.setupLinkType = "alias";
			row.setupValue = nearestSetupAttribute.value;
			row.value = nearestSetupAttribute.value;

			updateRowsThatReference(row.uuid, rows);
		}
	});
};

//Find the nearest setup attribute with the matching title
export const findNearestSetupAttributeWithTitle = (
	row,
	title,
	topUuid,
	{ data, dataMap, setupSheetMap, attributeToStartAt }
) => {
	if (!setupSheetMap) return {};

	if (!dataMap) {
		dataMap = new Map();
		data.forEach((row) => dataMap.set(row.uuid, row));
	}

	let owner;
	if (attributeToStartAt) owner = findOwnerByMap(attributeToStartAt, attributeToStartAt, dataMap, { uuid: topUuid });
	else owner = findOwnerByMap(row, row, dataMap, { uuid: topUuid });

	//If the owner is the same as the row passed in, we've probably hit the top and haven't found a matching attribute, just return ''
	if (owner.uuid === row.uuid) return {};

	//Get this object's setup sheets
	let ownerSetupNode = setupSheetMap.get(owner.uuid);

	//If there is not an owner setup node I will assume that it is a new object that was just copied so grab the owner of that owner and check that one instead. In this case it should always be there
	if (!ownerSetupNode) {
		let ownerOfOwner = findOwnerByMap(owner, owner, dataMap, { uuid: topUuid });
		ownerSetupNode = setupSheetMap.get(ownerOfOwner.uuid);
	}

	let fields = ownerSetupNode.leaves.filter((row) => row.title === title);

	// // let maps = [...setupSheetMap.entries()].filter( ([key, value]) => {
	// //     if(value.has(owner.uuid))
	// //         return value;
	// // });
	//
	// maps = maps.map( ([key, val]) => val);
	//
	// //Get the fields in the windows that have a matching title
	// maps.forEach(map => {
	//     if(map.has(owner.uuid))
	//         map.get(owner.uuid).forEach( window => {
	//             fields = [...fields, ...window.fields.filter(field => field.title === title)];
	//         })
	// });

	if (fields.length > 0) return fields[0];

	if (owner.uuid === topUuid) return {};

	return findNearestSetupAttributeWithTitle(owner, title, topUuid, { dataMap, setupSheetMap });
};

/**
 * Recursively updates the values of the rows that update the row passed in
 * @param uuid
 * @param data
 */
export const updateRowsThatReference = (uuid, data) => {
	let row = data.find((row) => row.uuid === uuid);
	let rowsThatReference = data.filter((row) => row[linkAttributeName] === uuid);
	rowsThatReference.forEach((item) => {
		item.value = row.value;
		item.setupValue = row.value;

		updateRowsThatReference(item.uuid, data);
	});
};

/**
 * Takes in a list and the object that owns the list.
 * Returns a map that maps each rows uuid and version to the row
 * @param list
 * @param topObject
 * @returns {{}}
 */
export const convertListToObjectMap = (list, topObject) => {
	if (list.length === 0) {
		return {};
	}

	let objectUuidWithVersion = getObjectIdAndVersionUuid(topObject);
	let map = { [objectUuidWithVersion]: {} };
	list.forEach((row) => (map[objectUuidWithVersion][row.uuid] = row));
	return map;
};

export const convertListToMap = (list) => {
	let map = {};
	list.forEach((row) => (map[getObjectIdAndVersionUuid(row, false)] = row));
	return map;
};

/**
 * Create a real javascript map not an object with properties
 * @param list
 * @returns {{}}
 */
export const convertListToRealJsMap = (list) => {
	let map = new Map();
	list.forEach((row) => map.set(getObjectIdAndVersionUuid(row, false), row));
	return map;
};

/**
 * Gets the object's uuid and version (used in a few places) and returns it as a concatenated string
 * with the "uuid,versionUuid"
 */
export const getObjectIdAndVersionUuid = (obj, requireUuidAndVersion = true) => {
	if (!obj || ((!obj.uuid || !obj.versionUuid) && requireUuidAndVersion)) return undefined;

	if (obj.versionUuid) return obj.uuid + "/" + obj.versionUuid;
	else return obj.uuid;
};

/**
 * Gets the object's uuid and version (used in a few places) and returns it as a concatenated string
 * with the "uuid,versionUuid"
 */
export const getSourceKey = (obj, requireUuidAndVersion = false) => {
	if (!obj || ((!obj.standardObjectUuid || !obj.standardObjectVersionUuid) && requireUuidAndVersion))
		return undefined;

	if (obj.standardObjectVersionUuid) return obj.standardObjectUuid + "/" + obj.standardObjectVersionUuid;
	else return obj.standardObjectUuid;
};

/**
 * Gets the object's uuid and version (used in a few places) and returns it as a concatenated string
 * with the "uuid,versionUuid"
 */
export const getLinkToKey = (obj, requireUuidAndVersion = false) => {
	if (!obj || ((!obj.linkToObjectUuid || !obj.linkToObjectVersionUuid) && requireUuidAndVersion)) return undefined;

	if (obj.linkToObjectVersionUuid) return obj.linkToObjectUuid + "/" + obj.linkToObjectVersionUuid;
	else return obj.linkToObjectUuid;
};

/**
 * Gets the object's uuid and version (used in a few places) and returns it as a concatenated string
 * with the "uuid,versionUuid"
 */
export const splitObjectIdAndVersion = (idAndVersionString) => {
	let split = idAndVersionString.split("/");
	return {
		uuid: split[0],
		versionUuid: split[1],
	};
};

/**
 * Gets the object's uuid and version (used in a few places) and returns it as a concatenated string
 * with the "uuid,versionUuid"
 */
export const getObjectAncestorIdAndVersionUuid = (obj) => {
	if (!obj || !obj.ancestorStandardObjectUuid || !obj.ancestorStandardObjectVersionUuid) return undefined;

	return obj.ancestorStandardObjectUuid + "/" + obj.ancestorStandardObjectVersionUuid;
};

/**
 * Gets the object's uuid and version (used in a few places) and returns it as a concatenated string
 * with the "uuid,versionUuid"
 */
export const getObjectDescendantIdAndVersionUuid = (obj) => {
	if (!obj || !obj.descendantStandardObjectUuid || !obj.descendantStandardObjectVersionUuid) return undefined;

	return obj.descendantStandardObjectUuid + "/" + obj.descendantStandardObjectVersionUuid;
};

export const getHierarchyDescendantUuidToVersionMap = (objectHierarchy) => {
	let map = new Map();
	objectHierarchy.forEach((row) => {
		map.set(row.descendantStandardObjectUuid, row.descendantStandardObjectVersionUuid);
	});
	return map;
};

//This is applicable to reference changes
//Iterate over a reference sorted list and remove any objects and their descendants that cross over object borders
export const filterOutSubObjects = (rows) => {
	if (!rows && !rows.length) return [];

	//Return all of the primus objects (Primus referring to the top of an object)
	let topRows = rows.filter((row) => {
		return row.objectTags?.includes(OBJECT_RECORD_NAME);
	});

	//If there are no top rows, we can just return as there is nothing to filter out
	if (topRows.length == 0) {
		return rows;
	}

	let rowsToReturn = [];
	//Iterate over each primus object and remove any references that start with any primus object reference
	topRows.forEach((topRow) => {
		rowsToReturn = rows.filter((row) => !row.reference.startsWith(topRow.reference + "."));
		rows = rowsToReturn;
	});

	return rowsToReturn;
};

//Get the list of primus ancestors and store them in a map by reference
export const getPrimusObjectMap = (objectRows) => {
	return getReferenceMap(
		objectRows.filter((row) => {
			return row.objectTags?.includes(OBJECT_RECORD_NAME) || row.isObject;
		})
	);
};

//Get the list of primus ancestors and store them in a map by reference
export const getReferenceMap = (objectRows) => {
	let referenceMap = new Map();
	objectRows.forEach((row) => {
		referenceMap.set(row.reference, row);
	});

	return referenceMap;
};

//With each iteration remove the end of the reference until we either hit the top or a primus object
export const findClosestPrimusAncestor = (objectRows, reference) => {
	let topCurrentRowsMap = getPrimusObjectMap(objectRows);

	return findClosestPrimusAncestorByMap(topCurrentRowsMap, reference);
};

//Check the map for the full reference
//With each iteration remove the end of the reference until we either hit the top or a primus object
export const findClosestPrimusAncestorByMap = (ancestorMap, reference) => {
	let splitReference = reference.split(".");
	if (splitReference.length > 1) {
		splitReference = splitReference.slice(0, splitReference.length - 1);
	}
	do {
		if (ancestorMap.has(splitReference.join("."))) {
			return ancestorMap.get(splitReference.join("."));
		}
		splitReference.splice(splitReference.length - 1, 1);
	} while (splitReference.length > 0);
	return ancestorMap.get("0");
};

//Check the map for the full reference
export const findAllPrimusDescendantsByMap = (primusMap, reference) => {
	let descendants = [];
	[...primusMap.entries()].forEach(([key, value]) => {
		if (key.startsWith(reference)) descendants.push(value);
	});

	return descendants;
};

/**
 * Get the Object MFI for the object ancestor, create a copy of each descendant and attach it to the attach id passed in
 * @param objects
 * @param parentObj
 * @param newReference
 */
export const copyObjectMfiAndAttachToId = async (
	objAncestorId,
	objAncestorVersionId,
	attachToObj,
	newReference,
	createdByUuid,
	attachToHierarchy
) => {
	let attachToId = attachToObj.uuid;

	//Get the object's mfi
	let mfi = await getCall(getUrl("getSingleLevelObjectMfi", [objAncestorId, objAncestorVersionId]));
	//Remove the top row because we already have a copy of the top object, we just need to copy all of their descendants
	// mfi.splice(0, 1);

	let newMfi = [];
	//If there was an object mfi, make a copy of it
	if (mfi.length > 0) newMfi = copyObjectMfi(mfi, objAncestorId, attachToId, "0", createdByUuid);

	newMfi.forEach((row) => (row.reference = `${newReference}.${row.reference}`));

	//TODO Here the descendant uuids... Why aren't the hologram records working?
	// Maybe we aren't passing in an attachToHierarchy... I'm not sure it's just so broken
	if (attachToHierarchy) {
		newMfi.objectHierarchy
			?.filter((row) => row.ancestorStandardObjectUuid === ZERO_ROW_UUID)
			.map((row) => {
				row.uuid = uuidv4();
				row.ancestorStandardObjectUuid = attachToHierarchy.uuid;
				row.ancestorStandardObjectVersionUuid = attachToHierarchy.versionUuid;
				//TODO-HOLOGRAM_DELETION: Should something else happen with the row.descendantStandardObjectUuid and version?
				// row.descendantHologramUuid = row.descendantStandardObjectUuid;
				// row.descendantHologramVersionUuid = row.descendantStandardObjectVersionUuid;
				row.descendantStandardObjectUuid = attachToObj.uuid;
				row.descendantStandardObjectVersionUuid = attachToObj.versionUuid;
			});
	}

	//return the copy of the ancestor and it's mfi
	return newMfi;
};

export const getSetupForms = async () => {
	let setupFormsList = await getCall(getUrl("getObjectSetupForms"));

	return setupFormsList;
};

/**
 * Creates an object hierarhcy record between the ancestor and descendant passed in
 * @param ancestorHierarchyRecord
 * @param descendant
 */
export const createObjectHierarchyRecord = (ancestorHierarchyRecord, descendant) => {
	let newHierarchyRecord = {
		uuid: uuidv4(),
		ancestorStandardObjectUuid: ancestorHierarchyRecord?.descendantStandardObjectUuid || ZERO_ROW_UUID,
		ancestorStandardObjectVersionUuid:
			ancestorHierarchyRecord?.descendantStandardObjectVersionUuid || ZERO_ROW_UUID,
		descendantStandardObjectUuid: descendant.uuid,
		descendantStandardObjectVersionUuid: descendant.versionUuid,
	};

	if (!ancestorHierarchyRecord?.ancestorStandardObjectUuid)
		newHierarchyRecord.pathEnum = getObjectIdAndVersionUuid(descendant);
	else if (ancestorHierarchyRecord?.pathEnum)
		newHierarchyRecord.pathEnum = ancestorHierarchyRecord.pathEnum + "." + getObjectIdAndVersionUuid(descendant);

	return newHierarchyRecord;
};

/**
 * Creates an object hierarhcy record between the ancestor and descendant passed in
 * @param ancestorHierarchyRecord
 * @param descendant
 */
export const createAssociatedObjectHierarchyRecord = (
	ancestorHierarchyRecord,
	descendantHierarchyRecord,
	descendant
) => {
	return {
		uuid: uuidv4(),
		ancestorStandardObjectUuid: ancestorHierarchyRecord.descendantStandardObjectUuid,
		ancestorStandardObjectVersionUuid: ancestorHierarchyRecord.descendantStandardObjectVersionUuid,
		descendantStandardObjectUuid: descendant.uuid,
		descendantStandardObjectVersionUuid: descendant.versionUuid,
		hierarchyTypeUuid: ASSOCIATED_OBJECT_GENERAL_TYPE_UUID,
		pathEnum:
			ancestorHierarchyRecord.pathEnum +
			">" +
			(descendantHierarchyRecord.pathEnum || getObjectIdAndVersionUuid(descendant)),
	};
};

export const saveObjects = async (mfis = []) => {
	let mfiUpdate = { objectRowsToUpdate: {} };
	mfis.forEach((mfi) => {
		mfiUpdate.objectRowsToUpdate[getObjectIdAndVersionUuid(mfi[0])] = mfi;
	});

	let url = getUrl("saveObjectRows");
	return await postCall(url, mfiUpdate);
};

export const setValuesBasedOnDefaults = (list, defaults) => {
	let destinationRows = [];
	list.forEach((row) => {
		let setupRow = row.setupRow;
		if (setupRow) {
			if (setupRow.defaultType === "hard-coded" && setupRow.defaultValue.length > 0)
				row.value = setupRow.defaultValue;
			else if (setupRow.defaultType === "table") {
				row.value =
					defaults
						.find((item) => item.uuid === setupRow.defaultClassUuid)
						?.fields.find((item) => item.uuid === setupRow.defaultFieldUuid)?.defaultValue || row.value;
			} else if (setupRow.defaultType === "destination") destinationRows.push(row);
		}
	});
	return destinationRows;
};

export const buildSetupSheetWithOwnerMfi = (data, topNode, setupSheetType) => {
	if (data.length < 1) return [];

	let setupSheetMfi = [];
	let ownerIds = [];
	let ownerList = [];
	let dataMap = new Map();
	data.forEach((row) => dataMap.set(row.uuid, row));

	//Get the setup sheet objects
	let setupSheets = data.filter((row) => row.objectTypeUuid === setupSheetType);
	if (setupSheets.length < 1) return;

	//Add the topNode to the list
	ownerIds.push(topNode.uuid);
	ownerList.push(topNode);
	setupSheetMfi.push(topNode);

	//For each each setup sheet, get it's owner and it's descendants, build the tree
	setupSheets.forEach((sheet) => {
		//Get the owner
		let owner = findOwnerByMap(sheet, sheet, dataMap, topNode);
		if (!ownerIds.includes(owner.uuid)) {
			let ownerCopy = { ...owner };
			ownerIds.push(owner.uuid);
			ownerList.push(ownerCopy);
			setupSheetMfi.push(ownerCopy);
		}

		let copy = { ...sheet };
		copy.parentUuid = owner.uuid;
		setupSheetMfi.push(copy);

		//Get descendants
		let descendants = data.filter((row) => row.reference.startsWith(sheet.reference) && row.uuid !== sheet.uuid);
		if (descendants.length > 0) setupSheetMfi = [...setupSheetMfi, ...descendants];
	});
	//Get list of the uuids of the setup sheets so we can check if each owner has a parent in the list
	let setupSheetMfiUuids = setupSheetMfi.map((row) => row.uuid);

	//Now we need to match up the owners if the setup sheets are scattered throughout the tree
	ownerList.forEach((owner) => {
		if (!setupSheetMfiUuids.includes(owner.parentUuid) && owner.uuid !== topNode.uuid) {
			//Get the nearest owner
			let nearestOwner = ownerList[0];
			ownerList.forEach((nearest) => {
				if (
					nearest.reference.length - nearestOwner.reference.length <
					nearest.reference.length - nearestOwner.reference.length
				)
					nearestOwner = nearest;
			});
			owner.parentUuid = nearestOwner.uuid;
			setupSheetMfiUuids.push(nearestOwner.uuid);
		}
	});
	return setupSheetMfi;
};

/**
 * Maps setup sheets
 *      Data looks like:
 *          {
 *              'Setup Sheet Title': [
 *                  {
 *                      uuid: 'object_uuid',
 *                      title: 'title',
 *                      windows: [
 *                          uuid: 'uuid of the leaves parent',
 *                          windowTitle: 'Breadcrumbs to the leaves parent',
 *                          fields: []
 *                      ],
 *                  },
 *              ],
 *          }
 *      'objects'... looks like
 *          'Object Title': [
 *              windows: [
 *
 *              ]
 *          ]
 * @param data
 * @param topNode
 * @param setupSheetType
 * @returns {*}
 */
export const buildSetupSheetToLeafMap = (data, topNode, setupSheetType) => {
	if (!data || data.length < 1) return new Map();

	let setupSheetMap = new Map();
	let dataMap = new Map();
	data.forEach((row) => dataMap.set(row.uuid, row));

	//Get the setup sheet objects
	let setupSheets = data.filter((row) => row.objectTypeUuid === setupSheetType);
	if (setupSheets.length < 1) return new Map();

	setupSheets.sort((a, b) => a.reference.length - b.reference.length);
	let setupSheetToOwnerMap = new Map();

	let listOfListSetupAttributes = [];
	let setupSheetTypeToOwnerMap = new Map();

	//For each each setup sheet, get it's owner and it's descendants, build the tree
	setupSheets.forEach((sheet) => {
		//Check if this setup sheet title is already in the setup sheet map
		if (!setupSheetMap.has(sheet.title)) setupSheetMap.set(sheet.title, new Map());

		if (!setupSheetTypeToOwnerMap.has(sheet.title)) setupSheetTypeToOwnerMap.set(sheet.title, []);

		let owner = findOwnerByMap(sheet, sheet, dataMap, topNode);
		setupSheetMap.get(sheet.title).set(owner.uuid, []);

		setupSheetToOwnerMap.set(sheet.uuid, owner.uuid);

		//Build the window data for this setup sheet, should there be one window per sheet? Or one window for each leaf and its siblings?
		//Get descendants
		let descendants = data.filter((row) => row.reference.startsWith(sheet.reference) && row.uuid !== sheet.uuid);

		//Get the list of leaves
		let leaves = getLeaves(descendants, descendants);

		//Build a map mapping a list of leaf siblings and their parent reference or parentUuid?
		let leafToParentMap = new Map();
		leaves.forEach((leaf) => {
			leaf.setupAttribute = true;
			if (!leafToParentMap.has(leaf.parentUuid)) leafToParentMap.set(leaf.parentUuid, []);

			//If the leaf references a list setup attribute add it to a list we will use later to re-arrange the setup info
			if (
				(leaf.setupType === SETUP_TYPES.LIST.value || leaf.inputType === INPUT_FIELD_TYPES.LIST.value) &&
				leaf.setupListObjectLocationUuid
			) {
				leaf.setupSheetTitle = sheet.title;
				listOfListSetupAttributes.push(leaf);
			}

			leafToParentMap.get(leaf.parentUuid).push(leaf);
		});

		//Build a window for each set of siblings
		leafToParentMap.forEach((value, key) => {
			setupSheetMap.get(sheet.title).get(owner.uuid).push({
				uuid: key,
				fields: value,
			});
		});
	});

	//Build an ancestor tree
	[...setupSheetMap.keys()].forEach((key) => {
		//Get the items for this setup sheet
		let sheets = setupSheetMap.get(key);

		let ownerIds = [...sheets.keys()];
		let owners = data.filter((row) => ownerIds.includes(row.uuid));
		//For each entry in sheets get the owner and find that owners nearest ancestor that is also has an entry in sheets
		owners.forEach((owner) => {
			//Get the nearest owner from the list of owners using a sort to sort the list of owners
			let ancestors = owners.filter((row) => owner.ancestors && owner.ancestors.includes(row.uuid));

			//Sort filteredOwners
			ancestors.sort((a, b) => {
				return owner.reference.length - a.reference.length - (owner.reference.length - b.reference.length);
			});

			//TODO: Does my sort needs reversed? In my testing I only found ancestors to have 0 or 1 ancestor
			//Nearest ancestor should be the top one because of the sort
			if (ancestors.length > 0) owner.ancestorUuid = ancestors[0].uuid;
			else owner.ancestorUuid = topNode.uuid;

			owner.setupAttributes = sheets.get(owner.uuid);

			setupSheetTypeToOwnerMap.get(key).push(owner);
		});
	});

	[...setupSheetTypeToOwnerMap.entries()].forEach(([setupSheetTitle, attributes]) => {
		//Build the ancestor tree with the attributes
		let tree = listToTreeAncestor(attributes, topNode.uuid);
		setupSheetTypeToOwnerMap.set(setupSheetTitle, tree);
	});

	//For each list setup attribute we want to re-arrange the setup sheets
	listOfListSetupAttributes.forEach((attr) => {
		attr.accordions = new Map();
		//Get the setup sheet the attribute is on

		//Get the children of the location the list of objects go
		let children = data.filter((row) => row.parentUuid === attr.setupListObjectLocationUuid);

		//For each child add the attributes from the same setup sheet as accordions of this attribute
		children.forEach((child) => {
			//Get the matching setup sheet for this child
			let setupWindow = setupSheetMap.get(attr.setupSheetTitle).get(child.uuid);

			//If there is a setup window (meaning the object has setup attributes) add it to this child's accordion attribute and remove it from the map
			if (setupWindow) {
				setupWindow.forEach((window) => {
					//TODO: Get the owner of this window
					let windowObj = data.find((row) => row.uuid === window.uuid);
					let owner = findOwnerByMap(windowObj, windowObj, dataMap, topNode);

					if (!owner.isAccordion) owner.isAccordion = [];

					owner.isAccordion.push(attr.setupSheetTitle);

					if (!attr.accordions.get(owner.uuid)) attr.accordions.set(owner.uuid, []);

					attr.accordions.get(owner.uuid).push({ uuid: window.uuid, fields: window.fields });
				});

				//Remove from the map
				setupSheetMap.get(attr.setupSheetTitle).delete(child.uuid);
			}
		});

		//Delete the setup attributes on this setup sheet from the setup sheet map
	});
	return setupSheetMap;
};

/**
 * Builds a map of the setup sheet trees
 * @param data
 * @param topNode
 * @param setupSheetType
 * @returns {Map<any, any>}
 */
export const getSetupSheets = (data, topNode, setupSheetType, { setupSheetTitle, parentSetupReference }) => {
	if (!data || data.length < 1) return [];

	let dataMap = new Map();
	data.forEach((row) => dataMap.set(row.uuid, row));

	//Build the tree for the data
	let tree = listToTree(data);

	//Get the setup sheet objects
	let setupSheets;
	if (setupSheetTitle)
		setupSheets = data.filter((row) => row.objectTypeUuid === setupSheetType && row.title === setupSheetTitle);
	else setupSheets = data.filter((row) => row.objectTypeUuid === setupSheetType);

	//If there are not rows with the setup sheet type, check if we are in a harvested obect
	if (setupSheets.length < 1 && topNode.generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE) {
		//If we are in a harvested object get the setup sheets from the setupSheets attribute
		let sheets = [];
		data.filter((row) => row.uuid !== topNode.uuid && row.setupSheets).forEach(
			(row) => (sheets = [...sheets, ...row.setupSheets])
		);

		//Sort alphabetically
		sheets = [...new Set(sheets)].sort((a, b) => a.localeCompare(b));
		return sheets;
	}

	setupSheets.sort((a, b) => a.reference.length - b.reference.length);
	let sheets = [...new Set(setupSheets.map((row) => row.title))];
	return sheets;
};

/**
 * Builds a map of the setup sheet trees
 * @param data
 * @param topNode
 * @param setupSheetType
 * @returns {Map<any, any>}
 */
export const buildSetupSheetTree = (data, topNode, setupSheetType, { setupSheetTitle, parentSetupReference }) => {
	if (!data || data.length < 1) return { setupTrees: new Map() };

	let setupSheetMap = new Map();
	let dataMap = new Map();
	data.forEach((row) => dataMap.set(row.uuid, row));

	//Get the setup sheet objects
	let setupSheets;
	if (setupSheetTitle)
		setupSheets = data.filter((row) => row.objectTypeUuid === setupSheetType && row.title === setupSheetTitle);
	else setupSheets = data.filter((row) => row.objectTypeUuid === setupSheetType);

	if (setupSheets.length < 1) return { setupTrees: new Map() };

	setupSheets.sort((a, b) => a.reference.length - b.reference.length);
	let setupSheetToOwnerMap = new Map();

	let mapOfListSetupAttributes = new Map();
	let setupSheetTypeToOwnerMap = new Map();
	let usedSheets = [];

	//For each each setup sheet, get it's owner and it's descendants, build the tree
	setupSheets.forEach((sheet) => {
		//If we've already used a sheet, don't use it again
		if (usedSheets.includes(sheet.uuid)) return;
		else usedSheets.push(sheet.uuid);

		//Check if this setup sheet title is already in the setup sheet map
		if (!setupSheetMap.has(sheet.title)) setupSheetMap.set(sheet.title, new Map());

		if (!setupSheetTypeToOwnerMap.has(sheet.title)) setupSheetTypeToOwnerMap.set(sheet.title, []);

		let owner = findOwnerByMap(sheet, sheet, dataMap, topNode);

		setupSheetMap.get(sheet.title).set(owner.uuid, []);

		setupSheetToOwnerMap.set(sheet.uuid, owner.uuid);

		//Build the window data for this setup sheet, should there be one window per sheet? Or one window for each leaf and its siblings?
		//Get descendants
		let descendants = data.filter((row) => row.reference.startsWith(sheet.reference) && row.uuid !== sheet.uuid);

		//Get the list of leaves
		let leaves = getLeaves(descendants, descendants);

		//Build a map mapping a list of leaf siblings and their parent reference or parentUuid?
		let leafToParentMap = new Map();
		leaves.forEach((leaf) => {
			leaf.setupAttribute = true;
			if (!leafToParentMap.has(leaf.parentUuid)) leafToParentMap.set(leaf.parentUuid, []);

			//If the leaf references a list setup attribute add it to a list we will use later to re-arrange the setup info
			if (
				(leaf.setupType === SETUP_TYPES.LIST.value || leaf.inputType === INPUT_FIELD_TYPES.LIST.value) &&
				leaf.setupListObjectLocationUuid
			) {
				//Get the container object in question
				let containerObject = data.find((row) => row.uuid === leaf.setupListObjectLocationUuid);

				//Get the children of the container object
				let children = data.filter((row) => row.parentUuid === containerObject.uuid);

				if (children.length > 0) leaf.hasChildren = true;

				//Iterate over the children of the container object
				children.forEach((child, index) => {
					child.listSetupAttribute = true;
					child.hasChildren = true;

					//Get the descendants
					let descendants = data.filter((row) => row.reference.startsWith(child.reference));

					let { setupTrees: subTrees, usedSheets: used } = buildSetupSheetTree(
						descendants,
						child,
						setupSheetType,
						{
							setupSheetTitle: sheet.title,
						}
					);
					if (used && used.length > 0) usedSheets = [...usedSheets, ...used];

					if (!subTrees || subTrees.size < 1) return;

					//TODO: I don't think this is used anymore?
					// ownersAlreadyUsed = [...ownersAlreadyUsed, child.uuid, ...descendants.map(row => row.uuid)];
					//Build the tree for ach child set its ancestor to this node

					setupSheetMap.get(sheet.title).delete(child.uuid);
					//Set the ancestor uuid of the roots of this subTree to be the uuid of the leaf
					subTrees.get(sheet.title).forEach((root) => (root[`ancestor${sheet.title}Uuid`] = leaf.uuid));
					let subList = convertTreeToList(subTrees.get(sheet.title));
					//Using the index update the references so it matches as a subtree TODO: I actually don't think this matters

					child[`ancestor${sheet.title}Uuid`] = leaf.uuid;
					if (!mapOfListSetupAttributes.has(sheet.title))
						mapOfListSetupAttributes.set(sheet.title, new Map());

					if (!mapOfListSetupAttributes.get(sheet.title).has(child.uuid))
						mapOfListSetupAttributes.get(sheet.title).set(child.uuid, []);

					mapOfListSetupAttributes
						.get(sheet.title)
						.set(child.uuid, [...mapOfListSetupAttributes.get(sheet.title).get(child.uuid), ...subList]);
				});

				leaf.setupSheetTitle = sheet.title;
			}

			leafToParentMap.get(leaf.parentUuid).push(leaf);
		});

		//Build a window for each set of siblings
		leafToParentMap.forEach((value, key) => {
			setupSheetMap.get(sheet.title).get(owner.uuid).push({
				uuid: key,
				fields: value,
			});
		});
	});

	//Build an ancestor tree
	[...setupSheetMap.keys()].forEach((key) => {
		//Get the items for this setup sheet
		let sheets = setupSheetMap.get(key);

		let ownerIds = [...sheets.keys()];
		let owners = data.filter((row) => ownerIds.includes(row.uuid));
		//For each entry in sheets get the owner and find that owners nearest ancestor that is also has an entry in sheets
		owners.forEach((owner) => {
			//Get the nearest owner from the list of owners using a sort to sort the list of owners
			let ancestors = owners.filter((row) => owner.ancestors && owner.ancestors.includes(row.uuid));

			//Sort filteredOwners
			ancestors.sort((a, b) => {
				return owner.reference.length - a.reference.length - (owner.reference.length - b.reference.length);
			});

			//Nearest ancestor should be the top one because of the sort
			if (ancestors.length > 0) owner[`ancestor${key}Uuid`] = ancestors[0].uuid;
			else owner[`ancestor${key}Uuid`] = topNode.uuid;

			setupSheetTypeToOwnerMap.get(key).push(owner);

			let value = sheets.get(owner.uuid);
			if (value && value.length > 0) {
				value[0].fields.forEach((field) => {
					field[`ancestor${key}Uuid`] = owner.uuid;
					setupSheetTypeToOwnerMap.get(key).push(field);
				});
			}
		});
	});

	[...setupSheetTypeToOwnerMap.entries()].forEach(([setupSheetTitle, attributes]) => {
		//Build the ancestor tree with the attributes
		if (mapOfListSetupAttributes.has(setupSheetTitle))
			[...mapOfListSetupAttributes.get(setupSheetTitle).values()].forEach(
				(list) => (attributes = [...attributes, ...list])
			);

		//If the top node is not in attributes, add it
		let top = attributes.find((row) => row.uuid === topNode.uuid);
		if (!top) attributes.unshift(topNode);

		//Fix the references of the attributes
		//I'm assuming the first record in attributes will be the top node so set that setupReference to '0'
		if (parentSetupReference) {
			attributes[0]["setup" + setupSheetTitle + "Reference"] = parentSetupReference.reference;
			attributes[0]["setup" + setupSheetTitle + "ReferenceNo"] = parentSetupReference.referenceNo;
		} else if (attributes.length > 0) attributes[0]["setup" + setupSheetTitle + "Reference"] = "0";
		else return;

		fixReferences(
			attributes.filter((row) => row.uuid !== topNode.uuid),
			topNode.uuid,
			parentSetupReference?.reference || "0",
			{ parentField: "ancestor" + setupSheetTitle + "Uuid", refField: "setup" + setupSheetTitle + "Reference" }
		);

		let tree = listToTreeAncestor(
			attributes.sort((a, b) => sortByReference(a, b, "setup" + setupSheetTitle + "Reference")),
			topNode.uuid,
			`ancestor${setupSheetTitle}Uuid`
		);

		//Update the tree with the children we previously got... How do I update the references?
		setupSheetTypeToOwnerMap.set(setupSheetTitle, tree);
	});

	return { setupTrees: setupSheetTypeToOwnerMap, usedSheets };
};

/**
 * This method takes in a sub map and adds all of its data to the setupSheetMap
 * @param setupSheetMap
 * @param subMap
 */
export const updateSetupSheetMap = (setupSheetMap, subMap, data) => {
	//Create a map that we can use to easily find the objects
	let map = buildUuidToObjectMap(data);
	let topNode = data[0];

	/**
	 * Adds the window to the current setupSheetMap, checks if the window is supposed to be in an accordion
	 * entry: The Setup Sheet Entry ex: 'Developer Setup Sheet' => all the windows
	 * key: the key in the window to add
	 * value: an array of setup sheets with fields on?
	 */
	const addWindow = (entry, key, value, setupSheetTitle) => {
		/**
		 * Check if the window is supposed to be in an accordion
		 * What does it mean for it be an accordion rather than another window?
		 * If there is a setup sheet attribute where its setupListObjectLocaionUuid
		 * is pointing to its parent
		 */
		let windowObj = map.get(key);

		//Check if the an objects setupListObjectLocationUuid is pointing to this parent
		let setupAttributeTiedToParent = data.find((row) => row.setupListObjectLocationUuid === windowObj.parentUuid);

		//Check if the setupAttributeOwner is in entry
		if (setupAttributeTiedToParent) {
			//find the owner of this setup attribute
			let setupAttributeOwner = findOwnerByMap(
				setupAttributeTiedToParent,
				setupAttributeTiedToParent,
				map,
				data[0]
			);

			if (entry.has(setupAttributeOwner.uuid)) {
				let parentWindow = entry.get(setupAttributeOwner.uuid);
				let fields = [];
				parentWindow.forEach((wind) => (fields = [...fields, ...wind.fields]));
				let field = fields.find((field) => field.setupListObjectLocationUuid === windowObj.parentUuid);

				if (!setupAttributeOwner.isAccordion) setupAttributeOwner.isAccordion = [];
				setupAttributeOwner.isAccordion.push({ setupSheetTitle, windowUuid: setupAttributeOwner.uuid });

				if (!field) return;

				if (!field.accordions) field.accordions = new Map();

				//Will this even work? do we need to destructure the 2 values
				if (field.accordions.has(key)) field.accordions.get(key).push(value);
				else field.accordions.set(key, value);
			} else {
				let mapKeys = [...entry.keys()];
				let ancestor = setupAttributeOwner.ancestors?.find((ancestor) => mapKeys.includes(ancestor));
				let arr;
				if (ancestor) arr = entry.get(ancestor);
				//The only case the map wouldnt have an entry for any of the setupAttribute owners ancestors is if the map has top row
				else arr = entry.get(topNode.uuid);

				let fields = [];
				arr.forEach((wind) => (fields = [...fields, ...wind.fields]));

				//The fields are the setup fields so they won't actually be an ancestor, if they have accordions the object they point to will be the ancestor
				let ancestorField = fields.find((field) =>
					setupAttributeOwner.ancestors.includes(field.setupListObjectLocationUuid)
				);
				if (!ancestorField.accordions) ancestorField.accordions = new Map();
				addWindow(ancestorField.accordions, key, value, setupSheetTitle);
			}
		} else {
			//If the key is not already in the entry just add its key and value
			if (!entry.has(key)) entry.set(key, value);
			//Otherwise we have to do a little more complicated merging
			else {
				//Get the window entry
				let windowEntry = entry.get(key);

				//Get the ids in the window entry
				let currentWindowEntryIds = windowEntry.map((ent) => ent.uuid);
				//For each sheet in value, check if it's already in the window, if not add it
				value.forEach((newSheet) => {
					//Check if this newSheet is already in the current window entries
					if (!currentWindowEntryIds.includes(newSheet.uuid)) windowEntry.push(newSheet);
					//Otherwise if it is in there we need to recursively add each field and their accordions
					else {
						let currentWindowSheet = windowEntry.find(
							(currentSheet) => currentSheet.uuid === newSheet.uuid
						);
						//Add all the fields in the new sheet to the currentSheet
						addFields(currentWindowSheet, newSheet, setupSheetTitle);
					}
				});
			}
		}
	};

	const addFields = (currentWindowSheet, newSheet, setupSheetTitle) => {
		//Get ids of all the fields in the currentWindowSheet
		let currentFields = currentWindowSheet.fields.map((field) => field.uuid);

		//For each field in the newSheet check if they are already on the current sheet, if not add id
		//TODO: As fields within accordions are added they need to be added to the corresponding accordiong in the current setup sheet map
		newSheet.fields.forEach((field) => {
			if (!currentFields.includes(field.uuid)) currentWindowSheet.push(field);
			//Otherwise if it is there verify each accordion is there
			else {
				field.accordions.forEach((accordion) => {
					[...accordion.entry()].forEach(([key, value]) => addWindow(accordion, key, value, setupSheetTitle));
				});
			}
		});

		newSheet.fields.sort(sortByReference);
	};

	[...subMap.entries()].forEach(([setupSheetTitle, windows]) => {
		//Check if the setupSheetMap has this key already, if not add it
		if (!setupSheetMap.has(setupSheetTitle)) setupSheetMap.set(setupSheetTitle, windows);
		else {
			let entry = setupSheetMap.get(setupSheetTitle);

			//Iterate over the windows and add them to the setupSheetMap
			[...windows.entries()].forEach(([key, value]) => {
				if (value.length < 1) return;
				addWindow(entry, key, value, setupSheetTitle);
			});
		}
	});
	return setupSheetMap;
};

const buildUuidToObjectMap = (data) => {
	let map = new Map();
	data.forEach((row) => map.set(row.uuid, row));

	return map;
};

/**
 * Removes a subSetupSheetMap from the currentSetupSheetMap
 *
 * @param currentSetupSheetMap
 * @param subSetupSheetMap
 */
export const removeWindowFromSetupSheet = (currentSetupSheetMap, subSetupSheetMap, ownerBeingRemoved) => {
	/**
	 * Recursively searches a window's fields for the key
	 * @param currentEntry: the current window entry to get the accordions for
	 * @param key:
	 */
	const recursivelySearchForAccordion = (currentEntry, key) => {
		let fields = [];
		[...currentEntry.entries()].forEach(([key, value]) => {
			value.forEach(
				(wind) =>
					(fields = [
						...fields,
						...wind.fields.filter((field) => ownerBeingRemoved.ancestors.includes(field.uuid)),
					])
			);
		});
		// let deletedKey = false;
		fields.forEach((field) => {
			if (!field.accordions) return;
			else if (field.accordions.has(key)) {
				field.accordions.delete(key);
				return;
			} else {
				recursivelySearchForAccordion(field.accordions, key);
			}
		});
	};

	//Iterate over the currentSetupSheetMap
	[...subSetupSheetMap.entries()].forEach(([setupSheetTitle, subEntry]) => {
		if (currentSetupSheetMap.has(setupSheetTitle)) {
			let currentEntry = currentSetupSheetMap.get(setupSheetTitle);

			//iterate over the subEntry windows and remove them from the currentEntry
			[...subEntry.entries()].forEach(([key, value]) => {
				//Remove each window if its there, if its not, it must be an accordion so figure out where it resides
				if (currentEntry.has(key)) currentEntry.delete(key);
				//If the current setup sheet doesn't have this window already, it is probably an accordion, we need to figure out what field this accordion is apart of
				else {
					recursivelySearchForAccordion(currentEntry, key);
				}
			});
		}
	});

	return currentSetupSheetMap;
};

/**
 * Takes in the map, the object the change applies to, and the change itself
 * If the object doesnt exist in the map add it
 */
export const addRowToChangedObjectMap = (map, object, row, deleteRow = false) => {
	let uuidAndVersion = getObjectIdAndVersionUuid(object);

	//Add this closest primus ancestor to our changed map along with the current row in question
	if (!map[uuidAndVersion]) map[uuidAndVersion] = { mfi: {}, deleted: {} };

	if (deleteRow) {
		map[uuidAndVersion].deleted[row.uuid] = row;

		//Remove it from the changes if it was there
		if (map[uuidAndVersion].mfi[row.uuid]) delete map[uuidAndVersion].mfi[row.uuid];
	} else map[uuidAndVersion].mfi[row.uuid] = row;
};

export const isSubObjectAddedToMap = (map, object) => {
	let uuidAndVersion = getObjectIdAndVersionUuid(object);
	if (!map[uuidAndVersion]) return false;
	else return true;
};

export const addObjectHierarchyToChangedMap = (map, object, row) => {
	let uuidAndVersion = getObjectIdAndVersionUuid(object);

	if (!map[uuidAndVersion])
		map[uuidAndVersion] = {
			objectHierarchy: {},
		};

	map[uuidAndVersion].objectHierarchy[row.uuid] = row;
};

export const getAssociatedObjectType = (sharedState) => {
	return sharedState.dbConstants.associatedObjectType?.referenceUuid;
};

export const checkForSubObject = (row, objectHierarchy) => {
	if (objectHierarchy)
		return objectHierarchy.find(
			(item) =>
				item.descendantStandardObjectUuid === row.uuid &&
				item.hierarchyTypeUuid !== ASSOCIATED_OBJECT_GENERAL_TYPE_UUID
		);
	// return objectHierarchy.find(item => item.descendantStandardObjectUuid === row.uuid && item.descendantStandardObjectVersionUuid === row.versionUuid);
};

//Removes any unnecessary fields
export const trimObject = (obj) => {
	return {
		uuid: obj.uuid,
		versionUuid: obj.versionUuid,
		title: obj.title,
		reference: obj.reference,
		referenceNo: obj.referenceNo,
		parentUuid: obj.parentUuid,
		readonly: obj.readonly,
	};

	let trimmedObj = { ...obj };
	//Objects
	delete trimmedObj.ancestors;
	delete trimmedObj.attachableTypes;
	delete trimmedObj.dropDownObjectTypes;
	delete trimmedObj.indeterminateSetupSheets;
	delete trimmedObj.objectType;
	delete trimmedObj.setupForms;
	delete trimmedObj.setupSheets;
	delete trimmedObj.stockNumber;
	delete trimmedObj.validations;
	// delete trimmedObj.objectHierarchy;
	if (typeof trimmedObj.objectHierarchy !== "string")
		trimmedObj.objectHierarchy = JSON.stringify(trimmedObj.objectHierarchy);

	//Unnecessary fields
	delete trimmedObj.depth;
	delete trimmedObj.generalTypeUuid;
	delete trimmedObj.included;
	delete trimmedObj.inputSource;
	delete trimmedObj.inputType;
	delete trimmedObj.languageFrameworkUuid;
	delete trimmedObj.logRecord;
	delete trimmedObj.mfiReference;
	delete trimmedObj.mfiTags;
	delete trimmedObj.objectClassUuid;
	delete trimmedObj.objectTypeUuid;
	delete trimmedObj.objectTypeVersionUuid;
	delete trimmedObj.referenceObjectTitle;
	delete trimmedObj.setupLinkType;
	delete trimmedObj[linkAttributeName];
	delete trimmedObj.setupListObjectLocationUuid;
	delete trimmedObj.setupOptions;
	delete trimmedObj.setupRow;
	delete trimmedObj.setupType;
	delete trimmedObj.setupValue;
	delete trimmedObj.sourceObjectTemplateUuid;
	delete trimmedObj.sourceObjectVersionUuid;
	delete trimmedObj.versionControl;
	delete trimmedObj.volumeUuid;

	return trimmedObj;
};

/**
 * Create a method that will load the sub-object for me.
 *  It should load the mfi and then handle the logic of do I replace the current open object or merge the two MFIs
 *
 * What do I need in order to do this?
 *  1. I need the row I'm loading
 *  2. I need either the matching hierarchyRecord or the entire objectHierarchy
 *  3. I need the two types: objUsrRel and objDefRel
 *  4.
 *
 * Logically what should happen?
 *  1. Get the mfi
 *  2. Check if we need to replace the current object or merge with it
 *
 * @param row
 * @param hierarchyRecord
 * @param context
 * @param dispatch
 * @param objUsrRel
 * @param objDefRel
 */
export const loadSubObject = async (
	row,
	hierarchyRecord,
	sharedState,
	dispatch,
	mergeMfis,
	{ addAttachmentPoint, getAttachmentPoints, updateAttachmentPoint, deleteAttachmentPoint },
	sendChanges = false,
	setQueryParams
	// changedHierarchy
) => {
	let {
		contextMfi: mfi,
		contextObjectHierarchy: objectHierarchy,
		contextAncestorObjects: ancestorObjects,
	} = sharedState;
	if (!hierarchyRecord) {
		if (row.objectHierarchy?.length > 0) hierarchyRecord = row.objectHierarchy[0];
		else
			hierarchyRecord = objectHierarchy.find(
				(hierarchyRecord) =>
					hierarchyRecord.descendantStandardObjectUuid === row.uuid &&
					hierarchyRecord.descendantStandardObjectVersionUuid === row.versionUuid
			);

		//If we still don't have a hierarchy record check for an associated record
		if (!hierarchyRecord)
			hierarchyRecord = objectHierarchy.find(
				(hierarchyRecord) => hierarchyRecord.descendantStandardObjectUuid === row[linkAttributeName]
			);
	}

	let attachmentPoint = { ...row, objectHierarchy: [hierarchyRecord] };
	//Grab the sub-objects mfi
	let newMfi;
	if (row.new)
		newMfi = await copySingleLevelObjectMfi(
			row.standardObjectUuid,
			row.standardObjectVersionUuid,
			dispatch,
			null,
			attachmentPoint
		);
	else newMfi = await getSingleLevelObjectMfi(row.uuid, row.versionUuid, dispatch, null, attachmentPoint);

	//TODO potential improvement
	// //Get they updated hierarchy by combining the object hierarchy from the database plus any new records in the fullObjectHierarchy
	// let newHierarchy = {};
	// newMfi[0].objectHierarchy?.forEach(row => newHierarchy[row.uuid] = row);
	//
	// //Get the descendants from fullObjectHierarchy (This will give us new hierarchy records that haven't been saved yet
	// changedHierarchy
	//     ?.filter(hier => hier.ancestorStandardObjectUuid === hierarchyRecord.descendantStandardObjectUuid && hier.ancestorStandardObjectVersionUuid === hierarchyRecord.descendantStandardObjectVersionUuid)
	//     ?.forEach(row => newHierarchy[row.uuid] = row);

	let topUuidAndVersion;

	if (sharedState.contextAncestorObjects && sharedState.contextAncestorObjects.length > 0)
		topUuidAndVersion = getObjectIdAndVersionUuid(sharedState.contextAncestorObjects[0]);
	else topUuidAndVersion = getObjectIdAndVersionUuid(sharedState.contextTop);

	let { objects: changes } = getStateChanges(sharedState, topUuidAndVersion);
	let hierarchy = getGlobalObjectHierarchy(sharedState);

	if (changes) changes = getChangesForObject(changes, row, hierarchy, true);
	else changes = null;

	//If the relationshipType is the objectUserRelationship, load the clicked object into the workspace replacing the currently open object, but adding breadcrumbs
	if (mergeMfis) {
		let newObjectHierarchy = newMfi[0].objectHierarchy;
		//When merging a sub-object in, some of the information in the topRow doesn't match with the corresponding attached row
		newMfi[0].setupSheets = row.setupSheets;
		newMfi[0].indeterminateSetupSheets = row.indeterminateSetupSheets;
		let mergedMfi = [...mfi.filter((row) => row.reference !== newMfi[0].reference), ...newMfi].sort(
			sortByReference
		);
		await dispatch({
			type: "SET_CONTEXT_OR_MFI",
			data: {
				mfi: mergedMfi,
				objectHierarchy: [...objectHierarchy, ...newObjectHierarchy],
				// objectHierarchy: Object.values(newHierarchy),
				loadedSubObject: newMfi[0],
			},
		});
	}
	//else by default, load it into the existing MFI
	else {
		newMfi = addChangesToMfi(newMfi, changes, row.reference);

		// newHierarchy[hierarchyRecord.uuid] = hierarchyRecord;

		await dispatch({
			type: "SET_CONTEXT_OR_MFI",
			data: {
				top: newMfi[0],
				mfi: newMfi,
				objectHierarchy: newMfi[0].objectHierarchy
					? [hierarchyRecord, ...newMfi[0].objectHierarchy]
					: [hierarchyRecord],
				// objectHierarchy: Object.values(newHierarchy),
				openingSubObject: true,
				resetContextMfiVersion: true,
				attachmentPoint,
			},
		});

		if (newMfi && newMfi[0].uuid) {
			//Rather than updating the attachmentPoints, update the query params
			setQueryParams({
				uuid: newMfi[0].uuid,
				versionUuid: newMfi[0].versionUuid,
				path: hierarchyRecord.pathEnum,
				ref: newMfi[0].reference,
			});

			let attachmentPoints = await getAttachmentPoints();

			let oldAttachmentPoint = attachmentPoints.find(
				(row) => row.uuid === newMfi[0].uuid && row.versionUuid === newMfi[0].versionUuid
			);

			if (oldAttachmentPoint) deleteAttachmentPoint(newMfi[0].uuid);
			addAttachmentPoint({ objectId: newMfi[0].uuid, ...trimObject(attachmentPoint) });
		}
	}
};

/**
 * Create a method that will load the sub-object for me.
 *  It should load the mfi and then handle the logic of do I replace the current open object or merge the two MFIs
 *
 * What do I need in order to do this?
 *  1. I need the row I'm loading
 *  2. I need either the matching hierarchyRecord or the entire objectHierarchy
 *  3. I need the two types: objUsrRel and objDefRel
 *  4.
 *
 * Logically what should happen?
 *  1. Get the mfi
 *  2. Check if we need to replace the current object or merge with it
 *
 * @param row
 * @param hierarchyRecord
 * @param context
 * @param dispatch
 * @param objUsrRel
 * @param objDefRel
 */
export const loadRelatedObject = async (
	linkingHierarchyRecord,
	linkingRow,
	sharedState,
	dispatch,
	replaceTopObjectInWorkspace = false,
	{ addAttachmentPoint, getAttachmentPoints, updateAttachmentPoint, deleteAttachmentPoint },
	setQueryParams
) => {
	let { contextObjectHierarchy: objectHierarchy } = sharedState;
	if (!linkingHierarchyRecord) {
		linkingHierarchyRecord = objectHierarchy.find(
			(hierarchyRecord) => hierarchyRecord.descendantStandardObjectUuid === linkingRow[linkAttributeName]
		);
	}
	/**
	 * How do I load this related object and still retain the path I went through to get here
	 * Ex: I start in Org Chart, navigate down to a position and then a job description.
	 * The Org Chart, Position, and Job Description are separate top objects that sit in my Reference Manual
	 * Navigating to the Position from the Org Chart:
	 *  1) Add attachment point and breadcrumb for Org Chart
	 *  2) Load the Position in as the new top
	 *  3) Replace the objectHierarchy and full Object Hierarchy with te stuff for the position
	 *  4) When adding changes, attach to the last related object that was opened not the top of the ancestor array.
	 */

	/**
	 * What should happen on loading a related object?
	 * The only thing is to update the current related object so we can attach changes to it.
	 * Load its MFI into the workspace
	 * Update the ancestor objects, current related object, along with the attachment points.
	 * @type {*}
	 */
	let associatedObjectType = getAssociatedObjectType(sharedState);
	//Get the mfi for the related object
	let newMfi = await getSingleLevelObjectMfi(
		linkingHierarchyRecord.descendantStandardObjectUuid,
		linkingHierarchyRecord.descendantStandardObjectVersionUuid,
		dispatch
	);

	let row = newMfi[0];
	row.associatedObject = true;
	if (linkingRow.readonly) row.readonly = true;

	let attachmentPoint = {
		...newMfi[0],
		generalTypeUuid: associatedObjectType,
		objectHierarchy: [linkingHierarchyRecord],
	};

	//Update the breadcrumbs
	let { contextAncestorObjects: ancestorObjects, contextTop: top, contextPacket: packet } = sharedState;
	if (replaceTopObjectInWorkspace) {
		if (ancestorObjects?.length < 1 && packet) ancestorObjects = [packet];
	} else {
		if (ancestorObjects?.length > 0) ancestorObjects = [...ancestorObjects, top];
		else if (packet) ancestorObjects = [packet];
		else ancestorObjects = [top];
	}

	//Do something like this to add the old top to the ancestorObjects (breadcrumbs)
	if (row && row.uuid) {
		linkingRow.objectHierarchy = [linkingHierarchyRecord];

		Object.keys(linkingHierarchyRecord).forEach((key) => {
			row["associatedHierarchyAttribute" + key] = linkingHierarchyRecord[key];
		});

		//TODO: How should the object hierarchy be updated?
		//Update the top, mfi, and the relatedObject in the state
		dispatch({
			type: "SET_CONTEXT_OR_MFI",
			data: {
				currentRelatedObject: row,
				top: row,
				mfi: newMfi,
				ancestorObjects,
				resetContextMfiVersion: true,
			},
		});

		setQueryParams({
			uuid: row.uuid,
			versionUuid: row.versionUuid,
			path: linkingHierarchyRecord.pathEnum,
			ref: row.reference,
		});

		let attachmentPoints = await getAttachmentPoints();

		let oldAttachmentPoint = attachmentPoints.find(
			(row) => row.uuid === newMfi[0].uuid && row.versionUuid === newMfi[0].versionUuid
		);

		if (oldAttachmentPoint) deleteAttachmentPoint(newMfi[0].uuid);
		addAttachmentPoint({ objectId: newMfi[0].uuid, ...trimObject(attachmentPoint) });
	}
};

export const splitPathIntoArrayOfObjectKeys = (pathEnum) => {
	return pathEnum.split(".");
};

export const addChangesToMfi = (mfi, changes, prependReference = "") => {
	if (changes) {
		//Convert the mfi to a map which will make searching easier, this will find TODO: (This can potentially cause issues throwing off the references of sub-objects verify later)
		let map = convertListToRealJsMap(mfi);

		//TODO If this is a hologram match up based on the hologramUuid and parentHologramUuid
		// Basically update the uuids in the changed rows to match the newly retrieved one

		//Apply the changes if any
		Object.values(changes.mfi).forEach((row) => {
			//For each row, replace the row in the mfi with the one in the changed rows
			let uuidAndVersion = getObjectIdAndVersionUuid(row, false);

			if (map.get(uuidAndVersion)) {
				let backendRow = map.get(uuidAndVersion);

				row.uuid = backendRow.uuid;
				row.versionUuid = backendRow.versionUuid;
				row.standardObjectUuid = backendRow.standardObjectUuid;
				row.standardObjectVersionUuid = backendRow.standardObjectVersionUuid;
				row.parentUuid = backendRow.parentUuid;
				/**
				 * Also update the reference to match the backend row? because the change row has the reference reset.
				 * I'm assuming that we grabbed the MFI with an attachment point and that the backend will the have the correct reference whether we are looking at a sub-object or a top object
				 *
				 * We also need to make a copy before changing the reference because we don't want the change to happen in the changed row
				 */
				row = { ...row };
				//This should have a reference that needs prepended meaning this won't be hit if we need the backend reference
				// row.reference = backendRow.reference;
			} else {
				row = { ...row };
			}

			if (prependReference) {
				if (row.reference === "0" || !row.reference) row.reference = prependReference;
				else row.reference = prependReference + "." + row.reference;
			}

			if (row.objectHierarchy) row.objectHierarchy = map.get(uuidAndVersion).objectHierarchy;

			map.set(uuidAndVersion, row);
		});

		Object.values(changes.deleted).forEach((row) => {
			//For each deleted row, remove it from the mfi
			let uuidAndVersion = getObjectIdAndVersionUuid(row, false);
			map.delete(uuidAndVersion);
		});

		//Do we need to fix the object hierarchy too?

		//Rebuild the mfi from the map
		mfi = [...map.values()].sort(sortByReference);
		return mfi;
	} else {
		return mfi;
	}
};

/**
 * Pass in the row and hierarchyRecord to build the attachment point to send back when retrieving subObjects
 * @param row
 * @param hierarchyRecord
 */
export const buildAttachmentPoint = (row, hierarchyRecord) => {
	return {
		...row,
		objectHierarchy: [hierarchyRecord],
	};
};

export const getChangesForObject = (currentChanged, ancestorRow) => {
	//Check if we have made any changes to any rows stored in the state
	if (currentChanged) {
		let changes = currentChanged[getObjectIdAndVersionUuid(ancestorRow)];

		if (changes) return changes;
	}
};

export const getStateChanges = (
	state,
	topUuidAndVersion,
	view = "",
	standardObjectTitle = defaultStandardObjectGitChangeTitle
) => {
	if (!state) return {};

	if (!view) view = state?.openView?.title;

	if (
		state.changedData &&
		state.changedData[view] &&
		state.changedData[view][standardObjectTitle] &&
		state.changedData[view][standardObjectTitle][topUuidAndVersion]
	)
		return {
			objects: state.changedData[view][standardObjectTitle][topUuidAndVersion].objects,
			objectHierarchy: Object.values(
				state.changedData[view][standardObjectTitle][topUuidAndVersion].objectHierarchy
			),
		};

	return {};
};

/**
 * Pass in the state and returns the global object hierarchy
 */
export const getGlobalObjectHierarchy = (state) => {
	if (state.contextFullObjectHierarchy) return state.contextFullObjectHierarchy;
	else return state.contextObjectHierarchy;
};

/**
 * Takes in a change map and adds changes at the objectToUpdate level
 */
export const buildNewChangeMap = (
	view,
	masterFileIndexTitle = defaultMasterFileIndexChangeTitle,
	standardObjectTitle = defaultStandardObjectGitChangeTitle
) => {
	return {
		[view]: {
			[masterFileIndexTitle]: {
				mfi: {},
				deleted: [],
			},
			[standardObjectTitle]: {},
		},
	};
};

export const objectIsPacket = (obj, sharedState) => {
	return obj.objectTypeUuid === sharedState.dbConstants.packetObject.referenceUuid;
};

export const isUbmItem = (obj) => {
	return obj[UBM_MASTER_FILE_INDEX_ITEM];
};

/**
 * Check if there are changes that need saved, if there are prompt the user and ask if they want to save them or cancel
 * @returns {*}
 */
export const promptForUnsavedChanges = (sharedState, dispatch, thereAreChanges) => {
	if (thereAreChanges || sharedState.thereAreChanges) {
		let answer = window.confirm("You have unsaved changes, are you sure you want to leave?");

		if (answer) return { thereAreChanges: true, discard: true, saved: false };

		if (dispatch) dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
		return { thereAreChanges: true, discard: false, saved: false };
	}
	return { thereAreChanges: false, discard: true, saved: null };
};

export const loadObjectVersions = async (dispatch, versionUuid) => {
	let versions = await getObjectsComputerVersions(versionUuid);
	dispatch({
		type: "SET_CONTEXT_OR_MFI",
		data: {
			versions,
		},
	});
};

export const shallowDiffers = (prev, next) => {
	for (let attribute in prev) {
		if (!(attribute in next)) {
			return true;
		}
	}
	for (let attribute in next) {
		if (prev[attribute] !== next[attribute]) {
			return true;
		}
	}
	return false;
};

export const convertStandardObjectMfiToObject = async (mfi, sharedState, dispatch, camelize = true) => {
	//Check if there is an `Object Creator Defined Attributes` Folder, this will be what we will use to create the object

	if (!mfi || mfi.length < 1) return {};
	//This method adds the defaults attribute and somehow the mfi that gets passed is the result of a previous conversion
	else if (mfi.defaults) return mfi;

	//Get the top level. We should only need to add an attribute for these?
	let topLevel = [];
	let versionUuid, uuid;
	if (mfi.length > 0) {
		versionUuid = mfi[0].versionUuid;
		uuid = mfi[0].uuid;
	}

	let ocda = mfi.find((row) => row.title === componentsNodeTitle);
	if (ocda) {
		topLevel = mfi.filter((row) => row.parentUuid === ocda.uuid);
		mfi = mfi.filter((row) => row.reference.startsWith(ocda.reference) && row.uuid !== ocda.uuid);
	} else topLevel = mfi.filter((row) => row.parentUuid === mfi[0].parentUuid);

	let obj = {
		defaults: {},
		versionUuid,
		uuid,
	};

	/**
	 *  For each row in the MFI add an attribute to the obj
	 *  How should this object look?
	 *  Open Point Object Example:
	 *  {
	 *      formRef: {
	 *      	//TODO: Do we need the inputType too? So we know how to display it later?
	 *      	inputType: 'association',
	 *          linkToSource: 'object-master-file-index' or 'data-warehouse-object',
	 *          linkToObjectUuid: '',
	 *          linkToObjectVersionUuid: '',
	 *          linkToAttributeUuid: '',
	 *      },
	 *      line: How do we implement this function and grab the index?,
	 *      priority: 'A-1',
	 *      openedRequestedBy: 'WH', //When using the title convert it to camelcase and remove special characters like the slash. In order to interpret this correctly we need the table to also build itself based on this definition...,
	 *      dateOpened: '04/14/2023',
	 *      leCompanyCcode: '',
	 *      contactEmail: 'blstarwars@live.com',
	 *      legalEntity: '',
	 *      modelAppTitle: '',
	 *      modelAppId: '',
	 *      modelAppVersion: '',
	 *      newVersionOrNewDocument: '', //Fix the Table Header,
	 *      documentTitleOrigination: '',
	 *      documentVersion: 4,
	 *      actionRequiredDescriptionOfChangeToBeMade: 'Add links to Open Points',
	 *      approvalLevelRequired: '',
	 *      objectType: '',
	 *      ubmChangeType: '',
	 *      reviewPointType: '',
	 *      approvedBy: '',
	 *      dateApproved: '',
	 *      assignedTo: '',
	 *      dueDate: '',
	 *      changeRequest: '',
	 *      disposition: '',
	 *      dispositionLink: {
	 *      	inputType: 'association',
	 *      	linkToSource: 'object-master-file-index' or 'data-warehouse-object',
	 *      	linkToObjectUuid: '',
	 *      	linkToObjectVersionUuid: '',
	 *      	linkToAttributeUuid: '',
	 *      },
	 *      closedBy: '',
	 *      dateClosed: '',
	 *  }
	 */

	await Promise.all(
		topLevel.map(async (row) => {
			row = { ...row };
			let children = mfi.filter((r) => r.parentUuid === row.uuid);
			if (camelize) row.title = camelizeAndRemoveSpecialCharacters(row.title);
			//There should only be a couple types that I would need to do something special for
			switch (row.inputType) {
				case INPUT_FIELD_TYPES.LIST.value:
					obj[row.title] = [];
					//If the child is an object, call this method on that object
					children.map(async (child) => {
						if (child.object) {
							obj[row.title].push(
								await convertStandardObjectMfiToObject(
									mfi.filter((r) => r.reference.startsWith(child.reference)),
									sharedState,
									dispatch
								)
							);
						} else obj[row.title].push(child);
					});
					break;
				case INPUT_FIELD_TYPES.ASSOCIATION.value:
					obj[row.title] = {
						inputType: "association",
						linkToSource: row.linkToSource,
						linkToObjectUuid: "",
						linkToObjectVersionUuid: "",
						linkToAttributeUuid: "",
					};
					break;
				default:
					//Implement defaults and if there isn't a value use Adam's Lexer to calculate what the default should be.
					if (children.length > 0) {
						obj[row.title] = await convertStandardObjectMfiToObject(
							mfi.filter((r) => r.reference.startsWith(row.reference) && r.uuid !== row.uuid),
							sharedState,
							dispatch
						);
					} else if (
						row.defaultValue &&
						(row.defaultValueOccurrence === "on-create" || row.defaultValueOccurrence === "every-time")
					) {
						if (row.defaultValue.includes("=")) {
							obj[row.title] = await evaluateExpression(row.defaultValue.slice(1), sharedState, {
								harvestedMfi: obj,
							});
						} else {
							obj[row.title] = row.defaultValue;
						}
					} else {
						obj[row.title] = row.value;
					}

					//We could potentially have defaults that occur later and not necessarily on creation, we need a reference to the expression and when to grab the default value
					if (row.defaultValueOccurrence !== "0") {
						obj.defaults[row.title] = {
							defaultValue: row.defaultValue,
							defaultValueOccurrence: row.defaultValueOccurrence,
						};
					}
					break;
			}
		})
	);

	//Iterate over each object key and the ones that we calculate every time, recalculate just in case they relied on another field. We will have to resolve this issue when we move to the graph database with a dependency graph or something
	Object.keys(obj.defaults)
		.filter(
			(row) =>
				row.defaultValue?.includes("=") &&
				(row.defaultValueOccurrence === "every-time" || row.defaultValueOccurrence === "on-create")
		)
		.map(async (key) => {
			obj[key] = await evaluateExpression(obj.defaults[key].defaultValue.slice(1), sharedState, {
				harvestedMfi: obj,
			});
		});

	return obj;
};

export const buildDefaultStructure = () => {};

export const getComponentsFromMfi = (mfi) => {
	let ocda = mfi.find(
		(row) =>
			row.parentUuid === mfi[0].uuid &&
			(row.title === componentsNodeTitle || row.title === oldComponentsNodeTitle)
	);
	let components = mfi.filter((row) => row.reference.startsWith(ocda.reference) && row.uuid !== ocda.uuid);
	//For each component get the path to it
	components.forEach((component) => (component.path = getComponentPath(ocda.uuid, component, components)));
	return mfi.filter((row) => row.reference.startsWith(ocda.reference) && row.uuid !== ocda.uuid);
};

const getComponentPath = (componentRowUuid, component, components) => {
	let path = component.title,
		foundFullPath = false;
	do {
		if (component.parentUuid === componentRowUuid) foundFullPath = true;
		else {
			component = components.find((row) => row.uuid === component.parentUuid);
			path = component.title + "." + path;
		}
	} while (!foundFullPath);

	return path;
};

export const getMethodsFromMfi = (mfi) => {
	let methods = mfi.find((row) => row.parentUuid === mfi[0].uuid && row.title === "Methods");

	if (methods) methods = mfi.find((row) => row.parentUuid === methods.uuid && row.title === "Method Objects");
	else methods = mfi.find((row) => row.parentUuid === mfi[0].uuid && row.title === "Method Objects");

	if (!methods) return [];

	return mfi.filter((row) => row.reference.startsWith(methods.reference) && row.uuid !== methods.uuid);
};

/**
 * I want the object to decide what is showing and what isn't, along with how
 * Use this method to build the order that the fields go in and how we should render each field?
 */
export const buildFieldOrderingForUseWithMethodAbove = () => {};

export const buildHierarchyRecordFromPath = (path) => {
	let split = path.split(".");

	//TODO: Is it better to return an empty object or null here?
	if (split.length < 1) return {};

	let descendant = split[split.length - 1].split("/");

	let hierarchyRecord = {
		descendantStandardObjectUuid: descendant[0],
		descendantStandardObjectVersionUuid: descendant[1],
		pathEnum: path,
	};

	if (split.length > 1) {
		let ancestor = split[split.length - 2].split("/");
		hierarchyRecord.ancestorStandardObjectUuid = ancestor[0];
		hierarchyRecord.ancestorStandardObjectVersionUuid = ancestor[1];
	} else {
		hierarchyRecord.ancestorStandardObjectUuid = ZERO_ROW_UUID;
		hierarchyRecord.ancestorStandardObjectVersionUuid = ZERO_ROW_UUID;
	}

	return hierarchyRecord;
};

export const getToc = (mfi) => {
	let components = mfi.find((row) => row.title === oldComponentsNodeTitle || row.title === componentsNodeTitle);
	return mfi.filter((row) => row.reference.startsWith(components.reference) && row.uuid !== components.uuid);
};

export const getStandardObjectRowFromPath = (pathArray, fullMfi) => {
	let match;

	for (let path in pathArray) {
		let variable;
		path = pathArray[path];
		if (!match) {
			variable = fullMfi.find((row) => row.title === path);
		} else {
			variable = fullMfi.find((row) => row.title === path && row.reference.startsWith(match.reference));
		}
		if (!variable) {
			match = {};
			break;
		} else {
			match = variable;
		}
	}
	return match;
};

export const getAncestors = (row, mfi) => {
	let top = mfi[0];
	let map = buildUuidToObjectMap(mfi);

	let ancestors = [];
	let parent = map.get(row.parentUuid);
	do {
		if (parent) {
			ancestors.push(parent);
			parent = map.get(parent.parentUuid);
		}
	} while (parent && parent.uuid !== top.uuid);
	return ancestors;
};

export const createNewObject = async (
	{ uuid, versionUuid, object, userUuid, ref, parent, releaseVersion },
	diffUser,
	objectType
) => {
	let original = object;
	if (!original) {
		original = await getObjectGitRecord(uuid, versionUuid);
	}
	let newObject = releaseVersion
		? copyStandardObject(original, "0", parent?.uuid || zeroRowUuid, userUuid, {
				newVersion: true,
				newFork: diffUser,
				releaseVersion,
		  })
		: copyStandardObject(original, "0", parent?.uuid || zeroRowUuid, userUuid, {
				newVersion: true,
				newFork: diffUser,
				newObject: true,
		  });

	//Set the general type of the rowCopy to the objectType
	if (objectType) newObject.generalTypeUuid = objectType;

	if (ref) {
		newObject.reference = ref.reference;
		newObject.referenceNo = ref.referenceNo;
	}

	if (parent) {
		newObject.setupSheets = parent.setupSheets;
		newObject.indeterminateSetupSheets = parent.indeterminateSetupSheets;
	}

	return newObject;
};

//Creates an update for an object
export const createObjectUpdate = (object, subObjectChanges) => {
	let update = {
		standardObject: object,
		subObjectChanges: new Map(),
		objectHierarchy: [],
	};

	// Iterate over the subObjectChanges and add them to the map
	if (subObjectChanges)
		for (let [key, value] of subObjectChanges) {
			update.subObjectChanges.set(key, value);
		}

	return update;
};

//Adds changes to the object update
export const addChangesToUpdate = (update, subObjectChanges) => {
	// addRowToChangedObjectMap;
};

export const createAssociatedObjectCopy = (objectToCopy, parent, ref) => {
	let copy = { ...objectToCopy };
	//Update the copy
	copy.reference = ref.reference;
	copy.referenceNo = ref.referenceNo;
	copy.parentUuid = parent.uuid;
	copy.setupSheets = parent.setupSheets;
	copy.indeterminateSetupSheets = parent.indeterminateSetupSheets;
	copy.generalTypeUuid = ASSOCIATED_OBJECT_GENERAL_TYPE_UUID;

	copy[linkAttributeName] = objectToCopy.uuid;
	copy[linkVersionAttributeName] = objectToCopy.versionUuid;
	copy.uuid = uuidv4();
	copy.versionUuid = uuidv4();
	copy.objectTags = "";
	copy.associatedObject = true;

	return copy;
};

/**
 * Gets the next reference for something that goes in the developers In Process Folder.
 * The way we handle things in process / review / sent for review is we leave everything in the In Process MFI
 * and whenever retrieving it we move things from the In Process Folder to the Sent for Review and Ready for my Review Folder
 * So when placing a new object in the In Process Folder we need to take into account the objects that were moved else where by the program
 * @param userMfi
 * @param inProcessMfi
 * @returns {{referenceNo: number}}
 */
export const getNewRefForObjectInUserFolder = (userMfi, inProcessMfi) => {
	let inProcessRecord = inProcessMfi[0],
		newRef;
	let children = userMfi.filter((row) => row.parentUuid === inProcessRecord.uuid);
	let childrenInReview = userMfi.filter((row) => row.originalParent === inProcessRecord.uuid);
	if (children.length > 0 && childrenInReview.length > 0) {
		newRef = getNextRef(inProcessRecord.reference + "." + (children.length + childrenInReview.length));
	} else if (children.length > 0) newRef = getNextRef(children[children.length - 1].reference);
	else if (childrenInReview.length > 0)
		newRef = getNextRef(childrenInReview[childrenInReview.length - 1].originalReference);
	else newRef = getNextRef(inProcessRecord.reference + ".00");

	return newRef;
};

export const findObjectWithMatchingReleaseVersionInDestinationModel = async (uuid, versionUuid, destinationModel) => {
	//Get the git record which will have the release version
	let gitRecord = await getObjectGitRecord(uuid, versionUuid);

	//Find matching release version in destination model
	return destinationModel.find((row) => row.logRecord?.versionUuid === gitRecord.logRecord?.versionUuid);
};

export const createMapToSaveNewObject = (newObject, changedRows, changedMfiRows) => {
	const openViewTitle = viewTitles.DEFAULT;
	const objectGitChangeTitle = dataObjects.STANDARD_OBJECT_GIT.title;
	const mfiChangeTitle = dataObjects.MASTER_FILE_INDEX_ROW.title;
	const map = {
		[openViewTitle]: {
			[objectGitChangeTitle]: {},
			[mfiChangeTitle]: {
				mfi: {},
				deleted: [],
			},
		},
	};

	let uuidAndVersion = getObjectIdAndVersionUuid(newObject);
	let newHierarchyRecord = createObjectHierarchyRecord({}, newObject);
	map[openViewTitle][objectGitChangeTitle][uuidAndVersion] = {
		objectHierarchy: [newHierarchyRecord],
		top: newObject,
		objects: {},
	};

	const subObjectMap = map[openViewTitle][objectGitChangeTitle][uuidAndVersion].objects;
	if (changedRows?.length > 0) {
		changedRows.forEach((row) => {
			const uuidAndVersion = getObjectIdAndVersionUuid(row);
			subObjectMap[uuidAndVersion] = row;
		});
	}

	const mfiChangeMap = map[openViewTitle][mfiChangeTitle].mfi;
	if (changedMfiRows?.length > 0) {
		changedMfiRows.forEach((row) => {
			mfiChangeMap[row.uuid] = row;
		});
	}

	return map;
};

export const saveNewObject = async (newObject, { newHierarchyRecord, objectChanges, mfiChanges }) => {
	if (!newHierarchyRecord) newHierarchyRecord = createObjectHierarchyRecord({}, newObject);

	//Build the format the save expects, we need to save the object first because the backend sends back the new version and the mfi rows need to point to it
	const topUuidAndVersion = getObjectIdAndVersionUuid(newObject);
	let update = {
		standardObjectUpdates: [
			{
				standardObject: newObject,
				subObjectChanges: {
					[topUuidAndVersion]: {
						objectRowsToUpdate: [newObject],
						objectRowsToDelete: [],
					},
				},
				objectHierarchy: [],
				// objectHierarchy: [newHierarchyRecord]
			},
		],
	};

	if (objectChanges?.length > 0) {
		objectChanges.forEach((row) => {
			update[0].subObjectChanges[topUuidAndVersion].objectRowsToUpdate.push(row);
		});
	}

	const objectUrl = getUrl("saveObjectRows");
	const mfiUrl = getUrl("updateMfi");
	const response = await postCall(objectUrl, update);

	const newVersion = response.standardObjectUpdates[0].standardObject.versionUuid;
	//Update the version of the new object that the mfi row pointed to
	if (mfiChanges?.length > 0) {
		const match = mfiChanges.find((row) => row.standardObjectUuid === newObject.uuid);
		match.standardObjectVersionUuid = newVersion;
		update = {
			mfiRowsToUpdate: mfiChanges,
		};

		let response = await postCall(mfiUrl, update);
	}
};
