import React, { useEffect, useRef, useState } from "react";
import BootstrapAccordion from "../BootstrapComponents/Accordion/BootstrapAccordion";
import { getSingleLevelObjectMfi } from "../../utils/ApiUtils";
import SetupField, { LabelWithHelpIcon } from "../ReactGridComponents/SetupSheetWindow/SetupField";
import SetupSheetWindow from "../ReactGridComponents/SetupSheetWindow/SetupSheetWindow";
import { SetupSheetWindowsWithPassedData } from "../ReactGridComponents/Body/SetupSheetPanel/SetupSheetPanel";
import { ExpandIcon, LinkIcon, TrashIcon, XIcon } from "../BootstrapComponents/Icons/Icons";
import { updateChangeData } from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { RemoveCircle } from "@mui/icons-material";
import { useDispatch, useTrackedState } from "../../utils/store";
import { sortByReference } from "../../utils/Referencing";
import { ColorfulLoader, SimpleLoader } from "../Loader/Loader";
import { useArray } from "../../utils/useStickyArray";

const HarvestedObjectView = ({
	obj,
	hierarchyRecord,
	updateRow,
	removeObject: _removeObject,
	nodeClicked: _nodeClicked,
	dataUpdates,
}) => {
	const [mfi, setMfi] = useState([]);
	const [showLoader, setShowLoader] = useState(false);

	const hierarchyRecordRef = useRef(hierarchyRecord);
	//I'm using both the state and ref because for some reason the mfi state doesn't always get updated.
	//I'm not sure how this is possible, but I even tried using the useArrayHook which is supposed to guarantee its a new array in memory and trigger necessary re-renders
	const mfiRef = useRef([]);
	const loadedObjectVersionRef = useRef({});

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		//Load the objects MFI and set the fields
		getMfi();
	}, [obj.uuid, obj.versionUuid]);

	useEffect(() => {
		let { subObjectUpdates, hierarchyUpdates } = sharedState.subObjectUpdates;
		if (!subObjectUpdates) return;

		//For each update check if we have a matching row in this components data
		subObjectUpdates.forEach((update) => {
			let match = mfi.find((row) => row.uuid === update.uuid);
			if (match) {
				match.title = update.title;
				match.versionUuid = update.versionUuid;
				match.value = update.value;
			}

			if (obj.uuid === update.uuid) {
				obj.title = update.title;
				obj.versionUuid = update.versionUuid;
				obj.value = update.value;

				//Update hierarchy records
				hierarchyRecordRef.current = hierarchyUpdates.find(
					(row) => row.descendantStandardObjectUuid === obj.uuid
				);

				//If we don't have an MFI for this object, its new and barely gotten saved so we need to retrieve it
				if (mfi.length < 1) getMfi();
			}
		});

		//Update the hierarchy records as well
		if (mfi.length > 0) {
			hierarchyUpdates.forEach((update) => {
				//Update the hierarchy. We need to update the old record to match rather than replacing it for the descendant components that may be using it as a pointer
				let oldMatchingDescendantRecord = mfi[0].objectHierarchy.find(
					(row) => row.descendantStandardObjectUuid === update.descendantStandardObjectUuid
				);
				if (oldMatchingDescendantRecord) {
					oldMatchingDescendantRecord.pathEnum = update.pathEnum;
					oldMatchingDescendantRecord.descendantStandardObjectVersionUuid =
						update.descendantStandardObjectVersionUuid;
				}

				let oldMatchingAncestorRecord = mfi[0].objectHierarchy.find(
					(row) => row.ancestorStandardObjectUuid === update.ancestorStandardObjectUuid
				);
				if (oldMatchingAncestorRecord) {
					oldMatchingAncestorRecord.pathEnum = update.pathEnum;
					oldMatchingAncestorRecord.ancestorStandardObjectVersionUuid =
						update.ancestorStandardObjectVersionUuid;
				}
			});
		}

		//When do we reset the subObjectUpdates? Can we do it here? Does anywhere else rely on them?
		dispatch({ type: "SET_SUB_OBJECT_UPDATES", data: [] });
	}, [sharedState.subObjectUpdates?.subObjectUpdates, sharedState.subObjectUpdates?.subObjectUpdates?.length]);

	const getMfi = async () => {
		if (
			mfi.length > 0 &&
			loadedObjectVersionRef.current.uuid === obj.uuid &&
			loadedObjectVersionRef.current.versionUuid === obj.versionUuid
		)
			return;

		loadedObjectVersionRef.current = { uuid: obj.uuid, versionUuid: obj.versionUuid };

		setShowLoader(true);
		let res = await getSingleLevelObjectMfi(obj.uuid, obj.versionUuid, null, null, {
			...obj,
			objectHierarchy: [hierarchyRecordRef.current],
		});

		if (res) {
			setMfi(res);
			mfiRef.current = res;
			obj.objectHierarchy = res[0].objectHierarchy;
		}
		setShowLoader(false);
	};

	const nodeClicked = (e, row) => {
		if (obj.isObject) {
			if (e.stopPropagation) e.stopPropagation();
			if (row) _nodeClicked(row);
			else _nodeClicked(obj.uuid);
		}
	};

	const titleChanged = (newTitle) => {
		if (obj.title === newTitle) return;

		obj.title = newTitle;

		updateRow(newTitle);
	};

	const removeObject = () => {
		_removeObject(obj);
	};

	const mfiUpdates = (update) => {
		let uuidsToRemove = [];
		if (update.deletedRows) uuidsToRemove = update.deletedRows.map((row) => row.uuid);

		uuidsToRemove = [...uuidsToRemove, ...update.objectRows.map((row) => row.uuid)];
		//Update the mfi with the update passed in?
		//If this is a new harvested object also add all each ancestor to the changed rows
		let newMfi = [...mfiRef.current.filter((row) => !uuidsToRemove.includes(row.uuid)), ...update.objectRows].sort(
			sortByReference
		);
		mfiRef.current = newMfi;
		setMfi(newMfi);
	};

	return (
		<BootstrapAccordion
			titles={[
				<LabelWithHelpIcon
					reference={obj.reference}
					title={obj.title}
					description={obj.description}
					labelStyles={{ marginBottom: "0px" }}
				/>,
			]}
			object={obj}
			editTitleButton={true}
			titleLink={true}
			titleLinkClicked={nodeClicked}
			deleteClicked={removeObject}
			handleBlur={titleChanged}
			divStyles={showLoader ? { opacity: ".5" } : {}}
			// activeIndex={0}
		>
			{[
				<>
					{showLoader ? (
						<SimpleLoader
							loaderStyles={{ position: "absolute", top: "40%", left: "50%" }}
							height={"48px"}
							width={"48px"}
						/>
					) : (
						""
					)}

					{/*Render each field as a setup field*/}
					<SetupSheetWindowsWithPassedData
						top={obj}
						mfi={mfi}
						updateRow={(row) => console.log("update row", row)}
						harvestedWindow={true}
						mfiUpdates={mfiUpdates}
						dataUpdates={dataUpdates}
						nodeSelected={nodeClicked}
					/>
				</>,
			]}
		</BootstrapAccordion>
	);
};

export default HarvestedObjectView;
