import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { VirtualizedList as ListboxComponent } from "../Virtualized/VirtualizedList";
import stylesModule from "./TitleAutoComplete.module.scss";

/**
 * An autocomplete component that can be used to auto complete any field as long as I'm passed the name of the field,
 *  the field label, value, list of options, and callback functions for handle change and handle blur
 * @param name : The name of the field that is using the autocomplete
 * @param label : The field label
 * @param value : The value of the field
 * @param options : The list of objects to show as options as text is inputted
 * @param handleChange : A callback method that is called whenever the value changes
 * @param handleBlur : A callback method called when the auto complete input field loses focus
 * @returns {*}
 * @constructor
 */
function TitleAutoComplete({
	name,
	label,
	value: _value,
	objectOptions = false,
	options = [],
	handleChange,
	handleBlur,
	allowNew = true,
	InputProps = {},
	focus,
	...other
}) {
	const [value, setValue] = useState("");
	const [myOptions, setMyOptions] = useState([]);

	const selectedOption = useRef({});
	const inputRef = useRef({});

	/**
	 * Called every time options changes
	 */
	useEffect(() => {
		if (options) setMyOptions(options);
	}, [options]);

	useEffect(() => {
		if (_value) {
			if (_value.uuid) setValue(_value[name]);
			else setValue(_value);
		}
	}, [_value]);

	useEffect(() => {
		if (focus) inputRef.current.focus();
	}, [focus]);

	const autocompleteBlurred = (e) => {
		if (allowNew && !selectedOption.current.uuid) handleBlur(e, name, value);
		// if(selectedOption.current.uuid)
		//     handleBlur(e, name, selectedOption.current);
	};

	return (
		<>
			<Autocomplete
				name={name}
				options={myOptions}
				getOptionLabel={(option) => option[name]}
				inputValue={value}
				onChange={(e, newValue) => {
					if (!newValue) {
						selectedOption.current = {};
						handleBlur("");
					} else if (newValue.uuid) {
						selectedOption.current = newValue;
						handleBlur(newValue);
					}

					// handleChange(e, name, newValue);
				}}
				onInputChange={(e, newValue) => {
					//If the options are objects the input change without an event causes issues
					//For text options it is necessary
					if (!e && objectOptions) return;

					setValue(newValue);

					if (!newValue) handleBlur("");

					//I don't think we need to call handleChange on this anymore, if entering a new value and allowNew is true we can handle this in the handleBlur function
					// handleChange(e, name, newValue);
				}}
				disableListWrap
				ListboxComponent={ListboxComponent}
				freeSolo={allowNew}
				selectOnFocus
				onBlur={autocompleteBlurred}
				{...other}
				renderInput={(params) => (
					<TextField
						{...params}
						inputRef={(input) => (inputRef.current = input)}
						InputProps={{ ...params.InputProps, ...InputProps }}
						label={label}
						className={stylesModule.formField}
						InputLabelProps={{ shrink: true }}
						// variant={"standard"}
					/>
				)}
			/>
		</>
	);
}

export default TitleAutoComplete;

export const MultiAutoComplete = ({
	fieldToChange,
	optionLabel,
	fieldLabel,
	value,
	options,
	handleBlur,
	handleChange,
	inputStyles,
}) => {
	const [myVal, setMyVal] = useState([]);

	useEffect(() => {
		if (!value || value.length < 1) {
			setMyVal([]);
			return;
		}

		let uuids = [];
		if (value[0]?.uuid) uuids = value.map((row) => `${row.uuid},${row.versionUuid}`);
		else uuids = value;

		//I don't think we want to restrict it to a certain version, just uuid
		uuids = uuids.map((uuid) => {
			return uuid.split(",")[0];
		});

		setMyVal(options.filter((option) => uuids.includes(`${option.uuid}`)));
	}, [value]);

	return (
		<Autocomplete
			multiple
			options={options}
			getOptionLabel={(option) => {
				if (Array.isArray(optionLabel)) {
					let val = "";
					optionLabel.forEach((opt) => (option[opt] ? (val += option[opt] + "   ") : ""));
					return val;
				}
				return option[optionLabel];
			}}
			value={myVal}
			onChange={handleChange}
			// onInputChange={onChange}
			ListboxComponent={ListboxComponent}
			onBlur={handleBlur}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="standard"
					label={fieldLabel}
					InputProps={{ ...params.InputProps, style: { ...inputStyles } }}
					// placeholder="Favorites"
				/>
			)}
		/>
	);
};
