import { useEffect } from "react";

const About = () => {
	useEffect(() => {
		document.title = "About";
	});

	return (
		<div className="container">
			<div className="row mt-3">
				<h1 className="text-center info-page-title">About Us</h1>
				<div className="info-page">
					<h3 className="text-center info-title">Conceptual Definition</h3>
					<article>
						<p className=" fs-6 text-center">
							Business Model Consulting, LLC (BMCL) is a consulting company focused on maintaining and
							developing the Universal Business Model. We offer consulting services to our clients in
							order to help them develop and maintain their own business models. We assist our clients in
							developing, reviewing, and operating their business models, which includes running the
							Management Reporting Application along with many others. We also have an App Store where our
							clients can share, post, or download any applications they wish.
						</p>
					</article>
				</div>

				<div className="row">
					<h3 className="text-center info-title">Mission Statement</h3>
					<article>
						<p className=" fs-6 text-center">
							BMCL's Mission is to create a Universal Business Model System for the purpose of creating a
							repeatable structure within a business that will account for every idea, process, and
							system. The Universal Business Model helps an entity create an organizational structure that
							will assist in developing a high level of organization, short-term and long-term goal
							setting, risk analysis, control, functionality, information, business-model updating, and
							record retention throughout an organization. A functioning model will facilitate information
							needed to strategically make positive decisions at every level in the organization. We
							accomplish these things by developing and maintaining checklists and processes that will
							report vital information to the CEO in a responsive and effective way.
						</p>
						<p>
							The Universal Business Model System will allow any person using this application to share
							policies, procedures, documents, applications, processes, etc., to assist others in creating
							their own model structure. The reason is to create a process that will allow anyone using
							the Universal Business Model (UBM) to develop solutions or applications within their own
							business, family, church, etc. model and to share those solutions or applications with other
							users. This will help everyone that is a part of the UBM avoid having to reinvent the wheel.
						</p>
					</article>
				</div>

				<div className="row">
					<h3 className="text-center info-title">Vision Statement</h3>
					<article>
						<p className=" fs-6 text-center">
							BMCL's Vision is to create and maintain a Universal Business Model system that is unmatched
							by any competitor in every way conceivable. It is our goal to create a system that every
							business in the world could use to obtain the most valuable and current information about
							their business, and in the process create a streamlined system for the business’ day to day
							activities. This will allow our clients to make informed decisions about their business. By
							having accurate information clients will be able to remove much of the uncertainty in the
							decision making process.
						</p>
					</article>
				</div>

				<div className="row">
					<h3 className="text-center info-title">Core Values</h3>
					<ul>
						<li>Balance - Maintaining a healthy balance between our Employees work and personal life.</li>
						<li>Collaboration - Collaborating within and outside the company to give the best. </li>
						<li>
							Commitment - Commitment to create great products, services, and other initiatives that
							impact lives both within and outside the organization.
						</li>
						<li>
							Community - A sense of responsibility and contribution to society that define our existence.
						</li>
						<li>Consistency - Be consistent in offering the best to our clients. </li>
						<li>Diversity - Respecting the diversity and giving the best of the composition. </li>
						<li>
							Efficiency - Being efficient and effective in our approach to give the best solution each
							time.
						</li>
						<li>
							Innovation - To come out with new creative ideas that have the potential to change the
							world.
						</li>
						<li>Integrity - To act with honesty and integrity without compromising the truth. </li>
						<li>Passion - Putting the heart and mind in the work to get the best product and service. </li>
						<li>Quality - Giving the best and unmatched results for all round satisfaction. </li>
						<li>
							Respect - Giving due respect to self and others and maintain the environment of team work
							and growth.
						</li>
						<li>
							Safety - Ensuring the safety of people and making sure to give them a trouble free
							experience.
						</li>
						<li>
							Service Excellence - Giving the best, world class service and achieving excellence each day.
						</li>
					</ul>
				</div>

				<div className="row">
					<div className="col-sm-6">
						<div>
							<p className=" fs-6 info-title">CUSTOMERS</p>
							<ul>
								<li>Business Entities</li>
								<li>Non-Profit Entities</li>
								<li>Individuals</li>
							</ul>
						</div>
						<div className="info-page">
							<p className=" fs-6 info-title">PRODUCTS</p>
							<ul>
								<li>Model Creation Suite</li>
								<li>UBM Software</li>
								<li>Online App Store</li>
								<li>Management Reporting Application</li>
								<li>Applications</li>
								<li>Universal Reference Manual</li>
								<li>Biz-Mat Policies &amp; Procedures</li>
								<li>Internal &amp; External Developers Section</li>
							</ul>
						</div>
					</div>
					<div className="col-sm-6">
						<div>
							<p className=" fs-6 info-title">SERVICES</p>
							<ul>
								<li>Assist in developing, reviewing, and operating business models</li>
								<li>Assist in developing, reviewing, and operating management reporting packets</li>
								<li>Assist in developing, reviewing, and operating applications</li>
								<li>Consulting Services- meetings, phone calls, emails, text messages</li>
								<li>9:00 AM to 5:00 PM Mountain Standard Time, Monday to Friday</li>
								<li>Business Model Hosting</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
