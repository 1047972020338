import { useState, useEffect, useRef } from "react";
import AppSearchBar from "../AppSearchBar/AppSearchBar";
import RecursiveTreeView from "./Tree";
import { uuidToAncestors } from "../../utils/TreeUtils";
import { Multiselect } from "multiselect-react-dropdown";
import "./BasciTreeWithSearch.scss";

/**
 * Combines our searching component along with our base mfi tree
 * @param {data, treeTitle}
 * @constructor
 */
const BasicTreeWithSearch = ({
	data,
	treeTitle,
	rowSelected,
	treeHeight,
	showVersions = false,
	selectedRows = [],
	treeProps,
	searchMfiForObjects = false,
	rowButtons,
	rowButtonsForObjects,
	changeRequestSources = [],
}) => {
	//state variables
	const [filteredData, setFilteredData] = useState([]);
	// const [filterBySources, setFilterBySoures] = useState(false);

	const highlightIds = useRef([]);
	const searchableAttributes = useRef(["title", "reference", "objectTags"]);
	const descendantToAncestorMap = useRef([]);

	useEffect(() => {
		if (data && data.length > 0) {
			//reset the data
			setFilteredData([]);

			descendantToAncestorMap.current = uuidToAncestors(data);
		}
	}, [data?.length]);

	/**
	 * This function is passed to the search bar component, it's called when the search bar conducts a search
	 * @param matches
	 */
	const filterData = (matches) => {
		if (!matches) {
			highlightIds.current = [];
			setFilteredData([]);
			return;
		}

		//Get all the ancestors of the matches so we can display where they reside in the warehouse
		let filterIds = [];
		matches.forEach((row) => {
			filterIds = [...filterIds, ...descendantToAncestorMap.current[row.uuid]];
			filterIds.push(row.uuid);
		});

		highlightIds.current = matches.map((row) => row.uuid);

		let filteredList = data.filter((row) => filterIds.includes(row.uuid));

		if (matches.length > 0) setFilteredData(filteredList);
		else setFilteredData([{ uuid: "emptyId", reference: "None", title: "No matches" }]);
	};

	const onFilterSelect = (selectedList, selectedItem) => {
		if (selectedItem.value === "affected-by-change-request") {
			let matches = data.filter((row) => changeRequestSources.includes(row.sourceObjectTemplateUuid));
			filterData(matches);
		}
	};

	const onFilterRemove = (selectedList, removedItem) => {
		if (removedItem.value === "affected-by-change-request") filterData();
	};

	return (
		<>
			<div
				style={{
					padding: "0 0px 10px",
					width: "100%",
					height: changeRequestSources?.length > 0 ? "110px" : "65px",
				}}
			>
				<AppSearchBar
					className="search-bar"
					objects={data}
					attributes={searchableAttributes.current}
					parentCallback={filterData}
					searchMfiForObjects={searchMfiForObjects}
				/>
				{changeRequestSources?.length > 0 ? (
					<div className={"multi-select-outer-div d-flex"} style={{ margin: "10px 0px" }}>
						<Multiselect
							placeholder={"Filter Search Results"}
							showCheckbox={true}
							// Options to display in the dropdown
							options={[
								{
									name: "Objects Affected by Change Request",
									value: "affected-by-change-request",
									id: 1,
								},
							]}
							selectedValues={[]} // Preselected value to persist in dropdown
							onSelect={onFilterSelect} // Function will trigger on select event
							onRemove={onFilterRemove} // Function will trigger on remove event
							displayValue="name" // Property name to display in the dropdown options
						/>
					</div>
				) : (
					""
				)}
			</div>
			<div style={{ height: "calc(100% - 85px" }}>
				<RecursiveTreeView
					data={data}
					treeTitle={treeTitle}
					droppable={false}
					draggable={false}
					className={""}
					filteredList={filteredData.length > 0 ? filteredData : undefined}
					highlightIds={highlightIds.current}
					rowSelected={rowSelected}
					// allowSmallRef={false}
					showVersions={showVersions}
					borderIds={selectedRows.map((row) => row.uuid)}
					treeHeight={treeHeight}
					rowButtons={rowButtons}
					rowButtonsForObjects={rowButtonsForObjects}
					{...treeProps}
				/>
			</div>
		</>
	);
};

export default BasicTreeWithSearch;
