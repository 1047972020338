import React, { Component } from "react";
import InlineEditableElement from "../../components/InlineEditableElement/InlineEditableElement";
import Grid from "@mui/material/Grid";

export default class RightHeader extends Component {
	constructor(props) {
		super();

		this.state = {
			changed: false,
			rightHeader: props.rightHeader,
			title: props.title,
		};
	}
	render() {
		const { rightHeader } = this.props;
		const { pageNumber } = this.props;

		return (
			<div style={{ textAlign: "right" }}>
				<Grid container direction={"column"}>
					<Grid item>
						<span className="header-label">Pkt Ref:</span>
						<span className="red">
							<InlineEditableElement text={rightHeader.objectPacketReference} type="text">
								<input
									type="text"
									name="objectPacketReference"
									value={rightHeader.objectPacketReference}
									onChange={(e) => {
										const { name, value } = e.target;
										rightHeader.objectPacketReference === value ||
											this.props.updateHeader("rightHeader", name, value);
									}}
									//Whenever the input loses focus call the method updating the state.
									onBlur={(e) => {
										const { name, value } = e.target;
										rightHeader.objectPacketReference === value ||
											this.props.updateHeader("rightHeader", name, value);
									}}
									autoFocus
								/>
							</InlineEditableElement>
						</span>
					</Grid>
					<Grid item>
						{pageNumber.current}
						<span className="red"> of </span>
						{pageNumber.total}
					</Grid>
				</Grid>
			</div>
		);
	}
}
