import React, { useState, useEffect, forwardRef } from "react";
import { InfoIcon, TogglesIcon } from "../../BootstrapComponents/Icons/Icons";
import ReactGrid from "../../ReactGrid/ReactGrid";
import VerticalMenu from "../VerticalMenu/VerticalMenu";
import { useDispatch, useTrackedState } from "../../../utils/store";
import { buildPanelLocation, getSubPanel, buildPanelContent, PANEL_CONTENT_TYPES } from "../../../utils/PanelTypes";
import { useMeasure } from "react-use";
import { Tooltip } from "react-tooltip";

/**
 * Renders a panel
 * @param
 * @constructor
 */
const Panel = forwardRef(
	(
		{
			panelTitle = "",
			panelContentTitle,
			hoverPanelTitle = panelTitle,
			menuItems,
			quickActionItems,
			bodyStyles = {},
			panelBody,
			children,
			panelStockNumber,
			panelStockNumberStyles = {},
			panelId,
			panelGroup = "",
			className,
			style,
			panelToolbarButtons,
			...other
		},
		ref
	) => {
		//state variables
		const [panelRef, { width }] = useMeasure();
		const sharedState = useTrackedState();
		const [render, setRender] = useState(false);
		//Other Methods

		useEffect(() => {
			setRender((prev) => !prev);
		}, [sharedState.layouts[panelId]?.subPanels?.length]);

		return (
			<div
				ref={panelRef}
				className={"panelElement"}
				style={{
					backgroundColor: "#FFFFFF",
					borderRadius: "4px",
					fontFamily: "Helvetica, Arial, sans-serif",
					height: "100%",
					transition: "box-shadow 300ms cubic-bezier(.4, 0, 0.2, 1) 0ms",
					width: "100%",
					border: "1px solid rgba(45, 69, 95, 0.29)",
					boxShadow: "rgb(45 69 95 / 10%) 0px 0px 25px 0px",
					boxSizing: "border-box",
					...style,
				}}
				{...other}
			>
				<div
					className={`panelHeader ${panelGroup} ${width > window.screen.width ? "extendedPanelHeader" : ""} `}
					style={{
						fontFamily: "Helvetica, Arial, sans-serif",
						textAlign: "center",
						width: "100%",
						minHeight: "31px",
						height: "fit-content",
					}}
					title={width > window.screen.width ? "This panel may extend multiple screens" : undefined}
				>
					<div style={{ display: "flex", float: "left" }}>
						{/* Left */}
						{menuItems && menuItems.length > 0 && (
							<VerticalMenu menuItems={menuItems} subMenuStyle={{ boxShadow: "0px 0px 15px #b5b5b5" }} />
						)}
						{panelStockNumber &&
							(width < 700 ? (
								<>
									<span data-tooltip-id={`${panelId}-tooltipAnchor`} style={{ paddingTop: "5px" }}>
										<InfoIcon color={"red"} />
									</span>
									<Tooltip
										id={`${panelId}-tooltipAnchor`}
										place="top"
										style={{
											backgroundColor: "rgb(235, 236, 236)",
											boxShadow: "-3px 3px 10px black",
										}}
									>
										{panelStockNumber}
									</Tooltip>
								</>
							) : (
								<span
									style={{
										paddingTop: "5px",
										marginLeft: "5px",
										marginRight: "5px",
										...panelStockNumberStyles,
									}}
								>
									{panelStockNumber}
								</span>
							))}

						{quickActionItems?.length > 0 ? (
							<div className="quickActions" title="Quick Actions">
								{quickActionItems.map((quickActionItem) => {
									return (
										<button
											onClick={quickActionItem.onClick}
											className={`${quickActionItem.className} btn ${
												quickActionItem?.disabled ? "disabled" : ""
											}`}
											title={quickActionItem.title}
											key={quickActionItem.id}
											style={{
												border: "none",
												padding: "5px",
												borderRadius: "0px",
												opacity: quickActionItem.disabled ? "0.3" : "inherit",
											}}
										>
											{quickActionItem.icon}
										</button>
									);
								})}
							</div>
						) : (
							""
						)}
					</div>
					{/* This width is dependant on the size of the reference stuff, we want this number to be as large as possible without overlapping with the reference stuff */}
					<div style={{ width: "78%", margin: "0 auto" }}>
						{/* Center */}
						<ul className="panelHeaderTitle ">
							{panelTitle ? (
								<li>
									Panel Title:<b>{panelTitle}</b>
								</li>
							) : (
								""
							)}
							{panelContentTitle ? (
								<li>
									Object Title:<b style={{ fontSize: "18px" }}>{panelContentTitle}</b>
								</li>
							) : (
								""
							)}
						</ul>
					</div>
				</div>
				<div className="panelBody" style={{ padding: "10px 5px 5px 5px", ...bodyStyles }} ref={ref}>
					{panelToolbarButtons?.length > 0 ? (
						<div className="toolbarActions" style={{ height: "50px" }}>
							{panelToolbarButtons.map((toolbarButton) => (
								<button
									onClick={toolbarButton.onClick}
									className={`btn btn-outline-primary mr-1`}
									title={toolbarButton.title}
									key={toolbarButton.id}
									style={{
										marginRight: "5px",
									}}
								>
									{toolbarButton.icon}
								</button>
							))}
						</div>
					) : (
						""
					)}
					{panelBody ? panelBody : children}
				</div>
			</div>
		);
	}
);

export default Panel;

export const DynamicPanel = ({ panelId, panelGroup, menuItems, allowResizeToggle = false, ...other }) => {
	const [layout, setLayout] = useState([]);
	const [subPanelGroup, setSubPanelGroup] = useState("");
	const [allowResizing, setAllowResizing] = useState(false);

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		let { subPanels } = sharedState.layouts[panelId];
		if (!subPanels) {
			return;
		}
		let subPanelGroup = "panelGroup";
		setLayout(
			subPanels.map((subPanelKey) => {
				let { subPanel, subPanelProps } = getSubPanel(subPanelKey, sharedState.layouts);
				subPanelGroup += subPanel.id;
				if (subPanel) return buildPanelLocation(subPanel.panelKey, subPanel.id, { ...subPanelProps });
			})
		);
		setSubPanelGroup(subPanelGroup);
	}, [sharedState.layouts[panelId]?.subPanels?.length]);

	return (
		<Panel
			panelId={panelId}
			panelGroup={panelGroup}
			menuItems={
				allowResizeToggle
					? [
							...menuItems,
							{
								id: "generic-panel-toggle-resizing",
								label: "Toggle Resizing",
								icon: <TogglesIcon />,
								onClick: () => setAllowResizing((prev) => !prev),
							},
					  ]
					: menuItems
			}
			{...other}
		>
			{sharedState.layouts[panelId]?.subPanels?.length > 0 ? (
				<ReactGrid
					layoutId={panelId}
					layout={layout}
					dynamicHeight={true}
					allowResize={allowResizing}
					draggableHandle={`.${subPanelGroup}`}
					draggable={allowResizing}
				>
					{sharedState.layouts[panelId]?.subPanels?.map((subPanelKey) => {
						let { subPanel } = getSubPanel(subPanelKey, sharedState.layouts);
						let passAllowResize =
							allowResizing && PANEL_CONTENT_TYPES[subPanel.panelKey].passAllowResizingToChildren;
						return buildPanelContent(
							{ ...subPanel, data: { ...subPanel.data, allowResizing: passAllowResize, subPanel } },
							sharedState.layouts,
							subPanelGroup,
							sharedState,
							dispatch
						);
					})}
				</ReactGrid>
			) : (
				other.children
			)}
		</Panel>
	);
};
