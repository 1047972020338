import React, { useEffect, useState } from "react";
import DraggableDialog from "../../components/Dialog/DraggableDialog/Dialog";
import TextField from "@mui/material/TextField";
import FreeSoloCreateOptionDialog from "../../components/Autocomplete/FreeSoloCreateOptionDialog";
import Link from "@mui/material/Link";
import { getCall, getUrl } from "../../utils/ApiUtils";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useTrackedState } from "../../utils/store";

/*TODO Need to update the data in the database with actual values
Point the urls in the APIUtils back to the online server
? Merge the stock-number service into the mfi-service
Fix the issues where there is already a stock number in the field (The previous field values aren't there, so when I go back to the stock number field it is reset to -...)
? Possibly move the call for the stock number dropdowns, so it doesn't need to be called as much
May need to give the user more help, such as a short explanation
May need to update the object classification to be an expanding accordion to better view the structure of it (Wait till the actual values are in the database)
 */

export default function StockNumber({
	step,
	stockNumber: pStockNumber,
	newVolumes: pNewVolumes,
	newObjectClasses: pNewObjectClasses,
	newLanguageFrameworks: pNewLanguageFrameworks,
	handleClose: pHandleClose,
	handleSave: pHandleSave,
	showDialog,
}) {
	//If stock number already has an id use it, otherwise generate a new one
	const [uuid, setUuid] = useState(pStockNumber ? pStockNumber.uuid || uuidv4() : uuidv4());
	//If there is already an NAICS Code and Instance Type Code use it, if not set it to an empty string
	const [naicsCode, setNaicsCode] = useState(pStockNumber ? pStockNumber.naicsCode || "" : "");
	const [instanceTypeCode, setInstanceTypeCode] = useState(pStockNumber ? pStockNumber.instanceTypeCode || "" : "");
	//If the stockNumber has a volumeNumber use the
	const [volume, setVolume] = useState(
		pStockNumber
			? pStockNumber.volumeNumber || pStockNumber.volume || { code: "", name: "" }
			: { code: "", name: "" }
	);
	const [objectClass, setObjectClass] = useState(
		pStockNumber
			? pStockNumber.objectClassCode || pStockNumber.objectClass || { code: "", name: "" }
			: { code: "", name: "" }
	);
	const [languageFramework, setLanguageFramework] = useState(
		pStockNumber
			? pStockNumber.languageFrameworkCode || pStockNumber.languageFramework || { code: "", name: "" }
			: { code: "", name: "" }
	);
	const [stockNumber, setStockNumber] = useState(pStockNumber);
	const [volumes, setVolumes] = useState([]);
	const [objectClasses, setObjectClasses] = useState([]);
	const [languageFrameworks, setLanguageFrameworks] = useState([]);
	const [newVolumes, setNewVolumes] = useState([]);
	const [newObjectClasses, setNewObjectClasses] = useState([]);
	const [newLanguageFrameworks, setNewLanguageFrameworks] = useState([]);

	//Global state used to store the existing objects that can be shared across components
	const dispatch = useDispatch();
	const sharedState = useTrackedState();
	// const [sharedState, setSharedState] = useSharedState();

	//Update the global states shared objects that is used across multiple components
	// const updateSharedState =  ( {volumes, objectClasses, languageFrameworks} ) => {
	//     if(volumes)
	//         setSharedState(prev => ( { ...prev, volumes} ));
	//     if(objectClasses)
	//         setSharedState(prev => ( { ...prev, objectClasses} ));
	//     if(languageFrameworks)
	//         setSharedState(prev => ( { ...prev, languageFrameworks} ));
	// };

	useEffect(() => {
		let isMounted = true;

		async function getVolumes() {
			let volumes = await getCall(getUrl("getVolumes"));
			if (isMounted) {
				updateVolumes(volumes);
			}
		}

		async function getObjectClasses() {
			let objectClasses = await getCall(getUrl("getObjectClasses"));
			if (isMounted) {
				updateObjectClasses(objectClasses);
			}
		}

		async function getLanguageFrameworks() {
			let languageFrameworks = await getCall(getUrl("getLanguageFrameworks"));
			if (isMounted) {
				updateLanguageFrameworks(languageFrameworks);
			}
		}

		if (sharedState.volumes.length < 1) getVolumes();
		else updateVolumes(sharedState.volumes);

		if (sharedState.objectClasses.length < 1) getObjectClasses();
		else updateObjectClasses(sharedState.objectClasses);

		if (sharedState.languageFrameworks.length < 1) getLanguageFrameworks();
		else updateLanguageFrameworks(sharedState.languageFrameworks);

		//Doesn't try to update the state if the component is no longer mounted
		return () => (isMounted = false);
	}, []);

	const updateVolumes = (volumes) => {
		if (pNewVolumes && Object.keys(pNewVolumes).length !== 0 && pNewVolumes.constructor === Object)
			volumes = Object.entries(newVolumes)
				.map((vol) => vol[1])
				.concat(volumes);
		setVolumes(volumes);
		if (volumes.length > 0 && volume && typeof volume !== "object")
			setVolume(volumes.find((o) => o.code === volume));

		dispatch({ type: "SET_VOLUMES", data: volumes });
		// updateSharedState({volumes});
	};

	const updateObjectClasses = (objectClasses) => {
		if (
			pNewObjectClasses &&
			Object.keys(pNewObjectClasses).length !== 0 &&
			pNewObjectClasses.constructor === Object
		)
			objectClasses = Object.entries(newObjectClasses)
				.map((e) => e[1])
				.concat(objectClasses);
		setObjectClasses(objectClasses);
		if (objectClasses.length > 0 && objectClass && typeof objectClass !== "object")
			setObjectClass(objectClasses.find((o) => o.code === objectClass));

		dispatch({ type: "SET_OBJECT_CLASSES", data: objectClasses });
		// updateSharedState({objectClasses});
	};

	const updateLanguageFrameworks = (languageFrameworks) => {
		if (
			pNewLanguageFrameworks &&
			Object.keys(pNewLanguageFrameworks).length !== 0 &&
			pNewLanguageFrameworks.constructor === Object
		)
			languageFrameworks = Object.entries(pNewLanguageFrameworks)
				.map((e) => e[1])
				.concat(languageFrameworks);
		setLanguageFrameworks(languageFrameworks);
		if (languageFrameworks.length > 0 && languageFramework && typeof languageFramework !== "object")
			setLanguageFramework(languageFrameworks.find((o) => o.code === languageFramework));

		dispatch({ type: "SET_LANGUAGE_FRAMEWORKS", data: languageFrameworks });
		// updateSharedState({languageFrameworks});
	};

	/**
	 * Called on close of the dialog, just resets the state variables to the props passed in.
	 **/
	const resetFieldToState = () => {
		// setStockNumber(pStockNumber);

		if (!pStockNumber || !pStockNumber.uuid) return;

		if (pStockNumber.volume || pStockNumber.volumeNumber)
			setVolume(pStockNumber.volume || pStockNumber.volumeNumber || { code: "", name: "" });
		if (pStockNumber.objectClass || pStockNumber.objectClassCode)
			setObjectClass(pStockNumber.objectClass || pStockNumber.objectClassCode || { code: "", name: "" });
		if (pStockNumber.languageFramework || pStockNumber.languageFrameworkCode)
			setLanguageFramework(
				pStockNumber.languageFramework || pStockNumber.languageFrameworkCode || { code: "", name: "" }
			);
		setNaicsCode(pStockNumber.naicsCode || "");
		setInstanceTypeCode(pStockNumber.instanceTypeCode || "");
	};

	const resetState = () => {
		// setStockNumber(pStockNumber);

		setUuid(uuidv4());
		setStockNumber();
		setVolume("");
		setObjectClass("");
		setLanguageFramework("");
		setNaicsCode("");
		setInstanceTypeCode("");
	};

	useEffect(() => {
		if (pStockNumber) {
			setStockNumber(pStockNumber.stockNumber);
			setUuid(pStockNumber.uuid || uuidv4());
			setNaicsCode(pStockNumber.naicsCode || "");
			setInstanceTypeCode(pStockNumber.instanceTypeCode || "");
			setVolume(pStockNumber.volume || pStockNumber.volumeNumber || { code: "", name: "" });
			setObjectClass(pStockNumber.objectClass || pStockNumber.objectClassCode || { code: "", name: "" });
			setLanguageFramework(
				pStockNumber.languageFramework || pStockNumber.languageFrameworkCode || { code: "", name: "" }
			);
		}
	}, [pStockNumber]);

	useEffect(() => {
		//Checks for an existing object, if it exists check if there's a code field, use if if there is
		//Else verify volume isn't null/undefined/'' and the volume isn't a javascript object ({}) if it checks out set it to volume
		//else set it to ''
		var stockNo =
			((volume && volume.code) || (volume && typeof volume != "object" ? volume : "")) +
			"-" +
			(naicsCode || "") +
			"." +
			((objectClass && objectClass.code) || (objectClass && typeof objectClass != "object" ? objectClass : "")) +
			"." +
			(instanceTypeCode || "") +
			"." +
			((languageFramework && languageFramework.code) ||
				(languageFramework && typeof languageFramework != "object" ? languageFramework : ""));

		setStockNumber(stockNo === "-..." ? "" : stockNo);
	}, [volume, naicsCode, objectClass, instanceTypeCode, languageFramework]);

	// const changeStepper = (step) => {
	//     setCurrentStep(step);
	//     updateStockNumber();
	// };

	const handleSave = () => {
		//If the stocknumber is empty and they try to save, just return
		if (
			!volume.code &&
			!volume.name &&
			!naicsCode &&
			!objectClass.code &&
			!objectClass.name &&
			!instanceTypeCode &&
			!languageFramework.code &&
			!languageFramework.name
		) {
			pHandleClose();
			return;
		}
		pHandleSave(
			{ uuid: uuid, volume, naicsCode, objectClass, instanceTypeCode, languageFramework, stockNumber },
			newVolumes,
			newObjectClasses,
			newLanguageFrameworks
		);
		resetState();
	};

	const handleClose = () => {
		resetFieldToState();
		pHandleClose();
	};

	const addVolume = (volume) => {
		setNewVolumes(newVolumes.concat(volume));
		setVolumes([volume, ...volumes]);
	};
	const addObjectClass = (objectClass) => {
		setNewObjectClasses(newObjectClasses.concat(objectClass));
		setObjectClasses([objectClass, ...objectClasses]);
	};
	const addLanguageFramework = (languageFramework) => {
		setNewLanguageFrameworks(newLanguageFrameworks.concat(languageFramework));
		setLanguageFrameworks([languageFramework, ...languageFrameworks]);
	};

	return (
		<div>
			<DraggableDialog
				header={"Stock Number"}
				handleSave={handleSave}
				handleClose={handleClose}
				showDialog={showDialog}
				size="small"
				contentStyles={{ padding: "24px" }}
			>
				{/*{getStepContent(currentStep)}*/}
				<form className={""} noValidate autoComplete="off">
					<span>What volume does this object belong to?</span>
					<FreeSoloCreateOptionDialog
						id="volume"
						label="UBM Volume"
						codeField="Volume Number"
						options={volumes}
						message="If your volume isn't in the list, please add it"
						value={volume}
						setState={(state) => {
							setVolume(state);
						}}
						addItem={addVolume}
					/>
					<Link href="https://www.naics.com/search/" target="_blank" rel="noreferrer">
						NAICS Code Search
					</Link>
					<br />
					<TextField
						id="naics-code"
						label="NAICS Code"
						type="number"
						variant="outlined"
						value={naicsCode}
						InputProps={{ inputProps: { min: 0, max: 999999 } }}
						error={naicsCode < 0 || naicsCode > 999999}
						helperText={naicsCode < 0 || naicsCode > 999999 ? "This must be a 1-6 digit number" : ""}
						onChange={(e) => {
							setNaicsCode(e.target.value);
						}}
					/>
					<FreeSoloCreateOptionDialog
						id="object-classification"
						label="Object Class"
						codeField="Object Class Code"
						codeFieldType="text"
						options={objectClasses}
						value={objectClass}
						setState={(state) => {
							setObjectClass(state);
						}}
						addItem={addObjectClass}
					/>
					<TextField
						id="instance-type-code"
						label="Instance Type Code"
						variant="outlined"
						value={instanceTypeCode || ""}
						onChange={(e) => {
							setInstanceTypeCode(e.target.value);
						}}
					/>
					<FreeSoloCreateOptionDialog
						id="language-framework"
						label="Language / Framework"
						codeField="Language / Framework Code"
						options={languageFrameworks}
						title=""
						message=""
						value={languageFramework}
						setState={(state) => {
							setLanguageFramework(state);
						}}
						addItem={addLanguageFramework}
					/>

					<h3 id="stock-number">
						Stock Number: <br />
						{stockNumber}
					</h3>
					{/*<TextField*/}
					{/*    id="stock-number"*/}
					{/*    label="Stock Number"*/}
					{/*    variant="outlined"*/}
					{/*    value={stockNumber}*/}
					{/*/>*/}
				</form>
				{/*<ProgressMobileStepper steps={6} changeStepper={changeStepper} step={currentStep}/>*/}
			</DraggableDialog>
		</div>
	);
}
