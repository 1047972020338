import React from "react";

/**
 * Manages the individual permissions of an object
 * @param { permissions, checked, ...other}
 * @constructor
 */
const Permissions = ({ permissions, onChange, ...other }) => {
	return (
		<div className="row">
			{permissions.map((permission) => {
				return (
					<div className="col" key={permission.uuid}>
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								id={permission.uuid + "-" + permission.title}
								onClick={onChange}
								checked={Math.random() < 0.5}
							/>
							<label className="form-check-label" htmlFor={permission.uuid + "-" + permission.title}>
								{permission.title}
							</label>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Permissions;
