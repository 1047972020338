import { createPortal } from "react-dom";
import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
import DrawableCanvas from "./DrawableCanvas";
import { useDispatch } from "../../utils/store";

const Feedback = ({ setShowFeedback }) => {
	const dispatch = useDispatch();
	const [canvasOpen, setCanvasOpen] = useState(false);
	const [canvas, setCanvas] = useState();

	useEffect(() => {
		captureScreenshot();
	}, []);

	const captureScreenshot = () => {
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
		html2canvas(document.querySelector("#root")).then(function (canvas) {
			setCanvas(canvas.toDataURL("image/png"));
			setCanvasOpen(true);
		});
	};

	const closeFeedback = () => {
		setCanvasOpen(false);
		setShowFeedback(false);
	};

	return createPortal(
		<div
			style={{
				display: !canvasOpen && "none",
				position: "fixed",
				top: 0,
				left: 0,
				width: "100vw",
				height: "100vh",
				zIndex: 2,
			}}
		>
			{canvasOpen && <DrawableCanvas closeCanvas={closeFeedback} imgSrc={canvas} />}
		</div>,
		document.getElementById("root")
	);
};

export default Feedback;
