import { Outlet } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import AppHeader from "../components/Headers/AppHeader";
import { useTrackedState } from "../utils/store";
import { useEffect, useState } from "react";
import { Popup } from "../utils/UserMessage";

const ApplicationLayout = () => {
	const sharedState = useTrackedState();
	const [showPopup, setShowPopup] = useState(false);
	useEffect(() => {
		if (sharedState.message.show) setShowPopup(true);
	}, [sharedState.message, sharedState.message?.content, sharedState.message?.show]);

	return (
		<>
			<AppHeader />
			<Outlet />
			<Loader show={sharedState.showLoadingBar} />
			{showPopup && (
				<Popup
					state={sharedState.message?.level}
					message={sharedState.message?.content}
					onClose={() => setShowPopup(false)}
					prompt={sharedState.message.prompt}
				/>
			)}
		</>
	);
};

export default ApplicationLayout;
