import { useEffect, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import "./Bootstrap.scss";
import "./App.scss";
import { initDB } from "@slnsw/react-indexed-db";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch } from "../utils/store";
import { RequireAuth, isAuthenticated, getUserInfo } from "../utils/Authentication.js";
import SignIn from "./Login/SignIn";
import SignUp from "./Login/SignUp";
import ForgotPassword from "./Login/ForgotPassword.js";
import About from "./About/About";
import SupportPage from "./SupportPage/SupportPage";
import { DBConfig } from "../utils/DBConfig";
import MasterFileIndex from "../components/MasterFileIndex/MasterFileIndex";
import PortalPage from "../components/Portal/PortalPage";
import UsersPage from "./Users/UsersPage";
import UserProfilePage from "./Users/UserProfilePage";
import EnvironmentsPage from "./Environment/EnvironmentsPage";
import EnvironmentPage from "./Environment/EnvironmentPage";
import ObjectWorkspaceTest from "./ObjectWorkspaceTest/ObjectWorkspaceTest";
import ApplicationLayout from "../layouts/ApplicationLayout";
import LandingPageLayout from "../layouts/LandingPageLayout";
import LandingPage from "./LandingPage/LandingPage";
import PricingPage from "./Pricing/PricingPage";
import ProductsPage from "./Products/ProductsPage";
import ResourcesPage from "./Resources/ResourcesPage";
import SolutionsPage from "./Solutions/SolutionsPage";
import ActivateAccount from "./Login/ActivateAccount";

initDB(DBConfig);

function App() {
	// const auth = new Authentication();

	const [loggedIn, setLoggedIn] = useState(isAuthenticated());
	// Global state used to store the existing objects that can be shared across components
	const dispatch = useDispatch();

	useEffect(() => {
		if (!loggedIn) {
			dispatch({ type: "SET_USER", data: undefined });
			return;
		}

		//Get the email from the cookie
		//Get the user info based on the email
		getUserInfo().then((userInfo) => {
			dispatch({ type: "SET_USER", data: userInfo });
		});
	}, [loggedIn]);

	return (
		<Router>
			<Routes>
				<Route element={<ApplicationLayout />}>
					<Route
						path="/mfi/:uuid"
						element={
							<RequireAuth redirectTo="/login">
								<MasterFileIndex />
							</RequireAuth>
						}
					/>
					<Route
						path="/mfi"
						element={
							<RequireAuth redirectTo="/login">
								<MasterFileIndex />
							</RequireAuth>
						}
					/>
					<Route
						path="/object-workspace/:uuid"
						element={
							<RequireAuth redirectTo="/login">
								<ObjectWorkspaceTest />
							</RequireAuth>
						}
					/>
					<Route
						path="/object-workspace"
						element={
							<RequireAuth redirectTo="/login">
								<ObjectWorkspaceTest />
							</RequireAuth>
						}
					/>
					<Route
						path="/dashboard"
						element={
							<RequireAuth redirectTo="/login">
								<PortalPage />
							</RequireAuth>
						}
					/>
					<Route
						path="/users"
						element={
							<RequireAuth redirectTo="/login">
								<UsersPage />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile"
						element={
							<RequireAuth redirectTo="/login">
								<UserProfilePage />
							</RequireAuth>
						}
					/>
					<Route
						path="/environments"
						element={
							<RequireAuth redirectTo="/login">
								<EnvironmentsPage />
							</RequireAuth>
						}
					/>
					<Route
						path="/environment"
						element={
							<RequireAuth redirectTo="/login">
								<EnvironmentPage />
							</RequireAuth>
						}
					/>
					<Route path="/login" element={<SignIn setLoggedIn={setLoggedIn} />} />
					<Route path="/activate" element={<ActivateAccount />} />
					<Route path="/register" element={<SignUp setLoggedIn={setLoggedIn} />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
				</Route>
				<Route element={<LandingPageLayout />}>
					<Route path="/" element={<LandingPage />} />
					<Route path="/about" element={<About />} />
					<Route path="/support" element={<SupportPage />} />
					<Route path="/pricing" element={<PricingPage />} />
					<Route path="/products" element={<ProductsPage />} />
					<Route path="/resources" element={<ResourcesPage />} />
					<Route path="/solutions" element={<SolutionsPage />} />
				</Route>
			</Routes>
		</Router>
	);
}

export default App;
