import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { register as signUp } from "../../utils/Authentication";
import { Link, Navigate } from "react-router-dom";
import { useLocation } from "react-router";
import Copyright from "./Copyright";
import stylesModule from "./Login.module.scss";

export default function SignUp(props) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [redirectToReferrer, setRedirectToReferrer] = useState(false);
	const { state } = useLocation();
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		document.title = "Sign Up";
	}, []);

	const register = (event) => {
		event.preventDefault();
		signUp(email, password, firstName, lastName)
			.then((result) => {
				setRedirectToReferrer(true);
				props.setLoggedIn(true);
			})
			.catch((e) => {
				setErrorMessage(e.message);
			});
	};

	if (redirectToReferrer === true) {
		return <Navigate replace to={state?.from || "/object-workspace"} />;
	}

	return (
		<div className="container-fluid">
			<div className="loginBox ">
				<Avatar className={`${stylesModule.logo}`}>
					<LockOutlinedIcon />
				</Avatar>
				<h3 className="text-center">Automated UBM Software</h3>
				<hr />
				<h3 className="text-center">Setup a New Account</h3>
				<form className={stylesModule.form}>
					<div className="mb-3">
						<label htmlFor="email" className="form-label">
							<strong>First Name</strong>
						</label>
						<input
							type="email"
							className="form-control"
							id="email"
							autoFocus
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="email" className="form-label">
							<strong>Last Name</strong>
						</label>
						<input
							type="email"
							className="form-control"
							id="email"
							autoFocus
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="email" className="form-label">
							<strong>Email address</strong>
						</label>
						<input
							type="email"
							className="form-control"
							id="email"
							autoFocus
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="password" className="form-label">
							<strong>Password</strong>
						</label>
						<input
							type="password"
							className="form-control"
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div className="text-red">{errorMessage}</div>
					<button type="submit" className={`btn btn-primary ${stylesModule.submit}`} onClick={register}>
						Sign Up
					</button>

					<Grid container mt={2}>
						<Grid item>
							<Link to="/login">Already have an account? Sign In</Link>
						</Grid>
					</Grid>
				</form>
				<Box mt={2}>
					<Copyright />
				</Box>
			</div>
		</div>
	);
}
