import React, { useRef, useState } from "react";
import { useTrackedState } from "../../utils/store";
import { CheckboxTreeDialog } from "../../components/Tree/Tree";
import { updateChangeData } from "../../components/ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { getTopMostObject } from "../../components/ReactGridComponents/Body/CreatorPanel/CreatorPanel";

const filterTreeText = "filter-tree-check";
/**
 * The Dialog used for creating filtered versions of our objects
 * @param {show = false}
 * @constructor
 */
export const FilterDialog = ({ show = false, handleClose: _handleClose, handleSave }) => {
	const [filteredMfi, setFilteredMfi] = useState([]);
	const sharedState = useTrackedState();

	const removeFilterTreeTextFromMfi = (mfi) => {
		//Remove the filterTreeText from each row
		mfi.forEach((row) => {
			if (row.setupSheets?.includes(filterTreeText))
				row.setupSheets = row.setupSheets.filter((ss) => ss !== filterTreeText);
			if (row.indeterminateSetupSheets?.includes(filterTreeText))
				row.indeterminateSetupSheets = row.indeterminateSetupSheets.filter((ss) => ss !== filterTreeText);
		});
	};

	/**
	 * When a checkbox is toggled what do we do? We need to go through and add or remove that row and its descendants from the tree
	 * @param row
	 * @param checkbox
	 * @param checked
	 */
	const checkboxToggled = (row, checkbox, checked) => {
		let { contextMfi: mfi } = sharedState;
		let descendants = mfi.filter((item) => item.reference.startsWith(row.reference) && item.uuid !== row.uuid);
		let ancestors = mfi.filter((item) => row.ancestors?.includes(item.uuid));
		if (checked) {
			descendants.forEach((desc) => {
				let setupSheets = [];
				if (desc.setupSheets && desc.setupSheets.length > 0) {
					setupSheets = [...new Set([...desc.setupSheets, filterTreeText])];
				} else {
					setupSheets = [filterTreeText];
				}
				//Parse it so we don't have a proxy
				setupSheets = JSON.parse(JSON.stringify(setupSheets));

				desc.setupSheets = setupSheets;
			});
			ancestors.forEach((row) => {
				let updated = [];
				if (row.indeterminateSetupSheets && row.indeterminateSetupSheets.length > 0)
					updated = [...new Set([...row.indeterminateSetupSheets, filterTreeText])];
				else updated = [filterTreeText];

				//For some reason its a proxy?
				updated = JSON.parse(JSON.stringify(updated));
				row.indeterminateSetupSheets = updated;
			});

			let updatedSetupSheets = [];
			if (row.setupSheets && row.setupSheets.length > 0)
				updatedSetupSheets = [...new Set([...row.setupSheets, filterTreeText])];
			else updatedSetupSheets.push(filterTreeText);

			//Update the row
			row.setupSheets = JSON.parse(JSON.stringify(updatedSetupSheets));
		} else {
			descendants.forEach((desc) => {
				let setupSheets = [];
				if (desc.setupSheets && desc.setupSheets.length > 0) {
					setupSheets = desc.setupSheets.filter((sheet) => sheet !== filterTreeText);
				}
				desc.setupSheets = setupSheets;
			});
			ancestors.forEach((ancestor) => {
				let checkedDescendants = mfi.filter(
					(item) =>
						item.uuid !== row.uuid &&
						item.uuid !== ancestor.uuid &&
						item.reference.startsWith(ancestor.reference) &&
						item.setupSheets?.includes(filterTreeText)
				);
				if (checkedDescendants.length < 1) {
					if (ancestor.indeterminateSetupSheets) {
						ancestor.indeterminateSetupSheets = ancestor.indeterminateSetupSheets.filter(
							(ss) => ss !== filterTreeText
						);
					}
				}
			});

			//Update the row
			if (row.setupSheets) row.setupSheets = row.setupSheets.filter((ss) => ss !== filterTreeText);
			if (row.indeterminateSetupSheets)
				row.indeterminateSetupSheets = row.indeterminateSetupSheets.filter((ss) => ss !== filterTreeText);
		}
		setFilteredMfi(
			mfi.filter(
				(item) =>
					item.setupSheets?.includes(filterTreeText) ||
					item.indeterminateSetupSheets?.includes(filterTreeText)
			)
		);
	};

	const handleClose = () => {
		removeFilterTreeTextFromMfi(sharedState.contextMfi);
		_handleClose();
	};

	const saveFilteredTree = () => {
		_handleClose();
		//Remove the filterTreeText from the setup sheet field
		removeFilterTreeTextFromMfi(filteredMfi);
		//Save the filtered tree
		handleSave(filteredMfi);
	};

	return (
		<CheckboxTreeDialog
			dialogHeader={"Create Filtered Object"}
			show={show}
			top={sharedState.contextTop}
			mfi={sharedState.contextMfi}
			checkboxToggled={checkboxToggled}
			handleSave={saveFilteredTree}
			handleClose={handleClose}
			origin={filterTreeText}
		/>
	);
};
