import React, { useState, useEffect } from "react";
import { DynamicPanel } from "./Panel";
import { getSmallRef } from "../../../utils/StringUtils";
import PanelOverlay from "../../PanelOverlay/PanelOverlay";
import { TopCenterHeader } from "../VisualRepPanel/VisualRepPanel";
import BasicTreeWithSearch from "../../Tree/BasicTreeWithSearch";
import { UBM_MASTER_FILE_INDEX_ITEM } from "../../../utils/StandardObject";
import { getSingleLevelObjectMfi } from "../../../utils/ApiUtils";
import { generate } from "../../../utils/Generator";
import { v4 as uuidv4 } from "uuid";
import { CodeIcon, JournalsIcon, ViewIcon } from "../../BootstrapComponents/Icons/Icons";
import Iframe from "../../Iframe/Iframe";
import { ObjectHeader } from "../Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import EmptyPanelMessage from "./EmptyPanelMessage";

const OBJECT_MASTER_FILE_INDEX_VIEW = "Object Master File Index";

/**
 * Renders the destination model with a visual rep for objects clicked within the destination model. Currently this is the panel on the far left
 * @param
 * @constructor
 */
const ObjectMasterFileIndexPanel = ({
	uuid,
	versionUuid,
	mfi: _mfi = [],
	panelGroup,
	panelId,
	surroundWithPanel = true,
	panelTitle = "Object Master File Index",
	menuItems = [],
	reference,
	referenceType,
	allowResizeToggle,
	origin,
}) => {
	const [top, setTop] = useState({});
	const [mfi, setMfi] = useState([]);
	const [views, setViews] = useState([]);
	const [activeView, setActiveView] = useState(OBJECT_MASTER_FILE_INDEX_VIEW);

	useEffect(() => {
		if (!uuid || !versionUuid) resetPanel();
		if (uuid && versionUuid && top?.uuid !== uuid && top?.versionUuid !== versionUuid) {
			setViews([]);
			setActiveView(OBJECT_MASTER_FILE_INDEX_VIEW);
			updateMfi(uuid, versionUuid);
			generateViews(uuid, versionUuid);
		}
	}, [uuid, versionUuid]);

	useEffect(() => {
		if (_mfi?.length > 0) {
			setMfi(_mfi);
			setTop(_mfi[0]);
		}
	}, [_mfi, _mfi.length]);

	const resetPanel = () => {
		setViews([]);
		setActiveView(OBJECT_MASTER_FILE_INDEX_VIEW);
		setMfi([]);
		setTop({});
	};

	const updateMfi = (uuid, versionUuid) => {
		getSingleLevelObjectMfi(uuid, versionUuid).then((mfi) => {
			setMfi(mfi);
			setTop(mfi[0]);
		});
	};

	const handleDrop = (event) => {
		let dragObj;
		try {
			dragObj = JSON.parse(event.dataTransfer.getData("text"));
		} catch (e) {
			return;
		}

		//Detect if the object is a UBM ROW that we dont have access to
		if (dragObj.origin === "models-panel" && !dragObj[UBM_MASTER_FILE_INDEX_ITEM])
			updateMfi(dragObj.standardObjectUuid, dragObj.standardObjectVersionUuid);
	};

	const generateViews = async (uuid, versionUuid) => {
		let code = await generate(uuid, versionUuid);
		if (code) setViews(code);
	};

	return (
		<PanelOverlay elementId={panelId} getMessage={() => "Drop to load Object MFI"} handleDrop={handleDrop}>
			{surroundWithPanel ? (
				<DynamicPanel
					panelTitle={panelTitle}
					panelContentTitle={top.title}
					panelStockNumber={
						top?.mfiReference ? (
							<ObjectHeader
								objectTitle={""}
								reference={top?.mfiReference}
								referenceType={referenceType}
								stockNumber={top?.stockNo}
								version={{}}
							/>
						) : (
							false
						)
					}
					menuItems={[
						...menuItems,
						{
							id: "object-master-file-index-object-master-file-index-view",
							label: OBJECT_MASTER_FILE_INDEX_VIEW,
							icon: <JournalsIcon />,
							onClick: () => setActiveView("Object Master File Index"),
							className: activeView === "Object Master File Index" ? "active" : "",
						},
						...views.map((view, index) => ({
							id: "object-master-file-index-different-views",
							label: view.name,
							icon:
								view.name === "Rendered" ? (
									<ViewIcon />
								) : view.name === "Object Hierarchy" || view.name === "Source Code MFI" ? (
									<JournalsIcon />
								) : view.name === "Generated Code" ? (
									<CodeIcon />
								) : (
									""
								),
							onClick: () => setActiveView(view.name),
							className: activeView === view.name ? "active" : "",
						})),
					]}
					panelId={panelId}
					panelGroup={panelGroup}
					allowResizeToggle={allowResizeToggle}
				>
					<BasicTreeWithSearch
						data={mfi}
						treeTitle={mfi?.[0]?.title}
						treeProps={{
							droppable: false,
							draggable: true,
							origin: origin || "object-mfi-panel",
							// getMdMfiOnDrop: true,
							// getObjMfiOnDrop: true,
							allowSmallRef: true,
							// showVersions: true,
						}}
						className={activeView === "Object Master File Index" ? "" : "d-none"}
					/>
					{activeView !== OBJECT_MASTER_FILE_INDEX_VIEW ? (
						views.length > 0 ? (
							views.map((view, index) => {
								// Add one to the index because in the iframe component we check if
								// the id exists and 0 is false
								return (
									<div
										style={{
											width: "100%",
											overflowX: "scroll",
											padding: "10px",
										}}
										className={activeView !== view.name ? "d-none" : ""}
									>
										<Iframe
											id={uuidv4()}
											key={index + 1}
											name={index + 1}
											content={view.code}
											className={"presentation-iframe"}
										/>
									</div>
								);
							})
						) : (
							<EmptyPanelMessage message={`No views found for ${top?.title}.`} />
						)
					) : (
						""
					)}
				</DynamicPanel>
			) : (
				<>
					<BasicTreeWithSearch
						data={mfi}
						treeTitle={mfi[0]?.title}
						treeProps={{
							droppable: false,
							// draggable: true,
							origin: "object-mfi-panel",
							// getMdMfiOnDrop: true,
							// getObjMfiOnDrop: true,
							allowSmallRef: true,
							// showVersions: true,
						}}
					/>
				</>
			)}
		</PanelOverlay>
	);
};

export default ObjectMasterFileIndexPanel;
