import { useReducer } from "react";
import { createContainer } from "react-tracked";
import { Views } from "./View";
import { dataObjects } from "./DataObject";
import {
	addObjectHierarchyToChangedMap,
	addRowToChangedObjectMap,
	convertListToMap,
	filterOutSubObjects,
	findClosestPrimusAncestorByMap,
	getAssociatedObjectType,
	getObjectIdAndVersionUuid,
	getPrimusObjectMap,
	isSubObjectAddedToMap,
	UBM_MASTER_FILE_INDEX_ITEM,
} from "./StandardObject";
import { buildNewPanel } from "../components/ReactGridComponents/Body/Body";
import { getTopMostObject } from "../components/ReactGridComponents/Body/CreatorPanel/CreatorPanel";

const initialState = {
	addedReactEditorSuggestions: false,
	addObjectsToPacketDialog: 0,
	attachableTypes: [],
	authenticated: false,
	canRelease: false,
	changedData: {}, //Data Change
	changedFilters: {},
	changedLanguageFrameworks: [],
	changedObjectClasses: [],
	changedOpenPoints: {},
	changedOpenPointUpdates: 0,
	changedStockNumbers: [],
	changeRequestsExist: false,
	changeRequestSelectionDialog: 0,
	changedVolumes: [],
	checkForChanges: false,
	company: "",
	contextAncestorObjects: [],
	contextAttachmentPoints: [],
	contextBreadcrumbs: [],
	contextCurrentRelatedObject: {},
	contextDestinationObject: {},
	contextFullObjectHierarchy: [],
	contextLoadedSubObjects: [],
	contextMfi: [],
	contextMfiVersion: 0,
	contextObjectHierarchy: [],
	contextOpenPoints: [],
	contextPacket: {},
	contextRelatedObjects: [],
	contextRelatedObjectUpdates: [],
	contextTemplateMfi: [],
	contextTemplateRow: {},
	contextTop: {},
	contextVersions: [],
	copyMfi: {},
	dataWarehouse: [],
	dbConstants: {
		objectType: undefined,
	},
	destinationModelObjects: [],
	destinationModels: [],
	destinationModel: [],
	defaultClasses: [],
	environment: {},
	fixPacketsExist: false,
	fullScreenVisualRep: false,
	functions: [],
	generalTypes: [],
	grid: "",
	loadObject: {},
	masterFileIndexTitle: dataObjects.MASTER_FILE_INDEX_ROW.title,
	menuObject: [],
	newPanel: {},
	newPanelContent: {},
	languageFrameworks: [],
	layouts: {
		main: [],
	},
	message: {
		show: 0,
		level: "success",
		content: "",
	},
	objectClasses: [],
	objects: [],
	objectMap: new Map(),
	openChangeRequestForm: 0,
	openChangeRequestPacketSummary: 0,
	openFixPacketSummary: 0,
	openGiveFeedbackDialog: 0,
	openObject: {},
	openObjects: [],
	openObjectComparisonDialog: 0,
	openOpenPointDialog: 0,
	openPropertyWindows: [],
	openReleaseDialog: 0,
	openView: Views("DEFAULT"),
	packetMode: true,
	primusUpdates: [],
	refreshVersionInfo: 0,
	releaseDialog: 0,
	resizePanels: false,
	retrievingStockNumberInfo: false,
	rowToUpdate: {},
	screenObject: [],
	selectedWorkspaceRow: {},
	setupForms: [],
	setupSheetActiveTitle: "",
	setupSheetData: {},
	setupSheetSelectedNodes: [],
	showFullRef: false,
	showLoadingBar: 0,
	showSetupSheets: true,
	showUtilityOverlay: false,
	sourceObject: {},
	standardObjectTitle: dataObjects.STANDARD_OBJECT_GIT.title,
	subObjectUpdates: [],
	taskRowToUpdate: {},
	thereAreChanges: false,
	triggerGenerateCode: false,
	triggerGenerateTemplate: false,
	triggerOpenDebugDialog: 0,
	triggerOpenUpdateDialog: 0,
	updatesAvailable: false,
	userRole: "",
	utility: "",
	volumes: [],
};

const reducer = (state, action) => {
	switch (action.type) {
		case "SET_GENERAL_TYPES":
			return {
				...state,
				generalTypes: action.data,
			};
		case "SET_AUTHENTICATED":
			return {
				...state,
				authenticated: action.data,
			};
		case "SET_USER":
			return {
				...state,
				currentUser: action.data,
			};
		case "SET_FUNCTIONS":
			return {
				...state,
				functions: action.data,
			};
		case "SET_SETUP_FORMS":
			return {
				...state,
				setupForms: action.data,
			};
		case "SET_DB_CONSTANT":
			return {
				...state,
				dbConstants: { ...state.dbConstants, [action.constant]: action.data },
			};
		case "SET_DEFAULT_CLASSES":
			return {
				...state,
				defaultClasses: action.data,
			};
		case "SET_VOLUMES":
			return {
				...state,
				volumes: action.data,
			};
		case "SET_OBJECT_CLASSES":
			return {
				...state,
				objectClasses: action.data,
			};
		case "SET_LANGUAGE_FRAMEWORKS":
			return {
				...state,
				languageFrameworks: action.data,
			};
		case "SET_EXISTING_OBJECTS":
			let list = action.data;
			let map = convertListToMap(list);

			return {
				...state,
				objects: list,
				objectMap: map,
			};
		case "OPEN_OBJECT":
			return {
				...state,
				openObjects: [...state.openObjects, action.data],
			};
		case "CLOSE_OBJECT":
			return {
				...state,
				openObjects: state.openObjects.filter((item) => {
					return item.uuid !== action.data;
				}),
			};
		case "UPDATE_CHANGED_STOCK_NUMBERS":
			let changedStockNumbers = state.changedStockNumbers;

			//Check to see if the stock number is already in the changedStockNumberList
			let stockNumber = changedStockNumbers.find((stockNumber, index) => {
				//If it is update the list to include the new stock number
				if (stockNumber.uuid === action.data.uuid) {
					changedStockNumbers[index] = action.data;
					return true;
				}
				return false;
			});

			//If the stock number wasn't already in the list add it
			if (!stockNumber) {
				changedStockNumbers = [...state.changedStockNumbers, action.data];
			}

			//Update the state with the new changed stock numbers
			return {
				...state,
				changedStockNumbers: changedStockNumbers,
			};
		case "UPDATE_CHANGED_VOLUMES":
			return {
				...state,
				changedVolumes: [...state.changedVolumes, ...action.data],
			};
		case "UPDATE_CHANGED_OBJECT_CLASSES":
			return {
				...state,
				changedObjectClasses: [...state.changedObjectClasses, ...action.data],
			};
		case "UPDATE_CHANGED_LANGUAGE_FRAMEWORKS":
			return {
				...state,
				changedLanguageFrameworks: [...state.changedLanguageFrameworks, ...action.data],
			};
		case "UPDATE_CHANGED_FILTERS":
			state.changedFilters[action.data.uuid] = action.data;
			return {
				...state,
				changedFilters: state.changedFilters,
			};
		case "UPDATE_CHANGED_OPEN_POINTS":
			let { changedOpenPoints, changedOpenPointUpdates } = state;
			changedOpenPoints[action.data.uuid] = action.data;
			changedOpenPointUpdates += 1;
			return {
				...state,
				thereAreChanges: true,
				changedOpenPoints,
				changedOpenPointUpdates,
			};
		case "RESET_STOCK_NUMBER_SAVE_DATA":
			return {
				...state,
				changedStockNumbers: [],
				changedVolumes: [],
				changedObjectClasses: [],
				changedLanguageFrameworks: [],
			};
		case "TOGGLE_SHOW_FULL_REF":
			return {
				...state,
				showFullRef: !state.showFullRef,
			};
		case "TOGGLE_CHANGES":
			let val;
			if (action.data !== undefined) val = action.data;
			else val = !state.thereAreChanges;

			if (state.thereAreChanges === val) return state;
			return {
				...state,
				thereAreChanges: val,
			};
		case "TOGGLE_SHOW_SETUP_SHEETS":
			return {
				...state,
				showSetupSheets: !state.showSetupSheets,
			};
		case "TRIGGER_GENERATE":
			if (action.data === "code")
				return {
					...state,
					triggerGenerateCode: !state.triggerGenerateCode,
				};
			else if (action.data === "template")
				return {
					...state,
					triggerGenerateTemplate: !state.triggerGenerateTemplate,
				};
			break;
		case "OPEN_DIALOG":
			if (action.data === "changeRequestForm")
				return {
					...state,
					openChangeRequestForm: state.openChangeRequestForm + 1,
				};
			else if (action.data === "changeRequestPacketSummary")
				return {
					...state,
					openChangeRequestPacketSummary: state.openChangeRequestPacketSummary + 1,
				};
			else if (action.data === "fixPacketSummary")
				return {
					...state,
					openFixPacketSummary: state.openFixPacketSummary + 1,
				};
			else if (action.data === "release")
				return {
					...state,
					openReleaseDialog: state.openReleaseDialog + 1,
				};
			else if (action.data === "debug")
				return {
					...state,
					triggerOpenDebugDialog: state.triggerOpenDebugDialog + 1,
				};
			else if (action.data === "update")
				return {
					...state,
					triggerOpenUpdateDialog: state.triggerOpenUpdateDialog + 1,
				};
			else if (action.data === "giveFeedback")
				return {
					...state,
					openGiveFeedbackDialog: state.openGiveFeedbackDialog + 1,
				};
			else if (action.data === "openPoint")
				return {
					...state,
					openOpenPointDialog: state.openOpenPointDialog + 1,
				};
			else if (action.data === "objectComparison")
				return {
					...state,
					openObjectComparisonDialog: state.openObjectComparisonDialog + 1,
				};
			else if (action.data === "changeRequestSelectionDialog")
				return {
					...state,
					changeRequestSelectionDialog: state.changeRequestSelectionDialog + 1,
				};
			else if (action.data === "addObjectsToPacketDialog")
				return {
					...state,
					addObjectsToPacketDialog: state.addObjectsToPacketDialog + 1,
				};
			else if (action.data === "releaseDialog")
				return {
					...state,
					releaseDialog: state.releaseDialog + 1,
				};
			break;
		case "SET_CHANGE_REQUESTS_EXIST":
			return {
				...state,
				changeRequestsExist: action.data,
			};
		case "SET_FIX_PACKETS_EXIST":
			return {
				...state,
				fixPacketsExist: action.data,
			};
		case "SET_UPDATES":
			return {
				...state,
				updatesAvailable: action.data,
			};
		case "SET_CAN_RELEASE":
			return {
				...state,
				canRelease: action.data,
			};
		case "SET_OPEN_PANELS":
			return {
				...state,
				openPanels: action.data,
			};
		case "OPEN_VIEW":
			return {
				...state,
				openView: Views(action.data),
			};
		case "SET_DATA_WAREHOUSE_MFI":
			return {
				...state,
				dataWarehouse: action.data,
			};
		case "SET_DESTINATION_MODELS":
			return {
				...state,
				destinationModels: action.data,
			};
		case "RESET_OBJECT_EDITOR_VALUES":
			return {
				...state,
				updatesAvailable: false,
				fixPacketsExist: false,
				canRelease: false,
				changeRequestsExist: false,
			};
		case "REFRESH_VERSION_INFO":
			return {
				...state,
				refreshVersionInfo: state.refreshVersionInfo + 1,
			};
		case "SET_SHOW_LOADING_BAR":
			return {
				...state,
				//If true is passed in it will increment the show Loading Bar to greater than zero, if it is false it will decrement it the lower limit being 0
				//This allows multiple things to set and remove the loading bar without having the one place that removes the loading bar remove it when it shouldn't be removed
				showLoadingBar: action.data
					? state.showLoadingBar + 1
					: state.showLoadingBar <= 0
					? 0
					: state.showLoadingBar - 1,
			};
		case "SET_COPY_MFI":
			return {
				...state,
				copyMfi: action.data,
			};
		case "SET_MENU_OBJECT":
			return {
				...state,
				menuObject: action.data,
			};
		case "SET_SCREEN_OBJECT":
			return {
				...state,
				screenObject: action.data,
			};
		case "TOGGLE_UTILITY_OVERLAY":
			return {
				...state,
				showUtilityOverlay: action.data,
			};
		case "TOGGLE_FULL_SCREEN_VISUAL_REP":
			return {
				...state,
				fullScreenVisualRep: action.data,
			};
		case "TOGGLE_RESIZE_PANELS":
			return {
				...state,
				resizePanels: !state.resizePanels,
			};
		case "SHOW_ERROR_MESSAGE":
			return {
				...state,
				message: { level: "error", content: action.data, show: state.message.show + 1 },
			};
		case "SHOW_WARN_MESSAGE":
			let m = {
				level: "warn",
				show: state.message.show + 1,
			};

			if (action.data.prompt) {
				m.prompt = true;
				m.content = action.data.text;
			} else m.content = action.data;

			return {
				...state,
				message: m,
			};
		case "SHOW_SUCCESS_MESSAGE":
			return {
				...state,
				message: { level: "success", content: action.data, show: state.message.show + 1 },
			};
		case "SHOW_MESSAGE":
			return {
				...state,
				message: { level: "basic", content: action.data, show: state.message.show + 1 },
			};
		case "HIDE_MESSAGE":
			return {
				...state,
				message: { ...state.message, show: 0 },
			};
		case "OPEN_OBJECT_FOR_EDITING":
			if (
				state.contextTop.uuid === action.data?.uuid &&
				state.contextTop.versionUuid === action.data?.versionUuid
			) {
				reducer(state, { type: "SET_SHOW_LOADING_BAR", data: false });
				return {
					...state,
				};
			}

			if (action.data?.[UBM_MASTER_FILE_INDEX_ITEM]) {
				reducer(state, { type: "SET_SHOW_LOADING_BAR", data: false });
				return {
					...state,
					message: {
						level: "warn",
						content:
							"You cannot open '" +
							action.data?.reference +
							"-" +
							action.data?.title +
							"' as it is a UBM object. However you can create a copy of it and edit the copy.",
						show: 1,
					},
				};
			}

			//TODO How do we come back here after we confirm we want to open the new object
			if (state.thereAreChanges) {
				reducer(state, { type: "SET_SHOW_LOADING_BAR", data: false });
				return {
					...state,
					checkForChanges: true,
				};
			}

			return {
				...state,
				openObject: action.data,
				// showLoadingBar: state.showLoadingBar - 1
			};
		case "SET_CONTEXT_OR_MFI":
			let {
				ancestorObjects,
				currentRelatedObject,
				destinationObject,
				destinationMfi,
				fullObjectHierarchy,
				loadedSubObject,
				mfi,
				objectHierarchy: _objectHierarchy,
				openingSubObject,
				openPoints,
				packet,
				selectedWorkspaceRow,
				reset,
				relatedObjects,
				relatedObjectUpdates,
				resetSubObjectInfo,
				resetContextMfiVersion,
				resetCreatorPanelOnly,
				templateRow,
				templateMfi,
				top,
				updateFullObjectHierarchyVersions,
				versions,
			} = action.data;

			if (resetCreatorPanelOnly) {
				let context = {
					contextTop: {
						standardObjectUuid: state.contextTemplateRow?.uuid,
						standardObjectVersionUuid: state.contextTemplateRow?.versionUuid,
					},
					contextMfi: [],
					contextMfiVersion: 0,
					contextDestinationObject: {},
					contextDestinationMfi: [],
					contextObjectHierarchy: [],
					contextOpenPoints: [],
					contextPacket: {},
					contextTemplateRow: state.contextTemplateRow,
					contextTemplateMfi: state.contextTemplateMfi,
					contextVersions: [],
					contextLoadedSubObjects: [],
					contextAncestorObjects: [],
					contextAttachmentPoints: [],
					setupSheetActiveTitle: "",
					setupSheetData: {},
				};

				if (destinationObject) context.contextDestinationObject = destinationObject;

				return {
					...state,
					...context,
					changedData: {},
					selectedWorkspaceRow: {},
					openPropertyWindows: [],
				};
			} else if (reset) {
				return {
					...state,
					contextDestinationObject: {},
					destinationMfi: [],
					contextMfi: [],
					contextMfiVersion: 0,
					contextObjectHierarchy: [],
					contextOpenPoints: [],
					contextPacket: {},
					contextTop: {},
					contextTemplateRow: {},
					contextTemplateMfi: [],
					contextVersions: [],
					contextLoadedSubObjects: [],
					contextAncestorObjects: [],
					contextAttachmentPoints: [],
					changedData: {},
					selectedWorkspaceRow: {},
					openPropertyWindows: [],
					setupSheetActiveTitle: "",
					setupSheetData: {},
				};
			}

			let update = {};

			//If we're opening a sub-object what do we want to do different?
			if (openingSubObject || _objectHierarchy) {
				/**
				 * In this case we want to do everything that we normally do, update the objectHierarchy, top, mfi, etc.
				 * However we want to keep track of the object and objectHierarchy that we came from.
				 *
				 * How should we keep track of it?
				 */
				if (state.contextFullObjectHierarchy && state.contextFullObjectHierarchy.length > 0)
					update.contextFullObjectHierarchy = [...state.contextFullObjectHierarchy, ..._objectHierarchy];
				else update.contextFullObjectHierarchy = [...state.contextObjectHierarchy, ..._objectHierarchy];

				let tempFullObjectHierarchy = {};
				update.contextFullObjectHierarchy.forEach((row) => {
					tempFullObjectHierarchy[row.uuid] = row;
				});
				update.contextFullObjectHierarchy = Object.values(tempFullObjectHierarchy);

				if (openingSubObject) {
					let ancObjs = [];
					if (state.contextAncestorObjects && state.contextAncestorObjects.length > 0)
						ancObjs = state.contextAncestorObjects;
					if (state.contextTop?.uuid) ancObjs.push(state.contextTop);
					else if (state.contextPacket?.uuid && !ancObjs.find((row) => row.uuid === state.contextPacket.uuid))
						ancObjs.push(state.contextPacket);

					update.contextAncestorObjects = ancObjs;
				}
				if (_objectHierarchy) {
					update.contextObjectHierarchy = _objectHierarchy;
				}
			}

			if (selectedWorkspaceRow) update.selectedWorkspaceRow = selectedWorkspaceRow;

			//Top is now the top most object
			if (top) {
				update.contextTop = { ...top };
				if (top.objectHierarchy) update.contextObjectHierarchy = top.objectHierarchy;

				//Reset some of the other fields when opening a new object that is not a sub-object
				if (resetSubObjectInfo) {
					update.contextAncestorObjects = [];
					update.contextFullObjectHierarchy = [];
					update.contextLoadedSubObjects = [];
					update.contextAttachmentPoints = [];
				}
			}

			if (mfi) {
				update.contextMfi = mfi;

				if (resetContextMfiVersion) update.contextMfiVersion = 0;
				else update.contextMfiVersion = state.contextMfiVersion + 1;
			}

			if (destinationObject) update.contextDestinationObject = destinationObject;
			if (destinationMfi) update.contextDestinationMfi = destinationMfi;
			if (templateRow) update.contextTemplateRow = templateRow;
			if (templateMfi) update.contextTemplateMfi = templateMfi;
			if (versions) update.contextVersions = versions;
			if (ancestorObjects) {
				if (ancestorObjects?.length > 0) {
					ancestorObjects.forEach((anc) => {
						while (typeof anc.objectHierarchy === "string") {
							anc.objectHierarchy = JSON.parse(anc.objectHierarchy);
						}
					});
				}
				update.contextAncestorObjects = ancestorObjects;
			}
			if (relatedObjects) update.contextRelatedObjects = relatedObjects;
			if (relatedObjectUpdates) update.contextRelatedObjectUpdates = relatedObjectUpdates;
			if (currentRelatedObject) update.contextCurrentRelatedObject = currentRelatedObject;
			if (openPoints) update.contextOpenPoints = openPoints;

			if (packet) {
				if (packet.uuid) update.contextPacket = packet;
				else update.contextPacket = null;
			}
			if (loadedSubObject) {
				if (state.contextLoadedSubObjects && state.contextLoadedSubObjects.length > 0)
					update.contextLoadedSubObjects = [...state.contextLoadedSubObjects, loadedSubObject];
				else if (loadedSubObject.uuid) update.contextLoadedSubObjects = [loadedSubObject];
				else update.contextLoadedSubObjects = [];
			}
			//Note: FullObjectHierarchy and updateFullObjectHierarchyVersions are not meant to be used together
			if (fullObjectHierarchy) update.contextFullObjectHierarchy = fullObjectHierarchy;
			if (updateFullObjectHierarchyVersions && updateFullObjectHierarchyVersions.length > 0) {
				if (state.contextFullObjectHierarchy) {
					update.contextFullObjectHierarchy = [...state.contextFullObjectHierarchy];
					//For each grab the corresponding record in the full object hierarchy and replace it with the one passed in
					updateFullObjectHierarchyVersions.forEach((newObjectHierarchyRecord) => {
						let recordIndex = update.contextFullObjectHierarchy.findIndex(
							(row) =>
								row.descendantStandardObjectUuid ===
								newObjectHierarchyRecord.descendantStandardObjectUuid
						);
						update.contextFullObjectHierarchy[recordIndex] = newObjectHierarchyRecord;
					});
				} else {
					update.contextFullObjectHierarchy = updateFullObjectHierarchyVersions;
				}
			}

			if (update.contextTop && update.contextTop?.uuid !== state.contextTop.uuid) {
				let reset = {
					openPropertyWindows: [],
					selectedWorkspaceRow: {},
				};

				if (update.selectedWorkspaceRow?.uuid) reset.selectedWorkspaceRow = update.selectedWorkspaceRow;

				return {
					...state,
					...update,
					...reset,
				};
			} else {
				return {
					...state,
					...update,
				};
			}
		// if(attachmentPoint)
		// {
		//     if(state.context.attachmentPoints && state.context.attachmentPoints.length > 0)
		//         update.attachmentPoints = [...update.attachmentPoints, attachmentPoint];
		//     else
		//         update.attachmentPoints = [attachmentPoint];
		// }

		case "UPDATE_CHANGED_ROWS":
			let standardObjectTitle = dataObjects.STANDARD_OBJECT_GIT.title;
			let masterFileIndexTitle = dataObjects.MASTER_FILE_INDEX_ROW.title;
			let view = action.data.viewTitle || state.openView.title;

			//Gather the current changed data
			let changedData = state.changedData;

			if (!changedData[view]) changedData[view] = {};

			let {
				objectToUpdate,
				subObjectToUpdate,
				deletedRows,
				changedMfiRows,
				deletedMfiRows,
				changedObjectRows,
				objectHierarchy,
				reset: _reset,
				changeIsFromDifferentObject = false,
				updateType,
				primusRows = false,
			} = action.data;

			let changes = state.thereAreChanges;
			let associatedTypeUuid = state.dbConstants.associatedObjectType?.referenceUuid;

			if (_reset) {
				return {
					...state,
					changedData: {},
					changedFilters: {},
					thereAreChanges: false,
					changedStockNumbers: [],
					changedVolumes: [],
					changedObjectClasses: [],
					changedLanguageFrameworks: [],
					changedOpenPoints: {},
				};
			}

			//What should I pass in to reset the data?
			if (changedMfiRows) {
				if (!changedData[view][masterFileIndexTitle])
					changedData[view][masterFileIndexTitle] = {
						mfi: {},
						deleted: [],
					};

				if (changedMfiRows.length > 0) {
					//TODO This looks unnecessary
					// if(!changedData[view][masterFileIndexTitle]) changedData[view][masterFileIndexTitle] = {};

					let changedMfiRowMap = {};

					//Add the changedMfiRows to the map, filtering out the UBM ones if the current destination is not UBM
					if (state.destinationModel[0]?.uuid !== state.dataWarehouse[0]?.uuid)
						changedMfiRows
							.filter((row) => !row[UBM_MASTER_FILE_INDEX_ITEM])
							.forEach((row) => (changedMfiRowMap[row.uuid] = row));
					else changedMfiRows.forEach((row) => (changedMfiRowMap[row.uuid] = row));

					//Merge with previously changed mfi rows
					changedData[view][masterFileIndexTitle].mfi = {
						...changedData[view][masterFileIndexTitle].mfi,
						...changedMfiRowMap,
					};
					changes = true;
				} else {
					changedData[view][masterFileIndexTitle] = {};
				}
			}

			//This needs to handle multiple objects, so lets create an array of the objects that need updated
			//Get the key (uuid and version) for the object these changed rows belong to
			let objectUuidWithVersion;
			// if(!objectToUpdate)
			//     objectToUpdate = state.contextTop;

			let primusUpdates = [];
			let readonly = false;
			if (objectToUpdate || changedObjectRows || changedMfiRows || deletedRows) {
				let associatedHierarchyRecords = [];
				//Check if we have navigated into a sub-object, if so the objectToUpdate needs to be the first ancestorObject
				if (!objectToUpdate?.uuid) {
					//If a related object is being updated that means we need to update all the ancestor objects as well.
					//The relationship will be updated along with the version of the object that has the relationship
					// if(state.context.currentRelatedObject?.uuid) {
					//     objectToUpdate = state.context.currentRelatedObject;
					// }
					// else
					objectToUpdate = getTopMostObject(state);
					readonly = state.contextAncestorObjects.find((row) => row.readonly)?.uuid;
					if (objectToUpdate.readonly || readonly) {
						return {
							...state,
							message: {
								level: "error",
								content: "This object is marked as read-only",
								show: 1,
							},
						};
					}
				}

				if (objectToUpdate.associatedObject) {
					//The ancestor objects should have all the hierarchy information
					let ancestorAssociations = state.contextAncestorObjects.filter((row) => row.associatedObject);
					ancestorAssociations.push(objectToUpdate);

					ancestorAssociations.forEach((row) => {
						let hierarchyRecord = {};
						Object.keys(row)
							.filter((key) => key.includes("associatedHierarchyAttribute"))
							.map((key) => {
								let subkey = key.slice("associatedHierarchyAttribute".length);
								hierarchyRecord[subkey] = row[key];
							});
						associatedHierarchyRecords.push(hierarchyRecord);
					});
				}

				if (changedData.associatedHierarchyRecords?.length > 0) {
					let map = new Map();
					changedData.associatedHierarchyRecords.forEach((row) => map.set(row.uuid, row));
					associatedHierarchyRecords.forEach((row) => map.set(row.uuid, row));
					changedData.associatedHierarchyRecords = [...map.values()];
				} else changedData.associatedHierarchyRecords = associatedHierarchyRecords;
				objectUuidWithVersion = getObjectIdAndVersionUuid(objectToUpdate);

				let arr = [];
				if (state.contextAncestorObjects?.length > 0)
					arr = [...state.contextAncestorObjects, ...state.contextMfi];
				else arr = state.contextMfi;

				if (deletedRows) {
					let deletedIds = deletedRows.map((row) => row.uuid);
					arr = arr.filter((row) => !deletedIds.includes(row.uuid));
				}

				//Build the primusObjectMap
				let primusObjectMap = getPrimusObjectMap(arr);

				//Ensure the changedData has the view, standardObjectTitle along with the objectUuidWithVersion
				if (!changedData[view][standardObjectTitle]) changedData[view][standardObjectTitle] = {};
				let currentObjectBeingChanged = changedData[view][standardObjectTitle];
				let currentChanged;

				if (objectUuidWithVersion) {
					if (!currentObjectBeingChanged[objectUuidWithVersion]) {
						currentObjectBeingChanged[objectUuidWithVersion] = {
							objectHierarchy: {},
							top: objectToUpdate,
							objects: {},
						};
					}

					currentChanged = currentObjectBeingChanged[objectUuidWithVersion].objects;
				}

				//Add the changed object rows
				if (changedObjectRows) {
					if (changedObjectRows.length > 0) {
						//For each row check its closes primus ancestor and add it to the object map?
						changedObjectRows.forEach((row) => {
							//Grab the closest primusAncestor
							let closestAncestor = findClosestPrimusAncestorByMap(primusObjectMap, row.reference);

							if (changeIsFromDifferentObject) closestAncestor = objectToUpdate;

							if (!row.keepObjectHierarchy) delete row.objectHierarchy;

							//If the subObjectToUpdate or the objectToUpdate is new we need to add all ancestors to the changed rows as well
							if (subObjectToUpdate) {
								//This will not allow us to at the same time send changes for a readonly and non-readonly object
								if (subObjectToUpdate.readonly) {
									return {
										...state,
										message: {
											level: "error",
											content: "This object is marked as readonly",
											show: 1,
										},
									};
								}

								//Merge the changes that were made to the hologram navigated to through the tree with the ones made to the sub-object (For now this only occurs when a checklist task is updated)
								let rowCopy = {
									...row,
									reference: row.reference?.startsWith(subObjectToUpdate.reference + ".")
										? row.reference.replace(subObjectToUpdate.reference + ".", "")
										: row.reference,
								};

								//If the closestAncestor is marked as a new object, add each ancestor of the changed row to the changed rows
								if (subObjectToUpdate.new) {
									//I'm assuming we can get the list of ancestors from the contextMfi in the global state, this assumes that the change is being made to the open currently open object
									state.contextMfi
										.filter(
											(item) =>
												row.ancestors.includes(item.uuid) &&
												item.uuid !== subObjectToUpdate.uuid
										)
										.forEach((item) => {
											let copy = {
												...item,
												reference: item.reference?.startsWith(subObjectToUpdate.reference + ".")
													? item.reference.replace(subObjectToUpdate.reference + ".", "")
													: item.reference,
											};

											addRowToChangedObjectMap(currentChanged, subObjectToUpdate, copy);
										});
								}

								//Verify we aren't overwriting changes to the standardObject
								if (currentChanged && !isSubObjectAddedToMap(currentChanged, subObjectToUpdate)) {
									//This change would get attached to that and this object hierarchy record would not need added
									addRowToChangedObjectMap(currentChanged, subObjectToUpdate, {
										...subObjectToUpdate,
										reference: "0",
									});
								}
								addRowToChangedObjectMap(currentChanged, subObjectToUpdate, rowCopy);
							}
							//Add this closest primus ancestor to our changed map along with the current row in question
							// if(closestAncestor || subObjectToUpdate)
							else if (closestAncestor) {
								//This will not allow us to at the same time send changes for a readonly and non-readonly object
								if (closestAncestor.readonly) {
									return {
										...state,
										message: {
											level: "error",
											content: "This object is marked as readonly",
											show: 1,
										},
									};
								}

								//The row has a different reference on the backend, reset it so it doesn't think we want to update its reference
								let rowCopy = { ...row };
								if (rowCopy.reference?.startsWith(closestAncestor.reference + "."))
									rowCopy.reference = rowCopy.reference.replace(closestAncestor.reference + ".", "");

								addRowToChangedObjectMap(currentChanged, closestAncestor, rowCopy);

								//If the closestAncestor is marked as a new object, add each ancestor of the changed row to the changed rows
								if (closestAncestor.new) {
									//I'm assuming we can get the list of ancestors from the contextMfi in the global state, this assumes that the change is being made to the open currently open object
									state.contextMfi
										.filter((item) => row.ancestors.includes(item.uuid))
										.forEach((item) => {
											let reference = item.reference;
											if (item.reference?.startsWith(closestAncestor.reference + "."))
												reference = item.reference.replace(closestAncestor.reference + ".", "");
											else if (item.uuid === closestAncestor.uuid) reference = "";

											addRowToChangedObjectMap(currentChanged, closestAncestor, {
												...item,
												reference: reference,
											});
										});
								}

								if (row.generalTypeUuid === associatedTypeUuid) {
									//We will assume that making changes to an associated object doesn't actually affect the object, Should we have the app not let the user make changes to it at all?
								}
								//If the row I am changing is a sub-object also add the change to itself
								else if (primusObjectMap.has(row.reference))
									addRowToChangedObjectMap(currentChanged, rowCopy, { ...rowCopy, reference: null });
							} else addRowToChangedObjectMap(currentChanged, objectToUpdate, row);

							//If the row is a primus object we want to add a change for that object too
							if (row.object) {
								if (!subObjectToUpdate || primusRows) {
									let rowCopy = { ...row };
									rowCopy.reference = "0";
									addRowToChangedObjectMap(currentChanged, rowCopy, rowCopy);
								}
							}
						});

						changes = true;
						primusUpdates = changedObjectRows.filter((row) => row.isObject);
					}
					//Is this how we should reset the change data?
					else if (deletedRows?.length < 1 && objectHierarchy?.length < 1)
						changedData[view][standardObjectTitle] = {};
				}

				if (objectHierarchy) {
					if (objectHierarchy.length > 0) {
						objectHierarchy.forEach((hierarchy) => {
							addObjectHierarchyToChangedMap(currentObjectBeingChanged, objectToUpdate, hierarchy);
							//Find the row matching up with the objectHierarchy's descendant key and add it
							if (hierarchy.hierarchyTypeUuid !== associatedTypeUuid) {
								let newSubRow = changedObjectRows.find(
									(row) =>
										row.uuid === hierarchy.descendantStandardObjectUuid &&
										row.versionUuid === hierarchy.descendantStandardObjectVersionUuid
								);
								addRowToChangedObjectMap(currentChanged, newSubRow, { ...newSubRow, reference: "0" });
							}
						});
						// changedData[view].objectHierarchy = objectHierarchy;
					} else if (currentObjectBeingChanged[objectUuidWithVersion]?.objectHierarchy) {
						currentObjectBeingChanged[objectUuidWithVersion].objectHierarchy = {};
					}
				}

				//Add the deleted rows
				if (deletedRows) {
					let deletedPrimusMap = getPrimusObjectMap(deletedRows);

					//Filter out the descendants that are subObjects because since they come with their ancestor, they're gone when their ancestor gets deleted.
					deletedRows = filterOutSubObjects(deletedRows);
					if (deletedRows.length > 0) {
						//Get the closest primus ancestor for the first row because we can (all the rows will have the same primus ancestor as that one)
						let primusAncestor = findClosestPrimusAncestorByMap(primusObjectMap, deletedRows[0].reference);
						//If we have passed in a subObjectToUpdate the primusAncestor should be the subObjectToUpdate
						if (subObjectToUpdate) {
							primusAncestor = subObjectToUpdate;
						}

						deletedRows.forEach((row) => {
							addRowToChangedObjectMap(currentChanged, primusAncestor, row, true);
						});
						changes = true;
					}

					[...deletedPrimusMap.values()].forEach((obj) => {
						//Only add the obj as a changed object if its not an associated object, the hierarchy records for associated objects happen on the backend
						if (obj.generalTypeUuid !== associatedTypeUuid)
							addRowToChangedObjectMap(currentChanged, obj, obj, true);
					});

					//Do I need an else here to reset it like we do with the changedObjectRows
				}
			}

			//I want to be able to make changes and not cause everything to re-render. Are there some updates that some components care about but not others?
			//Example when we change what is showing on the setup sheets, the object MFI doesn't care about the update... Instead I could make the tree only care about the attribute changes that it cares about. Since this doesn't cause any changes on the tree view
			//It shouldn't re-render anyways
			// if(updateType)
			// 	changed = updateType;

			return {
				...state,
				changedData,
				contextMfiVersion:
					!subObjectToUpdate && (changedObjectRows?.length > 0 || deletedRows?.length > 0)
						? state.contextMfiVersion + 1
						: state.contextMfiVersion,
				primusUpdates,
				thereAreChanges: changes,
			};
		case "UPDATE_TASK_ROW":
			/**
			 * action.data should looke like this:
			 *  {
			 *      uuid: '',
			 *      value: '',
			 *      component: true or false depending on if the row is a component of the uuid passed in
			 *  }
			 */
			return {
				...state,
				taskRowToUpdate: action.data,
			};
		case "UPDATE_OPEN_PROPERTY_WINDOWS":
			/**
			 * action.data should looke like this:
			 *  {
			 *      uuid: '',
			 *      value: '',
			 *      component: true or false depending on if the row is a component of the uuid passed in
			 *  }
			 */
			return {
				...state,
				openPropertyWindows: action.data,
			};
		case "UPDATE_ROW":
			/**
			 * action.data should look like this:
			 *  {
			 *      uuid: '', uuid of the row needing updated
			 *      field: '', the field to update
			 *      value: '', the new value for that field
			 *  }
			 */
			return {
				...state,
				rowToUpdate: action.data,
			};
		case "SET_SELECTED_WORKSPACE_ROW":
			return {
				...state,
				selectedWorkspaceRow: action.data,
			};
		case "UPDATE_ENVIRONMENT":
			//If action.data is undefined, reset the environment
			if (!action.data)
				return {
					...state,
					environment: {},
				};

			//Otherwise update the applicable attributes
			let { userRole, company, utility } = action.data;
			let environment = { ...state.environment };
			if (userRole) {
				environment.userRole = userRole;
			}
			if (company) {
				environment.company = company;
			}
			if (utility) {
				environment.utility = utility;
			}
			return {
				...state,
				environment,
			};
		case "UPDATE_LAYOUT":
			let { layout, panelId, type } = action.data;

			if (type === "overwrite") {
				return {
					...state,
					layouts: {
						...layout,
					},
				};
			} else if (type === "addNew") {
				let layout = buildNewPanel(panelId, state.layouts);
				return {
					...state,
					layouts: layout,
				};
			}
			break;
		case "UPDATE_DESTINATION_MODEL_OBJECTS":
			return {
				...state,
				destinationModelObjects: action.data,
			};
		case "UPDATE_DESTINATION_MODEL":
			let { mfi: destMfi, uuid } = action.data;
			return {
				...state,
				destinationModel: destMfi,
				destinationUuid: uuid,
			};
		case "SET_ATTACHABLE_TYPES":
			return {
				...state,
				attachableTypes: action.data,
			};
		case "SET_PACKET_MODE":
			return {
				...state,
				packetMode: action.data,
			};
		case "SET_LOAD_OBJECT":
			return {
				...state,
				loadObject: action.data,
			};
		case "UPDATE_SETUP_SHEET_INFO":
			let { attribute, data } = action.data;
			return {
				...state,
				["setupSheet" + attribute]: data,
			};
		case "SET_PRIMUS_UPDATES":
			return {
				...state,
				primusUpdates: action.data,
			};
		case "SET_SOURCE_OBJECT_ROW":
			return {
				...state,
				sourceObject: action.data,
			};
		case "SET_ADDED_REACT_EDITOR_SUGGESTIONS":
			return {
				...state,
				addedReactEditorSuggestions: action.data,
			};
		case "SET_SUB_OBJECT_UPDATES":
			return {
				...state,
				subObjectUpdates: action.data,
			};
		default:
			console.error("ACTION TYPE didn't exist", action.type);
			return state;
	}
};

const useValue = () => useReducer(reducer, initialState);

export const { Provider, useTracked, useTrackedState, useUpdate: useDispatch } = createContainer(useValue);
