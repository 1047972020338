import React, { useState, useEffect, useRef } from "react";
import Panel from "./Panel";
import styles from "./OpenPointPanel.module.scss";
import { v4 as uuidv4 } from "uuid";
import {
	getObjectsOpenPoints,
	getSheetOpenPoints,
	getSingleLevelObjectMfi,
	getSingleLevelObjectMfiFromBeginningAndEndOfPath,
} from "../../../utils/ApiUtils";
import { INPUT_FIELD_TYPES, SETUP_SHEET_OUTPUT_FIELD_TYPES } from "../../../utils/SetupTypes";
import { useDispatch, useTrackedState } from "../../../utils/store";
import { formatDate } from "../../../utils/DateUtils";
import { FilterIcon, PencilSquareIcon, PlusIcon, TogglesIcon } from "../../BootstrapComponents/Icons/Icons";
import { createOpenPointDefaults, createOpenPointJsonObject } from "../../OpenPointForm/OpenPoints";
import { ObjectHeader } from "../Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import SelectionDialog from "../../Dialog/DraggableDialog/SelectionDialog/SelectionDialog";
import { getAncestorObjectsFromPath, getTopMostObject, selectWorkspaceRow } from "../Body/CreatorPanel/CreatorPanel";
import Feedback from "../../Feedback/Feedback";
import { getObjectIdAndVersionUuid, getToc } from "../../../utils/StandardObject";
import evaluateExpression from "../../../utils/ExpressionUtils/ExpressionUtil";
import CheckboxAndFilterList from "../../CheckboxAndFilterList/CheckboxAndFilterList";
import DraggableDialog from "../../Dialog/DraggableDialog/Dialog";

/**
 * Based on
 * 	Open Point Sheet Object
 * 	Stock # - 101-541611.3081.OPF.4150 v17
 * Renders the open points within a panel
 * @param {}
 * @constructor
 */
const OpenPointPanel = ({ openPointSheetUuid, objectUuid, reference, checklist, toc, tocRowClick }) => {
	//state variables
	//Stock # - **NO STOCK # FOUND**: 04.04.01 - openPoints
	const [openPoints, setOpenPoints] = useState([]);
	const [showCreateLinkDialog, setShowCreateLinkDialog] = useState(false);
	const [showFilterDialog, setShowFilterDialog] = useState(false);

	const [showCaptureFeedback, setShowCaptureFeedback] = useState(false);
	const [objectMfi, setObjectMfi] = useState([]);
	const [showLinkToDwObjectDialog, setShowLinkToDwObjectDialog] = useState(false);
	const [filterCount, setFilterCount] = useState(0);
	const [showClearedPoints, setShowClearedPoints] = useState(false);

	const topMfiRef = useRef([]);
	const topRef = useRef([]);
	const creatingLinkFor = useRef({});
	const openPointDefaults = useRef({});
	const allOpenPoints = useRef([]);
	const filterObject = useRef({});
	const filterAttributes = useRef([]);
	const updatingFilterAttribute = useRef();
	const selectedRow = useRef();

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		//How do I have the object setup the default filter option for the open point sheet?
		//Set the newFilterList by using the INITIALS Function in the Data Warehouse
		if (sharedState.contextOpenPoints.length > 0) {
			allOpenPoints.current = sharedState.contextOpenPoints;
			setOpenPoints(allOpenPoints.current.filter((row) => !row.closedBy || !row.dateClosed));
		} else retrieveOpenPoints(objectUuid, openPointSheetUuid);

		//Get the defintion of the open point object
		createOpenPointDefaults(sharedState, dispatch).then((op) => {
			openPointDefaults.current = op;
		});
	}, []);

	useEffect(() => {
		selectedRow.current = sharedState.selectedWorkspaceRow;
	}, [sharedState.selectedWorkspaceRow?.uuid]);

	//Everytime filter count changes this means our filter updated and we need to refilter our open points
	useEffect(() => {
		// setOpenPoints(filterOpenPoints());
	}, [filterCount]);

	useEffect(() => {
		let top = getTopMostObject(sharedState);
		//Store the top row in a ref
		//We grab the top MFI that we use to link the open point to an attribute on it.
		if (
			!topMfiRef.current[0] ||
			top.uuid !== topRef.current.uuid ||
			top.versionUuid !== topRef.current.versionUuid
		) {
			getSingleLevelObjectMfi(top.uuid, top.versionUuid).then((singleMfi) => {
				//If the top is packet object get the TOC for it
				if (top.objectTypeUuid === sharedState.dbConstants.packetObject.referenceUuid) {
					topMfiRef.current = getToc(singleMfi);
					topMfiRef.current[0].objectHierarchy = singleMfi[0].objectHierarchy;
				} else topMfiRef.current = singleMfi;

				topRef.current = singleMfi[0];
			});
			//The top changed so we need to grab the new open points
			// retrieveOpenPoints(top.uuid, openPointSheetUuid);
		} else if (!sharedState.contextTop?.uuid) {
			//We don't look at what is in contextTop when grabbing open points, we look at the top ancestor
			// allOpenPoints.current = [];
			// setOpenPoints([]);
		}
	}, [sharedState.contextMfiVersion, sharedState.contextTop?.uuid]);

	useEffect(() => {
		if (sharedState.contextOpenPoints.length > 0) {
			allOpenPoints.current = sharedState.contextOpenPoints;
			//Filter the open points
		}
	}, [sharedState.contextOpenPoints?.length]);

	const filterOpenPoints = () => {
		//Loop over keys in filter object and run each filter
		return allOpenPoints.current.filter((item) => {
			for (const [key, filter] of Object.entries(filterObject.current)) {
				if (!item.hasOwnProperty(key)) {
					// If the object does not have the key, skip this item
					return false;
				}
				const value = item[key];
				const { operator, value: filterValue } = filter;
				if (!compare(operator, value, filterValue)) {
					// If the value does not satisfy the condition, skip this item
					return false;
				}
			}
			return true;
		});
	};

	const compare = (operator, value, filterValue) => {
		switch (operator) {
			case "=":
				return value === filterValue;
			case ">":
				return value > filterValue;
			case "<":
				return value < filterValue;
			case ">=":
				return value >= filterValue;
			case "<=":
				return value <= filterValue;
			default:
				return false;
		}
	};

	const retrieveOpenPoints = async (objectUuid, sheetUuid) => {
		let sheetOpenPoints = await getSheetOpenPoints(sheetUuid);

		let arr = [];
		// For each sheet open point, interpret the data using the object definition
		// How should I interpret it? Do I grab the single MFI here? Or do I have the backend handle that part? It seems like it makes sense for the backend to take care of that part
		// Just so users of this API don't have to do so much work
		sheetOpenPoints?.forEach((op, index) => {
			/**
			 *  How do we do the conversion from the JSON to an open point?
			 *  How do we decide what fields our open point table shows? Can we do it in a way that doesn't set it in stone?
			 */
			let converted = { ...op.jsonData };
			delete op.jsonData;
			converted = { ...converted, ...op, index };
			arr.push(converted);
		});

		arr.sort((a, b) => new Date(a.dateOpened) - new Date(b.dateOpened));
		allOpenPoints.current = arr;

		//Set default filter if it hasn't already been set
		if (Object.keys(filterObject.current).length < 1) {
			let initials = evaluateExpression("INITIALS(CURRENTUSERNAME())", sharedState);

			//Filter first by assigned to me, if none filter to opened by
			let filtered = allOpenPoints.current.filter(
				(row) => row.assignedTo === initials || row.assignedTo?.includes(initials)
			);
			let criteria = {};
			if (filtered.length > 0) criteria = { assignedTo: { value: initials, operator: "=" } };
			else {
				filtered = allOpenPoints.current.filter((row) => row.openedRequestedBy === initials);
				criteria = { openedRequestedBy: { value: initials, operator: "=" } };
			}

			// if(filtered.length < 1)
			// 	setOpenPoints(allOpenPoints.current);
			if (filtered.length > 0) {
				filterObject.current = criteria;
				// setOpenPoints(filterOpenPoints())
			}
		}

		setOpenPoints(arr.filter((row) => !row.closedBy || !row.dateClosed));
		// setOpenPoints(filterOpenPoints())
		updateGlobalStateOpenPoints(arr);
	};

	//Other Methods
	const updateGlobalStateOpenPoints = (newOpenPoints) => {
		dispatch({ type: "SET_CONTEXT_OR_MFI", data: { openPoints: newOpenPoints } });
	};

	const addNewOpenPoint = async () => {
		let newOpenPoints = openPoints;
		//If we're looking at cleared, switch to non-cleared
		if (showClearedPoints) {
			toggleClearedPoints();
			// await setShowClearedPoints(false);
			newOpenPoints = allOpenPoints.current.filter((row) => !row.closedBy || !row.dateClosed);
		}

		sharedState.selectedWorkspaceRow = selectedRow.current;
		let newOpenPoint = await createOpenPointJsonObject(sharedState, dispatch);
		//Convert the Document Reference Manual MFI from a string to a json object
		if (newOpenPoint.documentReferenceManualMfi)
			newOpenPoint.documentReferenceManualMfi = JSON.parse(newOpenPoint.documentReferenceManualMfi);
		//Update the openPointSheetUuid
		newOpenPoint.openPointSheetUuid = openPointSheetUuid;
		newOpenPoints = [...newOpenPoints, newOpenPoint];
		dispatch({ type: "UPDATE_CHANGED_OPEN_POINTS", data: newOpenPoint });

		allOpenPoints.current = [...allOpenPoints.current, newOpenPoint];
		updateGlobalStateOpenPoints(allOpenPoints.current);
		setOpenPoints(newOpenPoints);
	};

	const createLinkClicked = (op, field) => {
		creatingLinkFor.current = {
			op,
			field,
		};
		setShowCreateLinkDialog(true);
	};

	const navigateToLinkClicked = async (linkTo) => {
		if (!linkTo) return;

		switch (linkTo.linkToSource) {
			case "data-warehouse":
				//In this case we can just load the object? Do we want to load it into a dialog or just show a preview so I can still view the open point that led me here?
				window.open(
					`?uuid=${linkTo.linkToObjectUuid}&versionUuid=${linkTo.linkToObjectVersionUuid}&focus=${linkTo.linkToAttributeUuid}`,
					"_blank"
				);
				break;
			case "object-master-file-index":
				// Load the object and attribute the same way we do for reference links in the checklist panel
				let top = getTopMostObject(sharedState),
					{ contextTop, contextMfi } = sharedState;
				if (linkTo.linkToObjectUuid === top.uuid || linkTo.linkToObjectUuid === contextTop.uuid) {
					//If we have a packet open what should happen here? We don't let the user modify the packet contents, only sub-objects
					if (top.uuid === sharedState.contextPacket?.uuid && top.uuid === linkTo.linkToObjectUuid) {
						let tocRow = toc.find((row) => row.uuid === linkTo.linkToAttributeUuid);
						if (tocRow) {
							tocRowClick(tocRow);
							return;
						}
					} else {
						selectLinkedAttribute(contextMfi, linkTo.linkToAttributeUuid, sharedState, dispatch);
						return;
					}
				}

				let hierarchyRecord = {
					ancestorStandardObjectUuid: top.uuid,
					ancestorStandardObjectVersionUuid: top.versionUuid,
					descendantStandardObjectUuid: linkTo.linkToObjectUuid,
					descendantStandardObjectVersionUuid: linkTo.linkToObjectVersionUuid,
				};

				let tocMatch = toc.find(
					(row) => row.uuid === linkTo.linkToObjectUuid && row.versionUuid === linkTo.linkToObjectVersionUuid
				);
				if (tocMatch) {
					tocRowClick(tocMatch);
					return;
				}

				let mfi = await getSingleLevelObjectMfiFromBeginningAndEndOfPath({
					objectHierarchy: [hierarchyRecord],
				});

				//Get the current ancestorObjects
				let { contextAncestorObjects: ancestorObjects } = sharedState;
				//Get the ancestor objects for breadcrumbs
				hierarchyRecord = mfi[0].objectHierarchy.find(
					(row) =>
						row.descendantStandardObjectUuid === mfi[0].uuid &&
						row.descendantStandardObjectVersionUuid === mfi[0].versionUuid
				);

				//For each object in the hierarchy that is not already an ancestor, add it to the list of ancestor objects so it will appear in the breadcrumbs
				let newAncestorObjects = await getAncestorObjectsFromPath(
					hierarchyRecord.pathEnum,
					sharedState.contextAncestorObjects
				);

				//If one of the ancestors is the checklist, load the checklist and highlight the task if it was linked to one
				let checklistAncestor = newAncestorObjects.find(
					(row) => row.uuid === checklist.uuid && row.versionUuid === checklist.versionUuid
				);
				if (checklistAncestor) {
					tocRowClick(
						{
							uuid: linkTo.linkToObjectUuid,
							versionUuid: linkTo.linkToObjectVersionUuid,
							reference: linkTo.value,
							attributeUuid: linkTo.linkToAttributeUuid,
						},
						checklistAncestor
					);
					return;
				}

				await dispatch({
					type: "SET_CONTEXT_OR_MFI",
					data: {
						top: {},
						mfi: [],
					},
				});

				await dispatch({
					type: "SET_CONTEXT_OR_MFI",
					data: {
						top: mfi[0],
						mfi,
						ancestorObjects: newAncestorObjects,
						resetContextMfiVersion: true,
					},
				});
				selectLinkedAttribute(mfi, linkTo.linkToAttributeUuid, sharedState, dispatch);
				break;
			default:
				console.error("Unknown linkToSource: ", linkTo.linkToSource);
		}
		//What do we want to do here? Do we pull it up in a dialog? Because I think we want to stay in this current object.
		//What does it look like to review the linked object?
	};

	const linkToRowSelected = (row, object, id) => {
		setShowCreateLinkDialog(false);
		if (row) {
			let { op, field } = creatingLinkFor.current;
			let update = {};

			//If an attribute was selected along with an object, we want to link to both
			if (object && object.uuid !== row.uuid) {
				if (topRef.current.uuid !== topMfiRef.current[0].uuid && object.uuid === topMfiRef.current[0].uuid)
					object = topRef.current;

				update.linkToObjectUuid = object.uuid;
				update.linkToObjectVersionUuid = object.versionUuid;
				update.linkToAttributeUuid = row.uuid;

				if (id === "data-warehouse") update.value = objectMfi[0].mfiReference + ">" + row.reference;
				else update.value = row.reference;
			}
			//Otherwise if something in the data warehouse was selected, pull up the dialog so the user can select an attribute from that object
			else if (id === "data-warehouse") {
				//Get MFI for row
				getSingleLevelObjectMfi(row.standardObjectUuid, row.standardObjectVersionUuid, dispatch).then((mfi) =>
					setObjectMfi(mfi)
				);
				setShowLinkToDwObjectDialog(true);

				// update.linkToObjectUuid = row.standardObjectUuid;
				// update.linkToObjectVersionUuid = row.standardObjectVersionUuid;
				// update.value = row.reference;
			} else {
				update.linkToObjectUuid = object.uuid;
				update.linkToObjectVersionUuid = object.versionUuid;
				update.value = object.reference;
			}

			update.linkToSource = id;
			op = { ...op, [field]: update };
			dispatch({ type: "UPDATE_CHANGED_OPEN_POINTS", data: op });

			//Update the open points array to have the new link
			let indexToUpdate = openPoints.findIndex((row) => row.uuid === op.uuid);
			update = [...openPoints];
			update[indexToUpdate] = op;
			setOpenPoints(update);
		}
	};

	const deleteLinkClicked = (op, field) => {
		dispatch({ type: "UPDATE_CHANGED_OPEN_POINTS", data: { ...op, [field]: "" } });
	};

	const changeFilterClicked = (index) => {
		//Get the possibilities for the attribute we are filtering by
		//3 = Opened By, 22 = Assigned To
		// if(index === 3)
		// {
		// 	let attribute = 'openedRequestedBy';
		// 	//Get the values and populate the list
		// 	let options = new Set(allOpenPoints.current.filter(row => row[attribute]).map(row => row[attribute]).sort());
		// 	filterAttributes.current = [...options].map(row => ({uuid: uuidv4(), title: row}));
		// 	let currentValues = filterObject.current[attribute]?.value;
		// 	if(Array.isArray(currentValues))
		// 	{
		// 		currentValues.forEach(curr => {
		// 			let row = filterAttributes.current.find(row => row.title.includes(curr));
		// 			if(row)
		// 				row.checked = true;
		// 		})
		// 	}
		// 	else
		// 	{
		// 		let row = filterAttributes.current.find(row => row.title.includes(currentValues));
		// 		if(row)
		// 			row.checked = true;
		// 	}
		//
		// 	updatingFilterAttribute.current = attribute;
		// 	setShowFilterDialog(true);
		// }
	};

	const updateFilter = (checked, title) => {
		let attribute = updatingFilterAttribute.current;
		if (!filterObject.current[attribute]) filterObject.current[attribute] = { value: "", operator: "=" };

		if (checked) filterObject.current[attribute].value = [...filterObject.current[attribute].value, title];
		else
			filterObject.current[attribute].value = filterObject.current[attribute].value.filter(
				(row) => row !== title
			);

		setFilterCount(filterCount + 1);
	};

	/**
	 * Toggles between cleared and not cleared points
	 */
	const toggleClearedPoints = () => {
		//If we are already showing the cleared points, toggle to show the points that aren't yet cleared
		if (showClearedPoints) {
			setOpenPoints([...allOpenPoints.current.filter((row) => !row.dateClosed || !row.closedBy)]);
			setShowClearedPoints(false);
		}
		//else we aren't showing cleared points so toggle to only show the points that are cleared
		else {
			//For some reason the state isn't getting updated, could be happening here, could also be happening in the Open Point Row Component
			let arr = allOpenPoints.current.filter((row) => row.dateClosed && row.closedBy);
			setOpenPoints([...arr]);
			setShowClearedPoints(true);
		}
	};

	return (
		<Panel
			panelTitle={"Open Points"}
			menuItems={[
				{
					id: "open-point-add",
					label: "Create New Open Point",
					icon: <PlusIcon />,
					onClick: addNewOpenPoint,
				},
				{
					id: "open-point-screenshot",
					label: "New Open Point from Drawing",
					icon: <PencilSquareIcon />,
					onClick: () => setShowCaptureFeedback(true),
				},
				{
					id: "toggle-between-cleared-and-non-cleared",
					label: "Show / Hide Cleared Points",
					icon: <FilterIcon width={10} height={10} className={styles.filterIcon} />,
					onClick: toggleClearedPoints,
				},
			]}
			panelGroup={"creator"}
			panelStockNumber={<ObjectHeader reference={reference} referenceType={"PKT"} />}
			quickActionItems={[
				{
					id: "open-point-quick-action-plus",
					title: "Create New Open Point",
					icon: <PlusIcon />,
					onClick: addNewOpenPoint,
				},
			]}
		>
			<div className={`${styles.openPoints}`} style={{ height: "100%", overflow: "auto" }}>
				<table className={`table table-sm table-bordered `}>
					<thead className={`${styles.stickyHeader}`} style={{ zIndex: 1 }}>
						<tr style={{ backgroundColor: "white" }}>
							{Array.from(Array(29).keys()).map((i) => (
								<th className="fw-lighter" key={i}>
									<button
										className="btn p-0 text-nowrap"
										style={{ fontSize: "14px" }}
										onClick={() => changeFilterClicked(i)}
									>
										{i + 1}
										<FilterIcon width={10} height={10} className={styles.filterIcon} />
									</button>
								</th>
							))}
						</tr>
						<tr
							style={{
								backgroundColor: "rgb(215, 215, 215)",
								fontWeight: "bold",
								fontSize: "medium",
								zIndex: "3",
							}}
						>
							<th colSpan="3" style={{ backgroundColor: "white" }}></th>
							<th colSpan="4" className={`${styles.thickLeftBorder}`}>
								Requested By:
							</th>
							<th colSpan="12" className={`${styles.thickLeftBorder}`}>
								Open Point / Item to be Changed:
							</th>
							<th colSpan="10" className={`${styles.thickLeftBorder}`}>
								Change Task Assigned To:
							</th>
						</tr>
						<tr className={`${styles.secondHeader}`} style={{ backgroundColor: "white" }}>
							<th className={`${styles.vertical} text-danger`}>Form Reference</th>
							<th className={`${styles.vertical}`}>Line</th>
							<th className={`${styles.vertical}`}>Priority</th>
							<th className={`${styles.vertical} ${styles.thickLeftBorder}`}>Opened/Requested By</th>
							<th className={`${styles.vertical}`}>Date Opened</th>
							<th className={`${styles.vertical}`}>LE/Company/CCODE</th>
							<th className={`${styles.vertical}`}>Contact: #/Email</th>
							<th className={`${styles.vertical} ${styles.thickLeftBorder}`}>Legal Entity</th>
							<th className={`${styles.vertical}`}>Model/App Title</th>
							<th className={`${styles.vertical}`}>Model/App ID #</th>
							<th className={`${styles.vertical}`}>Model/App Version #</th>
							<th className={`${styles.vertical}`}>New Version or New Document</th>
							<th className={`${styles.vertical}`}>Document Title / Origination</th>
							<th className={`${styles.vertical}`}>Document Version #</th>
							<th className={`${styles.thickLeftBorder}`}>
								<span style={{ fontWeight: "bold", fontSize: "larger" }}>
									Action Required/Description of Change to be Made:
								</span>
							</th>
							<th className={`${styles.vertical} ${styles.thickLeftBorder}`}>
								Approval Level Required
								<br />
								<span style={{ marginTop: "80px" }}>
									<u>UBM </u>
									<u>BM </u>
									<u>APP </u>
								</span>
								<br />
								CEO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.
								<br />
								BM MGR 2.
							</th>
							<th className={`${styles.vertical}`}>Object Type</th>
							<th className={`${styles.vertical}`}>
								<strong>UBM Change Type:</strong>
								<br />
								Change Request/Open Point
								<br />
								Checklist/Protocol/Procedure
								<br />
								Syntax
							</th>
							<th className={`${styles.vertical}`}>
								<strong>Review Point Type:</strong>
								<br />
								UP 1. UBM Protocol
								<br />
								CL 2. Checklist Work Performed
								<br />
								FA 3. Financial Analysis
								<br />
								BE 4. Business Enterprise
								<br />
								Performance
							</th>
							<th className={`${styles.vertical} ${styles.thickLeftBorder}`}>Approved By</th>
							<th className={`${styles.vertical}`}>Date Approved</th>
							<th className={`${styles.vertical} ${styles.thickLeftBorder}`}>
								Assigned To Department/Position
							</th>
							<th className={`${styles.vertical}`}>Assigned To Person</th>
							<th className={`${styles.vertical}`}>Date Assigned</th>
							<th className={`${styles.vertical}`}>Due Date</th>
							<th className={`${styles.vertical}`}>Change Request #</th>
							<th className={`text-nowrap ${styles.thickLeftBorder}`}>
								<span style={{ fontWeight: "bold", fontSize: "20px" }}>Disposition:</span>
								<br />
								-To Do
								<br />
								-Need Feedback
								<br />
								-Ready to Clear
								<br />
								-Complete
								<br />
								-Approved Next Version
								<br />
								-Approved Future Version
								<br />
								-Rejected
							</th>
							<th className={`${styles.vertical} ${styles.thickLeftBorder}`}>Closed By</th>
							<th className={`${styles.vertical}`}>Date Closed</th>
						</tr>
					</thead>
					<tbody>
						{openPoints.map((op, index) => (
							<OpenPointRow
								op={op}
								index={index}
								openPointDefintionMfi={[]}
								createLinkClicked={createLinkClicked}
								navigateToLinkClicked={navigateToLinkClicked}
								deleteLinkClicked={deleteLinkClicked}
								defaults={openPointDefaults.current}
							/>
						))}
					</tbody>
				</table>
				{showCaptureFeedback && <Feedback setShowFeedback={setShowCaptureFeedback} />}
			</div>

			{/*Dialog for selecting the object the open point will link to*/}
			<SelectionDialog
				dialogTitle={"Select the object you wish to link to"}
				rowSelected={linkToRowSelected}
				open={showCreateLinkDialog}
				multipleTabs={true}
				tabs={[
					{
						id: "data-warehouse",
						heading: "Data Warehouse",
						treeData: sharedState.destinationModel,
						props: {
							requireFullObject: true,
							allowSubObjectNavigation: false,
						},
					},
					{
						id: "object-master-file-index",
						heading: topRef.current ? topRef.current.title : "Current Object",
						treeData: topMfiRef.current,
						props: {
							requireFullObject: false,
							allowSubObjectNavigation: true,
						},
					},
				]}
				submitButtonText={"Submit"}
			/>

			{/*Dialog for selecting the attribute the open point will link to*/}
			<SelectionDialog
				dialogTitle={"Select the attribute to link to"}
				treeData={objectMfi}
				treeTitle={objectMfi[0]?.title}
				rowSelected={(row, object) => linkToRowSelected(row, object, "data-warehouse")}
				open={showLinkToDwObjectDialog}
				requireFullObject={false}
				allowSubObjectNavigation={true}
				submitButtonText={"Submit"}
				cancelButtonText={"Cancel"}
			/>

			{/*Dialog for choosing open point filter options*/}
			<DraggableDialog
				style={{ minHeight: "600px", minWidth: "1200px" }}
				open={showFilterDialog}
				handleClose={() => setShowFilterDialog(false)}
				header={"Filter"}
				saveButton={false}
				cancelButtonText={"Close"}
				// handleSave={attributeFilterSave}
				PaperProps={{ style: { minWidth: "900px", width: "75%" } }}
			>
				<CheckboxAndFilterList
					attributes={filterAttributes.current}
					checked={{}}
					updateAttributeFilter={(param, param1, param2) => updateFilter(param1, param2)}
				/>
			</DraggableDialog>
		</Panel>
	);
};

export default OpenPointPanel;

/**
 * Based on:
 * 	Open Point Object
 * 	Stock # 101-541611.3081.OP v6
 *
 * 	Can potentially be incompatible with future versions of the object
 * @param op
 * @param index
 * @returns {JSX.Element}
 * @constructor
 */
const OpenPointRow = ({ op, index, createLinkClicked, navigateToLinkClicked, deleteLinkClicked, defaults }) => {
	const dispatch = useDispatch();
	const [status, setStatus] = useState("");

	//Get the open points status
	useEffect(() => {
		setStatus(getStatus(op));
	}, []);

	useEffect(() => {
		setStatus(getStatus(op));
	}, [op.uuid]);

	const getStatus = (op) => {
		if (op.closedBy && op.dateClosed) {
			return "COMPLETE";
		}

		let { disposition } = op;
		if (!disposition) return "TODO";

		let lowered = disposition.toLowerCase();
		if (lowered.includes("ready for review")) return "READY_FOR_REVIEW";
		else if (lowered.includes("blocked") || lowered.includes("needs feedback")) return "BLOCKED";
		else return "TODO";
	};

	const updateOpenPoint = (op, field, newVal) => {
		if (field === "autofocus") op[field] = newVal;
		else if (op[field] !== newVal) {
			op[field] = newVal;
			dispatch({ type: "UPDATE_CHANGED_OPEN_POINTS", data: op });

			if (field === "disposition") setStatus(getStatus(newVal));
		}
	};

	const getDefaultProperties = (attribute) => {
		let attributeDefault = defaults[attribute];
		return {
			defaultValue: attributeDefault?.defaultValue,
			defaultValueOccurrence: attributeDefault?.defaultValueOccurrence,
		};
	};

	return (
		<tr
			style={{
				color: status === "COMPLETE" ? "gray" : "black",
				backgroundColor:
					status === "BLOCKED"
						? "rgba(255, 192, 203, 0.35)"
						: status === "READY_FOR_REVIEW"
						? "#d6ffd6"
						: "inherit",
			}}
		>
			<td
				style={{
					padding: "0px",
					verticalAlign: "middle",
					textAlign: "center",
					height: "70px",
				}}
			>
				{op.location
					? INPUT_FIELD_TYPES.FILE_UPLOAD.render({
							row: { value: op.location, title: `Open Point ${index + 1}` },
							showUploader: false,
					  })
					: SETUP_SHEET_OUTPUT_FIELD_TYPES.LINK.render({
							linkTo: op.documentReferenceManualMfi,
							navigateToLinkClicked: () => navigateToLinkClicked(op.documentReferenceManualMfi),
							createLinkClicked: () => createLinkClicked(op, "documentReferenceManualMfi"),
							deleteLinkClicked: () => deleteLinkClicked(op, "documentReferenceManualMfi"),
							maxWidth: "80px",
					  })}
			</td>
			{/*Line Number*/}
			<td>{index + 1}</td>
			{/*Priority*/}
			<TdToInputField
				text={op.priority}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.priority,
					handleBlur: (val) => {
						updateOpenPoint(op, "priority", val);
					},
				}}
				defaultProperties={getDefaultProperties("priority")}
			/>
			{/*Opened By*/}
			<TdToInputField
				text={op.openedRequestedBy}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.openedRequestedBy,
					handleBlur: (val) => {
						updateOpenPoint(op, "openedRequestedBy", val);
					},
				}}
				tdProperties={{ className: `${styles.thickLeftBorder}` }}
			/>
			{/*Created At*/}
			<TdToInputField
				text={formatDate(op.dateOpened)}
				renderFunction={INPUT_FIELD_TYPES.DATE.render}
				renderParams={{
					label: "",
					value: op.dateOpened,
					handleBlur: (val) => {
						updateOpenPoint(op, "dateOpened", val);
					},
				}}
			/>
			{/*Legal Entity / Company / CCODE*/}
			<td>{op.legalEntityCompanyCcode}</td>
			{/*Contact Info*/}
			<TdToInputField
				text={op.contactEmail}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.contactEmail,
					handleBlur: (val) => {
						updateOpenPoint(op, "contactEmail", val);
					},
				}}
			/>
			{/*Legal Entity*/}
			<td className={`${styles.thickLeftBorder}`}>{op.legalEntity}</td>
			{/*Model Title*/}
			<TdToInputField
				text={op.modelAppTitle}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.modelAppTitle,
					handleBlur: (val) => {
						updateOpenPoint(op, "modelAppTitle", val);
					},
				}}
			/>
			{/*Model / App ID*/}
			<td>{op.modelAppId}</td>
			{/*Model / App Version, I don't think our open point has this yet*/}
			<td></td>
			{/*New Version or New Document*/}
			<TdToInputField
				text={op.newVersionOrDocument ? "New Version" : "New Document"}
				renderFunction={INPUT_FIELD_TYPES.DROP_DOWN.render}
				renderParams={{
					label: "",
					value: op.newVersionOrDocument,
					handleChange: (val) => {
						if (val === "New Version") val = true;
						else val = false;

						updateOpenPoint(op, "newVersionOrDocument", val);
					},
					optionGroups: [
						{
							title: "Select New Version or New Document",
							options: [
								{ label: "New Version", value: "New Version" },
								{ label: "New Document", value: "New Document" },
							],
						},
					],
				}}
			/>
			{/*Document Title / Origination*/}
			<td>{op.documentTitleOrigination}</td>
			{/*Document Version #*/}
			<td>{op.documentVersion}</td>
			{/*Action Required / Description of Change to be Made*/}
			<TdToInputField
				text={op.actionRequiredDescriptionOfChangeToBeMade}
				renderFunction={INPUT_FIELD_TYPES.TEXT_AREA.render}
				renderParams={{
					label: "",
					value: op.actionRequiredDescriptionOfChangeToBeMade,
					handleBlur: (val) => {
						updateOpenPoint(op, "actionRequiredDescriptionOfChangeToBeMade", val);
						//For some reason this doesn't work with the first focus
						// The user doesn't notice anything off, works fine for now
						updateOpenPoint(op, "autofocus", false);
					},
				}}
				tdProperties={{ className: `${styles.thickLeftBorder}` }}
				autofocus={op.autofocus}
			/>
			{/*Approval Level Required*/}
			<TdToInputField
				text={op.approvalLevelRequired}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.approvalLevelRequired,
					handleBlur: (val) => {
						updateOpenPoint(op, "approvalLevelRequired", val);
					},
				}}
				tdProperties={{ className: `${styles.thickLeftBorder}` }}
			/>
			{/*Object Type*/}
			<td></td>
			{/*UBM Change Type*/}
			<TdToInputField
				text={op.ubmChangeType}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.ubmChangeType,
					handleBlur: (val) => {
						updateOpenPoint(op, "ubmChangeType", val);
					},
				}}
			/>
			{/*Review Point Type*/}
			<TdToInputField
				text={op.reviewPointType}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.reviewPointType,
					handleBlur: (val) => {
						updateOpenPoint(op, "reviewPointType", val);
					},
				}}
			/>
			{/*Approved By*/}
			<TdToInputField
				text={op.approvedBy}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.approvedBy,
					handleBlur: (val) => {
						updateOpenPoint(op, "approvedBy", val);
					},
				}}
				tdProperties={{ className: `${styles.thickLeftBorder}` }}
			/>
			{/*Date Approved*/}
			<TdToInputField
				text={op.dateApproved}
				renderFunction={INPUT_FIELD_TYPES.DATE.render}
				renderParams={{
					label: "",
					value: op.dateApproved,
					handleBlur: (val) => {
						updateOpenPoint(op, "dateApproved", val);
					},
				}}
			/>
			{/*Assigned To Department/Position*/}
			<TdToInputField
				text={op.departmentPositionAssignedTo}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.departmentPositionAssignedTo,
					handleBlur: (val) => {
						updateOpenPoint(op, "departmentPositionAssignedTo", val);
					},
				}}
			/>
			{/*Assigned To Person*/}
			<TdToInputField
				text={op.personAssignedTo}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.personAssignedTo,
					handleBlur: (val) => {
						updateOpenPoint(op, "personAssignedTo", val);
					},
				}}
				tdProperties={{ className: `${styles.thickLeftBorder}` }}
			/>
			{/*Date Assigned*/}
			<TdToInputField
				text={op.dateAssigned}
				renderFunction={INPUT_FIELD_TYPES.DATE.render}
				renderParams={{
					label: "",
					value: op.dateAssigned,
					handleBlur: (val) => {
						updateOpenPoint(op, "dateAssigned", val);
					},
				}}
			/>
			{/*Due Date*/}
			<TdToInputField
				text={op.dueDate}
				renderFunction={INPUT_FIELD_TYPES.DATE.render}
				renderParams={{
					label: "",
					value: op.dueDate,
					handleBlur: (val) => {
						updateOpenPoint(op, "dueDate", val);
					},
				}}
			/>
			{/*Change Request Packet Number*/}
			<td>{op.changeRequest}</td>
			{/*Disposition*/}
			<TdToInputField
				text={op.disposition}
				renderFunction={INPUT_FIELD_TYPES.TEXT_AREA.render}
				renderParams={{
					label: "",
					value: op.disposition,
					handleBlur: (val) => {
						updateOpenPoint(op, "disposition", val);
					},
				}}
				tdProperties={{ className: `${styles.thickLeftBorder}` }}
			>
				{SETUP_SHEET_OUTPUT_FIELD_TYPES.LINK.render({
					linkTo: op.dispositionReference,
					navigateToLinkClicked: () => navigateToLinkClicked(op.dispositionReference),
					createLinkClicked: () => createLinkClicked(op, "dispositionReference"),
					height: "50px",
				})}
			</TdToInputField>
			{/*Closed By*/}
			<TdToInputField
				text={op.closedBy}
				renderFunction={INPUT_FIELD_TYPES.ALPHA_NUMERIC.render}
				renderParams={{
					label: "",
					value: op.closedBy,
					handleBlur: (val) => {
						updateOpenPoint(op, "closedBy", val);
					},
				}}
				tdProperties={{ className: `${styles.thickLeftBorder}` }}
				defaultProperties={getDefaultProperties("closedBy")}
			/>
			{/*Date Closed*/}
			<TdToInputField
				text={op.dateClosed}
				renderFunction={INPUT_FIELD_TYPES.DATE.render}
				renderParams={{
					label: "",
					value: op.dateClosed,
					handleBlur: (val) => {
						updateOpenPoint(op, "dateClosed", val);
					},
				}}
				defaultProperties={getDefaultProperties("dateClosed")}
			/>
		</tr>
	);
};

const TdToInputField = ({
	text: _text,
	renderFunction,
	renderParams,
	value,
	tdProperties = {},
	defaultProperties = {},
	autofocus,
	...other
}) => {
	const [showInputField, setShowInputField] = useState(false);
	const [text, setText] = useState("");

	const sharedState = useTrackedState();

	useEffect(() => {
		if (_text !== text) setText(_text);
	}, [_text]);

	const tdClick = () => {
		if (!showInputField) {
			//If theres not a value and we are focusing the input, check the default properties and if it has default value of onFocus set it
			if (!text && defaultProperties.defaultValueOccurrence === "on-focus") {
				//Check if we need to evaluate an expression or if we just need to set it to a value
				if (defaultProperties.defaultValue.includes("="))
					evaluateExpression(defaultProperties.defaultValue.slice(1), sharedState, {}).then((val) =>
						setText(val)
					);
				else setText(defaultProperties.defaultValue);
			}
			setShowInputField(true);
		}
	};

	if (autofocus) tdClick();

	return (
		<td onClick={tdClick} {...tdProperties}>
			{showInputField
				? renderFunction({
						...renderParams,
						other: { className: `checklist-input fill-cell` },
						value: value !== undefined ? value : text,
						handleBlur: (val) => {
							if (!renderParams.handleBlur) {
								setShowInputField(false);
								return;
							}
							setShowInputField(false);
							setText(val);
							renderParams.handleBlur(val);
						},
						handleChange: (val) => {
							if (renderParams.handleBlur) return;

							setShowInputField(false);
							setText(val);
							renderParams.handleChange(val);
						},
						focus: true,
				  })
				: text}
			{other?.children && !showInputField ? other.children : ""}
		</td>
	);
};

/**
 * Renders a little menu with checkboxes
 * @param options
 * @returns {*}
 * @constructor
 */
const FilterMenu = ({ options }) => {
	return <div></div>;
};

/**
 * Searches the MFI for a child of the linked attribute, if it can't find that searches for a row with the uuid and sets it as the selected row
 * The reason we search for a child first is because the linked row might be a section. We want the setup sheet to pull up the section that are children of the linked row
 * @param mfi
 * @param linkedUuid
 * @param sharedState
 * @param dispatch
 */
export const selectLinkedAttribute = (mfi, linkedUuid, sharedState, dispatch) => {
	//First look for child
	let child = mfi.find((row) => row.parentUuid === linkedUuid);
	if (child) {
		dispatch({ type: "SET_SELECTED_WORKSPACE_ROW", data: child });
		return;
	}

	let selectedRow = mfi.find((row) => row.uuid === linkedUuid);
	//If the selected row is an uploaded document can we trigger it to automatically open the dialog?
	if (INPUT_FIELD_TYPES.FILE_UPLOAD.checkIfFileAttribute(selectedRow, sharedState)) selectedRow.openDialog = true;
	if (selectedRow) dispatch({ type: "SET_SELECTED_WORKSPACE_ROW", data: selectedRow });
};
