export const wopiHost = () => {
	let wopi_host_url = process.env.REACT_APP_WOPI_HOST;

	const scheme = window.location.origin.startsWith("https") ? "https" : "http";

	if (!wopi_host_url) {
		throw new Error(
			"WOPI host address is required, see: https://sdk.collaboraonline.com/docs/Step_by_step_tutorial.html"
		);
	}
	if (!wopi_host_url.startsWith("http")) {
		throw new Error("Error! You have to specify the scheme protocol too (http|https) for the server address.");
	}
	if (!wopi_host_url.startsWith(scheme + "://")) {
		throw new Error("WOPi host server address scheme does not match the current page url scheme");
	}
	return wopi_host_url.endsWith("/") ? wopi_host_url.slice(0, -1) : wopi_host_url;
};

export const wopiClientHost = () => {
	let wopi_client_url = process.env.REACT_APP_WOPI_CLIENT_HOST;

	const scheme = window.location.origin.startsWith("https") ? "https" : "http";

	if (!wopi_client_url) {
		throw new Error(
			"Collabora Online server address is required, see: https://sdk.collaboraonline.com/docs/Step_by_step_tutorial.html"
		);
	}
	if (!wopi_client_url.startsWith("http")) {
		throw new Error("You must specify the protocol scheme (http|https) for the server address.");
	}
	if (!wopi_client_url.startsWith(scheme + "://")) {
		throw new Error("Collabora Online server address scheme does not match the current page url scheme");
	}

	return wopi_client_url.endsWith("/") ? wopi_client_url.slice(0, -1) : wopi_client_url;
};
