import { tokenVocabulary } from "./LexerUtil";
import { CstParser } from "chevrotain";
const FunctionName = tokenVocabulary.FunctionName;
const LParen = tokenVocabulary.LParen;
const RParen = tokenVocabulary.RParen;
const Comma = tokenVocabulary.Comma;
const NumberLiteral = tokenVocabulary.NumberLiteral;
const StringLiteral = tokenVocabulary.StringLiteral;
const JavaScriptObject = tokenVocabulary.JavaScriptObject;
const JavaScriptArray = tokenVocabulary.JavaScriptArray;
const Power = tokenVocabulary.Power;
const AdditionOperator = tokenVocabulary.AdditionOperator;
const MultiplicationOperator = tokenVocabulary.MultiplicationOperator;
const BooleanLiteral = tokenVocabulary.BooleanLiteral;
// ----------------- parser -----------------
class ExpressionParser extends CstParser {
	constructor() {
		super(tokenVocabulary);
		// for conciseness
		const $ = this;
		$.RULE("arithmeticExpression", () => {
			$.SUBRULE($.additionExpression);
		});
		$.RULE("additionExpression", () => {
			$.SUBRULE($.multiplicationExpression, { LABEL: "lhs" });
			$.MANY(() => {
				$.CONSUME(AdditionOperator);
				$.SUBRULE2($.multiplicationExpression, { LABEL: "rhs" });
			});
		});
		$.RULE("multiplicationExpression", () => {
			$.SUBRULE($.exponentiationExpression, { LABEL: "lhs" });
			$.MANY(() => {
				$.CONSUME(MultiplicationOperator);
				$.SUBRULE2($.exponentiationExpression, { LABEL: "rhs" });
			});
		});
		$.RULE("exponentiationExpression", () => {
			$.SUBRULE($.atom, { LABEL: "lhs" });
			$.MANY(() => {
				$.CONSUME(Power);
				$.SUBRULE2($.atom, { LABEL: "rhs" });
			});
		});
		$.RULE("atom", () => {
			$.OR([{ ALT: () => $.SUBRULE($.parenthesisExpression) }, { ALT: () => $.CONSUME(NumberLiteral) }]);
		});
		$.RULE("parenthesisExpression", () => {
			$.CONSUME(LParen);
			$.SUBRULE($.arithmeticExpression);
			$.CONSUME(RParen);
		});
		$.RULE("expression", () => {
			$.OR([{ ALT: () => $.SUBRULE($.fn) }, { ALT: () => $.SUBRULE($.arithmeticExpression) }]);
		});
		$.RULE("fn", () => {
			$.CONSUME(FunctionName);
			$.CONSUME(LParen);
			$.SUBRULE($.parameters);
			$.CONSUME(RParen);
		});
		$.RULE("parameters", () => {
			$.OPTION(() => {
				$.SUBRULE($.parameter);
			});
			$.MANY(() => {
				$.CONSUME(Comma);
				$.OPTION2(() => $.SUBRULE2($.parameter));
			});
		});
		$.RULE("parameter", () => {
			$.OR([
				{ ALT: () => $.SUBRULE($.expression) },
				{ ALT: () => $.CONSUME(StringLiteral) },
				{ ALT: () => $.CONSUME(BooleanLiteral) },
				{ ALT: () => $.CONSUME(JavaScriptObject) },
				{ ALT: () => $.CONSUME(JavaScriptArray) },
			]);
		});
		// very important to call this after all the rules have been defined.
		// otherwise the parser may not work correctly as it will lack information
		// derived during the self analysis phase.
		this.performSelfAnalysis();
	}
}

export { ExpressionParser };
