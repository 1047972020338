import { useEffect } from "react";
import { Link } from "react-router-dom";

const ResourcesPage = () => {
	useEffect(() => {
		document.title = "Resources";
	});

	return (
		<div className="container">
			<div className="row mt-3">
				<div className="col"></div>
				<div className="col text-center">
					<h1>Resources</h1>
					<div className="mx-auto">
						<ul className="list-unstyled mt-3">
							<li>
								<Link to="/support">Contact Us</Link>
							</li>
							<li>
								<Link to="/resources">Documentation</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="col"></div>
			</div>
		</div>
	);
};

export default ResourcesPage;
