/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */
import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import { useForkRef } from "@mui/material";
import TreeViewContext from "@mui/lab/TreeView/TreeViewContext";
import "./Tree.scss";
import stylesModule from "./TreeItem.module.scss";

const isPrintableCharacter = (str) => {
	return str && str.length === 1 && str.match(/\S/);
};

const TreeItem = React.forwardRef(function TreeItem(props, ref) {
	const {
		children,
		classes,
		className,
		collapseIcon,
		endIcon,
		expandIcon,
		icon: iconProp,
		label,
		checkbox,
		nodeId,
		onClick,
		onLabelClick,
		onIconClick,
		onFocus,
		onKeyDown,
		onMouseDown,
		TransitionComponent = Collapse,
		TransitionProps,
		styledNode,
		childNodes,
		setOpen,
		paddingAfterCheckbox = false,
		...other
	} = props;

	const {
		icons: contextIcons,
		focus,
		focusFirstNode,
		focusLastNode,
		focusNextNode,
		focusPreviousNode,
		focusByFirstCharacter,
		selectNode,
		selectRange,
		selectNextNode,
		selectPreviousNode,
		rangeSelectToFirst,
		rangeSelectToLast,
		selectAllNodes,
		expandAllSiblings,
		toggleExpansion,
		isExpanded,
		isFocused,
		isSelected,
		isTabbable,
		multiSelect,
		getParent,
		mapFirstChar,
		addNodeToNodeMap,
		removeNodeFromNodeMap,
	} = React.useContext(TreeViewContext);

	const nodeRef = React.useRef(null);
	const contentRef = React.useRef(null);
	const handleRef = useForkRef(nodeRef, ref);

	let icon = iconProp;

	const expandable = Boolean(Array.isArray(childNodes) ? childNodes.length : childNodes);
	const expanded = isExpanded ? isExpanded(nodeId) : false;
	const focused = isFocused ? isFocused(nodeId) : false;
	const tabbable = isTabbable ? isTabbable(nodeId) : false;
	const selected = isSelected ? isSelected(nodeId) : false;
	const icons = contextIcons || {};

	if (!icon) {
		if (expandable) {
			if (!expanded) {
				icon = expandIcon || icons.defaultExpandIcon;
			} else {
				icon = collapseIcon || icons.defaultCollapseIcon;
			}

			if (!icon) {
				icon = icons.defaultParentIcon;
			}
		} else {
			icon = endIcon || icons.defaultEndIcon;
		}
	}

	const handleClick = (event, doubleClick = false) => {
		if (!focused) {
			focus(nodeId);
		}

		const multiple = multiSelect && (event.shiftKey || event.ctrlKey || event.metaKey);

		// If already expanded and trying to toggle selection don't close
		if (expandable && !event.defaultPrevented && !(multiple && isExpanded(nodeId))) {
			toggleExpansion(event, nodeId);
			setOpen(!expanded);
		}

		if (multiple) {
			if (event.shiftKey) {
				selectRange(event, { end: nodeId });
			} else {
				selectNode(event, nodeId, true);
			}
		} else {
			selectNode(event, nodeId);
		}

		if (onClick) {
			onClick(event, doubleClick);
		}
	};

	// const click = useSingleAndDoubleClick(handleClick, (e) => handleClick(e, true));

	const handleMouseDown = (event) => {
		if (event.shiftKey || event.ctrlKey || event.metaKey) {
			event.preventDefault();
		}

		if (onMouseDown) {
			onMouseDown(event);
		}
	};

	const handleNextArrow = (event) => {
		if (expandable) {
			if (expanded) {
				focusNextNode(nodeId);
			} else {
				toggleExpansion(event);
			}
		}
		return true;
	};

	const handlePreviousArrow = (event) => {
		if (expanded) {
			toggleExpansion(event, nodeId);
			return true;
		}

		const parent = getParent(nodeId);
		if (parent) {
			focus(parent);
			return true;
		}
		return false;
	};

	const handleKeyDown = (event) => {
		let flag = false;
		const key = event.key;

		if (event.altKey || event.currentTarget !== event.target) {
			return;
		}

		const ctrlPressed = event.ctrlKey || event.metaKey;

		switch (key) {
			case " ":
				if (nodeRef.current === event.currentTarget) {
					if (multiSelect && event.shiftKey) {
						flag = selectRange(event, { end: nodeId });
					} else if (multiSelect) {
						flag = selectNode(event, nodeId, true);
					} else {
						flag = selectNode(event, nodeId);
					}
				}
				event.stopPropagation();
				break;
			case "Enter":
				if (nodeRef.current === event.currentTarget && expandable) {
					toggleExpansion(event);
					flag = true;
				}
				event.stopPropagation();
				break;
			case "ArrowDown":
				if (multiSelect && event.shiftKey) {
					selectNextNode(event, nodeId);
				}
				focusNextNode(nodeId);
				flag = true;
				break;
			case "ArrowUp":
				if (multiSelect && event.shiftKey) {
					selectPreviousNode(event, nodeId);
				}
				focusPreviousNode(nodeId);
				flag = true;
				break;
			case "ArrowRight":
				if ("rtl" === "rtl") {
					flag = handlePreviousArrow(event);
				} else {
					flag = handleNextArrow(event);
				}
				break;
			case "ArrowLeft":
				if ("rtl" === "rtl") {
					flag = handleNextArrow(event);
				} else {
					flag = handlePreviousArrow(event);
				}
				break;
			case "Home":
				if (multiSelect && ctrlPressed && event.shiftKey) {
					rangeSelectToFirst(event, nodeId);
				}
				focusFirstNode();
				flag = true;
				break;
			case "End":
				if (multiSelect && ctrlPressed && event.shiftKey) {
					rangeSelectToLast(event, nodeId);
				}
				focusLastNode();
				flag = true;
				break;
			default:
				if (key === "*") {
					expandAllSiblings(event, nodeId);
					flag = true;
				} else if (multiSelect && ctrlPressed && key.toLowerCase() === "a") {
					flag = selectAllNodes(event);
				} else if (!ctrlPressed && !event.shiftKey && isPrintableCharacter(key)) {
					focusByFirstCharacter(nodeId, key);
					flag = true;
				}
		}

		if (flag) {
			event.preventDefault();
			event.stopPropagation();
		}

		if (onKeyDown) {
			onKeyDown(event);
		}
	};

	const handleFocus = (event) => {
		if (!focused && event.currentTarget === event.target) {
			focus(nodeId);
		}

		if (onFocus) {
			onFocus(event);
		}
	};

	React.useEffect(() => {
		if (addNodeToNodeMap) {
			const childIds = [];
			childNodes.forEach((child) => {
				if (React.isValidElement(child) && child.props.nodeId) {
					childIds.push(child.props.nodeId);
				}
			});
			addNodeToNodeMap(nodeId, childIds);
		}
	}, [childNodes, nodeId, addNodeToNodeMap]);

	React.useEffect(() => {
		if (removeNodeFromNodeMap) {
			return () => {
				removeNodeFromNodeMap(nodeId);
			};
		}
		return undefined;
	}, [nodeId, removeNodeFromNodeMap]);

	React.useEffect(() => {
		if (mapFirstChar && label) {
			mapFirstChar(nodeId, contentRef.current.textContent.substring(0, 1).toLowerCase());
		}
	}, [mapFirstChar, nodeId, label]);

	React.useEffect(() => {
		if (focused) {
			nodeRef.current.focus();
		}
	}, [focused]);

	let ariaSelected;
	if (multiSelect) {
		ariaSelected = selected;
	} else if (selected) {
		ariaSelected = true;
	}

	return (
		<div
			className={clsx(className, stylesModule.tree, {
				[stylesModule.expanded]: expanded,
				[stylesModule.selected]: selected,
				[stylesModule.dragUnderNode]: styledNode.id === nodeId && styledNode.section.third === "bottom",
				[stylesModule.dragInNode]: styledNode.id === nodeId && styledNode.section.third === "middle",
				[stylesModule.dragOverNode]: styledNode.id === nodeId && styledNode.section.third === "top",
			})}
			role="treeitem"
			onKeyDown={handleKeyDown}
			onFocus={handleFocus}
			aria-expanded={expandable ? expanded : null}
			aria-selected={ariaSelected}
			ref={handleRef}
			tabIndex={tabbable ? 0 : -1}
			{...other}
			style={paddingAfterCheckbox ? { ...other.style, paddingLeft: 0 } : { ...other.style }}
		>
			<div
				style={{ whiteSpace: "nowrap", overflow: "hidden" }}
				className={stylesModule.content}
				onClick={handleClick}
				onMouseDown={handleMouseDown}
				ref={contentRef}
			>
				{paddingAfterCheckbox ? checkbox : ""}
				<div style={{ display: "inline-block" }} onClick={onIconClick} className={stylesModule.iconContainer}>
					{icon}
				</div>
				<div
					style={{ display: "inline-block", width: "calc(100% - 15px)" }}
					onClick={onLabelClick}
					className={stylesModule.label}
				>
					{label}
				</div>
			</div>
			{childNodes && (
				<TransitionComponent
					unmountOnExit
					className={stylesModule.group}
					in={expanded}
					component="ul"
					role="group"
					{...TransitionProps}
				></TransitionComponent>
			)}
		</div>
	);
});

TreeItem.propTypes = {
	// ----------------------------- Warning --------------------------------
	// | These PropTypes are generated from the TypeScript type definitions |
	// |     To update them edit the d.ts file and run "yarn proptypes"     |
	// ----------------------------------------------------------------------
	/**
	 * The content of the component.
	 */
	children: PropTypes.node,
	/**
	 * Override or extend the styles applied to the component.
	 * See [CSS API](#css) below for more details.
	 */
	classes: PropTypes.object,
	/**
	 * @ignore
	 */
	className: PropTypes.string,
	/**
	 * The icon used to collapse the node.
	 */
	collapseIcon: PropTypes.node,
	/**
	 * The icon displayed next to a end node.
	 */
	endIcon: PropTypes.node,
	/**
	 * The icon used to expand the node.
	 */
	expandIcon: PropTypes.node,
	/**
	 * The icon to display next to the tree node's label.
	 */
	icon: PropTypes.node,
	/**
	 * The tree node label.
	 */
	label: PropTypes.node,
	/**
	 * The id of the node.
	 */
	nodeId: PropTypes.string.isRequired,
	/**
	 * @ignore
	 */
	onClick: PropTypes.func,
	/**
	 * @ignore
	 */
	onFocus: PropTypes.func,
	/**
	 * `onClick` handler for the icon container. Call `event.preventDefault()` to prevent `onNodeToggle` from being called.
	 */
	onIconClick: PropTypes.func,
	/**
	 * @ignore
	 */
	onKeyDown: PropTypes.func,
	/**
	 * `onClick` handler for the label container. Call `event.preventDefault()` to prevent `onNodeToggle` from being called.
	 */
	onLabelClick: PropTypes.func,
	/**
	 * @ignore
	 */
	onMouseDown: PropTypes.func,
	/**
	 * The component used for the transition.
	 * [Follow this guide](/components/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.
	 */
	TransitionComponent: PropTypes.elementType,
	/**
	 * Props applied to the [`Transition`](http://reactcommunity.org/react-transition-group/transition#Transition-props) element.
	 */
	TransitionProps: PropTypes.object,
};

export default TreeItem;

export const useSingleAndDoubleClick = (actionSimpleClick, actionDoubleClick, delay = 150) => {
	const [click, setClick] = useState(0);

	const event = useRef(null);

	useEffect(() => {
		const timer = setTimeout(() => {
			// simple click
			if (click === 1) actionSimpleClick(event.current);
			setClick(0);
		}, delay);

		// the duration between this click and the previous one
		// is less than the value of delay = double-click
		if (click === 2) actionDoubleClick(event.current);

		return () => clearTimeout(timer);
	}, [click]);

	return (e) => {
		event.current = e;
		setClick((prev) => prev + 1);
	};
};
