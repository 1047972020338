import React, { useEffect } from "react";
import "./UserMessage.scss";
import {
	CloseIcon,
	ErrorIcon,
	InfoIcon,
	SuccessIcon,
	WarningIcon,
} from "../components/BootstrapComponents/Icons/Icons";

export const Popup = ({ state, message, onClose, prompt }) => {
	useEffect(() => {
		setTimeout(() => {
			onClose();
		}, 5000);
	}, []);

	let alertClass, icon;
	if (state === "basic") {
		icon = <InfoIcon height={64} width={64} color={"white"} />;
		alertClass = "primary";
	} else if (state === "success") {
		icon = <SuccessIcon height={64} width={64} color={"white"} />;
		alertClass = "success";
	} else if (state === "warn") {
		icon = <WarningIcon height={64} width={64} color={"white"} />;
		alertClass = "warning";
	} else if (state === "error") {
		icon = <ErrorIcon height={64} width={64} color={"white"} />;
		alertClass = "error";
	}

	return (
		<div className={`alert alert-${alertClass}`}>
			<span className="mdi mdi-close close" onClick={() => onClose()}>
				<CloseIcon color="white" width="24" height="24" />
			</span>
			<div className="icon__wrapper">{icon}</div>
			<p>{message}</p>
			{prompt ? (
				<div>
					<button className={"btn btn-outline-warning"}>Continue</button>
					<button className={"btn btn-outline-danger"}>Cancel</button>
				</div>
			) : (
				""
			)}
		</div>
	);
};
