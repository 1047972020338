import React, { useEffect, useRef, useState } from "react";
import Editor from "@monaco-editor/react";
import { Tooltip } from "@mui/material";
import { ContractIcon, ExpandIcon, InfoIcon } from "../BootstrapComponents/Icons/Icons";
import ReactGrid from "../ReactGrid/ReactGrid";
import "./ReactEditor.scss";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";
import { useMonaco } from "@monaco-editor/react";
import { useDispatch, useTrackedState } from "../../utils/store";
import { getComponentsFromMfi, getMethodsFromMfi } from "../../utils/StandardObject";

const languages = [
	{
		label: "Javascript",
		value: "javascript",
	},
	{
		label: "HTML",
		value: "html",
	},
	{
		label: "JSON",
		value: "json",
	},
	{
		label: "CSS",
		value: "css",
	},
];

const ReactEditor = ({ label, value: _val, handleBlur, help, components = [], methods = [] }) => {
	const value = useRef("");
	const [language, setLanguage] = useState("javascript");
	const [fullScreen, setFullScreen] = useState(false);
	const [completionDisposables, setCompletionDisposables] = useState();

	const randomNumber = useRef(Math.floor(Math.random() * 100));
	const editorDivRef = useRef();
	const addedReactEditorSuggestions = useRef(false);

	const monaco = useMonaco();

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			if (completionDisposables?.length > 0) {
				completionDisposables.forEach((completionDisposable) => {
					if (completionDisposable?.dispose && typeof completionDisposable.dispose === "function") {
						completionDisposable.dispose();
					}
				});
			}
		};
	}, [completionDisposables]);

	useEffect(() => {
		let components = getComponentsFromMfi(sharedState.contextMfi);
		let methods = getMethodsFromMfi(sharedState.contextMfi);

		addedReactEditorSuggestions.current = true;
		dispatch({ type: "SET_ADDED_REACT_EDITOR_SUGGESTIONS", data: true });
		let disposables = [];
		languages.forEach((lang, index) => {
			disposables[index] = monaco.languages.registerCompletionItemProvider(lang.value, {
				provideCompletionItems: function () {
					return {
						suggestions: [
							{
								label: "attachment_point",
								documentation: "Attaches the code for all sub-objects here",
								insertText: "{attachment_point}",
							},
							{
								label: "methods",
								documentation:
									"Attaches all methods within this object that have not already referenced",
								insertText: "{methods}",
							},
							{
								label: "standardObject: mfi",
								documentation: "Attaches the full Object MFI",
								insertText: "{standardObject: mfi}",
							},
							{
								label: "standardObject: stockNumber",
								documentation: `Attaches the current object's stock number`,
								insertText: "{standardObject: stockNumber}",
							},
							{
								label: "standardObject: title",
								documentation: `Attaches the current object's title`,
								insertText: "{standardObject: title}",
							},
							{
								label: "standardObject: reference",
								documentation: `Attaches the current object's reference`,
								insertText: "{standardObject: reference}",
							},
							{
								label: "standardObject: dataWarehouseUuid",
								documentation: `Attaches the current data warehouse uuid`,
								insertText: "{standardObject: dataWarehouseUuid}",
							},
							{
								label: "standardObject: harvestAndCamelize",
								documentation: `Attaches the harvested version of the full object mfi`,
								insertText: "{standardObject: harvestAndCamelize}",
							},
							...components.map((component) => ({
								label: component.title,
								insertText: `{var: ${component.path}}`,
							})),
							...methods.map((method) => ({
								label: method.title,
								insertText: `{method: ${method.title}}`,
							})),
						],
					};
				},
			});
		});

		setCompletionDisposables(disposables);
	}, []);

	useEffect(() => {
		value.current = _val;
	}, [_val]);

	/**
	 * Triggered when the editor value changes, updates the value in the state
	 */
	const handleChange = (val) => {
		value.current = val;
	};

	//Its still adding this multiple times, but now it only does the first time

	const toggleFullScreen = () => {
		setFullScreen((prev) => !prev);
	};

	return (
		<div
			style={
				fullScreen
					? {
							height: "calc(100% - 120px)",
							width: "100%",
							position: "fixed",
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							backgroundColor: "white",
							zIndex: 200,
					  }
					: {
							height: "100%",
							width: "100%",
					  }
			}
		>
			{label}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				{INPUT_FIELD_TYPES.DROP_DOWN.render({
					value: "javascript",
					handleChange: (newVal) => setLanguage(newVal),
					optionGroups: [
						{
							title: "Languages",
							options: languages,
						},
					],
					other: {
						divStyle: {
							maxWidth: "110px",
							marginBottom: "4px",
						},
						labelStyle: {
							textAlign: "center",
							marginTop: "5px",
						},
					},
				})}
				<button className={"btn btn-link"} onClick={toggleFullScreen}>
					{fullScreen ? <ContractIcon /> : <ExpandIcon />}
				</button>
			</div>
			{!fullScreen ? (
				<ReactGrid
					layout={[
						{
							x: 0,
							y: 0,
							h: 1,
							resizable: true,
							isBounded: true,
							i: `${label}-react-editor-${randomNumber}`,
							staticY: true,
							static: true,
						},
					]}
					allowResize={true}
					id={"setup-sheet-windows"}
					resizeHandles={["se"]}
				>
					<div key={`${label}-react-editor-${randomNumber}`} className={"react-editor-div"}>
						<Editor
							height="100%"
							language={language}
							defaultValue={value.current || _val || "// some comment"}
							theme="vs-dark"
							onChange={handleChange}
							onMount={(editor) => {
								editor.onDidBlurEditorWidget(() => {
									handleBlur(value.current);
								});
							}}
						/>
					</div>
				</ReactGrid>
			) : (
				<div style={{ height: "100%" }}>
					<Editor
						height="100%"
						language={language}
						defaultValue={value.current || _val || "// some comment"}
						theme="vs-dark"
						onChange={handleChange}
						onMount={(editor) => {
							editor.onDidBlurEditorWidget(() => {
								handleBlur(value.current);
							});
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default ReactEditor;
