import React, { useState, useEffect, useRef } from "react";
import stylesModule from "../SetupFormWindow/Window.module.scss";
import TitleAutoComplete from "../Autocomplete/TitleAutocomplete";
import { useTrackedState } from "../../utils/store";
import AssociationSetupType from "../AssociationSetupType/AssociationSetupType";
import BootstrapAccordion from "../BootstrapComponents/Accordion/BootstrapAccordion";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";
import { getSingleLevelObjectMfi } from "../../utils/ApiUtils";
import { getComponentsFromMfi } from "../../utils/StandardObject";

const OBJECT_TYPE_TITLE = "Object Type";
const FILTERED_ATTRIBUTES_TITLE = "Filtered Attributes";
const CRITERIA_TITLE = "Criteria";
const SIBLING_DATA_SOURCE_TITLE = "Sibling Data Source";
const JOIN_ATTRIBUTE_TITLE = "Join on";

/**
 * Stock # - 116-511210.228.RCFNCMPT.415101: 0 - ReportDataSource
 * Renders the sheet components allowing the user to build a report data source
 * @param {}
 * @constructor
 */
const ReportDataSource = ({ label, field, updateRow: _updateRow, focus }) => {
	//state variables
	const [sourceType, setSourceType] = useState({});

	const dataSourceMfi = useRef([]);

	const sharedState = useTrackedState();

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {}, []);

	useEffect(() => {
		if (field?.uuid && field?.versionUuid) {
			getDataSourceMfi();
		}
	}, [field?.uuid, field?.versionUuid]);

	//Other Methods
	const getDataSourceMfi = async () => {
		let mfi = await getSingleLevelObjectMfi(field.uuid, field.versionUuid);
		let obj = {};

		mfi = getComponentsFromMfi(mfi);
		let type = mfi.find((row) => row.title === OBJECT_TYPE_TITLE);
		if (type) {
			obj.objectTypeUuid = type.linkToObjectUuid;
			obj.objectTypeVersionUuid = type.linkToObjectVersionUuid;
		}

		let filteredAttributes = mfi.find((row) => row.title === FILTERED_ATTRIBUTES_TITLE);
		if (filteredAttributes) obj.filterUuid = filteredAttributes.filterUuid;

		//The data for criteria is stored in the same place as the filter so we don't need to do anything with this attribute right now
		let criteria = mfi.find((row) => row.title === CRITERIA_TITLE);

		let siblingDataSource = mfi.find((row) => row.title === SIBLING_DATA_SOURCE_TITLE);
		let joinAttribute = mfi.find((row) => row.title === JOIN_ATTRIBUTE_TITLE);

		obj.siblingDataSource = {
			linkToAttributeUuid: siblingDataSource.linkToAttributeUuid,
			joinAttribute: joinAttribute.linkToAttributeUuid,
		};

		dataSourceMfi.current = mfi;
		setSourceType(obj);
	};

	const updateRow = (_, update) => {
		let { linkToObjectUuid, linkToObjectVersionUuid, filterUuid, joinToDataSource, joinOnAttribute } = update;

		if (linkToObjectUuid && linkToObjectVersionUuid) {
			let objectType = dataSourceMfi.current.find((row) => row.title === OBJECT_TYPE_TITLE);
			objectType.linkToObjectUuid = linkToObjectUuid;
			objectType.linkToObjectVersionUuid = linkToObjectVersionUuid;

			//Call update row passing in the field as the sub-object to update and the top as the object to update
			_updateRow(field, [objectType]);
		}

		if (filterUuid) {
			let filter = dataSourceMfi.current.find((row) => row.title === FILTERED_ATTRIBUTES_TITLE);
			let criteria = dataSourceMfi.current.find((row) => row.title === CRITERIA_TITLE);

			filter.filterUuid = filterUuid;
			criteria.filterUuid = filterUuid;

			_updateRow(field, [filter, criteria]);
		}

		//We have to check against undefined here because we pass an empty string which is falsy
		if (joinToDataSource !== undefined) {
			let siblingDataSource = dataSourceMfi.current.find((row) => row.title === SIBLING_DATA_SOURCE_TITLE);
			siblingDataSource.linkToAttributeUuid = joinToDataSource;

			_updateRow(field, [siblingDataSource]);
		}

		if (joinOnAttribute) {
			let joinAttribute = dataSourceMfi.current.find((row) => row.title === JOIN_ATTRIBUTE_TITLE);
			joinAttribute.linkToAttributeUuid = joinOnAttribute;

			_updateRow(field, [joinAttribute]);
		}
	};

	return (
		<>
			<BootstrapAccordion titles={[label]} activeIndex={0}>
				{[
					<>
						{/*<h5>Data Source</h5>*/}
						<AssociationSetupType
							objectType={{
								uuid: sourceType.objectTypeUuid,
								versionUuid: sourceType.objectTypeVersionUuid,
							}}
							filterUuid={sourceType.filterUuid}
							linkToSourceIsDataWarehouse={true}
							showCardinality={false}
							objectTypes={sharedState.objects}
							updateRow={updateRow}
							focus={focus}
						/>

						<JoinDataSource
							uuid={field?.uuid}
							objectTypeUuid={sourceType.objectTypeUuid}
							linkedDataSourceUuid={sourceType.siblingDataSource?.linkToAttributeUuid}
							joinedAttributeUuid={sourceType.siblingDataSource?.joinAttribute}
							updateRow={updateRow}
						/>
					</>,
				]}
			</BootstrapAccordion>
		</>
	);
};

export default React.memo(ReportDataSource, (prevProps, nextProps) => {
	let { label: prevLabel, field: prevField, focus: prevFocus } = prevProps;
	let { label: nextLabel, field: nextField, focus: nextFocus } = nextProps;

	if (
		prevLabel !== nextLabel ||
		prevField.uuid !== nextField.uuid ||
		prevField.versionUuid !== nextField.versionUuid ||
		prevFocus !== nextFocus
	)
		return false;

	return true;
});

const JoinDataSource = ({ uuid, objectTypeUuid, linkedDataSourceUuid, joinedAttributeUuid, updateRow }) => {
	const [linkToAttribute, setLinkToAttribute] = useState();
	const [joinedAttribute, setJoinedAttribute] = useState();
	const [joinedDataSourceAttributes, setJoinedDataSourceAttributes] = useState([]);
	const sharedState = useTrackedState();

	useEffect(() => {
		if (uuid && linkedDataSourceUuid && linkToAttribute?.uuid !== linkedDataSourceUuid) {
			getJoinInfo(linkedDataSourceUuid);
		}

		/**
		 *  How should this work:
		 *  I could have a Person Data Source and join it to a Position Data Source.
		 *  I could join them on the attribute Position Filled By
		 *  This would give me all the people that fill a position.
		 *  If the Position Data Source had a filter like on a specific Org Chart
		 *      It would give all position on a specific org chart that are filled by a person
		 *
		 *  Dance Card Scenario:
		 *  I have a Data Source referencing the Men, one referencing the Women, and one referencing the Dance Cards between them.
		 *  The men will be joined with the dance cards on the 'owner' attribute
		 *  The women will be joined with the dance cards on the 'People on Dance Card' attribute
		 *
		 *  In this case I want to grab:
		 *      1. All the girls, maybe with a filter
		 *      2. All the boys based on the filter
		 *      3. All the dance cards for the boys
		 *
		 *    This is where the join types come in.
		 *    Do we want a join type for the Report and between data?
		 *
		 *  As a user building the dance card report what would I start with?
		 *
		 *  I could have it grab all by filter unless it is joined to a data source, in that case it would
		 *  also filter it by that data source
		 */
	}, [linkedDataSourceUuid]);

	const getJoinInfo = async (joinToSource) => {
		//Get the link to data source
		let { contextMfi: mfi } = sharedState;
		let attribute = mfi.find((row) => row.uuid === joinToSource);
		if (!attribute) return;

		setLinkToAttribute(attribute);

		//Grab the MFI for the linked data source and get its object type
		let linkToSourceMfi = await getSingleLevelObjectMfi(attribute.uuid, attribute.versionUuid);
		linkToSourceMfi = getComponentsFromMfi(linkToSourceMfi);

		//Apply state changes to the linkToSourceMfi
		//Build changes from global state
		// let changes =

		//Apply changes to linkToSourceMfi

		let objectType = linkToSourceMfi.find((row) => row.title === OBJECT_TYPE_TITLE);

		//Get the object type's mfi so we can get its attribute
		let { linkToObjectUuid, linkToObjectVersionUuid } = objectType;
		if (linkToObjectUuid && linkToObjectVersionUuid) {
			//TODO: Do I want to get by just the UUID so it's always updated? Find the latest version in the Data Warehouse? Exact version that was linked? In this case it might be worth grabbing the version in the destination model?
			let destinationVersions = sharedState.destinationModel.filter(
				(row) => row.standardObjectUuid === linkToObjectUuid
			);
			destinationVersions.sort((a, b) => b.computerVersion - a.computerVersion);
			let objectTypeMfi = await getSingleLevelObjectMfi(
				linkToObjectUuid,
				destinationVersions[0].standardObjectVersionUuid
			);
			setJoinedDataSourceAttributes(
				getComponentsFromMfi(objectTypeMfi).filter((row) => row.linkToObjectUuid === objectTypeUuid)
			);

			//Get the joined attribute
			let joinedAttribute = objectTypeMfi.find((row) => row.uuid === joinedAttributeUuid);
			if (joinedAttribute) setJoinedAttribute(joinedAttribute);
		}
	};

	const handleJoinToDataSourceChange = (fieldTitle, newValue) => {
		//If we don't receive a value, assume it is empty and reset it along with the joined attribute
		if (!newValue) {
			setLinkToAttribute({});

			updateRow("", { joinToDataSource: "" });
			return;
		}

		if (newValue.uuid) {
			setLinkToAttribute(newValue);
			getJoinInfo(newValue.uuid);
			updateRow("", { joinToDataSource: newValue.uuid });
		}
	};

	const handleJoinAttributeChange = (field, newValue) => {
		if (!newValue) {
			setJoinedAttribute({});
			updateRow("", { joinOnAttribute: {} });
			return;
		}

		if (newValue.uuid) {
			setJoinedAttribute(newValue);
			updateRow("", { joinOnAttribute: newValue.uuid });
		}
	};

	return (
		<>
			<TitleAutoComplete
				name={"title"}
				label={"Join With Data Source"}
				value={linkToAttribute?.title}
				options={sharedState.contextMfi?.filter(
					(row) =>
						row.objectTypeUuid === sharedState.dbConstants.dataSourceObject.referenceUuid &&
						row.uuid !== uuid
				)}
				objectOptions
				handleChange={handleJoinToDataSourceChange}
				handleBlur={(newVal) => handleJoinToDataSourceChange("objectTypeUuid", newVal)}
				allowNew={false}
				clearOnBlur={false}
			/>
			{
				//When there is a dataSource the user can choose an attribute that links these two types together to join to
				linkToAttribute?.uuid ? (
					<div style={{ marginTop: "10px" }}>
						<TitleAutoComplete
							name={"title"}
							label={"Join on Attribute"}
							value={joinedAttribute?.title}
							options={joinedDataSourceAttributes}
							objectOptions
							handleChange={handleJoinAttributeChange}
							handleBlur={(newVal) => handleJoinAttributeChange("objectTypeUuid", newVal)}
							allowNew={false}
							clearOnBlur={false}
						/>
					</div>
				) : (
					""
				)
			}
		</>
	);
};
