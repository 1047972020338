import { sortByReference } from "./Referencing";
import { ZERO_ROW_UUID } from "./StandardObject";
import { getCall, getUrl } from "./ApiUtils";

/**
 * This is where we manage our db constants
 * @param props
 * @constructor
 */

//Get DB Constants
/**
 * Get the object type constant
 * @returns {Promise<Response|never>}
 */
export const getDbConst = async (reference, name, sharedState, dispatch) => {
	if (sharedState.dbConstants[name]) return sharedState.dbConstants[name].referenceUuid;

	let objTpConst = await getCall(getUrl("getDbConstByRef", [reference]));
	if (dispatch) dispatch({ type: "SET_DB_CONSTANT", constant: name, data: objTpConst });
	return objTpConst?.referenceUuid;
};

export const getChangeRequestPacketConstUuid = async (sharedState, dispatch) => {
	return await getDbConst("CHRQPKT", "changeRequestPacket", sharedState, dispatch);
};

export const getFixPacketConstUuid = async (sharedState, dispatch) => {
	return await getDbConst("FIXPKT", "fixPacket", sharedState, dispatch);
};

export const getPatchPacketConstUuid = async (sharedState, dispatch) => {
	return await getDbConst("PATCHPKT", "patchPacket", sharedState, dispatch);
};

export const getPacketConstUuid = async (sharedState, dispatch) => {
	return await getDbConst("PK", "packetObject", sharedState, dispatch);
};
