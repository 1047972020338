import { useEffect, useState } from "react";
import stylesModule from "./Notifications.module.scss";

// Interval: The time in seconds to check for network connectivity
const Notification = ({
	uuid,
	createdAt,
	notificationType,
	message,
	objectTitle,
	objectUuid,
	objectVersionUuid,
	read,
	updateNotification,
}) => {
	const [timeSinceNotification, setTimeSinceNotification] = useState();

	useEffect(() => {
		//Get data from notification
		if (createdAt) setTimeSinceNotification(calcTimeSince(createdAt));
	}, []);

	const calcTimeSince = (date) => {
		const then = new Date(date);
		const now = new Date();
		const seconds = Math.floor((now - then) / 1000);
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		const months = Math.floor(days / 30); // Approximation, as months can vary in length
		const years = Math.floor(days / 365);

		if (years > 0) {
			return `${years} year${years !== 1 ? "s" : ""} ago`;
		} else if (months > 0) {
			return `${months} month${months !== 1 ? "s" : ""} ago`;
		} else if (days > 0) {
			return `${days} day${days !== 1 ? "s" : ""} ago`;
		} else if (hours > 0) {
			return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
		} else if (minutes > 0) {
			return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
		} else {
			return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
		}
	};

	const markAsRead = (e) => {
		if (!read) updateNotification(uuid, "read", true);
	};

	const notificationClick = () => {
		window.open(`?uuid=${objectUuid}&versionUuid=${objectVersionUuid}`, "_blank").focus();
	};

	return (
		<div
			className={`${stylesModule["notification"]} ${read ? stylesModule["read"] : ""}`}
			onMouseLeave={markAsRead}
		>
			<div className={`${stylesModule["header"]}`}>
				<span className={`${stylesModule["secondaryText"]}`}>{notificationType}</span>
				<span className={`${stylesModule["secondaryText"]}`}>{timeSinceNotification}</span>
			</div>
			<div className={`d-flex ${stylesModule["spaceBetween"]}`}>
				<div className={`${stylesModule["content"]}`}>
					<h5 className={`title fw-bold mb-2`}>{objectTitle}</h5>
					<p className={`description mb-2`}>{message}</p>
				</div>
				<button
					type="button"
					className={`btn btn-link ${stylesModule["notificationAction"]}`}
					onClick={notificationClick}
				>
					View Object
				</button>
			</div>
		</div>
	);
};

export default Notification;
