export const DBConfig = {
	name: "ObjectWorkspaceDB",
	version: 2,
	objectStoresMeta: [
		{
			store: "history",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [{ name: "objectId", keypath: "objectId", options: { unique: false } }],
		},
		{
			store: "attachmentPoints",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [{ name: "objectId", keypath: "objectId", options: { unique: false } }],
		},
		{
			store: "lastOpenedObject",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [
				{ name: "changedRows", keypath: "changedMfiRows", options: { unique: false } },
				{ name: "changedMfiRows", keypath: "changedMfiRows", options: { unique: false } },
				{ name: "contextId", keypath: "contextId", options: { unique: false } },
				{ name: "updatedStockNumberData", keypath: "updatedStockNumberData", options: { unique: false } },
				{ name: "deletedRows", keypath: "deletedRows", options: { unique: false } },
				// { name: 'defaultClasses', keypath: 'defaultClasses', options: { unique: false } },
				// { name: 'defaultFields', keypath: 'defaultFields', options: { unique: false } },
			],
		},
	],
};
