import React from "react";
import "./ChangeItem.css";
import Comparison from "../Comparison/Comparison"; // Import the CSS file for styling

const ChangeDetail = ({ additions, changes, deletions }) => {
	// const truncatedTitle = title.slice(0, Math.ceil(title.length / 2));

	return (
		<>
			<Comparison
				update={{
					objectChanges: { singleObjectDetail: { added: additions, modified: changes, deleted: deletions } },
				}}
			/>
		</>
	);
};

export default ChangeDetail;
