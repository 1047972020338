import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useTrackedState } from "../../utils/store";
import DraggableDialog from "./DraggableDialog/Dialog";
import { getObjectsComputerVersions, getSourceKey } from "../../utils/StandardObject";
import Version from "../VersionControl/Version";
import {
	getObjectDTOByUuidAndVersion,
	getObjectHierarchy,
	getObjectMfi,
	getObjectMfiBySubObject,
} from "../../utils/ApiUtils";
import Card from "@mui/material/Card";
import { CardActionArea, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Comparison, {
	applyUpdateToMfi,
	compareFullObject,
	compareTwoVersionMfis,
	squashDiff,
} from "../VersionControl/Comparison/Comparison";
import { updateChangeData } from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import ChangeSummary from "../VersionControl/ChangeSummary/ChangeSummary";
import { OtherLoader } from "../Loader/Loader";
import { getAvailableUpdates as getUpdates } from "../VersionControl/VersionUtils";
import { getTopMostObject } from "../ReactGridComponents/Body/CreatorPanel/CreatorPanel";
import Tooltip from "@mui/material/Tooltip";
import "./UpdateDialog.css";

//TODO Talk to Will about where the very top is
export const UpdateDialog = ({ obj, currentMfi }) => {
	const [showUpdateDialog, setShowUpdateDialog] = useState(false);
	// const [sourceVersions, setSourceVersions] = useState([]);
	// const [sourceObject, setSourceObject] = useState({});
	const [updateState, setUpdateState] = useState(0);
	const [newMfi, setNewMfi] = useState(null);
	const [update, setUpdate] = useState(null);
	const [updateBeingApplied, setUpdateBeingApplied] = useState("");
	const [availableUpdates, setAvailableUpdates] = useState([]);
	const dialogStates = ["Choose", "Review", "Preview"];

	const triggerOpenCount = useRef(0);
	const changesToBeApplied = useRef({ standardObject: {}, objectChangedData: { changedRows: [], deletedRows: [] } });
	const currentFullMfi = useRef();
	const updateMfi = useRef();

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [loadingText, setLoadingText] = useState("");
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		if (sharedState.triggerOpenUpdateDialog && triggerOpenCount.current !== sharedState.triggerOpenUpdateDialog) {
			triggerOpenCount.current = sharedState.triggerOpenUpdateDialog;
			openUpdateDialog();
		}
	}, [sharedState.triggerOpenUpdateDialog]);

	const getAvailableUpdates = async () => {
		setLoading(true);
		setLoadingText("Checking for updates");
		let sourceUpdate = await checkForSourceUpdate();
		let updateArr = [];
		if (sourceUpdate) {
			updateArr.push(sourceUpdate);
		}

		let topObject = getTopMostObject(sharedState);
		//This is where other updates will be added
		let otherUpdates = await getUpdates(topObject);

		updateArr.push(...otherUpdates);
		setLoading(false);
		//Set the list of updates
		setAvailableUpdates(updateArr);
	};

	/**
	 * Here is the method the button calls to open the dialog
	 */
	const openUpdateDialog = () => {
		//Opens the Update Dialog
		setShowUpdateDialog(true);

		getAvailableUpdates();
	};

	const checkForSourceUpdate = async () => {
		//Get the source object and set it
		//This may need to be changed to be specific to the object passed in
		// Currently "obj" is already using the sharedState.contextTop
		let sourceObject = sharedState.sourceObject;
		let topObject = getTopMostObject(sharedState);

		if (!sourceObject?.uuid) {
			sourceObject = await getObjectDTOByUuidAndVersion(
				topObject.standardObjectUuid,
				topObject.standardObjectVersionUuid
			);
			dispatch({ type: "SET_SOURCE_OBJECT", data: sourceObject });
		}

		let sourceVersions = await getSourceVersions();
		if (sourceVersions.length <= sourceObject.computerVersion) return undefined;

		return { availableUpdate: sourceObject, affectedObjects: [topObject] };
	};

	const getSourceVersions = async () => {
		return await getObjectsComputerVersions(obj.standardObjectVersionUuid);
	};

	const showPreview = (updateObj) => {
		// setUpdateState(3);
		// setUpdateBeingApplied(updateObj);
		// prepareUpdate(updateObj);
	};

	const showReview = (updateObj) => {
		setUpdateState(1);
		setUpdateBeingApplied(updateObj);
		reviewUpdate(updateObj);
	};

	const showAllUpdates = () => {
		setUpdateState(0);
	};

	const previewApplyUpdate = () => {
		prepareUpdate();
	};

	const updateAll = (update) => {
		updateObjects(update);
	};

	const reviewUpdate = async ({ obj: updateObj, versions }) => {
		let latestVersion = versions[versions.length - 1];

		updateMfi.current = {
			oldMfi: await getObjectMfi(updateObj.uuid, updateObj.versionUuid, dispatch),
			newMfi: await getObjectMfi(updateObj.uuid, latestVersion.uuid, dispatch),
		};
	};

	const updateObjects = async ({ availableUpdate: updateObj, affectedObjects }) => {
		setLoading(true);
		setLoadingText("Compiling update: 0%");

		let individualUpdates = new Map();
		affectedObjects.forEach((obj) => {
			let sourceKey = getSourceKey(obj);
			if (individualUpdates.has(sourceKey)) {
				individualUpdates.get(sourceKey).push(obj);
			} else {
				individualUpdates.set(sourceKey, [obj]);
			}
		});

		let targetMfi = await getObjectMfiBySubObject(updateObj.uuid, updateObj.versionUuid);

		let preparedUpdates = new Map();
		const sourceKeys = [...individualUpdates.keys()];
		const total = sourceKeys.length;
		for (let [index, key] of sourceKeys.entries()) {
			//Prepare the update
			let update = await compareFullObject(
				{ uuid: updateObj.uuid, versionUuid: individualUpdates.get(key)[0].standardObjectVersionUuid },
				targetMfi
			);

			preparedUpdates.set(key, update);

			// Calculate progress
			const progress = ((index + 1) / total) * 100;
			setLoadingText(`Compiling Update: ${progress.toFixed(2)}%`);
			setProgress(progress);
		}

		let objectTotal = affectedObjects.length;
		let updatedObjects = [];
		for (let [index, obj] of affectedObjects.entries()) {
			let fullObject = await getObjectMfiBySubObject(obj.uuid, obj.versionUuid);

			let updatedObject = applyUpdateToMfi(obj, fullObject, preparedUpdates.get(getSourceKey(obj)));
			updatedObjects.push(updatedObject);
			// Calculate progress
			const progress = ((index + 1) / objectTotal) * 100;
			setLoadingText(`Updating: ${progress.toFixed(2)}%`);
			setProgress(progress);
		}

		setLoadingText("Cleaning things up");

		let standardObject = getTopMostObject(sharedState);
		updatedObjects.forEach((obj) => {
			[...obj.objectChanges.keys()].forEach((key) => {
				//For each change call updateChangeData
				updateChangeData(dispatch, {
					//The very top object being updated.
					objectToUpdate: standardObject,
					//The primus ancestor being updated if its not the very top
					subObjectToUpdate: obj.objectChanges.get(key).obj,
					//Changes or modifications
					objectRows: obj.objectChanges.get(key).changedRows,
					//Deleted rows
					deletedRows: obj.objectChanges.get(key).deletedRows,
					objectHierarchy: obj.objectChanges.get(key).objectHierarchy,
				});
			});
		});

		setLoading(false);
		handleClose();
	};

	const prepareUpdate = async ({ obj: updateObj, versions }) => {
		let latestVersion = versions[versions.length - 1];
		setLoading(true);
		setLoadingText("Updating object(s)");

		let update = await compareFullObject(
			{ uuid: updateObj.uuid, versionUuid: updateObj.versionUuid },
			{ uuid: updateObj.uuid, versionUuid: latestVersion.uuid }
		);

		console.log("Update", update);

		//Do we need to get the whole mfi? or can we just update without it
		currentFullMfi.current = await getObjectMfiBySubObject(obj.uuid, obj.versionUuid, dispatch);
		// let currentObjectHierarchy = await getObjectHierarchy({ uuid: obj.uuid, versionUuid: obj.versionUuid });
		// obj.objectHierarchy = currentObjectHierarchy;

		//Current mfi should work for a single level object, but multiple levels will need the full mfi along with the things mentioned in the method comments
		let preparedUpdate = applyUpdateToMfi(obj, currentFullMfi.current, update);

		console.log("Applied update", preparedUpdate);

		setLoading(false);

		// For the summary you will have added, deleted, and modified
		// For the first version you will only use the object title, plus the number of changes per object
		// Added  just show title, Deleted just show title
		// Modified show count of rows modified
		setNewMfi(preparedUpdate.mfi);
		changesToBeApplied.current = {
			standardObject: preparedUpdate.standardObject,
			objectChangedData: preparedUpdate.objectChanges,
			objectAdditions: update.added,
			objectDeletions: update.deleted,
			objectChanges: update.modified,
		};
	};

	const saveChanges = () => {
		//This will call dispatch with the updated rows and deletedRows by object
		let newChanges = changesToBeApplied.current;
		[...newChanges.objectChangedData.keys()].forEach((key) => {
			//For each change call updateChangeData
			updateChangeData(dispatch, {
				//The very top object being updated.
				objectToUpdate: newChanges.standardObject,
				//The primus ancestor being updated if its not the very top
				subObjectToUpdate: newChanges.objectChangedData.get(key).obj,
				//Changes or modifications
				objectRows: newChanges.objectChangedData.get(key).changedRows,
				//Deleted rows
				deletedRows: newChanges.objectChangedData.get(key).deletedRows,
				objectHierarchy: newChanges.objectChangedData.get(key).objectHierarchy,
			});
		});

		// console.log("Finished adding changes");
	};

	const handleClose = () => {
		setShowUpdateDialog(false);
		setUpdateState(-1);
	};

	let updateDialog = "";
	let additionalActions = [];
	let saveButtonText = "";
	let dialogTitle = "";
	let handleSave = handleClose;
	let dialogContentHeader = "";
	switch (updateState) {
		//Git diff view of a single level object change detail (not used yet as it wasn't intuitive to use / pull up)
		case 1:
			updateDialog = (
				<Comparison
					original={updateMfi.current?.oldMfi}
					newVersion={updateMfi.current?.newMfi}
					showMfis={false}
				/>
			);
			additionalActions.push({ onClick: showAllUpdates, text: "Back", type: "Back", closeDialog: false });
			saveButtonText = "Preview Update";
			dialogTitle = "Review Update for " + updateBeingApplied?.availableUpdate?.title;
			handleSave = showPreview;
			break;
		//Git diff view of a full object (not useful at all, because it's too detailed unless coming from the change summary)
		case 2:
			updateDialog = <Comparison original={currentFullMfi.current} newVersion={newMfi} showMfis={false} />;
			additionalActions.push({ onClick: showAllUpdates, text: "Back", type: "Back", closeDialog: false });
			saveButtonText = "Apply";
			dialogTitle = "Update Preview for " + updateBeingApplied?.availableUpdate?.title;
			handleSave = saveChanges;
			break;
		//Summary view of the update. Object title, plus how many changedRows, deletedRows and addedRows
		case 3:
			updateDialog = (
				<>
					<Update obj={updateBeingApplied.obj} versions={updateBeingApplied.versions} />
					<ChangeSummary
						changes={changesToBeApplied.current.objectChanges || []}
						additions={changesToBeApplied.current.objectAdditions || []}
						deletions={changesToBeApplied.current.objectDeletions || []}
					/>
				</>
			);
			additionalActions.push({ onClick: showAllUpdates, text: "Back", type: "Back", closeDialog: false });
			saveButtonText = "Apply";
			dialogTitle = "Updating " + (obj?.title || "Object");
			dialogContentHeader = "Template " + updateBeingApplied?.availableUpdate?.title;
			handleSave = saveChanges;
			break;
		//Shows the list of the available updates.
		//Currently just for the top object, version 1.1 will have sub-objects available as well.
		default:
			updateDialog =
				availableUpdates.length > 0 ? (
					<>
						{availableUpdates.map((update) => (
							<Update
								obj={update.availableUpdate}
								affectedObjects={update.affectedObjects}
								versions={update.versions}
								review={(e) => {
									e.preventDefault();
									showPreview(update);
								}}
								update={() => updateAll(update)}
							/>
						))}
					</>
				) : (
					"You're up to date"
				);
			saveButtonText = "Close";
			dialogTitle = "Updates Available for " + (obj?.title || "Object");
			handleSave = handleClose;
			break;
	}

	return (
		<DraggableDialog
			id={"object-updates"}
			style={{ minHeight: "600px", minWidth: "1200px" }}
			showDialog={showUpdateDialog}
			handleClose={handleClose}
			header={dialogTitle}
			cancelButton={false}
			saveButtonText={saveButtonText}
			PaperProps={{ style: { maxWidth: "1300px" } }}
			additionalActions={additionalActions}
			handleSave={handleSave}
			disableSave={loading}
			disableCancel={loading}
		>
			{loading ? (
				<>
					<OtherLoader
						loaderStyles={{
							top: "50%",
							left: "50%",
							height: "200px",
						}}
					/>
					<div style={{ height: "175px", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
						{loadingText}
						{progress > 0 && progress < 100 ? <ProgressBar progress={progress} /> : ""}
					</div>
				</>
			) : (
				updateDialog
			)}
		</DraggableDialog>
	);
};

const Update = ({ obj, versions, affectedObjects, review, update }) => {
	return (
		<Card className={"update-card-content"}>
			<CardContent className={""}>
				<Grid container alignItems="center">
					<Grid item xs={5}>
						<span style={{ fontWeight: "bold" }}>
							{obj.title}
							<Version
								version={{
									computerVersion: obj.computerVersion,
									objectVersionNumber: obj.versionControl?.objectVersionNumber,
									objectState: obj.versionControl?.objectState,
									objectsComputerVersions: versions,
								}}
								chooseVersion={false}
							/>
						</span>
					</Grid>
					<Grid item xs={4} style={{ textAlign: "right" }}>
						Affects{" "}
						{review ? (
							<Tooltip title={"Review Update	"}>
								<button className={"btn btn-link review-link"} onClick={review}>
									{affectedObjects.length === 1 ? (
										affectedObjects[0].title
									) : (
										<>
											{affectedObjects.length} object{affectedObjects.length > 1 ? "s" : ""}
										</>
									)}
								</button>
							</Tooltip>
						) : (
							""
						)}
					</Grid>
					<Grid item xs={3} style={{ textAlign: "center" }}>
						{update ? (
							<Tooltip title={"Update all affected objects"}>
								<button className={"btn btn-outline-primary update-btn"} onClick={update}>
									Update
								</button>
							</Tooltip>
						) : (
							""
						)}
					</Grid>
					<Grid item xs={12} style={{ textAlign: "center" }}>
						{/*<CurrentAndLatestVersion obj={obj} versions={versions} />*/}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

const CurrentAndLatestVersion = ({ obj, versions }) => {
	return (
		<>
			Current Version:
			<Version
				version={{
					computerVersion: obj.computerVersion,
					objectVersionNumber: obj.objectVersionNumber,
					objectState: obj.objectState,
					objectsComputerVersions: versions,
				}}
				chooseVersion={false}
				style={{ paddingRight: "4px" }}
			/>
			-> Latest Version
			<Version
				version={{
					computerVersion: versions.length,
					objectVersionNumber: obj.objectVersionNumber,
					objectState: obj.objectState,
					objectsComputerVersions: versions,
				}}
				chooseVersion={false}
				style={{ paddingRight: "4px" }}
			/>
		</>
	);
};

function ProgressBar({ progress }) {
	return (
		<div style={{ width: "100%", backgroundColor: "#f3f3f3" }}>
			<div style={{ width: `${progress}%`, height: "20px", backgroundColor: "#4caf50" }}></div>
		</div>
	);
}
