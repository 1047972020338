import React, { useState, useEffect, useRef } from "react";
import { getReferenceNo, sortByReference } from "../../utils/Referencing";
import { getAncestors } from "../../utils/TreeUtils";
import { _updateRow } from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { useDispatch } from "../../utils/store";
import Window from "./Window";
import { LabelWithHelpIcon } from "../ReactGridComponents/SetupSheetWindow/SetupField";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";
export const UserSetupFormField = ({
	disabled,
	windowUuid,
	field,
	index,
	selected,
	updateRow,
	setSelected,
	hasChildren = false,
	showRef = true,
	refField = "reference",
	formFieldStyles = {},
}) => {
	const [value, setValue] = useState("");
	const highlightTimeout = useRef();
	const typeKeyTimeout = useRef();
	const pendingUpdate = useRef();
	const inputRef = useRef();

	const initialValue = useRef("");

	useEffect(() => {
		if (field.value !== value) {
			setValue(field.value);
			initialValue.current = field.value;
		}
	}, [field.value]);

	useEffect(() => {
		if (selected) startHighlightTimeout();
		else clearHighlightTimeout();
	}, [selected]);

	const startTypeKeyTimeout = () => {
		clearTypeTimeout();

		typeKeyTimeout.current = setTimeout(() => {
			//When done, update state
			handleBlur(pendingUpdate.current);
		}, 2000);
	};

	const clearTypeTimeout = () => {
		clearTimeout(typeKeyTimeout.current);
	};

	/**
	 * Timeout to highlight the field
	 */
	const startHighlightTimeout = () => {
		clearHighlightTimeout();
		// inputRef.current.focus();

		highlightTimeout.current = setTimeout(() => {}, 2000);
	};

	const clearHighlightTimeout = () => {
		clearTimeout(typeKeyTimeout.current);
	};

	const handleBlur = (newValue) => {
		clearTypeTimeout();
		if (initialValue.current !== newValue) {
			initialValue.current = newValue;
			setValue(newValue);

			updateRow(field.uuid, "value", newValue);
		}
	};

	return (
		<>
			<LabelWithHelpIcon reference={field.reference} title={field.title} description={field.description} />
			{hasChildren ? (
				<span className="float-end">
					<span className="p-2">
						<a href="#" onClick={() => setSelected(field, true, windowUuid)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-folder2-open"
								viewBox="0 0 16 16"
							>
								<path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z" />
							</svg>
						</a>
					</span>
				</span>
			) : (
				""
			)}
			{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
				label: "",
				value: value,
				handleChange: (newVal) => {
					pendingUpdate.current = newVal;
					setValue(newVal);
					startTypeKeyTimeout();
				},
				handleBlur: (e) => handleBlur(value),
				disabled: disabled,
				focus: selected,
				title: field.title,
			})}
			{/*<input*/}
			{/*	className="form-control"*/}
			{/*	type="text"*/}
			{/*	placeholder={field.title}*/}
			{/*	value={value}*/}
			{/*	style={{ padding: "12px" }}*/}
			{/*	ref={inputRef}*/}
			{/*	disabled={disabled}*/}
			{/*	onChange={(e) => {*/}
			{/*		let newVal = e.currentTarget.value;*/}
			{/*		pendingUpdate.current = newVal;*/}
			{/*		setValue(newVal);*/}
			{/*		startTypeKeyTimeout();*/}
			{/*	}}*/}
			{/*	onBlur={(e) => handleBlur(value)}*/}
			{/*/>*/}
		</>
	);
};

/**
 * A window that is loaded into the setup form when a node is clicked in the tree on the left
 * @param {values, handleBlur, dwBreadCrumb, objMfiBreadCrumb, children}
 * @constructor
 */
const UserSetupFormWindow = ({
	topNode,
	data,
	datawarehouse,
	handleBlur,
	uuid,
	selectedId,
	updateRow,
	setSelected,
	fields: _fields = [],
	cardStyle,
	showTopNodeRef = false,
}) => {
	//state variables
	const [dwBreadCrumbs, setDwBreadCrumbs] = useState([]);
	const [objMfiBreadCrumbs, setObjMfiBreadCrumbs] = useState([]);
	const [fields, setFields] = useState([]);
	//Used to update the windows title
	const [windowTitle, setWindowTitle] = useState("");

	const dispatch = useDispatch();

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		if (!data || data.length < 1) return;

		//If I receive a uuid, I need to build the bread crumbs and array of fields to display
		if (uuid) {
			//Get the corresponding row
			let mfiRow = data.find((row) => row.uuid === uuid);
			getDataWarehouseAncestors();
			if (!mfiRow) {
				if (uuid === "0") {
					let ancestors = [topNode];
					setFields([topNode]);
					setWindowTitle("");
					setObjMfiBreadCrumbs(ancestors);
					return;
				} else if (uuid === topNode.uuid) {
					let ancestors = [topNode];
					let children = data.filter((row) => row.parentUuid === topNode.uuid);
					setFields(children);
					setWindowTitle("");
					setObjMfiBreadCrumbs(ancestors);
					return;
				} else return;
			}

			//Get the ancestors of the row, build the obj mfi breadcrumbs
			// topNode.reference = '';
			let ancestors = [topNode];
			ancestors = [
				...ancestors,
				...getAncestors(
					mfiRow.parentUuid,
					data.filter((row) => row.uuid !== topNode.uuid)
				),
			];
			ancestors.sort(sortByReference);

			//Set the array of fields needed to display
			//Check if this row has children
			let children = data.filter((row) => row.parentUuid === mfiRow.uuid);
			if (_fields.length > 0) {
				setFields(_fields);
				ancestors.push(mfiRow);
			} else if (children.length > 0) {
				setFields(children);
				ancestors.push(mfiRow);
			} else {
				setFields(data.filter((row) => row.parentUuid === mfiRow.parentUuid));
				mfiRow = data.find((row) => row.uuid === mfiRow.parentUuid);
			}
			setWindowTitle(`${getReferenceNo(mfiRow.reference)}.${mfiRow.title}`);

			setObjMfiBreadCrumbs(ancestors);
		}
	}, [uuid, data.length]);

	useEffect(() => {
		if (_fields.length > 0) setFields(_fields);
	}, [_fields.length]);

	useEffect(() => {}, []);

	//Other Methods
	const getDataWarehouseAncestors = () => {
		let dwRow = datawarehouse.find(
			(row) => row.standardObjectUuid === topNode.uuid && row.standardObjectVersionUuid === topNode.versionUuid
		);
		if (!dwRow) return;

		let ancestors = getAncestors(dwRow.uuid, datawarehouse);
		ancestors.sort(sortByReference);
		setDwBreadCrumbs(ancestors);
	};

	return (
		<Window
			windowHeader={
				<>
					<span>{`${showTopNodeRef ? topNode.reference + "-" : ""}${topNode.title}`}</span>&nbsp;-&nbsp;
					<span>{windowTitle}</span>
				</>
			}
			open={setSelected}
			notFoundMessage={"Object not found on setup form"}
			breadCrumbs={objMfiBreadCrumbs}
			topNodeUuid={topNode.uuid}
			windowUuid={uuid}
			cardStyle={cardStyle}
		>
			{fields.length > 0
				? fields.map((field, index) => (
						<UserSetupFormField
							key={field.uuid + index}
							windowUuid={uuid}
							field={field}
							index={index}
							selected={selectedId === field.uuid}
							updateRow={(uuid, attribute, value) => _updateRow(dispatch, uuid, attribute, value)}
							hasChildren={data.filter((row) => row.parentUuid === field.uuid).length > 0}
							setSelected={setSelected}
						/>
				  ))
				: ""}
		</Window>
	);
};

export default UserSetupFormWindow;
