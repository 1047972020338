import Tooltip from "@mui/material/Tooltip";

/**
 * Allows the user to access different environments they have access to.
 * @param { title, roles, ...other }
 * @constructor
 */
const Portal = ({ title, roles, ...other }) => {
	return (
		<div className="col col-4">
			<div className="card mb-3">
				<div className="card-body text-center shadow">
					<div className="mb-3">
						<h1>{title}</h1>
					</div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="1em"
						height="1em"
						viewBox="0 0 16 16"
						fill="currentColor"
						className="bi bi-person-circle mb-3 mt-4"
						style={{ fontSize: "150px" }}
					>
						<path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"></path>
						<path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
						<path
							fillRule="evenodd"
							d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
						></path>
					</svg>
					<div className="mb-3">
						<form>
							<select className="form-select">
								<optgroup label="Access Level">
									{roles.map((role) => {
										return <option value={role.value}>{role.title}</option>;
									})}
								</optgroup>
							</select>
						</form>
					</div>
					<div className="mb-3">
						<button className="btn btn-primary btn-sm form-control" type="button">
							Select Portal
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

/**
 * The first screen that pulls up when a user logs in to the application.
 * @param { environments, ...other }
 * @constructor
 */
const PortalPage = ({ environments, ...other }) => {
	//state variables

	//For testing purposes
	environments = [
		{
			title: "BMCL",
			roles: [
				{ value: "bmcl-admin", title: "BMCL Admin" },
				{ value: "admin", title: "Admin" },
				{ value: "user", title: "User" },
			],
		},
		{
			title: "Standard Restaurant Supply",
			roles: [
				{ value: "bmcl-admin", title: "BMCL Admin" },
				{ value: "user", title: "User" },
			],
		},
	];

	return (
		<div className="container">
			<div className="row mt-5">
				{environments.map((e) => {
					return (
						<Tooltip title={e.title}>
							{" "}
							<Portal roles={e.roles} uuid={e.uuid} />
						</Tooltip>
					);
				})}
			</div>
		</div>
	);
};

export default PortalPage;
