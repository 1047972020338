import { Outlet } from "react-router-dom";
import LandingPageHeader from "../components/Headers/LandingPageHeader";

const LandingPageLayout = () => {
	return (
		<>
			<LandingPageHeader />
			<Outlet />
		</>
	);
};

export default LandingPageLayout;
