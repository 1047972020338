import React, { Component } from "react";
import InlineEditableElement from "../../components/InlineEditableElement/InlineEditableElement";
import Grid from "@mui/material/Grid";

export default class CenterHeader extends Component {
	constructor(props) {
		super();

		this.state = {
			changed: false,
			centerHeader: props.centerHeader,
			title: props.title,
		};
	}
	render() {
		const { centerHeader } = this.props;

		return (
			<div style={{ textAlign: "center" }}>
				<Grid container direction={"column"}>
					<Grid item>
						<span className="bold header-label">App</span> <span className="header-label">Title:</span>
						<InlineEditableElement text={centerHeader.appTitle} type="text">
							<input
								type="text"
								name="appTitle"
								value={centerHeader.appTitle}
								onChange={(e) => {
									const { name, value } = e.target;
									centerHeader.appTitle === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								//Whenever the input loses focus call the method updating the state.
								onBlur={(e) => {
									const { name, value } = e.target;
									centerHeader.appTitle === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								autoFocus
							/>
						</InlineEditableElement>
					</Grid>
					<Grid item>
						<span className="header-label">PS: </span>
						<InlineEditableElement text={centerHeader.appPositionTitle} type="text">
							<input
								type="text"
								name="appPositionTitle"
								value={centerHeader.appPositionTitle}
								onChange={(e) => {
									const { name, value } = e.target;
									centerHeader.appPositionTitle === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								//Whenever the input loses focus call the method updating the state.
								onBlur={(e) => {
									const { name, value } = e.target;
									centerHeader.appPositionTitle === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								autoFocus
							/>
						</InlineEditableElement>
						<span className="header-label"> PKT: </span>
						<InlineEditableElement text={centerHeader.appPacketTitle} type="text">
							<input
								type="text"
								name="appPacketTitle"
								value={centerHeader.appPacketTitle}
								onChange={(e) => {
									const { name, value } = e.target;
									centerHeader.appPacketTitle === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								//Whenever the input loses focus call the method updating the state.
								onBlur={(e) => {
									const { name, value } = e.target;
									centerHeader.appPacketTitle === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								autoFocus
							/>
						</InlineEditableElement>
					</Grid>
					<Grid item>
						<span className="bold header-label">Obj</span> <span className="header-label">PKT: </span>
						<InlineEditableElement text={centerHeader.objectPacketTitle} type="text">
							<input
								type="text"
								name="objectPacketTitle"
								value={centerHeader.objectPacketTitle}
								onChange={(e) => {
									const { name, value } = e.target;
									centerHeader.objectPacketTitle === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								//Whenever the input loses focus call the method updating the state.
								onBlur={(e) => {
									const { name, value } = e.target;
									centerHeader.objectPacketTitle === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								autoFocus
							/>
						</InlineEditableElement>
					</Grid>
					<Grid item>
						<span className="header-label">Obj/Doc Title: </span>
						<span className="bold">
							<InlineEditableElement text={centerHeader.objectDocumentTitle} type="text">
								<input
									type="text"
									name="objectDocumentTitle"
									value={centerHeader.objectDocumentTitle}
									onChange={(e) => {
										const { name, value } = e.target;
										centerHeader.objectDocumentTitle === value ||
											this.props.updateHeader("centerHeader", name, value);
									}}
									//Whenever the input loses focus call the method updating the state.
									onBlur={(e) => {
										const { name, value } = e.target;
										centerHeader.objectDocumentTitle === value ||
											this.props.updateHeader("centerHeader", name, value);
									}}
									autoFocus
								/>
							</InlineEditableElement>
						</span>
						<InlineEditableElement
							text={" (" + (centerHeader.productType || "") + ")"}
							type="text"
							placeholder={"(Object Type)"}
						>
							<input
								type="text"
								name="productType"
								value={centerHeader.productType}
								onChange={(e) => {
									const { name, value } = e.target;
									centerHeader.productType === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								//Whenever the input loses focus call the method updating the state.
								onBlur={(e) => {
									const { name, value } = e.target;
									centerHeader.productType === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								autoFocus
							/>
						</InlineEditableElement>
					</Grid>
					<Grid item>
						<InlineEditableElement
							text={centerHeader.reportDate}
							type="text"
							placeholder={"Effective Date"}
						>
							<input
								type="text"
								name="reportDate"
								value={centerHeader.reportDate}
								onChange={(e) => {
									const { name, value } = e.target;
									centerHeader.reportDate === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								//Whenever the input loses focus call the method updating the state.
								onBlur={(e) => {
									const { name, value } = e.target;
									centerHeader.reportDate === value ||
										this.props.updateHeader("centerHeader", name, value);
								}}
								autoFocus
							/>
						</InlineEditableElement>
					</Grid>
				</Grid>
			</div>
		);
	}
}
