import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Copyright = () => {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link to={"/"} style={{ color: "inherit" }}>
				Business Model Consulting LLC
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
};
export default Copyright;
