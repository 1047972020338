export const objectWorkspacePanels = {
	SOURCE_PANEL: {
		id: "bf48359f-540e-4ea2-8afa-dad232d0a7e3",
		gridId: "overall-source-panel",
		paperId: "source-panel",
		containerClass: "data-warehouse-tree-container",
		title: "Source",
	},
	DESTINATION_PANEL: {
		id: "86ac4cb7-c2e3-441d-b89c-521529b3242d",
		gridId: "overall-destination-panel",
		paperId: "destination-panel",
		containerClass: "data-warehouse-tree-container",
		title: "Destination",
	},
	WORKSPACE_PANEL: {
		id: "4927d712-1d19-4701-8f97-581d0cb76d33",
		gridId: "overall-workspace-panel",
		paperId: "workspace-panel",
		containerClass: "overall-panel",
		title: "New / Modified Object Workspace",
	},
	DATA_WAREHOUSE: {
		id: "7d44312b-e32a-4998-82f2-8885ba031325",
		gridId: "obj-wrksp-dw-view",
		paperId: "datawarehouse-panel",
		containerClass: "data-warehouse-tree-container",
		title: "Data Warehouse",
	},
	SOURCE_OBJECT_TEMPLATE_PANEL: {
		id: "44a535fd-d51d-4e28-9642-40c32a84c302",
		gridId: "template-mfi-view",
		paperId: "template-panel",
		containerClass: "workspace-tree-container",
		title: "Object Template",
	},
	NEW_MODIFIED_OBJECT_WORKSPACE: {
		id: "a30327cc-6bef-44e1-99c2-9523421eab1b",
		gridId: "obj-wrksp-mfi-view",
		paperId: "workspace-panel",
		containerClass: "workspace-tree-container",
		title: "Object MFI View",
	},
	SETUP_FORMS: {
		id: "6d56f35b-9bef-4c9a-b63a-32d7346b8972",
		gridId: "obj-wrksp-properties",
		paperId: "setup-forms",
		containerClass: "setup-form-parent",
		title: "Setup Sheets View",
	},
	VISUAL_REP: {
		id: "cfebdb49-9f45-4a8e-a15f-fe29382bcedd",
		gridId: "obj-wrksp-layout-view",
		paperId: "layout-panel",
		containerClass: "",
		title: "Visual Presentation Views",
	},
	COMPARISON: {
		id: "f452a333-4ced-4c1b-98a1-c66ac8217012",
		gridId: "obj-comparison-view",
		paperId: "obj-comparison",
		containerClass: "comparison-container",
		title: "Proposed Changes",
	},
	UBM_FORM: {
		id: "3a9925a4-2f61-44cc-9a40-f403d559fe40",
		gridId: "obj-ubm-form",
		paperId: "ubm-form-panel",
		containerClass: "",
		title: "UBM Form",
	},
	TABLE_OF_CONTENTS: {
		id: "afe00b29-67e1-42cf-8bfa-bf0f80b45bbb",
		gridId: "table-of-contents",
		paperId: "toc-panel",
		containerClass: "data-warehouse-tree-container",
		title: "TOC",
	},
	MODELS_PANEL: {
		id: "52335359-1a98-4180-a07c-0d404f4b9390",
		gridId: "models-mfi",
		paperId: "models",
		containerClass: "workspace-tree-container",
		title: "Accessible Models",
	},
	CHECKLIST_PANEL: {
		id: "857faf2c-c514-4c9b-9afd-694c57d83694",
		gridId: "checklist-panel",
		paperId: "checklist",
		containerClass: "workspace-tree-container",
		title: "Checklist",
	},
	OPEN_POINTS_PANEL: {
		id: "0f00ef51-01a4-41c9-97fe-df91c715fc10",
		gridId: "open-point-panel",
		paperId: "open-point",
		containerClass: "workspace-tree-container",
		title: "Open Point / Notes to Reviewer",
	},
	OBJECT_HIERARCHY_PANEL: {
		id: "1303ecf2-3dc9-4aac-b56a-a1fa71e676a1",
		gridId: "object-hierarchy-panel",
		paperId: "object-hierarchy",
		containerClass: "workspace-tree-container",
		title: "Object Hierarchy",
	},
	// DISPLAY_OBJECT_MFI: {
	//     id: '947a12d0-ade4-49e4-bc06-4a5148512bd6',
	//     gridId: 'display-object-mfi',
	//     paperId: 'display-mfi-panel',
	//     containerClass: 'data-warehouse-tree-container',
	//     title: 'Object MFI',
	// }
	// SETUP_FORMS: {
	//     gridId: '',
	//     paperId: '',
	//     containerClass: '',
	//     title: '',
	// },
};

export const panelSupportingStates = {
	CURRENT_OBJECT: {
		title: "Current Object",
	},
	SUGGESTED_CHANGE: {
		title: "Suggested Change",
	},
	NEW_VERSION: {
		title: "New Version",
	},
};
