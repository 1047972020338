import React, { useState, useEffect, useRef } from "react";
import BasicTreeWithSearch from "../../../Tree/BasicTreeWithSearch";
import Grid from "@mui/material/Grid";
import DraggableDialog from "../Dialog";
import { getSingleLevelObjectMfi } from "../../../../utils/ApiUtils";
import { findObjectAncestorUsingReference } from "../../../../utils/TreeUtils";
import { getParentRef } from "../../../../utils/Referencing";
import { getTopMostObject } from "../../../ReactGridComponents/Body/CreatorPanel/CreatorPanel";
import HorizontalTabs from "../../../ReactGridComponents/HorizontalTabs/HorizontalTabs";

/**
 * A container component for the SelectionDialog
 * @param {}
 * @constructor
 */
export const SelectionDialogHolder = ({}) => {
	return <SelectionDialog dialogTitle={"Select the object you wish to add"} />;
};

/**
 * A Dialog with a Searchable Tree that stores the selected node
 * @param {}
 * @constructor
 */
const SelectionDialog = ({
	dialogTitle,
	treeData: _treeData,
	treeTitle,
	rowSelected,
	open,
	multiSelect = false,
	treeHeight,
	handleClose,
	requireFullObject: _requireFullObject = true,
	selectedRows,
	submitButtonText = "Save",
	cancelButtonText = "Cancel",
	disableSubmit = false,
	disableCancel = false,
	additionalActions,
	allowSubObjectNavigation: _allowSubObjectNavigation = false,
	multipleTabs = false,
	tabs = [],
}) => {
	//state variables
	const [treeData, setTreeData] = useState([]);
	const [showDialog, setShowDialog] = useState(false);
	const [selected, setSelected] = useState([]);
	const [invalidSelection, setInvalidSelection] = useState(false);

	//This is the actual value of the field
	const selectedValue = useRef([]);
	const loadedUuids = useRef([]);
	const currentTab = useRef();

	//useEffects(Lifecycle Methods)
	useEffect(() => {
		loadedUuids.current = [];
	}, []);
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		if (_treeData) setTreeData(_treeData);
	}, [_treeData]);

	useEffect(() => {
		if (open) setShowDialog(true);
	}, [open]);

	useEffect(() => {
		if (multipleTabs) currentTab.current = 0;
	}, [multipleTabs]);

	useEffect(() => {
		if (selectedRows) {
			setSelected(selectedRows);
			selectedValue.current = selectedRows.map((row) => row.uuid);
		}
	}, [selectedRows]);

	const onRowSelect = (treeNode, index) => {
		let requireFullObject = _requireFullObject;

		let tab;
		if (tabs && multipleTabs) {
			tab = tabs[index];
			currentTab.current = index;
		}

		if (tab?.props?.hasOwnProperty("requireFullObject")) requireFullObject = tab.props.requireFullObject;

		if (requireFullObject) {
			if (treeNode.standardObjectUuid && treeNode.standardObjectVersionUuid) {
				setInvalidSelection(false);
				//If multiple rows can be selected
				if (multiSelect) {
					let uuid = treeNode.uuid;
					//If it's not already in the list add it
					if (!selectedValue.current.includes(uuid)) {
						selected.push(treeNode);
					}
					//Otherwise remove it from the array
					else {
						//Get the index it currently resides
						let ind = selectedValue.current.indexOf(treeNode.uuid);
						selected.splice(ind, 1);
					}
					setSelected([...selected]);
					selectedValue.current = selected.map((node) => node.uuid);
				} else {
					setSelected([treeNode]);
					selectedValue.current = [treeNode.uuid];
				}
			} else {
				setSelected([]);
				selectedValue.current = [];
				setInvalidSelection(true);
				// setTimeout(() => {
				//     setInvalidSelection(false);
				// }, 2000);
			}
		} else {
			setInvalidSelection(false);
			setSelected([treeNode]);
			selectedValue.current = [treeNode.uuid];
		}

		//If we allow subObjectNavigation and this is a valid object load the mfi
		loadSubObject(treeNode, index);
	};

	/**
	 * Load the sub-object into the MFI
	 */
	const loadSubObject = async (subObject, index) => {
		let data = treeData,
			allowSubObjectNavigation = _allowSubObjectNavigation,
			tab;
		if (index) {
			tab = tabs[index];
			data = tab.treeData;
			currentTab.current = index;

			if (tab.props.hasOwnProperty("allowSubObjectNavigation"))
				allowSubObjectNavigation = tab.props.allowSubObjectNavigation;
		}

		if (
			!allowSubObjectNavigation ||
			!subObject.isObject ||
			(subObject.uuid === data[0].uuid && subObject.versionUuid === data[0].versionUuid) ||
			loadedUuids.current.includes(subObject.uuid)
		)
			return;

		//Get the hierarchy record
		let hierarchyRecord = data[0].objectHierarchy.find(
			(row) =>
				row.descendantStandardObjectUuid === subObject.uuid &&
				row.descendantStandardObjectVersionUuid === subObject.versionUuid
		);

		let subMfi = await getSingleLevelObjectMfi(subObject.uuid, subObject.versionUuid, null, null, {
			...subObject,
			objectHierarchy: [hierarchyRecord],
		});

		data[0].objectHierarchy = [...data[0].objectHierarchy, ...subMfi[0].objectHierarchy];

		if (tab) tab.treeData = [...data, ...subMfi.slice(1)];

		//When we have multiple tabs the treeData in the state doesn't do anything
		// We'll update the tree data anyway, so it will force the tab to re-render
		setTreeData([...data, ...subMfi.slice(1)]);

		loadedUuids.current.push(subObject.uuid);
	};

	const handleSave = (e, node) => {
		//Verify we have selected a valid object
		if (selected.length < 1 && (!node || node.length == 0)) {
			rowSelected([]);
			setSelected([]);
			return;
		}

		let tab,
			allowSubObjectNavigation = _allowSubObjectNavigation;
		if (currentTab.current !== undefined && multipleTabs) {
			tab = tabs[currentTab.current];
			if (tab.props.hasOwnProperty("allowSubObjectNavigation"))
				allowSubObjectNavigation = tab.props.allowSubObjectNavigation;
		}

		let nodeToSelect = {};
		if (!multiSelect) {
			let rowToSelect;
			if (node) rowToSelect = node[0];
			else rowToSelect = selected[0];

			//If we allow sub object navigation send the row selected and the nearest object it is apart of
			if (allowSubObjectNavigation) {
				let objectSelected = rowToSelect;
				if (!objectSelected.isObject)
					objectSelected = findObjectAncestorUsingReference(rowToSelect.reference, treeData);

				let hierarchyRecord = treeData[0].objectHierarchy.find(
					(row) =>
						row.descendantStandardObjectUuid === objectSelected.uuid &&
						row.descendantStandardObjectVersionUuid === objectSelected.versionUuid
				);
				objectSelected.objectHierarchy = [hierarchyRecord];
				if (tab?.id) rowSelected(rowToSelect, objectSelected, tab?.id);
				else rowSelected(rowToSelect, objectSelected);
				reset();
				return;
			} else nodeToSelect = rowToSelect;
		} else {
			if (node) nodeToSelect = node;
			else nodeToSelect = selected;
		}

		if (tab?.id) rowSelected(nodeToSelect, null, tab?.id);
		else rowSelected(nodeToSelect);

		reset();
	};

	const reset = () => {
		setShowDialog(false);
		setInvalidSelection(false);
		setSelected([]);
		selectedValue.current = [];
		loadedUuids.current = [];
	};

	const tabChange = (index) => {
		setTreeData(tabs[index].treeData);
	};

	//TODO: This doesn't really work for anything but the data warehouse tree. Update so any tree list with select can use it. Need to update the rowSelected and treeTile sent to BasicTreeWithSearch
	return (
		<DraggableDialog
			id={""}
			style={{ minHeight: "600px", minWidth: "1200px" }}
			showDialog={showDialog}
			fullWidth={true}
			maxWidth={"1000px"}
			maxHeight={"900px"}
			handleClose={(e) => {
				rowSelected(undefined);
				reset();
				if (handleClose) handleClose();
			}}
			handleSave={handleSave}
			header={dialogTitle}
			cancelButton={true}
			disableSave={selected.length < 1 || invalidSelection || disableSubmit}
			disableCancel={disableCancel}
			saveButtonText={submitButtonText}
			cancelButtonText={cancelButtonText}
			PaperProps={{ style: { maxWidth: "900px", height: "650px" } }}
			contentStyles={{
				padding: "20px",
			}}
			additionalActions={additionalActions}
		>
			<Grid container style={{ height: "100%", display: "block" }}>
				{
					//Check if we want multiple tabs
					multipleTabs ? (
						<HorizontalTabs headings={tabs.map((t) => t.heading)} tabClicked={tabChange}>
							{tabs.map((t, index) => (
								<BasicTreeWithSearch
									treeHeight={treeHeight ? treeHeight : undefined}
									data={t.treeData}
									treeTitle={t.heading}
									allowSubObjectNavigation={_allowSubObjectNavigation}
									rowSelected={(treeNode) => onRowSelect(treeNode, index)}
									showVersions={true}
									selectedRows={selected}
									{...t.props}
									treeProps={{
										onDoubleClick: (e, node) => {
											handleSave(e, [node], index);
										},
									}}
								/>
							))}
						</HorizontalTabs>
					) : (
						<BasicTreeWithSearch
							treeHeight={treeHeight ? treeHeight : undefined}
							data={treeData}
							treeTitle={`Data Warehouse`}
							allowSubObjectNavigation={_allowSubObjectNavigation}
							rowSelected={onRowSelect}
							showVersions={true}
							selectedRows={selected}
							treeProps={{
								onDoubleClick: (e, node) => {
									handleSave(e, [node]);
								},
							}}
						/>
					)
				}
				<Grid
					container
					style={{
						justifyContent: "center",
						padding: "12px",
						position: "absolute",
						bottom: "15px",
						left: 0,
						fontSize: "1.25em",
						backgroundColor: "white",
						zIndex: "-1",
					}}
				>
					<Grid item>
						<span
							style={{
								fontWeight: "600",
								paddingLeft: "5px",
							}}
						>
							Selected:
						</span>
					</Grid>
					<Grid item>
						<>
							<div style={{ maxHeight: "40px", overflowY: "auto" }}>
								{selected.map((selected) => (
									<div key={selected.uuid}>
										<span
											style={{
												paddingLeft: "5px",
												paddingRight: "15px",
											}}
										>{` ${selected.reference} - ${selected.title}`}</span>
									</div>
								))}
							</div>
							{invalidSelection ? (
								<span
									style={{
										textDecoration: "underline",
										paddingLeft: "5px",
										color: "red",
									}}
								>{`Invalid Object`}</span>
							) : (
								""
							)}
						</>
					</Grid>
				</Grid>
			</Grid>
		</DraggableDialog>
	);
};

export default SelectionDialog;

export const SelectionDialogWithMultipleTabs = ({}) => {};
