import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Link, Navigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { getToken, setTokenCookie } from "../../utils/Authentication";
import { isAuthenticated } from "../../utils/Authentication";
import stylesModule from "./Login.module.scss";
import { useDispatch } from "../../utils/store";
import Copyright from "./Copyright";
import jwt_decode from "jwt-decode";

export default function SignIn(props) {
	const [redirectToReferrer, setRedirectToReferrer] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const { location } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const login = async (event) => {
		event.preventDefault();
		await getToken(email, password)
			.then((token) => {
				if (jwt_decode(token).active === false) {
					navigate("/activate?uuid=" + jwt_decode(token).uuid, { replace: true });
					return;
				}
				setTokenCookie({ token });
				setRedirectToReferrer(true);
				props.setLoggedIn(true);
			})
			.catch((err) => {
				setErrorMessage(err.message);
			});
	};

	useEffect(() => {
		document.title = "Sign In";
	}, []);

	useEffect(() => {
		if (isAuthenticated()) {
			dispatch({
				type: "SHOW_SUCCESS_MESSAGE",
				data: "You are already signed in.",
			});
			navigate("/object-workspace", { replace: true });
		}
	}, []);

	if (redirectToReferrer === true) {
		return <Navigate replace to={location?.from || "/object-workspace"} />;
	}

	return (
		<div className="container-fluid">
			<div className="loginBox ">
				<Avatar className={`${stylesModule.logo}`}>
					<LockOutlinedIcon />
				</Avatar>
				<h3 className="text-center">Automated UBM Software</h3>
				<hr />
				<h3 className="text-center">Login</h3>
				<form className={stylesModule.form}>
					<div className="mb-3">
						<label htmlFor="email" className="form-label">
							<strong>Email address</strong>
						</label>
						<input
							type="email"
							className="form-control"
							id="email"
							autoFocus
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="password" className="form-label">
							<strong>Password</strong>
						</label>
						<input
							type="password"
							className="form-control"
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div className="text-red">{errorMessage}</div>
					<button type="submit" className={`btn btn-primary ${stylesModule.submit}`} onClick={login}>
						Log In
					</button>

					<Grid container mt={2}>
						<Grid item xs>
							<Link to="/forgot-password">Forgot password?</Link>
						</Grid>
						<Grid item>
							<Link to="/register">Don't have an account? Sign Up</Link>
						</Grid>
					</Grid>
				</form>
				<Box mt={2}>
					<Copyright />
				</Box>
			</div>
		</div>
	);
}
