import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

const InlineEditableElement = ({ childRef, text, type, tooltip, placeholder, children, ...props }) => {
	const [isEditing, setEditing] = useState(false);

	useEffect(() => {
		if (childRef && childRef.current && isEditing === true) {
			childRef.current.focus();
		}
	}, [isEditing, childRef]);

	const handleKeyDown = (event, type) => {
		const { key } = event;
		const keys = ["Escape", "Tab"];
		const enterKey = "Enter";
		const allKeys = [...keys, enterKey]; // All keys array

		/*
          - For textarea, check only Escape and Tab key and set the state to false
          - For everything else, all three keys will set the state to false
        */
		if ((type === "textarea" && keys.includes(key)) || (type !== "textarea" && allKeys.includes(key))) {
			setEditing(false);
			event.target.blur();
		}
	};

	return (
		<Tooltip title={tooltip}>
			<section {...props} style={{ display: "inline" }}>
				<>
					{isEditing ? (
						<div
							onBlur={() => setEditing(false)}
							onKeyDown={(e) => handleKeyDown(e, type)}
							style={{ display: "inline" }}
						>
							{children}
						</div>
					) : (
						<div onClick={() => setEditing(true)} style={{ display: "inline" }}>
							<span style={props.style}>
								{text === 0 ? "0" : text || placeholder || "Editable content"}
							</span>
						</div>
					)}
				</>
			</section>
		</Tooltip>
	);
};

export default InlineEditableElement;
