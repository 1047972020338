import React, { useState, useEffect, useRef, memo } from "react";
import Panel from "../../Panel/Panel";
import ReactGrid from "../../../ReactGrid/ReactGrid";
import NewModifiedWorkspacePanel, {
	DebugDialog,
	getDataWarehouse,
	getExistingObjects,
	ObjectHeader,
	OpenPointDialog,
	updateChangeData,
} from "../NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import {
	addChangesToMfi,
	addRowToChangedObjectMap,
	buildNewChangeMap,
	buildRelationship,
	checkForSubObject,
	componentsNodeTitle,
	convertListToObjectMap,
	copyStandardObject,
	defaultMasterFileIndexChangeTitle,
	defaultStandardObjectGitChangeTitle,
	getChangesForObject,
	getHierarchyDescendantUuidToVersionMap,
	getGlobalObjectHierarchy,
	getObjectIdAndVersionUuid,
	getObjectsComputerVersions,
	getStateChanges,
	getToc,
	linkAttributeName,
	linkVersionAttributeName,
	loadObjectVersions,
	loadRelatedObject,
	loadSubObject,
	objectIsPacket,
	oldComponentsNodeTitle,
	packetProductsSectionReference,
	splitObjectIdAndVersion,
	trimObject,
	UBM_MASTER_FILE_INDEX_ITEM,
	updateRowsThatReferenceThisRow,
	ZERO_ROW_UUID,
	convertStandardObjectMfiToObject,
	copyObjectMfi,
	copyMasterFileIndexRows,
	FILTERED_OBJECT_GENERAL_TYPE,
	createNewObject,
	OPEN_POINT_OBJECT_TYPE,
	ASSOCIATED_OBJECT_GENERAL_TYPE_UUID,
	getNewRefForObjectInUserFolder,
	saveObjects,
	createObjectHierarchyRecord,
} from "../../../../utils/StandardObject";
import {
	createNotification,
	createObjectStatus,
	getCall,
	getChecklistTasks,
	getDestinationModelObjectTypes,
	getDestinationModels,
	getGeneralTypes,
	getLatestEnvironmentReleaseVersion,
	getMasterFileIndex,
	getObjectDTOByUuidAndVersion,
	getObjectGitRecord,
	getObjectMfi,
	getSingleLevelObjectMfi,
	getSingleLevelObjectMfiFromPath,
	getStatusesForObject,
	getStatusUpdatesForDataWarehouse,
	getStatusUpdatesForObject,
	getUrl,
	getUserRoles,
	filterObjects,
	postCall,
	releaseObjects,
	saveRelationships,
} from "../../../../utils/ApiUtils";
import { useDispatch, useTrackedState } from "../../../../utils/store";
import {
	JournalsIcon,
	OpenPointIcon,
	PrintIcon,
	TogglesIcon,
	TrashIcon,
	WarningIcon,
	PersonIcon,
	FolderIcon,
	PlusIcon,
	DiffIcon,
	SaveIcon,
	SuggestionIcon,
	ShareIcon,
	PencilSquareIcon,
	FilterIcon,
	PaperClipIcon,
	ToolboxIcon,
	CheckSquareIcon,
	CubeIcon,
	ContractIcon,
	ReleaseIcon,
	EditIcon,
	BinderIcon,
} from "../../../BootstrapComponents/Icons/Icons";
import "./Checklist.scss";
import RecursiveTreeView, { CheckboxTreeDialog, createModelMfiCopy } from "../../../Tree/Tree";
import { Breadcrumbs } from "../../SetupSheetWindow/SetupSheetWindow";
import { useArray } from "../../../../utils/useStickyArray";
import { v4 as uuidv4 } from "uuid";
import { INPUT_FIELD_SOURCES, INPUT_FIELD_TYPES } from "../../../../utils/SetupTypes";
import { getSmallRef } from "../../../../utils/StringUtils";
import { dataObjects } from "../../../../utils/DataObject";
import { deleteRow, getNextRef, getPrevRef, insertRowAndReRef, sortByReference } from "../../../../utils/Referencing";
import { viewTitles } from "../../../../utils/View";
import { zeroRowUuid } from "../../../../utils/MfiUtils";
import PanelOverlay, { _removeOverlay } from "../../../PanelOverlay/PanelOverlay";
import DraggableDialog from "../../../Dialog/DraggableDialog/Dialog";
import ObjectSetupForm from "../../../ObjectSetupForm/ObjectSetupForm";
import { duplicateFile } from "../../../../utils/FileServiceUtils";
import { useIndexedDB } from "@slnsw/react-indexed-db";
import { wopiHost } from "../../../../utils/WopiServiceUtils";
import SelectionDialog from "../../../Dialog/DraggableDialog/SelectionDialog/SelectionDialog";
import { getChangedDataForSourceCodeGeneration } from "../../VisualRepPanel/VisualRepPanel";
import { checkPermissions } from "../../../../utils/StaticData";
import { getDescendantsRecursively, uuidToAncestors } from "../../../../utils/TreeUtils";
import { useVirtualizer } from "@tanstack/react-virtual";
import { formatDate } from "../../../../utils/DateUtils";
import "./UtilitiesScreen.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { UserRoles } from "../../../../utils/StaticData";
import OpenPointPanel, { selectLinkedAttribute } from "../../Panel/OpenPointPanel";
import { getAndStoreFunctions } from "../../../../utils/ExpressionUtils/GetFunctionsUtil";
import evaluateExpression from "../../../../utils/ExpressionUtils/ExpressionUtil";
import { ObjectComparisonDialog } from "../../../Comparison/ObjectComparisonDialog";
import { FileUploaderSetupField, WopiDialog } from "../../SetupSheetWindow/SetupField";
import { UpdateDialog } from "../../../Dialog/UpdateDialog";
import { useMonaco } from "@monaco-editor/react";
import {
	createChangeRequestPacket,
	createFixPacket,
	createPatchPacket,
	getPatchedItems,
	getPossibleSourceObjects,
	getReleasableItems,
	releaseToProduction,
} from "../../../VersionControl/VersionUtils";
import Version from "../../../VersionControl/Version";
import { ChangeRequestSelectionDialog } from "../../../Dialog/ChangeRequestSelectionDialog";
import { ChangeRequestForm } from "../../../ChangeRequestForm/ChangeRequestForm";
import styles from "../../Panel/OpenPointPanel.module.scss";
import diffTree from "../../../Tree/DiffTree";
import DiffTree from "../../../Tree/DiffTree";
import { AddDataWarehouseObjectsToPacketDialog } from "../../../Dialog/AddDataWarehouseObjectsToPacketDialog";
import { TwoTreeDialog } from "../../../Dialog/TwoTreeDialog";
import { objectStates } from "../../../VersionControl/ChangeRequest/ChangeRequestForm";
import { VersionControlLog } from "../../../VersionControlLog/VersionControlLog";
import { FilterDialog } from "../../../../containers/FilterDialog/FilterDialog";
import {
	getChangeRequestPacketConst,
	getChangeRequestPacketConstUuid,
	getDbConst,
	getFixPacketConstUuid,
	getPatchPacketConstUuid,
} from "../../../../utils/DbConstantsUtils";

const CreatorPanel = ({ params }) => {
	const [checklist, setChecklist] = useState([]);
	const [toc, setToc] = useState([]);
	const [relatedObjectUpdatesInPacket, setRelatedObjectUpdatesInPacket] = useState([]);
	const [objSetupFormOpen, setObjSetupFormOpen] = useState(false);
	const [objSetupFormNewVersion, setObjSetupFormNewVersion] = useState(false);
	//Boolean value to tell if we are creating a new object from an existing one (copy)
	// const [newFromExisting, setNewFromExisting] = useState(false);
	const [uploadToDataWarehouse, setUploadToDataWarehouse] = useState(false);
	const [objectToUpload, setObjectToUpload] = useState({});
	const [overwriteObject, setOverwriteObject] = useState(false);
	const [showSelectDestinationTypeDialog, setShowSelectDestinationTypeDialog] = useState(false);
	const [showSelectDestinationDialog, setShowSelectDestinationDialog] = useState(false);
	const [showAttachToDestinationObjectDialog, setShowAttachToDestinationObjectDialog] = useState(false);
	const [showCreatorPanels, setShowCreatorPanels] = useState(false);
	const [destinationModelUuid, setDestinationModelUuid] = useState(false);
	const [showDialogForTSAndNTS, setShowDialogForTSAndNTS] = useState(false);
	const [showSendToDeveloperForReviewDialog, setShowSendToDeveloperForReviewDialog] = useState(false);
	const [canCreateDataWarehouse, setCanCreateDataWarehouse] = useState(false);
	const [objectState, setObjectState] = useState("");
	const [itemsToRelease, setItemsToRelease] = useState([]);
	const [showUploadDialog, setShowUploadDialog] = useState();
	const [showFilterObjectDialog, setShowFilterObjectDialog] = useState(false);

	const newObjTransaction = useRef({});
	const printTemplates = useRef(false);
	const workspaceContainer = useRef({});
	const topObjectRow = useRef({});
	const packetRow = useRef({});
	const newFromExisting = useRef(false);
	const packetObjectUuid = useRef();
	const checklistRef = useRef({});
	const sentForReviewType = useRef({});
	//For some reason we aren't catching all the updates to the sharedState.context object, we store a ref so we can still use it
	const ancestorObjectsRef = useRef([]);
	const mfiRef = useRef([]);
	const oldMfiRef = useRef([]);
	const stockNumberChangeData = useRef({});
	const openPointChangeData = useRef([]);
	const openAfterSave = useRef(false);
	const mounted = useRef(false);
	const dialogTocRow = useRef({});
	const changesRef = useRef(false);
	const harvesting = useRef(false);
	const functions = useRef([]);
	const changeRequestSources = useRef([]);
	const toggledPacketModeForPacket = useRef(new Map());

	const monaco = useMonaco();

	const { array: breadCrumbs, set: setBreadCrumbs, push: addBreadCrumb, remove: removeBreadCrumb } = useArray([]);

	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	const {
		getAll: getHistory,
		add: addToHistory,
		deleteRecord: deleteFromHistory,
		clear: clearHistory,
	} = useIndexedDB("history");
	const {
		getAll: getLastOpenedObject,
		update: updateLastOpenedObject,
		clear: clearLastOpenedObject,
	} = useIndexedDB("lastOpenedObject");
	const {
		getAll: getAttachmentPoints,
		add: addAttachmentPoint,
		clear: clearAttachmentPoints,
		update: updateAttachmentPoint,
		deleteRecord: deleteAttachmentPoint,
	} = useIndexedDB("attachmentPoints");

	const navigate = useNavigate();
	const location = useLocation();
	const [queryParams, setQueryParams] = useSearchParams();

	useEffect(() => {
		if (sharedState.destinationUuid) {
			getAndStoreFunctions(sharedState, dispatch).then((res) => (functions.current = res));
		}
	}, [sharedState.destinationUuid, sharedState.destinationModel?.length]);

	useEffect(() => {
		let title = sharedState.contextTop.title
			? ` |  ${sharedState.contextTop.title} v${sharedState.contextTop.computerVersion}`
			: "";
		document.title = `Object Creator IDE ${title}`;
	}, [sharedState.contextTop]);

	useEffect(() => {
		let { uuid, versionUuid, path, reference, focus } = getUrlParams(queryParams);

		if (!checkIfUuidAndVersionIsAlreadyLoaded(uuid, versionUuid, sharedState))
			workspaceStartup(uuid, versionUuid, reference, path, focus);
	}, [params.uuid, params.version]);

	useEffect(() => {
		function handleKeyDown(e) {
			//Save: Command, Window, or Control Key + s
			if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {
				// Don't popup chrome save
				e.preventDefault();
			}
			if ((e.metaKey || e.ctrlKey) && e.keyCode === 83 && sharedState.thereAreChanges) {
				saveObject();
			}
			//Print: COmmand, Window, or Control Key + p
			else if ((e.metaKey || e.ctrlKey) && e.keyCode === 80) {
				e.preventDefault();
				// e.stopPropagation();
				print();
			}
		}

		document.addEventListener("keydown", handleKeyDown);

		changesRef.current = sharedState.thereAreChanges;

		return function cleanup() {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [sharedState.thereAreChanges]);

	//Catch page refresh
	useEffect(() => {
		const checkIfUserCanCreateDataWarehouses = async () => {
			let roles = await getUserRoles(sharedState.currentUser.uuid);
			//If the roles contain 'BMCL Admin' this user is authorized to create data warehouses
			let adminRole = roles.find((row) => row.title === "BMCL Admin");
			if (adminRole) setCanCreateDataWarehouse(true);
		};

		checkIfUserCanCreateDataWarehouses();

		const handleBeforeUnload = (event) => {
			// Custom logic or confirmation prompt
			// You can add your own logic or show a confirmation prompt here
			// For example:
			console.log("changes", changesRef);
			if (changesRef.current) {
				// Cancel the page reload
				event.preventDefault();
				const confirmationMessage = "Are you sure you want to leave this page?";
				event.returnValue = confirmationMessage;
				return confirmationMessage;
			} else return "";
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		window.addEventListener(
			"message",
			(event) => {
				if (event.origin !== window.origin) {
					return;
				}
				// Ch
				if (event.data.eventType === "PUT") {
					updateFromEvent(event.data.update);
				} else if (event.data.eventType === "EVALUATE") {
					//Send it like this to evaluate the expression
					// let data = { eventType: 'EVALUATE', buffer, evaluate: {expression: '', params: []}, };
					evaluate(event.data.evaluate).then((res) => {
						//How do I pass the result back?
						event.source.postMessage(
							{ type: "EVALUATE", expression: event.data.evaluate.expression, result: res },
							event.origin
						);
					});
				}
			},
			false
		);
		return () => {
			window.removeEventListener("message", () => {});
		};
	}, []);

	useEffect(() => {
		getGeneralTypes().then((types) => dispatch({ type: "SET_GENERAL_TYPES", data: types }));

		getUserRoleAndSetPermissions(sharedState.environment?.userRole);

		if (!sharedState.destinationModel || sharedState.destinationModel.length < 2) {
			let modelsPromise = getDestinationModels();

			let mdConstPromise = getDbConst("MDMFI", "mdMfi", sharedState, dispatch);

			Promise.all([modelsPromise, mdConstPromise]).then((res) => {
				let mdMfi = res[0];

				mdMfi = mdMfi.sort(sortByReference);
				dispatch({ type: "SET_DESTINATION_MODELS", data: mdMfi });
				// getDestinationModel();
			});
		}

		//Grab the destination model objects if we haven't already
		if (!sharedState.destinationModelObjects || sharedState.destinationModels.length < 1) {
			getDestinationModelObjectTypes().then((destTypes) => {
				//Only put the latest version of each object as a valid type
				let typeMap = new Map();
				destTypes.forEach((tp) => {
					if (!typeMap.has(tp.uuid)) typeMap.set(tp.uuid, tp);

					if (typeMap.get(tp.uuid).computerVersion < tp.computerVersion) typeMap.set(tp.uuid, tp);
				});
				dispatch({ type: "UPDATE_DESTINATION_MODEL_OBJECTS", data: [...typeMap.values()] });
			});
		}

		getDbConsts();
	}, []);

	useEffect(() => {
		getUserRoleAndSetPermissions(sharedState.environment?.userRole);
	}, [sharedState.environment?.userRole]);

	useEffect(() => {
		let { loadObject } = sharedState;

		if (loadObject && loadObject.computerVersion) {
			loadSpecificVersion(loadObject.computerVersion);
		} else {
			//If we already have this open, don't open it again
			if (
				!loadObject ||
				!loadObject.uuid ||
				!loadObject.versionUuid ||
				(loadObject.uuid === packetRow.current.uuid &&
					loadObject.versionUuid === packetRow.current.versionUuid) ||
				(mfiRef.current[0] &&
					loadObject.uuid === mfiRef.current[0].uuid &&
					loadObject.versionUuid === mfiRef.current[0].versionUuid)
			)
				return;

			openExisting(loadObject.uuid, loadObject.versionUuid);
			dispatch({ type: "SET_LOAD_OBJECT", data: {} });
		}
	}, [sharedState.loadObject]);

	useEffect(() => {
		convertStandardObjectMfiToObject(sharedState.contextMfi, sharedState).then((res) => {
			document.evaluateExpression = (expression) =>
				evaluateExpression(expression, sharedState, { harvestedMfi: res });
		});
		if (sharedState.contextMfiVersion !== 0) {
			mfiRef.current = sharedState.contextMfi;
			//What do I do with the diff?
			// let diff = DiffTree(mfiRef.current, oldMfiRef.current);
			return;
		}

		dispatch({ type: "SET_SELECTED_WORKSPACE_ROW", data: {} });

		getSingleLevelObjectMfi(sharedState.contextTop.uuid, sharedState.contextTop.versionUuid).then((oldMfi) => {
			oldMfiRef.current = oldMfi;

			//If there are changes, run the diff
			if (sharedState.thereAreChanges && oldMfiRef.current[0].uuid !== sharedState.contextMfi[0].uuid) {
				//Run the comparison between the oldMfi and the current MFI
				// let diff = DiffTree(sharedState.contextMfi, oldMfi);
			}
		});

		//Get the objects state
		if (sharedState.contextPacket?.uuid) getObjectStatuses(sharedState.contextPacket.uuid);
		else getObjectStatuses(sharedState.contextTop.uuid);

		let { contextMfi: mfi, contextPacket: packet } = sharedState;
		if (mfi?.length > 0) {
			mfiRef.current = mfi;
			getCreatorPanelData();
			// setBreadCrumbs([{uuid: uuidv4(), standardObjectUuid: top.uuid, standardObjectVersionUuid: top.versionUuid, title: top.title}]);
		} else if (packet && packet.uuid) {
			// setBreadCrumbs([{uuid: uuidv4(), standardObjectUuid: packet.uuid, standardObjectVersionUuid: packet.versionUuid, title: packet.title}]);
		} else {
			setChecklist([]);
			setToc([]);
		}

		let topMost = getTopMostObject(sharedState);
		let match = sharedState.destinationModel.find(
			(row) => row.standardObjectUuid === topMost.uuid && row.standardObjectVersionUuid === topMost.versionUuid
		);
		if (match) topMost.mfiReference = match.reference;
	}, [sharedState.contextMfiVersion, sharedState.contextTop?.uuid, sharedState.contextTop?.versionUuid]);

	useEffect(() => {
		getDestinationModel();
	}, [sharedState.destinationModel, sharedState.destinationModel[0]?.uuid]);

	useEffect(() => {
		if (sharedState.refreshVersionInfo) {
			let top = getTopMostObject(sharedState);

			refreshComputerVersions(top.versionUuid);
			incrementComputerVersionNumber();
		}
	}, [sharedState.refreshVersionInfo]);

	useEffect(() => {}, [sharedState.dataWarehouse.length]);

	useEffect(() => {
		if (sharedState.contextTop) topObjectRow.current = sharedState.contextTop;
	}, [sharedState.contextTop.uuid, sharedState.contextTop.versionUuid]);

	useEffect(() => {
		if (sharedState.contextAncestorObjects?.length > 0)
			ancestorObjectsRef.current = sharedState.contextAncestorObjects;
		else ancestorObjectsRef.current = [];
	}, [sharedState.contextAncestorObjects?.length]);

	//This useEffect handles the case where a packet was loaded, but there isn't a top.
	useEffect(() => {
		let { contextPacket: globalPacket, contextTop: top } = sharedState;

		if (
			globalPacket?.uuid &&
			!top.uuid &&
			(packetRow.current.uuid !== globalPacket.uuid || packetRow.current.versionUuid !== globalPacket.versionUuid)
		) {
			if (!sharedState.packetMode) dispatch({ type: "SET_PACKET_MODE", data: true });

			getChecklistAndTOC(globalPacket, null, true);
		}
		//If there used to be a packet and there isn't anymore, reset those fields
		else if (packetRow.current.uuid && !globalPacket?.uuid) {
			//Reset the checklist and packet fields
			resetCreatorPanelData();
		}
		//TODO: I think resetting is taken care of elsewhere, verify
		// else resetCreatorPanelData();
	}, [sharedState.contextPacket, sharedState.contextPacket?.uuid, sharedState.contextPacket?.versionUuid]);

	useEffect(() => {
		let { changedStockNumbers, changedVolumes, changedObjectClasses, changedLanguageFrameworks } = sharedState;
		stockNumberChangeData.current = {
			changedStockNumbers,
			changedVolumes,
			changedObjectClasses,
			changedLanguageFrameworks,
		};
	}, [
		sharedState.changedStockNumbers?.length,
		sharedState.changedVolumes?.length,
		sharedState.changedObjectClasses?.length,
		sharedState.changedLanguageFrameworks?.length,
		Object.values(sharedState.changedOpenPoints)?.length,
	]);

	useEffect(() => {
		openPointChangeData.current = Object.values(sharedState.changedOpenPoints);
	}, [sharedState.changedOpenPointUpdates]);

	useEffect(() => {
		let { primusUpdates } = sharedState;
		if (primusUpdates.length > 0) {
			primusUpdates
				.filter((updated) => toc.find((row) => row.uuid === updated.uuid))
				.forEach((row) => {
					let index = toc.findIndex((tocItem) => row.uuid === tocItem.uuid);
					toc.splice(index, 1, row);
				});
			setToc([...toc]);
			dispatch({ type: "SET_PRIMUS_UPDATES", data: [] });
		}
	}, [sharedState.primusUpdates]);

	let { uuid: paramUuid } = getUrlParams(queryParams);
	let openObject = paramUuid !== undefined;

	/**
	 * Loads the object that should be opened
	 */
	const workspaceStartup = async (uuid, versionUuid, reference, path, selected) => {
		packetObjectUuid.current = await getPacketObjectDbConst(sharedState, dispatch).referenceUuid;
		const changeRequestPacketUuid = await getChangeRequestPacketConstUuid(sharedState, dispatch);
		const fixPacketUuid = await getFixPacketConstUuid(sharedState, dispatch);
		const patchPacketUuid = await getPatchPacketConstUuid(sharedState, dispatch);

		if (!sharedState.dataWarehouse || sharedState.dataWarehouse.length < 1)
			getDataWarehouse().then((warehouseMfi) => {
				// setBmclMfi(warehouseMfi);
				dispatch({ type: "SET_DATA_WAREHOUSE_MFI", data: warehouseMfi });
			});

		//Load the database constants
		loadDbConstants();

		getObjectList();

		const attachmentPoints = await getAttachmentPoints();

		//If I'm passed a uuid and version, load that object
		if (uuid && versionUuid) {
			let promise, readonly;
			if (reference && path) {
				//Get the ancestor of the object we are opening
				let split, ancestorUuid, ancestorVersionUuid;
				let ancestor;
				if (path.includes(">")) {
					split = path.split(">");
					let firstPartSplit = split[0].split(".");
					ancestor = firstPartSplit[firstPartSplit.length - 1].split("/");
				} else {
					split = path.split(".");

					if (split.length > 1) ancestor = split[split.length - 2].split("/");
				}
				ancestorUuid = ancestor[0];
				ancestorVersionUuid = ancestor[1];

				let point = attachmentPoints.find((row) => row.uuid === uuid);

				//If there's not an attachment point update the url and return, this should only happen if using a URL someone sent where we don't have the sub-object in memory
				if (!point) {
					//Grab the uuid and version at the very beginning of the path
					let split = path.split(".");
					let [uuid, versionUuid] = split[0].split("/");
					setQueryParams({ uuid, versionUuid });

					promise = getSingleLevelObjectMfi(uuid, versionUuid);
					reference = undefined;
					path = undefined;
				} else {
					readonly = point.readonly;
					promise = getSingleLevelObjectMfi(uuid, versionUuid, null, null, {
						uuid,
						versionUuid,
						reference,
						readonly: point.readonly,
						parentUuid: point.parentUuid,
						objectHierarchy: [
							{
								ancestorStandardObjectUuid: ancestorUuid,
								ancestorStandardObjectVersionUuid: ancestorVersionUuid,
								descendantStandardObjectUuid: uuid,
								descendantStandardObjectVersionUuid: versionUuid,
								pathEnum: path,
							},
						],
					});
				}
			} else promise = getSingleLevelObjectMfi(uuid, versionUuid);

			promise.then((mfi) => {
				//Not sure if the mfi really contains the top record, I can figure this out later. This code is rarely called if ever
				let top = mfi[0];
				// mfi.shift();
				if (readonly) top.readonly = readonly;

				//If there is a path, grab each ancestor and set it so the breadcrumbs will be updated
				let promises = [];
				if (path) {
					let currentRelatedObject;
					let relatedObjects = [];
					//TODO: There are a couple issues with these related objects that I need to resolve after Nate fixes his issues.
					if (path.includes(">")) {
						//Remove the end uuid and version because that isn't an ancestor
						//TODO: Not sure what the path would look like if i navigated into a nested related object. Need to test later
						//TODO: This assumes that the path wont be object/version>relatedObject/version>nestedRelated/version
						if (path.includes("."))
							path = path
								.split(".")
								.slice(0, path.split(".").length - 1)
								.join(".");
						else {
							let split = path.split(">");
							currentRelatedObject = split[split.length - 1].split("/");
							path = path.split(">")[0];
						}

						//If the path includes the relation symbol, we also need to update the currentRelatedObject
						let split = path.split(">");
						//The current related object is in the last part
						let end = split[split.length - 1].split(".");
						if (!currentRelatedObject) currentRelatedObject = end[0].split("/");

						//We are getting everything by the path, there can be multiple related objects in the path and we need to treat everything as if we started at the top and navgiated all the way down
						split.forEach((piece, index) => {
							let splitPiece = piece.split(".");
							if (index !== 0) relatedObjects.push(splitPiece[0]);

							splitPiece.forEach((obj) => {
								let [uuid, versionUuid] = obj.split("/");
								promises.push(getObjectGitRecord(uuid, versionUuid));
							});
						});
					} else {
						let split = path.split(".");
						//Remove the last object because that is the current one and we add that to our breadcrumbs differently
						split.pop();
						split.forEach((obj) => {
							let [uuid, versionUuid] = obj.split("/");
							promises.push(getObjectGitRecord(uuid, versionUuid));
						});
					}

					Promise.all(promises).then((res) => {
						let update = {};
						if (currentRelatedObject) {
							let relatedObject = res.find(
								(row) =>
									row.uuid === currentRelatedObject[0] && row.versionUuid === currentRelatedObject[1]
							);
							if (
								!relatedObject &&
								top.uuid === currentRelatedObject[0] &&
								top.versionUuid === currentRelatedObject[1]
							) {
								update.currentRelatedObject = top;
								top.associatedObject = true;
							} else update.currentRelatedObject = relatedObject;

							res.forEach((ancestor) => {
								if (relatedObjects.includes(getObjectIdAndVersionUuid(ancestor)))
									ancestor.associatedObject = true;
							});
						}

						let ancestorPackets = res.filter((row) => row.objectTypeUuid === packetObjectUuid.current);
						if (ancestorPackets.length > 0) {
							const versionControlPackets = ancestorPackets.filter(
								(row) =>
									row.standardObjectUuid === changeRequestPacketUuid ||
									row.standardObjectUuid === fixPacketUuid ||
									row.standardObjectUuid === patchPacketUuid
							);
							//If one of the packets is a version control packet, set that as the packet
							if (!path.includes(">") && versionControlPackets.length > 0)
								update.packet = versionControlPackets[versionControlPackets.length - 1];
							else update.packet = ancestorPackets[ancestorPackets.length - 1];
						}

						if (selected) update.selectedWorkspaceRow = mfi.find((row) => row.uuid === selected);

						dispatch({
							type: "SET_CONTEXT_OR_MFI",
							data: {
								ancestorObjects: res,
								top,
								mfi,
								objectHierarchy: top.objectHierarchy,
								...update,
								resetContextMfiVersion: true,
							},
						});
					});
				}
				//TODO-VersionControl This is one of the main places to address multiple workspace utilities
				else if (top.objectTypeUuid === packetObjectUuid.current)
					dispatch({
						type: "SET_CONTEXT_OR_MFI",
						data: {
							packet: top,
						},
					});
				else
					dispatch({
						type: "SET_CONTEXT_OR_MFI",
						data: {
							top,
							mfi,
							selectedWorkspaceRow: mfi.find((row) => row.uuid === selected),
							objectHierarchy: top.objectHierarchy,
							resetContextMfiVersion: true,
						},
					});
			});
		}
	};

	/**
	 * Retrieve list of objects if needed
	 * @param event
	 * @param createNew
	 */
	const getObjectList = () => {
		if (sharedState.objects && sharedState.objects.length > 0) return sharedState.objects;

		getExistingObjects(sharedState.destinationModel[0]?.uuid, sharedState, dispatch);
	};

	const loadDbConstants = async () => {
		if (!sharedState.dbConstants.sourceFileType)
			getCall(getUrl("getDbConstByRef", ["SRCCDFL"])).then((fileType) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "sourceFileType", data: fileType })
			);

		if (!sharedState.dbConstants.sourcePieceType)
			getCall(getUrl("getDbConstByRef", ["SRCCDPC"])).then((pieceType) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "sourcePieceType", data: pieceType })
			);

		if (!sharedState.dbConstants.objectType)
			getCall(getUrl("getDbConstByRef", ["OBJTP"])).then((objTp) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "objectType", data: objTp })
			);

		if (!sharedState.dbConstants.templateType)
			getCall(getUrl("getDbConstByRef", ["TMPLT"])).then((templateTp) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "templateType", data: templateTp })
			);

		if (!sharedState.dbConstants.setupSheetType)
			getCall(getUrl("getDbConstByRef", ["SETUPSHEET"])).then((setupSheetTp) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "setupSheetType", data: setupSheetTp })
			);

		if (!sharedState.dbConstants.approvedObjMfi)
			getCall(getUrl("getDbConstByRef", ["APPROBJMFI"])).then((approvedObjMfiTp) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "approvedObjMfi", data: approvedObjMfiTp })
			);

		if (!sharedState.dbConstants.mfiViewType)
			getCall(getUrl("getDbConstByRef", ["MFIVIEW"])).then((mfiViewType) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "mfiViewType", data: mfiViewType })
			);

		if (!sharedState.dbConstants.processObject)
			getCall(getUrl("getDbConstByRef", ["PRCSSOBJ"])).then((processObject) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "processObject", data: processObject })
			);

		if (!sharedState.dbConstants.fileObject)
			getCall(getUrl("getDbConstByRef", ["FILE"])).then((fileObject) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "fileObject", data: fileObject })
			);

		if (!sharedState.dbConstants.objDefRel)
			getCall(getUrl("getDbConstByRef", ["OBJDEFREL"])).then((objDefRel) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "objDefRel", data: objDefRel })
			);

		if (!sharedState.dbConstants.objUsrRel)
			getCall(getUrl("getDbConstByRef", ["OBJUSRREL"])).then((objUsrRel) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "objUsrRel", data: objUsrRel })
			);

		if (!sharedState.dbConstants.packetObject)
			getCall(getUrl("getDbConstByRef", ["PK"])).then((packet) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "packet", data: packet })
			);

		//Retrieve the Version Control Process packets
		if (!sharedState.dbConstants.changeRequestPacket)
			getCall(getUrl("getDbConstByRef", ["CHRQPKT"])).then((packet) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "changeRequestPacket", data: packet })
			);
		if (!sharedState.dbConstants.fixPacket)
			getCall(getUrl("getDbConstByRef", ["FIXPKT"])).then((packet) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "fixPacket", data: packet })
			);
		if (!sharedState.dbConstants.patchPacket)
			getCall(getUrl("getDbConstByRef", ["PATCHPKT"])).then((packet) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "patchPacket", data: packet })
			);

		if (!sharedState.dbConstants.changeRequestForm)
			getCall(getUrl("getDbConstByRef", ["CRF"])).then((changeRequestForm) =>
				dispatch({ type: "SET_DB_CONSTANT", constant: "changeRequestForm", data: changeRequestForm })
			);

		if (!sharedState.dbConstants.ubmMenu)
			getCall(getUrl("getDbConstByRef", ["UBMMENU"])).then(async (ubmMenu) => {
				dispatch({ type: "SET_DB_CONSTANT", constant: "ubmMenu", data: ubmMenu });
				//Get the ubm menu object
				let ubmMenuObject = await getObjectMfi(ubmMenu.referenceUuid);
				dispatch({ type: "SET_MENU_OBJECT", constant: "ubmMenu", data: ubmMenuObject });
			});

		if (!sharedState.dbConstants.ubmScreen)
			getCall(getUrl("getDbConstByRef", ["UBMSCRN"])).then(async (ubmScreen) => {
				dispatch({ type: "SET_DB_CONSTANT", constant: "ubmScreen", data: ubmScreen });
				//Get the ubm screen object
				let ubmScreenObject = await getObjectMfi(ubmScreen.referenceUuid);
				dispatch({ type: "SET_SCREEN_OBJECT", constant: "ubmScreen", data: ubmScreenObject });
			});

		if (!sharedState.dbConstants.processObject) getDbConst("PRCSSOBJ", "processObject", sharedState, dispatch);
		if (!sharedState.dbConstants.openPointSheetObject)
			getDbConst("OPS", "openPointSheetObject", sharedState, dispatch);
		if (!sharedState.dbConstants.openPointObject) getDbConst("OP", "openPointObject", sharedState, dispatch);
		if (!sharedState.dbConstants.versionControlLog) getDbConst("VCL", "versionControlLog", sharedState, dispatch);
		if (!sharedState.dbConstants.change) getDbConst("CHANGE", "change", sharedState, dispatch);

		getPacketObjectDbConst(sharedState, dispatch).then((packetConst) => {
			packetObjectUuid.current = packetConst.referenceUuid;
		});
	};

	const evaluate = async ({ expression, params }) => {
		//If the functions aren't there get them first
		if (functions.current.length < 1) {
			functions.current = await getAndStoreFunctions(sharedState, dispatch);
		}
		return await evaluateExpression(expression, sharedState, sharedState.contextTop, params, functions.current);
	};

	const getObjectStatuses = async (uuid) => {
		if (!uuid) return;
		sentForReviewType.current = await getDbConst("SNTREVFLD", "sentForReviewFolderType", sharedState, dispatch);

		let statuses = await getStatusUpdatesForObject(uuid);
		statuses = statuses.sort((a, b) => a.createdAt - b.createdAt);
		setObjectState(statuses[statuses.length - 1]);
	};

	const getDiff = () => {};

	const updateFromEvent = async (updates) => {
		let updatedRows = [];
		await Promise.all(
			updates.map(async (row) => {
				let mfi;
				let updateCurrentContext = sharedState.contextTop.uuid === row.uuid;
				if (!updateCurrentContext)
					mfi = await getSingleLevelObjectMfi(row.uuid, row.versionUuid, dispatch, null);
				else mfi = mfiRef.current;

				delete row.uuid;
				delete row.versionUuid;
				let attributes = Object.keys(row);
				attributes.forEach((attribute) => {
					let mfiRow = mfi?.find((mfiRow) => mfiRow.title === attribute);
					if (!mfiRow) return;
					mfiRow.value = row[attribute];
					updatedRows.push(mfiRow);
				});

				let update = {
					objectRows: updatedRows,
				};

				if (!updateCurrentContext) update.subObjectToUpdate = mfi[0];

				updateChangeData(dispatch, update);
			})
		);
	};

	const resetCreatorPanelData = () => {
		setToc([]);
		setChecklist([]);
		packetRow.current = {};
		dispatch({ type: "SET_PACKET_MODE", data: false });
	};

	const resetMfiRowChanges = (mfi) => {
		mfi.forEach((row) => {
			if (row.added !== undefined) delete row.added;
			if (row.changes !== undefined) delete row.changes;
			if (row.descendantModified !== undefined) delete row.descendantModified;
		});
	};

	const getDbConsts = () => {
		//Get the developer general type
		getDbConst("DEVDFN", "developerType", sharedState, dispatch);
		getDbConst("UBMDFN", "ubmType", sharedState, dispatch);
		getDbConst("ASCOBJ", "associatedObjectType", sharedState, dispatch);
		getDbConst("SUBOBJ", "subObjectType", sharedState, dispatch);
		getDbConst("OP", "openPointObject", sharedState, dispatch);
		getDbConst("FN", "functionObject", sharedState, dispatch);
		getDbConst("IPFLD", "inProcessFolderType", sharedState, dispatch);
		getDbConst("RVFLD", "reviewFolderType", sharedState, dispatch);
		getDbConst("SNTREVFLD", "sentForReviewFolderType", sharedState, dispatch);
		getDbConst("DS", "dataSourceObject", sharedState, dispatch);
	};

	const getRelatedObjectType = async () => {
		return await getDbConst("ASCOBJ", "associatedObjectType", sharedState, dispatch);
	};

	const getUBMDefinedConstant = async () => {
		let ubmType = await getDbConst("UBMDFN", "ubmType", sharedState, dispatch);
		return ubmType;
	};

	const getUserRoleAndSetPermissions = async (userRole) => {
		if (!userRole) {
			userRole = getUserRole(sharedState);
			dispatch({ type: "UPDATE_ENVIRONMENT", data: { userRole } });
		}

		let index = UserRoles.indexOf(userRole);
		//Check if the current role is allowed to edit creators
		let canEditCreators = checkPermissions(index, 0);
		let { contextTop: top, contextPacket: packet, contextAncestorObjects: ancestorObjects } = sharedState;

		//If we went from not editing creators to editing creators reset the checklist and toc
		// if (!sharedState.editPacketMode && canEditCreators) {
		// 	setToc([]);
		// 	setChecklist([]);
		//
		// 	if (packet?.uuid) {
		// 		openExisting(packet.uuid, packet.versionUuid, packet.computerVersion);
		// 		setPacket({});
		// 		dispatch({ type: "SET_CONTEXT_OR_MFI", data: { packet: null } });
		// 	}
		// 	// setShowCreatorPanels(false);
		// 	packetRow.current = {};
		// } else {
		// 	if (ancestorObjects?.length > 0)
		// 		await openExisting(
		// 			ancestorObjects[0].uuid,
		// 			ancestorObjects[0].versionUuid,
		// 			ancestorObjects[0].computerVersion
		// 		);
		//
		// 	getCreatorPanelData();
		// }
	};

	const getProcessObjectDbConst = async () => {
		if (!sharedState.dbConstants.processObject) {
			let processObject = await getCall(getUrl("getDbConstByRef", ["PRCSSOBJ"]));
			dispatch({
				type: "SET_DB_CONSTANT",
				constant: "processObject",
				data: processObject,
			});
			return processObject;
		} else {
			return sharedState.dbConstants.processObject;
		}
	};

	/**
	 * Checks if the open object has a packet type, if so, shows the creator panels (TOC and Checklist) and populates them
	 *
	 * @param
	 */
	const getCreatorPanelData = async () => {
		let {
			contextTop: top,
			contextPacket: packet,
			contextMfi: mfi,
			contextAncestorObjects: ancestorObjects,
		} = sharedState;
		//Get the packet type
		let packetConst = await getPacketObjectDbConst(sharedState, dispatch);
		let changeRequestPacketUuid = await getDbConst("CHRQPKT", "changeRequestPacket", sharedState, dispatch);
		let fixPacketUuid = await getFixPacketConstUuid(sharedState, dispatch);
		let patchPacketUuid = await getPatchPacketConstUuid(sharedState, dispatch);
		packetObjectUuid.current = packetConst.referenceUuid;

		//If top is not a packet and there isn't a packet, leave packet mode
		if (
			top.objectTypeUuid !== packetObjectUuid.current &&
			packet?.objectTypeUuid !== packetObjectUuid.current &&
			packetRow.current?.objectTypeUuid !== packetObjectUuid.current
		) {
			dispatch({ type: "SET_PACKET_MODE", data: false });
			packetRow.current = {};
			loadObjectVersions(dispatch, top.versionUuid);
			getObjectStatuses(top.uuid);
			return;
		}

		//We need to do a check here to see if this packet has already been opened
		//if top is a packet, set it as the global states packet
		if (
			packetRow.current.uuid &&
			((packetRow.current.uuid === packet?.uuid && packetRow.current.versionUuid === packet?.versionUuid) ||
				(top.ancestors?.includes(packetRow.current.uuid) &&
					packetRow.current.standardObjectUuid === changeRequestPacketUuid))
		)
			return;
		else if (
			top.objectTypeUuid === packetObjectUuid.current &&
			packet?.standardObjectUuid !== changeRequestPacketUuid &&
			packet?.standardObjectUuid !== patchPacketUuid &&
			packet?.standardObjectUuid !== fixPacketUuid
		) {
			if (packetRow.current?.uuid === top.uuid && packetRow.current?.versionUuid === top.versionUuid) return;

			dispatch({
				type: "SET_CONTEXT_OR_MFI",
				data: {
					packet: top,
				},
			});

			packet = top;
		}

		let packetMode;
		if (packet) {
			if (toggledPacketModeForPacket.current.has(packet.uuid))
				packetMode = toggledPacketModeForPacket.current.get(packet.uuid);
			else packetMode = true;
			//If we've gotten to this point, load the checklist and toc for the packet
			getChecklistAndTOC(packet, null, !top.uuid || top.uuid === packet.uuid);
			if (sharedState.contextPacket?.uuid !== packet.uuid)
				dispatch({ type: "SET_CONTEXT_OR_MFI", data: { packet } });
		} else {
			packetMode = false;
		}

		dispatch({ type: "SET_PACKET_MODE", data: packetMode });
		// //If the user can edit harvested creators, hide the toc and checklist panel, move everything over to the new modified panel
		// if (sharedState.editPacketMode) {
		// 	if (!showCreatorPanels) {
		// 		loadObjectVersions(dispatch, top.versionUuid);
		// 		return;
		// 	}
		//
		// 	// setShowCreatorPanels(false);
		// 	packetRow.current = {};
		//
		// 	//How do we move everything over to the new modified panel?
		// 	dispatch({ type: "SET_PACKET_MODE", data: true });
		//
		// 	let newTop = getTopMostObject(sharedState);
		// 	openExisting(newTop.uuid, newTop.versionUuid, newTop.computerVersion);
		// 	return;
		// }

		//This handles the specific case where a packet is loaded but doesn't have a sub-object to load so there is no top, if it's triggered on any other case it's a bug.
		// if (!top.uuid && packet?.uuid) return;

		/**
		 * How do we know if we're supposed to show the creator panels?
		 * 	We show them:
		 * 		1. If the object open is a packet and it has a harvested type
		 * 		2. If an ancestor has a packet type and is harvested.
		 *
		 * 	We don't show them:
		 * 		1. If the user has a BMCL role - DONE
		 * 		2. If the packet isn't harvested yet.
		 */

		//The user is not allowed to edit harvested packets, un-harvested packets show in edit packet mode, harvested packets show in packet mode
		// let ancestorPackets = ancestorObjects.filter((row) => row.objectTypeUuid === packetObjectUuid.current);
		//
		// //If the object open is a packet and it has a harvested type show in packet mode
		// if (
		// 	!sharedState.editPacketMode ||
		// 	(top.objectTypeUuid === packetObjectUuid.current &&
		// 		top.generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE &&
		// 		(packet.uuid === top.uuid ||
		// 			packet.standardObjectUuid !== sharedState.dbConstants.changeRequestPacket.referenceUuid))
		// ) {
		// 	await dispatch({ type: "SET_PACKET_MODE", data: false });
		// 	dispatch({ type: "SET_CONTEXT_OR_MFI", data: { packet: top } });
		//
		// 	getChecklistAndTOC(top, mfi, true);
		// 	// setShowCreatorPanels(true);
		// }
		// //If an ancestor has a packet type and is harvested.
		// else if (
		// 	(ancestorObjects.length > 0 &&
		// 		ancestorPackets.length > 0 &&
		// 		ancestorPackets[ancestorPackets.length - 1].generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE) ||
		// 	packet?.generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE
		// ) {
		// 	let packetToOpen = ancestorPackets[ancestorPackets.length - 1];
		// 	getChecklistAndTOC(packetToOpen, null, false);
		// 	await dispatch({ type: "SET_PACKET_MODE", data: false });
		// 	dispatch({ type: "SET_CONTEXT_OR_MFI", data: { packet: packetToOpen } });
		// 	// setShowCreatorPanels(true);
		// } else {
		// 	dispatch({ type: "SET_PACKET_MODE", data: true });
		// 	loadObjectVersions(dispatch, top.versionUuid);
		// 	// setShowCreatorPanels(false);
		// }
		//
		// //Load the related object records
		// loadRelationships(mfi);
	};

	/**
	 * Get the related objects for the open object
	 * @param objectUuid
	 * @param updateGlobalState
	 */
	const loadRelationships = async (mfi, updateGlobalState = true) => {
		//Get the object hierarchy and find the hierarchy records with the related object hierarchy type
		let { contextObjectHierarchy: objectHierarchy } = sharedState;

		let relationships = [];
		let relatedObjectType = await getRelatedObjectType();
		let relatedHierarchyRecords = objectHierarchy.filter((row) => row.hierarchyTypeUuid === relatedObjectType);

		if (relatedHierarchyRecords.length > 0) {
			relatedHierarchyRecords.forEach((hier) => {
				let row = mfi.find(
					(row) =>
						row[linkAttributeName] === hier.descendantStandardObjectUuid &&
						row[linkVersionAttributeName] === hier.descendantStandardObjectVersionUuid
				);
				relationships.push({ ...row, objectHierarchy: [hier] });
			});
		}

		//Return the relatedHierarchyRecords?

		if (updateGlobalState) {
			let relatedObjectUpdates = checkForRelatedObjectUpdates(relationships);
			//I'll assume if we're updating the global state, that these relationships are for the current open object
			dispatch({
				type: "SET_CONTEXT_OR_MFI",
				data: {
					relatedObjects: relationships,
					relatedObjectUpdates,
				},
			});
		}
		//
		return relationships;
	};

	/**
	 * Checks if the current object is related to an older version of an object in the reference manual
	 */
	const checkForRelatedObjectUpdates = (relationships) => {
		let updates = [];
		//For each current relationship, check the destination model for an object with matching uuid and versionUuid
		relationships.forEach((row) => {
			let relatedUuid = row[linkAttributeName];
			let relatedVersion = row[linkVersionAttributeName];

			let destinationModelRow = sharedState.destinationModel.find(
				(mfiRow) =>
					mfiRow.standardObjectUuid === relatedUuid && mfiRow.standardObjectVersionUuid === relatedVersion
			);

			//If we didn't find a destinationModelRow that either means the object was deleted from reference manual, or that it was updated to a newer version
			if (!destinationModelRow) {
				destinationModelRow = sharedState.destinationModel.find(
					(mfiRow) =>
						mfiRow.standardObjectUuid === relatedUuid && mfiRow.computerVersion > row.computerVersion
				);
				if (destinationModelRow)
					updates.push({ ...row, newVersionUuid: destinationModelRow.standardObjectVersionUuid });
			}
		});

		return updates;
	};

	const getChecklistAndTOC = async (top, mfi, loadFirstPacketProduct = true) => {
		/**
		 *  Criteria change:
		 *  	1. Checklist and TOC never show for un-harvested packet
		 *  	2. Checklist and TOC always show for harvested packet unless the user is a BMCL Role
		 */

		//We've already set it or its changed and we're allowing the packet to be edited
		if (
			!top?.uuid ||
			!top?.versionUuid ||
			(packetRow.current?.uuid === top?.uuid && packetRow.current?.versionUuid === top?.versionUuid)
		)
			return;

		loadObjectVersions(dispatch, top.versionUuid);
		getObjectStatuses(top.uuid);

		//Get the checklist type
		let processConst = await getProcessObjectDbConst();
		let processObjectUuid = processConst.referenceUuid;
		let { contextObjectHierarchy: objectHierarchy, contextFullObjectHierarchy: fullObjectHierarchy } = sharedState;

		//How do we know if we want to load an object from the packet or
		packetRow.current = top;

		if (!mfi) {
			//If this is the very top we dont need to send an attachmentPoint
			if (
				sharedState.contextAncestorObjects &&
				sharedState.contextAncestorObjects.length > 0 &&
				top.uuid === sharedState.contextAncestorObjects[0].uuid
			)
				mfi = await getSingleLevelObjectMfi(top.uuid, top.versionUuid);
			else mfi = await getSingleLevelObjectMfi(top.uuid, top.versionUuid, dispatch, null, top);

			//It's ok to get most attributes from the mfi we get back.
			//However, some only come from the top and will get erased if we don't copy them over
			packetRow.current = {
				...mfi[0],
				readonly: packetRow.current.readonly,
			};
			dispatch({
				type: "SET_CONTEXT_OR_MFI",
				data: {
					packet: packetRow.current,
				},
			});
		}

		//If an ancestor is a packet object, load its checklist
		let packetRelationships = await loadRelationships(mfi, false);
		let updates = checkForRelatedObjectUpdates(packetRelationships, mfi);
		setRelatedObjectUpdatesInPacket(updates);
		// setShowCreatorPanels(true);

		//Get the checklist and object to populate the workspace with
		let components = mfi.find((row) => row.title === oldComponentsNodeTitle || row.title === componentsNodeTitle);
		let descendants = getToc(mfi);
		descendants[0].objectHierarchy = mfi[0].objectHierarchy;

		let checklist = descendants.find((row) => row.objectTypeUuid === processObjectUuid);
		if (checklist) {
			const checklistHierarchyRecord = mfi[0].objectHierarchy.find(
				(row) => row.descendantStandardObjectUuid === checklist.uuid
			);
			checklist.objectHierarchy = [checklistHierarchyRecord];
			buildChecklist(checklist);
		}

		//TODO: Dont delete Adam this is feature you asked for, that Nate thinks nobody uses. We can make it work later if needed.
		//For each object on the TOC get the relationships section for it and add it.
		// let objects = descendants.filter((row) => row.isObject && row.uuid !== checklist?.uuid);
		// let promises = [];
		// objects.forEach((obj) => {
		// 	let matchingHierarchyRecord = objectHierarchy.find(
		// 		(row) =>
		// 			row.descendantStandardObjectUuid === obj.uuid &&
		// 			row.descendantStandardObjectVersionUuid === obj.versionUuid
		// 	);
		// 	if (!matchingHierarchyRecord && fullObjectHierarchy?.length > 0)
		// 		matchingHierarchyRecord = fullObjectHierarchy.find(
		// 			(row) =>
		// 				row.descendantStandardObjectUuid === obj.uuid &&
		// 				row.descendantStandardObjectVersionUuid === obj.versionUuid
		// 		);
		//
		// 	if (matchingHierarchyRecord)
		// 		promises.push(
		// 			getSingleLevelObjectMfi(obj.uuid, obj.versionUuid, null, null, {
		// 				...obj,
		// 				objectHierarchy: [matchingHierarchyRecord],
		// 			})
		// 		);
		// 	else promises.push(getSingleLevelObjectMfi(obj.uuid, obj.versionUuid, null, null, obj));
		// });
		// if (promises.length > 0) {
		// 	Promise.all(promises).then((res) => {
		// 		for (let i = 0; i < res.length; i++) {
		// 			//Get the relationships
		// 			let mfi = res[i];
		// 			let object = objects[i];
		//
		// 			if (!mfi) break;
		//
		// 			//Grab the relationship section, its direct children and then its descendants, we are going to attach this mfi to the toc
		// 			let relationshipSection = mfi.find(
		// 				(row) => row.title === relationshipNodeTitle && row.parentUuid === object.uuid
		// 			);
		// 			let relationshipChildren = mfi.filter((row) => row.parentUuid === relationshipSection.uuid);
		// 			let relationshipDescendants = mfi.filter(
		// 				(row) =>
		// 					row.reference.startsWith(relationshipSection.reference) &&
		// 					row.uuid !== relationshipSection.uuid
		// 			);
		//
		// 			relationshipChildren.forEach((row) => (row.parentUuid = object.uuid));
		// 			descendants = [...descendants, ...relationshipDescendants];
		// 		}
		//
		// 		//Populate the TOC
		// 		setToc(descendants);
		// 	});
		// } else setToc(descendants);
		let object;

		let changeRequestPacketUuid = await getChangeRequestPacketConstUuid(sharedState, dispatch);
		let fixPacketUuid = await getFixPacketConstUuid(sharedState, dispatch);

		if (packetRow.current.standardObjectUuid === changeRequestPacketUuid) {
			//Get the change request section F add the sources for each one.
			let sectionF = descendants.find((row) => row.reference === "04.06");
			let sectionFChildren = descendants.filter((row) => row.parentUuid === sectionF.uuid);
			if (sectionFChildren.length > 0) {
				changeRequestSources.current = sectionFChildren.map((row) => row.uuid);
				object = sectionFChildren[0];
			}
			//If that section is empty get sources from section D
			else {
				let sectionD = descendants.find((row) => row.reference === "04.04");
				let sectionDChildren = descendants.filter((row) => row.parentUuid === sectionD.uuid);
				changeRequestSources.current = sectionDChildren.map((row) => row.uuid);
				object = sectionDChildren[0];
			}
		} else changeRequestSources.current = [];
		setToc(descendants);

		/**
		 * To start with I'm going to assume a packet has 3 sections and that the objects are in the third section usually called `(C) Products`
		 * Grab the first object in that and call loadSubObject on it
		 */
		let tocLevel1 = descendants.filter((row) => row.parentUuid === components.uuid);

		if (packetRow.current.standardObjectUuid === fixPacketUuid) {
			object = descendants.find((row) => row.parentUuid === tocLevel1[3].uuid && row.isObject);
		}
		if (!object && tocLevel1.length >= 3)
			object = descendants.find((row) => row.parentUuid === tocLevel1[2].uuid && row.isObject);

		let hierarchyRecord = mfi[0].objectHierarchy?.find(
			(row) =>
				(row.descendantStandardObjectUuid === object?.uuid &&
					row.descendantStandardObjectVersionUuid === object?.versionUuid) ||
				(row.descendantStandardObjectUuid === object?.linkToObjectUuid &&
					row.descendantStandardObjectVersionUuid === object?.linkToObjectVersionUuid)
		);

		if (!sharedState.packetMode) toggleEditPacket();

		if (loadFirstPacketProduct || mfi[0].objectTypeUuid === processObjectUuid) {
			if (object && hierarchyRecord) {
				if (hierarchyRecord.hierarchyTypeUuid === ASSOCIATED_OBJECT_GENERAL_TYPE_UUID) {
					if (sharedState.contextAncestorObjects?.length < 1)
						sharedState.contextAncestorObjects = [packetRow.current];
					loadRelatedObject(
						hierarchyRecord,
						object,
						sharedState,
						dispatch,
						true,
						{
							addAttachmentPoint,
							getAttachmentPoints,
							updateAttachmentPoint,
							deleteAttachmentPoint,
						},
						setQueryParams
					);
				} else if (sharedState.contextTop?.uuid !== object.uuid)
					await loadSubObject(
						object,
						hierarchyRecord,
						sharedState,
						dispatch,
						false,
						{
							addAttachmentPoint,
							getAttachmentPoints,
							updateAttachmentPoint,
							deleteAttachmentPoint,
						},
						null,
						setQueryParams
					);
			} else {
				//Set the top to empty so it doesn't let the user edit the packet
				dispatch({ type: "SET_CONTEXT_OR_MFI", data: { top: {}, mfi: [] } });
			}
		}
	};

	/**
	 * Gets the tasks within the object mfi and builds the checklist
	 * @param mfi
	 */
	const buildChecklist = async (checklist) => {
		try {
			checklistRef.current.uuid = checklist.uuid;
			checklistRef.current.versionUuid = checklist.versionUuid;

			checklistRef.current.title = checklist.title;
			checklistRef.current.reference = checklist.reference;

			let tasks = await getChecklistTasks(checklist.uuid, checklist.versionUuid, {
				changedRows: getChangedDataForSourceCodeGeneration(sharedState),
				hierarchyRecord: checklist.objectHierarchy[0],
			});
			setChecklist(tasks);
		} catch (e) {
			console.error("Unable to get checklist tasks");
			console.error(e);
		}
	};

	/**
	 * Gets the destination model from the shared state, if it isn't set, guides the user through selecting or creating one.
	 * @param mfi
	 */
	const getDestinationModel = async (destinationUuid) => {
		//Get the destination model, I am assuming if I pass in a destinationUuid I want to load it even if its the same one previously loaded (Most likely there has been changes made)
		if (!destinationUuid) {
			if (!sharedState.destinationModel[0]?.uuid) {
				destinationUuid = localStorage.destinationModel;
				if (!destinationUuid) {
					//Pull up dialog to select the destination model.
					setShowSelectDestinationDialog(true);

					//Clear the creator and source panels
					resetWorkspace();

					setDestinationModelUuid("");

					//Clear the history so that if you refresh it doesn't pull up an object that may be in a destination you don't have access to
					await clearHistory();
					return;
				}
			} else if (sharedState.destinationModel.length === 1)
				destinationUuid = sharedState.destinationModel[0].uuid;
			else return sharedState.destinationModel;

			//Don't load the destinationModel if we have already loaded it.
			if (destinationUuid === destinationModelUuid) return sharedState.destinationModel;
		}

		//Fill in the destination model's UBM sections
		if (destinationUuid) {
			setDestinationModelUuid(destinationUuid);

			//Get the destination model mfi
			let destinationModelMfi = await getMasterFileIndex(destinationUuid);

			let ubmType = await getUBMDefinedConstant();

			let { dataWarehouse } = sharedState;
			if (dataWarehouse.length < 1) dataWarehouse = await getDataWarehouse(dispatch, sharedState);

			let ubmRowsToAddToDestination = [];
			if (dataWarehouse[0].uuid !== destinationUuid) {
				//Get the UBM sections
				let ubmSections = destinationModelMfi.filter((row) => row.mfiGeneralTypeUuid === ubmType);
				ubmSections.forEach((row) => {
					//Find the corresponding row in the UBM Data Warehouse
					let matchInDataWarehouse = dataWarehouse.find(
						(dwRow) => dwRow.standardObjectUuid === row.standardObjectUuid
					);
					if (matchInDataWarehouse) {
						let matchingMfi = dataWarehouse.filter(
							(dwRow) =>
								dwRow.reference.startsWith(matchInDataWarehouse.reference) &&
								dwRow.uuid !== matchInDataWarehouse.uuid
						);

						let children = matchingMfi.filter((row) => row.parentUuid === matchInDataWarehouse.uuid);
						children.forEach((dwRow) =>
							ubmRowsToAddToDestination.push({
								...dwRow,
								parentUuid: row.uuid,
								reference: getNextRef(row.reference + "." + (dwRow.referenceNo - 1)).reference,
								[UBM_MASTER_FILE_INDEX_ITEM]: true,
							})
						);
						matchingMfi
							.filter((row) => row.parentUuid !== matchInDataWarehouse.uuid)
							.forEach((dwRow) =>
								ubmRowsToAddToDestination.push({
									...dwRow,
									reference: dwRow.reference.replace(matchInDataWarehouse.reference, row.reference),
									[UBM_MASTER_FILE_INDEX_ITEM]: true,
								})
							);
					}
				});
			}

			getExistingObjects(destinationUuid, sharedState, dispatch).then((objects) =>
				dispatch({ type: "SET_ATTACHABLE_TYPES", data: objects })
			);

			//Fill in each persons Review and Sent for Review Folder
			if (destinationModelMfi && destinationModelMfi.length > 0) {
				if (ubmRowsToAddToDestination.length > 0) {
					let uuidsToRemove = [];
					//Loop over ubmRowsToAddToDestination, if there are any rows in the destinationModelMFI with the same title and standardObjectUuid filter it out
					ubmRowsToAddToDestination.forEach((row) => {
						let duplicateRow = destinationModelMfi.find(
							(_row) =>
								_row.standardObjectUuid &&
								_row.standardObjectUuid === row.standardObjectUuid &&
								_row.title === row.title
						);
						if (duplicateRow?.uuid) uuidsToRemove.push(duplicateRow.uuid);
					});

					destinationModelMfi = [
						...destinationModelMfi.filter((row) => !uuidsToRemove.includes(row.uuid)),
						...ubmRowsToAddToDestination,
					];
				}

				//Get all objects that are in review, move them to the corresponding review and sent for review folders
				let developerTypeUuid = await getDbConst("DEVDFN", "developerType", sharedState, dispatch);
				let developerFolderTypeUuid = await getDbConst("DEVFLD", "developerFolderType", sharedState, dispatch);
				let inProcessType = await getDbConst("IPFLD", "inProcessFolderType", sharedState, dispatch);
				let reviewType = await getDbConst("RVFLD", "reviewFolderType", sharedState, dispatch);
				let sentForReviewType = await getDbConst("SNTREVFLD", "sentForReviewFolderType", sharedState, dispatch);

				let objectStatuses = await getStatusUpdatesForDataWarehouse(destinationUuid);
				let statusMap = new Map();
				if (objectStatuses && objectStatuses.length) {
					objectStatuses.forEach((status) => {
						//Build a map that maps an object to each of its status updates
						if (!statusMap.has(status.objectUuid)) statusMap.set(status.objectUuid, []);

						statusMap.get(status.objectUuid).push(status);
					});

					destinationModelMfi = [...destinationModelMfi];
					[...statusMap.keys()].forEach((key) => {
						let statusUpdates = statusMap.get(key);
						statusUpdates.sort((a, b) => a.createdAt - b.createdAt);
						//Get the last status update and if it is sent for review than move into the correctly location in destinationModel
						let latest = statusUpdates[statusUpdates.length - 1];
						if (latest.typeUuid === sentForReviewType) {
							let obj = destinationModelMfi.find(
								(row) =>
									row.standardObjectUuid === latest.objectUuid &&
									row.standardObjectVersionUuid === latest.objectVersionUuid
							);
							if (!obj)
								obj = destinationModelMfi.find((row) => row.standardObjectUuid === latest.objectUuid);
							if (!obj) return;

							//Find the in process folder the object is in
							let userFolder = destinationModelMfi.find(
								(row) =>
									row.mfiGeneralTypeUuid === developerFolderTypeUuid &&
									obj.reference.startsWith(row.reference)
							);
							let sentForReviewCopy = { ...obj };

							let inProcessFolder, sentForReviewFolder;
							if (!userFolder) {
								inProcessFolder = destinationModelMfi.find(
									(row) =>
										obj.reference.startsWith(row.reference) &&
										row.mfiGeneralTypeUuid === inProcessType
								);
								if (!userFolder) return;
								userFolder = destinationModelMfi.find((row) => row.uuid === inProcessFolder.parentUuid);
								sentForReviewFolder = destinationModelMfi.find(
									(row) =>
										row.parentUuid === inProcessFolder.parentUuid &&
										row.mfiGeneralTypeUuid === sentForReviewType
								);
							} else {
								inProcessFolder = destinationModelMfi.find(
									(row) =>
										row.reference.startsWith(userFolder.reference) &&
										row.mfiGeneralTypeUuid === inProcessType
								);
								sentForReviewFolder = destinationModelMfi.find(
									(row) =>
										row.reference.startsWith(userFolder.reference) &&
										row.mfiGeneralTypeUuid === sentForReviewType
								);
							}

							//Remove it from the in process folder, do I need to re-reference? Comment out for now, it's too confusing having the references mismatch.
							//TODO: Is it better to have the references mismatch or look like things are missing because references are "skipped"
							// deleteRow(sentForReviewCopy, destinationModelMfi);
							destinationModelMfi = destinationModelMfi.filter(
								(row) => row.uuid !== sentForReviewCopy.uuid
							);

							//Add it to the Sent for Review folder

							let siblings = destinationModelMfi.filter(
								(row) => row.parentUuid === sentForReviewFolder.uuid
							);

							//Update uuid, reference, and parentUuid
							sentForReviewCopy.originalParent = sentForReviewCopy.parentUuid;
							sentForReviewCopy.parentUuid = sentForReviewFolder.uuid;
							// sentForReviewCopy.uuid = uuidv4();

							let newRef, insertAfter;
							if (siblings.length > 0) {
								newRef = getNextRef(siblings[siblings.length - 1].reference);
								insertAfter = siblings[siblings.length - 1];
							} else {
								newRef = getNextRef(sentForReviewFolder.reference + ".00");
								insertAfter = {
									parentUuid: sentForReviewFolder.uuid,
									uuid: sentForReviewFolder.uuid,
									referenceNo: 0,
								};
							}

							sentForReviewCopy.reference = newRef.reference;
							sentForReviewCopy.referenceNo = newRef.referenceNo;
							insertRowAndReRef(insertAfter, destinationModelMfi, sentForReviewCopy);

							//Add it to the correct users review folder
							let sentToUserFolder = destinationModelMfi.find(
								(row) => row.location === latest.userUuid && row.parentUuid === userFolder.parentUuid
							);

							//Add to review folder
							let userReviewFolder = destinationModelMfi.find(
								(row) =>
									row.reference.startsWith(sentToUserFolder.reference) &&
									row.mfiGeneralTypeUuid === reviewType
							);
							siblings = destinationModelMfi.filter((row) => row.parentUuid === userReviewFolder.uuid);

							let reviewCopy = { ...obj };
							//Update uuid, reference, and parentUuid
							reviewCopy.parentUuid = userReviewFolder.uuid;
							reviewCopy.uuid = uuidv4();

							if (siblings.length > 0) {
								newRef = getNextRef(siblings[siblings.length - 1].reference);
								insertAfter = siblings[siblings.length - 1];
							} else {
								newRef = getNextRef(userReviewFolder.reference + ".00");
								insertAfter = {
									uuid: userReviewFolder.uuid,
									parentUuid: userReviewFolder.uuid,
									referenceNo: 0,
								};
							}

							reviewCopy.reference = newRef.reference;
							reviewCopy.referenceNo = newRef.referenceNo;
							insertRowAndReRef(insertAfter, destinationModelMfi, reviewCopy);
						}
					});
				}

				destinationModelMfi.sort(sortByReference);
				dispatch({
					type: "UPDATE_DESTINATION_MODEL",
					data: { mfi: [...destinationModelMfi], uuid: destinationUuid },
				});
			}
		}
	};

	/**
	 * Takes in an object to either add or remove from the breadcrumbs
	 * @param mfi
	 */
	const updateBreadCrumbs = (obj, add) => {
		if (add) {
			addBreadCrumb({
				uuid: uuidv4(),
				standardObjectUuid: obj.standardObjectUuid,
				standardObjectVersionUuid: obj.standardObjectVersionUuid,
				title: obj.title,
			});
			let { contextMfi: mfi } = sharedState;
			let matchingObject = mfi.find((row) => row.uuid === obj.standardObjectUuid);
			mfi = mfi.filter((row) => row.reference.startsWith(matchingObject.reference));
			dispatch({
				type: "SET_CONTEXT_OR_MFI",
				data: {
					top: mfi[0],
					mfi,
				},
			});
		} else {
			let index = breadCrumbs.indexOf((row) => row.uuid === obj.uuid);
			removeBreadCrumb(index);
		}
	};

	/**
	 * Updates the task standard object record when it is changed on the checklist
	 * @param uuid
	 * @param value
	 * @param componentTitle
	 */
	const updateTask = (row, newValue, task) => {
		if (row && row.value !== newValue) {
			row.value = newValue;

			//Fix the references and the hierarchy records to start with the stuff from the very top
			let subObjectToUpdate = {
				...task.row,
				reference: checklistRef.current.reference + "." + task.row.reference,
			};
			row.reference = checklistRef.current.reference + "." + row.reference;

			let hierarchyRecord = task.objectHierarchy[0];
			hierarchyRecord.pathEnum = getObjectIdAndVersionUuid(packetRow.current) + "." + hierarchyRecord.pathEnum;

			updateChangeData(
				dispatch,
				{ objectRows: [row], objectToUpdate: packetRow.current, subObjectToUpdate, primusRows: false },
				true
			);
		}
	};

	// Render the objects then print them
	const print = (printAll) => {
		if (printAll) {
			printTemplates.current = "All";
		} else {
			printTemplates.current = true;
		}
		dispatch({ type: "TRIGGER_GENERATE", data: "template" });
	};

	/**
	 * Close the object setup form
	 */
	const objSetupFormHandleClose = () => {
		setObjSetupFormOpen(false);
		newFromExisting.current = false;
		newObjTransaction.current = {};
		setObjSetupFormNewVersion(false);
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	};

	const openDefaultView = () => {
		dispatch({ type: "OPEN_VIEW", data: "DEFAULT" });
	};

	/**
	 * Update local storage with either a contextId, or changes not committed
	 * @param contextId
	 * @param lastObj
	 */
	const saveLocalStorage = ({ contextId, contextVersionId, lastObj, path, reference }) => {
		//Change this to save to indexedDB rather than localStorage

		if (contextId) {
			addToHistory({ objectId: contextId, objectVersionId: contextVersionId, path, reference });
			let { uuid, versionUuid, debug: isDebug, path: _path } = getUrlParams(queryParams);

			if (uuid !== contextId || versionUuid !== contextVersionId || _path !== path) {
				let update = {
					uuid: contextId,
					versionUuid: contextVersionId,
				};
				if (path && reference) {
					update.path = path;
					update.ref = reference;
				}

				if (isDebug) update.debug = true;

				setQueryParams(update);
			}

			// navigate(`?uuid=${contextId}&versionUuid=${contextVersionId}`);

			clearLastOpenedObject();
			// delete localStorage.lastObjectInfo;
			// localStorage.contextId = contextId;
		} else if (lastObj) {
			//Update the lastOpenedObject
			updateLastOpenedObject({ id: 1, ...JSON.parse(JSON.stringify(lastObj)) });
			// localStorage.lastObjectInfo = JSON.stringify(lastObj);
		}
	};

	/**
	 * Submit the new object, this could either be a copy of an object or new blank object
	 * @param values
	 */
	const submitNewTopLevelObject = async (values) => {
		// setCommittedOrReset(true);
		setObjSetupFormNewVersion(false);

		//Check if the object is a new blank object, or a copy of an existing
		//If creating a copy, update the context's title and description
		if (newFromExisting.current || objSetupFormNewVersion) {
			let newRow;
			if (newObjTransaction.current.templateRow.uuid)
				newRow = { ...newObjTransaction.current.context, ...values };
			else newRow = { ...sharedState.contextTop, ...values };

			newRow.parentUuid = ZERO_ROW_UUID;

			newObjTransaction.current.context = newRow;

			newObjTransaction.current.changedRows.push(newRow);
			delete newRow.objectHierarchy;

			//The top node didn't used to be in the tree, we now put it in. Now that we have a new title add the top node to the mfi
			newObjTransaction.current.mfi.push(newRow);

			//Also add the current object's template row if it's not there and there is a current templateRow
			// let topTemplate = newObjTransaction.current.templateMfi.find(row => row.uuid === newObjTransaction.current.templateRow.uuid);
			// if (!topTemplate && newObjTransaction.current.templateRow && newObjTransaction.current.templateRow.uuid) {
			//     newObjTransaction.current.templateMfi.push(newObjTransaction.current.templateRow);
			// }

			//Update state to close the dialogs
			newFromExisting.current = false;
			setObjSetupFormOpen(false);
			// setCommittedOrReset(false);

			//If we are creating a destination model type object we want to place the new object at a specific spot on the MFI, we don't want the user to choose one.
			//I think we can assume if the destination model is not set, we are creating a new destination model
			if (sharedState.destinationModel.length < 1) {
				//Create the master file index row.
				let mfiRow = copyStandardObject(newRow);
				let newRef = getNextDestinationModelReference();

				//Update MFI Row, verify it points to the correct standard object
				mfiRow.reference = newRef.reference;
				mfiRow.referenceNo = newRef.referenceNo;
				mfiRow.standardObjectUuid = newRow.uuid;
				mfiRow.standardObjectVersionUuid = newRow.versionUuid;
				mfiRow.parentUuid = sharedState.destinationModels[0].parentUuid;

				newObjTransaction.current.uploadObject = mfiRow;
				newObjTransaction.current.context.mfiReference = mfiRow.reference;

				let { changedMfiRows: modelMfi } = await createModelMfiCopy(
					mfiRow,
					newRow.standardObjectUuid,
					newRow.standardObjectVersionUuid,
					sharedState,
					dispatch
				);

				let destinationModelMfi = [mfiRow, ...modelMfi];
				newObjTransaction.current.changedMfiRows = destinationModelMfi;

				dispatch({ type: "UPDATE_DESTINATION_MODEL", data: { mfi: destinationModelMfi, uuid: mfiRow.uuid } });
				localStorage.destinationModel = mfiRow.uuid;
				updateStateWithTransaction();

				newFromExisting.current = false;
				return;
			}

			setUploadToDataWarehouse(true);
			return;
		} else {
			//How do I reset the source object panel?
			// setTemplateMfi([]);
			// setTemplateRow({});
		}

		//Otherwise create a new row like we were doing before
		let obj = createNewFromBlank(values);
		async function makeCall() {
			obj = await postCall(getUrl("submitNewObject"), obj);
			let newMfi = [];

			//Rather than setting these, set the new transaction object, in case the user clicks and cancels and decides not to continue creating the object
			newObjTransaction.current.mfi = newMfi;
			newObjTransaction.current.context = obj;
			newObjTransaction.current.changedRows = [];

			setObjSetupFormOpen(false);
			// setCommittedOrReset(false);

			//After that's done, pull up another dialog and ask the user where they want to put the object in the warehouse
			setUploadToDataWarehouse(true);

			//Add to local storage
			saveLocalStorage({ contextId: obj.uuid, contextVersionId: obj.versionUuid });
			localStorage.contextId = obj.uuid;
			localStorage.contextVersionId = obj.versionUuid;
		}
		if (!values.uuid) makeCall();
		else createNewFromExisting(values);

		newFromExisting.current = false;
	};

	const getNextDestinationModelReference = () => {
		let destinationModels = sharedState.destinationModels;
		let lastDestinationModel = destinationModels.slice(-1)[0];

		return getNextRef(lastDestinationModel.reference);
	};

	const objReadyToUpload = (obj) => {
		//Check if there an object in the newObjectTransaction, if so, use it and set all the stuff
		if (newObjTransaction.current.uuid) {
			//Set the context, the mfi, the templateRow and template mfi
		}
		if (!newObjTransaction.current.uploadObject) newObjTransaction.current.uploadObject = [];
		newObjTransaction.current.uploadObject.push(obj);

		//Add the row dragged over to the list of objects we are harvesting
		if (!newObjTransaction.current.objects) newObjTransaction.current.objects = [];
		if (Array.isArray(newObjTransaction.current.context))
			newObjTransaction.current.objects.push(
				newObjTransaction.current.context.find((row) => row.uuid === obj.standardObjectUuid)
			);

		newObjTransaction.current.context.mfiReference = obj.reference;
	};

	const updateStateWithTransaction = async (event) => {
		//Close the Data Warehouse Dialog
		setUploadToDataWarehouse(false);
		setObjSetupFormNewVersion(false);

		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

		// Grab all rows from the object that have the File type. All rows with this type contain only a reference to the actual file
		let wopi_rows = newObjTransaction.current.mfi.filter(
			(row) => row.objectTypeUuid === sharedState.dbConstants.fileObject.referenceUuid && row.value
		);
		if (wopi_rows.length > 0) {
			let promises = [];
			// Because the value is a reference to a file on the File Service, we want to duplicate the file on the service side when copying an object
			// So we call duplicateFile, giving it the id of the file that needs duplicated. A new id is returned which references a copy of the original file
			wopi_rows.forEach((row, i) => {
				promises.push(duplicateFile(row.value));
			});
			// Wait for the service to respond that all files have been duplicated, then update each row with the new id
			await Promise.all(promises).then((res) => {
				res.forEach((r, i) => {
					updateRowsThatReferenceThisRow(wopi_rows[i].uuid, r, newObjTransaction.current.mfi, []);
					wopi_rows[i].value = `${wopiHost()}/wopi/files/${r}`;
				});
			});
		}

		//Update contextRef
		// await dispatch({
		//     type: 'SET_CONTEXT_OR_MFI',
		//     data: {
		//         top: newObjTransaction.current.context,
		//         mfi: [newObjTransaction.current.context, ...newObjTransaction.current.mfi],
		//         templateRow: newObjTransaction.current.templateRow,
		//         templateMfi: newObjTransaction.current.templateMfi,
		//         versions: [],
		//         resetSubObjectInfo: true,
		//     }
		// });

		// getObjectDefaults(newObjTransaction.current.context.uuid, newObjTransaction.current.mfi);

		if (newObjTransaction.current.newStockNumbers?.length > 0)
			dispatch({ type: "UPDATE_CHANGED_STOCK_NUMBERS", data: newObjTransaction.current.newStockNumbers });

		let view = sharedState.openView.title;
		//This happens when filtering
		//This used to be harvesting, but is now filtering. Need to change the variables to be titled filtering instead of harvesting
		if (harvesting.current) {
			//Separate harvesting and filtering. Whats the difference?
			let { uploadObject, changedRows, objects, changedMfiRows } = newObjTransaction.current;
			//Save the harvested objects
			//The payload gets too big when including everything so just send back the uuid and version
			let updates = await filterObjects(
				changedRows.map((obj) =>
					obj.map((row) => ({ uuid: row.uuid, versionUuid: row.versionUuid, title: row.title }))
				)
			);

			//Save the changedMFI rows
			let changeMap = buildNewChangeMap(sharedState.openView.title);
			changedMfiRows.forEach(
				(row) => (changeMap[view][defaultMasterFileIndexChangeTitle].mfi[getObjectIdAndVersionUuid(row)] = row)
			);

			if (newObjTransaction.current.newStockNumbers?.length > 0)
				changeMap.stockNumbers = newObjTransaction.current.newStockNumbers;

			await saveObject(event, changeMap, updates);
		} else {
			//If newObjTransaction.current.context is an array we are harvesting
			if (Array.isArray(newObjTransaction.current.context)) {
				let { changedMfiRows, context } = newObjTransaction.current;
				//For each row in context that we have also created a matching mfi row, add it to changed rows so we can save it.
				let uuids = changedMfiRows.map((row) => row.standardObjectUuid + row.standardObjectVersionUuid);

				//Build the change map
				let changeMap = buildNewChangeMap(view);

				for (const row1 of context.filter((row) => uuids.includes(row.uuid + row.versionUuid))) {
					delete row1.objectHierarchy;

					//Build the change for this row, I will assume the only change per object is the top row which should tell it to create a new object

					changeMap[view][defaultStandardObjectGitChangeTitle][getObjectIdAndVersionUuid(row1)] = {
						top: row1,
						objectHierarchy: {},
						objects: {},
					};

					let map =
						changeMap[view][defaultStandardObjectGitChangeTitle][getObjectIdAndVersionUuid(row1)].objects;
					addRowToChangedObjectMap(map, row1, row1);
				}

				//Now add the changed master file index rows
				changedMfiRows.forEach(
					(row) => (changeMap[view][defaultMasterFileIndexChangeTitle].mfi[row.uuid] = row)
				);

				if (newObjTransaction.current.newStockNumbers?.length > 0)
					changeMap.stockNumbers = newObjTransaction.current.newStockNumbers;

				await saveObject(event, changeMap);
			} else {
				delete newObjTransaction.current.context.objectHierarchy;
				let changedMap = convertListToObjectMap(
					newObjTransaction.current.changedRows,
					newObjTransaction.current.context
				);

				await dispatch({
					type: "UPDATE_CHANGED_ROWS",
					data: {
						objectToUpdate: newObjTransaction.current.context,
						changedObjectRows: newObjTransaction.current.changedRows,
						changedMfiRows: newObjTransaction.current.changedMfiRows,
					},
				});
				openAfterSave.current = true;

				newObjTransaction.current.changedRows = changedMap;
				await saveObject(event, convertNewObjTransactionToChangeMap());
			}
		}

		harvesting.current = false;
		newObjTransaction.current = {};
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	};

	const convertNewObjTransactionToChangeMap = (changeMap) => {
		let view = sharedState.openView.title;
		let top = newObjTransaction.current.context;
		let uuidAndVersion = getObjectIdAndVersionUuid(top);
		let map;
		if (changeMap) map = changeMap;
		else
			map = {
				[sharedState.openView.title]: {
					[defaultStandardObjectGitChangeTitle]: {},
					[defaultMasterFileIndexChangeTitle]: {
						mfi: {},
						deleted: [],
					},
				},
			};

		if (uuidAndVersion)
			map[sharedState.openView.title][defaultStandardObjectGitChangeTitle][uuidAndVersion] = {
				objectHierarchy: {},
				top,
				objects: {},
			};

		if (newObjTransaction.current.changedRows) {
			Object.keys(newObjTransaction.current.changedRows).forEach((key) => {
				map[sharedState.openView.title][defaultStandardObjectGitChangeTitle][uuidAndVersion].objects[key] = {
					mfi: newObjTransaction.current.changedRows[key],
					deleted: {},
				};
			});
		}

		if (newObjTransaction.current.objectHierarchy)
			map[sharedState.openView.title][defaultStandardObjectGitChangeTitle][uuidAndVersion].objectHierarchy =
				newObjTransaction.current.objectHierarchy;

		if (map[view][defaultMasterFileIndexChangeTitle]) {
			let mfiChanges = map[view][defaultMasterFileIndexChangeTitle].mfi;
			newObjTransaction.current.changedMfiRows.forEach((row) => (mfiChanges[row.uuid] = row));
		}

		return map;
	};

	/**
	 * TODO: Consolidate this with the copy object in Tree.js hanldeDrop they do the same ting
	 * Create new objects for the selected object and each of the descendants in it's object master file index
	 */
	const createNewFromExisting = async (uuid, object, diffUser = false) => {
		//TODO: I think this causes an issue? Comment out for now
		// let { thereAreChanges, discard } = promptForUnsavedChanges();
		//
		// if(!discard && thereAreChanges)
		//     return;

		//Get the object mfi
		let rows;
		//If uuid was passed in, get the corresponding row
		if (diffUser) {
			object = sharedState.contextTop;
		} else if (uuid) {
			//Get the object and it's rows
			//TODO: I don't think we need to do this anymore because the backend only needs the top row to create a copy of the whole MFI? Verify and remove it
			// rows = await getObjectMfi(uuid, object.versionUuid);
			//The top object is the first row in the object
			// object = rows[0];
			//Remove the first item in the array as it is taken care of through the context
			// rows.splice(0, 1);
		}

		let objectType = sharedState.dbConstants.objectType;
		if (!objectType) objectType = await getDbConst("OBJTP", "objectType", sharedState, dispatch);

		//Copy the object
		let rowCopy;

		//Create the copy
		rowCopy = await createNewObject({ object, userUuid: sharedState.currentUser?.uuid }, diffUser);

		if (rowCopy.stockNumber && rowCopy.stockNumber.uuid) {
			dispatch({ type: "UPDATE_CHANGED_STOCK_NUMBERS", data: rowCopy.stockNumber });
		}

		//TODO: Should this point to the standard object uuid instead of the uuid?
		//update row copy
		//If it is a different user modifying the object, this is copying instead of deriving from
		//So we don't need to update the copy's standard object uuid
		//If a different user isn't modifying the object we want the new object to point to the object we are copying
		//TODO I need to update so when it creates a git fork it doesn't set the standard_object_uuid of the copy

		//TODO In order for this to work we need to do this even if we are a diff user
		// We will copy the changes from the original object
		// and assign them to the new object
		if (!diffUser) {
			rowCopy.standardObjectUuid = object.uuid;
			rowCopy.standardObjectVersionUuid = object.versionUuid;
			rowCopy.referenceObjectTitle = object.title;
		}

		// newMfi = copyObjectMfi(rows, object.uuid, rowCopy.uuid, rowCopy.reference, sharedState.currentUser?.uuid);

		//TODO: I don't think we need to do this anymore, ask Nate
		//Now that we have created a copy of the row and all of its object descendants, set the template object and mfi, and the context row and mfi
		//If it's a different user modifying the object the current template stuff is still correct
		if (!diffUser) {
			newObjTransaction.current.templateRow = object;
			// newObjTransaction.current.templateMfi = rows;
		} else {
			newObjTransaction.current.templateRow = sharedState.contextTemplateRow;
			newObjTransaction.current.templateMfi = sharedState.contextTemplateMfi;
			newObjTransaction.current.gitFork = rowCopy.gitFork;
			delete rowCopy.gitFork;
		}

		//set the context and new mfi
		newObjTransaction.current.context = rowCopy;
		newObjTransaction.current.mfi = [];
		// newObjTransaction.current.mfi = newMfi;

		//Add the new setup rows
		newObjTransaction.current.changedSetupRows = [];

		//Add to list of changed rows to be saved when save button is clicked
		newObjTransaction.current.changedRows = [];
		// addChangedRows( [...newMfi, rowCopy] );

		if (diffUser) {
			setObjSetupFormNewVersion(true);
		}
		setObjSetupFormOpen(true);
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	};

	/**
	 * Save the object information:
	 *      Any rows that need updated or created (object MFI)
	 *      Stock # info that need updated or created
	 *          Stock #s, Volumes, ObjectClasses, LanguageFrameworks
	 */
	const saveObject = async (event, objectsToSave, updates) => {
		let viewTitle = sharedState.openView.title;

		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

		//This returns true when the default save is required and false when the view's save is adequate
		let continueDefaultSave = await sharedState.openView.save(
			sharedState.changedData,
			viewTitle,
			sharedState.currentUser
		);

		//logic on data to pass to save
		if (!continueDefaultSave) {
			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			openDefaultView();
			return;
		}

		let standardObjectTitle = dataObjects.STANDARD_OBJECT_GIT.title;
		let masterFileIndexTitle = dataObjects.MASTER_FILE_INDEX_ROW.title;

		//Save the object and mfi rows
		async function postObjectAndMfiRows(objectsToSave, stockNumbers) {
			let url = getUrl("saveObjectRows");

			//Convert open points to json object format
			let updatedOpenPoints = [];
			openPointChangeData.current.forEach((row) => {
				let { uuid, sourceObjectUuid, sourceObjectVersionUuid, openPointSheetUuid } = row;
				let updated = {
					uuid,
					sourceObjectUuid,
					sourceObjectVersionUuid,
					openPointSheetUuid,
				};
				delete row.uuid;
				delete row.sourceObjectUuid;
				delete row.sourceObjectVersionUuid;
				delete row.openPointSheetUuid;
				updated.jsonData = row;
				updatedOpenPoints.push(updated);
			});

			let mfiUpdate = {
				standardObjectUpdates: [],
				stockNumbersToUpdate: stockNumberChangeData.current.changedStockNumbers,
				volumesToUpdate: stockNumberChangeData.current.changedVolumes,
				objectClassesToUpdate: stockNumberChangeData.current.changedObjectClasses,
				languageFrameworksToUpdate: stockNumberChangeData.current.changedLanguageFrameworks,
				associatedObjectsBeingUpdated: sharedState.changedData.associatedHierarchyRecords,
				openPointsToUpdate: updatedOpenPoints,
			};

			if (stockNumbers) {
				mfiUpdate.stockNumbersToUpdate = stockNumbers;
			}

			if (objectsToSave) {
				Object.keys(objectsToSave).forEach((key) => {
					let objects = objectsToSave[key].objects;
					let objectHierarchy = objectsToSave[key].objectHierarchy;

					let update = {
						standardObject: objectsToSave[key].top,
						subObjectChanges: {},
						objectHierarchy: [],
						//TODO: These dont really do anything
						// replace: overwriteObject,
						// gitFork: objectsToSave?.current?.gitFork,
					};

					if (objectHierarchy) {
						Object.values(objectHierarchy).forEach((row) => update.objectHierarchy.push(row));
					}

					//If the createdBy is null/undefined for the context set it to the user that is currently saving
					//If the createdBy is different than the current user, they need to choose a location in the data warehouse
					//To store the version of the object
					if (!objectsToSave[key].top.createdByUuid)
						objectsToSave[key].top.createdByUuid = sharedState.currentUser?.uuid;

					Object.keys(objects).forEach((uuidAndVersion) => {
						update.subObjectChanges[uuidAndVersion] = {
							objectRowsToUpdate: Object.values(objects[uuidAndVersion].mfi),
							objectRowsToDelete: Object.values(objects[uuidAndVersion].deleted),
						};
					});
					mfiUpdate.standardObjectUpdates.push(update);
				});
			}

			let filters = Object.keys(sharedState.changedFilters);
			if (filters.length > 0) mfiUpdate.filtersToUpdate = Object.values(sharedState.changedFilters);

			setOverwriteObject(false);

			//Save the standardObject updates
			let response = await postCall(url, mfiUpdate);

			//TODO Once multiple objects are open this will be replaced with the top of each openObject and will be moved down into the forEach
			let top = topObjectRow.current;
			let {
				contextPacket: packet,
				contextDestinationObject: destinationObject,
				contextCurrentRelatedObject: currentRelatedObject,
			} = sharedState;
			let mfi = mfiRef.current;
			let attachmentPoints = await getAttachmentPoints();
			let ancestorObjects = ancestorObjectsRef.current;
			let newUpdatedHierarchy = [];
			let newUpdatedHierarchyUuids = [];
			let fullObjectHierarchyUpdates = [];
			let { associatedObjectsBeingUpdated } = response;
			let subObjectUpdates = [];

			//TODO We also need to update the Data Warehouse rows with the new versions
			if (!harvesting.current) {
				response.standardObjectUpdates.forEach((update) => {
					//What new stuff can we trust from the response.
					//     StandardObject's VersionControlLog uuid for the latest version
					//     StandardObject's ObjectHierarchy is the updated hierarchy
					//     The subObjectChange's objectRowsToUpdate where the row has the same uuid as the old key uuid
					//     let updatedSubObject = value.objectRowsToUpdate?.find(row => row.uuid === oldUuidAndVersion.uuid);
					let topUuid = update.standardObject?.uuid;
					let newTopVersion = update.standardObject?.logRecord.uuid;
					let updatedObjectHierarchy = update.standardObject.objectHierarchy;

					let directDescendants = updatedObjectHierarchy?.filter(
						(row) => row.ancestorStandardObjectUuid === top.uuid
					);

					//Old stuff we can use from the response
					//     The key from each of the subObjectChanges

					//What object hierarchy records need updated

					let updatedTop = false;
					//Loop over subChanges and do what?
					Object.entries(update.subObjectChanges).forEach(([key, value]) => {
						let oldUuidAndVersion = splitObjectIdAndVersion(key);

						//value contains the new row for the sub-object
						let updatedSubObject = value.objectRowsToUpdate?.find(
							(row) => row.uuid === oldUuidAndVersion.uuid
						);

						if (!updatedSubObject) return;
						if (updatedObjectHierarchy && !newUpdatedHierarchyUuids.includes(updatedSubObject.uuid)) {
							let updatedHierarchyRecord = updatedObjectHierarchy.find(
								(row) => row.descendantStandardObjectUuid === updatedSubObject.uuid
							);

							if (updatedHierarchyRecord) newUpdatedHierarchy.push(updatedHierarchyRecord);
							newUpdatedHierarchyUuids.push(updatedSubObject.uuid);
							subObjectUpdates.push(updatedSubObject);
						}

						//Does this update apply to the top? Also what is the top?
						if (updatedSubObject.uuid === top.uuid) {
							updatedTop = true;
							updatedSubObject.reference = top.reference;
							updatedSubObject.referenceNo = top.referenceNo;
							updatedSubObject.objectTags = top.objectTags;
							updatedSubObject.isObject = top.isObject;
							if (!updatedSubObject.parentUuid) updatedSubObject.parentUuid = top.parentUuid;

							//Retrieve the versions from the updatedHierarchyMap
							let uuidToVersionMap = getHierarchyDescendantUuidToVersionMap(directDescendants);
							//Find any of the old rows in the master file index and update them to the latest version
							//This matches up the front-end versions with the backend versions
							mfi.forEach((row) => {
								if (uuidToVersionMap.has(row.uuid)) {
									row.versionUuid = uuidToVersionMap.get(row.uuid);
								}
							});

							if (top.associatedObject) {
								updatedSubObject.associatedObject = true;
								let newHierarchyRecord = associatedObjectsBeingUpdated.find(
									(row) => row.descendantStandardObjectUuid === top.uuid
								);
								Object.keys(newHierarchyRecord).forEach((key) => {
									updatedSubObject["associatedHierarchyAttribute" + key] = newHierarchyRecord[key];
								});
								top.objectHierarchy = [newHierarchyRecord];
							}
							top = updatedSubObject;
							topObjectRow.current = updatedSubObject;

							if (top.objectHierarchy)
								top.objectHierarchy = [
									...top.objectHierarchy,
									...updatedObjectHierarchy.filter(
										(row) => row.ancestorStandardObjectUuid === top.uuid
									),
								];
							else
								top.objectHierarchy = updatedObjectHierarchy.filter(
									(row) =>
										row.ancestorStandardObjectUuid === top.uuid ||
										row.descendantStandardObjectUuid === top.uuid
								);

							//We need to update the attachmentPoints with the new latest version if there is one
							let oldAttachmentPoint = attachmentPoints.find((row) => row.uuid === updatedSubObject.uuid);
							if (oldAttachmentPoint)
								updateAttachmentPoint({
									...oldAttachmentPoint,
									...trimObject(updatedSubObject),
									reference: oldAttachmentPoint.reference,
								});
						}
						//Does it apply to an ancestorObject?
						else if (ancestorObjects.find((anc) => anc.uuid === updatedSubObject.uuid)) {
							let updateIndex = ancestorObjects.findIndex(
								(row) => row.uuid === oldUuidAndVersion.uuid // && row.versionUuid === oldUuidAndVersion.versionUuid
							);
							let ancestorObject = ancestorObjects[updateIndex];

							fullObjectHierarchyUpdates = [...fullObjectHierarchyUpdates, ...updatedObjectHierarchy];
							//Update the corresponding breadcrumb and attachmentPoint
							//Some of the updatedSubObjects are coming back without a reference, before replacing the ancestor object grab its reference
							updatedSubObject.reference = ancestorObject.reference;
							updatedSubObject.referenceNo = ancestorObject.referenceNo;
							updatedSubObject.objectTags = ancestorObject.objectTags;
							updatedSubObject.isObject = ancestorObject.isObject;
							if (!updatedSubObject.parentUuid) updatedSubObject.parentUuid = ancestorObject.parentUuid;

							if (ancestorObject.associatedObject) {
								updatedSubObject.associatedObject = true;
								let newHierarchyRecord = associatedObjectsBeingUpdated.find(
									(row) => row.descendantStandardObjectUuid === top.uuid
								);
								Object.keys(newHierarchyRecord).forEach((key) => {
									updatedSubObject["associatedHierarchyAttribute" + key] = newHierarchyRecord[key];
								});
							}

							ancestorObjects.splice(updateIndex, 1, updatedSubObject);

							//Does it apply to the open packet?
							if (packet?.uuid === updatedSubObject.uuid) {
								packet = updatedSubObject;
								packetRow.current = packet;
								toc[0].objectHierarchy = updatedObjectHierarchy;
							}

							//If this is the top object we don't need to worry about updating the attachment point for it because there shouldn't be one
							if (updatedSubObject.uuid !== ancestorObjects[0].uuid) {
								//We need to update the attachmentPoints with the new latest version
								let oldAttachmentPoint = attachmentPoints.find(
									(row) => row.uuid === updatedSubObject.uuid
								);
								if (!oldAttachmentPoint)
									addAttachmentPoint({
										objectId: updatedSubObject.uuid,
										...trimObject(updatedSubObject),
									});
								else {
									updateAttachmentPoint({
										...oldAttachmentPoint,
										...trimObject(updatedSubObject),
										reference: oldAttachmentPoint.reference,
									});
								}
							}
						} else if (destinationObject?.uuid === updatedSubObject.uuid) {
							updatedSubObject.reference = destinationObject.reference;
							updatedSubObject.referenceNo = destinationObject.referenceNo;
							updatedSubObject.objectTags = destinationObject.objectTags;
							updatedSubObject.isObject = destinationObject.isObject;
							destinationObject = updatedSubObject;
						} else if (updatedSubObject.uuid === packet?.uuid) {
							packet = updatedSubObject;
							packetRow.current = packet;
						}

						if (packet?.uuid === updatedSubObject.uuid) {
							packet = updatedSubObject;
							packetRow.current = packet;
							toc[0].objectHierarchy = updatedObjectHierarchy;
						}

						//Does it apply to the current related object
						if (currentRelatedObject?.uuid === topUuid) {
							updatedSubObject.reference = currentRelatedObject.reference;
							updatedSubObject.referenceNo = currentRelatedObject.referenceNo;
							updatedSubObject.objectTags = currentRelatedObject.objectTags;
							updatedSubObject.isObject = currentRelatedObject.isObject;
							currentRelatedObject = updatedSubObject;
						}

						//Is the object on the TOC?
						let tocMatch = toc.find((row) => row.uuid === updatedSubObject.uuid);
						if (tocMatch) {
							tocMatch.versionUuid = updatedSubObject.versionUuid;
						}

						value.objectRowsToUpdate
							.filter((row) => row.versionUuid)
							.forEach((row) => {
								let hierarchyRecord = updatedObjectHierarchy.find(
									(hr) => hr.descendantStandardObjectUuid === row.uuid
								);
								if (hierarchyRecord && !newUpdatedHierarchyUuids.includes(row.uuid)) {
									newUpdatedHierarchy.push(hierarchyRecord);
									newUpdatedHierarchyUuids.push(row.uuid);
									subObjectUpdates.push(row);
								} else if (!newUpdatedHierarchyUuids.includes(row.uuid)) subObjectUpdates.push(row);
							});
					});
				});

				//TODO: After a save remove the new attribute from any objects and/or sub-objects that have it. This should probably check the subObjectUpdates along with the contextTop and contextMfi. If we're in a new object we need to re-open it to get the real UUIDs?

				dispatch({
					type: "SET_SUB_OBJECT_UPDATES",
					data: { subObjectUpdates, hierarchyUpdates: newUpdatedHierarchy },
				});

				//If there were ancestor objects add the very top to the history so it will load the latest one on refresh
				if (ancestorObjects?.length > 0) {
					//Get the reference and the path to update the location query parameters
					let { reference } = getUrlParams(queryParams);
					let path = ancestorObjects.map((anc) => getObjectIdAndVersionUuid(anc)).join(".");
					path += "." + getObjectIdAndVersionUuid(topObjectRow.current);
					saveLocalStorage({
						contextId: topObjectRow.current.uuid,
						contextVersionId: topObjectRow.current.versionUuid,
						path,
						reference: topObjectRow.current.reference,
					});
				} else if (packet?.uuid && packet?.versionUuid) {
					//If there's a packet and top, build the new path
					if (top.uuid && top.versionUuid)
						saveLocalStorage({
							contextId: top.uuid,
							contextVersionId: top.versionUuid,
							path: getObjectIdAndVersionUuid(packet) + "." + getObjectIdAndVersionUuid(top),
							reference: top.reference,
						});
					else saveLocalStorage({ contextId: packet.uuid, contextVersionId: packet.versionUuid });
				} else {
					if (top.uuid && top.versionUuid)
						saveLocalStorage({ contextId: top.uuid, contextVersionId: top.versionUuid });
					//TODO: Not sure why we check the contextPacket if there is no top
					else if (packet?.uuid && packet?.versionUuid)
						saveLocalStorage({
							contextId: packet.uuid,
							contextVersionId: packet.versionUuid,
						});
				}

				let update = {
					updateFullObjectHierarchyVersions: fullObjectHierarchyUpdates,
				};
				if (top.uuid) update.top = top;
				if (ancestorObjects?.length > 0) update.ancestorObjects = ancestorObjects;
				if (destinationObject?.uuid) update.destinationObject = destinationObject;
				if (currentRelatedObject?.uuid) update.currentRelatedObject = currentRelatedObject;
				if (packet?.uuid) update.packet = packet;
				if (top && mfi?.length > 0) {
					//Remove deleted, added and changes from mfi
					let updatedMfi = [top, ...mfi.filter((row) => row.uuid !== top.uuid)];
					resetMfiRowChanges(updatedMfi);
					update.mfi = updatedMfi;
				}

				dispatch({
					type: "SET_CONTEXT_OR_MFI",
					data: update,
				});
			}

			//TODO once we are for reals modify multiple objects this will need to be updated accordingly
			//If the top open object is a sub-object its version won't match up with this logRecord, we need to get it from the updated object hierarchy
			//How do we know if our top doesnt match up with the standard object?
			// let top = topObjectRow.current;
			// let newVersion;
			//
			// if (top.uuid === response.standardObjectUpdates[0].standardObject.uuid)
			//     newVersion = response.standardObjectUpdates[0].standardObject.logRecord.uuid;
			// else if (!newVersion && !top?.uuid) {
			//     top = response.standardObjectUpdates[0].standardObject;
			//     newVersion = top.logRecord.uuid;
			// } else {
			//     //Pull the newVersion by filtering out the standardObjectUpdates.subObjectChanges
			//     let subObjectChanges = response.standardObjectUpdates[0].subObjectChanges;
			//     let objectSaved = Object.entries(subObjectChanges).filter(obj => obj[0].split('/')[0] === topObjectRow.current.uuid)[0]?.[1];
			//     newVersion = objectSaved?.objectRowsToUpdate.filter(obj => obj.uuid === topObjectRow.current.uuid)[0].versionUuid;
			// }

			//if the versionUuid is null then I'm assuming we are in a sub-object, so we need to grab the new version from the updated hierarchy
			// if (!newVersion && response) {
			//     let hierarchyRecord = response.standardObjectUpdates[0].standardObject.objectHierarchy.find(row => row.descendantStandardObjectUuid === top.uuid);
			//
			//     if (hierarchyRecord)
			//         newVersion = hierarchyRecord.descendantStandardObjectVersionUuid;
			// }
			//
			// let attachmentPoint, hierarchyRecord;

			//We need to update the fullObjectHierarchy with the new versions of the object
			// let updatedHierarchy = response.standardObjectUpdates[0].standardObject.objectHierarchy;

			//Get the matching object hierarchy record
			// if (updatedHierarchy)
			//     hierarchyRecord = updatedHierarchy.find(row => row.descendantStandardObjectUuid === top.uuid && row.descendantStandardObjectVersionUuid === newVersion);

			// dispatch({
			//     type: 'SET_CONTEXT_OR_MFI',
			//     data: {
			//         updateFullObjectHierarchyVersions: updatedHierarchy,
			//     }
			// });

			//TODO: I may need to create this key from the old one uuid and version, verify
			// let key = getObjectIdAndVersionUuid(sharedState.contextTop);
			//
			// let updatedRow = response.standardObjectUpdates[0].subObjectChanges[key]?.objectRowsToUpdate?.find(row => row.uuid === top.uuid);
			// let ancestorObjects = ancestorObjectsRef.current;

			//If the parent uuid is not equal to the zero row uuid, we are at a sub-object, i think? We will use this as the attachment point
			//TODO: Do we want to get rid of the if and just run this code every time?
			// if (
			//     top.parentUuid && top.parentUuid !== ZERO_ROW_UUID &&
			//     (
			//         (ancestorObjects &&
			//             ancestorObjects.length > 0) ||
			//         sharedState.contextPacket?.uuid ||
			//         sharedState.contextDestinationObject?.uuid
			//     )) {
			//     //Get the updatedRow from the global context (this is the attachment point) then update the version and log record based on the return in the response
			//     let { packet, destinationObject } = sharedState.context;
			//
			//     //Grab the updated hierarchy record
			//     attachmentPoint = { ...top, newVersion, logRecord: updatedRow?.logRecord || top.logRecord, versionControl: updatedRow?.versionControl || top.versionControl, objectHierarchy: [hierarchyRecord] };
			//
			//     //We also need to update the ancestor objects array with the updated version we just got back
			//     let subChanges = response.standardObjectUpdates[0].subObjectChanges;
			//     let updatedObjectHierarchy = response.standardObjectUpdates[0].standardObject.objectHierarchy;
			//     let oldAncestorObjects = [...ancestorObjects];
			//     let attachmentPoints = await getAttachmentPoints();
			//
			//     Object.entries(subChanges).forEach(([key, value]) => {
			//         //Key is the old uuid and version
			//         let oldUuidAndVersion = splitObjectIdAndVersion(key);
			//
			//         //value contains the new row for the sub-object
			//         let updatedSubObject = value.objectRowsToUpdate?.find(row => row.uuid === oldUuidAndVersion.uuid);
			//         if (!updatedSubObject)
			//             return;
			//
			//         if (updatedSubObject.uuid === packet?.uuid) {
			//             dispatch({
			//                 type: 'SET_CONTEXT_OR_MFI',
			//                 data: {
			//                     packet: { ...updatedSubObject }
			//                 }
			//             })
			//         }
			//
			//         if (updatedSubObject.uuid === destinationObject.uuid)
			//             dispatch({
			//                 type: 'SET_CONTEXT_OR_MFI',
			//                 data: {
			//                     destinationObject: { ...updatedSubObject }
			//                 }
			//             })
			//
			//         updatedSubObject.objectHierarchy = [updatedObjectHierarchy.find(row => row.descendantStandardObjectUuid === updatedSubObject.uuid && row.descendantStandardObjectVersionUuid === updatedSubObject.versionUuid)];
			//
			//         let updateIndex = ancestorObjects.findIndex(row => row.uuid === oldUuidAndVersion.uuid && row.versionUuid === oldUuidAndVersion.versionUuid);
			//         if (updateIndex > -1)
			//         {
			//             //Some of the updatedSubObjects are coming back without a reference, before replacing the ancestor object grab its reference
			//             updatedSubObject.reference = ancestorObjects[updateIndex].reference;
			//             updatedSubObject.referenceNo = ancestorObjects[updateIndex].referenceNo;
			//             if(!updatedSubObject.parentUuid)
			//                 updatedSubObject.parentUuid = ancestorObjects[updateIndex].parentUuid;
			//             ancestorObjects.splice(updateIndex, 1, updatedSubObject);
			//         }
			//
			//         //If this is the top object we don't need to worry about updating the attachment point for it because there shouldn't be one
			//         if (ancestorObjects[0] && updatedSubObject.uuid !== ancestorObjects[0].uuid)
			//         {
			//             //We need to update the attachmentPoints with the new latest version
			//             let oldAttachmentPoint = attachmentPoints.find(row => row.uuid === updatedSubObject.uuid);
			//             if (!oldAttachmentPoint)
			//                 addAttachmentPoint({ objectId: updatedSubObject.uuid, ...trimObject(updatedSubObject) });
			//             else {
			//                 updateAttachmentPoint({
			//                     ...oldAttachmentPoint,
			//                     ...trimObject(updatedSubObject),
			//                     reference: oldAttachmentPoint.reference,
			//                 });
			//             }
			//         }
			//     });

			//Pass the updated ancestorObjects
			// dispatch({
			//     type: 'SET_CONTEXT_OR_MFI',
			//     data: {
			//         ancestorObjects,
			//     }
			// });

			//Reset change data
			//     await dispatch({
			//         type: 'UPDATE_CHANGED_ROWS',
			//         data: {
			//             //How do I get the object to update? it would need to be passed in from the Body Component probably
			//             changedObjectRows: [],
			//             deletedRows: [],
			//             objectHierarchy: [],
			//
			//         }
			//     });
			//
			//     //As related objects get updated, what should happen to the ancestors? Does the backend take care of this already?
			//     // updateRelationships(top, oldAncestorObjects, ancestorObjects);
			// }
			//Clear attachment points?
			// else {
			//     clearAttachmentPoints();
			// }

			// if (newVersion != null) {
			//     //This should "refresh" the page on each save
			//     await openExisting(top.uuid, newVersion, null, attachmentPoint, updatedHierarchy);
			// }

			//We don't need to do this anymore because we do it in openExisting
			// saveLocalStorage( {contextId: top.uuid, contextVersionId: newVersion} );

			// objectChangeData.current[viewTitle] = {};
			// changedRows.current = {};
			dispatch({ type: "UPDATE_CHANGED_ROWS", data: { reset: true } });
			// dispatch({ type: 'TOGGLE_CHANGES', data: false });
			// // updatedStockNumberData.current = initialStockNumberData;
			// dispatch({ type: 'RESET_STOCK_NUMBER_SAVE_DATA' });
			dispatch({ type: "REFRESH_VERSION_INFO" });

			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			return response;
		}

		const postMfiRows = async (changedMfiRows) => {
			if (changedMfiRows && Object.values(changedMfiRows).length > 0) {
				let sorted = Object.values(changedMfiRows).sort(sortByReference);
				let mfiUpdate = {
					mfiRowsToUpdate: sorted,
					rowsToDelete: [],
					reRef: false,
					stockNumbersToUpdate: [],
					volumesToUpdate: [],
					objectClassesToUpdate: [],
					languageFrameworksToUpdate: [],
				};

				//Update mfi rows
				let url = getUrl("updateMfi");
				let response = await postCall(url, mfiUpdate);
			}
		};

		//For now you can't change another existing object on an objects page, in the future we may need to change this to
		//add a check for other objects
		//If the current object is being changed, and someone owns the object, and you are not the owner then create a copy of the object the user can modify
		//Changed to check if user has access on add changed rows rather than at the save
		// if (
		//     !isLocalhost &&
		//     // isLocalhost &&
		//     (sharedState.changedData[viewTitle][standardObjectTitle] && currentObject.createdByUuid && currentObject.createdByUuid !== sharedState.currentUser?.uuid)) {
		//     // if((obj.changedRows[getObjectIdAndVersionUuid(currentObject)] && currentObject.createdByUuid && currentObject.createdByUuid !== sharedState.currentUser.uuid)) {
		//     createNewFromExisting(currentObject.uuid, currentObject, true);
		//
		//     dispatch({ type: 'SET_SHOW_LOADING_BAR', data: false });
		// }
		// else {
		//Save the rows
		let changed;
		if (objectsToSave) changed = objectsToSave[viewTitle][standardObjectTitle];
		else if (
			sharedState.changedData &&
			sharedState.changedData[viewTitle] &&
			sharedState.changedData[viewTitle][standardObjectTitle]
		)
			changed = sharedState.changedData[viewTitle][standardObjectTitle];
		else if (newObjTransaction) changed = {};

		//Each key in changed is an object that needs saved
		// Object.keys(changed).forEach(key => {
		//     let p = postObjectAndMfiRows(changed[key]);
		//     promises.push(p);
		// });

		let saveResponse = await postObjectAndMfiRows(changed, objectsToSave?.stockNumbers);
		let newTop = saveResponse.standardObjectUpdates[0]?.standardObject;
		//Update the changedMfiRows based on the saveResponse above if needed (May happen when we create MFI rows tied to sub-objects we just saved)
		let changedMfiRows;
		if (objectsToSave) changedMfiRows = objectsToSave[viewTitle]?.[masterFileIndexTitle]?.mfi;
		else changedMfiRows = sharedState.changedData[viewTitle]?.[masterFileIndexTitle]?.mfi;

		if (changedMfiRows) {
			let top = saveResponse?.standardObjectUpdates[0]?.standardObject;
			//If we're harvesting objects, do this part a little differently
			if (harvesting.current && updates) {
				top = updates[0];

				let pointersToObjects = Object.values(changedMfiRows).filter((row) => row.standardObjectVersionUuid);

				pointersToObjects.forEach((row) => {
					let match = updates.find((update) => update.title === row.title);
					if (match) {
						row.standardObjectUuid = match.uuid;
						row.standardObjectVersionUuid = match.versionUuid;
					}
				});
			} else {
				let pointersToObjects;
				if (!harvesting.current)
					pointersToObjects = Object.values(changedMfiRows).filter(
						(row) => row.standardObjectVersionUuid && row.standardObjectUuid !== top.uuid
					);
				else pointersToObjects = Object.values(changedMfiRows).filter((row) => row.standardObjectVersionUuid);

				pointersToObjects.forEach((row) => {
					let idAndVersionId = getObjectIdAndVersionUuid({
						uuid: row.standardObjectUuid,
						versionUuid: row.standardObjectVersionUuid,
					});
					let match = saveResponse.standardObjectUpdates.find((update) =>
						Object.keys(update.subObjectChanges).includes(
							getObjectIdAndVersionUuid({
								uuid: row.standardObjectUuid,
								versionUuid: row.standardObjectVersionUuid,
							})
						)
					);
					let updatedRow = match?.subObjectChanges[idAndVersionId]?.objectRowsToUpdate?.find(
						(updatedRow) => updatedRow.uuid === row.standardObjectUuid
					);
					if (updatedRow) row.standardObjectVersionUuid = updatedRow.logRecord.uuid;

					//Update to the standardObjectUuid and standardObjectVersionUuid to match the ones in the saveResponse
					// let matchingHierarchy = top.objectHierarchy.find(
					// 	(hier) =>
					// 		hier.ancestorStandardObjectUuid === top.uuid &&
					// 		hier.ancestorStandardObjectVersionUuid === top.versionUuid
					// );
					// if (matchingHierarchy) {
					// 	row.standardObjectUuid = matchingHierarchy.descendantStandardObjectUuid;
					// 	row.standardObjectVersionUuid = matchingHierarchy.descendantStandardObjectVersionUuid;
					// 	row.objectHierarchyUuid = matchingHierarchy.uuid;
					// }
				});
			}
		}

		//Save all of the mfi rows, if there are some
		await postMfiRows(changedMfiRows);

		if (openAfterSave.current) {
			openAfterSave.current = false;
			let top = saveResponse?.standardObjectUpdates[0]?.standardObject;
			openExisting(top.uuid, top.logRecord.uuid);
		}

		//Change to get destination model MFI instead of this

		if (destinationModelUuid) {
			dispatch({ type: "UPDATE_DESTINATION_MODEL", data: { mfi: [] } });
			getDestinationModel(destinationModelUuid);
		}

		//Reset change data
		dispatch({
			type: "UPDATE_CHANGED_ROWS",
			data: {
				//How do I get the object to update? it would need to be passed in from the Body Component probably
				reset: true,
			},
		});
		//}
		if (viewTitle != viewTitles.DEFAULT) {
			openDefaultView();
		}
		return newTop;
	};

	/**
	 * As a related object is updated we want to update the relationships of the objects we came from
	 * Decide which relationships need created and which objects need updated to point to the new relationships
	 *
	 * Look at breadcrumbs and get all the ones that are a related object (I can look at old and / or new)
	 * Ex: Org Chart Creator -> Position Creator -> BOD Position -> Reports to CEO Position
	 * If I change the title of CEO Position, what should happen to the ancestors?
	 * My breadcrumbs look like this:
	 *  Org Chart Creator - RELATED_TO -> Position Creator - COMPOSED_OF -> BOD Position - RELATED_TO -> CEO Position
	 *  1) CEO Position gets a new version because its title changed
	 *
	 *  2) New relationship created relating BOD Position to new Version of CEO Position
	 *  3) BOD Position gets a new version with the updated relationship
	 *
	 *  4) New relationship created relating Position Creator to the new Version of BOD Position
	 *  5) Position Creator gets a new version in order to point to the new relationship
	 *
	 *  6) New relationship created relating Org Chart Creator to the new version of Position Creator
	 *  7) Org Chart Creator gets a new version in order to point to the new relationship created above
	 *          Get
	 *
	 *  To break this down:
	 *  1) Get the breadcrumbs (ancestorObjects) that are related objects
	 *  2) For each one relatedObject
	 *      Get its ancestor
	 *      Get the related objects for the ancestor
	 *      Find the relationship that related the ancestor to the old version of the related object
	 *      Create new relationship relating ancestor to new version of related object
	 *      Find row in ancestor's MFI pointing to the old relationship and update it to point to the new one
	 *      Assign the change to the topMost object it should be associated with, it may not be the ancestor.
	 *
	 *      we need to create a new relationship that ties its ancestor to the new version of the related object
	 *      I probably want to go backwards so I have the new versionUuid for the related object as I hit its ancestor
	 *  3) If current related object is not in the breadcrumbs do the same thing as above for it and its ancestor.
	 *
	 * @param relatedObject
	 */
	const updateRelationships = async (updatedRelatedObject, oldAncestorObjects, newAncestorObjects) => {
		return;
		//Decide which relationships need created and which objects need updated to point to the new relationships
		//Get the relatedObjects from the old ancestors (We get the old ones so we can find the specific relationship tying to the old object)
		let relatedAncestors = oldAncestorObjects.filter(
			(row) => row.inputSource === INPUT_FIELD_SOURCES.OBJECT_RELATIONSHIP.value
		);

		let newRelationships = [];
		let oldToNewVersionMap = new Map();

		//Check if we need to update the current related object, if we haven't navigated into a sub-object it won't be in the ancestorObjects

		//For each related object, get the ancestor object above it, create a new relationship and update the ancestor object
		for (let i = relatedAncestors.length - 1; i >= 0; i--) {
			let related = relatedAncestors[i];
			//Get the ancestor above
			let relatedIndex = oldAncestorObjects.findIndex((row) => row.uuid === related.uuid);
			let oldAncestor = oldAncestorObjects[relatedIndex - 1];
			let newAncestor = newAncestorObjects[relatedIndex - 1];

			//TODO: How do i find out if there's a new version of this related object?
			if (oldToNewVersionMap.has(related.versionUuid))
				related.versionUuid = oldToNewVersionMap.get(related.versionUuid);

			//Create the new relationship tying to this newly updated related object
			let newRelationship = {
				uuid: uuidv4(),
				objectUuid: newAncestor.uuid,
				relatedObjectUuid: related.uuid,
				relatedObjectVersionUuid: related.versionUuid,
			};
			newRelationships.push(newRelationship);

			//Get the existing relationships for the ancestor
			let relationships = await loadRelationships(oldAncestor.uuid);

			//Get the ancestors MFI
			let ancestorMfi = await getSingleLevelObjectMfi(
				newAncestor.uuid,
				newAncestor.versionUuid,
				dispatch,
				newAncestor.computerVersion,
				newAncestor
			);

			//Get the relationship that tied to the older version of the related object
			let oldRelated = oldAncestorObjects[relatedIndex];
			let relationshipUuids = relationships
				.filter(
					(row) =>
						row.relatedObjectUuid === oldRelated.uuid &&
						row.relatedObjectVersionUuid === oldRelated.versionUuid
				)
				.map((row) => row.uuid);

			//I'm not sure I can guarantee I'm finding the correct related row. Need to get the oldRelationship by the exact old related object uuid and version, not just the uuid
			let relatedRows = ancestorMfi.filter((row) => relationshipUuids.includes(row[linkAttributeName]));

			//There should only be one related row, but we'll do a filter instead of a find just in case
			let updatedRows = [];
			//Update each row to point to the new relationship
			relatedRows.forEach((row) => {
				row[linkAttributeName] = newRelationship.uuid;
				updatedRows.push(row);
			});

			let objectToUpdate, subObjectToUpdate;
			//We want to attach this change to the top so check if the ancestor is the top or if we need to go find it
			if (newAncestor.parentUuid !== ZERO_ROW_UUID) {
				subObjectToUpdate = newAncestor;

				//Get the topMost object, we can probably use the breadcrumbs and get the ancestorObject right after the previous related object
				let previousRelatedObject = relatedAncestors[i - 1];
				let indexOfPrevious = newAncestorObjects.findIndex((row) => row.uuid === previousRelatedObject.uuid);
				objectToUpdate = newAncestorObjects[indexOfPrevious + 1];
			} else objectToUpdate = newAncestor;
			//Send the update data to the global state
			//TODO: Relationship Look at this when updating how we are going to handle relationships
			await updateChangeData(dispatch, { objectToUpdate, subObjectToUpdate, objectRows: updatedRows });

			//I think I need to call the save in order to figure out what the new version is
			let response = await saveObject();
			let updatedTop = response.standardObjectUpdates[0].subObjectChanges[
				getObjectIdAndVersionUuid(objectToUpdate)
			]?.objectRowsToUpdate?.find((row) => row.uuid === objectToUpdate.uuid);
			oldToNewVersionMap.set(objectToUpdate.versionUuid, updatedTop.versionUuid);

			//Add the old to new version to the map

			saveRelationships([newRelationship]);
		}
	};

	/**
	 *
	 * @param relationship
	 * @param newVersion
	 */
	const updateRelationship = async ({ top, subObject, mfi }, relationship, newVersion) => {
		return;

		//Get the hierarchy record
		let hierarchyRecord = relationship.objectHierarchy[0];

		//Update the version for the corresponding row and hierarchy record
		hierarchyRecord.descendantStandardObjectVersionUuid = newVersion;
		relationship.versionUuid = newVersion;

		//TODO: Relationship, need to update how we handle relationships
		await updateChangeData(dispatch, {
			objectToUpdate: top,
			// objectHierarchy: [hierarchyRecord],
			objectRows: [relationship],
		});

		saveObject();
	};

	/**
	 * Open or copy the existing object selected in the list (in dialog)
	 */
	const openExisting = async (
		uuid,
		versionUuid,
		computerVersion,
		attachmentPoint,
		updatedHierarchy,
		topAncestorUuid,
		topAncestorVersionUuid
	) => {
		// dispatch({ type: 'TOGGLE_EDIT_PACKET', data: editPacket});

		let oldUuid = sharedState.contextTop.uuid;
		let oldVersionUuid = sharedState.contextTop.versionUuid;
		let oldComputerVersion = sharedState.contextTop.computerVersion;

		//Verify there aren't unsaved changes that will get overwritten
		let { thereAreChanges, discard } = promptForUnsavedChanges();
		if (thereAreChanges && !discard) return;

		if (!sharedState.showSetupSheets) dispatch({ type: "TOGGLE_SHOW_SETUP_SHEETS" });

		//Check if we creating a copy of an object or if we are just opening
		let mfi = [];
		if (newFromExisting.current) {
			createNewFromExisting();
		} else {
			//TODO: I may be able to just call loadWorkspacePanel here instead of doing all this
			//Add the id to local storage
			// setMfi([]);
			// setTemplateRow({});
			// setTemplateMfi([]);
			if (
				oldUuid === uuid &&
				oldVersionUuid === versionUuid &&
				(computerVersion === oldComputerVersion || !computerVersion)
			)
				return;

			//Need to pass back an attachment point if this is a sub-object.
			mfi = await getSingleLevelObjectMfi(
				uuid,
				versionUuid,
				dispatch,
				computerVersion,
				attachmentPoint,
				topAncestorUuid,
				topAncestorVersionUuid
			);

			if (!mfi) {
				console.error("Unable to open object: " + uuid + "/" + versionUuid);
				return;
			}

			/**
			 * As we reload the top MFI, there can potentially be sub-objects that were loaded before that need loaded again, because for some reason we have to reload everything every time we save...
			 * What are the options we have?
			 *  1. We can keep track of all the sub-objects we have loaded in the shared state or something
			 *  2. We can check the new mfi with the old one and just merge the two, every reference that isn't in the new one add to it before updating the state
			 *      (This seems like the most efficient option although it may be more confusing?) I guess this probably won't work because the sub-object could possibly be a hologram
			 *  3. Grab all the sub-objects by finding the ones with the primus tag (I'm assuming they don't get the primus tag until their MFI is loaded)
			 *      Reload all of those
			 *
			 *  I'm going to leave this part out for now because in order for it to work, if I have an object open and have multiple nested levels, when I save and I'm making a change to the object that is the most deep
			 *      it triggers a version change in all the objects that are ancestors. this means that in order to ensure I have the right version of each object (because the ancestors could potentially have different versions)
			 *      I have to load each level one after the other. There should be a better way to do this.
			 *
			 *  Looks like I get the updated version for the sub-objects that get changed, lets try loading all of those, in order for this to work then I also need the updated objectHierarchy records
			 *
			 *  Now I get the updated hierarchy records, I can use this to ensure I have the correct version for everything
			 *  Get all the primus objects from the previous mfi
			 *  Get their updated versions from the returned objectHierarchy (this is in the saveResponse)
			 *  Grab each primus object using the new versions
			 */

			//Check if we loaded a sub-object and need to update the hierarchy stuff?
			let hierarchy = mfi[0]?.objectHierarchy?.filter(
				(row) =>
					row.descendantStandardObjectUuid === uuid && row.descendantStandardObjectVersionUuid === versionUuid
			);
			if (!attachmentPoint?.standardObjectUuid && attachmentPoint?.objectHierarchyUuid) {
				let hierarchyRecord = hierarchy.find((row) => row.uuid === attachmentPoint.objectHierarchyUuid);
				updatedHierarchy = [hierarchyRecord];
				//Grab each ancestor
				let splitPath = hierarchyRecord.pathEnum.split(".");
				let topAncestorUuidAndVersion = splitPath[0].split("/");
				let topAncestor = await getObjectDTOByUuidAndVersion(
					topAncestorUuidAndVersion[0],
					topAncestorUuidAndVersion[1]
				);
				dispatch({
					type: "SET_CONTEXT_OR_MFI",
					data: {
						ancestorObjects: [topAncestor],
					},
				});

				addAttachmentPoint({
					objectId: attachmentPoint.uuid,
					...trimObject({ ...attachmentPoint, objectHierarchy: [hierarchyRecord] }),
				});
			}

			let path = "";
			//TODO: loadedSubObjects might not get reset when it needs to, verify.
			//If we are reloading the same object then check if there are sub-objects that we need to load as well. We know if we are reloading the same object if we receive a saveResponse
			if (updatedHierarchy) {
				let hierarchyRecord = updatedHierarchy.find(
					(row) =>
						row.descendantStandardObjectUuid === uuid &&
						row.descendantStandardObjectVersionUuid === versionUuid
				);

				if (sharedState.contextLoadedSubObjects?.length > 0) {
					//The save response now returns the primus row with its new version, this lets us tie the old hologram object to the new one. We still need to figure out how to make the tie if we made a change to a hologram
					//and turned it into a 'real' object.
					let promises = [];
					sharedState.contextLoadedSubObjects.forEach((primusRow) => {
						//For each primus object grab the new objectHierarchy record and we'll pass that back, hoping there is enough info there to give me the correct mfi and attach it at the correct location?
						//TODO: NATE - I don't think this will work if the old primus object was a hologram, how do we resolve this? May be able to grab the one with the same path length as the old matching object hierarchy record
						let hierarchyRecord = updatedHierarchy.find(
							(row) => row.descendantStandardObjectUuid === primusRow.uuid
						);
						primusRow.versionUuid = hierarchyRecord.descendantStandardObjectVersionUuid;
						//TODO: NATE - Do I need to check if the loaded sub-object is a hologram?
						promises.push(
							getSingleLevelObjectMfi(primusRow.uuid, primusRow.versionUuid, dispatch, null, {
								...primusRow,
								objectHierarchy: [hierarchyRecord],
							})
						);
					});

					Promise.all(promises).then((res) => {
						res.forEach((res) => (mfi = [...mfi, ...res.slice(1)]));
						mfi.sort(sortByReference);
						dispatch({
							type: "SET_CONTEXT_OR_MFI",
							data: {
								top: mfi[0],
								mfi,
							},
						});
					});
				} else
					dispatch({
						type: "SET_CONTEXT_OR_MFI",
						data: {
							top: mfi[0],
							mfi,
						},
					});

				//Get the matching object hierarchy record
				if (hierarchyRecord) path = hierarchyRecord.pathEnum;

				/**
				 * TODO:
				 *  1. Verify this path is saved to localStorage,
				 *  2. When switching versions keep track of the path to get to this object. Can I potentially have an older version of an object that does not exist within the top object I opened?
				 *  3. When saving verify it retains the path information
				 *  4. When reloading the page check for the path information, if there is a path load the StandardObjectGit record for each one and set it in the breadcrumbs.
				 *
				 */
			}
			//Otherwise we are loading a new object
			else {
				loadObjectVersions(dispatch, mfi[0].versionUuid);
				dispatch({
					type: "SET_CONTEXT_OR_MFI",
					data: {
						top: mfi[0],
						mfi,
						resetSubObjectInfo: true,
						resetContextMfiVersion: true,
					},
				});

				if (oldUuid !== uuid) {
					dispatch({
						type: "UPDATE_OPEN_PROPERTY_WINDOWS",
						data: [],
					});

					dispatch({
						type: "SET_SELECTED_WORKSPACE_ROW",
						data: {},
					});
				}
			}

			//TODO: Trim attachmentPoints so it doesn't take all the memory
			saveLocalStorage({ contextId: uuid, contextVersionId: versionUuid, path });
		}

		// setCurrentObjects(existingObjects);
		// setObjListOpen(false);
		dispatch({ type: "TOGGLE_CHANGES", data: false });
		// if (location.hash !== `#${mfi[0].uuid}/${mfi[0].versionUuid}`)
		// 	navigate(`#${mfi[0].uuid}/${mfi[0].versionUuid}`);
		return mfi;
	};

	const checkForChanges = () => {
		//return if there are any changes
		if (sharedState.thereAreChanges) return true;
		else return false;
	};

	/**
	 * Check if there are changes that need saved, if there are prompt the user and ask if they want to save them or cancel
	 * @returns {*}
	 */
	const promptForUnsavedChanges = () => {
		if (checkForChanges()) {
			let answer = window.confirm("You have unsaved changes, are you sure you want to leave?");

			if (answer) {
				_removeOverlay(document);
				return { thereAreChanges: true, discard: true, saved: false };
			}
			// clearLastOpenedObject().then(res => {
			//     //If I received a uuid add it to the object history table then refresh
			//     if(uuid  && versionUuid)
			//         saveLocalStorage( {contextId: uuid, contextVersionId: versionUuid} );
			//
			//     // window.location.reload()
			//     resetWorkspace();
			//     setContextIdAndVersion(uuid, versionUuid);
			// });
			else _removeOverlay(document);

			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			return { thereAreChanges: true, discard: false, saved: false };
		}
		return { thereAreChanges: false, discard: true, saved: null };
	};

	/**
	 * Resets the data in the workspace
	 */
	const resetWorkspace = (resetCreatorPanelOnly = false, destinationObject) => {
		clearAttachmentPoints();

		packetRow.current = {};
		dispatch({ type: "SET_PACKET_MODE", data: false });
		//Update the global state context and mfi to be nothing
		dispatch({
			type: "SET_CONTEXT_OR_MFI",
			//Passing in nothing should reset it
			data: {
				reset: true,
				resetCreatorPanelOnly,
				destinationObject,
			},
		});

		// setShowCreatorPanels(false);

		//If we are resetting more than just the creator panel, reset the change rows too
		if (!resetCreatorPanelOnly) dispatch({ type: "UPDATE_CHANGED_ROWS", data: { reset: true } });
	};

	/**
	 * Empty changed rows array, deleted rows, and whatever is in local storage
	 *  resets everything by removing local storage, updating the context, then refreshing the page
	 * TODO: Change to reset by storing the original mfi, objects, and values and reset the changed values, we currently do not store the original copy
	 */
	const discardChanges = () => {
		if (window.confirm("Are you sure you want to discard your change? This action cannot be undone.")) {
			clearLastOpenedObject().then((res) => {
				window.location.reload();
			});
		}
	};

	/**
	 * This is called when something is dropped onto an element.
	 * I am assuming that this will only be called when I want it to,
	 * which, as of now, is only when something is dropped onto the template
	 * or workspace panel.
	 * @param event
	 * @param panel
	 */
	const handleDrop = (event, panelId) => {
		//Get the row the was dropped from the event
		let dragObj;
		try {
			dragObj = JSON.parse(event.dataTransfer.getData("text"));
		} catch (e) {
			return;
		}

		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

		//If there's no origin then return because we don't know where it came from
		if (
			!dragObj.origin ||
			(dragObj.origin !== "datawarehouse-panel" &&
				dragObj.origin !== "models-panel" &&
				dragObj.origin !== "template-panel")
		) {
			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			return;
		}

		if (dragObj[UBM_MASTER_FILE_INDEX_ITEM]) {
			//TODO: How should we handle warnings? This should probably warn the user that this is a UBM object and cannot be loaded into the workspace
			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			dispatch({
				type: "SHOW_ERROR_MESSAGE",
				data: "You are not authorized to open a UBM Object. To create an instance, click on the UBM Object and drag from the Source Panel and drop into the Creator Panel",
			});
			return;
		}

		let newObj = null;
		//We only care about the logic for dropping onto the workspace panel so I'll remove the rest
		//Check if the dragObj came from the data warehouse and if it already associated with a standard object record
		let { thereAreChanges, discard } = promptForUnsavedChanges();

		if (!discard && thereAreChanges) {
			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			return;
		} else if (dragObj.origin === "toc-tree") {
			if (!dragObj.isObject) {
				dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
				return;
			}

			//TODO: Program this later, needs to update the breadcrumbs, may need to add, remove, and / or replace some

			// updateBreadCrumbs(dragObj, true);
			// openExisting(dragObj.uuid, dragObj.versionUuid);
			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			return;
		}
		if (dragObj.origin === "datawarehouse-panel" && !dragObj.standardObjectUuid) {
			//If it's not, create a new object record and add to the list of changed objects
			newObj = copyStandardObject(dragObj, "0", zeroRowUuid, sharedState.currentUser?.uuid);
			newObj.standardObjectUuid = newObj.uuid;

			//Update the drag object to point to the new standard object and add it to the list of changed mfi rows
			dragObj.standardObjectUuid = newObj.uuid;
			dragObj.standardObjectVersionUuid = newObj.versionUuid;

			updateChangeData(
				dispatch,
				{ objectToUpdate: newObj, objectRows: [newObj], mfiRows: [dragObj] },
				false,
				sharedState.currentUser
			);

			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
		}

		//Check whether we came from data warehouse panel or the template panel
		if (dragObj.origin === "datawarehouse-panel" || dragObj.origin === "models-panel") {
			//If we have already opened this object don't do anything
			if (
				sharedState.contextTop.uuid === dragObj.standardObjectUuid &&
				sharedState.contextTop.versionUuid === dragObj.standardObjectVersionUuid
			) {
				dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
				return;
			}

			//Open the object
			newFromExisting.current = false;
			resetWorkspace();

			//If the dragObj.objectHierarchy is not null, we need to send an attachment point back so it knows what the top is
			let attachmentPoint;
			if (dragObj.objectHierarchyUuid)
				attachmentPoint = {
					uuid: dragObj.standardObjectUuid,
					versionUuid: dragObj.standardObjectVersionUuid,
					reference: "",
					title: dragObj.title,
					objectHierarchyUuid: dragObj.objectHierarchyUuid,
				};

			openExisting(dragObj.standardObjectUuid, dragObj.standardObjectVersionUuid, null, attachmentPoint, null);

			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
		} else if (dragObj.origin === "template-panel") {
			newFromExisting.current = true;
			let { contextDestinationObject: destinationObject, contextTop: top, contextPacket: packet } = sharedState;

			if (packet?.uuid) destinationObject = packet;
			else if (top?.uuid) destinationObject = top;

			if (destinationObject && destinationObject.uuid) {
				if (objectIsPacket(destinationObject, sharedState)) {
					//If it is a packet we need to create a relationship between the newDestination and the new object being created along with a row on the newDestination pointing to the relationship
					//Create relationship record (We don't have the uuid for this new object yet, we will have to set it when everything gets saved.
					let relationship = {
						uuid: uuidv4(),
						objectUuid: destinationObject.uuid,
					};
					newObjTransaction.current.relationship = relationship;
				}
			}

			resetWorkspace(true, destinationObject);

			createNewFromExisting(dragObj.uuid, dragObj);
		} else dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	};

	/**
	 * Create a copy of the object passed in, with a new id with a parent of the zero row
	 * @param obj
	 * @returns {{uuid: *, parentUuid: string, standardObjectUuid: *, stockNumber: (null|*), title: *, description: *, reference: string, referenceNo: number, value: string}}
	 */
	const createNewFromBlank = (obj) => {
		const uuid = uuidv4();
		return {
			uuid: uuid,
			parentUuid: zeroRowUuid,
			standardObjectUuid: uuid,
			stockNumber: obj.stockNumber || {},
			objectType: {},
			title: obj.title,
			description: obj.description,
			reference: "0",
			referenceNo: 0,
			value: "",
		};
	};

	const getDropMessage = (event, panelId) => {
		let origin = "";
		if (event.dataTransfer.types.includes("template-panel")) origin = "template-panel";
		else if (event.dataTransfer.types.includes("workspace-panel")) origin = "workspace-panel";
		else if (event.dataTransfer.types.includes("datawarehouse-panel")) origin = "datawarehouse-panel";
		else if (event.dataTransfer.types.includes("models-panel")) origin = "models-panel";

		if (!origin || origin === panelId) return;

		//Check if we are in the tree or outside it
		let treeNode = event.target.closest(".tree-node");

		//If we found an ancestor with a class of tree we are dragging inside the tree rather than replacing it, update the dragMessage to reflect this
		if (treeNode) {
			//We only handle the dropping on new modified workspace here, we can remove logic that checks otherwise
			return undefined;
		}
		//Otherwise we are dragging over the panel outside the tree
		else {
			//If I came from the data-warehouse panel and I'm dropping on the template panel, opens up the object
			if (origin === "datawarehouse-panel" || origin === "models-panel")
				return "Drop to load this object into the workspace (this allows you to modify the object)";
			else return "Drop to load a new copy of this object into this panel (replacing the data already here)";
		}
	};

	const safeResetWorkspace = () => {
		let { thereAreChanges, discard } = promptForUnsavedChanges();

		if (!discard && thereAreChanges) {
			dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			return;
		} else {
			resetWorkspace();
		}
	};

	//Version Control stuff
	const loadPreviousVersion = async () => {
		let { contextTop: top, contextPacket: packet } = sharedState;
		safeResetWorkspace();

		if (packet) top = packet;

		let newMfi = await openExisting(top.uuid, top.versionUuid, top.computerVersion - 1);
		// let previousVersionMfi = await getObjectMfi(context.uuid, context.versionUuid, dispatch, context.computerVersion - 1);
		// loadWorkspacePanel(context.uuid, context.versionUuid, previousVersionMfi);
		//TODO This may need to be removed for UX on future changes
		//On refresh pull up the version I was looking at
		saveLocalStorage({ contextId: top.uuid, contextVersionId: newMfi[0].versionUuid });
	};

	const loadNextVersion = async () => {
		let { contextTop: top, contextPacket: packet } = sharedState;
		safeResetWorkspace();
		if (packet) top = packet;

		let newMfi = await openExisting(top.uuid, top.versionUuid, top.computerVersion + 1);
		saveLocalStorage({ contextId: top.uuid, contextVersionId: newMfi[0].versionUuid });
	};

	const loadSpecificVersion = async (version) => {
		if (
			isNaN(version)
			//TODO: Not sure where the objectsComputerVersions comes from
			// || version > objectsComputerVersions.length
		)
			return;

		if (version < 1) version = 1;
		else if (sharedState.contextVersions.length < version) version = sharedState.contextVersions.length;

		safeResetWorkspace();

		let { contextTop: top, contextPacket: packet } = sharedState;
		if (packet?.uuid) top = packet;

		let newMfi = await openExisting(top.uuid, top.versionUuid, version);
		// loadWorkspacePanel(context.uuid, context.versionUuid, nextVersionMfi);
		//TODO This may need to be removed for UX on future changes
		//On refresh pull up the version I was looking at
		saveLocalStorage({ contextId: top.uuid, contextVersionId: newMfi[0].versionUuid });
	};

	const refreshComputerVersions = async (versionUuid) => {
		getObjectsComputerVersions(versionUuid).then((computerVersions) => {
			dispatch({
				type: "SET_CONTEXT_OR_MFI",
				data: {
					versions: computerVersions,
				},
			});
			// setComputerVersions(computerVersions);
		});
	};

	//This is a temporary computer version number so the user can see the number changing when the save is called
	//The user has the option of modifying an older version and saving it. This changes it to the latest version so
	// this gets the latest version and increments the number
	const incrementComputerVersionNumber = () => {
		sharedState.contextTop.computerVersion = sharedState.contextVersions.length + 1;
	};

	/**
	 * Called when a destination model type is selected in the dialog,
	 * Trigger the creation process for the type
	 * @param type
	 */
	const destinationModelTypeSelected = (type) => {
		setShowSelectDestinationTypeDialog(false);
		if (!type?.uuid) {
			setShowSelectDestinationDialog(true);
			return;
		}
		//Do whatever the handle drop from the Source Object Template triggers, but don't allow the user to stick it anywhere, create the MFI row and place it behind the scenes.
		newFromExisting.current = true;

		resetWorkspace(true);

		createNewFromExisting(type.uuid, type);
	};

	/**
	 * Called when a destination model is selected in the dialog,
	 * Update the global state
	 * @param type
	 */
	const destinationModelSelected = async (destinationModel) => {
		if (!canCreateDataWarehouse && !destinationModel?.uuid) {
			await setShowSelectDestinationDialog(false);
			setShowSelectDestinationDialog(true);
			return;
		}

		setShowSelectDestinationDialog(false);
		if (destinationModel?.uuid) {
			localStorage.destinationModel = destinationModel.uuid;
			dispatch({
				type: "UPDATE_DESTINATION_MODEL",
				data: { mfi: [destinationModel], uuid: destinationModel.uuid },
			});
			getExistingObjects(destinationModel.uuid, sharedState, dispatch);
		} else setShowSelectDestinationTypeDialog(true);
	};

	/**
	 * When a breadcrumb is clicked I'm back up the Object Hierarchy, I need to grab the MFI for the object clicked,
	 *  grab previous changes that need applied to it, set it as the context and MFI.
	 *  Ensure the fullObjectHierarchy is updated correctly.
	 * @param uuid
	 * @returns {Promise<void>}
	 */
	const breadcrumbClicked = async (uuid) => {
		//Base everything off of Model, App, Object IDE view. This will need to change once we are doing things from multiple views
		let view = sharedState.openView.title;
		let standardObjectTitle = dataObjects.STANDARD_OBJECT_GIT.title;
		let topUuidAndVersion = getObjectIdAndVersionUuid(sharedState.contextAncestorObjects[0]);

		//Get the corresponding row from the sharedState's loaded ancestors
		let ancestorRow = sharedState.contextAncestorObjects.find((row) => row.uuid === uuid);
		let ancestorIndex = sharedState.contextAncestorObjects.findIndex((row) => row.uuid === uuid);
		let ancestorRowUuidAndVersion = getObjectIdAndVersionUuid(ancestorRow);

		let body;

		if (sharedState.contextFullObjectHierarchy && sharedState.contextFullObjectHierarchy.length > 0) {
			let hierarchyRecord = checkForSubObject(ancestorRow, sharedState.contextFullObjectHierarchy);
			if (hierarchyRecord && hierarchyRecord.ancestorStandardObjectUuid !== ZERO_ROW_UUID)
				body = { ...ancestorRow, objectHierarchy: [hierarchyRecord] };
		} else {
			let attachmentPoints = await getAttachmentPoints();
			if (attachmentPoints.length > 0) {
				let attachmentPoint = attachmentPoints.find((row) => row.objectId === uuid);

				if (attachmentPoint && attachmentPoint.parentUuid != null) {
					body = {
						...ancestorRow,
						objectHierarchy:
							typeof attachmentPoint.objectHierarchy === "string"
								? JSON.parse(attachmentPoint.objectHierarchy)
								: attachmentPoint.objectHierarchy,
					};
				}
			}
		}

		//Grab the mfi for the uuid clicked
		let mfi = await getSingleLevelObjectMfi(ancestorRow.uuid, ancestorRow.versionUuid, dispatch, null, body);

		let changes;
		let referenceToPrepend;

		//Check if we have made any changes to any rows stored in the state
		if (
			sharedState.changedData &&
			sharedState.changedData[view] &&
			sharedState.changedData[view][standardObjectTitle] &&
			sharedState.changedData[view][standardObjectTitle][topUuidAndVersion]
		) {
			changes =
				sharedState.changedData[view][standardObjectTitle][topUuidAndVersion]?.objects[
					ancestorRowUuidAndVersion
				];
			if (changes) {
				Object.values(changes.mfi).forEach((row) => {
					if (body) referenceToPrepend = body.reference;
				});
			}
		}

		mfi = addChangesToMfi(mfi, changes, referenceToPrepend);

		//Update the ancestorObjects array to end right before the object we clicked on
		let { contextAncestorObjects: ancestorObjects } = sharedState;
		let attachmentPointsToRemove = ancestorObjects.splice(ancestorIndex + 1);
		ancestorObjects = ancestorObjects.slice(0, ancestorIndex);

		//Get the current attachmentPoints and remove the ones underneath this object clicked
		let attachmentPoints = await getAttachmentPoints();
		attachmentPointsToRemove.forEach((row) => {
			let point = attachmentPoints.find(
				(point) => point.uuid === row.uuid && point.versionUuid === row.versionUuid
			);
			if (point) deleteAttachmentPoint(point.id);
		});
		//Also remove the attachment point for the currently open object
		let currentPoint = attachmentPoints.find(
			(row) => row.uuid === sharedState.contextTop.uuid && row.versionUuid === sharedState.contextTop.versionUuid
		);
		if (currentPoint) deleteAttachmentPoint(currentPoint.id);

		//Get the changed hierarchygetStateChanges
		let { objectHierarchy: hierarchyChanges } = getStateChanges(sharedState, topUuidAndVersion);

		//Adding the new object's hierarchy (an object not yet saved) to the returned object's object hierarchy
		//When you have added a new sub-object it creates a new object hierarchy record (not in the database)
		//When you navigate into the sub-object and go back to the parent, we need to re-add it to the call's objectHierarchy list
		if (hierarchyChanges?.length > 0) {
			let directDescendants = hierarchyChanges.filter(
				(row) =>
					row.ancestorStandardObjectUuid === mfi[0].uuid &&
					row.ancestorStandardObjectVersionUuid === mfi[0].versionUuid
			);
			let tempObjectHierarchy = {};
			if (mfi[0].objectHierarchy && mfi[0].objectHierarchy.length > 0) {
				//Convert to a map to remove duplicates
				mfi[0].objectHierarchy.forEach((row) => (tempObjectHierarchy[row.uuid] = row));
				directDescendants.forEach((row) => (tempObjectHierarchy[row.uuid] = row));
				tempObjectHierarchy = Object.values(tempObjectHierarchy);
			} else tempObjectHierarchy = directDescendants;

			mfi[0].objectHierarchy = tempObjectHierarchy;
		}

		let top = mfi[0];
		let packet;
		if (objectIsPacket(top, sharedState)) packet = top;
		else packet = {};

		//Set the mfi in the sharedState
		dispatch({
			type: "SET_CONTEXT_OR_MFI",
			data: {
				top: mfi[0],
				mfi,
				ancestorObjects,
				packet,
				resetContextMfiVersion: true,
			},
		});
	};

	/**
	 * Triggered when a row on the TOC is clicked, or called when a link is clicked that links to something on the TOC
	 * Loads the row into the workspace whether its a relationship or sub-object
	 *
	 * @param tocRow: the row that was clicked. if it's an object we can load it into the workspace, if its an uploaded document we can pull up the dialog with the document
	 * @param obj: Right now this will only come from the open point panel if it links to something within the checklist. In this case it will always be the checklist.
	 * 					If wanting to load something within the checklist just show the checklist and if it's a task highlight that task
	 */
	const tocRowClicked = async (tocRow, obj, fromTaskRef) => {
		//First off we won't let the user modify the checklist, but
		if (
			(tocRow.uuid === checklistRef.current.uuid && tocRow.versionUuid === checklistRef.current.versionUuid) ||
			(tocRow.standardObjectUuid === checklistRef.current.uuid &&
				tocRow.standardObjectVersionUuid === checklistRef.current.versionUuid)
		) {
			return;
		}
		//If I have an object, at the start it will always be the checklist and the tocRow will be the link to info
		else if (obj && obj.uuid === checklistRef.current.uuid) {
			selectWorkspaceRow({ ...tocRow, highlightOnChecklist: true }, dispatch);
			return;
		}

		let processConst = await getProcessObjectDbConst();
		if (tocRow.objectTypeUuid === processConst.referenceUuid) {
			let hierarchyRecord = toc[0].objectHierarchy.find(
				(row) => row.descendantStandardObjectUuid === tocRow.uuid
			);
			tocRow.objectHierarchy = [hierarchyRecord];
			buildChecklist(tocRow);
			return;
		}

		if (tocRow.inputType === "file-upload-wopi" || tocRow.inputType === INPUT_FIELD_TYPES.FILE_UPLOAD.value) {
			dialogTocRow.current = tocRow;
			//If there is no location pull up the dialog for uploading a file
			if (!dialogTocRow.current.value) setShowUploadDialog(true);
			else setShowDialogForTSAndNTS(true);
			return;
		}

		let object;
		if (tocRow.isObject || tocRow.isAssociatedObject) object = tocRow;
		else if (fromTaskRef) {
			let childObjects = toc.filter((row) => row.parentUuid === tocRow.uuid && row.isObject);
			if (childObjects.length > 0) object = childObjects[0];
		}

		if (
			!object ||
			(object &&
				topObjectRow.current &&
				object.uuid === topObjectRow.current.uuid &&
				object.versionUuid === topObjectRow.current.versionUuid)
		)
			return;

		//If we're loading a new object from here, we want to clear out the top and mfi, because this will replace that object rather than get added on after it.
		//Check if its a relationship
		if (
			object.inputSource === INPUT_FIELD_SOURCES.OBJECT_RELATIONSHIP.value ||
			object.generalTypeUuid === ASSOCIATED_OBJECT_GENERAL_TYPE_UUID
		) {
			//Load the relationships for the packet
			let hierarchyRecord = toc[0].objectHierarchy.find(
				(row) =>
					row.descendantStandardObjectUuid === object[linkAttributeName] &&
					row.descendantStandardObjectVersionUuid === object[linkVersionAttributeName] &&
					row.hierarchyTypeUuid === ASSOCIATED_OBJECT_GENERAL_TYPE_UUID
			);

			if (hierarchyRecord)
				loadRelatedObject(
					hierarchyRecord,
					object,
					sharedState,
					dispatch,
					true,
					{
						addAttachmentPoint,
						getAttachmentPoints,
						updateAttachmentPoint,
						deleteAttachmentPoint,
					},
					setQueryParams
				);
		} else if (object) {
			let hierarchyRecord = checkForSubObject(object, toc[0].objectHierarchy);
			if (hierarchyRecord) {
				let update = {
					top: {},
					mfi: [],
				};

				if (sharedState.contextCurrentRelatedObject?.uuid === sharedState.contextTop?.uuid)
					update.currentRelatedObject = {};

				await dispatch({
					type: "SET_CONTEXT_OR_MFI",
					data: update,
				});
				let { objects: changes, objectHierarchy } = getStateChanges(
					sharedState,
					getObjectIdAndVersionUuid(sharedState.contextAncestorObjects[0])
				);
				let hierarchy = getGlobalObjectHierarchy(sharedState);

				if (changes)
					changes = getChangesForObject(changes, sharedState.contextAncestorObjects[0], hierarchy, true);
				else changes = null;

				loadSubObject(
					object,
					hierarchyRecord,
					sharedState,
					dispatch,
					false,
					{
						addAttachmentPoint,
						getAttachmentPoints,
						updateAttachmentPoint,
						deleteAttachmentPoint,
					},
					changes,
					setQueryParams
				);
			}
		}
	};

	/**
	 * Get the products from the TOC? Load them into the same process and dialog of dragging them into the appropriate place in the destination model
	 */
	const harvestProducts = async () => {
		//Get the products from the TOC, we'll let the user put any of these in their Reference Manual
		//Get the top level of the TOC (For now we will assume that the 3rd section is product section and that has the objects that need harvested
		let level1 = toc.filter((row) => row.parentUuid === toc[0].parentUuid);

		//Get the children of the third section which is where the products are held
		let objects;
		if (toc.length < 1) objects = [topObjectRow.current];
		else objects = toc.filter((row) => row.isObject && row.parentUuid === level1[2].uuid);

		let copies = [];
		let stockNumbersToUpdate = [];

		//Trigger the create new stuff pulling up the dialog that lets you place them in your model
		objects.forEach((obj) => {
			let copy = copyStandardObject(obj, "0", zeroRowUuid, sharedState.currentUser?.uuid, {
				newStockNumber: true,
				newVersion: true,
			});
			copy.standardObjectUuid = obj.uuid;
			copy.standardObjectVersionUuid = obj.versionUuid;
			copy.referenceObjectTitle = obj.title;
			copies.push(copy);

			if (copy.stockNumber && copy.stockNumber.uuid) stockNumbersToUpdate.push(copy.stockNumber);
		});

		//Update the new object transaction with the copies
		newObjTransaction.current.context = copies;
		newObjTransaction.current.changedRows = copies;
		newObjTransaction.current.mfi = [];
		newObjTransaction.current.newStockNumbers = stockNumbersToUpdate;

		// harvesting.current = true;
		setUploadToDataWarehouse(true);
	};

	/**
	 * Get the products from the TOC? Load them into the same process and dialog of dragging them into the appropriate place in the destination model
	 */
	const submitFilteredObject = async (filteredMfi) => {
		//Get the children of the third section which is where the products are held
		let objects = [topObjectRow.current];
		// if (toc.length < 1) objects = [topObjectRow.current];
		// else objects = toc.filter((row) => row.isObject && row.parentUuid === level1[2].uuid);

		let copies = [];
		let stockNumbersToUpdate = [];

		//Trigger the create new stuff pulling up the dialog that lets you place them in your model
		objects.forEach((obj) => {
			let copy = copyStandardObject(obj, "0", zeroRowUuid, sharedState.currentUser?.uuid, {
				newStockNumber: true,
				newVersion: true,
			});
			copy.standardObjectUuid = obj.uuid;
			copy.standardObjectVersionUuid = obj.versionUuid;
			copy.referenceObjectTitle = obj.title;
			copies.push(copy);

			if (copy.stockNumber && copy.stockNumber.uuid) stockNumbersToUpdate.push(copy.stockNumber);
		});

		let fitleredMfiCopy = filteredMfi.map((row) => ({ ...row }));
		// filteredMfi.filter(row => row.parentUuid === oldTop.uuid).forEach(row => row.parentUuid = copies[0].uuid);
		// filteredMfi.splice(0, 1, copies[0]);
		//For harvest we changed to only filter one object at a time rather than multiple
		//Update the new object transaction with the copies
		newObjTransaction.current.context = [fitleredMfiCopy[0]];
		newObjTransaction.current.changedRows = [fitleredMfiCopy];
		newObjTransaction.current.mfi = [];
		newObjTransaction.current.newStockNumbers = stockNumbersToUpdate;

		harvesting.current = true;
		setUploadToDataWarehouse(true);
	};

	/**
	 * Attach this object to the destination object
	 */
	const attachCurrentObjectToDestinationObject = async () => {
		let { contextDestinationObject: destinationObject, contextDestinationMfi: destinationMfi } = sharedState;

		if (!destinationMfi || destinationMfi.length < 1) return;

		//Get the very top object
		let top = getTopMostObject(sharedState);

		let rowToAttachTo;

		//First check if we know where to put this relationship
		//If the destination object has a packet type we will put it at the bottom of section (C)
		if (destinationObject?.objectTypeUuid === sharedState.dbConstants.packetObject.referenceUuid)
			rowToAttachTo = destinationMfi.find((row) => row.reference === packetProductsSectionReference);
		//If not get the type of the current object and see if the destination object has any rows with a matching attachable type
		else {
			//If so put it in that section
			let { objectTypeUuid } = top;
			let rowWithAttachableType = destinationMfi.filter((row) => {
				let types = row.attachableTypes.map((type) => type.split(",")[0]);
				if (types.length > 0) return types.includes(objectTypeUuid);
			});

			//If we found a row with this attachable type this is where we will attach
			if (rowWithAttachableType.length > 0) rowToAttachTo = rowWithAttachableType[0];

			//If not pull up a dialog that lets you drag the relationship where in the Object Master File Index it should go (What dialog should this be?).
		}

		// if(rowToAttachTo)
		//     buildAndSaveRelationship(rowToAttachTo, top)
		// else
		//     setShowAttachToDestinationObjectDialog(true);
		if (rowToAttachTo) {
			//The row within the destinations MFI will be a copy of the git record
			/**
			 * For the object_hierarchy record the ancestor will be the destination object
			 *  the descendant will be the current object, 'top' in this case
			 *  the hierarchy_type_uuid will be the related object type
			 */

			//Build the hierarchyRecord that will relate the two objects
			let { hierarchyRecord, newRow } = buildRelationship(
				destinationObject,
				top,
				rowToAttachTo,
				destinationMfi,
				await getRelatedObjectType()
			);

			if (!hierarchyRecord || !newRow) return;

			await dispatch({
				type: "UPDATE_CHANGED_ROWS",
				data: {
					objectToUpdate: destinationObject,
					changedObjectRows: [newRow],
					objectHierarchy: [hierarchyRecord],
					changeIsFromDifferentObject: true,
				},
			});

			//This stuff may need to wait until after pulling up the dialog in the cases that there isn't a place to put it.
			let res = await saveObject();
		}
	};

	/**
	 * Creates a relationship at the rowAttachedTo, updates changed state and sends the save
	 * @param rowToAttachTo
	 * @param top
	 * @returns {Promise<void>}
	 */
	const buildAndSaveRelationship = async (rowToAttachTo, top) => {
		let { contextDestinationObject: destinationObject, contextDestinationMfi: destinationMfi } = sharedState;
		let relatedObjectType = await getRelatedObjectType();

		//Get the very top object
		if (!top) top = getTopMostObject(sharedState);

		//Build the hierarchyRecord that will relate the two objects
		let { hierarchyRecord, newRow } = buildRelationship(
			destinationObject,
			top,
			rowToAttachTo,
			destinationMfi,
			relatedObjectType
		);

		if (!hierarchyRecord || !newRow) return;

		await dispatch({
			type: "UPDATE_CHANGED_ROWS",
			data: {
				objectToUpdate: destinationObject,
				changedObjectRows: [newRow],
				objectHierarchy: [hierarchyRecord],
				changeIsFromDifferentObject: true,
			},
		});

		//This stuff may need to wait until after pulling up the dialog in the cases that there isn't a place to put it.
		let res = await saveObject();
	};

	const destinationAttachmentSelected = (rowToAttachTo) => {
		if (!rowToAttachTo) return;

		//Get the row to attach to, normally it will be the row passed unless it is an object
		if (rowToAttachTo?.isObject)
			rowToAttachTo = sharedState.contextDestinationMfi.find((row) => row.uuid === rowToAttachTo.parentUuid);

		buildAndSaveRelationship(rowToAttachTo);
	};

	const getDevelopers = () => {
		let developerTypeUuid = sharedState.dbConstants.developerType?.referenceUuid;
		let developerFolderTypeUuid = sharedState.dbConstants.developerFolderType?.referenceUuid;
		if (!developerTypeUuid || !developerFolderTypeUuid) return [];

		let developers = sharedState.destinationModel.find(
			(row) => row.mfiGeneralTypeUuid === sharedState.dbConstants.developerType.referenceUuid
		);
		//TODO: Right now the developer template folder doesnt have a location, if it ever does it'll break this part
		return sharedState.destinationModel.filter(
			(row) =>
				row.parentUuid === developers?.uuid && row.location && row.location !== sharedState.currentUser.uuid
		);
	};

	const sendToDeveloperForReviewSelected = async (row) => {
		if (!row) {
			setShowSendToDeveloperForReviewDialog(false);
			return;
		}

		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
		//Create object status record
		let top = getTopMostObject(sharedState);
		// let statuses = await getStatusesForObject();
		let status = {
			uuid: uuidv4(),
			objectUuid: top.uuid,
			objectVersionUuid: top.versionUuid,
			typeUuid: sharedState.dbConstants.sentForReviewFolderType.referenceUuid,
			userUuid: row.location,
			dataWarehouseUuid: sharedState.destinationUuid,
			createdBy: sharedState.currentUser.uuid,
		};

		await createObjectStatus(status);
		setObjectState(status);

		//We should also store the notification here
		let notification = {
			uuid: uuidv4(),
			objectUuid: top.uuid,
			objectVersionUuid: top.versionUuid,
			//The location should store the user's uuid
			userUuid: row.location,
			message: `${top.title} was sent to you for review by ${sharedState.currentUser.firstName} ${sharedState.currentUser.lastName}`,
			typeUuid: sharedState.dbConstants.sentForReviewFolderType.referenceUuid,
		};

		await createNotification(notification);

		setShowSendToDeveloperForReviewDialog(false);
		dispatch({ type: "UPDATE_DESTINATION_MODEL", data: { mfi: [] } });
		getDestinationModel(destinationModelUuid);
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	};

	const sendObjectBackToDeveloper = async () => {
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
		let top = getTopMostObject(sharedState);
		// let statuses = await getStatusesForObject();
		let status = {
			uuid: uuidv4(),
			objectUuid: top.uuid,
			objectVersionUuid: top.versionUuid,
			typeUuid: sharedState.dbConstants.inProcessFolderType.referenceUuid,
			dataWarehouseUuid: sharedState.destinationUuid,
			createdBy: sharedState.currentUser.uuid,
		};

		await createObjectStatus(status);

		//We should also create the notification here
		let notification = {
			uuid: uuidv4(),
			objectUuid: top.uuid,
			objectVersionUuid: top.versionUuid,
			//The location should store the user's uuid
			userUuid: objectState.createdBy,
			message: `${top.title} was reviewed by ${sharedState.currentUser.firstName} ${sharedState.currentUser.lastName} and sent back`,
			typeUuid: sharedState.dbConstants.inProcessFolderType.referenceUuid,
		};

		await createNotification(notification);

		setObjectState(status);
		dispatch({ type: "UPDATE_DESTINATION_MODEL", data: { mfi: [] } });
		getDestinationModel(destinationModelUuid);
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	};

	const showAddObjectsToPacket = () => {
		dispatch({ type: "OPEN_DIALOG", data: "addObjectsToPacketDialog" });
	};

	const initiateChangeRequest = () => {
		dispatch({ type: "OPEN_DIALOG", data: "changeRequestSelectionDialog" });
	};

	const initiateFixPacket = async (changeRequestPacket) => {
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
		if (sharedState.thereAreChanges) await saveObject();
		let fixPacket = await createFixPacket(sharedState, changeRequestPacket);
		saveAndOpenObject(fixPacket);
	};

	const initiateReleasePacket = async (fixPacket) => {
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
		if (sharedState.thereAreChanges) await saveObject();
		let patchPacket = await createPatchPacket(sharedState, fixPacket);
		saveAndOpenObject(patchPacket);
	};

	const changeRequestSourceSelected = async (source, additionalObjects, additionalChanges) => {
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
		//Happens on save of this dialog opened above
		let changeRequest = await createChangeRequestPacket(sharedState, source, additionalObjects, additionalChanges);
		saveAndOpenObject(changeRequest);
	};

	const prepareObjectsForRelease = async (patchPacket) => {
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
		if (sharedState.thereAreChanges) await saveObject();
		setItemsToRelease(await releaseToProduction(sharedState, patchPacket));
		// let releasedItems = await releaseToProduction(sharedState, patchPacket);
		dispatch({ type: "OPEN_DIALOG", data: "releaseDialog" });
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
		// for (const releasedItem of releasedItems) {
		// 	await saveAndOpenObject(releasedItem, true);
		// }
	};

	const release = async (releasedItems, mfiRows) => {
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

		let response = await releaseObjects(releasedItems);

		mfiRows.forEach((row) => {
			//Find the standard object match
			let match = response.find((obj) => obj.uuid === row.standardObjectUuid);

			//Update row to have a standardObjectVersionUuid of match
			if (match) row.standardObjectVersionUuid = match.logRecord.uuid;
		});
		newObjTransaction.current.changedMfiRows = mfiRows;
		harvesting.current = true;
		await saveObject(null, convertNewObjTransactionToChangeMap());
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
		// for (const releasedItem of releasedItems) {
		// 	await saveAndOpenObject(releasedItem, true);
		// }
		// harvesting.current = false;
	};

	const toggleEditPacket = () => {
		let { packetMode, contextAncestorObjects: ancestorObjects, contextTop } = sharedState;

		dispatch({ type: "SET_PACKET_MODE", data: !packetMode });
		if (packetMode) {
			let top = getTopMostObject(sharedState);
			toggledPacketModeForPacket.current.set(packetRow.current.uuid, false);
			if (top.uuid === packetRow.current.uuid)
				openExisting(packetRow.current.uuid, packetRow.current.versionUuid);
			//What should happen in the else? It would mean the packet is a sub-object of the top, do we reset all the way to the top? or load the packet into the workspace and make sure we have its ancestors?
			else openExisting(top.uuid, top.versionUuid);
		} else {
			//Switching back to packet mode, reset the workspace data and grab the toc and checklist for the packet
			toggledPacketModeForPacket.current.set(packetRow.current.uuid, true);

			//Get the closest ancestor packet
			getChecklistAndTOC(packetRow.current, null, true);

			dispatch({ type: "SET_CONTEXT_OR_MFI", data: { top: {}, mfi: [] } });
		}
	};

	//TODO Will what should this method be called
	const saveAndOpenObject = async (object, isRelease = false) => {
		newObjTransaction.current.context = object.top;

		let map = new Map();
		object.subObjects.forEach((row) =>
			map.set(getObjectIdAndVersionUuid(row), {
				[getObjectIdAndVersionUuid(row)]: { ...row, reference: "0" },
			})
		);

		let rowsWithAncestor = object.changedRows.filter((row) => row.ancestorUuidAndVersion);
		let rowsWithoutAncestor = object.changedRows.filter((row) => !row.ancestorUuidAndVersion);
		rowsWithAncestor.forEach((row) => {
			if (!map.has(row.ancestorUuidAndVersion)) map.set(row.ancestorUuidAndVersion, {});

			map.get(row.ancestorUuidAndVersion)[row.uuid] = row;
		});

		let changeMap = convertListToObjectMap(rowsWithoutAncestor, object.top);

		//merge changeMap and map created above
		for (let [key, value] of map) {
			changeMap[key] = value;
		}

		newObjTransaction.current.changedRows = changeMap;
		newObjTransaction.current.objectHierarchy = object.objectHierarchy;
		newObjTransaction.current.changedMfiRows = [];

		if (!isRelease) {
			//Create the record that attaches the change request the users developer folder
			let mfiRow;

			let { inProcessMfi, userMfi } = await getUserMfiInDataWarehouse(sharedState);
			if (inProcessMfi) {
				let newRef = getNewRefForObjectInUserFolder(userMfi, inProcessMfi);

				//Create the new row
				mfiRow = copyStandardObject(
					object.top,
					newRef.reference,
					inProcessMfi[0].uuid,
					sharedState.currentUser?.uuid,
					{ newObject: true }
				);
			} else if (userMfi) {
				let userRecord = userMfi[0];
				//Get the reference for the new mfi record
				let newRef;
				let children = userMfi.filter((row) => row.parentUuid === userRecord.uuid);
				if (children.length > 0) newRef = getNextRef(children[children.length - 1].reference);
				else newRef = getNextRef(userRecord.reference + ".00");

				//Create the new row
				mfiRow = copyStandardObject(
					object.top,
					newRef.reference,
					userRecord.uuid,
					sharedState.currentUser?.uuid,
					{ newObject: true }
				);
			}
			//If there's not a user mfi or inProess mfi I don't know where to stick the change request packet
			if (mfiRow) newObjTransaction.current.changedMfiRows.push(mfiRow);
		} else if (object.changedMfiRows) newObjTransaction.current.changedMfiRows = object.changedMfiRows;

		let packet = await saveObject(null, convertNewObjTransactionToChangeMap());
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
		if (!isRelease) window.open(`?uuid=${packet.uuid}&versionUuid=${packet.versionUuid}`, "_blank");
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	};

	const triggerVersionControlSave = async (changes) => {
		let { newChange, newHierarchyRecord, versionControlLog } = changes;
		//Build the change map adding the change and version control log to it
		let changeMap = convertListToObjectMap(
			[{ ...newChange, reference: newChange.reference.replace(versionControlLog.reference + ".", "") }],
			versionControlLog
		);
		changeMap[getObjectIdAndVersionUuid(versionControlLog)][getObjectIdAndVersionUuid(versionControlLog)] = {
			...versionControlLog,
			reference: "0",
		};
		//Add the new change passed in to the change map for the versionControlLog along with itself
		changeMap[getObjectIdAndVersionUuid(newChange)] = {
			[getObjectIdAndVersionUuid(newChange)]: { ...newChange, reference: "0" },
		};

		//Add the changes to newObjTransaction
		newObjTransaction.current = {
			context: packetRow.current,
			changedMfiRows: [],
		};
		newObjTransaction.current.changedRows = changeMap;
		newObjTransaction.current.objectHierarchy = [newHierarchyRecord];

		let convertedChanges;
		if (sharedState.changedData && Object.keys(sharedState.changedData).length > 0)
			convertedChanges = convertNewObjTransactionToChangeMap(sharedState.changedData);
		else convertedChanges = convertNewObjTransactionToChangeMap();

		await saveObject(null, convertedChanges);
	};

	// let workspaceMfiPanel = sharedState.openView.openPanels.find(panel => panel.id === objectWorkspacePanels.NEW_MODIFIED_OBJECT_WORKSPACE.id);

	let breadcrumbs = [];
	if (sharedState.contextAncestorObjects?.length > 0) {
		if (sharedState.packetMode) {
			let { contextAncestorObjects: ancestorObjects } = sharedState;
			//Get the packet objects
			let packetObjects = ancestorObjects.filter(
				(row) => row.objectTypeUuid === packetObjectUuid.current
				// && (row.generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE ||
				// 	!allowedToEditHarvestedObjects(sharedState))
			);

			if (packetObjects.length < 1) packetObjects = [packetRow.current];
			else {
				let indexOfChFixOrPatchPacket;
				if (packetRow.current?.uuid)
					indexOfChFixOrPatchPacket = packetObjects.findIndex((row) => row.uuid === packetRow.current.uuid);
				//Check if one of the packet objects is a change request, if so, the breadcrumb for that goes in the Object Creation, Edit, Setup Workspace not this one
				else
					indexOfChFixOrPatchPacket = packetObjects.findIndex(
						(row) =>
							row.standardObjectUuid === sharedState.dbConstants.changeRequestPacket.referenceUuid ||
							row.standardObjectUuid === sharedState.dbConstants.fixPacket.referenceUuid ||
							row.standardObjectUuid === sharedState.dbConstants.patchPacket.referenceUuid
					);
				if (indexOfChFixOrPatchPacket > -1)
					packetObjects = packetObjects.slice(0, indexOfChFixOrPatchPacket + 1);
			}

			//If there are any packet objects the creator breadcrumbs will start at the first ancestor object and stop at the last packet object
			if (packetObjects.length > 0) {
				//Find the index of the last packet object, that will be where we caught off these creator breadcrumbs
				let index = ancestorObjects.findIndex(
					(row) => row.uuid === packetObjects[packetObjects.length - 1].uuid
				);

				breadcrumbs = ancestorObjects.slice(0, index + 1).map((row) => ({
					key: row.uuid,
					text: row.title + (row.readonly ? " - READ ONLY" : ""),
					version: row.computerVersion,
					versionUuid: row.versionUuid,
				}));
			} else if (!sharedState.contextTop?.uuid && packetRow.current.uuid)
				breadcrumbs = ancestorObjects.map((row) => ({
					key: row.uuid,
					text: row.title + (row.readonly ? " - READ ONLY" : ""),
					version: row.computerVersion,
					versionUuid: row.versionUuid,
				}));
		}
	}

	//Add the packet if there's no top and it's not in the ancestors already
	if (
		sharedState.packetMode &&
		!sharedState.contextTop?.uuid &&
		packetRow.current?.uuid
		// && sharedState.contextAncestorObjects?.length < 1
	)
		breadcrumbs.push({
			key: packetRow.current.uuid,
			text: packetRow.current.title,
			version: packetRow.current.computerVersion,
			versionUuid: packetRow.current.versionUuid,
		});

	let relatedObjectUpdates = [];
	if (relatedObjectUpdatesInPacket.length > 0) relatedObjectUpdates = relatedObjectUpdatesInPacket;
	if (sharedState.contextRelatedObjectUpdates?.length > 0)
		relatedObjectUpdates = [...relatedObjectUpdates, ...sharedState.contextRelatedObjectUpdates];
	// if(sharedState.contextAncestorObjects?.length > 0 && !sharedState.editPacketMode)
	//     breadcrumbs = [...sharedState.contextAncestorObjects.filter(row => row.objectTypeUuid === packetObjectUuid.current && !row.noCreatorView).map(row => ({key: row.uuid, text: row.title, version: row.computerVersion, versionUuid: row.versionUuid}))];
	//
	// if(!sharedState.contextTop?.uuid && packet?.uuid && !sharedState.editPacketMode)
	//     breadcrumbs.push({key: packet.uuid, text: packet.title, version: packet.computerVersion, versionUuid: packet.versionUuid});

	const newModifiedPanel = <NewModifiedWorkspacePanel params={params} updateBreadCrumbs={updateBreadCrumbs} />;

	const layoutWithCreatorPanels = [
		//Table of Contents Panel
		{
			x: 0,
			y: 0,
			h: 1,
			w: 70,
			dynamicHeight: true,
			resizable: true,
			isBounded: true,
			i: "ae57d18e-ae15-4806-9a90-fa31d214712d",
			staticY: true,
		},
		//Checklist Panel
		{
			x: 70,
			y: 0,
			h: 1,
			w: 164,
			dynamicHeight: true,
			resizable: true,
			isBounded: true,
			i: "a7d08fe2-08e9-4b0d-b3fc-c509f7cb2075",
			staticY: true,
		},
		//New Modified Workspace Panel With Creator Checklist And Table Of Contents
		{
			x: 234,
			y: 0,
			h: 1,
			w: 606,
			dynamicHeight: true,
			resizable: true,
			isBounded: true,
			i: "969ec931-5445-4e6a-b2ef-af84646651f7",
			staticY: true,
		},
	];

	const layoutWithoutCreatorPanels = [
		//New Modified Workspace Panel Without Creator Checklist And Table Of Contents
		{
			x: 0,
			y: 0,
			h: 1,
			w: 840,
			dynamicHeight: true,
			resizable: true,
			isBounded: true,
			i: "ae57d18e-ae15-4806-9a90-fa31d9s0342d",
			staticY: true,
		},
	];

	let allowedToEdit = allowedToEditHarvestedObjects(sharedState);
	let showEditPacketButton =
		(topObjectRow.current?.objectTypeUuid === sharedState.dbConstants.packetObject?.referenceUuid &&
			(topObjectRow.current?.generalTypeUuid !== FILTERED_OBJECT_GENERAL_TYPE || allowedToEdit)) ||
		(packetRow.current?.uuid &&
			(packetRow.current?.generalTypeUuid !== FILTERED_OBJECT_GENERAL_TYPE || allowedToEdit));

	let panelToolbarButtons = [];
	if (packetRow.current?.uuid) {
		panelToolbarButtons.push({
			id: "creation-menu-attach-objects",
			title: "Attach Objects to Packet",
			icon: <PaperClipIcon />,
			onClick: showAddObjectsToPacket,
		});

		panelToolbarButtons.push({
			id: "creation-menu-send-for-review",
			title: objectState?.typeUuid !== sentForReviewType.current ? "Send for Review" : "Send back to owner",
			icon: <ShareIcon />,
			onClick: () => {
				if (objectState?.typeUuid !== sentForReviewType.current) setShowSendToDeveloperForReviewDialog(true);
				else sendObjectBackToDeveloper();
			},
		});

		if (packetRow.current.standardObjectUuid) {
			if (packetRow.current?.standardObjectUuid === sharedState.dbConstants.changeRequestPacket?.referenceUuid)
				panelToolbarButtons.push({
					id: "creation-menu-fix-packet",
					title: "Send to a Fix packet",
					icon: <ToolboxIcon />,
					onClick: (e) => {
						initiateFixPacket(packetRow.current);
					},
				});
			else if (packetRow.current?.standardObjectUuid === sharedState.dbConstants.fixPacket?.referenceUuid)
				panelToolbarButtons.push({
					id: "creation-menu-fix-packet",
					title: "Send to a Release Packet",
					icon: <ReleaseIcon />,
					onClick: (e) => {
						initiateReleasePacket(packetRow.current);
					},
				});
			else if (packetRow.current?.standardObjectUuid === sharedState.dbConstants.patchPacket?.referenceUuid)
				panelToolbarButtons.push({
					id: "creation-menu-fix-packet",
					title: "Release Objects",
					icon: <ReleaseIcon />,
					onClick: (e) => {
						prepareObjectsForRelease(packetRow.current);
					},
				});
		}
	}

	if (showEditPacketButton)
		panelToolbarButtons.push({
			id: "creation-menu-edit-packet",
			title: sharedState.packetMode ? "Edit Packet" : "Go to Packet View",
			icon: sharedState.packetMode ? <PencilSquareIcon /> : <BinderIcon />,
			onClick: (e) => {
				toggleEditPacket();
			},
		});

	return (
		<PanelOverlay elementId={"creator-toc-panel"} getMessage={getDropMessage} handleDrop={handleDrop}>
			<Panel
				panelContentTitle={
					breadcrumbs.length > 0 && (
						<Breadcrumbs breadcrumbs={breadcrumbs} breadcrumbClicked={breadcrumbClicked} />
					)
				}
				// className={"add-blur"}
				style={{ boxShadow: "rgb(45 69 95 / 90%) 0px 0px 25px 0px" }}
				panelTitle={"Creation Application Workspace"}
				menuItems={[
					{
						id: "creation-menu-file",
						label: "File",
						icon: <FolderIcon />,
						onClick: "",
						children: [
							{
								id: "creation-menu-save",
								label: "Save",
								icon: <SaveIcon />,
								onClick: saveObject,
								className: !sharedState.thereAreChanges ? "disabled" : "",
							},
							{
								id: "creation-menu-discard-changes",
								label: "Discard Changes",
								icon: <TrashIcon />,
								onClick: discardChanges,
								className: !sharedState.thereAreChanges ? "disabled" : "",
							},
							{
								id: "creation-menu-print",
								label: "Print",
								icon: <PrintIcon />,
								onClick: () => print(false),
							},
							{
								id: "creation-menu-print-all",
								label: "Print All",
								icon: <JournalsIcon />,
								onClick: () => print(true),
							},
							{
								id: "creation-menu-open-points",
								label: "Open Points",
								icon: <OpenPointIcon />,
								onClick: () => dispatch({ type: "OPEN_DIALOG", data: "openPoint" }),
							},
							{
								id: "creation-menu-harvest-products",
								label: "Harvest",
								icon: <JournalsIcon />,
								className:
									!sharedState.contextPacket?.uuid && !packetRow.current?.uuid ? "disabled" : "",
								onClick: harvestProducts,
							},
							{
								id: "creation-menu-filter-object",
								label: "Create Filter",
								icon: <JournalsIcon />,
								className:
									sharedState.contextPacket?.uuid ||
									sharedState.contextTop.generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE
										? "disabled"
										: "",
								onClick: () => setShowFilterObjectDialog(true),
							},
							{
								id: "creation-menu-attach-to-destination",
								label: "Attach to Destination",
								icon: <JournalsIcon />,
								className: !sharedState.contextDestinationObject?.uuid ? "disabled" : "",
								onClick: attachCurrentObjectToDestinationObject,
							},
							{
								id: "creation-menu-updates",
								label: "Updates",
								icon: <JournalsIcon />,
								// className: relatedObjectUpdates.length < 1 ? "disabled" : "",
								onClick: () => dispatch({ type: "OPEN_DIALOG", data: "update" }),
								// children:
								// 	relatedObjectUpdates.length > 0
								// 		? relatedObjectUpdates.map((row) => ({
								// 				id: `creation-menu-update-row-title-${row.uuid}`,
								// 				label: `Update ${row.reference} - ${row.title}`,
								// 				icon: <SaveIcon />,
								// 				onClick: () =>
								// 					updateRelationship(
								// 						{
								// 							top: getTopMostObject(),
								// 							mfi: packet?.uuid ? toc : sharedState.contextMfi,
								// 						},
								// 						row,
								// 						row.newVersionUuid
								// 					),
								// 		  }))
								// 		: [],
							},
						],
					},
					{
						id: "creation-menu-view",
						label: "View",
						icon: <FolderIcon />,
						onClick: "",
						children: [
							{
								id: "creation-menu-toggle-ref",
								label: "Toggle Full References",
								icon: <TogglesIcon />,
								onClick: (e) => dispatch({ type: "TOGGLE_SHOW_FULL_REF" }),
							},
							{
								id: "creation-menu-toggle-filter-object-mfi",
								label: "Toggle Filter Object MFI",
								icon: <TogglesIcon />,
								onClick: "() => setFilterWorkspaceMFI(prev => !prev)",
							},
						],
					},
					{
						id: "creation-menu-vcs",
						label: "VCS",
						icon: <FolderIcon />,
						onClick: "",
						children: [
							{
								id: "creation-menu-compare-versions",
								label: "Compare Versions",
								icon: <DiffIcon />,
								onClick: () => {
									// dispatch({ type: 'OPEN_VIEW', data: 'CREATE_CHANGE_REQUEST' });
									dispatch({ type: "OPEN_DIALOG", data: "objectComparison" });
									//TODO: Ask Nate what this should be
									// updateContextRefs(viewTitles.CREATE_CHANGE_REQUEST, {}, {});
								},
							},
							{
								id: "creation-menu-send-object-for-review",
								label: "Send for Review",
								icon: <ShareIcon />,
								onClick: () => {
									// Pull up dialog letting user choose who to send it to for review
									setShowSendToDeveloperForReviewDialog(true);
								},
							},
							{
								id: "creation-menu-send-object-back",
								label: "Send object back to original owner",
								icon: <ShareIcon />,
								onClick: sendObjectBackToDeveloper,
							},
							{
								id: "creation-menu-suggest-change",
								label: "Suggest a change",
								icon: <SuggestionIcon />,
								onClick: initiateChangeRequest,
							},
							// {
							// 	id: "creation-menu-create-change-request",
							// 	label: "Create Change Request",
							// 	icon: <PersonIcon />,
							// 	onClick: () => {
							// 		dispatch({ type: "OPEN_VIEW", data: "CREATE_CHANGE_REQUEST" });
							// 		dispatch({ type: "OPEN_DIALOG", data: "changeRequestForm" });
							// 		//TODO: Ask Nate what this should be
							// 		// updateContextRefs(viewTitles.CREATE_CHANGE_REQUEST, {}, {});
							// 	},
							// },
							// {
							// 	id: "creation-menu-submit-change-request",
							// 	label: "Submit Change Request",
							// 	icon: <PersonIcon />,
							// 	onClick: saveObject,
							// },
							// {
							// 	id: "creation-menu-review-change-requests",
							// 	label: "Review Change Requests",
							// 	icon: <PersonIcon />,
							// 	onClick: () => {
							// 		dispatch({ type: "OPEN_DIALOG", data: "changeRequestPacketSummary" });
							// 	},
							// },
							// {
							// 	id: "creation-menu-submit-to-gatekeeper",
							// 	label: "Submit to Gatekeeper",
							// 	icon: <PersonIcon />,
							// 	onClick: saveObject,
							// },
							// {
							// 	id: "creation-menu-approve-change-request",
							// 	label: "Approve Change Request",
							// 	icon: <PersonIcon />,
							// 	onClick: saveObject,
							// },
							// {
							// 	id: "creation-menu-review-fix-packets",
							// 	label: "Review Fix Packets",
							// 	icon: <PersonIcon />,
							// 	onClick: () =>
							// 		dispatch({
							// 			type: "OPEN_DIALOG",
							// 			data: "fixPacketSummary",
							// 		}),
							// },
							// {
							// 	id: "creation-menu-release",
							// 	label: "Release",
							// 	icon: <PersonIcon />,
							// 	onClick: () => dispatch({ type: "OPEN_DIALOG", data: "release" }),
							// },
							{
								id: "creation-menu-update",
								label: "Update",
								icon: <PersonIcon />,
								onClick: () => dispatch({ type: "OPEN_DIALOG", data: "update" }),
							},
						],
					},
					{
						id: "creation-menu-developer",
						label: "Developer",
						icon: <FolderIcon />,
						onClick: "",
						children: [
							{
								id: "creation-menu-debug",
								label: "Debug",
								icon: <WarningIcon />,
								onClick: () => dispatch({ type: "OPEN_DIALOG", data: "debug" }),
							},
						],
					},
					{
						id: "creation-menu-add-panel",
						label: "Add Panel",
						icon: <PlusIcon />,
						onClick: "",
					},
				]}
				quickActionItems={[
					{
						id: "creation-menu-quick-action-save",
						title: "Save",
						icon: <SaveIcon />,
						onClick: saveObject,
						disabled: !sharedState.thereAreChanges,
					},
					{
						id: "creation-menu-suggest-change",
						title: "Suggest a change",
						icon: <SuggestionIcon />,
						onClick: initiateChangeRequest,
					},
				]}
				panelToolbarButtons={panelToolbarButtons}
				panelStockNumber={
					sharedState.packetMode && packetRow.current?.uuid ? (
						<ObjectHeader
							objectTitle={""}
							reference={
								packetRow.current?.uuid
									? packetRow.current?.mfiReference + "[04]"
									: sharedState.contextTop?.mfiReference
							}
							referenceType={"DW"}
							stockNumber={
								packetRow.current?.uuid ? packetRow.current?.stockNo : sharedState.contextTop?.stockNo
							}
							version={
								packetRow.current?.uuid
									? {
											...packetRow.current.versionControl,
											//TODO: This could potentially cause issues if the versions are associated with the top and not the open packet? Unless sub-objects don't have versions and we don't show anything if there are no versions
											objectsComputerVersions: sharedState.contextVersions,
											computerVersion: packetRow.current.computerVersion,
											loadPreviousVersion,
											loadNextVersion,
											loadSpecificVersion,
									  }
									: {
											...topObjectRow.current.versionControl,
											objectsComputerVersions: sharedState.contextVersions,
											computerVersion: topObjectRow.current.computerVersion,
											loadPreviousVersion,
											loadNextVersion,
											loadSpecificVersion,
									  }
							}
						/>
					) : (
						""
					)
				}
				ref={workspaceContainer}
				panelGroup={"main"}
			>
				<CreatorGrid
					showCreatorPanels={sharedState.packetMode}
					toc={toc}
					tocRowClicked={tocRowClicked}
					checklistRow={checklistRef.current}
					checklist={checklist}
					updateTask={updateTask}
					newModifiedWorkspacePanel={newModifiedPanel}
					packet={packetRow.current}
					triggerSave={triggerVersionControlSave}
				/>

				<ObjectSetupFormDialog
					open={objSetupFormOpen}
					object={newFromExisting.current || objSetupFormNewVersion ? newObjTransaction.current.context : {}}
					submitNewObject={submitNewTopLevelObject}
					clearTransactionObject={objSetupFormHandleClose}
					objSetupFormNewVersion={objSetupFormNewVersion}
				/>

				<UploadToDataWarehouseDialog
					open={uploadToDataWarehouse}
					newObjTransaction={newObjTransaction}
					setObjectToUpload={setObjectToUpload}
					objReadyToUpload={objReadyToUpload}
					addChangedRows={(rows) => {
						let mfiRows = rows.filter((row) => row.mfiRow);
						let objectRows = rows.filter((row) => !row.mfiRow);

						if (
							!newObjTransaction.current.changedMfiRows ||
							!newObjTransaction.current.changedMfiRows.length
						)
							newObjTransaction.current.changedMfiRows = [];

						//Because this is part of a user transaction (the changes aren't final until the end) add the changed mfi rows to the newObjTransaction.current
						if (mfiRows.length > 0)
							newObjTransaction.current.changedMfiRows = [
								...newObjTransaction.current.changedMfiRows,
								...mfiRows,
							];
						if (objectRows.length > 0)
							newObjTransaction.current.changedRows = [
								...newObjTransaction.current.changedRows,
								...objectRows,
							];
						// addChangedRows(null, null, rows)
					}}
					upload={updateStateWithTransaction}
					uploadCancel={() => {
						deleteRow(newObjTransaction.current.uploadObject, sharedState.destinationModel);
						newObjTransaction.current = {};
						harvesting.current = false;
						dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
						setUploadToDataWarehouse(false);
					}}
				/>

				{/*Dialog for selecting the Destination Model Object*/}
				<SelectionDialog
					dialogTitle={"Select your Destination Model"}
					treeData={sharedState.destinationModels}
					treeTitle={"Destination Models"}
					rowSelected={destinationModelSelected}
					open={showSelectDestinationDialog}
					disableCancel={!canCreateDataWarehouse}
					submitButtonText={"Open"}
					cancelButtonText={"New"}
				/>

				{/*Dialog for selecting place the current object should attach to*/}
				<SelectionDialog
					dialogTitle={"Select the location to attach to"}
					treeData={sharedState.contextDestinationMfi}
					treeTitle={"Destinatino Object"}
					rowSelected={destinationAttachmentSelected}
					open={showAttachToDestinationObjectDialog}
					requireFullObject={false}
					submitButtonText={"Attach To"}
					cancelButtonText={"Cancel"}
				/>

				<ChangeRequestSelectionDialog changeRequestSourceSelected={changeRequestSourceSelected} />

				<DebugDialog
					deleteItemDescendants={(row) => {
						let listToDelete = [row];
						getDescendantsRecursively(row.uuid, sharedState.contextMfi, listToDelete);

						updateChangeData(
							dispatch,
							{ objectToUpdate: getTopMostObject(sharedState), deletedRows: listToDelete },
							false,
							sharedState.currentUser
						);
					}}
				/>

				<OpenPointDialog />
				{
					//Adam, don't delete this it's debugging code
					<ObjectComparisonDialog
						currentObject={sharedState.contextTop}
						currentObjectMfi={sharedState.contextMfi}
					/>
				}

				{/*Dialog for selecting the Destination Model Object Type to create*/}
				<SelectionDialog
					dialogTitle={"Create your Destination Model"}
					treeData={sharedState.destinationModelObjects.map((obj, index) => ({
						...obj,
						parentUuid: ZERO_ROW_UUID,
						reference: getNextRef("" + index + "").reference,
					}))}
					treeTitle={"Destination Model Types"}
					rowSelected={destinationModelTypeSelected}
					open={showSelectDestinationTypeDialog}
					submitButtonText={"Create"}
				/>

				{/*Dialog for selecting the Developer to send this object for review*/}
				<SelectionDialog
					dialogTitle={"Submit Object For Review"}
					treeData={getDevelopers()}
					treeTitle={"Developers"}
					rowSelected={sendToDeveloperForReviewSelected}
					open={showSendToDeveloperForReviewDialog}
					submitButtonText={"Submit"}
					requireFullObject={false}
				/>

				<WopiDialog
					show={showDialogForTSAndNTS}
					setShowing={setShowDialogForTSAndNTS}
					row={dialogTocRow.current}
				/>

				<DraggableDialog
					id={"upload-dialog"}
					header={`${dialogTocRow.current.reference} - ${dialogTocRow.current.title}`}
					showDialog={showUploadDialog}
					handleClose={() => setShowUploadDialog(false)}
					saveButton={false}
					cancelButtonText={"Close"}
				>
					{INPUT_FIELD_TYPES.FILE_UPLOAD.render({
						label: ``,
						description: dialogTocRow.current.description,
						updateRow: (fileId) => {
							dialogTocRow.current.value = fileId;
							dialogTocRow.current.objectTypeUuid = sharedState.dbConstants.fileObject.referenceUuid;
							updateChangeData(dispatch, {
								objectToUpdate: packetRow.current,
								objectRows: [dialogTocRow.current],
							});
						},
						row: dialogTocRow.current,
						centerContents: true,
					})}
				</DraggableDialog>

				<FilterDialog
					show={showFilterObjectDialog}
					handleClose={() => setShowFilterObjectDialog(false)}
					handleSave={submitFilteredObject}
				/>

				<AddDataWarehouseObjectsToPacketDialog
					toc={toc}
					packet={packetRow.current}
					sources={changeRequestSources.current}
				/>

				<UpdateDialog obj={sharedState.contextTop} currentMfi={sharedState.contextMfi} />

				<UserFolderDialog openExisting={openExisting} shouldOpen={!openObject} />

				<ReleaseDialog
					objectsToRelease={itemsToRelease}
					destinationModel={sharedState.destinationModel}
					release={release}
				/>
			</Panel>
		</PanelOverlay>
	);
};

export default CreatorPanel;

const TableOfContentsPanel = ({ children, elementId, ...other }) => {
	return (
		<Panel
			panelTitle={"Table of Contents"}
			id={elementId}
			menuItems={[
				{
					id: "table-of-contents-add-panel",
					label: "Add Panel",
					icon: <PlusIcon />,
					onClick: "",
				},
			]}
			panelGroup={"creator"}
		>
			{children}
		</Panel>
	);
};

const UserFolderDialog = ({ openExisting, shouldOpen = true }) => {
	const [userMfi, setUserMfi] = useState([]);
	const [open, setOpen] = useState(false);
	const [queryParams, setQueryParams] = useSearchParams();

	//Only want this to open the first time
	const openedDialogForWarehouse = useRef("");

	const sharedState = useTrackedState();

	useEffect(() => {
		if (sharedState.destinationModel.length > 0) {
			let destinationUuid = sharedState.destinationModel[0].uuid;
			if (openedDialogForWarehouse.current !== destinationUuid) openedDialogForWarehouse.current = "";
		}
		if (sharedState.destinationModel.length > 1 && !openedDialogForWarehouse.current && shouldOpen) {
			getUserMfiInDataWarehouse(sharedState).then(({ userMfi: mfi, dataWarehouse }) => {
				openedDialogForWarehouse.current = sharedState.destinationModel[0].uuid;
				if (mfi) setUserMfi(mfi);
				//TODO: Do we want to show the data-warehouse if the current user doesn't have a user folder?
				// else if(dataWarehouse)
				// 	setUserMfi(dataWarehouse);
				else return;

				setOpen(true);
			});
		}
	}, [sharedState.destinationModel?.length]);

	const rowSelected = (row) => {
		if (row) openExisting(row.standardObjectUuid, row.standardObjectVersionUuid);
		setOpen(false);
	};

	return (
		<SelectionDialog
			dialogTitle={"Open an object from your Developer Folder"}
			treeData={userMfi}
			treeTitle={"Data Warehouse"}
			rowSelected={rowSelected}
			open={open}
			submitButtonText={"Open"}
			cancelButtonText={"Close"}
			requireFullObject={true}
		/>
	);
};

const CreatorGrid = ({
	toc,
	showCreatorPanels,
	tocRowClicked: _tocRowClicked,
	checklistRow,
	checklist,
	updateTask,
	newModifiedWorkspacePanel,
	packet,
	triggerSave,
}) => {
	const [showPanel, setShowPanel] = useState("CKL");
	// const [versionControlLogChanges, setVersionControlLogChanges] = useState([]);

	//Store a ref to the ancestorObjects because there should always be some if we render the checklist and open points panel. And for some reason this doesn't get the state update
	const changeRequestFormMfi = useRef([]);
	const ancestorObjects = useRef([]);
	const openPointSheetTypeUuid = useRef("");
	const openPointTypeUuid = useRef("");
	const versionControlLogUuid = useRef("");
	const checklistTypeUuid = useRef("");
	const openPointSheetRef = useRef("");
	const openPointRef = useRef("");
	const versionControlLogMfi = useRef([]);
	const versionControlLogChanges = useRef([]);
	const versionControlLogRef = useRef({});
	const destinationModel = useRef([]);

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		getDbConstants();
	}, []);

	useEffect(() => {
		destinationModel.current = sharedState.destinationModel;
	}, [sharedState.destinationModel?.length]);

	useEffect(() => {
		//Every time the top changes and there are no ancestor objects reset to show the checklist panel
		let { contextAncestorObjects } = sharedState;
		//We have to check the ancestor objects because we could be in a packet and continuously loading sub-objects, in this case we don't want to reset
		if (contextAncestorObjects.length < 1) setShowPanel("CKL");
	}, [sharedState.contextTop?.uuid]);

	useEffect(() => {
		if (sharedState.contextAncestorObjects) ancestorObjects.current = sharedState.contextAncestorObjects;
	}, [sharedState.contextAncestorObjects, sharedState.contextAncestorObjects?.length]);

	const getDbConstants = async () => {
		checklistTypeUuid.current = await getDbConst("PRCSSOBJ", "processObject", sharedState, dispatch);
		openPointSheetTypeUuid.current = await getDbConst("OPS", "openPointSheetObject", sharedState, dispatch);
		openPointTypeUuid.current = await getDbConst("OP", "openPointObject", sharedState, dispatch);
		versionControlLogUuid.current = await getDbConst("VCL", "versionControlLog", sharedState, dispatch);

		getDbConst("CLASS", "classGeneralType", sharedState, dispatch);
	};

	const tocRowClicked = async (row, obj, taskRef = false) => {
		//Check if the row is the open point form, if so switch to show open points
		if (row.objectTypeUuid === openPointSheetTypeUuid.current) {
			openPointSheetRef.current = row;
			setShowPanel("OP");
		} else if (row.objectTypeUuid === checklistTypeUuid.current) {
			if (checklist.uuid !== row.uuid || checklist.versionUuid !== row.versionUuid) _tocRowClicked(row);
			setShowPanel("CKL");
		} else if (row.objectTypeUuid === openPointTypeUuid.current) {
			openPointRef.current = row;
			let hierarchyRecord = toc[0].objectHierarchy.find(
				(hierarchy) =>
					hierarchy.descendantStandardObjectUuid === row.uuid &&
					hierarchy.descendantStandardObjectVersionUuid === row.versionUuid
			);
			openPointRef.current.objectHierarchy = [hierarchyRecord];

			//Use toc to find the change request row, make a call to get its mfi
			if (changeRequestFormMfi.current.length == 0) {
				dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
				changeRequestFormMfi.current = await getSingleLevelObjectMfi(
					row.uuid,
					row.versionUuid,
					null,
					null,
					row
				);
				dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			}

			setShowPanel("CRF");
		} else if (row.objectTypeUuid === versionControlLogUuid.current) {
			let hierarchyRecord = toc[0].objectHierarchy.find(
				(hierarchy) =>
					hierarchy.descendantStandardObjectUuid === row.uuid &&
					hierarchy.descendantStandardObjectVersionUuid === row.versionUuid
			);
			row.objectHierarchy = [hierarchyRecord];

			//Use toc to find the change request row, make a call to get its mfi
			if (
				versionControlLogChanges.current.length === 0 ||
				versionControlLogRef.current.uuid !== row.uuid ||
				versionControlLogRef.current.versionUuid !== row.versionUuid
			) {
				dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
				let vcl = await getSingleLevelObjectMfi(row.uuid, row.versionUuid, null, null, row);
				versionControlLogMfi.current = vcl;
				//Get the list of changes from the vcl mfi
				let changeRow = vcl.find((item) => item.title === "Changes");
				let changes = vcl.filter((item) => item.parentUuid === changeRow.uuid);
				let results = await Promise.all(
					changes.map((change) => getSingleLevelObjectMfi(change.uuid, change.versionUuid))
				);
				versionControlLogChanges.current = results;
				dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
			}

			versionControlLogRef.current = row;
			setShowPanel("VCL");
		} else if (obj?.uuid === checklistRow.uuid) {
			setShowPanel("CKL");
			_tocRowClicked(row, obj);
		} else _tocRowClicked(row, null, taskRef);
	};

	const updateChangeRequestForm = (row) => {
		updateChangeData(
			dispatch,
			{ objectRows: [row], objectToUpdate: packet, subObjectToUpdate: openPointRef.current, primusRows: false },
			true
		);
	};

	const addNewChange = async () => {
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });
		//Find the change object in the data warehouse
		let changeObject = destinationModel.current.find(
			(row) => row.standardObjectUuid === sharedState.dbConstants.change.referenceUuid
		);
		let changeRow = versionControlLogMfi.current.find((row) => row.title === "Changes");
		let children = versionControlLogMfi.current.filter((row) => row.parentUuid === changeRow.uuid);
		let newRef;
		if (children.length > 0) newRef = getNextRef(children[children.length - 1].reference);
		else newRef = getNextRef(changeRow.reference + ".00");

		//Create a copy of the change object, add it as a sub-object
		let copy = await createNewObject({
			uuid: changeObject.standardObjectUuid,
			versionUuid: changeObject.standardObjectVersionUuid,
			userUuid: sharedState.currentUser?.uuid,
			ref: newRef,
			parent: changeRow,
		});

		//Create the hierarchy record
		let objectHierarchyRecord = createObjectHierarchyRecord(versionControlLogRef.current.objectHierarchy[0], copy);

		//Add row and hierarchy record to the state changes
		//Create the changes to pass to saveObject

		// await updateChangeData(dispatch, {
		// 	objectRows: [copy],
		// 	objectHierarchy: [objectHierarchyRecord],
		// 	subObjectToUpdate: versionControlLogRef.current,
		// });

		//Trigger save
		await triggerSave({
			newChange: copy,
			newHierarchyRecord: objectHierarchyRecord,
			versionControlLog: versionControlLogRef.current,
		});

		//Grab mfi for change
		let changeMfi = await getSingleLevelObjectMfi(copy.uuid, copy.versionUuid);

		//Add change mfi to list of changes
		versionControlLogChanges.current.push(changeMfi);
		dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
	};

	const updateVersionControlLog = (row, subObjectToUpdate) => {
		updateChangeData(dispatch, {
			subObjectToUpdate,
			objectRows: [row],
			objectToUpdate: packet,
		});
	};

	return (
		<ReactGrid
			layout={[
				//Table of Contents Panel
				{
					x: 0,
					y: 0,
					h: 1,
					w: 70,
					dynamicHeight: true,
					resizable: true,
					isBounded: true,
					i: "ae57d18e-ae15-4806-9a90-fa31d214712d",
					staticY: true,
				},
				//Checklist Panel
				{
					x: 70,
					y: 0,
					h: 1,
					w: 164,
					dynamicHeight: true,
					resizable: true,
					isBounded: true,
					i: "a7d08fe2-08e9-4b0d-b3fc-c509f7cb2075",
					staticY: true,
				},
				//New Modified Workspace Panel With Creator Checklist And Table Of Contents
				{
					x: 234,
					y: 0,
					h: 1,
					w: 606,
					dynamicHeight: true,
					resizable: true,
					isBounded: true,
					i: "969ec931-5445-4e6a-b2ef-af84646651f7",
					staticY: true,
				},
				//New Modified Workspace Panel Without Creator Checklist And Table Of Contents
				{
					x: 0,
					y: 0,
					h: 1,
					w: 840,
					dynamicHeight: true,
					resizable: true,
					isBounded: true,
					i: "ae57d18e-ae15-4806-9a90-fa31d9s0342d",
					staticY: true,
				},
			]}
			dynamicHeight={false}
			allowOverlap={true}
			draggableHandle={".panelHeader.creator"}
			draggable={sharedState.resizePanels && !sharedState.fullScreenVisualRep}
			id={"creator-panel-grid"}
		>
			<div className={showCreatorPanels ? "" : "d-none"} key={"ae57d18e-ae15-4806-9a90-fa31d214712d"}>
				{showCreatorPanels ? (
					<TableOfContentsPanel elementId={"creator-toc-panel"}>
						<RecursiveTreeView
							data={toc}
							treeTitle={toc[0]?.title || "Table of Contents"}
							topNodeId={toc[0]?.parentUuid}
							draggable={true}
							className={`tree`}
							origin={"toc-tree"}
							showVersions={true}
							rowSelected={tocRowClicked}
						/>
					</TableOfContentsPanel>
				) : (
					""
				)}
			</div>
			<div className={showCreatorPanels ? "" : "d-none"} key={"a7d08fe2-08e9-4b0d-b3fc-c509f7cb2075"}>
				{showCreatorPanels ? (
					showPanel === "CKL" ? (
						<ChecklistPanel
							checklistRow={checklistRow}
							tasks={checklist}
							updateTask={updateTask}
							toc={toc}
							loadTOCItem={tocRowClicked}
						/>
					) : showPanel === "OP" ? (
						<OpenPointPanel
							objectUuid={ancestorObjects.current[0]?.uuid}
							reference={openPointSheetRef.current.reference}
							openPointSheetUuid={openPointSheetRef.current.uuid}
							toc={toc}
							tocRowClick={tocRowClicked}
							checklist={checklistRow}
						/>
					) : showPanel === "CRF" ? (
						<Panel
							panelTitle={"Change Request Form"}
							// panelContentTitle={checklistRow.title}
							menuItems={[
								{
									id: "change-request-form-add-panel",
									label: "Add Panel",
									icon: <PlusIcon />,
									onClick: () => console.log("add panel"),
								},
							]}
							panelGroup={"creator"}
							panelStockNumber={
								<ObjectHeader reference={openPointRef.current.reference} referenceType={"PKT"} />
							}
						>
							<ChangeRequestForm
								openPoint={openPointRef.current}
								updateRow={updateChangeRequestForm}
								changeRequestFormMfi={changeRequestFormMfi.current}
							/>
						</Panel>
					) : showPanel === "VCL" ? (
						<Panel
							panelTitle={"Version Control Log"}
							menuItems={[
								{
									id: "change-request-form-add-panel",
									label: "Add Panel",
									icon: <PlusIcon />,
									onClick: () => console.log("add panel"),
								},
							]}
							panelGroup={"creator"}
							panelStockNumber={
								<ObjectHeader
									reference={versionControlLogRef.current.reference}
									referenceType={"PKT"}
								/>
							}
							quickActionItems={[
								{
									id: "vcl-quick-action-plus",
									title: "Create New Change",
									icon: <PlusIcon />,
									onClick: addNewChange,
								},
							]}
						>
							<VersionControlLog
								versionControlLog={versionControlLogRef.current}
								updateRow={updateVersionControlLog}
								changes={versionControlLogChanges.current}
							/>
						</Panel>
					) : (
						""
					)
				) : (
					""
				)}
			</div>
			{/*New Modified Workspace Panel not full width for when creator panels are shown*/}
			<div className={showCreatorPanels ? "" : "d-none"} key={"969ec931-5445-4e6a-b2ef-af84646651f7"}>
				{showCreatorPanels ? newModifiedWorkspacePanel : ""}
			</div>
			{/*New Modified Workspace Panel fills full width if creator panels are not shown*/}
			<div className={showCreatorPanels ? "d-none" : ""} key={"ae57d18e-ae15-4806-9a90-fa31d9s0342d"}>
				{!showCreatorPanels ? newModifiedWorkspacePanel : ""}
			</div>
		</ReactGrid>
	);
};

const ChecklistPanel = ({ checklistRow, tasks = [], updateTask, toc, loadTOCItem, ...other }) => {
	const [selectedTask, setSelectedTask] = useState({});
	const parentRef = React.useRef(null);
	const topRef = useRef({});
	const mfiRef = useRef({});
	const packetRef = useRef({});
	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		let uuid = sharedState.selectedWorkspaceRow?.uuid;
		if (uuid) {
			let task = tasks.find((row) => row._uuid === uuid);
			if (task) {
				setSelectedTask(task);

				setTimeout(() => {
					setSelectedTask({});
				}, 5000);
			}
		}
	}, [sharedState.selectedWorkspaceRow?.uuid]);

	useEffect(() => {
		topRef.current = sharedState.contextTop;
		mfiRef.current = sharedState.contextMfi;
	}, [sharedState.contextTop?.uuid, sharedState.contextTop?.versionUuid]);

	useEffect(() => {
		mfiRef.current = sharedState.contextMfi;
	}, [sharedState.contextMfiVersion]);

	useEffect(() => {
		if (sharedState.contextPacket?.uuid) packetRef.current = sharedState.contextPacket;
	}, [sharedState.contextPacket?.uuid]);

	const taskRefClicked = async (bmRef) => {
		let tocItem = toc.find((row) => row.uuid === bmRef.linkToAttributeUuid);

		if (!bmRef.objectHierarchy?.[0]) {
			if (tocItem) loadTOCItem(tocItem, null, true);
			return;
		}
		let newHierachyRecord = {
			pathEnum: bmRef.objectHierarchy[0].pathEnum,
		};

		//Check if the linked row is on the TOC and is an uploaded document, if so load it into the dialog

		//We need to get the uuid and version and see if the corresponding object is already open
		let associatdObjects = newHierachyRecord.pathEnum.split(".");
		let [uuid, versionUuid] = associatdObjects[associatdObjects.length - 1].split("/");
		if (!tocItem) tocItem = toc.find((row) => row.uuid === bmRef.objectHierarchy[0].descendantStandardObjectUuid);

		if (tocItem.uuid === topRef.current.uuid && tocItem.versionUuid === topRef.current.versionUuid) {
			//Update the selectedRow
			if (bmRef.linkToAttributeUuid) {
				let selectedRow = mfiRef.current.find((row) => row.uuid === bmRef.linkToAttributeUuid);
				if (selectedRow) {
					selectLinkedAttribute(mfiRef.current, bmRef.linkToAttributeUuid, sharedState, dispatch);
				}
			}

			return;
		} else if (tocItem) {
			loadTOCItem(tocItem, null, true);
			return;
		} else if (uuid === packetRef.current.uuid) return;

		let mfi = await getSingleLevelObjectMfiFromPath({ objectHierarchy: [newHierachyRecord] });

		//Check the TOC for the object, if it's on the TOC we can update the references
		let match = toc.find((row) => row.uuid === mfi[0].uuid && row.versionUuid === mfi[0].versionUuid);
		if (match)
			mfi.forEach((row, index) => {
				if (index === 0) row.reference = match.reference;
				else row.reference = match.reference + "." + row.reference;
			});

		//Get the objectHierarchy record for the top
		let path = mfi[0].objectHierarchy[0].pathEnum;
		let newAncestorObjects = getAncestorObjectsFromPath(path, sharedState.contextAncestorObjects);

		await dispatch({
			type: "SET_CONTEXT_OR_MFI",
			data: {
				top: {},
				mfi: [],
			},
		});

		await dispatch({
			type: "SET_CONTEXT_OR_MFI",
			data: {
				mfi,
				top: mfi[0],
				ancestorObjects: newAncestorObjects,
			},
		});

		if (bmRef.linkToAttributeUuid) {
			selectLinkedAttribute(mfi, bmRef.linkToAttributeUuid, sharedState, dispatch);
		}
	};

	const count = tasks?.length ?? 0;
	const virtualizer = useVirtualizer({
		count,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 100,
		overscan: 15,
	});

	const items = virtualizer.getVirtualItems();

	const [paddingTop, paddingBottom] =
		items.length > 0
			? [Math.max(0, items[0].start - 150), Math.max(0, virtualizer.getTotalSize() - items[items.length - 1].end)]
			: [0, 0];

	return (
		<Panel
			panelTitle={"Checklist"}
			panelContentTitle={checklistRow.title}
			panelStockNumber={<ObjectHeader reference={checklistRow?.reference} referenceType={"PKT"} />}
			menuItems={[
				{
					id: "checklist-toggle",
					icon: <TogglesIcon />,
				},
				{
					id: "checklist-add-panel",
					label: "Add Panel",
					icon: <PlusIcon />,
					onClick: "",
				},
			]}
			panelGroup={"creator"}
		>
			<div style={{ height: "100%", overflow: "auto" }} ref={parentRef}>
				<table className="table table-condensed table-bordered checklist">
					<thead>
						<tr>
							<th style={{ maxWidth: "38px" }} className="vertical">
								Final Review By
							</th>
							<th>Date</th>
							<th style={{ maxWidth: "38px" }} className="vertical">
								Reviewed By
							</th>
							<th>Date</th>
							<th style={{ maxWidth: "38px" }} className="vertical">
								Prepared By
							</th>
							<th>Date</th>
							<th style={{ width: "auto" }} className="vertical reference">
								Help / Explanation Ref
							</th>
							<th style={{ width: "auto" }} className="vertical reference">
								UBM Master File Index - Reference Manual Templates
							</th>
							<th style={{ width: "auto" }} className="vertical reference">
								BM/App Master File Index Ref
							</th>
							<th style={{ minWidth: "50px" }}>Ln #</th>
							<th style={{ minWidth: "350px" }}>Instruction Detail</th>
							<th className="vertical">Budgeted Hours</th>
							<th className="vertical">Actual Time</th>
							<th className="vertical">Review Time</th>
							<th className="vertical">Difference</th>
						</tr>
					</thead>
					<tbody style={{ height: "100%" }}>
						{tasks.length > 0 ? (
							<>
								{paddingTop > 0 && (
									<tr>
										<td colSpan={15} style={{ height: paddingTop }}></td>
									</tr>
								)}
								{items.map((virtualRow) => (
									<tr
										key={virtualRow.key}
										data-index={virtualRow.index}
										ref={virtualizer.measureElement}
										style={{
											backgroundColor:
												selectedTask?._uuid === tasks[virtualRow.index]._uuid
													? "rgba(63, 81, 181, 0.13)"
													: "",
										}}
									>
										<Task
											task={tasks[virtualRow.index]}
											updateTask={(row, value) => updateTask(row, value, tasks[virtualRow.index])}
											taskRefClicked={taskRefClicked}
										/>
									</tr>
								))}
								{paddingBottom > 0 && (
									<tr>
										<td colSpan={15} style={{ height: paddingBottom }}></td>
									</tr>
								)}
							</>
						) : (
							<tr>
								<td colSpan="15">No checklist items found</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</Panel>
	);
};

const Task = memo(
	({
		index,
		style,
		task: {
			_uuid,
			_versionUuid,
			_lineNumber,
			instructionDetail: _instructionDetail,
			budgetTime: _budgetTime,
			actualTime: _actualTime,
			reviewTime: _reviewTime,
			finalReviewer: _finalReviewer,
			finalReviewDate: _finalReviewDate,
			reviewer: _reviewer,
			reviewDate: _reviewDate,
			preparer: _preparer,
			prepareDate: _preparedDate,
			helpRef: _helpRef,
			templateRef: _templateRef,
			bmRef: _bmRef,
			type: taskType,
			section,
		},
		updateTask: _updateTask,
		taskRefClicked,
		...props
	}) => {
		const [finalReviewer, setFinalReviewer] = useState("");
		const [finalReviewDate, setFinalReviewDate] = useState("");
		const [reviewedBy, setReviewedBy] = useState("");
		const [reviewDate, setReviewDate] = useState("");
		const [preparedBy, setPreparedBy] = useState("");
		const [prepareDate, setPrepareDate] = useState("");
		const [actualHours, setActualHours] = useState("");
		const [reviewHours, setReviewHours] = useState("");

		const sharedState = useTrackedState();

		let rowBackgroundColor = "inherit";
		if (taskType?.value === "Phase") rowBackgroundColor = "#f5f5f5";

		useEffect(() => {
			if (!_uuid) return;

			setFinalReviewer(_finalReviewer?.value);
			setFinalReviewDate(_finalReviewDate?.value);
			setReviewedBy(_reviewer?.value);
			setReviewDate(_reviewDate?.value);
			setPreparedBy(_preparer?.value);
			setPrepareDate(_preparedDate?.value);
			setActualHours(_actualTime?.value);
			setReviewHours(_reviewTime?.value);

			if (
				_finalReviewer === undefined ||
				_finalReviewDate === undefined ||
				_reviewer === undefined ||
				_reviewDate === undefined ||
				_preparer === undefined ||
				_preparedDate === undefined ||
				_actualTime === undefined ||
				_reviewTime === undefined
			) {
				console.warn(
					`Task ${_lineNumber} ${_instructionDetail?.value} is missing the following required colmuns: ${
						_finalReviewer === undefined ? "_finalReviewer" : ""
					} ${_finalReviewDate === undefined ? "_finalReviewDate" : ""} ${
						_reviewer === undefined ? "_reviewer" : ""
					} ${_reviewDate === undefined ? "_reviewDate" : ""} ${_preparer === undefined ? "_preparer" : ""} ${
						_preparedDate === undefined ? "_preparedDate" : ""
					} ${_actualTime === undefined ? "_actualTime" : ""} ${
						_reviewTime === undefined ? "_reviewTime" : ""
					}`
				);
			}
		}, [_uuid]);

		const updateTask = (row, newVal) => {
			_updateTask(row, newVal);
		};

		return (
			<>
				{taskType?.value === "Phase" || section?.value ? (
					<>
						<td
							colSpan={9}
							style={{
								fontWeight: 900,
								fontSize: "18px",
								backgroundColor: rowBackgroundColor,
							}}
						>
							{_instructionDetail?.value}
						</td>
						<td style={{ backgroundColor: rowBackgroundColor }}>{_lineNumber}</td>
						<td style={{ backgroundColor: rowBackgroundColor }}></td>
					</>
				) : (
					<>
						{/*Final Reviewer*/}
						<td>
							{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
								label: "",
								value: finalReviewer,
								handleChange: (value) => {
									setFinalReviewer(value);
									if (!finalReviewDate) {
										let date = formatDate(new Date(), true);
										setFinalReviewDate(date);
										updateTask(_finalReviewDate, date);
									}
								},
								handleBlur: (value) => {
									updateTask(_finalReviewer, value);
								},
								invalid: "",
								focus: false,
								defaultValue: sharedState.currentUser
									? sharedState.currentUser.firstName[0] + sharedState.currentUser.lastName[0]
									: "",
								classes: "checklist-input fill-cell",
							})}
						</td>
						{/*Final Review Date*/}
						<td>
							{INPUT_FIELD_TYPES.DATE.render({
								label: "",
								value: finalReviewDate,
								handleChange: (value) => {
									setFinalReviewDate(value);
									updateTask(_finalReviewDate, value);
								},
								handleBlur: () => {},
								invalid: "",
								focus: false,
								defaultValue: formatDate(new Date(), true),
								classes: "checklist-input",
							})}
						</td>
						{/*Reviewed By*/}
						<td>
							{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
								label: "",
								value: reviewedBy,
								handleChange: (value) => {
									setReviewedBy(value);
									if (!reviewDate) {
										let date = formatDate(new Date(), true);
										setReviewDate(date);
										updateTask(_reviewDate, date);
									}
								},
								handleBlur: (value) => {
									updateTask(_reviewer, value);
								},
								invalid: "",
								focus: false,
								defaultValue: sharedState.currentUser
									? sharedState.currentUser.firstName[0] + sharedState.currentUser.lastName[0]
									: "",
								classes: "checklist-input fill-cell",
							})}
						</td>
						{/*Review Date*/}
						<td>
							{INPUT_FIELD_TYPES.DATE.render({
								label: "",
								value: reviewDate,
								handleChange: (value) => {
									setReviewDate(value);
									updateTask(_reviewDate, value);
								},
								handleBlur: () => {},
								invalid: "",
								focus: false,
								defaultValue: formatDate(new Date(), true),
								classes: "checklist-input",
							})}
						</td>
						{/*Prepared By*/}
						<td>
							{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
								label: "",
								value: preparedBy,
								handleChange: (value) => {
									setPreparedBy(value);
									if (!prepareDate) {
										let date = formatDate(new Date(), true);
										setPrepareDate(date);
										updateTask(_preparedDate, date);
									}
								},
								handleBlur: (value) => {
									updateTask(_preparer, value);
								},
								invalid: "",
								focus: false,
								defaultValue: sharedState.currentUser
									? sharedState.currentUser.firstName[0] + sharedState.currentUser.lastName[0]
									: "",
								classes: "checklist-input fill-cell",
							})}
						</td>
						{/*Prepare Date*/}
						<td>
							{INPUT_FIELD_TYPES.DATE.render({
								label: "",
								value: prepareDate,
								handleChange: (value) => {
									setPrepareDate(value);
									updateTask(_preparedDate, value);
								},
								handleBlur: () => {},
								invalid: "",
								focus: false,
								defaultValue: formatDate(new Date(), true),
								classes: "checklist-input",
							})}
						</td>
						{/*Help Ref*/}
						<td style={{ color: "red" }} title={_helpRef?.value} className={"reference"}>
							{_helpRef.objectHierarchy?.length > 0 ? (
								<button
									style={{
										width: "100%",
										height: "100%",
										border: "none",
										borderRadius: "unset",
									}}
									type={"button"}
									className={"btn btn-outline-danger"}
									onClick={() => taskRefClicked(_helpRef)}
								>
									{_helpRef
										? sharedState.showFullRef
											? _helpRef.value
											: getSmallRef(_helpRef.value)
										: ""}
								</button>
							) : _helpRef ? (
								sharedState.showFullRef ? (
									_helpRef.value
								) : (
									getSmallRef(_helpRef.value)
								)
							) : (
								""
							)}
						</td>
						{/*Template Ref*/}
						<td style={{ color: "red" }} className={"reference"} title={_templateRef?.value}>
							{_templateRef.objectHierarchy?.length > 0 ? (
								<button
									style={{
										width: "100%",
										height: "100%",
										border: "none",
										borderRadius: "unset",
									}}
									type={"button"}
									className={"btn btn-outline-danger"}
									onClick={() => taskRefClicked(_templateRef)}
								>
									{_templateRef
										? sharedState.showFullRef
											? _templateRef.value
											: getSmallRef(_templateRef.value)
										: ""}
								</button>
							) : _templateRef ? (
								sharedState.showFullRef ? (
									_templateRef.value
								) : (
									getSmallRef(_templateRef.value)
								)
							) : (
								""
							)}
						</td>
						<td
							style={{ color: "red", textAlign: "center", verticalAlign: "middle" }}
							title={_bmRef?.value}
							className={"reference"}
						>
							{/*<button*/}
							{/*	style={{*/}
							{/*		width: "100%",*/}
							{/*		height: "100%",*/}
							{/*		border: "none",*/}
							{/*		borderRadius: "unset",*/}
							{/*	}}*/}
							{/*	type={"button"}*/}
							{/*	className={"btn btn-outline-danger"}*/}
							{/*	onClick={() => taskRefClicked(_bmRef)}*/}
							{/*>*/}
							{/*	{_bmRef ? (sharedState.showFullRef ? _bmRef.value : getSmallRef(_bmRef.value)) : ""}*/}
							{/*</button>*/}
							{_bmRef.objectHierarchy?.length > 0 ? (
								<button
									style={{
										width: "100%",
										height: "100%",
										border: "none",
										borderRadius: "unset",
									}}
									type={"button"}
									className={"btn btn-outline-danger"}
									onClick={() => taskRefClicked(_bmRef)}
								>
									{_bmRef ? (sharedState.showFullRef ? _bmRef.value : getSmallRef(_bmRef.value)) : ""}
								</button>
							) : _bmRef ? (
								sharedState.showFullRef ? (
									_bmRef.value
								) : (
									getSmallRef(_bmRef.value)
								)
							) : (
								""
							)}
						</td>
						<td>{_lineNumber}</td>
						<td>{_instructionDetail?.value}</td>
					</>
				)}
				{/*Budget Time*/}
				<td
					style={{
						backgroundColor: rowBackgroundColor,
					}}
				>
					{_budgetTime?.value}
				</td>
				{/*Actual Hours*/}
				<td
					style={{
						backgroundColor: rowBackgroundColor,
					}}
				>
					{INPUT_FIELD_TYPES.NUMERIC.render({
						label: "",
						value: actualHours,
						handleChange: (value) => setActualHours(value),
						handleBlur: (value) => {
							updateTask(_actualTime, value);
						},
						invalid: "",
						focus: false,
						classes: "checklist-input",
					})}
				</td>
				{/*Review Hours*/}
				<td
					style={{
						backgroundColor: rowBackgroundColor,
					}}
				>
					{INPUT_FIELD_TYPES.NUMERIC.render({
						label: "",
						value: reviewHours,
						handleChange: (value) => setReviewHours(value),
						handleBlur: (value) => {
							updateTask(_reviewTime, value);
						},
						invalid: "",
						focus: false,
						classes: "checklist-input",
					})}
				</td>
				{/*Budget Time*/}
				<td
					style={{
						backgroundColor: rowBackgroundColor,
					}}
				>
					{_budgetTime?.value - actualHours - reviewHours}
				</td>
			</>
		);
	},
	(prevProps, nextProps) => {
		let prevTask = prevProps.task;
		let nextTask = nextProps.task;

		return prevTask._uuid === nextTask._uuid && prevTask._versionUuid === nextTask._versionUuid;
	}
);

const ObjectSetupFormDialog = ({
	open,
	object,
	submitNewObject,
	clearTransactionObject,
	objSetupFormNewVersion,
	...other
}) => {
	const [showDialog, setShowDialog] = useState(false);
	const [obj, setObj] = useState({});

	useEffect(() => {
		if (object) setObj(object);
	}, [object]);

	useEffect(() => {
		//TODO: There may be an issue with this, may want this to be completely controlled by the sharedState
		setShowDialog(open);
	}, [open]);

	const handleDialogClose = () => {
		setShowDialog(false);
		clearTransactionObject();
	};

	return (
		<DraggableDialog
			id={"object-setup-form-dialog"}
			style={{ minHeight: "200px", margin: "0 auto", width: "800px" }}
			fullWidth={true}
			showDialog={showDialog}
			handleClose={(e) => {
				handleDialogClose();
			}}
			handleSave={() => submitNewObject(obj)}
			header={"Create New Object"}
			PaperProps={{ style: { maxWidth: "1300px" } }}
		>
			{objSetupFormNewVersion ? (
				<h4
					style={{
						padding: "30px 40px 0px",
						fontSize: "24px",
						fontWeight: "300",
						lineHeight: "30px",
						color: "red",
						textAlign: "center",
					}}
				>
					You made changes to an object you don't have write access to.
					<br /> Would you like to create your own copy?
				</h4>
			) : (
				""
			)}
			<ObjectSetupForm updateObject={(obj) => setObj(obj)} object={obj} />
		</DraggableDialog>
	);
};

/**
 * This dialog lets you drag an object to somewhere in your data warehouse and select the environment to release it to
 * Alpha, Beta, Production
 * @returns {JSX.Element}
 * @constructor
 */
const ReleaseDialog = ({ objectsToRelease, destinationModel, release }) => {
	const [releaseNewDialogOpen, setReleaseNewDialogOpen] = useState(false);
	const [releaseUpdateDialogOpen, setReleaseUpdateDialogOpen] = useState(false);
	const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);
	const [latestReleaseVersions, setLatestReleaseVersions] = useState(new Map());
	const [invalidVersionNumbers, setInvalidVersionNumbers] = useState([]);

	const changes = useRef([]);
	const releasedObjects = useRef([]);
	const sharedState = useTrackedState();

	useEffect(() => {
		if (objectsToRelease.length > 0) getLatestReleaseVersions();
	}, [objectsToRelease.length]);

	useEffect(() => {
		if (sharedState.releaseDialog) setReleaseDialogOpen((prev) => !prev);
	}, [sharedState.releaseDialog]);

	const getLatestReleaseVersions = async () => {
		let map = new Map();
		let invalid = [];
		//For each object get the latest release version and add it to the objectReleaseVersions map
		for (let i in objectsToRelease) {
			let obj = objectsToRelease[i];
			let alphaKey = obj.versionUuid + objectStates.ALPHA;
			let betaKey = obj.versionUuid + objectStates.BETA;
			let productionKey = obj.versionUuid + objectStates.PRODUCTION;
			//Get the latest release version for Alpha
			let alphaVersion = await getLatestEnvironmentReleaseVersion(obj.uuid, objectStates.ALPHA);
			//Get the latest release version for Beta
			let betaVersion = await getLatestEnvironmentReleaseVersion(obj.uuid, objectStates.BETA);
			//Get the latest release version for Production
			let prodVersion = await getLatestEnvironmentReleaseVersion(obj.uuid, objectStates.PRODUCTION);

			map.set(alphaKey, alphaVersion);
			map.set(betaKey, betaVersion);
			map.set(productionKey, prodVersion);

			let latest = map.get(obj.versionUuid + obj.versionControl.objectState);
			if (latest) {
				let newVersionNumber = latest.objectVersionNumber.split(".");
				newVersionNumber[2] = Number(newVersionNumber[2]) + 1;
				obj.versionControl.objectVersionNumber = newVersionNumber.join(".");
			}
		}
		setInvalidVersionNumbers(invalid);
		setLatestReleaseVersions(map);
	};

	const handleRelease = () => {
		// console.log('release to', environment);
		setReleaseDialogOpen(false);
		setReleaseNewDialogOpen(true);
	};

	const getChanges = (changeMap) => {
		changes.current = [...changeMap.values()];
	};

	const handleVersionChange = (uuid, attribute, newVal) => {
		let matchingRow = objectsToRelease.find((top) => top.uuid === uuid);
		let latestRelease;
		switch (attribute) {
			case "objectVersionNumber":
				//Check if the value is valid, it needs to be ahead of the latest release version for this environment in order to be valid
				//Get the latest version number for this environment
				latestRelease = latestReleaseVersions.get(
					matchingRow.versionUuid + matchingRow.versionControl.objectState
				);

				//Check if new value is ahead and therefore valid
				if (latestRelease && newVal < latestRelease.objectVersionNumber) {
					updateInvalidVersionNumbers(matchingRow.versionUuid, true);
					return;
				}

				matchingRow.versionControl.objectVersionNumber = newVal;
				updateInvalidVersionNumbers(matchingRow.versionUuid, false);
				break;
			case "objectState":
				matchingRow.versionControl.objectState = newVal;

				latestRelease = latestReleaseVersions.get(matchingRow.versionUuid + newVal);
				//Check if new value is ahead and therefore valid
				if (latestRelease && matchingRow.versionControl.objectVersionNumber < latestRelease.objectVersionNumber)
					updateInvalidVersionNumbers(matchingRow.versionUuid, true);
				else updateInvalidVersionNumbers(matchingRow.versionUuid, false);
				break;
		}
	};

	const updateInvalidVersionNumbers = (objVersionUuid, add = true) => {
		let updated = new Set(invalidVersionNumbers);
		if (add) updated.add(objVersionUuid);
		else updated.delete(objVersionUuid);
		setInvalidVersionNumbers([...updated]);
	};

	/**
	 *
	 * @param uuid
	 * @param newVal
	 */
	const handleReleaseTypeChange = (row, newVal) => (row.dropType = newVal);

	const handleTwoTreeDialogClose = () => {
		setReleaseNewDialogOpen(false);
		setReleaseUpdateDialogOpen(false);
		setReleaseDialogOpen(true);

		//Reset changes and remove any added rows from destination model
		if (changes.current.length > 0) {
			//Remove any rows that were potentially added to the destination by finding changed rows that have the standard object uuid and version of the objects to release and remove them
			objectsToRelease.forEach(({ top }) => {
				let match = destinationModel.findIndex(
					(row) => row.standardObjectUuid === top.uuid && row.standardObjectVersionUuid === top.versionUuid
				);
				if (match) {
					deleteRow(destinationModel[match], destinationModel);
					destinationModel.splice(match, 1);
				}
			});
			changes.current = [];
		}
	};

	const handleSave = (changeMap) => {
		//If an object was dropped on an object with the same uuid but different version we want to update that MFI record to point to the new object rather than creating a new MFI record
		//So we'd update the MFI row that was dropped on and remove the one created inside it from the changes
		let objectIds = objectsToRelease.map((top) =>
			getObjectIdAndVersionUuid({ uuid: top.standardObjectUuid, versionUuid: top.standardObjectVersionUuid })
		);

		//Save the objects (which in this case would be the tops in the objectsToRelease)

		//Save the MFI rows, I might be able to just call a method from creator panel that will convert everything for me
		let usedMfiRows = [];
		objectsToRelease.forEach((top) => {
			let mfiRow = changes.current.find(
				(row) => row.standardObjectUuid === top.uuid && row.standardObjectVersionUuid === top.versionUuid
			);
			if (mfiRow) {
				release.changedMfiRows = [mfiRow];
				usedMfiRows.push(mfiRow.uuid);
			} else releasedObjects.current.push(top);
		});

		// objectsToRelease[0].changedMfiRows = [
		// 	...objectsToRelease[0].changedMfiRows,
		// 	...changes.current.filter((row) => !usedMfiRows.includes(row.uuid)),
		// ];
		release(objectsToRelease, changes.current);

		//Reset the dialog and changes
		setReleaseNewDialogOpen(false);
		setReleaseUpdateDialogOpen(false);
		changes.current = [];
	};

	const cancelRelease = () => {
		setReleaseDialogOpen(false);
	};

	const rowUpdate = (releaseObject, mfiRow) => {
		mfiRow.standardObjectVersionUuid = releaseObject.versionUuid;
	};

	return (
		<>
			{/*Release new objects*/}
			<TwoTreeDialog
				open={releaseNewDialogOpen}
				treeData1={objectsToRelease?.filter((row) => row.dropType !== "0") || []}
				treeData2={destinationModel}
				tooltipHelpInstructions={"Drag objects from the left tree to the appropriate location on the right."}
				saveButtonText={"Release"}
				saveChanges={handleSave}
				cancel={handleTwoTreeDialogClose}
				header={`Release Object(s) to the Data Warehouse`}
				getChanges={getChanges}
				allAreRequired={true}
				allowObjectUpdatesToRightTree={true}
			/>

			{/*Release objects as updates*/}
			{/*<TwoTreeDialog*/}
			{/*	open={releaseUpdateDialogOpen}*/}
			{/*	treeData1={objectsToRelease.filter((row) => row.releaseType === "update") || []}*/}
			{/*	treeData2={destinationModel}*/}
			{/*	saveButtonText={"Release"}*/}
			{/*	saveChanges={handleSave}*/}
			{/*	cancel={handleTwoTreeDialogClose}*/}
			{/*	header={`Select the Existing Entries to Update`}*/}
			{/*	getChanges={getChanges}*/}
			{/*	allAreRequired={true}*/}
			{/*	addRowsToRight={false}*/}
			{/*	rightTreeButtons={[*/}
			{/*		{*/}
			{/*			text: "Update",*/}
			{/*			classes: "btn btn-outline-primary",*/}
			{/*			onClick: rowUpdate,*/}
			{/*		},*/}
			{/*	]}*/}
			{/*/>*/}

			<DraggableDialog
				dialogWidth={"800px"}
				header={"Choose the Release Environment"}
				showDialog={releaseDialogOpen}
				handleSave={handleRelease}
				handleClose={cancelRelease}
				saveButtonText={"Release"}
				disableSave={invalidVersionNumbers.length > 0}
			>
				<ul className={"list-group"}>
					{objectsToRelease.map((top) => (
						<li
							key={top.uuid + top.versionUuid}
							className={"d-flex list-group-item"}
							style={{
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "5px",
							}}
						>
							<h5 style={{ marginRight: "10px", width: "450px" }}>{top.title}:</h5>
							<div className={"d-flex"} style={{ alignItems: "center" }}>
								{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
									label: "Version:",
									title: "Version Number",
									value: top.versionControl.objectVersionNumber,
									handleBlur: (newVal) =>
										handleVersionChange(top.uuid, "objectVersionNumber", newVal),
									inputStyles: { width: "75px", margin: "0px 10px 0px 5px" },
									invalid: invalidVersionNumbers.includes(top.versionUuid),
									onHoverText: `The latest released version in ${top.versionControl.objectState} is ${
										latestReleaseVersions.get(top.versionUuid + top.versionControl.objectState)
											?.objectVersionNumber
									}`,
								})}
							</div>
							{INPUT_FIELD_TYPES.DROP_DOWN.render({
								label: "Env:",
								value: top.versionControl.objectState,
								optionGroups: [
									{
										options: [
											{
												label: objectStates.ALPHA,
												value: objectStates.ALPHA,
											},
											{
												label: objectStates.BETA,
												value: objectStates.BETA,
											},
											{
												label: objectStates.PRODUCTION,
												value: objectStates.PRODUCTION,
											},
										],
									},
								],
								divStyle: { display: "flex", alignItems: "center", marginLeft: "20px" },
								selectStyle: { marginLeft: "5px", width: "100px" },
								handleChange: (newVal) => handleVersionChange(top.uuid, "objectState", newVal),
							})}
							{INPUT_FIELD_TYPES.DROP_DOWN.render({
								label: "",
								value: top.dropType || "new",
								optionGroups: [
									{
										options: [
											{
												label: "No Release",
												value: "0",
											},
											{
												label: "New Entry",
												value: "new",
											},
											{
												label: "Update Existing Entry",
												value: "update",
											},
										],
									},
								],
								divStyle: { display: "flex", alignItems: "center" },
								selectStyle: { marginLeft: "20px", width: "150px" },
								handleChange: (newVal) => handleReleaseTypeChange(top, newVal),
							})}
						</li>
					))}
				</ul>
			</DraggableDialog>
		</>
	);
};

const UploadToDataWarehouseDialog = ({
	open,
	newObjTransaction,
	setObjectToUpload,
	objReadyToUpload,
	addChangedRows,
	upload,
	uploadCancel,
	...other
}) => {
	const [draggableIds, setDraggableIds] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [validDrop, setValidDrop] = useState(false);
	const [userMfi, setUserMfi] = useState([]);

	const alreadyDroppedUuids = useRef([]);
	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	useEffect(() => {
		let ids = [];
		if (newObjTransaction.current.context) {
			if (newObjTransaction.current.context.uuid) ids.push(newObjTransaction.current.context.uuid);
			else if (newObjTransaction.current.context.length > 0)
				ids = newObjTransaction.current.context.map((row) => row.uuid);
		}

		if (newObjTransaction.current.uploadObject) ids.push(newObjTransaction.current.uploadObject.uuid);

		setDraggableIds(ids);
	}, [newObjTransaction.current?.context]);

	useEffect(() => {
		//TODO: There may be an issue with this, may want this to be completely controlled by the sharedState
		setShowDialog(open);
	}, [open]);

	useEffect(() => {
		if (sharedState.destinationModel.length > 1) getUserMfi();
	}, [sharedState.destinationModel.length]);

	//Build the user's MFI grabbing the Developer Folder structure from the data warehouse
	const getUserMfi = async () => {
		let inProcessFolderType = await getDbConst("IPFLD", "inProcessFolderType", sharedState, dispatch);
		//We modify the destination model we store in the global state to move objects sent for review to the applicable folders
		//Because of this when we are choosing where to place a new object in our folder, grab the current data warehouse without moving things around
		let destinationModelMfi = await getMasterFileIndex(sharedState.destinationUuid);
		let { inProcessMfi, userMfi, developerMfi, developerFolder, dataWarehouse } = await getUserMfiInDataWarehouse(
			sharedState,
			destinationModelMfi
		);

		let developerSection, userFolder;
		let userName = `${sharedState.currentUser?.firstName} ${sharedState.currentUser?.lastName}`;
		//For now if we don't find the developer section we will default to the entire datwarehouse, we don't want this long term though
		if (!developerMfi) {
			setUserMfi(dataWarehouse);
			return;
		} else developerSection = developerMfi[0];
		//If there's not a user folder, create one
		if (!userMfi) {
			//Create user folder
			let siblings = developerMfi.filter((row) => row.parentUuid === developerSection.uuid);
			let newRow;
			if (siblings.length > 0) newRow = { ...siblings[siblings.length - 1] };
			else {
				newRow = { ...developerSection };
				newRow.generalTypes = null;
				newRow.parentUuid = developerSection.uuid;
				newRow.reference = developerSection.reference + ".00";
			}

			newRow.uuid = uuidv4();
			let newRef = getNextRef(newRow.reference);
			if (sharedState.currentUser) newRow.title = userName;
			newRow.reference = newRef.reference;
			newRow.referenceNo = newRef.referenceNo;
			newRow.location = sharedState.currentUser?.uuid;

			let newMfi = [];
			if (developerFolder) {
				let mfiToCopy = developerMfi.filter(
					(row) => row.reference.startsWith(developerFolder.reference) && row.uuid !== developerFolder.uuid
				);
				newMfi = copyMasterFileIndexRows(mfiToCopy, developerFolder.uuid, newRow.uuid, newRow.reference);
			}
			newMfi.push(newRow);

			developerMfi = [...developerMfi, ...newMfi];
			developerMfi.sort(sortByReference);

			userFolder = newRow;

			addChangedRows(newMfi.map((row) => ({ ...row, mfiRow: true })));
		} else userFolder = userMfi[0];

		let inProcess = developerMfi.find(
			(row) => row.reference.startsWith(userFolder.reference) && row.mfiGeneralTypeUuid === inProcessFolderType
		);
		if (inProcess) setUserMfi(developerMfi.filter((row) => row.reference.startsWith(inProcess.reference)));
		else setUserMfi(developerMfi.filter((row) => row.reference.startsWith(userFolder.reference)));
	};

	const updateRow = (uuid, attribute, value, treeNode) => {
		//Find the object and update its title
		let objectRow = newObjTransaction.current.context.find((row) => row.uuid === uuid);
		objectRow.title = value;

		//If the master file index row was already created update that as well
		let mfiRow = newObjTransaction.current.changedMfiRows?.find(
			(row) => row.standardObjectUuid === uuid && row.standardObjectVersionUuid === objectRow.versionUuid
		);
		if (mfiRow) {
			mfiRow.title = value;
			userMfi.find(
				(row) => (row.standardObjectUuid = uuid && row.standardObjectVersionUuid === objectRow.versionUuid)
			).title = value;
		}
	};

	const handleCancel = () => {
		setShowDialog(false);
		setValidDrop(false);
		uploadCancel();
		alreadyDroppedUuids.current = [];
	};

	const getChanges = (changeMap) => {
		setValidDrop(true);
		//Get the object from the changes and send that as the objdct ready to upload
		let arr = [...changeMap.values()];
		arr.forEach((row) => (row.mfiRow = true));
		addChangedRows(arr);

		//Get the object that was just uploaded
		let objects = [];
		if (Array.isArray(newObjTransaction.current.context)) objects = newObjTransaction.current.context;
		else objects.push(newObjTransaction.current.context);

		let objectUuids = objects.map((row) => getObjectIdAndVersionUuid(row));

		let obj = arr.find((row) => {
			//The changes coming back are master file index rows not object rows so we have to reference its standardObjectUuid and version
			let idAndVersion = getObjectIdAndVersionUuid({
				uuid: row.standardObjectUuid,
				versionUuid: row.standardObjectVersionUuid,
			});
			let match = objectUuids.includes(idAndVersion) && !alreadyDroppedUuids.current.includes(idAndVersion);
			alreadyDroppedUuids.current.push(idAndVersion);
			return match;
		});

		objReadyToUpload(
			objects.find(
				(row) => row.uuid === obj.standardObjectUuid && row.versionUuid === obj.standardObjectVersionUuid
			)
		);
	};

	const save = () => {
		setValidDrop(false);
		alreadyDroppedUuids.current = [];
		upload();
	};

	return (
		<TwoTreeDialog
			treeData1={
				newObjTransaction.current.context && newObjTransaction.current.context.uuid
					? [newObjTransaction.current.context]
					: newObjTransaction.current.context && newObjTransaction.current.context.length > 0
					? newObjTransaction.current.context
					: [sharedState.contextTop]
			}
			treeData2={userMfi}
			getChanges={getChanges}
			saveChanges={save}
			cancel={handleCancel}
			showDialog={showDialog}
			editableLeftTree={Array.isArray(newObjTransaction.current.context)}
			updateRow={updateRow}
			header={"Upload to Data Warehouse"}
			tooltipHelpInstructions={
				Array.isArray(newObjTransaction.current.context)
					? "Double click on the objects on the left to modify their title."
					: "Drag the new objects into the destination model on the right."
			}
		/>
	);
};

export const getPacketObjectDbConst = async (sharedState, dispatch) => {
	if (!sharedState.dbConstants.packetObject) {
		let packetObject = await getCall(getUrl("getDbConstByRef", ["PK"]));
		dispatch({
			type: "SET_DB_CONSTANT",
			constant: "packetObject",
			data: packetObject,
		});
		return packetObject;
	} else {
		return sharedState.dbConstants.packetObject;
	}
};

export const getTopMostObject = (sharedState) => {
	let {
		contextCurrentRelatedObject: currentRelatedObject,
		contextTop: top,
		contextPacket: packet,
		contextAncestorObjects: ancestorObjects,
	} = sharedState;

	//Get the most top object
	if (currentRelatedObject?.uuid) top = currentRelatedObject;
	else if (ancestorObjects && ancestorObjects.length > 0) top = ancestorObjects[0];
	else if (packet?.uuid) top = packet;

	return top;
};

export const getUrlParams = (queryParams) => {
	let uuid = queryParams.get("uuid");
	let versionUuid = queryParams.get("versionUuid");
	let path = queryParams.get("path");
	let reference = queryParams.get("ref");
	let debug = queryParams.get("debug");
	let focus = queryParams.get("focus");

	if (debug === "true") debug = true;
	else debug = false;
	//The query params put a '%' in place of the slash, convert it back
	if (path) path = path.replace("%", "/");

	return {
		uuid,
		versionUuid,
		path,
		reference,
		debug,
		focus,
	};
};

export const selectWorkspaceRow = (selectedRow, dispatch) => {
	dispatch({ type: "SET_SELECTED_WORKSPACE_ROW", data: selectedRow });
};

export const getAncestorObjectsFromPath = async (path, currentAncestorObjects, dispatch) => {
	//TODO: Does this need to be updated to account for related objects?
	let splitPath = path.split(".");
	//Remove the last element because that is the object that is being opeened. It's not supposed to be an ancestor object
	splitPath.pop();

	let idAndVersions = currentAncestorObjects.map((row) => getObjectIdAndVersionUuid(row));

	let newAncestorObjects = [];
	let promises = [];
	//For each object, get it's git record, build it's hierarchy record, and add it as an ancestor object so it will show up in the breadcrumbs
	splitPath.forEach((split, index) => {
		let [uuid, versionUuid] = split.split("/");

		if (idAndVersions.includes(split))
			newAncestorObjects.push(
				currentAncestorObjects.find((row) => row.uuid === uuid && row.versionUuid === versionUuid)
			);
		else {
			promises.push(getObjectGitRecord(uuid, versionUuid));
			newAncestorObjects.push({ uuid, versionUuid });
		}
	});

	//reset the top and mfi
	if (dispatch)
		await dispatch({
			type: "SET_CONTEXT_OR_MFI",
			data: {
				top: {},
				mfi: [],
			},
		});

	let res = await Promise.all(promises);
	res.forEach((git) => {
		let index = newAncestorObjects.findIndex((row) => row.uuid === git.uuid && row.versionUuid === git.versionUuid);
		newAncestorObjects.splice(index, 1, git);
	});

	return newAncestorObjects;
};

export const checkIfUuidAndVersionIsAlreadyLoaded = (uuid, versionUuid, sharedState) => {
	if (
		(uuid === sharedState.contextPacket?.uuid && versionUuid == sharedState.contextPacket?.versionUuid) ||
		(uuid === sharedState.contextTop?.uuid && versionUuid === sharedState.contextTop?.versionUuid) ||
		(sharedState.contextAncestorObjects &&
			sharedState.contextAncestorObjects[0] &&
			sharedState.contextAncestorObjects[0].uuid === uuid &&
			sharedState.contextAncestorObjects[0].versionUuid === versionUuid)
	)
		return true;
	else return false;
};

export const getUserMfiInDataWarehouse = async (sharedState, dataWarehouse) => {
	let developerFolderTypeUuid = await getDbConst("DEVFLD", "developerFolderType", sharedState);
	let developerTypeUuid = await getDbConst("DEVDFN", "developerType", sharedState);
	let inProcessFolderType = await getDbConst("IPFLD", "inProcessFolderType", sharedState);

	if (!developerTypeUuid) return {};

	//Get the current data warehouse
	let currentDw;
	if (!dataWarehouse) currentDw = sharedState.destinationModel;
	else currentDw = dataWarehouse;

	let developerSection = currentDw.find((row) => row.mfiGeneralTypeUuid === developerTypeUuid);
	let developerMfi = currentDw.filter((row) => row.reference.startsWith(developerSection?.reference));
	let developerFolder = currentDw.find((row) => row.mfiGeneralTypeUuid === developerFolderTypeUuid);

	let userName = `${sharedState.currentUser?.firstName} ${sharedState.currentUser?.lastName}`;
	//Get the logged in users folder
	let userFolder = developerMfi.find(
		(row) =>
			row.parentUuid === developerSection?.uuid &&
			row.title === userName &&
			row.location === sharedState.currentUser?.uuid
	);
	//For now if we don't find the developer section we will default to the entire datwarehouse, we don't want this long term though
	if (!developerSection) {
		return { dataWarehouse: currentDw };
	}
	//If there's not a user folder, create one
	else if (!userFolder) {
		return { developerMfi, developerFolder, dataWarehouse: currentDw };
	}

	let inProcess = developerMfi.find(
		(row) => row.reference.startsWith(userFolder.reference) && row.mfiGeneralTypeUuid === inProcessFolderType
	);

	if (inProcess)
		return {
			userMfi: developerMfi.filter((row) => row.reference.startsWith(userFolder.reference)),
			inProcessMfi: developerMfi.filter((row) => row.reference.startsWith(inProcess.reference)),
			developerFolder,
			developerMfi,
			dataWarehouse: currentDw,
		};
	else
		return {
			userMfi: developerMfi.filter((row) => row.reference.startsWith(userFolder.reference)),
			developerFolder,
			developerMfi,
			dataWarehouse: currentDw,
		};
};

export const getUserRole = (sharedState) => {
	if (sharedState.environment.userRole) return sharedState.environment.userRole;
	if (localStorage.userRole) return localStorage.userRole;
	else return UserRoles[0];
};

export const allowedToEditHarvestedObjects = (sharedState, insideChangeRequestPacket = false) => {
	let index = UserRoles.indexOf(getUserRole(sharedState));
	//Check if the current role is allowed to edit creators
	if (!insideChangeRequestPacket) return checkPermissions(index, 0);

	if (
		sharedState.contextTop?.uuid !== sharedState.contextPacket?.uuid &&
		sharedState.contextPacket?.standardObjectUuid === sharedState.dbConstants.changeRequestPacket?.referenceUuid
	)
		return true;
	else return checkPermissions(index, 0);
};
