import React, { useState, useEffect, useRef } from "react";
import { useTrackedState } from "../../../utils/store";
import Panel from "../Panel/Panel";
import {
	CloseIcon,
	CodeIcon,
	FileEarmarkText,
	JournalsIcon,
	PlusIcon,
	ViewIcon,
} from "../../BootstrapComponents/Icons/Icons";
import Iframe from "../../Iframe/Iframe";
import { v4 as uuidv4 } from "uuid";
import {
	getObjectAncestorIdAndVersionUuid,
	getObjectDescendantIdAndVersionUuid,
	getObjectIdAndVersionUuid,
} from "../../../utils/StandardObject";
import { ObjectHeader, ObjectHierarchyTree } from "../Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import VerticalMenu from "../VerticalMenu/VerticalMenu";
import { generate } from "../../../utils/Generator";
import EmptyPanelMessage from "../Panel/EmptyPanelMessage";
import { dataObjects } from "../../../utils/DataObject";

/**
 * A panel that shows the views for the object in question
 * @param uuid: The id of the object
 * @param versionUuid: The version of the object
 * @param sendGlobalChanges: Not sure how to handle this yet, we use VisualRepPanel in multiple places and only some of these have changes applicable to the object we are generating views for
 * @param includeObjectHierarchy: As said above, this view is only applicable in some cases (more that the functionality is only available to display this in some cases). How should we handle it.
 * @param panelContentTitle: The title of the content being loaded into the panel.
 * @constructor
 */

const DebugPanel = ({ panelTitle, reference, panelId, panelGroup, ...other }) => {
	const sharedState = useTrackedState();

	const [objectChanges, setObjectChanges] = useState([]);

	let standardObjectTitle = dataObjects.STANDARD_OBJECT_GIT.title;
	let masterFileIndexTitle = dataObjects.MASTER_FILE_INDEX_ROW?.title;
	let view = sharedState.openView?.title;

	useEffect(() => {
		setObjectChanges(sharedState.changedData?.[view]?.[standardObjectTitle]);
	}, [sharedState.contextMfiVersion]);

	//Build the menu items
	let menuItems = [
		{
			id: "close-debug-panel",
			label: "Close Debug",
			icon: <CloseIcon />,
			// onClick: (dispatch) => addPanel(dispatch, panelId),
		},
		{
			id: "destination-add-panel",
			label: "Add Panel",
			icon: <PlusIcon />,
			// onClick: (dispatch) => addPanel(dispatch, panelId),
		},
	];

	return (
		<Panel
			panelTitle={panelTitle ? panelTitle : "Visual Presentation Views"}
			menuItems={menuItems}
			panelId={panelId}
			panelGroup={panelGroup}
			{...other}
		>
			{debug(objectChanges)}
		</Panel>
	);
};

const debug = (objectChanges) => {
	let changedObjects = objectChanges ? Object.keys(objectChanges) : [];

	return (
		<>
			<h2>Changes</h2>
			<ul>
				{changedObjects.map((objKey) => {
					let obj = objectChanges[objKey];
					let subObjects = Object.keys(obj.objects);
					let objectHierarchy = obj.objectHierachy || [];
					return (
						<>
							<li key={objKey}>
								{objKey}
								<br />
								{getKeys(obj)}
								<br />
								{obj.top.title}
								<br />
								<b>{"Change count: " + subObjects.length + " "}</b>
								<h5>Objects</h5>
							</li>
							<ul>
								{subObjects.map((subKey) => {
									let subObject = obj.objects[subKey];
									let subObjectChanges = Object.keys(subObject.mfi);
									return (
										<>
											<li key={subKey}>
												{subKey}
												<br />
												{getKeys(subObject)}
												<br />
												<b>Change count: {subObjectChanges.length}</b>
											</li>
											<ul>
												{subObjectChanges.map((subChange) => (
													<li key={subChange}>
														{subChange}
														<br />
														{subObject.mfi[subChange].title}
													</li>
												))}
											</ul>
										</>
									);
								})}
							</ul>
							<li>
								<h5>Hierarchy</h5>
							</li>
							<ul>
								{objectHierarchy.map((row) => (
									<li>
										{getObjectAncestorIdAndVersionUuid(row) +
											"->" +
											getObjectDescendantIdAndVersionUuid(row)}
									</li>
								))}
							</ul>
						</>
					);
				})}
			</ul>
		</>
	);
};

const getKeys = (obj) => {
	return JSON.stringify(Object.keys(obj));
};

export default DebugPanel;
