import React, { useState, useEffect, Fragment } from "react";
import stylesModule from "./PagingTable.module.scss";

const numRowsToShow = [10, 25, 50, 100];

/**
 * A table that takes in rows while allowing pagination.
 * @param {}
 * @constructor
 */
const PagingTable = ({ rows: _rows, attributes, title, sort }) => {
	//state variables
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSearchPage, setCurrentSearchPage] = useState(0);
	const [rows, setRows] = useState(_rows || []);
	const [numRowsShowing, setNumRowsShowing] = useState(numRowsToShow[0]);
	const [filteredRows, setFilteredRows] = useState(undefined);
	const [searching, setSearching] = useState(false);

	//Returns the current paging information
	const getPagingInformation = () => {
		//If there are filtered rows show that, otherwise use the full list
		let arrToCheck = filteredRows || rows;
		let length = arrToCheck.length;
		let page = currentSearchPage || currentPage;
		//Default page information
		let pageInformation = {
			numPages: 0,
			message: "No rows found",
			pageNumsShown: [],
			rowsOnPage: [],
		};

		if (length > 0) {
			//Contains the number of the last item on the page
			let pageXRowsShowing = page * numRowsShowing;
			//Sets the text that tells what items are displayed on the page
			pageInformation.message = `Showing ${pageXRowsShowing - (numRowsShowing - 1)} to ${
				pageXRowsShowing < length ? pageXRowsShowing : length
			} of ${length}`;
			//The total number of pages
			pageInformation.numPages = Math.ceil(length / numRowsShowing);
			//The page numbers that should be shown on the pagination navigation
			let pageNumsShown = [1, pageInformation.numPages, page, page + 1, page + 2, page - 1, page - 2];
			//Filters to unique results, Removes results < 0 or > the number of pages, then sorts it
			pageInformation.pageNumsShown = pageNumsShown
				.filter((value, index, arr) => arr.indexOf(value) === index)
				.filter((num) => num > 0 && num <= pageInformation.numPages)
				.sort((a, b) => a - b);
			//Returns the rows that are displayed on the page
			pageInformation.rowsOnPage = arrToCheck.slice(
				pageXRowsShowing - numRowsShowing,
				pageXRowsShowing < length ? pageXRowsShowing : length
			);
		}

		return pageInformation;
	};
	const [pagingInformation, setPagingInformation] = useState(getPagingInformation());

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		setPagingInformation(getPagingInformation());
	}, [currentPage, currentSearchPage, rows.length, filteredRows?.length, numRowsShowing]);

	useEffect(() => {
		if (!searching) setCurrentSearchPage(0);
	}, [searching]);

	useEffect(() => {
		if (sort) setRows(_rows.sort(sort));
	}, [sort]);

	//Other Methods
	//Searches the array, if there are attributes passed in, it will search only those attributes, otherwise it will search all values in the row
	const searchArray = (objects, value, attributes) => {
		if (!attributes) {
			return objects.filter((item) => {
				return Object.values(item).toString().toLowerCase().includes(value.toLowerCase());
			});
		} else {
			return objects.filter((item) => {
				return attributes.some((key) => (item[key] || "").toLowerCase().includes(value.toLowerCase()));
			});
		}
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col">
					<div className="card shadow mt-5">
						<div className="card-header py-3">
							<p className="text-primary m-0 fw-bold">{title}</p>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-md-6 text-nowrap">
									<div
										id="dataTable_length-1"
										className="dataTables_length"
										aria-controls="dataTable"
									>
										<label className="form-label">
											Show&nbsp;
											<select
												className="d-inline-block form-select form-select-sm"
												onChange={(e) => setNumRowsShowing(e.target.value)}
												value={numRowsShowing}
											>
												{numRowsToShow.map((rowCount, index) => {
													return (
														<option key={rowCount} value={rowCount}>
															{rowCount}
														</option>
													);
												})}
											</select>
											&nbsp;
										</label>
									</div>
								</div>
								<div className="col-md-6">
									<div
										className="text-md-end dataTables_filter"
										id="dataTable_filter-1"
										style={{ width: "100%" }}
									>
										<label className="form-label" style={{ width: "100%" }}>
											<input
												type="search"
												className="form-control form-control-sm"
												aria-controls="dataTable"
												placeholder="Search"
												style={{ width: "100%" }}
												onKeyUp={(e) => {
													if (e.keyCode === 13 && e.target.value.length > 0) {
														setCurrentSearchPage(1);
														let matches = searchArray(rows, e.target.value);
														setSearching(true);
														setFilteredRows(matches);
													} else if (
														searching &&
														(e.keyCode = (8 || 46) && e.target.value === "")
													) {
														setSearching(false);
														setFilteredRows(undefined);
													}
												}}
											/>
										</label>
									</div>
								</div>
							</div>
							<div
								className="table-responsive table mt-2"
								id="dataTable-1"
								role="grid"
								aria-describedby="dataTable_info"
							>
								<table className="table my-0" id="dataTable">
									<thead>
										<tr>
											{attributes.map((att) => (
												<th key={att.title + "-thead"}>{att.title}</th>
											))}
										</tr>
									</thead>
									<tbody>
										{pagingInformation.rowsOnPage.map((row, index) => {
											return (
												<tr key={"table-row-" + index}>
													{attributes.map((att) => (
														<td key={att.title + "-tbody"}>
															{row[att.value] || att.value || ""}
														</td>
													))}
												</tr>
											);
										})}
									</tbody>
									<tfoot>
										<tr>
											{attributes.map((att) => (
												<th key={att.title + "-tfoot"}>{att.title}</th>
											))}
										</tr>
									</tfoot>
								</table>
							</div>
							<div className="row">
								<div className="col-md-6 align-self-center">
									<p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">
										{pagingInformation.message}
									</p>
								</div>
								<div className="col-md-6">
									{pagingInformation.numPages > 0 ? (
										<nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
											<ul className="pagination">
												<li
													className={
														"page-item " +
														stylesModule.paginationListItem +
														" " +
														(currentPage == 1 ? "disabled" : "")
													}
												>
													<a
														className="page-link"
														href="#"
														aria-label="Previous"
														onClick={(e) => setCurrentPage(currentPage - 1)}
													>
														<span aria-hidden="true">«</span>
													</a>
												</li>
												{pagingInformation.pageNumsShown.map((value, index, arr) => {
													return (
														<Fragment key={"page-no-" + index}>
															<li
																className={
																	"page-item " +
																	stylesModule.paginationListItem +
																	" " +
																	(currentPage == value ? "active disabled" : "")
																}
															>
																<a
																	className="page-link"
																	href="#"
																	onClick={(e) => setCurrentPage(value)}
																>
																	{value}
																</a>
															</li>
															{
																//This shows a ... if there is a gap in the numbers
																index + 1 < arr.length &&
																value + 1 != arr[index + 1] ? (
																	<li
																		className={
																			"disabled " +
																			stylesModule.paginationOtherItem
																		}
																	>
																		...
																	</li>
																) : (
																	""
																)
															}
														</Fragment>
													);
												})}
												<li
													className={
														"page-item " +
														stylesModule.paginationListItem +
														" " +
														(currentPage == pagingInformation.numPages ? "disabled" : "")
													}
												>
													<a
														className="page-link"
														href="#"
														aria-label="Next"
														onClick={(e) => setCurrentPage(currentPage + 1)}
													>
														<span aria-hidden="true">»</span>
													</a>
												</li>
											</ul>
										</nav>
									) : (
										""
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PagingTable;
