import "./index.scss";
import App from "./containers/App";
import { Provider } from "./utils/store";
import reportWebVitals from "./reportWebVitals";

import { createRoot } from "react-dom/client";
import { isLocalhost, isTest } from "./utils/ApiUtils";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<Provider>
		<App />
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let link = document.querySelector("link[rel~='icon']");
if (isLocalhost) {
	link.href = "./bmcl-logo-red-glow.png";
}
if (isTest) {
	link.href = "./bmcl-logo-yellow-glow.png";
}
