import React, { useState, useEffect } from "react";
import ReactGrid from "../../ReactGrid/ReactGrid";
import { useTrackedState } from "../../../utils/store";
import { buildPanelLocation, buildPanelContent } from "../../../utils/PanelTypes";
import { useDispatch } from "../../../utils/store";
import { getDataWarehouse } from "./NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { useSearchParams } from "react-router-dom";
import { getUrlParams } from "./CreatorPanel/CreatorPanel";

/**
 * Renders our app header
 * @param
 * @constructor
 */
const ObjectWorkspaceBody = ({ params, ...other }) => {
	//state variables
	const [layout, setLayout] = useState([]);
	// const [dom, setDom] = useState();
	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	const [queryParams, setQueryParams] = useSearchParams();

	useEffect(() => {
		buildDefaultLayout();
	}, []);

	useEffect(() => {
		setLayout(sharedState.layouts.main.map((panel) => buildPanelLocation(panel.panelKey, panel.id)));
	}, [sharedState.layouts.main.length]);

	const buildDefaultLayout = async () => {
		let { debug } = getUrlParams(queryParams);
		let main = [];

		let sourcePanelId = "panel2";
		let creatorPanelId = "panel3";
		let destinationPanelId = "panel4";
		let dataWarehousePanelId = "panel5";
		let mfiAndVisualRepPanelId = "panel6";
		let sourceObjectTemplatePanelId = "panel7";
		let destinationModelPanelId = "panel8";
		let destinationObjectAndVisualRepPanelId = "panel9";
		let debugPanelId = "panel934";

		main.push(
			{
				id: sourcePanelId,
				panelKey: "SOURCE_PANEL",
				subPanels: [mfiAndVisualRepPanelId, sourceObjectTemplatePanelId],
			},
			{
				id: creatorPanelId,
				panelKey: "CREATOR_PANEL",
				data: [params],
			},
			debug
				? {
						id: debugPanelId,
						panelKey: "DEBUG_PANEL",
				  }
				: {
						id: destinationPanelId,
						panelKey: "DESTINATION_PANEL",
						subPanels: [destinationObjectAndVisualRepPanelId, destinationModelPanelId],
				  }
		);

		//Grab the data warehouse data
		let dataWarehouseMfi = await getDataWarehouse(dispatch);

		let sourcePanel = {
			id: sourcePanelId,
			panelKey: "SOURCE_PANEL",
			subPanels: [mfiAndVisualRepPanelId],
		};

		let creatorPanel = {
			id: creatorPanelId,
			panelKey: "CREATOR_PANEL",
		};

		let destinationPanel = {
			id: destinationPanelId,
			panelKey: "DESTINATION_PANEL",
			subPanels: [destinationObjectAndVisualRepPanelId, { panelId: destinationModelPanelId, x: 420 }],
		};

		let debugPanel = {
			id: debugPanelId,
			panelKey: "DEBUG_PANEL",
		};

		let dataWarehousePanel = {
			id: dataWarehousePanelId,
			panelKey: "DATA_WAREHOUSE_PANEL",
			data: { mfi: dataWarehouseMfi },
		};

		let mfiAndVisualRepPanel = {
			id: mfiAndVisualRepPanelId,
			panelKey: "DESTINATION_AND_VISUAL_REP_PANEL",
			data: { mfi: dataWarehouseMfi },
		};

		let sourceObjectPanel = {
			id: sourceObjectTemplatePanelId,
			panelKey: "SOURCE_OBJECT_TEMPLATE_PANEL",
			data: [],
			// treeTitle: sourceObject.title || "Object MFI",
			// topNode: sourceObject,
			// getObjMfiOnDrop: true,
			// topNodeId: sourceObject.uuid,
			// draggable: true,
			// className: `tree`,
			// origin: 'template-panel',
		};

		// let destinationObjectPanel = {
		//     id: destinationObjectPanelId,
		//     panelKey: 'OBJECT_MASTER_FILE_INDEX_PANEL',
		//     data: {panelTitle: 'Destination Object'}
		// }

		let destinationObjectAndVisualRepPanel = {
			id: destinationObjectAndVisualRepPanelId,
			panelKey: "DESTINATION_OBJECT_AND_VISUALREP_PANEL",
			data: { panelTitle: "Destination Object" },
			subPanels: [],
		};

		let destinationModelPanel = {
			id: destinationModelPanelId,
			panelKey: "DESTINATION_MODEL_PANEL",
			data: { mfi: sharedState.destinationModel },
		};

		let newLayout = {
			main,
			[sourcePanelId]: sourcePanel,
			[creatorPanelId]: creatorPanel,
			[destinationPanelId]: destinationPanel,
			[mfiAndVisualRepPanelId]: mfiAndVisualRepPanel,
			// [visualRepPanelId]: visualRepPanel,
			[dataWarehousePanelId]: dataWarehousePanel,
			[sourceObjectTemplatePanelId]: sourceObjectPanel,
			// [destinationObjectPanelId]: destinationObjectPanel,
			[destinationObjectAndVisualRepPanelId]: destinationObjectAndVisualRepPanel,
			[destinationModelPanelId]: destinationModelPanel,
			[debugPanelId]: debugPanel,
		};
		//Send new layout to the shared state
		dispatch({ type: "UPDATE_LAYOUT", data: { layout: newLayout, type: "overwrite" } });
	};

	//Other Methods
	return (
		<ReactGrid
			layoutId={"panel1"}
			layout={layout}
			dynamicHeight={true}
			margin={[8, 0]}
			styling={{ marginLeft: "0px" }}
			draggableHandle={".panelHeader.main"}
			draggable={sharedState.resizePanels && !sharedState.fullScreenVisualRep}
			cols={840}
			rows={6}
			wrap={"nowrap"}
		>
			{sharedState.layouts.main.map((panel) =>
				buildPanelContent(panel, sharedState.layouts, "main", sharedState, dispatch)
			)}
		</ReactGrid>
	);
};

export default ObjectWorkspaceBody;

export const buildNewPanel = (panelId, layout) => {
	let panel = layout[panelId];
	let newPanelId = `panel${Object.keys(layout).length + 1}`;
	let newPanel = {
		id: newPanelId,
		panelKey: "NEW_PANEL",
	};
	if (panel.subPanels) {
		panel.subPanels.push(newPanelId);
	} else {
		panel.subPanels = [newPanelId];
	}
	layout[newPanelId] = newPanel;
	return layout;
};
