import React, { useEffect, useRef, useState } from "react";
import { getSingleLevelObjectMfi } from "../../utils/ApiUtils";
import { convertStandardObjectMfiToObject } from "../../utils/StandardObject";
import { useDispatch, useTrackedState } from "../../utils/store";

import "./ChangeRequestForm.scss";
import { INPUT_FIELD_TYPES } from "../../utils/SetupTypes";
import { camelize } from "../../utils/StringUtils";

const OPENED_REQUESTED_BY = "Opened/Requested By";
const LE_COMPANY = "LE/Company";
const EMAIL = "Email";
const DATE_OPENED = "Date Opened";
const CCODE = "CCODE";
const CONTACT_NUMBER = "Contact Number";
const SUBMITTER_CHANGE_REQUEST_PACKET_NUMBER = "Submitter Change Request Packet Number";
const SUGGESTED_CHANGE_DEPENDENCIES = "Suggested Change Dependencies";
const LEGAL_ENTITY = "Legal Entity";
const MODEL_TITLE = "Model Title";
const MODEL_ID_NUMBER = "Model ID Number";
const MODEL_VERSION_NUMBER = "Model Version Number";
const MODEL_REVISION_DATE = "Model Revision Date";
const APP_TITLE = "App Title";
const APP_ID_NUMBER = "App ID Number";
const APP_VERSION_NUMBER = "App Version Number";
const APP_REVISION_DATE = "App Revision Date";
const DOCUMENT_TITLE_ORIGINATION = "Document Title / Origination";
const DOCUMENT_REFERENCE_MANUAL_MFI = "Document Reference Manual MFI";
const DOCUMENT_VERSION_NUMBER = "Document Version Number";
const DOCUMENT_REVISION_DATE = "Document Revision Date";
const ACTION_REQUIRED = "Action Required/Description of Change to be Made";
const NEW_VERSION_OR_DOCUMENT = "New Version or Document";
const OBJECT_TYPE = "Object Type";
const APPROVAL_LEVEL_REQUIRED = "Approval Level Required";
const UBM_CHANGE_TYPE = "UBM Change Type";
const REVIEW_POINT_TYPE = "Review Point Type";
const APPROVED_BY = "Approved By";
const DATE_APPROVED = "Date Approved";
const PRIORITY = "Priority";
const PERSON_ASSIGNED_TO = "Person Assigned To";
const DEPARTMENT_POSITION_ASSIGNED_TO = "Department/Position Assigned To";
const COMPANY = "Company";
const DISPOSITION = "Disposition";
const DISPOSITION_REFERENCE = "Disposition Reference";
const STATUS = "STATUS";
const DATE_ASSIGNED = "Date Assigned";
const DUE_DATE = "Due Date";
const CHANGE_REQUEST_PACKET_NUMBER = "Change Request Packet Number";
const CLOSED_BY = "Closed By";
const DATE_CLOSED = "Date Closed";
const SUBMITTER_NOTIFIED = "Submitter Notified";

export const CHANGE_REQUEST = {
	OPENED_REQUESTED_BY,
	LE_COMPANY,
	EMAIL,
	DATE_OPENED,
	CCODE,
	CONTACT_NUMBER,
	SUBMITTER_CHANGE_REQUEST_PACKET_NUMBER,
	SUGGESTED_CHANGE_DEPENDENCIES,
	LEGAL_ENTITY,
	MODEL_TITLE,
	MODEL_ID_NUMBER,
	MODEL_VERSION_NUMBER,
	MODEL_REVISION_DATE,
	APP_TITLE,
	APP_ID_NUMBER,
	APP_VERSION_NUMBER,
	APP_REVISION_DATE,
	DOCUMENT_TITLE_ORIGINATION,
	DOCUMENT_REFERENCE_MANUAL_MFI,
	DOCUMENT_VERSION_NUMBER,
	DOCUMENT_REVISION_DATE,
	ACTION_REQUIRED,
	NEW_VERSION_OR_DOCUMENT,
	OBJECT_TYPE,
	APPROVAL_LEVEL_REQUIRED,
	UBM_CHANGE_TYPE,
	REVIEW_POINT_TYPE,
	APPROVED_BY,
	DATE_APPROVED,
	PRIORITY,
	PERSON_ASSIGNED_TO,
	DEPARTMENT_POSITION_ASSIGNED_TO,
	COMPANY,
	DISPOSITION,
	DISPOSITION_REFERENCE,
	STATUS,
	DATE_ASSIGNED,
	DUE_DATE,
	CHANGE_REQUEST_PACKET_NUMBER,
	CLOSED_BY,
	DATE_CLOSED,
	SUBMITTER_NOTIFIED,
};

/**
 * Takes in an open point and returns the change request form view of it
 * @param { ref, header = false, tree = false }
 * @constructor
 */
export const ChangeRequestForm = ({ openPoint, updateRow, changeRequestFormMfi }) => {
	const [render, setRender] = useState(false);

	//Requested By Group
	const openedRequestedBy = useRef("");
	const leCompany = useRef("");
	const email = useRef("");
	const dateOpened = useRef("");
	const ccode = useRef("");
	const contactNumber = useRef("");
	const submitterChangeRequestPacketNumber = useRef("");
	const suggestedChangeDependencies = useRef("");

	//Open Point / Item to be Changed Group
	const legalEntity = useRef("");
	const modelTitle = useRef("");
	const modelIdNumber = useRef("");
	const modelVersionNumber = useRef("");
	const modelRevisionDate = useRef("");
	const appTitle = useRef("");
	const appIdNumber = useRef("");
	const appVersionNumber = useRef("");
	const appRevisionDate = useRef("");
	const documentTitleOrigination = useRef("");
	const documentReferenceManualMFI = useRef("");
	const documentVersionNumber = useRef("");
	const documentRevisionDate = useRef("");

	//Change Type Group
	const actionRequired = useRef("");
	const newVersionOrDocument = useRef("");
	const objectType = useRef("");
	const approvalLevelRequired = useRef("");
	const ubmChangeType = useRef("");
	const reviewPointType = useRef("");

	//Change Task Assigned To Group
	const approvedBy = useRef("");
	const dateApproved = useRef("");
	const priority = useRef("");
	const personAssignedTo = useRef("");
	const departmentPositionAssignedTo = useRef("");
	const company = useRef("");
	const disposition = useRef("");
	const dispositionReference = useRef("");
	const status = useRef("");
	const dateAssigned = useRef("");
	const dueDate = useRef("");
	const changeRequestPacketNumber = useRef("");
	const closedBy = useRef("");
	const dateClosed = useRef("");
	const submitterNotified = useRef("");
	const mfiRef = useRef({});

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	//Get the open points object info
	useEffect(() => {
		if (openPoint?.uuid && openPoint?.versionUuid) getOpenPointData();
	}, [openPoint?.uuid, openPoint?.versionUuid]);

	useEffect(() => {
		getOpenPointData();
	}, [changeRequestFormMfi?.length]);

	//Get the data for open point and update the view with the correct values
	const getOpenPointData = async () => {
		let mfi;
		if (changeRequestFormMfi?.length > 0) mfi = changeRequestFormMfi;
		else mfi = await getSingleLevelObjectMfi(openPoint.uuid, openPoint.versionUuid, null, null, openPoint);

		mfiRef.current = mfi;

		openedRequestedBy.current = { ...mfi.find((row) => row.title === OPENED_REQUESTED_BY) };
		leCompany.current = { ...mfi.find((row) => row.title === LE_COMPANY) };
		email.current = { ...mfi.find((row) => row.title === EMAIL) };
		dateOpened.current = { ...mfi.find((row) => row.title === DATE_OPENED) };
		ccode.current = { ...mfi.find((row) => row.title === CCODE) };
		contactNumber.current = { ...mfi.find((row) => row.title === CONTACT_NUMBER) };
		submitterChangeRequestPacketNumber.current = {
			...mfi.find((row) => row.title === SUBMITTER_CHANGE_REQUEST_PACKET_NUMBER),
		};
		suggestedChangeDependencies.current = { ...mfi.find((row) => row.title === SUGGESTED_CHANGE_DEPENDENCIES) };

		legalEntity.current = { ...mfi.find((row) => row.title === LEGAL_ENTITY) };
		modelTitle.current = { ...mfi.find((row) => row.title === MODEL_TITLE) };
		modelIdNumber.current = { ...mfi.find((row) => row.title === MODEL_ID_NUMBER) };
		modelVersionNumber.current = { ...mfi.find((row) => row.title === MODEL_VERSION_NUMBER) };
		modelRevisionDate.current = { ...mfi.find((row) => row.title === MODEL_REVISION_DATE) };
		appTitle.current = { ...mfi.find((row) => row.title === APP_TITLE) };
		appIdNumber.current = { ...mfi.find((row) => row.title === APP_ID_NUMBER) };
		appVersionNumber.current = { ...mfi.find((row) => row.title === APP_VERSION_NUMBER) };
		appRevisionDate.current = { ...mfi.find((row) => row.title === APP_REVISION_DATE) };
		documentTitleOrigination.current = { ...mfi.find((row) => row.title === DOCUMENT_TITLE_ORIGINATION) };
		documentReferenceManualMFI.current = { ...mfi.find((row) => row.title === DOCUMENT_REFERENCE_MANUAL_MFI) };
		documentVersionNumber.current = { ...mfi.find((row) => row.title === DOCUMENT_VERSION_NUMBER) };
		documentRevisionDate.current = { ...mfi.find((row) => row.title === DOCUMENT_REVISION_DATE) };

		actionRequired.current = { ...mfi.find((row) => row.title === ACTION_REQUIRED) };
		newVersionOrDocument.current = { ...mfi.find((row) => row.title === NEW_VERSION_OR_DOCUMENT) };
		objectType.current = { ...mfi.find((row) => row.title === OBJECT_TYPE) };
		approvalLevelRequired.current = { ...mfi.find((row) => row.title === APPROVAL_LEVEL_REQUIRED) };
		ubmChangeType.current = { ...mfi.find((row) => row.title === UBM_CHANGE_TYPE) };
		reviewPointType.current = { ...mfi.find((row) => row.title === REVIEW_POINT_TYPE) };

		approvedBy.current = { ...mfi.find((row) => row.title === APPROVED_BY) };
		dateApproved.current = { ...mfi.find((row) => row.title === DATE_APPROVED) };
		priority.current = { ...mfi.find((row) => row.title === PRIORITY) };
		personAssignedTo.current = { ...mfi.find((row) => row.title === PERSON_ASSIGNED_TO) };
		departmentPositionAssignedTo.current = { ...mfi.find((row) => row.title === DEPARTMENT_POSITION_ASSIGNED_TO) };
		company.current = { ...mfi.find((row) => row.title === COMPANY) };
		disposition.current = { ...mfi.find((row) => row.title === DISPOSITION) };
		dispositionReference.current = { ...mfi.find((row) => row.title === DISPOSITION_REFERENCE) };
		status.current = { ...mfi.find((row) => row.title === STATUS) };
		dateAssigned.current = { ...mfi.find((row) => row.title === DATE_ASSIGNED) };
		dueDate.current = { ...mfi.find((row) => row.title === DUE_DATE) };
		changeRequestPacketNumber.current = { ...mfi.find((row) => row.title === CHANGE_REQUEST_PACKET_NUMBER) };
		closedBy.current = { ...mfi.find((row) => row.title === CLOSED_BY) };
		dateClosed.current = { ...mfi.find((row) => row.title === DATE_CLOSED) };
		submitterNotified.current = { ...mfi.find((row) => row.title === SUBMITTER_NOTIFIED) };
		setRender((prev) => !prev);
	};

	const handleBlur = (row) => {
		let match = mfiRef.current.find((r) => r.uuid === row.uuid);
		if (match.value !== row.value) {
			updateRow(row);
			match.value = row.value;
		}
	};

	return (
		<div className="crf container-fluid">
			<div className="row">
				<div className="col background-gray border border-dark border-3">
					<strong>Requested By:</strong>
				</div>
			</div>
			<div className="row">
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Opened/Requested By:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: openedRequestedBy.current.value,
							handleChange: (newVal) => (openedRequestedBy.current.value = newVal),
							handleBlur: (newVal) => handleBlur(openedRequestedBy.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						LE/Company:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: leCompany.current.value,
							handleChange: (newVal) => (leCompany.current.value = newVal),
							handleBlur: (newVal) => handleBlur(leCompany.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Email:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: email.current.value,
							handleChange: (newVal) => (email.current.value = newVal),
							handleBlur: (newVal) => handleBlur(email.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
			</div>
			<div className="row">
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Date Opened:
						{INPUT_FIELD_TYPES.DATE.render({
							value: dateOpened.current.value,
							handleChange: (newVal) => (dateOpened.current.value = newVal),
							handleBlur: (newVal) => handleBlur(dateOpened.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input type="date" className="invisible-input"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						CCODE:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: ccode.current.value,
							handleChange: (newVal) => (ccode.current.value = newVal),
							handleBlur: (newVal) => handleBlur(ccode.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Contact #:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: contactNumber.current.value,
							handleChange: (newVal) => (contactNumber.current.value = newVal),
							handleBlur: (newVal) => handleBlur(contactNumber.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
			</div>
			<div className="row">
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Submitter Change Request Packet #:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: submitterChangeRequestPacketNumber.current.value,
							handleChange: (newVal) => (submitterChangeRequestPacketNumber.current.value = newVal),
							handleBlur: (newVal) => handleBlur(submitterChangeRequestPacketNumber.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Suggested Change Dependencies (this change is only valid if the following changes are accepted):
						{INPUT_FIELD_TYPES.TEXT_AREA.render({
							value: suggestedChangeDependencies.current.value,
							handleChange: (newVal) => (suggestedChangeDependencies.current.value = newVal),
							handleBlur: (newVal) => handleBlur(suggestedChangeDependencies.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<textarea className="invisible-input"></textarea>*/}
				</div>
			</div>
			<div className="row">
				<div className="col background-gray border border-dark border-3">
					<strong>Open Point / Item to be changed: </strong>
				</div>
			</div>
			<div className="row">
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Legal Entity:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: legalEntity.current.value,
							handleChange: (newVal) => (legalEntity.current.value = newVal),
							handleBlur: (newVal) => handleBlur(legalEntity.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
			</div>
			<div className="row">
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Model Title:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: modelTitle.current.value,
							handleChange: (newVal) => (modelTitle.current.value = newVal),
							handleBlur: (newVal) => handleBlur(modelTitle.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Model ID#
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: modelIdNumber.current.value,
							handleChange: (newVal) => (modelIdNumber.current.value = newVal),
							handleBlur: (newVal) => handleBlur(modelIdNumber.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Version #
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: modelVersionNumber.current.value,
							handleChange: (newVal) => (modelVersionNumber.current.value = newVal),
							handleBlur: (newVal) => handleBlur(modelVersionNumber.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Revision Date:
						{INPUT_FIELD_TYPES.DATE.render({
							value: modelRevisionDate.current.value,
							handleChange: (newVal) => (modelRevisionDate.current.value = newVal),
							handleBlur: (newVal) => handleBlur(modelRevisionDate.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
			</div>
			<div className="row">
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						App Title:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: appTitle.current.value,
							handleChange: (newVal) => (appTitle.current.value = newVal),
							handleBlur: (newVal) => handleBlur(appTitle.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						App ID #
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: appIdNumber.current.value,
							handleChange: (newVal) => (appIdNumber.current.value = newVal),
							handleBlur: (newVal) => handleBlur(appIdNumber.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Version #
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: appVersionNumber.current.value,
							handleChange: (newVal) => (appVersionNumber.current.value = newVal),
							handleBlur: (newVal) => handleBlur(appVersionNumber.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Revision Date
						{INPUT_FIELD_TYPES.DATE.render({
							value: appRevisionDate.current.value,
							handleChange: (newVal) => (appRevisionDate.current.value = newVal),
							handleBlur: (newVal) => handleBlur(appRevisionDate.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
			</div>
			<div className="row">
				<div className="col-4 border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Document Title/Origination:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: documentTitleOrigination.current.value,
							handleChange: (newVal) => (documentTitleOrigination.current.value = newVal),
							handleBlur: (newVal) => handleBlur(documentTitleOrigination.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col-4 border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Doc Ref Manual MFI:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: documentReferenceManualMFI.current.value,
							handleChange: (newVal) => (documentReferenceManualMFI.current.value = newVal),
							handleBlur: (newVal) => handleBlur(documentReferenceManualMFI.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col-2 border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Version #
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: documentVersionNumber.current.value,
							handleChange: (newVal) => (documentVersionNumber.current.value = newVal),
							handleBlur: (newVal) => handleBlur(documentVersionNumber.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col-2 border border-dark d-flex align-items-start">
					<label className={"full-width full-height"}>
						Revision Date:
						{INPUT_FIELD_TYPES.DATE.render({
							value: documentRevisionDate.current.value,
							handleChange: (newVal) => (documentRevisionDate.current.value = newVal),
							handleBlur: (newVal) => handleBlur(documentRevisionDate.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="date"/>*/}
				</div>
			</div>
			<div className="row">
				<div className="col background-gray border border-dark border-3">
					<strong>Action Required/Description of Change to be made:</strong>
				</div>
			</div>
			<div className="row">
				<div className="col">
					{INPUT_FIELD_TYPES.TEXT_AREA.render({
						value: actionRequired.current.value,
						handleChange: (newVal) => (actionRequired.current.value = newVal),
						handleBlur: (newVal) => handleBlur(actionRequired.current),
						classes: "invisible-input",
						focus: true,
					})}
					{/*<textarea className="invisible-input"></textarea>*/}
				</div>
			</div>
			<div className="row border border-dark">
				<div className="col-2">
					<input type="checkbox" /> Version
				</div>
				<div className="col-2">
					<input type="checkbox" /> New Document
				</div>
				<div className="col-8 border border-dark">Object Type:</div>
			</div>
			<div className="row">
				<div className="col border border-dark border-2 background-gray">
					<strong>Approval Level Required (Initial)</strong>
				</div>
				<div className="col border border-dark border-2 background-gray">
					<strong>UBM Change Type:</strong>
				</div>
				<div className="col border border-dark border-2 background-gray">
					<strong>Review Point Type:</strong>
				</div>
			</div>

			<div className="row">
				{/*Approval Level Required*/}
				<div className="col border border-dark">
					<div className="row">
						<div className="col-5"></div>
						<div className="col-1"></div>
						<div className="col-2">
							<u>UBM</u>
						</div>
						<div className="col-2">
							<u>BM</u>
						</div>
						<div className="col-2">
							<u>App</u>
						</div>
					</div>
					<div className="row">
						<div className="col-5">CEO</div>
						<div className="col-1">1.</div>
						<div className="col-2">
							<input type="text" style={{ width: "35px" }} />
						</div>
						<div className="col-2">
							<input type="text" style={{ width: "35px" }} />
						</div>
						<div className="col-2">
							<input type="text" style={{ width: "35px" }} />
						</div>
					</div>
					<div className="row">
						<div className="col-5">Model Manager</div>
						<div className="col-1">2.</div>
						<div className="col-2">
							<input type="text" style={{ width: "35px" }} />
						</div>
						<div className="col-2">
							<input type="text" style={{ width: "35px" }} />
						</div>
						<div className="col-2">
							<input type="text" style={{ width: "35px" }} />
						</div>
					</div>
					<div className="row">
						<div className="col"></div>
					</div>
				</div>
				{/*UBM Change Type*/}
				<div className="col border border-dark">
					<div className="row">
						<div className="col-6"></div>
						<div className="col">
							<u>(U)UBM</u>
						</div>
						<div className="col">
							<u>(B) BM</u>
						</div>
						<div className="col">
							<u>(A) App</u>
						</div>
					</div>
					<div className="row">
						<div className="col-6">Change Request/Open Point</div>
						<div className="col">
							<input type="checkbox" />
						</div>
						<div className="col">
							<input type="checkbox" />
						</div>
						<div className="col">
							<input type="checkbox" />
						</div>
					</div>
					<div className="row">
						<div className="col-6">(P)Checklist/Protocol/Procedure</div>
						<div className="col">
							<input type="checkbox" />
						</div>
						<div className="col">
							<input type="checkbox" />
						</div>
						<div className="col">
							<input type="checkbox" />
						</div>
					</div>
					<div className="row">
						<div className="col-6">(S)Syntax</div>
						<div className="col">
							<input type="checkbox" />
						</div>
						<div className="col">
							<input type="checkbox" />
						</div>
						<div className="col">
							<input type="checkbox" />
						</div>
					</div>
				</div>
				{/*Review Point Type*/}
				<div className="col border border-dark">
					<div className="row">
						<div className="col-1">
							<input type="checkbox" />
						</div>
						<div className="col-10">UP 1. UBM Protocol</div>
					</div>
					<div className="row">
						<div className="col-1">
							<input type="checkbox" />
						</div>
						<div className="col-10">CL 2. Checklist Work Performed</div>
					</div>
					<div className="row">
						<div className="col-1">
							<input type="checkbox" />
						</div>
						<div className="col-10">FA 3. Financial Analysis</div>
					</div>
					<div className="row">
						<div className="col-1">
							<input type="checkbox" />
						</div>
						<div className="col-10">BE 4. Business Enterprise Performance</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col background-gray border border-dark text-danger">
					<strong>Change Task Assigned To:</strong>
				</div>
			</div>
			<div className="row">
				<div className="col d-flex align-items-start text-danger border border-dark">
					<label className={"full-width full-height"}>
						<strong>Approved By:</strong>
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: approvedBy.current.value,
							handleChange: (newVal) => (approvedBy.current.value = newVal),
							handleBlur: (newVal) => handleBlur(approvedBy.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col-5 d-flex align-items-start text-danger border border-dark">
					<label className={"full-width full-height"}>
						<strong>Date Approved:</strong>
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: dateApproved.current.value,
							handleChange: (newVal) => (dateApproved.current.value = newVal),
							handleBlur: (newVal) => handleBlur(dateApproved.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="date"/>*/}
				</div>
				<div className="col-3 d-flex align-items-start text-danger border border-dark">
					<label className={"full-width full-height"}>
						Priority
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: priority.current.value,
							handleChange: (newVal) => (priority.current.value = newVal),
							handleBlur: (newVal) => handleBlur(priority.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
			</div>
			<div className="row">
				<div className="col-4 d-flex align-items-start text-danger border border-dark">
					<label className={"full-width full-height"}>
						Assigned To:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: personAssignedTo.current.value,
							handleChange: (newVal) => (personAssignedTo.current.value = newVal),
							handleBlur: (newVal) => handleBlur(personAssignedTo.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col-3 d-flex align-items-start text-danger border border-dark">
					<label className={"full-width full-height"}>
						Date Assigned:
						{INPUT_FIELD_TYPES.DATE.render({
							value: dateAssigned.current.value,
							handleChange: (newVal) => (dateAssigned.current.value = newVal),
							handleBlur: (newVal) => handleBlur(dateAssigned.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="date"/>*/}
				</div>
				<div className="col-2 d-flex align-items-start text-danger border border-dark">
					<label className={"full-width full-height"}>
						Due Date:
						{INPUT_FIELD_TYPES.DATE.render({
							value: dueDate.current.value,
							handleChange: (newVal) => (dueDate.current.value = newVal),
							handleBlur: (newVal) => handleBlur(dueDate.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="date"/>*/}
				</div>
				<div className="col-3 d-flex align-items-start text-danger border border-dark">
					<label className={"full-width full-height"}>
						<strong>Change Request Packet #:</strong>
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: changeRequestPacketNumber.current.value,
							handleChange: (newVal) => (changeRequestPacketNumber.current.value = newVal),
							handleBlur: (newVal) => handleBlur(changeRequestPacketNumber.current),
							classes: "invisible-input",
							inputStyles: { width: "48px" },
						})}
					</label>
					{/*<input className="invisible-input" type="text" style={{width: "48px"}}/>*/}
				</div>
			</div>
			<div className="row border border-dark">
				<div className="col-4 text-danger border border-dark">
					<label className={"full-width full-height"}>
						Company:
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: company.current.value,
							handleChange: (newVal) => (company.current.value = newVal),
							handleBlur: (newVal) => handleBlur(company.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col-2 text-danger d-flex align-items-start">
					<label className={"full-width full-height"}>
						<strong>Disposition/Status:</strong>
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: disposition.current.value,
							handleChange: (newVal) => (disposition.current.value = newVal),
							handleBlur: (newVal) => handleBlur(disposition.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col-4 text-danger">
					<input type="checkbox" /> To Do
					<input type="checkbox" /> Need Feedback
					<input type="checkbox" /> Ready to Clear
					<input type="checkbox" /> Complete
					<input type="checkbox" /> Approved Next Version
					<input type="checkbox" /> Approved Future Version
					<input type="checkbox" /> Rejected
				</div>
			</div>
			<div className="row">
				<div className="col d-flex align-items-start border border-dark text-danger">
					<label className={"full-width full-height"}>
						Closed By
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: closedBy.current.value,
							handleChange: (newVal) => (closedBy.current.value = newVal),
							handleBlur: (newVal) => handleBlur(closedBy.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col d-flex align-items-start border border-dark text-danger">
					<label className={"full-width full-height"}>
						Date Closed
						{INPUT_FIELD_TYPES.ALPHA_NUMERIC.render({
							value: dateClosed.current.value,
							handleChange: (newVal) => (dateClosed.current.value = newVal),
							handleBlur: (newVal) => handleBlur(dateClosed.current),
							classes: "invisible-input",
						})}
					</label>
					{/*<input className="invisible-input" type="text"/>*/}
				</div>
				<div className="col border border-dark text-danger">
					<label>Submitter Notified? </label>
					{INPUT_FIELD_TYPES.CHECK_BOX.render({
						value: submitterNotified.current.value,
						handleChange: (newVal) => (submitterNotified.current.value = newVal),
					})}{" "}
					Yes
					{INPUT_FIELD_TYPES.CHECK_BOX.render({
						value: !submitterNotified.current.value,
						handleChange: (newVal) => (submitterNotified.current.value = newVal),
					})}{" "}
					No
					{/*<input type="checkbox"/> Yes <input type="checkbox"/> No*/}
				</div>
			</div>
		</div>
	);
};
