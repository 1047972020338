import React, { useState, useEffect } from "react";
import EditableLabel from "../EditableLabel";

/**
 * Renders a Label with a place to enter multiple editable labels
 * @param {label, values: _values, update}
 * @constructor
 */
const MultiEditableLabels = ({ uuid, label, values: _values, update }) => {
	//state variables
	const [values, setValues] = useState("");
	const [valFocus, setValFocus] = useState(-1);

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		if (_values !== values) setValues(_values);
	}, [_values]);

	//Other Methods
	const updateValues = (index, val) => {
		let valArr = [];
		if (values) valArr = values.split(",");

		if (index === -1) valArr.push(val);
		else if (!val) valArr.splice(index, 1);
		else valArr[index] = val;

		let newVal = valArr.join(",");
		setValues(newVal);

		update(newVal);
	};

	return (
		<div className="d-flex flex-row flex-wrap">
			<span style={{ paddingRight: "12px", margin: "auto 0" }} className={""}>
				{label}:
			</span>
			{values
				? values.split(",").map((val, index) => (
						<>
							<div
								key={uuid + index}
								className={"flex"}
								style={{
									margin: "auto 0",
									padding: "2px 2px",
									marginRight: "12px",
									display: "inline-flex",
									alignContent: "center",
									justifyContent: "center",
									paddingLeft: "4px",
									paddingRight: "4px",
									borderStyle: "solid",
									borderWidth: "1px",
									borderRadius: "3px",
									fontSize: "12px",
									lineHeight: "1.84615385",
									textDecoration: "none",
									verticalAlign: "middle",
									whiteSpace: "nowrap",
									borderColor: "transparent",
									backgroundColor: "#e1ecf4",
									color: "#39739d",
									// minWidth: `${tag.length * 10}px`,
								}}
							>
								<EditableLabel
									initialValue={`${val}`}
									changed={false}
									onFocus={(value) => (value ? setValFocus(index) : setValFocus(-1))}
									onBlur={(value) => {
										//update the corresponding index in the values
										updateValues(index, value);

										setValFocus(-1);
									}}
									uuid={uuid !== "emptyId" ? uuid : undefined}
									style={{
										display: "inline-flex",
										alignContent: "center",
										justifyContent: "center",
										minWidth: "0",
										paddingLeft: "4px",
										paddingRight: "4px",
										borderStyle: "solid",
										borderWidth: "1px",
										borderRadius: "3px",
										fontSize: "12px",
										lineHeight: "1.84615385",
										textDecoration: "none",
										verticalAlign: "middle",
										whiteSpace: "nowrap",
										borderColor: "transparent",
										backgroundColor: "#e1ecf4",
										color: "#39739d",
									}}
								/>

								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className={`bi bi-x-lg remove-tag-icon ${valFocus === index ? "d-none" : ""}`}
									viewBox="0 0 16 16"
									style={{
										cursor: "pointer",
										width: "18px",
										height: "18px",
										margin: "auto",
										padding: "3px",
									}}
									onClick={(e) => {
										e.stopPropagation();
										updateValues(index, "");
									}}
								>
									<path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
								</svg>
							</div>
						</>
				  ))
				: ""}
			<div style={{ margin: "auto 0" }}>
				<EditableLabel
					initialValue={``}
					changed={false}
					onBlur={(value) => {
						updateValues(-1, value);
					}}
					uuid={-1}
					style={{ color: "rgb(86 86 86 / 65%)", fontSize: "14px" }}
					labelPlaceholder={"(Double click to add new)"}
				/>
			</div>
		</div>
	);
};

export default MultiEditableLabels;
