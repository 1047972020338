import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "@mui/material/Input";

const ENTER_KEY_CODE = 13;
const DEFAULT_LABEL_PLACEHOLDER = "(Double click to edit)";

const EditableLabel = ({
	onFocus = () => {},
	onBlur = () => {},
	changed,
	uuid,
	leftPadding,
	labelProps,
	version,
	placeholder,
	disableEdit = false,
	...props
}) => {
	const [isEditing, setEditing] = useState(false);
	const [value, setValue] = useState(props.initialValue);
	const inputRef = useRef(null);

	useEffect(() => {
		if (props.initialValue !== value) setValue(props.initialValue);
	}, [props.initialValue]);

	const originalValue = props.initialValue;

	const isTextValueValid = () => typeof value !== "undefined" && value.trim().length > 0;

	const handleFocus = (event) => {
		event.stopPropagation();
		if (!uuid || disableEdit) return;

		const fn = isEditing ? onBlur : onFocus;
		// if(!isEditing) {
		//     if(value === '(Double click to eidt')
		// }
		if (!isEditing || (isEditing && value !== originalValue)) {
			fn(value);
			if (uuid === -1) setValue("");
		} else {
			onFocus(false);
		}
		handleEditState();
	};

	const handleChange = (e) => setValue(inputRef.current.value);

	const handleKeyDown = (e) => {
		if (e.keyCode === ENTER_KEY_CODE) {
			handleEnterKey(e);
		}
	};

	const handleEditState = () => {
		// if(!isTextValueValid()) return;
		setEditing((prev) => !prev);
	};

	const handleEnterKey = (e) => {
		handleFocus(e);
	};

	const stopEventPropagation = (e) => {
		e.stopPropagation();
	};

	if (isEditing) {
		let newPadding = leftPadding ? 50 + leftPadding : 10;
		if (version) newPadding += 110;

		return (
			<div style={{ width: "100%", display: "inline-block" }}>
				<Input
					inputProps={{
						ref: inputRef,
						value,
						style: { padding: "0px", width: `calc(100% - ${newPadding}px)`, minWidth: "100px" },
					}}
					onChange={handleChange}
					onBlur={handleFocus}
					onKeyDown={handleKeyDown}
					onClick={stopEventPropagation}
					autoFocus
					style={{ ...props.style, height: "20px", width: `calc(100% - ${newPadding}px)` }}
				/>
			</div>
		);
	}

	const labelText = isTextValueValid() ? value : props.labelPlaceholder || DEFAULT_LABEL_PLACEHOLDER;

	let colorStyle = changed
		? { textShadow: "1px 1px 0px #d2d2d2", color: "rgb(255, 193, 7)" }
		: props.style && props.style.color
		? { color: props.style.color }
		: {};

	return (
		<div
			style={{
				...props.style,
				margin: "auto 0",
				color: "rgba(0, 0, 0, 1)",
				...colorStyle,
				display: "inline-block",
			}}
			onClick={stopEventPropagation}
			onDoubleClick={handleFocus}
			{...labelProps}
		>
			{placeholder || labelText}
		</div>
	);
};

EditableLabel.propTypes = {
	initialValue: PropTypes.string.isRequired,
	emptyEdit: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
};

export default EditableLabel;
