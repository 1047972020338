import { getObjectMfi } from "../ApiUtils";
import { convertStandardObjectMfiToObject, FUNCTION_OBJECT_TYPE } from "../StandardObject";

const convertToFunctions = async (fns, sharedState, dispatch) => {
	const fnObjects = await Promise.all(
		fns.map(async (fn) => await convertStandardObjectMfiToObject(fn, sharedState, dispatch))
	);
	return fnObjects;
};

const getAndStoreFunctions = async (sharedState, dispatch) => {
	const fnReference = FUNCTION_OBJECT_TYPE;
	if (sharedState.functions?.length > 0) return sharedState.functions;
	const fnTops = sharedState.destinationModel.filter((row) => row.objectTypeUuid === fnReference);
	const fns = await Promise.all(
		fnTops
			.filter((f) => f.standardObjectUuid !== fnReference)
			.map((fnTop) => {
				let match = sharedState.functions.find(
					(row) =>
						row.uuid === fnTop.standardObjectUuid && row.versionUuid === fnTop.standardObjectVersionUuid
				);
				if (match) return match;
				return getObjectMfi(fnTop.standardObjectUuid, fnTop.standardObjectVersionUuid);
			})
	);
	const functions = await convertToFunctions(fns, sharedState, dispatch);
	document.functions = functions;
	await dispatch({
		type: "SET_FUNCTIONS",
		data: functions,
	});
	return functions;
};

export { getAndStoreFunctions };
