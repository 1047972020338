import { useState } from "react";
import { objectStates } from "./ChangeRequest/ChangeRequestForm";
import InlineEditableElement from "../InlineEditableElement/InlineEditableElement";
import { ClockRotateLeftIcon, ClockRotateRightIcon } from "../BootstrapComponents/Icons/Icons";
import { trimVersion } from "./VersionUtils";

/**
 * Takes in a version and converts it to an fancy version view. First version will only change colors, the goal is to change it to different icons based on its state
 * @param { version: {
 *     computerVersion: 10,
 *     objectVersionNumber: "0.0.1",
 *     objectState: "Under Construction",
 *     objectsComputerVersions: [v1, v2, v3, vn],
 *     loadPreviousVersion: func(),
 *     loadNextVersion: func(),
 *     loadSpecificVersion: func(),
 * } }
 * @constructor
 */
const Version = ({ version, chooseVersion = true, tooltip, classes = "", styles, ...other }) => {
	//state variables
	let [newVersion, setNewVersion] = useState(version?.computerVersion);

	let isProductionVersion = version?.objectState === objectStates.PRODUCTION;
	let objectsComputerVersions = version?.objectsComputerVersions;

	//Other Methods

	return (
		<div
			style={{
				color: isProductionVersion ? "green" : "red",
				fontSize: "0.8em",
				display: "inline-flex",
				alignItems: "center",
				padding: "0px 4px",
				...styles,
			}}
			{...other}
			className={classes}
		>
			v{trimVersion(version?.objectVersionNumber)}
			{isProductionVersion ? "" : version?.objectState ? "-" + version.objectState : ""}
			{version?.computerVersion || version.computerVersion === 0 ? (
				<>
					{objectsComputerVersions?.length > 1 && version.loadPreviousVersion ? (
						<div style={{ paddingLeft: "6px", display: "flex" }}>
							{version.computerVersion > 1 ? (
								<button
									style={{ border: "none", backgroundColor: "inherit", padding: "0 5px" }}
									onClick={version.loadPreviousVersion}
									title="Previous version"
								>
									<ClockRotateLeftIcon color={"rgba(0, 0, 0, 0.54)"} width={16} height={16} />
								</button>
							) : (
								""
							)}
							<InlineEditableElement
								text={version.computerVersion}
								type="number"
								tooltip={tooltip}
								style={{
									padding: "3px 8px 2px 8px",
									backgroundColor: "#f3f6f6",
									borderRadius: "2px",
									fontSize: "1.1em",
									boxShadow: "1px 1px 2px #c0bbbb inset",
								}}
							>
								<input
									type="number"
									name="computerVersion"
									value={newVersion}
									onFocus={(e) => e.target.select()}
									onChange={(e) => {
										const { value } = e.target;
										setNewVersion(value);
									}}
									onBlur={(e) => {
										if (version.computerVersion !== newVersion)
											version.loadSpecificVersion(newVersion);
									}}
									onKeyDown={(e) =>
										e.key === "Enter" ? version.loadSpecificVersion(newVersion) : ""
									}
									autoFocus
									style={{ width: "50px" }}
								/>
							</InlineEditableElement>
							{version.computerVersion < objectsComputerVersions?.length ? (
								<button
									style={{ border: "none", backgroundColor: "inherit", padding: "0 5px" }}
									onClick={version.loadNextVersion}
									title="Next version"
								>
									<ClockRotateRightIcon color={"rgba(0, 0, 0, 0.54)"} width={16} height={16} />
								</button>
							) : (
								""
							)}
						</div>
					) : version.computerVersion ? (
						":" + version.computerVersion
					) : (
						""
					)}
				</>
			) : (
				""
			)}
		</div>
	);
};

export default Version;
