import toAst from "./VisitorUtil";
import { getTopMostObject } from "../../components/ReactGridComponents/Body/CreatorPanel/CreatorPanel";
import { getStandardObjectRowFromPath } from "../StandardObject";

const evaluateExpression = async (expressionString, sharedState, { harvestedMfi, top, mfi }, params, functions) => {
	// If functions are not passed in explicitly, use the functions from the document
	if (!functions || functions?.length < 1) functions = document.functions;
	const api = {
		currentUser: () => sharedState.currentUser,
		currentObject: () => getTopMostObject(sharedState),
		this: () => harvestedMfi,
		path: (pathArray) => {
			if (mfi) return getStandardObjectRowFromPath(pathArray, mfi);
			else return getStandardObjectRowFromPath(pathArray, sharedState.contextMfi);
		},
		context: () => {
			if (top) return top;
			else return sharedState.contextTop;
		},
		getLinkToCurrentContext: () => {
			let { contextTop: context, selectedWorkspaceRow } = sharedState;

			let obj = {
				linkToSource: "object-master-file-index",
				linkToObjectUuid: context.uuid,
				linkToObjectVersionUuid: context.versionUuid,
				value: null,
				linkToAttributeUuid: null,
			};
			if (selectedWorkspaceRow?.uuid) {
				obj.value = selectedWorkspaceRow.reference;
				obj.linkToAttributeUuid = selectedWorkspaceRow.uuid;
			} else obj.value = context.reference;

			return JSON.stringify(obj);
		},
	};
	console.time(`Evaluating ${expressionString}`);
	let astFromVisitor = await toAst(expressionString, functions, api, params);
	console.timeEnd(`Evaluating ${expressionString}`);
	return astFromVisitor;
};

export default evaluateExpression;
