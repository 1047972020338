import { generateSourceCode } from "./ApiUtils";

export const generate = async (uuid, versionUuid, type = "template", changes) => {
	let code = await generateSourceCode(uuid, versionUuid, type, changes);

	//If we don't find any code, what should I return?
	if (!code || !code.length || code.length < 1) return;

	let filesToDownload = [];
	let templates = [];
	let htmlFiles = [];

	if (type === "code") {
		let str = '<script src="https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js"></script>';
		code.forEach((file) => {
			if (file.name.includes(".html")) {
				file.code += "<br><br>";
				str += file.code;
				htmlFiles.push(file.code);
			} else {
				//Since the file isn't an HTML file, create a basic HTML element that can display the file name and code
				str += `
                        <h3>${file.name + " - "}<span style="font-size: .75em; font-weight: 100; color: red;">${
					file.ref
				}</span></h3>
                        <pre class="prettyprint">${new Option(file.code).innerHTML}</pre>
                        <br>
                        <br>
                    `;
			}

			if (file.id !== -1) {
				let fileObj = {
					id: filesToDownload.length,
					ref: file.ref,
					ownerRef: file.ownerRef,
					name: file.name,
					code: file.code,
					download: true,
				};
				if (file.template) templates.push(fileObj);
				else filesToDownload.push(fileObj);
			}
			//TODO: Do we want to return the files so they can be downloaded? I don't know if anybody used this feature anyways
		});
		return str;
	} else if (type === "template") {
		return code;
	}
};
