import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useTrackedState } from "../../../utils/store";
import {
	getRenderElement,
	INPUT_FIELD_TYPES,
	SETUP_SHEET_OUTPUT_FIELD_TYPES,
	SETUP_TYPES,
	typeExists,
	validateInput,
} from "../../../utils/SetupTypes";
import {
	convertStandardObjectMfiToObject,
	copyStandardObject,
	createObjectHierarchyRecord,
	findClosestPrimusAncestor,
	getObjectIdAndVersionUuid,
	FILTERED_OBJECT_GENERAL_TYPE,
	linkAttributeName,
	linkVersionAttributeName,
	ZERO_ROW_UUID,
} from "../../../utils/StandardObject";
import { updateChangeData } from "../Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { getTopMostObject } from "../Body/CreatorPanel/CreatorPanel";
import { sortByTitle } from "../../../utils/ArrayUtils";
import { deleteRow, getNextRef, getReferenceNo, sortByReference } from "../../../utils/Referencing";
import { getFilter, getObjectsOfTypeInDataWarehouseByFilter } from "../../../utils/ApiUtils";
import { v4 as uuidv4 } from "uuid";
import {
	FolderIcon,
	InfoIcon,
	LightningIcon,
	PaperClipIcon,
	PaperclipIcon,
} from "../../BootstrapComponents/Icons/Icons";
import EditableLabel from "../../EditableLabel/EditableLabel";
import { UserSetupFormField } from "../../SetupFormWindow/UserSetupFormWindow";
import SelectionDialog from "../../Dialog/DraggableDialog/SelectionDialog/SelectionDialog";
import DraggableDialog from "../../Dialog/DraggableDialog/Dialog";
import Wopi from "../../Wopi/Wopi";
import { fileServiceUrl } from "../../../utils/FileServiceUtils";
import Tooltip from "@mui/material/Tooltip";
import { wopiHost } from "../../../utils/WopiServiceUtils";
import Dropzone from "react-dropzone-uploader";
import evaluateExpression from "../../../utils/ExpressionUtils/ExpressionUtil";
import { SmartButton } from "@mui/icons-material";
import { SimpleLoader } from "../../Loader/Loader";
import ListInputFieldType from "../../InputFieldTypes/ListInputFieldType";
import AssociationOutputType from "../../InputFieldTypes/AssociationOutputType";

const SetupField = memo(
	({
		field,
		data,
		top,
		updateRow,
		setShowObjectSelectDialog,
		objectClicked,
		setupSheet,
		setShowRelatableObjectDialog,
		setShowLinkToObjectMfiDialog,
		updateRelatableObjects,
		updateFieldToAttachTo,
		updateAttachableTypeMfi,
		mfiUpdates,
		dataUpdates,
	}) => {
		const [invalid, setInvalid] = useState(false);
		const [focus, setFocus] = useState(false);
		const [showLoader, setShowLoader] = useState(false);
		const loadedOptions = useRef();
		const [value, setValue] = useState([]);

		// const value = useRef([]);
		const descendants = useRef([]);
		const focusRef = useRef(false);
		// const showLoader = useRef(false);
		// const setupData = useRef([]);

		const sharedState = useTrackedState();
		const dispatch = useDispatch();

		useEffect(() => {
			if (!data) data = sharedState.setupSheetData[setupSheet];

			if (!data) return;

			descendants.current = data.filter((row) => row.reference.startsWith(field.reference));
			let children = descendants.current.filter((row) => row.parentUuid === field.uuid);
			if (
				field.inputType === INPUT_FIELD_TYPES.ASSOCIATION.value ||
				field.inputType === INPUT_FIELD_TYPES.LIST.value
			)
				setValue(children);
		}, [sharedState.setupSheetData, data]);

		useEffect(() => {
			//How should I deal with this for the harvested object?
			let { uuid } = sharedState.selectedWorkspaceRow;
			if (uuid && uuid === field.uuid && !focus) {
				//Check if we need to open the dialog file
				focusRef.current = true;
				setFocus(true);
			} else if (uuid && uuid !== field.uuid && focus) {
				focusRef.current = false;
				setFocus(false);
			}
		}, [sharedState.selectedWorkspaceRow?.uuid]);
		/**
		 * Takes in:
		 * The value to validate
		 * The field that will hold value (This is where the validations come from
		 * The attribute to update if the value is valid
		 */
		const validate = (value, field) => {
			let isValid = validateInput(value, { field });
			if (!isValid) {
				setInvalid(true);
			} else {
				setInvalid(false);
			}
		};

		const getDefault = async () => {
			let value;
			if (field.defaultValue?.includes("=")) {
				setShowLoader(true);
				let mfi = data;
				if (!data.find((row) => row.uuid === top.uuid)) mfi = [top, ...data];
				let convertedMfi = await convertStandardObjectMfiToObject(mfi, sharedState);
				value = await evaluateExpression(field.defaultValue.slice(1), sharedState, {
					harvestedMfi: convertedMfi,
					top,
					mfi,
				});
				setShowLoader(false);
			} else {
				value = field.defaultValue;
			}
			field.value = value;
			updateRow(field.uuid, "value", value);
		};

		const sendUpdates = (update) => {
			mfiUpdates(update);
			dataUpdates(update);
		};

		const removeObject = (obj) => {
			//Update state with child removed from MFI
			let changes = deleteRow(obj, data);
			let update = {
				objectRows: changes,
				deletedRows: [obj],
			};

			let objectToUpdate = top || data[0];

			if (objectToUpdate.uuid !== sharedState.contextTop.uuid) update.subObjectToUpdate = objectToUpdate;
			sendUpdates(update);
		};

		/**
		 * Builds the setup field that should be rendered based on the object's properties
		 */
		const buildSetupField = useCallback(
			(field) => {
				if (!data) data = sharedState.setupSheetData[sharedState.setupSheetActiveTitle];
				let label = (
					<LabelWithHelpIcon
						reference={field.reference}
						title={field.title}
						description={field.description}
						defaultValue={field.defaultValue}
						getDefault={getDefault}
						showLoader={showLoader}
					/>
				);
				if (
					field.inputType === INPUT_FIELD_TYPES.DROP_DOWN.value ||
					(!field.inputType && field.setupType === SETUP_TYPES.DROP_DOWN.value)
				) {
					let optionGroups = [
						{
							title: "Options",
							options: [{ label: `Select ${field.title}`, value: "" }],
						},
					];

					if (field.setupOptions?.length > 0)
						optionGroups[0].options = [
							...optionGroups[0].options,
							...field.setupOptions.split(",").map((option) => ({ label: option, value: option })),
						];

					if (field.dropDownObjectTypes?.length > 0) {
						let mfiRows;
						if (field.dropDownObjectTypes[0] === ZERO_ROW_UUID) {
							mfiRows = sharedState.destinationModel
								.filter((row) => row.standardObjectUuid && row.standardObjectVersionUuid)
								.sort(sortByTitle);
							optionGroups.push({
								title: "Objects",
								options: mfiRows.map((row) => ({
									label: `${row.title} - ${row.reference}`,
									value: getObjectIdAndVersionUuid({
										uuid: row.standardObjectUuid,
										versionUuid: row.standardObjectVersionUuid,
									}),
								})),
							});
						} else {
							mfiRows = sharedState.destinationModel.filter((row) =>
								field.dropDownObjectTypes.includes(row.uuid)
							);

							//For each record search the current open object's mfi for any rows of that type
							mfiRows.forEach((_row) => {
								let currentRows = sharedState.destinationModel.filter(
									(row) =>
										row.objectTypeUuid === _row.standardObjectUuid &&
										row.standardObjectUuid !== sharedState.contextTop.uuid
								);

								//Create an option group for these
								optionGroups.push({
									title: _row.title,
									options: currentRows.map((row) => ({
										label: `${row.reference} - ${row.title}`,
										value: getObjectIdAndVersionUuid({
											uuid: row.standardObjectUuid,
											versionUuid: row.standardObjectVersionUuid,
										}),
									})),
								});
							});
						}
					}

					return (
						// <SetupFieldWrapper key={field.uuid}>
						//     {
						INPUT_FIELD_TYPES.DROP_DOWN.render({
							label,
							value: field.value,
							description: field.description,
							optionGroups: optionGroups,
							disabled: showLoader,
							handleChange: (val) => {
								if (val.uuid) val = val.uuid;

								updateRow(field.uuid, "value", val);
							},
							focus,
						})
						//     }
						// </SetupFieldWrapper>
					);
				} else if (
					field.inputType === INPUT_FIELD_TYPES.LIST.value ||
					(!field.inputType && field.setupType === SETUP_TYPES.LIST.value)
				) {
					let children = data.filter((row) => row.parentUuid === field.uuid);
					return (
						<ListInputFieldType
							label={label}
							field={field}
							data={data}
							children={children}
							updateFieldToAttachTo={updateFieldToAttachTo}
							updateAttachableTypeMfi={updateAttachableTypeMfi}
							setShowObjectSelectDialog={setShowObjectSelectDialog}
							objectClicked={objectClicked}
							updateRow={updateRow}
							sendUpdates={sendUpdates}
							removeObject={removeObject}
						/>
					);
				} else if (
					(!field.inputType && field.setupType === SETUP_TYPES.TEXT_AREA.value) ||
					(field.inputType && field.inputType === INPUT_FIELD_TYPES.TEXT_AREA.value)
				) {
					return (
						// <SetupFieldWrapper className={'flex-fill col-12'} width={''}>
						//     {
						INPUT_FIELD_TYPES.TEXT_AREA.render({
							label,
							value: field.value,
							description: field.description,
							disabled: showLoader,
							handleChange: (newVal) => {
								validate(newVal, field);
							},
							handleBlur: (newVal) => {
								if (field.value !== newVal && !invalid) updateRow(field.uuid, "value", newVal);
							},
							invalid,
							allowTabInside: false,
							focus,
						})
						// }
						// </SetupFieldWrapper>
					);
				}
				//For now, on the setup sheet it will treat both the WOPI file upload and the normal file upload like a WOPI file upload
				else if (
					field.inputType === "file-upload-wopi" ||
					field.inputType === INPUT_FIELD_TYPES.FILE_UPLOAD.value
				) {
					return INPUT_FIELD_TYPES.FILE_UPLOAD.render({
						label: label,
						description: field.description,
						updateRow: (fileId, fileName) => {
							updateRow(field.uuid, "multi", {
								value: fileId,
								objectTypeUuid: sharedState.dbConstants.fileObject.referenceUuid,
							});
						},
						row: field,
						focus,
					});
				} else if (
					field.inputType === INPUT_FIELD_TYPES.NUMERIC.value ||
					field.inputType === INPUT_FIELD_TYPES.ALPHA_NUMERIC.value ||
					field.inputType === INPUT_FIELD_TYPES.INCLUDE_SPECIAL_CHARACTERS.value
				)
					return (
						// <SetupFieldWrapper>
						//     {
						getRenderElement(field.inputType, {
							label,
							title: field.title,
							value: field.value,
							description: field.description,
							handleChange: (newVal) => {
								validate(newVal, field);
							},
							handleBlur: (newVal) => {
								if (field.value !== newVal && !invalid) updateRow(field.uuid, "value", newVal);
							},
							invalid,
							focus,
						})
						//     }
						// </SetupFieldWrapper>
					);
				else if (field.inputType === INPUT_FIELD_TYPES.CHECK_BOX.value)
					return (
						// <SetupFieldWrapper>
						//     {
						getRenderElement(field.inputType, {
							label,
							value: field.value,
							handleChange: (newVal) => {
								updateRow(field.uuid, "value", newVal);
							},
							focus,
						})
						//     }
						// </SetupFieldWrapper>
					);
				else if (!field.inputType && field.setupType === SETUP_TYPES.TEXT_AREA.value) {
					return INPUT_FIELD_TYPES.TEXT_AREA.render({
						label,
						title: field.title,
						value: field.value,
						description: field.description,
						disabled: showLoader,
						handleChange: (newVal) => {
							validate(newVal, field);
						},
						handleBlur: (newVal) => {
							if (field.value !== newVal && !invalid) updateRow(field.uuid, "value", newVal);
						},
						invalid,
						allowTabInside: false,
						focus,
					});
				} else if (field.inputType === INPUT_FIELD_TYPES.ASSOCIATION.value) {
					let children = data.filter((row) => row.parentUuid === field.uuid);
					return (
						<AssociationOutputType
							label={label}
							field={field}
							data={data}
							children={children}
							updateRelatableObjects={updateRelatableObjects}
							setShowRelatableObjectDialog={setShowRelatableObjectDialog}
							setShowLinkToObjectMfiDialog={setShowLinkToObjectMfiDialog}
							updateFieldToAttachTo={updateFieldToAttachTo}
							dataUpdates={dataUpdates}
							mfiUpdates={mfiUpdates}
							objectClicked={objectClicked}
							removeObject={removeObject}
						/>
					);
				} else if (field.children?.length > 0 || field.hasChildren) {
					return (
						<div
							style={{
								height: "50px",
								border: "1px solid rgba(45, 69, 95, 0.11)",
								background: "rgb(229, 242, 255)",
								display: "flex",
								color: "#4183c4",
								alignItems: "center",
							}}
						>
							<button className="btn btn-link p-2" onClick={() => objectClicked(field.uuid)}>
								<FolderIcon />
							</button>
							{field.listSetupAttribute ? (
								<EditableLabel
									initialValue={field.title}
									uuid={field.uuid}
									onBlur={(val) => {
										//If the val is an object prompt to overwrite, if not call updateRow updating the corresponding row
										// if (val.uuid) {
										//     //Prompt the user and say this will replace the current contents of that object
										//     objectReplacement.current = {
										//         currentObject: field,
										//         newObject: val
										//     };
										//
										//
										//     field.title = val.title;
										//
										//     if (field.title === '(Edit Title)')
										//         handleYes();
										//     else
										//         setShowPrompt(true);
										// }
										// else {
										field.title = val;
										updateRow(field.uuid, "title", val);
										// }
									}}
									style={{
										color: "#4183c4",
									}}
								/>
							) : (
								<span>{label}</span>
							)}
						</div>
					);
				} else if (field.inputType === INPUT_FIELD_TYPES.LINK.value) {
					return (
						// <SetupFieldWrapper>
						<LinkField
							field={field}
							label={label}
							description={field.description}
							data={descendants.current}
							updateRow={updateRow}
						/>
						// </SetupFieldWrapper>
					);
				} else if (field.inputType === INPUT_FIELD_TYPES.SOURCE_CODE.value) {
					return INPUT_FIELD_TYPES.SOURCE_CODE.render({
						label,
						value: field.value,
						description: field.description,
						handleChange: (newVal) => {
							validate(newVal, field);
						},
						handleBlur: (newVal) => {
							if (field.value !== newVal && !invalid) updateRow(field.uuid, "value", newVal);
						},
						invalid,
						focus,
					});
				} else if (field.objectTypeUuid === sharedState.dbConstants.dataSourceObject?.referenceUuid) {
					return INPUT_FIELD_TYPES.REPORT_DATA_SOURCE.render({
						label,
						field,
						focus,
						updateRow: (subObjectToUpdate, rows) =>
							updateChangeData(dispatch, {
								objectToUpdate: getTopMostObject(sharedState),
								subObjectToUpdate,
								objectRows: rows,
							}),
					});
				} else if (typeExists(field.inputType) && field.inputType !== SETUP_TYPES.NONE.value) {
					return (
						// <SetupFieldWrapper width='900px'>
						//     {
						getRenderElement(field.inputType, {
							label,
							value: field.value,
							description: field.description,
							disabled: showLoader,
							title: field.title,
							handleChange: (newVal) => {
								validate(newVal, field);
							},
							handleBlur: (newVal) => {
								if (field.value !== newVal && !invalid) updateRow(field.uuid, "value", newVal);
							},
							invalid,
							focus,
							// focus: focusRef.current,
						})
						//     }
						// </SetupFieldWrapper>
					);
				} else if (field.generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE) {
					let hierarchyRecord = data[0].objectHierarchy.find(
						(row) =>
							row.descendantStandardObjectUuid === field.uuid &&
							row.descendantStandardObjectVersionUuid === field.versionUuid
					);
					if (!hierarchyRecord)
						hierarchyRecord = sharedState.contextObjectHierarchy.find(
							(row) =>
								row.descendantStandardObjectUuid === field.uuid &&
								row.descendantStandardObjectVersionUuid === field.versionUuid
						);
					return SETUP_SHEET_OUTPUT_FIELD_TYPES.HARVESTED_OBJECT_VIEW.render({
						object: field,
						hierarchyRecord,
						nodeClicked: objectClicked,
						updateRow: (newTitle) => updateRow(field.uuid, "title", newTitle),
						removeObject,
						dataUpdates,
					});
				} else if (field.isObject) {
					return SETUP_SHEET_OUTPUT_FIELD_TYPES.IS_OBJECT.render({
						child: field,
						folderIconClicked: objectClicked,
						handleBlur: (val) => {
							// if (val.uuid) {
							//     //Prompt the user and say this will replace the current contents of that object
							//     objectReplacement.current = {
							//         currentObject: field,
							//         newObject: val
							//     };
							//
							//     if (field.title === '(Edit Title)') {
							//         field.title = val.title;
							//         handleYes();
							//     }
							//     else
							//         setShowPrompt(true);
							// }
							// else {
							field.title = val;
							updateRow(field.uuid, "title", val);
							// }
						},
						removeChild: removeObject,
						lastChild: true,
					});
				} else
					return (
						// <SetupFieldWrapper>
						<UserSetupFormField
							key={field.uuid}
							// windowUuid={subObject.uuid}
							field={field}
							// index={index}
							updateRow={updateRow}
							hasChildren={false}
							showRef={true}
							refField={"reference"}
							disabled={showLoader}
							selected={focus}
						/>
						// </SetupFieldWrapper>
					);
			},
			[field.uuid, field.inputType, field.value, focus, value, showLoader, data[0]?.objectHierarchy?.length]
		);

		return (
			<SetupFieldWrapper
				width={
					field.inputType === INPUT_FIELD_TYPES.SOURCE_CODE.value ||
					field.inputType === INPUT_FIELD_TYPES.LIST.value ||
					field.inputType === INPUT_FIELD_TYPES.RICH_TEXT_AREA.value ||
					field.inputType === INPUT_FIELD_TYPES.REPORT_DATA_SOURCE.value ||
					field.generalTypeUuid === FILTERED_OBJECT_GENERAL_TYPE
						? "100%"
						: undefined
				}
				showLoader={showLoader}
			>
				{buildSetupField(field)}
			</SetupFieldWrapper>
		);
	}
);

export default React.memo(SetupField, (prevProps, nextProps) => {
	let { field: prevField, data: prevData } = prevProps;
	let { field: nextField, data: nextData } = nextProps;

	let areEqual = true;
	if (
		prevField.title !== nextField.title ||
		prevField.uuid !== nextField.uuid ||
		prevField.value !== nextField.value ||
		prevField.inputType !== nextField.inputType ||
		prevData[0].objectHierarchy?.length !== nextData[0].objectHierarchy?.length
	)
		return false;

	//Check if there are more children of the field
	let prevChildren = prevData.filter((row) => row.parentUuid === prevField.uuid);
	let nextChildren = nextData.filter((row) => row.parentUuid === nextField.uuid);

	if (prevChildren?.length !== nextChildren?.length) return false;

	return areEqual;
});

/**
 * Used to wrap each setup field with the styling we want, margin, padding, etc.
 * @param children
 * @param width
 * @param other
 * @returns {*}
 * @constructor
 */
const SetupFieldWrapper = ({ children, description, width = "300px", showLoader = false, ...other }) => {
	return (
		<div
			style={{
				margin: "16px",
				width: width ? width : "",
			}}
			className={`flex-fill ${showLoader ? "field-fading" : ""}`}
			{...other}
		>
			{children}
		</div>
	);
};

const LinkField = ({ field, label, data, updateRow, description, ...other }) => {
	const [linkedObject, setLinkedObject] = useState({});
	const [showFieldLinkDialog, setShowFieldLinkDialog] = useState(false);

	useEffect(() => {
		let linked = data.find((row) => row.uuid === field.value);
		if (linked) setLinkedObject(linked);
	}, [field.uuid]);

	const fieldLinkSelected = (row) => {
		if (!row) return;

		if (row) {
			setLinkedObject(row);
			updateRow(field.uuid, "value", row.uuid);
		} else {
			setLinkedObject({});
			updateRow(field.uuid, "value", "");
		}
		setShowFieldLinkDialog(false);
	};

	return (
		<>
			{INPUT_FIELD_TYPES.LINK.render({
				label,
				description,
				link: linkedObject,
				linkClick: (val) => setShowFieldLinkDialog(true),
				removeLink: () => fieldLinkSelected(),
			})}

			{/*Select Dropdown type*/}
			<SelectionDialog
				dialogTitle={`Link '${field.reference} - ${field.title}' to`}
				treeData={data}
				treeTitle={`Data Warehouse`}
				rowSelected={fieldLinkSelected}
				open={showFieldLinkDialog}
				treeHeight={"calc(100% - 117px"}
				// multiSelect={true}
				selectedRows={[linkedObject]}
			/>
		</>
	);
};

export const LabelWithHelpIcon = ({
	reference,
	title,
	description,
	labelClass = "form-label",
	labelFor = "",
	defaultValue,
	getDefault,
	showLoader = false,
	labelStyles = {},
	addElementInlabel,
}) => {
	return (
		<div style={{ justifyContent: "space-between", alignItems: "center" }} className="flex">
			<label
				htmlFor={labelFor}
				className={`${labelClass}`}
				style={{ paddingLeft: "3px", marginBottom: "0px", textAlign: "left", ...labelStyles }}
			>
				{reference ? `${getReferenceNo(reference)}.${title}` : title}
				{addElementInlabel}
			</label>

			<div>
				{defaultValue &&
					(showLoader ? (
						<SimpleLoader />
					) : (
						<button className="btn btn-link p-0" onClick={getDefault} title="Default this field">
							<LightningIcon color="yellow" />
						</button>
					))}
				<Tooltip title={description || "No description found"}>
					<span className="ms-1">
						<InfoIcon color="#777777" />
					</span>
				</Tooltip>
			</div>
		</div>
	);
};

export const FileUploaderSetupField = ({
	label,
	updateRow,
	row,
	submitFile,
	uploadUsingUrl = true,
	showUploader = true,
	focus = false,
	centerContents = false,
}) => {
	const sharedState = useTrackedState();
	//For now, on the setup sheet it will treat both the WOPI file upload and the normal file upload like a WOPI file upload
	const [fileUploaded, setFileUploaded] = useState(
		INPUT_FIELD_TYPES.FILE_UPLOAD.checkIfFileAttribute(row, sharedState) && row.value ? row.title : false
	);
	const [fileUploadedClass, setFileUploadedClass] = useState("");
	const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {
		if (focus && !showDialog) {
			row.openDialog = false;
			// setShowDialog(true);
		}
	}, [focus]);

	const fileLinkClicked = () => {
		fetch(row.value).then((response) => {
			if (response.status === 200) {
				response.json().then((data) => {
					if (data.BaseFileName.split(".").pop() === "zip") {
						const link = document.createElement("a");
						link.href = `${row.value}/contents`;
						link.click();
					} else {
						setShowDialog(true);
					}
				});
			}
		});
	};

	return (
		<>
			{showUploader ? (
				<FileUploader
					label={label}
					uploadUrl={fileServiceUrl}
					updateRow={updateRow}
					fileUploaded={fileUploaded}
					fileUploadedClass={fileUploadedClass}
					setFileUploadedClass={setFileUploadedClass}
					setFileUploaded={setFileUploaded}
					fileLinkClicked={fileLinkClicked}
					focus={focus}
					uploadUsingUrl={uploadUsingUrl}
					submitFile={submitFile}
					centerContents={centerContents}
				/>
			) : (
				<button onClick={fileLinkClicked} className="btn btn-link p-0">
					<PaperClipIcon />
				</button>
			)}
			<WopiDialog row={row} show={showDialog} setShowing={setShowDialog} />
		</>
	);
};

export const WopiDialog = ({ show, setShowing, row }) => {
	const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {
		setShowDialog(show);
	}, [show]);

	return (
		<DraggableDialog
			id={"file-view-dialog"}
			dialogWidth={"1920px"}
			style={{ margin: "auto", height: "75%", minHeight: "75%" }}
			showDialog={showDialog}
			saveButton={false}
			handleClose={(e) => {
				setShowDialog(false);
				if (setShowing) setShowing(false);
			}}
			cancelButtonText={"Close"}
			// noButtons={true}
			header={`Office - ${row?.title}`}
			PaperProps={{ style: { width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%" } }}
		>
			<Wopi location={row?.value} />
		</DraggableDialog>
	);
};

const FileUploader = ({
	label,
	uploadUrl,
	updateRow,
	fileUploaded,
	fileUploadedClass,
	setFileUploadedClass,
	setFileUploaded,
	fileLinkClicked,
	focus,
	uploadUsingUrl = true,
	submitFile,
	centerContents,
}) => {
	const [connected, setConnected] = useState(false);
	const connectionErrorMessage = useRef("");
	const url = useRef("");
	const buttonRef = useRef({});

	useEffect(() => {
		try {
			if (uploadUsingUrl) {
				url.current = uploadUrl();
				setConnected(true);
			} else setConnected(true);
		} catch (error) {
			connectionErrorMessage.current = error.message;
			setConnected(false);
		}
	}, []);

	useEffect(() => {
		if (connected && focus && fileUploaded) buttonRef.current.focus();
	}, [connected]);

	// specify upload params and url for your files
	const getUploadParams = ({ meta }) => {
		return { url: url.current };
	};
	// called every time a file's `status` changes
	const handleChangeStatus = ({ meta, remove, file, xhr }, status) => {
		if (status === "headers_received") {
			setFileUploaded(`${meta.name}`);
			setFileUploadedClass("text-success");
			remove();
		} else if (status === "error_upload_params" && !uploadUsingUrl && submitFile) {
			setFileUploaded(`${meta.name}`);
			setFileUploadedClass("text-success");
			submitFile(file);
			remove();
		} else if (status === "aborted") {
			setFileUploaded("Upload failed...");
			setFileUploadedClass("text-danger");
		} else if (status === "done") {
			updateRow(`${wopiHost()}/wopi/files/${JSON.parse(xhr.response).id}`);
		}
	};

	return (
		<>
			{connected ? (
				<div
					className="flex-fill"
					style={
						centerContents
							? {
									margin: "8px",
									width: "300px",
									textAlign: "center",
							  }
							: {
									margin: "8px",
									width: "300px",
							  }
					}
				>
					{label && <label className="form-label pb-0 ps-1">{label}</label>}
					<div className="row" style={centerContents ? { justifyContent: "center" } : {}}>
						{fileUploaded && uploadUsingUrl && (
							<div className="col-6">
								<button
									ref={buttonRef}
									className={"btn btn-secondary open-upload-button"}
									onClick={fileLinkClicked}
								>
									{fileUploaded}
								</button>
							</div>
						)}
						<div className="col-6">
							<Dropzone
								getUploadParams={getUploadParams}
								onChangeStatus={handleChangeStatus}
								accept="*"
								inputContent={
									!uploadUsingUrl && submitFile && fileUploaded
										? fileUploaded
										: "Drag'n Drop or Click to Upload"
								}
								maxFiles={1}
								multiple={false}
								canCancel={false}
								addClassNames={{ inputLabel: fileUploadedClass }}
							/>
						</div>
					</div>
				</div>
			) : (
				<div style={{ backgroundColor: "#fff9f9", border: "1px solid red", padding: "5px", color: "red" }}>
					<strong>File service not connected: </strong>
					{connectionErrorMessage.current} (Contact BMCL Support)
				</div>
			)}
		</>
	);
};

export const createMatchingHierarchyRecordAndUpdateState = (
	standardObjectUuid,
	standardObjectVersionUuid,
	rowCopy,
	sharedState,
	dispatch,
	hierarchyType,
	updateStockNumber = true
) => {
	//TODO Verify the Object Hierarchy will also be copied
	// let mfiToCopy = await getObjectMfi(standardObjectUuid, standardObjectVersionUuid, dispatch);
	// let topNode = { ...mfiToCopy[0] };
	// let rowsToAdd = copyObjectMfi(mfiToCopy, mfiToCopy[0].uuid, rowCopy.uuid, rowCopy.reference, sharedState.currentUser.uuid);
	rowCopy.standardObjectUuid = standardObjectUuid;
	rowCopy.standardObjectVersionUuid = standardObjectVersionUuid;

	//Create the corresponding hierarchy record
	let hierarchyToAttachTo = findClosestPrimusAncestor(sharedState.contextMfi, rowCopy.reference);
	let ancestorHierarchyRecord = sharedState.contextObjectHierarchy.find(
		(row) =>
			row.descendantStandardObjectUuid === hierarchyToAttachTo.uuid &&
			row.descendantStandardObjectVersionUuid === hierarchyToAttachTo.versionUuid
	);

	let newHierarchyRecord = createObjectHierarchyRecord(ancestorHierarchyRecord, { ...rowCopy });
	if (hierarchyType) newHierarchyRecord.hierarchyTypeUuid = hierarchyType;
	sharedState.contextObjectHierarchy.push(newHierarchyRecord);
	sharedState.contextMfi.push(rowCopy);

	if (updateStockNumber && rowCopy.stockNumber && rowCopy.stockNumber.uuid && dispatch)
		dispatch({ type: "UPDATE_CHANGED_STOCK_NUMBERS", data: rowCopy.stockNumber });

	//Send the change information with the hierarchy record to the global store.
	if (dispatch)
		updateChangeData(
			dispatch,
			{
				objectToUpdate: getTopMostObject(sharedState),
				objectRows: [rowCopy],
				objectHierarchy: [newHierarchyRecord],
			},
			false,
			sharedState.currentUser
		);

	return newHierarchyRecord;
};
