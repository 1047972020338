import { tokenVocabulary, lex } from "./LexerUtil";
import { ExpressionParser } from "./ParserUtil";
import { tokenMatcher } from "chevrotain";

const Plus = tokenVocabulary.Plus;
const Multiply = tokenVocabulary.Multiply;

const parserInstance = new ExpressionParser();
// The base visitor class can be accessed via the a parser instance.
const BaseExpressionVisitor = parserInstance.getBaseCstVisitorConstructor();

const AsyncFunction = new Function(`return Object.getPrototypeOf(async function(){}).constructor`)();

const evaluateFunction = async (functionName, parameters, functions, api, passedParameters) => {
	let fn = functions.find((fn) => fn.name === functionName);
	if (!fn) {
		throw new Error(`Function "${functionName}" is not defined`);
	}
	let fnParamNames = fn.parameter.map((fP) => (fP.repeating === "true" ? `...${fP.name}` : fP.name));
	const newFn = AsyncFunction("api", ...fnParamNames, fn.method);
	// I want to allow the parameters to be passed in as an array, or as individual parameters and transparently change between the two as needed
	// This is definitely not the right way to do this, but it works for now
	// TODO: Fix this
	parameters =
		fn.parameter[fn.parameter.length - 1]?.repeating === "true" && Array.isArray(parameters[0])
			? parameters[0]
			: parameters;
	const executedFn = await newFn(api, ...parameters);
	if (passedParameters) parameters = passedParameters;
	console.log("Function: ", functionName, ", Params: ", parameters, "=> ", executedFn);
	return executedFn;
};
class ExpressionToAstVisitor extends BaseExpressionVisitor {
	constructor(functions, api, passedParameters) {
		super();
		this.validateVisitor();
		this.functions = functions;
		this.api = api;
		this.passedParameters = passedParameters;
	}
	arithmeticExpression(ctx) {
		return this.visit(ctx.additionExpression);
	}
	additionExpression(ctx) {
		let result = this.visit(ctx.lhs);
		if (ctx.rhs) {
			ctx.rhs.forEach((rhsOperand, idx) => {
				// there will be one operator for each rhs operand
				let rhsValue = this.visit(rhsOperand);
				let operator = ctx.AdditionOperator[idx];
				if (tokenMatcher(operator, Plus)) {
					result += rhsValue;
				} else {
					// Minus
					result -= rhsValue;
				}
			});
		}
		return result;
	}
	multiplicationExpression(ctx) {
		let result = this.visit(ctx.lhs);
		// "rhs" key may be undefined as the grammar defines it as optional (MANY === zero or more).
		if (ctx.rhs) {
			ctx.rhs.forEach((rhsOperand, idx) => {
				// there will be one operator for each rhs operand
				let rhsValue = this.visit(rhsOperand);
				let operator = ctx.MultiplicationOperator[idx];
				if (tokenMatcher(operator, Multiply)) {
					result *= rhsValue;
				} else {
					// Division
					result /= rhsValue;
				}
			});
		}
		return result;
	}
	atom(ctx) {
		if (ctx.parenthesisExpression) {
			// passing an array to "this.visit" is equivalent
			// to passing the array's first element
			return this.visit(ctx.parenthesisExpression);
		} else if (ctx.NumberLiteral) {
			// If a key exists on the ctx, at least one element is guaranteed
			return parseInt(ctx.NumberLiteral[0].image, 10);
		} else if (ctx.exponentiationExpression) {
			return this.visit(ctx.exponentiationExpression);
		}
	}
	parenthesisExpression(ctx) {
		// The ctx will also contain the parenthesis tokens, but we don't care about those
		// in the context of calculating the result.
		return this.visit(ctx.arithmeticExpression);
	}
	exponentiationExpression(ctx) {
		let result = this.visit(ctx.lhs);
		if (ctx.rhs) {
			return Math.pow(result, ctx.rhs);
		}
		return result;
	}
	async expression(ctx) {
		if (ctx.fn) {
			return await this.visit(ctx.fn);
		} else {
			return this.visit(ctx.arithmeticExpression);
		}
	}
	async fn(ctx) {
		return await evaluateFunction(
			ctx.FunctionName[0].image,
			(await this.visit(ctx.parameters)) || [],
			this.functions,
			this.api,
			this.passedParameters
		);
	}
	async parameters(ctx) {
		if (ctx.parameter) {
			return await Promise.all(ctx.parameter.map(async (p) => await this.visit(p)));
		}
	}
	async parameter(ctx) {
		if (ctx.expression) {
			return await this.visit(ctx.expression);
		}
		if (ctx.StringLiteral) {
			// When I want to care about datatypes, I can do it like this
			// return {
			// type: "StringLiteral",
			//   value: ctx.StringLiteral[0].image,
			// }
			return JSON.parse(ctx.StringLiteral[0].image);
		}
		if (ctx.JavaScriptObject) {
			return JSON.parse(ctx.JavaScriptObject[0].image);
		}
		if (ctx.BooleanLiteral) {
			return JSON.parse(ctx.BooleanLiteral[0].image);
		}
		if (ctx.JavaScriptArray) {
			return JSON.parse(ctx.JavaScriptArray[0].image);
		}
	}
}
const toAst = (inputText, functions, api, passedParameters) => {
	const toAstVisitorInstance = new ExpressionToAstVisitor(functions, api, passedParameters);
	const lexResult = lex(inputText);
	// ".input" is a setter which will reset the parser's internal's state.
	parserInstance.input = lexResult.tokens;
	// Automatic CST created when parsing
	const cst = parserInstance.expression();
	if (parserInstance.errors.length > 0) {
		throw Error("Sad sad panda, parsing errors detected!\n" + parserInstance.errors[0].message);
	}
	const ast = toAstVisitorInstance.visit(cst);
	return ast;
};
export default toAst;
