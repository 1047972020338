import React, { useState, useEffect, useRef } from "react";
import CreatorPanel from "../components/ReactGridComponents/Body/CreatorPanel/CreatorPanel";
import { DestinationModelTree } from "../components/ReactGridComponents/Body/DestinationPanel/DestinationPanel";
import VisualRepPanel, { TopCenterHeader } from "../components/ReactGridComponents/VisualRepPanel/VisualRepPanel";
import Panel, { DynamicPanel } from "../components/ReactGridComponents/Panel/Panel";
import PanelOverlay from "../components/PanelOverlay/PanelOverlay";
import {
	ObjectHeader,
	updateChangeData,
} from "../components/ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import RecursiveTreeView from "../components/Tree/Tree";
import ReactGrid, { gridColumns } from "../components/ReactGrid/ReactGrid";
import { v4 as uuidv4 } from "uuid";
import { promptForUnsavedChanges, ZERO_ROW_UUID } from "../utils/StandardObject";
import { getSingleLevelObjectMfi } from "./ApiUtils";
import { getSmallRef } from "../utils/StringUtils";
import DataWarehousePanel from "../components/ReactGridComponents/Panel/DataWarehousePanel";
import DestinationAndVisualRepPanel from "../components/ReactGridComponents/Panel/DestinationAndVisualRepPanel";
import DestinationObjectAndVisualRepPanel from "../components/ReactGridComponents/Panel/DestinationObjectAndVisualRepPanel";
import ObjectMasterFileIndexPanel from "../components/ReactGridComponents/Panel/ObjectMasterFileIndexPanel";
import { CloseIcon, PlusIcon } from "../components/BootstrapComponents/Icons/Icons";
import DebugPanel from "../components/ReactGridComponents/DebugPanel/DebugPanel";

const addPanel = (dispatch, panelId) => {
	return;
	dispatch({ type: "UPDATE_LAYOUT", data: { panelId, type: "addNew" } });
};

export const PANEL_CONTENT_TYPES = {
	CREATOR_PANEL: {
		uuid: "eac5cbc2-245f-4657-ac0c-f08dc698ca32",
		title: "Creator Panel",
		description: "",
		x: 103,
		y: 0,
		w: 636,
		h: 1,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: (params, children) => {
			return <CreatorPanel params={params}>{children}</CreatorPanel>;
		},
	},
	DATA_WAREHOUSE_PANEL: {
		uuid: "4cb3c770-1ea1-4723-aab6-c9eec2d5d79c",
		title: "Data Warehouse Panel",
		description: "",
		x: 0,
		y: 0,
		h: 1,
		w: 420,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ panelId, mfi, rowSelected, addChangedRows, panelGroup }, sharedState, dispatch) => {
			return (
				<DataWarehousePanel
					panelId={panelId}
					panelGroup={panelGroup}
					mfi={mfi}
					rowSelected={rowSelected}
					sharedState={sharedState}
					dispatch={dispatch}
				/>
			);
		},
	},
	DATA_WAREHOUSE_WITHOUT_PANEL: {
		uuid: "4cb3c770-1ea1-4723-aab6-c9eec2d5d79c",
		title: "Data Warehouse Panel",
		description: "",
		x: 0,
		y: 0,
		h: 1,
		w: 420,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ panelId, panelGroup, mfi, rowSelected, addChangedRows }, sharedState, dispatch) => {
			return (
				<DataWarehousePanel
					panelId={panelId}
					panelGroup={panelGroup}
					mfi={mfi}
					rowSelected={rowSelected}
					sharedState={sharedState}
					dispatch={dispatch}
					withPanel={false}
				/>
			);
		},
	},
	DESTINATION_AND_VISUAL_REP_PANEL: {
		uuid: "c3fa3211-d49f-44f1-83d9-45ce6f4ebfce",
		title: "Mfi And Visual Rep",
		description: "",
		x: 0,
		y: 0,
		h: 1,
		w: 840,
		layoutProps: { dynamicHeight: true, resizable: true, isBounded: true },
		passAllowResizingToChildren: true,
		Render: ({ panelId, panelGroup, allowResizing }, sharedState, dispatch) => {
			return (
				<DestinationAndVisualRepPanel panelId={panelId} panelGroup={panelGroup} allowResizing={allowResizing} />
			);
		},
	},
	DESTINATION_MODEL_PANEL: {
		uuid: "79dbabb0-6cba-4056-9ff9-aa7d70c35ba8",
		title: "Destination Panel",
		description: "",
		x: 0,
		y: 0,
		w: 420,
		h: 1,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: (
			{ panelId, panelGroup, panelTitle = "Destination Model", rowSelected, allowDrop, handleTreeDrop },
			sharedState,
			dispatch
		) => {
			return (
				<DynamicPanel
					panelTitle={panelTitle}
					menuItems={[
						{
							id: "panel-types-change-destination-model",
							label: "Change Destination Model",
							onClick: () => {
								delete localStorage.destinationModel;
								dispatch({ type: "UPDATE_DESTINATION_MODEL", data: { mfi: [] } });
							},
						},
						{
							id: "panel-types-add-panel",
							label: "Add Panel",
							icon: <PlusIcon />,
							onClick: (dispatch) => addPanel(dispatch, panelId),
						},
					]}
					panelId={panelId}
					panelGroup={panelGroup}
				>
					<DestinationModelTree rowSelected={rowSelected} allowDrop={allowDrop} handleDrop={handleTreeDrop} />
				</DynamicPanel>
			);
		},
	},
	DESTINATION_MODEL_WITHOUT_PANEL: {
		uuid: "3cabb6d3-0815-4299-bf00-b9fb467fd662",
		title: "Destination Panel",
		description: "",
		x: 739,
		y: 0,
		w: 101,
		h: 1,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ panelId, rowSelected, panelGroup }, sharedState, dispatch) => {
			return (
				<>
					<TopCenterHeader text={"Data Warehouse"} className={panelGroup} />
					<DestinationModelTree rowSelected={rowSelected} />
				</>
			);
		},
	},
	DESTINATION_OBJECT_AND_VISUALREP_PANEL: {
		uuid: "c3fa3211-d49f-44f1-83d9-45ce6f549e1t",
		title: "Mfi And Visual Rep",
		description: "",
		x: 0,
		y: 0,
		h: 1,
		w: 420,
		layoutProps: { dynamicHeight: true, resizable: true, isBounded: true },
		passAllowResizingToChildren: true,
		Render: (
			{ panelId, mfi, addChangedRows, panelGroup, panelTitle = "Object Master File Index", allowResizing },
			sharedState,
			dispatch
		) => {
			return (
				<DestinationObjectAndVisualRepPanel
					panelId={panelId}
					mfi={mfi}
					addChangedRows={addChangedRows}
					panelGroup={panelGroup}
					panelTitle={panelTitle}
					allowResizing={allowResizing}
				/>
			);
		},
	},
	DESTINATION_PANEL: {
		uuid: "79dbabb0-6cba-4056-9ff9-aa7d70c35ba8",
		title: "Destination Panel",
		description: "",
		x: 739,
		y: 0,
		w: 101,
		h: 1,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ panelId, panelGroup }) => {
			return (
				<DynamicPanel
					panelTitle={"Destination"}
					menuItems={[
						{
							id: "destination-add-panel",
							label: "Add Panel",
							icon: <PlusIcon />,
							onClick: (dispatch) => addPanel(dispatch, panelId),
						},
					]}
					panelId={panelId}
					panelGroup={panelGroup}
					allowResizeToggle={true}
				/>
			);
		},
	},
	DEBUG_PANEL: {
		uuid: "b49d35c2-6e93-4c46-9aee-5a1bb3160ad1",
		title: "Debug Panel",
		description: "",
		x: 739,
		y: 0,
		w: 101,
		h: 1,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ panelId, panelGroup }) => {
			return (
				<DebugPanel panelTitle={"Debug"} panelId={panelId} panelGroup={panelGroup} allowResizeToggle={true} />
			);
		},
	},
	NEW_PANEL: {
		uuid: uuidv4(),
		title: "New Panel",
		description: "",
		x: 0,
		y: 0,
		w: 210,
		h: 1,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ panelId, children, panelGroup }) => {
			return (
				<DynamicPanel
					panelTitle={"New Panel"}
					menuItems={[
						{
							id: "new-panel-add-panel",
							label: "Add Panel",
							icon: <PlusIcon />,
							onClick: (dispatch) => addPanel(dispatch, panelId),
						},
					]}
					panelId={panelId}
					panelGroup={panelGroup}
				>
					{children}
				</DynamicPanel>
			);
		},
	},
	SOURCE_PANEL: {
		uuid: "38ee166d-a389-41ad-a881-45c64f4ebfce",
		title: "Source Panel",
		description: "",
		x: 0,
		y: 0,
		w: 103,
		h: 1,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ panelId, panelGroup }) => {
			return (
				<DynamicPanel
					panelTitle={"Source"}
					menuItems={[
						{
							id: "source-add-panel",
							label: "Add Panel",
							icon: <PlusIcon />,
							onClick: (dispatch) => addPanel(dispatch, panelId),
						},
					]}
					panelId={panelId}
					panelGroup={panelGroup}
					allowResizeToggle={true}
				/>
			);
		},
	},
	VISUALREP_PANEL: {
		uuid: "c3fa3211-d49f-44f1-83d9-45ce6f4ebfce",
		title: "Visual Rep Panel",
		description: "",
		x: 420,
		y: 0,
		h: 1,
		w: 420,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ uuid, versionUuid, panelContentTitle, panelTitle, reference, panelGroup, panelId }) => {
			return (
				<VisualRepPanel
					uuid={uuid}
					reference={reference}
					referenceType={"PKT"}
					versionUuid={versionUuid}
					panelContentTitle={panelContentTitle}
					panelGroup={panelGroup}
					panelTitle={panelTitle}
					panelId={panelId}
				></VisualRepPanel>
			);
		},
	},
	VISUALREP_WITHOUT_PANEL: {
		uuid: "c3fa3211-d49f-44f1-83d9-45ce6f4ebfce",
		title: "Visual Rep Panel",
		description: "",
		x: 420,
		y: 0,
		h: 1,
		w: 420,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ uuid, versionUuid, panelContentTitle, reference, panelGroup, panelId, panelTitle }) => {
			return (
				<VisualRepPanel
					uuid={uuid}
					reference={reference}
					referenceType={"DW"}
					versionUuid={versionUuid}
					panelContentTitle={panelContentTitle}
					panelGroup={panelGroup}
					panelId={panelId}
					surroundWithPanel={false}
					panelTitle={panelTitle}
				/>
			);
		},
	},
	DATA_WAREHOUSE_WITH_OBJECT_VIEWS_PANEL: {
		uuid: "c3fa3211-d49f-44f1-83d9-45ce6f4ebfce",
		title: "Mfi And Visual Rep",
		description: "",
		x: 0,
		y: 0,
		h: 1,
		w: 840,
		layoutProps: { dynamicHeight: true, resizable: true, isBounded: true },
		passAllowResizingToChildren: true,
		Render: ({ panelId, mfi, addChangedRows, panelGroup, allowResizing }, sharedState, dispatch) => {
			const [uuidAndVersion, setUuidAndVersion] = useState({});
			const [layout, setLayout] = useState([]);
			const object = useRef({});

			useEffect(() => {
				let dw = buildPanelLocation("DATA_WAREHOUSE_PANEL", PANEL_CONTENT_TYPES.DATA_WAREHOUSE_PANEL.uuid);
				dw.w = gridColumns;
				setLayout([dw]);
			}, []);

			const rowSelected = (row) => {
				if (
					row.standardObjectUuid &&
					row.standardObjectVersionUuid &&
					uuidAndVersion.uuid != row.standardObjectUuid &&
					uuidAndVersion.versionUuid != row.standardObjectVersionUuid
				) {
					object.current = { title: row.title, reference: row.reference };
					setUuidAndVersion({ uuid: row.standardObjectUuid, versionUuid: row.standardObjectVersionUuid });
					setLayout([
						{
							...buildPanelLocation(
								"DATA_WAREHOUSE_PANEL",
								PANEL_CONTENT_TYPES.DATA_WAREHOUSE_PANEL.uuid
							),
							w: gridColumns / 2,
						},
						{
							...buildPanelLocation(
								"OBJECT_MASTER_FILE_INDEX_AND_VISUALREP_PANEL",
								PANEL_CONTENT_TYPES.OBJECT_MASTER_FILE_INDEX_AND_VISUALREP_PANEL.uuid
							),
							w: gridColumns / 2,
						},
					]);
				} else {
					object.current = {};
					setUuidAndVersion({});
					let dw = buildPanelLocation("DATA_WAREHOUSE_PANEL", PANEL_CONTENT_TYPES.DATA_WAREHOUSE_PANEL.uuid);
					dw.w = gridColumns;
					setLayout([dw]);
				}
			};

			return (
				<DynamicPanel
					// panelTitle={panelTitle}
					panelTitle={"Data Warehouse"}
					panelId={panelId}
					panelGroup={panelGroup}
					allowResizeToggle={false}
				>
					<ReactGrid
						layoutId={panelId}
						layout={layout}
						dynamicHeight={true}
						allowResize={allowResizing}
						draggableHandle={`.${panelGroup}-subPanel`}
						draggable={allowResizing}
						isBounded={false}
					>
						<div key={PANEL_CONTENT_TYPES.DATA_WAREHOUSE_WITHOUT_PANEL.uuid}>
							{PANEL_CONTENT_TYPES.DATA_WAREHOUSE_WITHOUT_PANEL.Render(
								{
									panelId,
									mfi,
									addChangedRows,
									panelGroup: panelGroup + "-subPanel",
									rowSelected,
								},
								sharedState,
								dispatch
							)}
						</div>
						{uuidAndVersion.uuid && uuidAndVersion.versionUuid ? (
							<div
								key={PANEL_CONTENT_TYPES.OBJECT_MASTER_FILE_INDEX_AND_VISUALREP_PANEL.uuid}
								className={!uuidAndVersion.uuid || !uuidAndVersion.versionUuid ? "d-none" : ""}
							>
								{PANEL_CONTENT_TYPES.OBJECT_MASTER_FILE_INDEX_AND_VISUALREP_PANEL.Render(
									{
										panelId,
										...uuidAndVersion,
										...object.current,
										panelGroup: panelGroup + "-subPanel",
										panelTitle: "Visual Presentation",
										panelContentTitle: object.current.title,
									},
									sharedState,
									dispatch
								)}
							</div>
						) : (
							""
						)}
					</ReactGrid>
				</DynamicPanel>
			);
		},
	},
	SOURCE_OBJECT_TEMPLATE_PANEL: {
		uuid: "c3fa3211-d49f-44f1-83d9-5fe6cd7d8025",
		title: "Object Template Panel",
		description: "",
		x: 560,
		y: 0,
		h: 1,
		w: 280,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ panelId, panelGroup }, sharedState, dispatch) => {
			const [sourceObjectMfi, setSourceObjectMfi] = useState([]);
			const [sourceObject, setSourceObject] = useState({});
			useEffect(() => {
				let {
					contextTop: top,
					contextTemplateRow: templateRow,
					contextTemplateMfi: templateMfi,
					packet,
				} = sharedState;

				let uuid, versionUuid;
				//If a packet is selected grab the packet rather than the source of the top
				if (packet && packet.uuid) {
					uuid = packet.standardObjectUuid;
					versionUuid = packet.standardObjectVersionUuid;
				}
				//There is one case where the templateRow is set at the same time as the top uuid changes, check for this case
				else if (
					templateRow &&
					templateMfi &&
					templateRow.uuid === top.standardObjectUuid &&
					templateRow.versionUuid === top.standardObjectVersionUuid
				) {
					setSourceObject(templateRow);
					setSourceObjectMfi(templateMfi);
					return;
				}
				//Do I need to check the version as well at the end of this if?
				else if (
					(!top.uuid && top.standardObjectUuid) ||
					(top.uuid !== top.standardObjectUuid &&
						top.versionUuid !== top.standardObjectVersionUuid &&
						top.standardObjectUuid !== sourceObject.uuid)
				) {
					uuid = top.standardObjectUuid;
					versionUuid = top.standardObjectVersionUuid;
				}
				//When a context is set and has a different source object than itself
				else if (!top.uuid || top.standardObjectUuid === top.uuid) {
					setSourceObjectMfi([]);
					setSourceObject({});

					dispatch({
						type: "SET_CONTEXT_OR_MFI",
						data: {
							templateRow: {},
							templateMfi: [],
						},
					});
					return;
				}
				getMfiAndUpdateState(uuid, versionUuid);
			}, [
				sharedState.contextTop?.uuid,
				sharedState.contextTop?.standardObjectUuid,
				sharedState.contextTop?.standardObjectVersionUuid,
			]);

			useEffect(() => {
				let { contextPacket: packet } = sharedState;
				if (packet && packet.uuid)
					getMfiAndUpdateState(packet.standardObjectUuid, packet.standardObjectVersionUuid);
			}, [sharedState.contextPacket?.uuid]);

			const getMfiAndUpdateState = (uuid, versionUuid) => {
				getSingleLevelObjectMfi(uuid, versionUuid).then((objMfi) => {
					if (!objMfi) return;

					let obj = objMfi.find((row) => row.uuid === uuid && row.versionUuid === versionUuid);
					//If we couldn't find an object in the mfi with a matching uuid and version check for one with just a matching uuid
					if (!obj) obj = objMfi.find((row) => row.uuid === uuid);

					if (!obj || (sourceObject.uuid === obj.uuid && sourceObject.versionUuid === obj.versionUuid))
						return;

					obj.reference = "";
					// //TODO: For some reason the parent uuid for top objects is set to null and in order to show the top node
					// //it needs a non-null parent
					obj.parentUuid = ZERO_ROW_UUID;
					setSourceObjectMfi(objMfi);
					setSourceObject(obj);

					dispatch({
						type: "SET_CONTEXT_OR_MFI",
						data: {
							templateRow: obj,
							templateMfi: objMfi,
						},
					});
				});
			};

			const rowClicked = async (selected) => {
				//Verify the selected row is an object and has no children
				let children = sourceObjectMfi.filter((row) => row.parentUuid === selected.uuid);
				if (!selected.isObject || children.length > 0) return;

				//Get the hierarchy record
				let hierarchyRecord = sourceObject.objectHierarchy.find(
					(row) =>
						row.descendantStandardObjectUuid === selected.uuid &&
						row.descendantStandardObjectVersionUuid === selected.versionUuid
				);

				//Get the MFI, attach it to the sourceMfi
				let subMfi = await getSingleLevelObjectMfi(selected.uuid, selected.versionUuid, dispatch, null, {
					...selected,
					objectHierarchy: [hierarchyRecord],
				});

				//Update the sourceObjects hierarchy as well
				setSourceObject({
					...sourceObject,
					objectHierarchy: [...sourceObject.objectHierarchy, ...subMfi[0].objectHierarchy],
				});

				setSourceObjectMfi([...sourceObjectMfi, ...subMfi.slice(1)]);
			};

			return (
				<PanelOverlay
					elementId={"c3fa3211-d49f-44f1-83d9-5fe6cd7d8025"}
					getMessage={() => "Drop to load this object (Clears the workspace data)"}
					handleDrop={(event) => {
						let dragObj;
						try {
							dragObj = JSON.parse(event.dataTransfer.getData("text"));
						} catch (e) {
							return;
						}

						dispatch({ type: "SET_SHOW_LOADING_BAR", data: true });

						//If there's no origin then return because we don't know where it came from
						if (!dragObj.origin) {
							dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
							return;
						}

						//Check if we came from the data warehouse panel
						if (dragObj.origin === "datawarehouse-panel" || dragObj.origin === "models-panel") {
							//Verify there aren't any changes that need committed before loading the new template and erasing the context
							let { thereAreChanges, discard } = promptForUnsavedChanges(sharedState);

							if (
								(!discard && thereAreChanges) ||
								(!dragObj.standardObjectUuid && !dragObj.standardObjectVersionUuid) ||
								(sharedState.contextTemplateRow?.uuid === dragObj.standardObjectUuid &&
									sharedState.contextTemplateRow?.versionUuid === dragObj.standardObjectVersionUuid)
							) {
								dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
								return;
							}

							//We will now update the destination with the current context
							let newDestination = sharedState.contextTop;
							if (sharedState.contextPacket && sharedState.contextPacket.uuid)
								newDestination = sharedState.contextPacket;
							else if (
								sharedState.contextAncestorObjects &&
								sharedState.contextAncestorObjects.length > 0
							)
								newDestination = sharedState.contextAncestorObjects[0];

							//Replace the templateRow and templateMfi state variables with the dropped object and it's object MFI
							dispatch({
								type: "SET_CONTEXT_OR_MFI",
								data: {
									top: {
										standardObjectUuid: dragObj.standardObjectUuid,
										standardObjectVersionUuid: dragObj.standardObjectVersionUuid,
									},
									mfi: [],
									packet: {},
									destinationObject: newDestination,
								},
							});

							//Reset changes?
							updateChangeData(dispatch, { reset: true }, true);
						}
						dispatch({ type: "SET_SHOW_LOADING_BAR", data: false });
					}}
				>
					<Panel
						panelTitle={"Preview"}
						panelContentTitle={sourceObject.title}
						menuItems={[
							{
								id: "preview-add-panel",
								label: "Add Panel",
								icon: <PlusIcon />,
								onClick: (dispatch) => addPanel(dispatch, panelId),
							},
						]}
						panelId={panelId}
						panelGroup={panelGroup}
						id={"source-object-panel"}
						panelStockNumberStyles={{
							position: "relative",
							top: "0px",
							display: "inline-block",
							marginTop: "-20px",
							width: "100%",
						}}
						panelStockNumber={
							sourceObject?.title ? (
								<ObjectHeader
									objectTitle={""}
									reference={sourceObject.mfiReference}
									referenceType={"DW"}
									stockNumber={sourceObject.stockNo}
								/>
							) : (
								""
							)
						}
					>
						<RecursiveTreeView
							data={sourceObjectMfi}
							treeTitle={sourceObject.title || "Object MFI"}
							topNode={sourceObject}
							getObjMfiOnDrop={true}
							topNodeId={sourceObject.uuid}
							emptyMessage="Click an object in the Data Warehouse to preview."
							// droppable={true}
							draggable={true}
							// editable={true}
							className={`tree`}
							origin={"template-panel"}
							rowSelected={rowClicked}
							// ref={templateTreeContainer}
						/>
					</Panel>
				</PanelOverlay>
			);
		},
	},
	OBJECT_MASTER_FILE_INDEX_PANEL: {
		uuid: "f3b817ca-f288-4880-b2b5-bec93b51058e",
		title: "Object Master File Index Panel",
		description: "",
		x: 420,
		y: 0,
		w: 420,
		h: 1,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: (
			{
				panelId,
				uuid,
				versionUuid,
				panelTitle = "Object Master File Index",
				panelGroup,
				reference,
				referenceType,
				origin,
				allowResizeToggle = true,
				menuItems = [],
			},
			sharedState,
			dispatch
		) => {
			return (
				<ObjectMasterFileIndexPanel
					uuid={uuid}
					versionUuid={versionUuid}
					panelGroup={panelGroup}
					panelId={panelId}
					menuItems={menuItems}
					panelTitle={panelTitle}
					allowResizeToggle={allowResizeToggle}
					reference={reference}
					referenceType={referenceType}
					origin={origin}
				/>
			);
		},
	},
	OBJECT_MASTER_FILE_INDEX_WITHOUT_PANEL: {
		uuid: "f3b817ca-f288-4880-b2b5-bec93b51058e",
		title: "Object Master File Index Panel",
		description: "",
		x: 420,
		y: 0,
		w: 420,
		h: 1,
		layoutProps: { resizable: true, dynamicHeight: true, isBounded: true },
		Render: ({ uuid, versionUuid, mfi, panelId, panelGroup }, sharedState, dispatch) => {
			return (
				<ObjectMasterFileIndexPanel
					uuid={uuid}
					versionUuid={versionUuid}
					mfi={mfi}
					panelId={panelId}
					panelGroup={panelGroup}
					surroundWithPanel={false}
				/>
			);
		},
	},
};

export const buildPanelLocation = (key, panelId, { x: _x, y: _y, h: _h, w: _w } = {}) => {
	let { x, y, h, w, layoutProps } = PANEL_CONTENT_TYPES[key];
	if (_x !== undefined) x = _x;
	if (_y !== undefined) y = _y;
	if (_h !== undefined) h = _h;
	if (_w !== undefined) w = _w;
	return { x, y, h, w, i: panelId, ...layoutProps };
};

export const getSubPanel = (subPanelKey, layouts) => {
	let subPanel,
		subPanelProps = {};
	if (subPanelKey.panelId) {
		let { panelId, ...other } = subPanelKey;
		subPanel = layouts[panelId];
		subPanelProps = other;
	} else {
		subPanel = layouts[subPanelKey];
	}
	return { subPanel, subPanelProps };
};

export const buildPanelContent = (
	{ panelKey, data = [], subPanels, gridProps, id },
	layout,
	panelGroup,
	sharedState,
	dispatch
) => {
	let panel = PANEL_CONTENT_TYPES[panelKey];
	/**
	 * subPanels = ['datawarehouse1', 'sourceObject1'];
	 * layout[panel] ('datawarehouse1') = [
	 *      {
	 *          id: ...,
	 *          panelKey: 'DATAWAREHOUSE_PANEL',
	 *      },
	 *      {
	 *          id: ...,
	 *          panelKey: 'Source Object Panel',
	 *      }
	 * ]
	 */
	let children;
	if (subPanels && subPanels.length > 0) {
		// let childrenLayout = [];
		// children = subPanels?.map((panel) => {
		//     let subPanel = layout[panel];
		//     childrenLayout.push(buildPanelLocation(subPanel.panelKey));
		//     return buildPanelContent(subPanel, layout)
		// });
		// children = (
		//     <ReactGrid
		//         layout={childrenLayout}
		//         {...gridProps}
		//     >
		//         {children}
		//     </ReactGrid>
		// )
	}

	return <div key={id}>{panel.Render({ ...data, panelId: id, panelGroup }, sharedState, dispatch)}</div>;
};
