import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useTrackedState } from "../../utils/store";
import { createSuggestedChangeObject, getPossibleSourceObjects } from "../VersionControl/VersionUtils";
import DraggableDialog from "./DraggableDialog/Dialog";
import Version from "../VersionControl/Version";
import { getTopMostObject } from "../ReactGridComponents/Body/CreatorPanel/CreatorPanel";

import "./ChangeRequestSelectionDialog.scss";
import { CopyIcon, DownArrowIcon, ExclamationTriangle } from "../BootstrapComponents/Icons/Icons";
import { getSmallRef } from "../../utils/StringUtils";
import {
	createAssociatedObjectCopy,
	createAssociatedObjectHierarchyRecord,
	createNewObject,
	createObjectHierarchyRecord,
	ZERO_ROW_UUID,
} from "../../utils/StandardObject";
import SelectionDialog from "./DraggableDialog/SelectionDialog/SelectionDialog";
import {
	_updateRow,
	updateChangeData,
} from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import BasicTreeWithSearch from "../Tree/BasicTreeWithSearch";
import { getNextRef, sortByReference } from "../../utils/Referencing";
import { getObjectGitRecord, getSingleLevelObjectMfi } from "../../utils/ApiUtils";
import HorizontalTabs from "../ReactGridComponents/HorizontalTabs/HorizontalTabs";
import { TwoTreeDialog } from "./TwoTreeDialog";

export const AddDataWarehouseObjectsToPacketDialog = ({ packet, toc, sources }) => {
	const [selectDataWarehouseObjectDialog, setSelectDataWarehouseObjectDialog] = useState(false);
	const [selectTocLocationDialog, setSelectTocLocationDialog] = useState(false);
	const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
	const [filterByAffectedObjects, setFilterByAffectedObjects] = useState(false);

	const activeTabRef = useRef(0);
	const linkOrCopy = useRef("");
	const selectedRef = useRef({});
	const attachmentTree1 = useRef([]);

	const sharedState = useTrackedState();
	const dispatch = useDispatch();

	//On mount, grab the Data Warehouse MFI
	useEffect(() => {}, []);

	useEffect(() => {
		if (sharedState.addObjectsToPacketDialog) setSelectDataWarehouseObjectDialog((prev) => !prev);
	}, [sharedState.addObjectsToPacketDialog]);

	const handleSave = () => {};

	const handleClose = () => {
		linkOrCopy.current = "";
		selectedRef.current = {};
		setSelectDataWarehouseObjectDialog(false);
	};

	const attachAsLink = async (row) => {
		linkOrCopy.current = "link";
		selectedRef.current = row;
		// setSelectDataWarehouseObjectDialog(false);
		//Create an associated link between the packet and the row selected
		//Only pull up the attachment dialog for packets from the data warehouse
		if (activeTabRef.current === 0 && row.objectTypeUuid === sharedState.dbConstants.packetObject.referenceUuid) {
			// if(packet.standardObjectUuid === sharedState.dbConstants.changeRequestPacket?.referenceUuid)
			// {
			// 	return;
			// }

			attachmentTree1.current = await getSingleLevelObjectMfi(
				row.standardObjectUuid,
				row.standardObjectVersionUuid
			);
			setSelectDataWarehouseObjectDialog(false);
			setShowAttachmentDialog(true);
		} else setSelectTocLocationDialog(true);
	};

	const attachCopy = (row) => {
		linkOrCopy.current = "copy";
		selectedRef.current = row;
		// setSelectDataWarehouseObjectDialog(false);
		setSelectTocLocationDialog(true);
	};

	const tocLocationSelected = async (row) => {
		setSelectTocLocationDialog(false);

		let gitRecord;
		//0 is data warehouse, which means we're looking at MFI records so the standardObjectUuid and version would point to the object
		if (activeTabRef.current === 0)
			gitRecord = await getObjectGitRecord(
				selectedRef.current.standardObjectUuid,
				selectedRef.current.standardObjectVersionUuid
			);
		/**
		 * In the case of 1:
		 * 	Move the record to the new location, create a copy in the place it used to be that is now a link to the object (associated object)
		 * 	Rather than an actual sub-object
		 */ else if (activeTabRef.current === 1) gitRecord = selectedRef.current;

		//If a row was sent back attach the selected object as a link or copy inside the selected row
		//Get the reference for the row
		let ref, parentUuid;
		//If the selected row is an object, get its parent and attach as the last sibling of the row
		if (row.isObject) {
			parentUuid = row.parentUuid;
			let siblings = toc.filter((row) => row.parentUuid === parentUuid);
			ref = getNextRef(siblings[siblings.length - 1].reference);
		} else {
			parentUuid = row.uuid;
			let siblings = toc.filter((row) => row.parentUuid === parentUuid);
			if (siblings.length > 0) ref = getNextRef(siblings[siblings.length - 1].reference);
			else ref = getNextRef(row.reference + ".00");
		}

		//Now create a copy of the row (either as a link or as a new sub-object) updating the parent and reference
		let copy,
			hierarchyRecord,
			ancestorHierarchyRecord = toc[0].objectHierarchy.find(
				(row) =>
					row.descendantStandardObjectUuid === packet.uuid &&
					row.descendantStandardObjectVersionUuid === packet.versionUuid
			);

		let objectRows = [];
		switch (linkOrCopy.current) {
			case "link":
				copy = createAssociatedObjectCopy(gitRecord, { ...row, uuid: parentUuid }, ref);
				hierarchyRecord = createAssociatedObjectHierarchyRecord(
					ancestorHierarchyRecord,
					gitRecord.objectHierarchy[0],
					gitRecord
				);
				copy.associatedObject = true;
				objectRows.push(copy);

				//If the object we're linking is a packet, pull up a dialog and let the user drag anything from the source packet to the destination packet

				break;
			case "copy":
				if (activeTabRef.current === 0) {
					//If the packet is a change request packet, attach the copy as a suggested change, This should really happen if they link something from the data warehouse too,
					//It would attach the link and this to the different sections
					if (packet.standardObjectUuid === sharedState.dbConstants.changeRequestPacket?.referenceUuid) {
						let suggestion = await createSuggestedChangeObject(
							gitRecord,
							ref,
							{ ...row, uuid: parentUuid },
							ancestorHierarchyRecord,
							sharedState.currentUser?.uuid
						);
						copy = suggestion.object;
						hierarchyRecord = suggestion.hierarchyRecord;
					} else {
						copy = await createNewObject({ object: gitRecord, ref, parent: { ...row, uuid: parentUuid } });
						hierarchyRecord = createObjectHierarchyRecord(ancestorHierarchyRecord, copy);
					}

					copy.isObject = true;
					objectRows.push(copy);
				} else if (activeTabRef.current === 1) {
					/**
					 * The reason we do this when creating a copy of something within the packet and placing it somewhere else is because
					 * we need to in order for the BMCL Suggested Change in a Change Request Packet to work. I decided to make this the default behavior
					 * for packets in general. Right now this is the only case we use it.
					 */
					//Create a linking record in the record's current location
					copy = createAssociatedObjectCopy(
						gitRecord,
						{ ...gitRecord, uuid: gitRecord.parentUuid },
						gitRecord
					);
					hierarchyRecord = createAssociatedObjectHierarchyRecord(
						ancestorHierarchyRecord,
						toc[0].objectHierarchy.find(
							(row) =>
								row.descendantStandardObjectUuid === packet.uuid &&
								row.descendantStandardObjectVersionUuid === packet.versionUuid
						),
						gitRecord
					);
					//In this case we want the object hierarchy record to maintain the version we decide
					hierarchyRecord.autoUpdate = false;

					copy.associatedObject = true;
					copy.readonly = true;
					objectRows.push(copy);

					//Update gitRecord, give it new parent and reference
					gitRecord.parentUuid = parentUuid;
					gitRecord.reference = ref.reference;
					gitRecord.referenceNo = ref.referenceNo;
					objectRows.push(gitRecord);
				}
				break;
		}

		//Update the toc
		toc.push(copy);
		toc.sort(sortByReference);

		//Send changes to global state
		let topMost = getTopMostObject(sharedState);
		updateChangeData(dispatch, {
			objectToUpdate: topMost,
			objectRows,
			objectHierarchy: [hierarchyRecord],
			subObjectToUpdate: topMost.uuid !== packet.uuid ? packet : undefined,
		});
	};

	const tabClicked = (index) => {
		activeTabRef.current = index;
	};

	const attachDialogSave = (changeMap) => {
		setSelectDataWarehouseObjectDialog(true);
		setShowAttachmentDialog(false);

		let newRows = [];
		let newHierarchyRecords = [];
		let ancestorHierarchyRecord = toc[0].objectHierarchy.find(
			(row) =>
				row.descendantStandardObjectUuid === packet.uuid &&
				row.descendantStandardObjectVersionUuid === packet.versionUuid
		);
		//How do I add the change rows to the changed state?
		//For each row generate the corresponding hierarchy record
		[...changeMap.values()].forEach((obj) => {
			let matchingHierarchy = toc[0].objectHierarchy.find(
				(row) =>
					row.descendantStandardObjectUuid === obj.uuid &&
					row.descendantStandardObjectVersionUuid === obj.versionUuid
			);

			if (matchingHierarchy || !(obj.isAssociatedObject || obj.isObject)) {
				newRows.push(obj);
				return;
			}

			//If it's an associated object build an associated hierarchy record
			if (obj.isAssociatedObject)
				newHierarchyRecords.push(
					createAssociatedObjectHierarchyRecord(
						ancestorHierarchyRecord,
						{},
						{ uuid: obj.linkToObjectUuid, versionUuid: obj.linkToObjectVersionUuid }
					)
				);
			else if (
				packet.standardObjectUuid === sharedState.dbConstants.fixPacket?.referenceUuid &&
				obj.standardObjectUuid !== sharedState.dbConstants.changeRequestPacket?.referenceUuid
			) {
				obj = createAssociatedObjectCopy(attachmentTree1.current[0], { ...obj, uuid: obj.parentUuid }, obj);
				obj.readonly = true;
				newHierarchyRecords.push(
					createAssociatedObjectHierarchyRecord(
						ancestorHierarchyRecord,
						{},
						{ uuid: obj.linkToObjectUuid, versionUuid: obj.linkToObjectVersionUuid }
					)
				);
			} else if (obj.standardObjectUuid === attachmentTree1.current[0].uuid && linkOrCopy.current) {
				obj = createAssociatedObjectCopy(attachmentTree1.current[0], { ...obj, uuid: obj.parentUuid }, obj);
				newHierarchyRecords.push(
					createAssociatedObjectHierarchyRecord(
						ancestorHierarchyRecord,
						{},
						{ uuid: obj.linkToObjectUuid, versionUuid: obj.linkToObjectVersionUuid }
					)
				);
			} else newHierarchyRecords.push(createObjectHierarchyRecord(ancestorHierarchyRecord, obj));

			newRows.push(obj);
			toc.push(obj);
		});

		toc.sort(sortByReference);
		updateChangeData(dispatch, {
			objectToUpdate: packet,
			objectRows: newRows,
			objectHierarchy: newHierarchyRecords,
		});
	};

	return (
		<>
			<DraggableDialog
				style={{ minHeight: "600px", minWidth: "1200px" }}
				PaperProps={{ style: { minWidth: "1200px", width: "75%" } }}
				header={"Select Objects to Attach"}
				showDialog={selectDataWarehouseObjectDialog}
				handleClose={handleClose}
				handleSave={handleClose}
				saveButtonText={"Done"}
			>
				<HorizontalTabs headings={["Data Warehouse", "Current Packet"]} tabClicked={tabClicked}>
					{[
						<BasicTreeWithSearch
							treeHeight={500}
							data={sharedState.destinationModel}
							treeTitle={`Data Warehouse`}
							allowSubObjectNavigation={false}
							showVersions={true}
							rowButtonsForObjects={true}
							rowButtons={[
								{
									text:
										packet.standardObjectUuid ===
										sharedState.dbConstants.changeRequestPacket?.referenceUuid
											? "Attach as Current Document"
											: "Attach a Link",
									classes: "btn btn-outline-primary",
									onClick: attachAsLink,
								},

								{
									text:
										packet.standardObjectUuid ===
										sharedState.dbConstants.changeRequestPacket?.referenceUuid
											? "Attach as Suggested Change"
											: "Attach a Copy",
									classes: "btn btn-outline-primary",
									onClick: attachCopy,
								},
							]}
							changeRequestSources={sources}
						/>,
						<BasicTreeWithSearch
							treeHeight={500}
							data={toc}
							treeTitle={`Current Packet`}
							allowSubObjectNavigation={false}
							showVersions={true}
							rowButtonsForObjects={true}
							rowButtons={[
								{
									text: "Attach a Copy",
									classes: "btn btn-outline-primary",
									onClick: attachCopy,
								},
							]}
						/>,
					]}
				</HorizontalTabs>
			</DraggableDialog>

			{/*Dialog for selecting the Developer to send this object for review*/}
			<SelectionDialog
				dialogTitle={"Select the location to attach the " + linkOrCopy.current}
				treeData={toc}
				treeTitle={"TOC"}
				rowSelected={tocLocationSelected}
				open={selectTocLocationDialog}
				submitButtonText={"Submit"}
				requireFullObject={false}
			/>

			<TwoTreeDialog
				open={showAttachmentDialog}
				treeData1={attachmentTree1.current}
				treeData2={[{ ...packet, uuid: toc[0]?.parentUuid }, ...toc]}
				saveChanges={attachDialogSave}
				cancel={() => {
					setShowAttachmentDialog(false);
					setSelectDataWarehouseObjectDialog(true);
				}}
			/>
		</>
	);
};
