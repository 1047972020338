import { useState, useEffect, useRef } from "react";

export const useClickHandler = (onSingleClick, onDoubleClick, onTripleClick) => {
	const [clickCount, setClickCount] = useState(0);
	const timeoutRef = useRef(null);

	useEffect(() => {
		return () => clearTimeout(timeoutRef.current);
	}, []);

	const handleClick = (e, row) => {
		setClickCount(clickCount + 1);
		clearTimeout(timeoutRef.current);
		let eCopy = { ...e };
		timeoutRef.current = setTimeout(() => {
			if (clickCount === 0) {
				onSingleClick && onSingleClick(eCopy, row);
				console.log("Single click", row);
			} else if (clickCount === 1) {
				onDoubleClick && onDoubleClick(eCopy, row);
				console.log("Double click", row);
			} else if (clickCount === 2) {
				onTripleClick && onTripleClick(eCopy, row);
			}

			setClickCount(0);
		}, 250);
	};

	return {
		handleClick,
	};
};
