import { useEffect } from "react";

const ProductsPage = () => {
	useEffect(() => {
		document.title = "Products";
	});

	return (
		<div className="container">
			<div className="row mt-3">
				<div className="col"></div>
				<div className="col text-center">
					<h1>Products</h1>
					<div className="mx-auto">
						<ul className="list-unstyled mt-3">
							<li>Model Creation Suite</li>
							<li>UBM Software</li>
							<li>Online App Store</li>
							<li>Management Reporting Application</li>
							<li>Applications</li>
							<li>Universal Reference Manual</li>
							<li>Biz-Mat Policies &amp; Procedures</li>
							<li>Internal &amp; External Developers Section</li>
						</ul>
					</div>
				</div>
				<div className="col"></div>
			</div>
		</div>
	);
};

export default ProductsPage;
