import React, { useEffect, useRef, useState } from "react";
import StockNumber from "../StockNumber/StockNumber";
import { useDispatch, useTrackedState } from "../../utils/store";

/**
 * This component handles all the stock # stuff
 * it takes in a stock # for the current stock # and a method to pass back
 * new stock # stuff as they are created
 * @returns {*}
 * @constructor
 */
export default function StockNumberManager({
	stockNumber,
	updateStockNumberData,
	showDialog,
	handleClose: pHandleStockNumberClose,
}) {
	const [openStockNumber, setOpenStockNumber] = useState(false);
	// const [stockNo, setStockNo] = useState(false);

	//Track new stock # stuff
	const changedStockNumbers = useRef({});
	const changedVolumes = useRef({});
	const changedObjectClasses = useRef({});
	const changedLanguageFrameworks = useRef({});
	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	useEffect(() => {
		setOpenStockNumber(showDialog);
	}, [showDialog]);

	useEffect(() => {
		// setStockNo(stockNumber);
	}, [stockNumber]);

	/**
	 * Methods to add new StockNumbers, Volumes, ObjectClasses, LanguageFrameworks, etc.
	 * Used in the save method to pass back the new stock number items that need saved / updated.
	 */
	const addStockNumber = (stockNumber) => {
		dispatch({ type: "UPDATE_CHANGED_STOCK_NUMBERS", data: stockNumber });
		// changedStockNumbers.current[stockNumber.uuid] = stockNumber;
	};
	const addVolumes = (vols) => {
		if (!vols || vols.length === 0) return;

		dispatch({ type: "UPDATE_CHANGED_VOLUMES", data: vols });
		// vols.forEach(vol => changedVolumes.current[vol.uuid] = vol);
	};
	const addObjectClasses = (classes) => {
		if (!classes || classes.length === 0) return;

		dispatch({ type: "UPDATE_CHANGED_OBJECT_CLASSES", data: classes });
		// classes.forEach(obj => changedObjectClasses.current[obj.uuid] = obj);
	};
	const addLanguageFrameworks = (frameworks) => {
		if (!frameworks || frameworks.length === 0) return;

		dispatch({ type: "UPDATE_CHANGED_LANGUAGE_FRAMEWORKS", data: frameworks });
		// frameworks.forEach(framework => changedLanguageFrameworks.current[framework.uuid] = framework);
	};

	/**
	 * Called when save is clicked on the stock number form
	 * Passed to the stock number component, comes back with the stockNumber and all the new stock number parts that were created
	 * @param stockNumber
	 * @param newVolumes
	 * @param newObjectClasses
	 * @param newLanguageFrameworks
	 */
	const handleStockNumberSave = (stockNumber, newVolumes, newObjectClasses, newLanguageFrameworks) => {
		//Update the stock number fields
		// let updatedRow = {...row};
		// updatedRow.stockNumber = stockNumber;
		// updatedRow.stockNumberUuid = stockNumber.uuid;
		//
		// setRow(updatedRow);

		handleStockNumberClose();

		addStockNumber(stockNumber);
		addVolumes(newVolumes);
		addObjectClasses(newObjectClasses);
		addLanguageFrameworks(newLanguageFrameworks);

		updateStockNumberData(stockNumber);
	};

	/**
	 * Called when the stock number dialog is closed
	 **/
	const handleStockNumberClose = () => {
		if (pHandleStockNumberClose) pHandleStockNumberClose();
		else setOpenStockNumber(false);
		// setTimeout(e => setOpenStockNumber(false), 1000);
		// props.api.stopEditing(true);
	};

	/**
	 * Called when the stock number dialog is closed
	 **/
	// const handleStockNumberOpen = () => {
	//     if(!row.uuid)
	//         return;
	//     document.activeElement.blur();
	//     setOpenStockNumber(true);
	//     // setTimeout(e => setOpenStockNumber(false), 1000);
	//     // props.api.stopEditing(true);
	// };

	return (
		<StockNumber
			stockNumber={stockNumber}
			handleSave={handleStockNumberSave}
			handleClose={handleStockNumberClose}
			step={stockNumber ? "end" : ""}
			newVolumes={changedVolumes.current}
			newObjectClasses={changedObjectClasses.current}
			newLanguageFrameworks={changedLanguageFrameworks.current}
			showDialog={openStockNumber}
		/>
	);
}
