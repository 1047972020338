import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { createMasterFileIndexRow } from "../../utils/MfiUtils";
import { getTopNodeId, uuidToAncestors } from "../../utils/TreeUtils";
import Header from "../../containers/Header/Header";
import AppSearchBar from "../AppSearchBar/AppSearchBar";
import RecursiveTreeView from "../Tree/Tree";
import { getCall, getUrl, postCall, urls } from "../../utils/ApiUtils";
import { sortByReference } from "../../utils/Referencing";
import stylesModule from "./MasterFileIndex.module.scss";

//The attributes to search when searching the list of objects
const attributesToSearch = ["title", "objectTags"];

/**
 * A Master File Index React Component. Has the functionality to add / modify mfi rows, drag and drop rows to move around
 * @param { mfI: _mfi = [], topNodeId: _topNodeId }
 * @constructor
 */
const MasterFileIndex = ({ mfI: _mfi = [], topNodeId: _topNodeId }) => {
	//state variables
	const [mfi, setMfi] = useState([]);
	const [topNodeId, setTopNodeId] = useState({});
	const [filteredMfi, setFilteredMfi] = useState([]);
	const [changedRows, setChangedRows] = useState({});
	const [changes, setChanges] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const deletedRows = useRef([]);
	const changedIds = useRef([]);
	const [matchIds, setMatchIds] = useState([]);
	const dwDescendantToAncestor = useRef([]);

	const [rerender, setRerender] = useState(false);

	//useEffects(Lifecycle Methods)
	/**
	 * ComponentDidMount: What should happen when this component is first loaded into the DOM
	 */
	useEffect(() => {
		if (_mfi.length > 0) {
			setMfi(_mfi);
			setLoaded(true);

			dwDescendantToAncestor.current = uuidToAncestors(_mfi);
		} else getMfi(_topNodeId);
	}, [_mfi.length]);

	useEffect(() => {
		document.title = "Master File Index";
	});

	//Other Methods
	/**
	 * Finds the corresponding row in the array, updates the corresponding value and updates the state
	 * @param uuid, field, value
	 */
	const updateRow = (uuid, field, value) => {
		let rowToUpdate = mfi.find((row) => row.uuid === uuid);

		//Check what field we are updating
		if (field === "tags") {
			let rowTags = rowToUpdate.tags.split(",");
			if (!rowToUpdate.tags) rowTags = [];

			if (value.index === -1) rowTags.push(value.value);
			else if (!value.value) rowTags.splice(value.index, 1);
			else rowTags[value.index] = value.value;

			rowToUpdate.tags = rowTags.join(",");
			addChangedRows([rowToUpdate]);
		} else {
			rowToUpdate[field] = value;
			addChangedRows([rowToUpdate]);
		}
		setMfi(mfi);

		setChanges(true);
	};

	/**
	 * Inserts a row into the master file index. If there is not yet a topNodeId set it to the parent of the row that was inserted. Currently this is only called when there are no rows in the mfi
	 * @param row
	 */
	const insertRow = (row) => {
		if (!topNodeId) setTopNodeId(row.parentUuid);
		else row.parentUuid = topNodeId;

		mfi.push(row);
		setMfi(mfi);
		setChanges(true);

		addChangedRows([row]);
	};

	/**
	 * Retrieves the master file index, if it's passed a id, gets that ids mfi, if its not it gets the top mfi
	 * @param uuid
	 */
	const getMfi = async (uuid) => {
		let mfi, context;
		if (uuid) mfi = await getCall(getUrl("getMfi", [uuid, -1]));
		else {
			mfi = await getCall(urls["getTopMfi"]);
			context = mfi[0];
			mfi = await getCall(getUrl("getMfi", [context.uuid, -1]));
		}

		if (context.uuid) setTopNodeId(context.uuid);
		else setTopNodeId(getTopNodeId(mfi));

		// let topId = getTopNodeId(mfi);
		//
		// if(topId)
		//     setTopNodeId(topId);

		dwDescendantToAncestor.current = uuidToAncestors(mfi);

		setLoaded(true);
		setMfi(mfi);
	};

	/**
	 * Takes in a list of rows and adds them to the map of changed rows
	 * @param data
	 */
	const addChangedRows = (data) => {
		let changed = {};

		//Iterate over the changed objects, add each one to the changed map, and update the corresponding record in the tree
		data.forEach((item) => (changed[item.uuid] = item));

		//Add the ids of the changed rows to the array of changed ids
		let arr = data.filter((item) => !changedIds.current.includes(item.uuid));
		changedIds.current = changedIds.current.concat(arr.map((item) => item.uuid));

		changed = { ...changedRows, ...changed };
		setChangedRows(changed);

		setChanges(true);
	};

	/**
	 * Add the array of ids to this component's array of ids to delete
	 * @param rows
	 */
	const addDeleteRows = (rows) => {
		deletedRows.current = [...deletedRows.current, ...rows.map((row) => row.uuid)];
		let newMfi = mfi.filter((item) => !deletedRows.current.includes(item.uuid));
		setMfi(newMfi);

		//Remove deleted rows from changed rows
		deletedRows.current.forEach((id) => {
			delete changedRows[id];
		});

		setChanges(true);
	};

	/**
	 * This function is passed to the search bar component, it's called when the search bar conducts a search
	 * @param matches
	 */
	const filterMfi = (matches) => {
		if (!matches) {
			setFilteredMfi([]);
			setMatchIds([]);
			return;
		}

		//Get all the ancestors of the matches so we can display where they reside in the warehouse
		let filterIds = [];
		matches.forEach((row) => {
			filterIds = [...filterIds, ...dwDescendantToAncestor.current[row.uuid]];
			filterIds.push(row.uuid);
		});

		setMatchIds(matches.map((row) => row.uuid));

		let filteredList = mfi.filter((row) => filterIds.includes(row.uuid));

		if (matches.length > 0) setFilteredMfi(filteredList);
		else setFilteredMfi([{ uuid: "emptyId", reference: "None", title: "No matches" }]);
	};

	/**
	 * Commit / Save changes made to the master file index.
	 * Creates an object that contains the rows that need updated, the rows that need deleted and a
	 * boolean that tells the service if it needs to re reference the rows because of reference changes or rows deleted.
	 * @param commit
	 */
	const resolveChanges = (commit) => {
		//Check if we want to discard changes or if we want to commit the changes
		if (commit) {
			//Build the MFI update object
			let updateObject = {
				mfiRowsToUpdate: Object.values(changedRows).sort(sortByReference),
				rowsToDelete: deletedRows.current,
				reRef: false,
			};

			//post it using API utils
			postCall(getUrl("updateMfi"), updateObject);

			setChangedRows({});
			setChanges(false);
		} else window.location.reload();
	};

	return (
		<Grid
			container
			className={stylesModule.MasterFileIndex}
			style={{ padding: "0px 24px", display: "block", height: "calc(100% - 150px)" }}
		>
			<Grid item xs={12} className={""} style={{}}>
				<Header />
			</Grid>
			<Grid item xs={12} className={""} style={{}}>
				<AppSearchBar
					className="search-bar"
					objects={mfi}
					attributes={attributesToSearch}
					addBlankRow={() => insertRow(createMasterFileIndexRow("01"))}
					parentCallback={filterMfi}
					changes={changes}
					resolveChanges={resolveChanges}
					noRows={mfi.length < 1}
					loaded={loaded}
				/>
			</Grid>
			<Grid item xs={12} className={""} style={{ paddingTop: "15px", height: "calc(100% - 175px)" }}>
				<RecursiveTreeView
					data={mfi}
					filteredList={filteredMfi.length > 0 ? filteredMfi : undefined}
					// treeTitle={mfi[0].title || "Master File Index"}
					addChangedRows={addChangedRows}
					addDeleteRows={addDeleteRows}
					// topNode={topNode}
					topNodeId={topNodeId}
					droppable={filteredMfi.length < 1}
					draggable={filteredMfi.length < 1}
					editable={filteredMfi.length < 1}
					updateRow={updateRow}
					changedIds={Object.keys(changedRows)}
					className={"tree"}
					origin={"master-file-index"}
					highlightIds={matchIds}
					allowSmallRef={false}
					showVersions={true}
					createNewNode={createMasterFileIndexRow}
					reRenderOnInsertOrDelete={() => setRerender((prev) => !prev)}
				/>
			</Grid>
		</Grid>
	);
};

export default MasterFileIndex;
