//Padding for the listbox
import React, { useEffect, useRef, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import { VariableSizeList } from "react-window";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField/TextField";

const CONTAINER_PADDING = 8; // px

const renderGroup = (params) => [
	<ListSubheader key={params.key} component="div">
		{params.group}
	</ListSubheader>,
	params.children,
];

const TreeTextField = ({ message, index, label, className, ...params }) => {
	const root = React.useRef();
	const { setSize, windowWidth } = React.useContext(OuterElementContext);
	useEffect(() => {
		setSize(index, root.current.getBoundingClientRect().height);
	}, [windowWidth]);
	return (
		<TextField
			ref={root}
			{...params}
			label={label}
			variant="outlined"
			className={className}
			InputLabelProps={{ shrink: true }}
		/>
	);
};

//This function renders the actual items in the list, it copies the items in the options list and adds a style property
function renderRow(props) {
	const { data, index, style } = props;
	//Copies the original element and adds the style property, copying the previous styles, and adding a top property
	return React.cloneElement(data[index], {
		style: {
			...style,
			top: style.top + CONTAINER_PADDING,
		},
		index,
	});
}

//Provides a way to pass data through the component tree without having to pass props down manually
//Allows us to pass the state we want without interfering with the other objects in between
const OuterElementContext = React.createContext({});

//Takes in a reference and forwards it to a child
const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

//I'm assuming this resets everything after the input loses focus and "scrolls everything back up to the top"
function useResetCache(data) {
	const ref = React.useRef(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
const VirtualizedList = React.forwardRef(function VirtualizedList(props, ref) {
	//Destructures props to a variable children and other
	const { children, ...other } = props;
	//Converts the children of the object to an array
	const itemData = React.Children.toArray(children);
	//The total length of children
	const itemCount = itemData.length;
	const [itemSize, setItemSize] = useState(48);

	const gridRef = useResetCache(itemCount);

	const divRef = useRef();

	useEffect(() => {
		if (props.itemSize) setItemSize(props.itemSize);
	}, [props.itemSize]);
	//Calculates the size of the child item
	const getChildSize = (child) => {
		if (React.isValidElement(child) && child.type === ListSubheader) {
			return 48;
		}

		if (divRef.current && child.props.children.length * 10 - 50 > divRef.current.clientWidth) return itemSize * 2;

		return itemSize;
	};

	//Get's the height of the item that will be in the select
	const getHeight = () => {
		if (props.height) return props.height;
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const mergeRefs = (...refs) => {
		const filteredRefs = refs.filter(Boolean);
		if (!filteredRefs.length) return null;
		if (filteredRefs.length === 0) return filteredRefs[0];
		return (inst) => {
			for (const ref of filteredRefs) {
				if (typeof ref === "function") {
					ref(inst);
				} else if (ref) {
					ref.current = inst;
				}
			}
		};
	};

	//Returns a div with a provider that passes the props back down to it's children without messing everything else up
	//VariableSizeList is a react-window component that only renders the stuff visual in the window
	return (
		<div ref={mergeRefs(divRef, ref)} className={"full-width full-height"}>
			<OuterElementContext.Provider value={{ ...other }}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * CONTAINER_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

VirtualizedList.propTypes = {
	children: PropTypes.node,
};

export { VirtualizedList };
