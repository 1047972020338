import { getTopMostObject } from "../ReactGridComponents/Body/CreatorPanel/CreatorPanel";
import {
	copyStandardObject,
	createAssociatedObjectCopy,
	createAssociatedObjectHierarchyRecord,
	createNewObject,
	createObjectHierarchyRecord,
	createObjectUpdate,
	getLinkToKey,
	ZERO_ROW_UUID,
} from "../../utils/StandardObject";
import {
	getCall,
	getObjectDataWarehouseSource,
	getObjectGitRecord,
	getObjectMfiRow,
	getUrl,
} from "../../utils/ApiUtils";
import { getNextRef } from "../../utils/Referencing";
import { objectStates } from "./ChangeRequest/ChangeRequestForm";
import { zeroRowUuid } from "../../utils/MfiUtils";

//This retrieves a list the likely sources a user may be submitting a change request for
export const getPossibleSourceObjects = async (sharedState) => {
	//Get the top object / packet object
	let topObject = getTopMostObject(sharedState);

	if (!topObject || !topObject.uuid) {
		return { templates: [], instances: [] };
	}

	//Get the currently open object
	let openObject = sharedState.contextTop;
	//Get the current open panel object

	let templates = [];
	let instances = [];
	instances.push(topObject);

	let topSource = await getObjectGitRecord(topObject.standardObjectUuid, topObject.standardObjectVersionUuid);
	topSource.current = topObject;

	//Check if the top is in data-warehouse and if so, if there is a newer version
	let destinationModelMatch = sharedState.destinationModel.find((row) => row.standardObjectUuid === topSource.uuid);
	if (destinationModelMatch && destinationModelMatch.standardObjectVersionUuid !== topSource.versionUuid)
		topSource.newerVersion = await getObjectGitRecord(
			destinationModelMatch.standardObjectUuid,
			destinationModelMatch.standardObjectVersionUuid
		);

	if (topSource) templates.push(topSource);

	if (topObject.uuid !== openObject.uuid || topObject.versionUuid !== openObject.versionUuid) {
		instances.push(openObject);
		let openObjectSources = await getObjectDataWarehouseSource(
			openObject.standardObjectUuid,
			openObject.standardObjectVersionUuid
		);

		let openObjectSource = openObjectSources[openObjectSources.length - 1];
		//If there isn't a hierarchy record check if we got one when getting the source for the top
		if (openObjectSource.objectHierarchy?.length < 1) {
			let match = topSource.objectHierarchy.find(
				(row) => row.descendantStandardObjectUuid === openObjectSource.uuid
			);
			if (match) openObjectSource.objectHierarchy = [match];
		}
		openObjectSource.current = openObject;
		templates.push(openObjectSource);
	}

	return { templates, instances };
};

export const getAvailableUpdates = async (object) => {
	let uuid = object.uuid;
	let versionUuid = object.versionUuid;

	return await getCall(getUrl("getAvailableUpdates", [uuid, versionUuid]));
};

export const createChangeRequestPacket = async (sharedState, object, additionalObjects, additionalChanges) => {
	let packetUuid = sharedState.dbConstants.changeRequestPacket.referenceUuid;

	//Copy the change request packet
	//Update the createNewObject to work with just a uuid (This will also mean updating getObjectGitRecord to support just a uuid)
	let changeRequestPacket = await createNewObject({ uuid: packetUuid, userUuid: sharedState.currentUser?.uuid });
	changeRequestPacket.title = changeRequestPacket.title + " for " + object.title;
	let changeRequestHierarchyRecord = createObjectHierarchyRecord(
		{
			descendantStandardObjectUuid: ZERO_ROW_UUID,
			descendantStandardObjectVersionUuid: ZERO_ROW_UUID,
		},
		changeRequestPacket
	);

	//Get the specific row by reference
	let objectRows = await getObjectMfiRow(
		changeRequestPacket.standardObjectUuid,
		changeRequestPacket.standardObjectVersionUuid,
		"04."
	);

	//Put a link to the current object in the current section along with an associated hierarchy record
	let currentSection = objectRows.filter((row) => row.reference === "04.05")?.[0];
	let children = objectRows.filter((row) => row.parentUuid === currentSection.uuid);
	let newRef;
	if (children.length > 0) newRef = getNextRef(children[children.length - 1].reference);
	else newRef = getNextRef(currentSection.reference + ".00");

	let associatedCopy = createAssociatedObjectCopy(object, currentSection, newRef);
	let associatedObjectHierarchyRecord = createAssociatedObjectHierarchyRecord(
		changeRequestHierarchyRecord,
		object.objectHierarchy?.[0] || {},
		object
	);

	associatedCopy.readonly = true;

	let newSection = objectRows.filter((row) => row.reference === "04.04")?.[0];
	children = objectRows.filter((row) => row.parentUuid === newSection.uuid);
	if (children.length > 0) newRef = getNextRef(children[children.length - 1].reference);
	else newRef = getNextRef(newSection.reference + ".00");

	let { object: objectCopy, hierarchyRecord: objectHierarchyRecord } = await createSuggestedChangeObject(
		object,
		newRef,
		newSection,
		changeRequestHierarchyRecord,
		sharedState.currentUser?.uuid
	);

	//The copy is attached to the change request
	objectCopy.ancestorUuid = changeRequestPacket.uuid;

	//Save the changeRequestUpdate
	return {
		top: changeRequestPacket,
		// subObjects: [objectCopy, ...additionalObjects],
		subObjects: [objectCopy],
		// changedRows: [changeRequestPacket, associatedCopy, objectCopy, ...additionalChanges],
		changedRows: [changeRequestPacket, associatedCopy, objectCopy],
		objectHierarchy: [changeRequestHierarchyRecord, associatedObjectHierarchyRecord, objectHierarchyRecord],
	};
};

export const createSuggestedChangeObject = async (object, ref, parent, ancestorHierarchyRecord, userUuid) => {
	let objectCopy = await createNewObject({
		object,
		userUuid,
		ref,
		parent,
		releaseVersion: true,
	});

	objectCopy.versionControl.objectState = objectStates.SUGGESTEDCHANGE;
	objectCopy.versionControl.objectVersionNumber = "1";
	//Update the compare with fields
	objectCopy.compareWithUuid = object.uuid;
	objectCopy.compareWithVersionUuid = object.versionUuid;

	let hierarchyRecord = createObjectHierarchyRecord(ancestorHierarchyRecord, objectCopy);

	return { object: objectCopy, hierarchyRecord };
};

export const createFixPacket = async (sharedState, changeRequestPacket) => {
	//Create a new fix packet
	let packetUuid = sharedState.dbConstants.fixPacket.referenceUuid;

	//Copy the change request packet
	//Update the createNewObject to work with just a uuid (This will also mean updating getObjectGitRecord to support just a uuid)
	let fixPacket = await createNewObject({ uuid: packetUuid, userUuid: sharedState.currentUser?.uuid });
	let fixHierarchyRecord = createObjectHierarchyRecord(
		{
			descendantStandardObjectUuid: ZERO_ROW_UUID,
			descendantStandardObjectVersionUuid: ZERO_ROW_UUID,
		},
		fixPacket
	);

	//Get the specific row by reference
	let objectRows = await getObjectMfiRow(fixPacket.standardObjectUuid, fixPacket.standardObjectVersionUuid, "04.");

	let currentSection = objectRows.filter((row) => row.reference === "04.05")?.[0];
	//Get the items from the change request packet section current documents (E)
	let crqCurrentSection = await getObjectMfiRow(changeRequestPacket.uuid, changeRequestPacket.versionUuid, "04.05.");

	let associatedRecords = [];
	let associatedHierarchyRecords = [];
	crqCurrentSection.forEach((item) => {
		//Attach the items from the change request packet section current documents (E) to the fix packet section current documents (E)
		let children = objectRows.filter((row) => row.parentUuid === currentSection.uuid);
		let newRef;
		if (children.length > 0) newRef = getNextRef(children[children.length - 1].reference);
		else newRef = getNextRef(currentSection.reference + ".00");

		let associatedCopy = createAssociatedObjectCopy(
			{ ...item, uuid: item.linkToObjectUuid, versionUuid: item.linkToObjectVersionUuid },
			currentSection,
			newRef
		);
		let associatedObjectHierarchyRecord = createAssociatedObjectHierarchyRecord(
			fixHierarchyRecord,
			//We may need to update this to get the objectHierarchy from somewhere else
			{ pathEnum: getLinkToKey(item) },
			{ uuid: item.linkToObjectUuid, versionUuid: item.linkToObjectVersionUuid }
		);

		objectRows.push(associatedCopy);

		associatedRecords.push(associatedCopy);
		associatedHierarchyRecords.push(associatedObjectHierarchyRecord);
	});

	let newSection = objectRows.filter((row) => row.reference === "04.04")?.[0];
	//Get the items from the change request packet section F
	let crqNewUpdatedSection = await getObjectMfiRow(
		changeRequestPacket.uuid,
		changeRequestPacket.versionUuid,
		"04.06."
	);

	//TODO we may need to do a mixture of the submitter and bmcl suggested changes
	if (crqNewUpdatedSection.length == 0)
		crqNewUpdatedSection = await getObjectMfiRow(
			changeRequestPacket.uuid,
			changeRequestPacket.versionUuid,
			"04.04."
		);

	let subObjectRecords = [];
	//Attach the items from the change request packet new/updated documents (F) to the Fix Packet new/updated documents (D)
	crqNewUpdatedSection.forEach((item) => {
		let children = objectRows.filter((row) => row.parentUuid === newSection.uuid);
		let newRef;
		if (children.length > 0) newRef = getNextRef(children[children.length - 1].reference);
		else newRef = getNextRef(newSection.reference + ".00");

		let objectCopy = copyStandardObject(item, "0", newSection.uuid || zeroRowUuid, sharedState.currentUser?.uuid, {
			newVersion: true,
			releaseVersion: true,
		});

		//Update the copy's version control to be a suggested change
		objectCopy.versionControl.objectState = objectStates.DEVELOPMENT;
		objectCopy.versionControl.objectVersionNumber = "1";

		let associatedCopy = objectRows.find(
			(row) => row.reference === currentSection.reference + "." + newRef.referenceNo
		);

		//Update the compare with fields
		objectCopy.compareWithUuid = associatedCopy.linkToObjectUuid;
		objectCopy.compareWithVersionUuid = associatedCopy.linkToObjectVersionUuid;

		objectCopy.reference = newRef.reference;
		objectCopy.referenceNo = newRef.referenceNo;

		let objectHierarchyRecord = createObjectHierarchyRecord(fixHierarchyRecord, objectCopy);

		objectRows.push(objectCopy);

		if (objectCopy.linkToObjectUuid) {
			associatedRecords.push(objectCopy);
		} else {
			subObjectRecords.push(objectCopy);
		}
		associatedHierarchyRecords.push(objectHierarchyRecord);
	});

	//Attach the Change Request Paket to the Change Request Packet's section
	let changeRequestSection = objectRows.filter((row) => row.reference === "04.06")?.[0];
	let children = objectRows.filter((row) => row.parentUuid === changeRequestSection.uuid);
	let newRef;
	if (children.length > 0) newRef = getNextRef(children[children.length - 1].reference);
	else newRef = getNextRef(changeRequestSection.reference + ".00");

	let changeRequestAssociation = createAssociatedObjectCopy(changeRequestPacket, changeRequestSection, newRef);
	let changeRequestAssociatedObjectHierarchyRecord = createAssociatedObjectHierarchyRecord(
		fixHierarchyRecord,
		changeRequestPacket.objectHierarchy[0],
		changeRequestPacket
	);

	//The copy is attached to the change request

	//Save the changeRequestUpdate
	return {
		top: fixPacket,
		subObjects: [...subObjectRecords],
		changedRows: [fixPacket, ...associatedRecords, ...subObjectRecords, changeRequestAssociation],
		objectHierarchy: [
			fixHierarchyRecord,
			...associatedHierarchyRecords,
			changeRequestAssociatedObjectHierarchyRecord,
		],
	};
};

export const createPatchPacket = async (sharedState, fixPacket) => {
	//Create a new fix packet
	let packetUuid = sharedState.dbConstants.patchPacket.referenceUuid;

	//Copy the change request packet
	//Update the createNewObject to work with just a uuid (This will also mean updating getObjectGitRecord to support just a uuid)
	let patchPacket = await createNewObject({ uuid: packetUuid, userUuid: sharedState.currentUser?.uuid });
	let patchHierarchyRecord = createObjectHierarchyRecord(
		{
			descendantStandardObjectUuid: ZERO_ROW_UUID,
			descendantStandardObjectVersionUuid: ZERO_ROW_UUID,
		},
		patchPacket
	);

	//Get the specific row by reference
	let objectRows = await getObjectMfiRow(
		patchPacket.standardObjectUuid,
		patchPacket.standardObjectVersionUuid,
		"04."
	);

	let patchSection = objectRows.filter((row) => row.reference === "04.03")?.[0];
	//Get the items from the fix packet's current items
	let fixCurrentSection = await getObjectMfiRow(fixPacket.uuid, fixPacket.versionUuid, "04.04.");

	// let subObjectRecords = [];
	// let subObjectHierarchyRecords = [];
	let associatedObjectRecords = [];
	let associatedHierarchyRecords = [];
	//Attach the items from the fix packet's current items to the patch section in the patch packet
	fixCurrentSection.forEach((item) => {
		let children = objectRows.filter((row) => row.parentUuid === patchSection.uuid);
		let newRef;
		if (children.length > 0) newRef = getNextRef(children[children.length - 1].reference);
		else newRef = getNextRef(patchSection.reference + ".00");

		//We are going to try changing this to be an associated copy rather than a copy or the same thing
		// let objectCopy = copyStandardObject(
		// 	item,
		// 	"0",
		// 	patchSection.uuid || zeroRowUuid,
		// 	sharedState.currentUser?.uuid,
		// 	false,
		// 	false,
		// 	null,
		// 	null,
		// 	false
		// );

		// item.reference = newRef.reference;
		// item.referenceNo = newRef.referenceNo;
		// item.parentUuid = patchSection.uuid;

		// objectCopy.reference = newRef.reference;
		// objectCopy.referenceNo = newRef.referenceNo;

		//Update the copy's version control to be a suggested change
		// objectCopy.versionControl.objectState = objectStates.ALPHA;
		// objectCopy.versionControl.objectVersionNumber = "1";

		let associatedCopy = createAssociatedObjectCopy(item, patchSection, newRef);
		associatedCopy.readonly = true;
		let associatedObjectHierarchyRecord = createAssociatedObjectHierarchyRecord(
			patchHierarchyRecord,
			//We may need to update this to get the objectHierarchy from somewhere else
			{ pathEnum: getLinkToKey(associatedCopy) },
			item
		);

		associatedObjectRecords.push(associatedCopy);
		objectRows.push(associatedCopy);

		// associatedObjectRecords.push(item);
		associatedHierarchyRecords.push(associatedObjectHierarchyRecord);
	});

	//Save the changeRequestUpdate
	return {
		top: patchPacket,
		subObjects: [],
		changedRows: [patchPacket, ...associatedObjectRecords],
		objectHierarchy: [patchHierarchyRecord, ...associatedHierarchyRecords],
	};
};

export const getReleasableItems = async (patchPacket) => {
	let items = await getObjectMfiRow(patchPacket.uuid, patchPacket.versionUuid, "04.03.");

	//Iterate over each item and create a copy
};

export const releaseToProduction = async (sharedState, patchPacket) => {
	//Get the items from the release packet section C
	let patchedItems = await getObjectMfiRow(patchPacket.uuid, patchPacket.versionUuid, "04.03.");

	let releaseItems = [];
	//Attach the items from the change request packet section F to the Fix Packet section D
	for (let item of patchedItems) {
		// let objectCopy = copyStandardObject(
		// 	item,
		// 	"0",
		// 	zeroRowUuid,
		// 	sharedState.currentUser?.uuid,
		// 	false,
		// 	true,
		// 	null,
		// 	null,
		// 	//TODO Here we need to make sure the ReleaseVersion is using the same production releaseVersion if it already exists
		// 	true
		// );

		//The patched items are now links so pass in the patchedItems linkToKey
		let objectCopy = await createNewObject({
			uuid: item.linkToObjectUuid,
			versionUuid: item.linkToObjectVersionUuid,
			userUuid: sharedState.currentUser?.uuid,
			ref: { reference: "0", referenceNo: 0 },
			parent: ZERO_ROW_UUID,
			releaseVersion: true,
		});

		//Update the copy's version control to be a suggested change
		objectCopy.versionControl.objectState = objectStates.ALPHA;

		//TODO Here instead of setting the version number to 1 we need to get the next production version
		// objectCopy.versionControl.objectVersionNumber = "1";

		//Remove the compare with fields
		objectCopy.compareWithUuid = undefined;
		objectCopy.compareWithVersionUuid = undefined;

		let objectHierarchyRecord = createObjectHierarchyRecord(null, objectCopy);

		releaseItems.push(objectCopy);
	}

	//Save the release
	return releaseItems;
};

export const getObjectsVersion = (object) => {
	let objectVersionNumber = object?.versionControl?.objectVersionNumber;
	let objectState = object?.versionControl?.objectState;
	let objectComputerVersion = object.computerVersion || object?.logRecord?.computerVersion;

	let version = "";
	version += trimVersion(objectVersionNumber);
	version += objectState === objectStates.PRODUCTION ? "" : objectState ? "-" + objectState : "";
	version += objectComputerVersion ? ":" + objectComputerVersion : "";
	return version;
};

export const trimVersion = (versionNumber) => {
	if (!versionNumber) return "";
	versionNumber = versionNumber + "";

	let versionPieces = versionNumber.split(".");
	//Check the last box for a number, if it is a zero remove it and check again else return
	//If the tail of the version is a zero it doesn't need to be displayed
	while (versionPieces[versionPieces.length - 1] == "0") {
		versionPieces.splice(versionPieces.length - 1);
	}
	return versionPieces.join(".");
};
