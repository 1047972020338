import { useEffect } from "react";
import PagingTable from "../../components/Table/PagingTable";

/**
 * The page that allows a BMCL Admin to manage the environments in the system.
 * @param {}
 * @constructor
 */
const EnvironmentsPage = ({}) => {
	//Other Methods
	let attributes = [
		{ title: "", value: <input type="checkbox" /> },
		{ title: "Name", value: "name" },
		{ title: "Setup Date", value: "setup_date" },
		{ title: "Status", value: "status" },
	];

	useEffect(() => {
		document.title = "Environments";
	});

	const sortAlphabetical = (item1, item2) => {
		return item1.name.compareTo(item2.name);
	};

	return (
		<div>
			<PagingTable rows={environments} attributes={attributes} title={"Environments"} sort={sortAlphabetical} />
		</div>
	);
};

const environments = [
	{
		id: 1,
		name: "Voolith",
		setup_date: "3/9/2021",
		status: "Disabled",
	},
	{
		id: 2,
		name: "Oozz",
		setup_date: "8/25/2021",
		status: "Disabled",
	},
	{
		id: 3,
		name: "Plambee",
		setup_date: "12/2/2021",
		status: "Disabled",
	},
	{
		id: 4,
		name: "Fiveclub",
		setup_date: "4/12/2021",
		status: "Disabled",
	},
	{
		id: 5,
		name: "Skinix",
		setup_date: "8/12/2021",
		status: "Active",
	},
];

export default EnvironmentsPage;
