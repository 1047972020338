import React, { useState, useEffect, useRef } from "react";
import { getNextRef, getReferenceNo, insertRowAndReRef, sortByReference } from "../../utils/Referencing";
import { findOwnerByMap, findSetupSheetOwner, getAncestors } from "../../utils/TreeUtils";
import TitleAutoComplete, { MultiAutoComplete } from "../Autocomplete/TitleAutocomplete";
import { useDispatch, useTrackedState } from "../../utils/store";
import StockNumberManager from "../../containers/StockNumberManager/StockNumberManager";
import EditableLabel from "../EditableLabel/EditableLabel";
import BootstrapInput from "../BootstrapComponents/Input/BootstrapInput";
import BootstrapSelect from "../BootstrapComponents/Select/BootstrapSelect";
import {
	createObjectHierarchyRecord,
	findObjectWithMatchingReleaseVersionInDestinationModel,
	linkAttributeName,
	linkObjectName,
	linkObjectVersionName,
	linkVersionAttributeName,
	ZERO_ROW_UUID,
} from "../../utils/StandardObject";
import BootstrapAccordion from "../BootstrapComponents/Accordion/BootstrapAccordion";
import { getValidations, INPUT_FIELD_SOURCES, INPUT_FIELD_TYPES, SETUP_TYPES } from "../../utils/SetupTypes";
import BootstrapTextarea from "../BootstrapComponents/Textarea/BootstrapTextarea";
import SelectionDialog from "../Dialog/DraggableDialog/SelectionDialog/SelectionDialog";
import BasicFileUploader from "../FileUpload/BasicFileUploader";
import { fileServiceUrl } from "../../utils/FileServiceUtils";
import {
	_updateRow,
	updateChangeData,
} from "../ReactGridComponents/Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import { updateSelectedPropertyWindows } from "../ReactGridComponents/Body/SetupSheetPanel/SetupSheetPanel";
import { getTopMostObject } from "../ReactGridComponents/Body/CreatorPanel/CreatorPanel";
import { getObjectGitRecord, getSingleLevelObjectMfi } from "../../utils/ApiUtils";
import stylesModule from "./Window.module.scss";
import { ArrowBarDownIcon, ArrowBarUpIcon, CloseIcon, FolderIcon } from "../BootstrapComponents/Icons/Icons";
import { EditIcon } from "../BootstrapComponents/Icons/Icons";
import { Breadcrumbs } from "../ReactGridComponents/SetupSheetWindow/SetupSheetWindow";
import { TagsInputType } from "../TagsInputType/TagsInputType";

/**
 * A window that is loaded into the setup form when a node is clicked in the tree on the left
 * @param {values, handleBlur, dwBreadCrumb, objMfiBreadCrumb, children}
 * @constructor
 */
const DeveloperSetupFormWindow = ({ topNode, data, dataMap, datawarehouse, handleBlur, uuid }) => {
	//state variables
	const [objMfiBreadCrumbs, setObjMfiBreadCrumbs] = useState([]);
	const [fields, setFields] = useState([]);
	//Used to update the windows title
	const [windowTitle, setWindowTitle] = useState("");

	const windowParentUuid = useRef("");

	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	useEffect(() => {
		if (!data || data.length < 1) return;

		//If I receive a uuid, I need to build the bread crumbs and array of fields to display
		if (uuid) {
			// let mfiRow = dataMap.get(uuid);
			let mfiRow = data.find((row) => row.uuid === uuid);

			//This will be the topNode
			if (uuid === ZERO_ROW_UUID) {
				let ancestors = [topNode];
				// let children = data.filter(row => row.parentUuid === topNode.uuid);
				//We want to be able to display the top node and its children, make so the second the top node is set as the uuid
				// if(objMfiBreadCrumbs.length === 1 &&)
				setFields([topNode]);
				setWindowTitle("");
				setObjMfiBreadCrumbs(ancestors);
				return;
			} else if (!mfiRow) {
				if (uuid === "0") {
					let ancestors = [topNode];
					setFields([topNode]);
					setWindowTitle("");
					setObjMfiBreadCrumbs(ancestors);
					return;
				} else return;
			}

			let ancestors = [];

			//We know that we will be receiving a parent Id, use that to grab everything.

			let children = data.filter((row) => row.parentUuid === mfiRow.uuid);
			setFields(children);

			ancestors = [...ancestors, ...getAncestors(mfiRow.parentUuid, data)];
			ancestors.sort(sortByReference);

			//TODO: Figure out why this is taking so long to re-render
			setWindowTitle(`${getReferenceNo(mfiRow.reference)}.${mfiRow.title}`);
			ancestors.push(mfiRow);

			setObjMfiBreadCrumbs(ancestors);
		}
	}, [uuid]);

	//This will check if a field needs to be added to the fields in the state
	useEffect(() => {
		//Get the children that have the windowParentUuid and if the length is different than the fields, update fields
		if (!windowParentUuid.current || fields.length < 1) return;

		let children = data.filter((row) => row.parentUuid === windowParentUuid.current);
		if (children.length !== fields.length) {
			//Do I need to do something different if the children is greater than or less than? Or can I handle them the same?
			//For now just try resetting the fields, we will see how slow this is compared to updating the fields array
			setFields(children);
		}
	}, [data, data.length]);

	//Other Methods
	const setSelected = (row, multiClick = false, replaceUuid) => {
		updateSelectedPropertyWindows(sharedState, dispatch, { parentUuid: row.uuid }, multiClick, replaceUuid);
	};

	return (
		<div className="p-2 border rounded" style={{ backgroundColor: "#F3F7FD", height: "100%" }}>
			<button className="btn-close float-end" onClick={() => setSelected({}, true, uuid)} />
			<ol className="breadcrumb">
				{objMfiBreadCrumbs.map((breadCrumb, index) => {
					let active = false;
					if (index === objMfiBreadCrumbs.length - 1) active = true;

					let display = "";
					if (breadCrumb.uuid === topNode.uuid) display = `${breadCrumb.title}`;
					else display = `${getReferenceNo(breadCrumb.reference)}.${breadCrumb.title}`;

					return (
						<li
							key={breadCrumb.uuid}
							className={`breadcrumb-item`}
							style={{ paddingRight: "0px", paddingLeft: "0px" }}
						>
							<a
								className={`${stylesModule.cardLocationText} ${
									active ? stylesModule.activeBreadcrumb : ""
								}`}
								style={{ paddingLeft: "5px", paddingRight: "10px" }}
								href="#"
								onClick={() => setSelected(breadCrumb, true, uuid)}
							>
								<span>{display}</span>
							</a>
						</li>
					);
				})}
			</ol>
			{fields.map((field, index) => (
				<DeveloperSetupFormCard
					key={field.uuid + index}
					windowUuid={uuid}
					field={field}
					uuid={field.uuid}
					reference={field.reference}
					tags={field.objectTags}
					title={field.title}
					description={field.description}
					value={field.value}
					stockNumber={field.stockNumber}
					objectType={field.objectType}
					objectTypeUuid={field.objectTypeUuid}
					relationshipTypeUuid={field.relationshipTypeUuid}
					attachableTypes={field.attachableTypes}
					index={index}
					selected={sharedState.selectedWorkspaceRow.uuid === field.uuid}
					generalTypeUuid={field.generalTypeUuid}
					roleTypeUuid={field.roleTypeUuid}
					// updateRow={updateRow}
					hasChildren={data.filter((row) => row.parentUuid === field.uuid).length > 0}
					setSelected={setSelected}
					topNodeUuid={topNode.uuid}
					topNode={topNode}
					data={data}
					dataMap={dataMap}
					breadcrumbs={objMfiBreadCrumbs}
				/>
			))}
		</div>
	);
};

export default DeveloperSetupFormWindow;

const DeveloperSetupFormCard = ({
	windowUuid,
	uuid,
	reference,
	title: _title,
	description: _description,
	value: _value,
	relationshipTypeUuid: _relationshipTypeUuid,
	stockNumber: _stockNumber,
	// objectType: _objectType,
	objectTypeUuid: _objectTypeUuid,
	attachableTypes: _attachableTypes,
	generalTypeUuid: _generalTypeUuid,
	roleTypeUuid: _roleTypeUuid,
	index,
	selected,
	setSelected,
	hasChildren = false,
	field,
	tags: _tags,
	topNodeUuid,
	topNode,
	data,
	dataMap,
	breadcrumbs,
	...other
}) => {
	const [value, setValue] = useState("");
	const [title, setTitle] = useState("");
	let [openStockNumber, setOpenStockNumber] = useState(false);
	let [description, setDescription] = useState("");
	let [stockNumber, setStockNumber] = useState("");
	let [stockNumberUuid, setStockNumberUuid] = useState("");
	let [objectType, setObjectType] = useState({});
	let [objectTypeUuid, setObjectTypeUuid] = useState("");
	let [objectClass, setObjectClass] = useState({});
	let [objectClassUuid, setObjectClassUuid] = useState("");
	const [relationshipTypeUuid, setRelationshipTypeUuid] = useState("");
	const [tags, setTags] = useState(_tags);
	const [generalTypeUuid, setGeneralTypeUuid] = useState(_generalTypeUuid);
	const [roleTypeUuid, setRoleTypeUuid] = useState(_roleTypeUuid);

	const [attachableTypes, setAttachableTypes] = useState([]);
	const [objectTypes, setObjectTypes] = useState([]);
	const [generalTypes, setGeneralTypes] = useState([]);

	const [tagFocus, setTagFocus] = useState({});
	const [expandAccordions, setExpandAccordions] = useState(false);

	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	const highlightTimeout = useRef();
	const typeKeyTimeout = useRef();
	const pendingUpdate = useRef({ field: "", value: "" });
	const inputRef = useRef();

	const initialValue = useRef({});
	const attachableTypeList = useRef([]);

	useEffect(() => {
		attachableTypeList.current = sharedState.attachableTypes;
		getAndSetObjectTypes();
	}, [sharedState.attachableTypes, objectTypes.length, sharedState.objects]);

	useEffect(() => {
		if (_value !== value) setValue(_value);

		if (_title !== title) setTitle(_title);

		if (_description !== description) setDescription(_description);

		//How should we check the list of attachableTypes? It's currently an array of uuids I think
		// if(_attachableTypes !== attachableTypes)
		setAttachableTypes(_attachableTypes);

		setStockNumber(_stockNumber);
		if (_relationshipTypeUuid && _relationshipTypeUuid !== relationshipTypeUuid)
			setRelationshipTypeUuid(_relationshipTypeUuid);

		initialValue.current = {
			title: _title,
			description: _description,
			value: _value,
			stockNumber: _stockNumber,
			attachableTypes: _attachableTypes,
		};
	}, [_title, _description, _value, _stockNumber, _attachableTypes, _relationshipTypeUuid]);

	useEffect(() => {
		setGeneralTypeUuid(_generalTypeUuid);
	}, [_generalTypeUuid]);

	useEffect(() => {
		if (_objectTypeUuid) {
			initialValue.current.objectTypeUuid = _objectTypeUuid;

			setObjectTypeUuid(_objectTypeUuid);
			getAndSetObjectTypes(_objectTypeUuid);
		}
	}, [_objectTypeUuid]);

	useEffect(() => {
		getAndSetObjectTypes(_objectTypeUuid);
	}, [sharedState.objects]);

	useEffect(() => {
		if (sharedState.generalTypes) setGeneralTypes(sharedState.generalTypes);
	}, [sharedState.generalTypes]);

	useEffect(() => {
		if (selected) startHighlightTimeout();
		else clearHighlightTimeout();
	}, [selected]);

	const startTypeKeyTimeout = () => {
		clearTypeTimeout();

		typeKeyTimeout.current = setTimeout(() => {
			//When done, update state
			handleBlur(pendingUpdate.current.field, pendingUpdate.current.value);
		}, 5000);
	};

	const clearTypeTimeout = () => {
		clearTimeout(typeKeyTimeout.current);
	};

	/**
	 * Timeout to highlight the field
	 */
	const startHighlightTimeout = () => {
		clearHighlightTimeout();
		inputRef.current.focus();

		highlightTimeout.current = setTimeout(() => {}, 2000);
	};

	const clearHighlightTimeout = () => {
		clearTimeout(typeKeyTimeout.current);
	};

	const handleBlur = (attribute, newValue) => {
		clearTypeTimeout();
		if (initialValue.current[attribute] !== newValue) {
			initialValue.current[attribute] = newValue;

			_updateRow(dispatch, uuid, attribute, newValue);
		}
	};

	//Update the object type on blur of the field
	const handleTypeBlur = (field, newValue) => {
		//If the value is set to nothing, set the object type to a blank object, the uuid to null, and the title to blank
		//Update the row with the blank object
		if (!newValue?.uuid) {
			setObjectType({});
			setObjectTypeUuid(null);
			_updateRow(dispatch, uuid, "multi", {
				objectType: null,
				objectTypeUuid: null,
			});
			//If the user tries to set the type to something that doesn't already exist, set it back to what it was
		} else if (newValue?.uuid) {
			//field should = 'objectTypeUuid'
			if (newValue.uuid !== initialValue.current[field]) {
				setObjectType(newValue);
				_updateRow(dispatch, uuid, "multi", {
					objectType: newValue,
					objectTypeUuid: newValue.uuid,
				});
			}
		}
	};

	const handleTypeChange = (event, fieldTitle, newValue) => {
		if (!event) return;

		if (!newValue) {
			setObjectType({});
			setObjectTypeUuid("");
			return;
		}

		if (newValue && newValue.uuid) {
			setObjectType(newValue);
			setObjectTypeUuid(newValue.uuid);
			handleTypeBlur("objectTypeUuid", newValue);
		} else if (event) {
			setObjectType({ title: newValue });
		}
	};

	//Update the object type on blur of the field
	const handleClassBlur = (field, newValue) => {
		//Update the row with the blank object
		if (!newValue?.uuid) {
			setObjectClass({});
			setObjectClassUuid(null);
			_updateRow(dispatch, uuid, "multi", {
				objectClass: null,
				classObjectUuid: null,
				classObjectVersionUuid: null,
			});
			//If the user tries to set the type to something that doesn't already exist, set it back to what it was
		} else if (newValue?.uuid) {
			//field should = 'objectClassUuid'
			if (newValue.uuid !== initialValue.current[field]) {
				setObjectClass(newValue);
				_updateRow(dispatch, uuid, "multi", {
					objectClass: newValue,
					classObjectUuid: newValue.uuid,
					classObjectVersionUuid: newValue.versionUuid,
				});
			}
		}
	};

	const handleClassChange = (event, fieldTitle, newValue) => {
		if (!event) return;

		if (!newValue) {
			setObjectClass({});
			setObjectClassUuid("");
			return;
		}

		if (newValue && newValue.uuid) {
			setObjectClass(newValue);
			setObjectClassUuid(newValue.uuid);
			handleClassBlur("objectClassUuid", newValue);
		} else if (event) {
			setObjectClass({ title: newValue });
		}
	};

	const getAndSetObjectTypes = async (uuid) => {
		let types = [];
		if (objectTypes.length > 0 && sharedState.objects.length === objectTypes.length) types = objectTypes;
		else types = sharedState.objects;

		if (uuid) setObjectType(types.find((row) => row.uuid === uuid));

		setObjectTypes(types);
		return types;
		// return [
		//     ...sharedState.attachableTypes,
		//     ...sharedState.objects.filter(row => row.title.includes('**'))
		// ];
	};

	//Stock Number stuff
	const updateStockNumberData = (updatedStockNumber) => {
		dispatch({ type: "UPDATE_CHANGED_STOCK_NUMBERS", data: updatedStockNumber });
		setStockNumber(updatedStockNumber);
		setStockNumberUuid(updatedStockNumber.uuid);

		_updateRow(dispatch, uuid, "stockNumber", updatedStockNumber);

		//Pass the updated stock # information to the
		// props.updateChangedStockNumberData(changedStockNumberData);

		setOpenStockNumber(false);
	};

	/**
	 * Called when the stock number dialog is closed
	 **/
	const handleStockNumberClose = () => {
		setOpenStockNumber(false);
	};

	/**
	 * Called when the stock number dialog is closed
	 **/
	const handleStockNumberOpen = () => {
		setOpenStockNumber(true);
	};

	const updateTags = (index, value) => {
		let rowTags = [];
		if (tags) rowTags = tags.split(",");

		if (index === -1) rowTags.push(value);
		else if (!value) rowTags.splice(index, 1);
		else rowTags[index] = value;

		let newVal = rowTags.join(",");
		setTags(newVal);

		_updateRow(dispatch, uuid, "objectTags", newVal);
	};

	const fileUploader = () => {
		try {
			return (
				<BasicFileUploader
					uploadUrl={fileServiceUrl()}
					updateRow={(uuid, field, value) => _updateRow(dispatch, uuid, field, value)}
					rowUuid={uuid}
				/>
			);
		} catch (error) {
			return (
				<div style={{ backgroundColor: "#fff9f9", border: "1px solid red", padding: "5px", color: "red" }}>
					<strong>File service not connected: </strong>
					{error.message} (Contact BMCL Support)
				</div>
			);
		}
	};

	return (
		<div
			className="card m-2"
			style={{
				border: "2px solid #b0b0b0",
			}}
		>
			<div className="card-body">
				<div className="flex justify-content-center ">
					<label
						className="form-label"
						style={{
							fontWeight: "600",
							fontSize: "20px",
						}}
						title={`${reference}.${title}`}
					>
						{`${getReferenceNo(reference)}.${title}`}
						{/*{`${reference}.${title}`}*/}
					</label>
					<div
						style={{
							position: "absolute",
							right: 0,
							paddingRight: "30px",
						}}
					>
						<label className="form-label">Stock #:</label>

						<button
							className={`${stylesModule.cardHeaderLocationText} p-1 btn btn-link`}
							onClick={handleStockNumberOpen}
						>
							{stockNumber?.stockNumber ? (
								<span style={{ textDecoration: "underline" }}>{`${stockNumber.stockNumber}`}</span>
							) : (
								<span style={{ textDecoration: "underline" }}>{`Not Set`}</span>
							)}
						</button>

						<StockNumberManager
							stockNumber={stockNumber}
							updateStockNumberData={updateStockNumberData}
							showDialog={openStockNumber}
							handleClose={handleStockNumberClose}
							key={"stockNumberManager-" + uuid}
						/>
					</div>
				</div>

				<span className="float-end">
					<span className="p-2"></span>
					{hasChildren && uuid !== "0" && (
						<button
							className={`${stylesModule.folderIcon} btn btn-link p-2`}
							onClick={() => setSelected(field, true, windowUuid)}
						>
							<FolderIcon />
						</button>
					)}
				</span>
				<hr style={{ marginTop: "3px" }} />
				{/*Title Field*/}
				<div className="row pb-3">
					<div className={"col"}>
						<BootstrapInput
							label={"Title"}
							placeholder={"Title"}
							value={title}
							handleChange={(val) => {
								pendingUpdate.current.value = val;
								pendingUpdate.current.field = "title";
								setTitle(val);
								startTypeKeyTimeout();
							}}
							inputStyles={{
								height: "74px",
							}}
							handleBlur={(val) => handleBlur("title", val)}
						/>
					</div>
					<div className="col">
						<BootstrapTextarea
							label={"Description"}
							placeholder={"Description"}
							value={description}
							handleChange={(val) => {
								pendingUpdate.current.value = val;
								pendingUpdate.current.field = "description";
								setDescription(val);
								startTypeKeyTimeout();
							}}
							handleBlur={(val) => handleBlur("description", val)}
						/>
					</div>
					<div className="col">
						Value
						<textarea
							className="form-control"
							type="text"
							placeholder={title}
							value={value}
							style={{ padding: "12px" }}
							ref={inputRef}
							onChange={(e) => {
								let newVal = e.currentTarget.value;
								pendingUpdate.current.value = newVal;
								pendingUpdate.current.field = "value";
								setValue(newVal);
								startTypeKeyTimeout();
							}}
							onBlur={(e) => handleBlur("value", value)}
						/>
					</div>
				</div>
				{!expandAccordions ? (
					<div className="row pt-3">
						<button
							className={`${stylesModule.folderIcon}`}
							onClick={() => setExpandAccordions(true)}
							style={{ border: "none", backgroundColor: "white" }}
						>
							<div className="col">
								<hr />
								<div
									style={{
										margin: "auto",
										position: "relative",
										left: "0px",
										right: "0px",
										width: "30px",
										height: "30px",
										backgroundColor: "white",
										marginTop: "-30px",
									}}
								></div>
								<span
									className="p-2"
									style={{
										margin: "auto",
										position: "relative",
										left: "0px",
										right: "0px",
										width: "30px",
										height: "30px",
										backgroundColor: "white",
										marginTop: "-31px",
										display: "block",
									}}
								>
									<ArrowBarDownIcon />
								</span>
							</div>
						</button>
					</div>
				) : (
					<>
						<div className="row pt-3 pb-3">
							<button
								className={`${stylesModule.folderIcon}`}
								onClick={() => setExpandAccordions(false)}
								style={{ border: "none", backgroundColor: "white" }}
							>
								<div className="col">
									<hr />
									<div
										style={{
											margin: "auto",
											position: "relative",
											left: "0px",
											right: "0px",
											width: "30px",
											height: "30px",
											backgroundColor: "white",
											marginTop: "-40px",
										}}
									></div>
									<span
										className="p-2"
										style={{
											margin: "auto",
											position: "relative",
											left: "0px",
											right: "0px",
											width: "30px",
											height: "30px",
											backgroundColor: "white",
											marginTop: "-31px",
											display: "block",
										}}
									>
										<ArrowBarUpIcon />
									</span>
								</div>
							</button>
						</div>
						<label className="form-label mb-0 mt-4" style={{ fontWeight: "600" }}>
							Developer Options
						</label>
						<hr className="mt-0" />
						<div className={"flex px-4"}>
							{/*Attachable Type*/}
							<div className="pe-3 w-100">
								<MultiAutoComplete
									fieldToChange={"attachableTypes"}
									optionLabel={["stockNo", "title", "version"]}
									fieldLabel={"Attachable Types"}
									value={attachableTypes}
									options={objectTypes.filter(
										(row) =>
											row.roleTypeUuid === sharedState.dbConstants.classGeneralType.referenceUuid
									)}
									handleBlur={(e) => {
										if (attachableTypes && attachableTypes.length > 0)
											handleBlur(
												"attachableTypes",
												attachableTypes.map((row) =>
													row.uuid ? row.uuid + "," + row.versionUuid : row
												)
											);
										else handleBlur("attachableTypes", []);
									}}
									handleChange={(e, value) => {
										setAttachableTypes(value);
										handleBlur(
											"attachableTypes",
											value.map((row) => row.uuid + "," + row.versionUuid)
										);
									}}
								/>
							</div>
							{/*Object Class*/}
							<div className="pe-3 w-100">
								<TitleAutoComplete
									name={"title"}
									label={"Object Class"}
									value={objectClass?.title}
									options={objectTypes.filter(
										(row) =>
											row.roleTypeUuid === sharedState.dbConstants.classGeneralType.referenceUuid
									)}
									objectOptions
									handleChange={handleClassChange}
									handleBlur={(newVal) => handleClassBlur("classObjectUuid", newVal)}
									allowNew={false}
									clearOnBlur={false}
									error={!objectClassUuid + ""}
									className={stylesModule.formField}
								/>
							</div>
							{/*Object Type*/}
							<div className="pe-3 w-100">
								<TitleAutoComplete
									name={"title"}
									label={"Object Type"}
									value={objectType?.title}
									options={objectTypes}
									objectOptions
									handleChange={handleTypeChange}
									handleBlur={(newVal) => handleTypeBlur("objectTypeUuid", newVal)}
									allowNew={false}
									clearOnBlur={false}
									error={!objectTypeUuid + ""}
									className={stylesModule.formField}
								/>
							</div>
						</div>
						<div className={"flex px-4"} style={{ marginBottom: "10px" }}>
							{/*General Type*/}
							<div className="pe-3 w-100">
								{INPUT_FIELD_TYPES.DROP_DOWN.render({
									label: "Type",
									value: generalTypeUuid,
									optionGroups: [
										{
											title: "Types",
											options: generalTypes.map((row) => ({
												label: row.title,
												value: row.uuid,
											})),
										},
									],
									handleChange: (newVal) => handleBlur("generalTypeUuid", newVal),
								})}
							</div>
							{/*Role Type: Class, Template, Instance, Creator, etc.*/}
							<div className="pe-3 w-100">
								{INPUT_FIELD_TYPES.DROP_DOWN.render({
									label: "Role Type",
									value: roleTypeUuid,
									optionGroups: [
										{
											title: "Role Types",
											options: [
												{ label: "Select Role Type", value: "" },
												...generalTypes
													.filter(
														(row) =>
															row.title === "Creator Object" ||
															row.title === "Instance Object" ||
															row.title === "Template Object" ||
															row.title === "Class Object"
													)
													.map((row) => ({
														label: row.title,
														value: row.uuid,
													})),
											],
										},
									],
									handleChange: (newVal) => handleBlur("roleTypeUuid", newVal),
								})}
							</div>
						</div>
						<div className={"flex px-4"} style={{ marginBottom: "10px" }}>
							<div className="pe-3 w-100">
								{/*Min Count*/}
								{INPUT_FIELD_TYPES.NUMERIC.render({
									label: "Min Count",
									title: "Min Count",
									value: field.minCount,
									handleBlur: (newVal) => handleBlur("minCount", newVal),
								})}
							</div>
							<div className="pe-3 w-100">
								{/*Max Count*/}
								{INPUT_FIELD_TYPES.NUMERIC.render({
									label: "Max Count",
									title: "Max Count",
									value: field.maxCount,
									handleBlur: (newVal) => handleBlur("maxCount", newVal),
								})}
							</div>
						</div>
						<div className={"row px-4"}>
							<TagsInputType
								uuid={uuid}
								label={"Tags:"}
								value={tags}
								updateRow={(newVal) => handleBlur("objectTags", newVal)}
							/>
						</div>
						<div className={"flex px-4"} style={{ marginTop: "10px" }}>
							<div className="pe-3 w-100">
								{/*Min Count*/}
								{INPUT_FIELD_TYPES.TEXT_AREA.render({
									label: "Developer Notes",
									title: "Developer Notes",
									value: field.developerNotes,
									handleBlur: (newVal) => handleBlur("developerNotes", newVal),
								})}
							</div>
						</div>
						<label className="form-label mb-0 mt-4" style={{ fontWeight: "600" }}>
							Setup Options
						</label>
						<hr className="mt-0" />
						<SetupAttributes
							field={field}
							topNode={topNode}
							data={data}
							dataMap={dataMap}
							objectTypes={objectTypes}
						/>
						{/*Defaults*/}
						<label className="form-label mb-0 mt-4" style={{ fontWeight: "600" }}>
							Default Options
						</label>
						<hr className="mt-0" />
						<div className="flex px-4">
							{/*Default Value Field: This is where you can enter expressions*/}
							<div className="pe-3 w-100">
								{INPUT_FIELD_TYPES.TEXT_AREA.render({
									label: "Default Value",
									title: "Default Value",
									value: field.defaultValue,
									handleBlur: (newVal) => handleBlur("defaultValue", newVal),
								})}
							</div>

							{/*Default Value Occurrence: Choose when to get the default value*/}
							<div className="pe-3 w-100">
								{INPUT_FIELD_TYPES.DROP_DOWN.render({
									label: "When to Set Default Value",
									title: "When to Set Default Value",
									value: field.defaultValueOccurrence,
									optionGroups: [
										{
											title: "Select when to set default value",
											options: [
												{ label: `Choose...`, value: "0" },
												{ label: `On Creation`, value: "on-create" },
												{ label: `On Focus`, value: "on-focus" },
												{ label: `On Select`, value: "on-select" },
												{ label: `Calculate Every Time`, value: "every-time" },
											],
										},
									],
									handleChange: (newVal) => handleBlur("defaultValueOccurrence", newVal),
								})}
							</div>
						</div>
						<div className="flex px-4" style={{ marginTop: "10px" }}>
							{/*Default Value Field: This is where you can enter expressions*/}
							<div className="pe-3 w-100">
								{INPUT_FIELD_TYPES.TEXT_AREA.render({
									label: "Validator",
									title: "Validator",
									value: field.validator,
									handleBlur: (newVal) => handleBlur("validator", newVal),
								})}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

const SetupAttributes = ({ field, topNode, data, dataMap, objectTypes }) => {
	const [setupValue, setSetupValue] = useState("");
	const [inputSource, setInputSource] = useState("");
	const [setupOptions, setSetupOptions] = useState("");
	//TODO: This should be changed to say objectFieldLink
	const [setupLink, setSetupLink] = useState({});
	const [inputType, setInputType] = useState("value");
	const [setupLinkType, setSetupLinkType] = useState("alias");
	const [showLinkToObjectDialog, setShowLinkToObjectDialog] = useState(false);
	const [setupListObject, setSetupListObject] = useState({});
	const [showListObjectDialog, setShowListObjectDialog] = useState(false);
	const [setupListObjectLocation, setSetupListObjectLocation] = useState({});
	const [validations, setValidations] = useState([]);

	const [dropdownTypes, setDropdownTypes] = useState([]);
	const [showDropdownTypeDialog, setShowDropdownTypeDialog] = useState(false);

	const currentSetupLink = useRef({});
	const currentSetupListObject = useRef({});
	const currentDropdownTypeObject = useRef({});
	const currentSetupListObjectLocation = useRef({});
	const setupListDialogIndex = useRef(0);

	const fieldUuid = useRef("");
	const ownerAndSetupSheetString = useRef("");
	const addAttrToSetupSheet = useRef(false);
	const topMfi = useRef([]);

	//Global Store
	const dispatch = useDispatch();
	const sharedState = useTrackedState();

	/**
	 * I need to convert this to use the new data structure which has a input field type and input source
	 * The data is different though, What is the best way to update this?
	 */
	useEffect(() => {
		setSetupValue(field.setupValue);
		fieldUuid.current = field.uuid;

		//Get the owner and the setup sheet the attribute is on
		let owner = findOwnerByMap(field, field, dataMap, topNode);
		let setupSheetOwner = findSetupSheetOwner(field, dataMap, sharedState.dbConstants.setupSheetType.referenceUuid);

		if (setupSheetOwner)
			ownerAndSetupSheetString.current = `
                ${owner.reference} ${owner.title} -> 
                ${setupSheetOwner.reference.substring(owner.reference.length + 1, setupSheetOwner.reference.length)} ${
				setupSheetOwner.title
			} ->
                ${field.reference.substring(setupSheetOwner.reference.length + 1, field.reference.length)} ${
				field.title
			}`;
		else ownerAndSetupSheetString.current = `Select the object that ${field.reference} ${field.title}`;

		//Check if the setup link is to a setup attribute on a setup sheet that is a descendant of the top node or if it's a setup attribute on a global setup sheet
		let linkObj = dataMap.get(field[linkAttributeName]);
		if (linkObj) {
			setSetupLink(linkObj);
			currentSetupLink.current = linkObj;
		}

		//TODO: We have to do a bunch of stuff to migrate the old data to the new
		if (field.inputSource) setInputSource(field.inputSource);
		else if (field.setupType === INPUT_FIELD_SOURCES.HARD_CODED_VALUE.value) {
			setInputSource(field.setupType);
			_updateRow(dispatch, field.uuid, "multi", {
				inputSource: field.setupType,
				setupType: "",
			});
			field.setupType = "";
		} else if (field.setupType === SETUP_TYPES.LINK.value) {
			setInputSource(INPUT_FIELD_SOURCES.OBJECT_FIELD.value);
			_updateRow(dispatch, field.uuid, "multi", {
				inputSource: INPUT_FIELD_SOURCES.OBJECT_FIELD.value,
				setupType: "",
			});
			field.setupType = "";
		} else if (field.setupType === SETUP_TYPES.OBJECT_TITLE.value) {
			setInputSource(INPUT_FIELD_SOURCES.OBJECT_OWNER_TITLE.value);
			_updateRow(dispatch, field.uuid, "multi", {
				inputSource: INPUT_FIELD_SOURCES.OBJECT_OWNER_TITLE.value,
				setupType: "",
			});
			field.setupType = "";
		}

		if (field.inputType) setInputType(field.inputType);
		else if (field.setupType === SETUP_TYPES.TEXT_AREA.value) {
			setInputType(INPUT_FIELD_TYPES.TEXT_AREA.value);
			_updateRow(dispatch, field.uuid, "multi", {
				inputType: INPUT_FIELD_TYPES.TEXT_AREA.value,
				setupType: "",
			});
			field.setupType = "";
		} else if (field.setupType === SETUP_TYPES.DROP_DOWN.value) {
			setInputType(INPUT_FIELD_TYPES.DROP_DOWN.value);

			let updateObject = {
				inputType: INPUT_FIELD_TYPES.DROP_DOWN.value,
				setupType: "",
			};

			//Check if there is a setupListObjectMfiUuid, this needs converted to the new field
			if (field.setupListObjectMfiUuid) {
				field.dropDownObjectTypes = [field.setupListObjectMfiUuid];
				updateObject.dropDownObjectTypes = [field.setupListObjectMfiUuid];
				updateObject.setupListObjectMfiUuid = "";
			}

			_updateRow(dispatch, field.uuid, "multi", updateObject);
			field.setupType = "";
		} else if (field.setupType) {
			setInputType(field.setupType);
			_updateRow(dispatch, field.uuid, "multi", {
				inputType: field.setupType,
				setupType: "",
			});
			field.setupType = "";
		}

		if (field.setupLinkType) setSetupLinkType(field.setupLinkType);

		if (field.setupOptions) setSetupOptions(field.setupOptions);

		if (field.inputType === INPUT_FIELD_TYPES.DROP_DOWN.value && field.dropDownObjectTypes?.length > 0) {
			if (field.dropDownObjectTypes[0] === ZERO_ROW_UUID)
				setDropdownTypes([{ uuid: ZERO_ROW_UUID, title: "All Object Types" }]);
			else
				setDropdownTypes(
					sharedState.dataWarehouse.filter((row) => field.dropDownObjectTypes.includes(row.uuid))
				);
		} else if (field.inputType === INPUT_FIELD_TYPES.ASSOCIATION.value) {
		}

		let possibleValidations = getValidations(field.inputType);

		if (possibleValidations && field.validations)
			setValidations(possibleValidations.filter((validation) => field.validations.includes(validation.uuid)));

		updateDropdownAndListTypes();

		if (field.setupListObjectLocationUuid) {
			let obj = dataMap.get(field.setupListObjectLocationUuid);
			if (!obj) return;

			setSetupListObjectLocation(obj);
			currentSetupListObjectLocation.current = obj;
		}
		setInputType(field.inputType);
	}, [field.uuid]);

	//Onmount get the MFI for the top-most object
	useEffect(() => {
		//How do I find the top-most object?
		let top = getTopMostObject(sharedState);
		if (!top) return;

		getSingleLevelObjectMfi(top.uuid, top.versionUuid, dispatch, top.computerVersion).then(
			(mfi) => (topMfi.current = mfi)
		);
	}, []);

	const updateDropdownAndListTypes = async () => {
		if (
			field.inputType !== INPUT_FIELD_TYPES.DROP_DOWN.value &&
			field.inputType !== INPUT_FIELD_TYPES.LIST.value &&
			field.setupType !== INPUT_FIELD_TYPES.DROP_DOWN.value &&
			field.setupType !== INPUT_FIELD_TYPES.LIST.value
		)
			return;

		let mfiRecord;
		if (field.linkToObjectUuid && field.linkToObjectVersionUuid)
			mfiRecord = await findObjectWithMatchingReleaseVersionInDestinationModel(
				field.linkToObjectUuid,
				field.linkToObjectVersionUuid,
				sharedState.destinationModel
			);
		else if (field.setupListObjectMfiUuid)
			mfiRecord = sharedState.destinationModel.find((row) => row.uuid === field.setupListObjectMfiUuid);

		//If we didn't find the corresponding mfi record just return
		if (!mfiRecord) return;

		if (
			field.inputType === INPUT_FIELD_TYPES.DROP_DOWN.value ||
			field.setupType === INPUT_FIELD_TYPES.DROP_DOWN.value
		) {
			setDropdownTypes(mfiRecord);
			currentDropdownTypeObject.current = mfiRecord;
		}
		//This assumes the only other type that uses this is the list input type, this will need updated when that is no longer the case
		else {
			setSetupListObject(mfiRecord);
			currentSetupListObject.current = mfiRecord;
		}
	};

	/**
	 * Create a setup sheet type, point a constant to it, pull that from the database
	 * Set the type of the setup sheets to this new type
	 *
	 * Build the tree for each setup sheet so we can choose the setup attribute we want to show
	 * Add setup sheets to the setup forms panel, handle accordingly
	 */

	const handleInputTypeChange = (val) => {
		//Do I need to reset any of the other fields as this changes?
		switch (val) {
			case INPUT_FIELD_TYPES.DROP_DOWN.value:
				setInputType(INPUT_FIELD_TYPES.DROP_DOWN.value);
				_updateRow(dispatch, field.uuid, "multi", {
					inputType: INPUT_FIELD_TYPES.DROP_DOWN.value,
					//TODO: We reset this because the dropdown type uses this field differently, should we use different fields instead?
					linkToObjectUuid: "",
					linkToObjectVersionUuid: "",
					setupListObjectMfiUuid: "",
				});
				break;
			case INPUT_FIELD_TYPES.NONE.value:
				setInputType(SETUP_TYPES.NONE.value);
				setSetupLink({});
				_updateRow(dispatch, field.uuid, "multi", {
					setupValue: "",
					inputType: INPUT_FIELD_TYPES.NONE.value,
					setupLinkType: "",
					[linkAttributeName]: "",
					setupOptions: "",
					linkToObjectUuid: "",
					linkToObjectVersionUuid: "",
					setupListObjectLocationUuid: "",
				});
				break;
			default:
				setInputType(val);
				_updateRow(dispatch, field.uuid, "inputType", val);
				break;
		}
	};

	const handleSourceTypeChange = (val) => {
		//Do I need to reset any of the other fields as this changes?
		switch (val) {
			case "addToSetupSheet":
				setInputSource(INPUT_FIELD_SOURCES.OBJECT_FIELD.value);

				addAttrToSetupSheet.current = true;

				//Pull up setup sheet dialog
				setShowLinkToObjectDialog(true);
				break;
			default:
				setInputSource(val);
				_updateRow(dispatch, field.uuid, "inputSource", val);
				break;
		}
	};

	const handleLinkTypeChange = (e) => {
		switch (e) {
			case "copy":
				//Each of these things need to update the same attributes in the field
				setSetupLink({});
				setSetupLinkType("alias");
				setInputType(SETUP_TYPES.VALUE.value);
				setSetupValue(setupLink.value);
				_updateRow(dispatch, field.uuid, "multi", {
					value: setupLink.value,
					inputType: SETUP_TYPES.VALUE.value,
					setupValue: setupLink.value,
					setupLinkType: "",
					[linkAttributeName]: "",
				});
				//Might want to show a toast or something saying we've copied the link's current value
				break;
			case "alias":
				setSetupLinkType("alias");
				field.value = setupLink.value;
				_updateRow(dispatch, field.uuid, "multi", {
					value: setupLink.value,
					setupLinkType: "alias",
				});
				break;
			default:
				console.warn("Invalid Link Type: ", e.currentTarget.value);
				break;
		}
	};

	const dropdownTypeObjectSelected = (treeNodes) => {
		if (!treeNodes) return;

		//Verify a valid object was selected
		if (treeNodes === "all") {
			setDropdownTypes([{ uuid: ZERO_ROW_UUID, title: "All Object Types" }]);
			_updateRow(dispatch, field.uuid, "dropDownObjectTypes", [ZERO_ROW_UUID]);
		} else if (treeNodes.length > 0) {
			let val = treeNodes.map((node) => node.uuid);
			setDropdownTypes(treeNodes);
			_updateRow(dispatch, field.uuid, "dropDownObjectTypes", val);
		} else setDropdownTypes([]);

		setShowDropdownTypeDialog(false);
	};

	let inputTypeResult = "";
	//Need to check for two things here
	switch (inputType) {
		case INPUT_FIELD_SOURCES.HARD_CODED_VALUE.value:
			//If the source is a hard coded value, do I need to do anything here? I should be ok just rendering whatever the input type is, not worrying about what the source is?
			break;
		case INPUT_FIELD_TYPES.TEXT_AREA.value:
			inputTypeResult = INPUT_FIELD_TYPES.TEXT_AREA.renderMoreSetupFields(
				setupValue,
				(newVal) => setSetupValue(newVal),
				(newVal) => {
					if (setupValue !== newVal)
						_updateRow(dispatch, field.uuid, "multi", {
							value: newVal,
							setupValue: newVal,
							inputType: SETUP_TYPES.TEXT_AREA.value,
						});
				}
			);
			break;
		case INPUT_FIELD_TYPES.DROP_DOWN.value:
			inputTypeResult = INPUT_FIELD_TYPES.DROP_DOWN.renderMoreSetupFields(
				field.uuid,
				setupOptions,
				(values) => {
					_updateRow(dispatch, field.uuid, "setupOptions", values);
				},
				dropdownTypes.length > 0 ? `${dropdownTypes.map((type) => type.title)}` : "",
				() => {
					setShowDropdownTypeDialog(true);
				}
			);
			break;
		case INPUT_FIELD_TYPES.LIST.value:
			inputTypeResult = INPUT_FIELD_TYPES.LIST.renderMoreSetupFields(
				() => {
					setupListDialogIndex.current = 0;
					setShowListObjectDialog(true);
				},
				setupListObject,
				() => {
					setSetupListObject({});
					currentSetupListObject.current = {};

					_updateRow(dispatch, field.uuid, "multi", {
						linkToObjectUuid: "",
						linkToObjectVersionUuid: "",
						//We include this field because it was the old way, we want to cycle it out eventually
						setupListObjectMfiUuid: "",
					});
				}
			);
			break;
		case INPUT_FIELD_TYPES.ASSOCIATION.value:
			inputTypeResult = INPUT_FIELD_TYPES.ASSOCIATION.renderMoreSetupFields(
				field[linkAttributeName],
				field[linkVersionAttributeName],
				field.filterUuid,
				field.cardinality,
				objectTypes,
				(type, newVal) => {
					if (type === "type") {
						_updateRow(dispatch, field.uuid, "multi", {
							[linkAttributeName]: newVal?.uuid,
							[linkVersionAttributeName]: newVal?.versionUuid,
						});
					} else if (type === "filter") {
						_updateRow(dispatch, field.uuid, "filter", newVal);
						field.filter = newVal;
					} else _updateRow(dispatch, field.uuid, type, newVal);
					//How do I update the row?
				}
			);
			break;
		default:
			console.error("Input type not expected");
	}

	let possibleValidations = getValidations(field.inputType);

	let inputSourceResult = "";
	switch (inputSource) {
		case INPUT_FIELD_SOURCES.OBJECT_FIELD.value:
			inputSourceResult = INPUT_FIELD_SOURCES.OBJECT_FIELD.renderMoreSetupFields(
				setupLink.uuid ? `${setupLink.title} - Value: ${setupLink.value}` : "",
				() => setShowLinkToObjectDialog(true)
			);
			break;
		case INPUT_FIELD_SOURCES.HARD_CODED_VALUE.value:
			break;
		default:
			console.error("Input source not expected");
	}

	let inputSourceOptions = Object.keys(INPUT_FIELD_SOURCES).map((key) => ({
		value: INPUT_FIELD_SOURCES[key].value,
		label: INPUT_FIELD_SOURCES[key].label,
	}));

	return (
		<div className="px-4">
			<TagsInputType
				uuid={field.uuid}
				label={"Setup Sheets:"}
				value={field.setupSheets}
				updateRow={(newVal) => _updateRow(dispatch, field.uuid, "setupSheets", newVal.split(","))}
			/>
			<div className="flex" style={{ marginTop: "20px" }}>
				<div className="pe-3 w-100">
					<BootstrapSelect
						label={"Input Type"}
						value={inputType}
						optionGroups={[
							{
								title: "Input Types",
								options: [
									{ value: 0, label: "Select Input Type" },
									...Object.keys(INPUT_FIELD_TYPES)
										.map((key) => ({
											value: INPUT_FIELD_TYPES[key].value,
											label: INPUT_FIELD_TYPES[key].label,
										}))
										.sort((a, b) => a.label.localeCompare(b.label)),
								],
							},
						]}
						handleChange={handleInputTypeChange}
					/>
				</div>
				<div className="pe-3 w-100">
					<BootstrapSelect
						label={"Input Sources"}
						value={inputSource}
						optionGroups={[
							{
								title: "Sources",
								options: [{ value: 0, label: "Select Source" }, ...inputSourceOptions],
							},
						]}
						handleChange={handleSourceTypeChange}
					/>
				</div>
				{inputSourceResult}

				{/*Select List Object Dialog*/}
				<SelectionDialog
					dialogTitle={ownerAndSetupSheetString.current + " should contain a list of"}
					treeData={sharedState.destinationModel}
					treeTitle={"Destination Model"}
					open={showListObjectDialog}
					submitButtonText={"Save"}
					rowSelected={(row) => {
						setShowListObjectDialog(false);
						if (!row) return;

						//Verify we have a standardObjectUuid and standardObjectVersionUuid
						if (row?.uuid && row?.standardObjectUuid && row?.standardObjectVersionUuid) {
							setSetupListObject(row);
							currentSetupListObject.current = row;
						}

						_updateRow(dispatch, field.uuid, "multi", {
							linkToObjectUuid: row?.standardObjectUuid || "",
							linkToObjectVersionUuid: row?.standardObjectVersionUuid || "",
							setupListObjectMfiUuid: "",
						});
					}}
				/>

				{/*Select Link to Object Dialog*/}
				<SelectionDialog
					dialogTitle={ownerAndSetupSheetString.current + " should link to"}
					treeData={topMfi.current}
					treeTitle={"Destination Model"}
					allowSubObjectNavigation={true}
					open={showLinkToObjectDialog}
					submitButtonText={"Save"}
					requireFullObject={false}
					rowSelected={(row, object) => {
						setShowLinkToObjectDialog(false);

						//Verify we have a standardObjectUuid and standardObjectVersionUuid
						if (row?.uuid) {
							setSetupLink(row);
							currentSetupListObject.current = row;
						} else return;

						let { contextTop: top, contextObjectHierarchy: objectHierarchy, contextMfi: mfi } = sharedState;
						//Rather than having a row that points to this we are going to create a related row that is the actual selected object, I'm not sure what to do about he specific attribute yet though
						//Create Object Hierarchy record relating the object to the current top object
						let topHierarchyRecord = objectHierarchy.find(
							(r) =>
								r.descendantStandardObjectUuid === top.uuid &&
								r.descendantStandardObjectVersionUuid === top.versionUuid
						);
						let newHierarchyRecord = createObjectHierarchyRecord(topHierarchyRecord, object);
						newHierarchyRecord.hierarchyTypeUuid = sharedState.dbConstants.relatedObjectType.referenceUuid;

						//Create a copy of object and update parentUuid to field.uuid
						let copy = { ...object };

						//TODO: Should we limit a row to only have 1 relationship per row? If the length of the children is greater than 1 do we replace it? I'll limit for now, but we may end up wanting multiple because you could potentially have multiple types of relationships
						//Get the reference for the copy
						let children = mfi.filter((r) => r.parentUuid === field.uuid);
						let ref = getNextRef(field.reference + "." + (children.length || 0));
						copy.reference = ref.reference;
						copy.referenceNo = ref.referenceNo;
						copy.parentUuid = field.uuid;
						copy[linkAttributeName] = row.uuid;

						let deletedRows = [];
						if (children.length < 1) children.push({ parentUuid: field.uuid, referenceNo: 0 });
						else {
							if (children[0].uuid === object.uuid && children[0].versionUuid === object.versionUuid)
								return;
							deletedRows.push(children[0]);
						}
						insertRowAndReRef(children.slice(-1)[0], mfi, copy);

						//Pass the object hierarchy record and the copied row to the changed state
						updateChangeData(dispatch, {
							deletedRows,
							objectRows: [copy],
							objectHierarchy: [newHierarchyRecord],
						});

						setSetupLinkType("alias");
						setInputSource(INPUT_FIELD_SOURCES.OBJECT_FIELD.value);
						setSetupListObject({});
						setSetupListObjectLocation({});
					}}
				/>
				<SelectionDialog
					dialogTitle={"Select the object type you wish to populate this dropdown"}
					treeData={sharedState.destinationModel}
					treeTitle={`Data Warehouse`}
					rowSelected={dropdownTypeObjectSelected}
					open={showDropdownTypeDialog}
					treeHeight={"calc(100% - 117px"}
					multiSelect={true}
					selectedRows={dropdownTypes}
					additionalActions={[
						{
							text: "All",
							onClick: () => dropdownTypeObjectSelected("all"),
							type: "save",
							closeDialog: true,
						},
					]}
				/>
			</div>
			<div>
				{setupLink.uuid && (
					<BootstrapSelect
						label={"Link Type"}
						value={setupLinkType}
						optionGroups={[
							{
								title: "Link Types",
								options: [
									{ value: "copy", label: "Copy Current Value" },
									{ value: "alias", label: "Alias (Updates as link is updated)" },
								],
							},
						]}
						handleChange={handleLinkTypeChange}
					/>
				)}
				{inputTypeResult}

				{possibleValidations.length > 0 && (
					<div style={{ padding: "5px 5px 10px" }}>
						<MultiAutoComplete
							fieldToChange={"validations"}
							optionLabel={"title"}
							fieldLabel={"Validations"}
							value={validations}
							options={possibleValidations.filter((validation) => validation.title !== "base")}
							handleBlur={(e) => {
								_updateRow(
									dispatch,
									field.uuid,
									"validations",
									validations.map((row) => row.uuid)
								);
							}}
							handleChange={(e, val) => {
								if (val.length < validations.length)
									_updateRow(
										dispatch,
										field.uuid,
										"validations",
										val.map((row) => row.uuid)
									);

								setValidations(val);
							}}
							inputStyle={{ paddingBottom: "10px" }}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
