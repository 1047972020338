import { Link } from "react-router-dom";

/**
 * Renders our app header
 * @param
 * @constructor
 */
const LandingPageHeader = () => {
	//Other Methods
	return (
		<nav className="navbar navbar-expand-lg mx-auto" style={{ border: "1px solid lightgray", maxWidth: "5120px" }}>
			<div className="container-fluid">
				<Link className="navbar-brand fw-bold" to="/">
					Business Model Consulting LLC
				</Link>
				<div className="navbar-collapse" id="navbarNav">
					<ul className="navbar-nav">
						<li className="nav-item fs-6 px-4">
							<Link className="nav-link text-black" to="/products">
								Products
							</Link>
						</li>
						<li className="nav-item fs-6 px-4">
							<Link className="nav-link text-black" to="/solutions">
								Solutions
							</Link>
						</li>
						<li className="nav-item fs-6 px-4">
							<Link className="nav-link text-black" to="/resources">
								Resources
							</Link>
						</li>
						<li className="nav-item fs-6 px-4">
							<Link className="nav-link text-black" to="/pricing">
								Pricing
							</Link>
						</li>
						<li className="nav-item fs-6 px-4">
							<Link className="nav-link text-black" to="/support">
								Support
							</Link>
						</li>
						<li className="nav-item fs-6 px-4">
							<Link className="nav-link text-black" to="/about">
								About
							</Link>
						</li>
					</ul>
				</div>
				<Link className="btn btn-dark mx-5" to={"/support"} role={"button"}>
					Talk to an Expert
				</Link>
				<Link className="btn btn-primary mx-5" to={"/register"} role={"button"}>
					Get a free trial
				</Link>
				<Link className="btn btn-outline-primary ms-5" to={"/login"} role={"button"}>
					Login
				</Link>
			</div>
		</nav>
	);
};

export default LandingPageHeader;
