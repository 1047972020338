/**
 * Renders a title (for a company) with a logo
 * @param
 * @constructor
 */
const CompanyWithLogo = ({ companyName, logoUrl, showCompanyName = false }) => {
	return (
		<div className="mt-2">
			<img src={logoUrl} alt={companyName} height={"60px"} />
			<span
				style={{
					margin: "10px",
					fontSize: "30px",
					color: "#374948",
				}}
			>
				{showCompanyName && companyName}
			</span>
		</div>
	);
};

export default CompanyWithLogo;
