import React, { useEffect, useState } from "react";
import { EditIcon, PaperclipIcon, TrashIcon } from "./Icons/Icons";

export const LinkButton = ({
	linkTo,
	navigateToLinkClicked,
	createLinkClicked,
	deleteLinkClicked,
	maxWidth = "100%",
	height = "100%",
	buttonStyles = {},
}) => {
	const [buttonValue, setButtonValue] = useState("");

	useEffect(() => {
		setButtonValue(linkTo?.value);
	}, [linkTo?.value]);

	const deleteLinkClick = (e) => {
		e.stopPropagation();
		setButtonValue("");
		deleteLinkClicked();
	};

	const editLinkClick = (e) => {
		e.stopPropagation();
		createLinkClicked();
	};

	const linkClick = (e) => {
		if (buttonValue) {
			e.stopPropagation();
			navigateToLinkClicked();
		} else editLinkClick(e);
	};

	const heightAndWidth = {
		height: "100%",
		width: "100%",
		maxWidth,
	};

	if (height) heightAndWidth.height = height;
	else heightAndWidth.minHeight = "75px";

	return (
		<button
			className={"btn btn-outline-danger"}
			style={{
				...heightAndWidth,
				border: "none",
				whiteSpace: "nowrap",
				overflow: "hidden",
				textOverflow: "ellipsis",
				position: "relative",
				padding: "20px 10px",
				borderRadius: "0px",
			}}
			onClick={linkClick}
			title={buttonValue}
		>
			<button
				title={"Edit Link"}
				className={"btn btn-outline-primary"}
				onClick={editLinkClick}
				style={{
					border: "none",
					padding: "2px 4px",
					position: "absolute",
					right: "13px",
					top: "0px",
				}}
			>
				<EditIcon color="white" width={10} height={10} />
			</button>
			<button
				title={"Delete Link"}
				className={"btn btn-outline-warning"}
				style={{
					border: "none",
					padding: "2px 4px",
					position: "absolute",
					right: "0px",
					top: "0px",
					color: "white",
				}}
				onClick={deleteLinkClick}
			>
				<TrashIcon color="white" width={10} height={10} />
			</button>
			{buttonValue}
		</button>
	);
};
