import React, { useState, useEffect, useRef } from "react";
import { useTrackedState } from "../../../utils/store";
import Panel from "../Panel/Panel";
import { CodeIcon, FileEarmarkText, JournalsIcon, ViewIcon } from "../../BootstrapComponents/Icons/Icons";
import Iframe from "../../Iframe/Iframe";
import { v4 as uuidv4 } from "uuid";
import { getObjectIdAndVersionUuid } from "../../../utils/StandardObject";
import { ObjectHeader, ObjectHierarchyTree } from "../Body/NewModifiedWorkspacePanel/NewModifiedWorkspacePanel";
import VerticalMenu from "../VerticalMenu/VerticalMenu";
import { generate } from "../../../utils/Generator";
import EmptyPanelMessage from "../Panel/EmptyPanelMessage";
import { SimpleLoader } from "../SetupSheetWindow/SetupField";
import { ColorfulLoader, OtherLoader } from "../../Loader/Loader";

/**
 * A panel that shows the views for the object in question
 * @param uuid: The id of the object
 * @param versionUuid: The version of the object
 * @param sendGlobalChanges: Not sure how to handle this yet, we use VisualRepPanel in multiple places and only some of these have changes applicable to the object we are generating views for
 * @param includeObjectHierarchy: As said above, this view is only applicable in some cases (more that the functionality is only available to display this in some cases). How should we handle it.
 * @param panelContentTitle: The title of the content being loaded into the panel.
 * @constructor
 */

const VisualRepPanel = ({
	uuid,
	versionUuid,
	panelTitle,
	reference,
	panelId,
	panelGroup,
	panelContentTitle,
	sendGlobalChanges = false,
	includeObjectHierarchy = false,
	surroundWithPanel = true,
	...other
}) => {
	const [activeView, setActiveView] = useState("");
	const [activeViewReference, setActiveViewReference] = useState("");
	const [views, setViews] = useState([]);
	const [code, setCode] = useState([]);
	const [loading, setLoading] = useState(true);
	const [destinationUuid, setDestinationUuid] = useState("");

	const extraMenuItems = useRef([]);
	const loadedViews = useRef(false);
	const loadedObject = useRef({});

	const sharedState = useTrackedState();

	useEffect(() => {
		if (!uuid || !versionUuid) setLoading(false);
		else {
			setLoading(true);
			if (destinationUuid) generateReport(destinationUuid);
		}
	}, [uuid, versionUuid]);

	useEffect(() => {
		if (includeObjectHierarchy)
			extraMenuItems.current = [
				{
					id: uuidv4(),
					label: "Object Hierarchy",
					icon: <JournalsIcon />,
					onClick: () => {
						setActiveView("Generated Object Hierarchy");
						setActiveViewReference("");
					},
				},
			];
	}, [includeObjectHierarchy]);

	useEffect(() => {
		if (uuid && versionUuid && destinationUuid) {
			generateReport(destinationUuid);
		} else {
			reset();
		}
	}, [destinationUuid]);

	useEffect(() => {
		if (sharedState.destinationModel?.length > 0) {
			setDestinationUuid(sharedState.destinationModel[0].uuid);
		}
	}, [sharedState.destinationModel?.length]);

	/**
	 * Generate the source code for the object
	 * @param download
	 * @returns {Promise<void>}
	 */
	const generateCode = async (download = true) => {
		sharedState.destinationUuid = destinationUuid;
		setLoading(true);
		let code;

		if (sendGlobalChanges)
			code = await generate(uuid, versionUuid, "code", getChangedDataForSourceCodeGeneration(sharedState));
		else code = await generate(uuid, versionUuid, "code");

		setActiveView("Generated Source Code");
		setActiveViewReference("");

		if (code) setCode(code);

		setLoading(false);
	};

	const generateReport = async (_destinationUuid) => {
		if (_destinationUuid) sharedState.destinationUuid = _destinationUuid;
		else sharedState.destinationUuid = destinationUuid;
		setLoading(true);
		let views;
		if (sendGlobalChanges)
			views = await generate(uuid, versionUuid, "template", getChangedDataForSourceCodeGeneration(sharedState));
		else views = await generate(uuid, versionUuid);

		loadedViews.current = true;
		//Sometimes when loading a packet we call generate code on the packet which takes forever, then call it on the object we load which doesn't take very long.
		//Because the packet one comes back with an error after we've already set the views for loaded sub-object it overwrites what was there.
		//We could check if the uuid and versionUuid at this point is the same as the packet and there is a different top. In this case we don't want to reset if the packet comes back with an error
		if (
			uuid === sharedState.contextPacket?.uuid &&
			versionUuid === sharedState.contextPacket?.versionUuid &&
			sharedState.contextTop?.uuid
		) {
			setLoading(false);
			return;
		}

		views = views?.sort((a, b) => a.ref.length - b.ref.length);
		if (views && views.length > 0) {
			loadedObject.current = { uuid, versionUuid };
			setActiveView(views[0].name);
			setActiveViewReference(reference !== "0" ? `${reference}.${views[0].ref}` : views[0].ref);
			setViews(views);
		} else {
			let ancestors = sharedState.contextAncestorObjects;
			let match = ancestors.find(
				(row) => row.uuid === loadedObject.current.uuid && row.versionUuid === loadedObject.current.versionUuid
			);
			if (!match) reset();
		}
		setLoading(false);
	};

	const reset = () => {
		if (!loadedViews.current) return;

		setViews([]);
		setCode([]);
		setActiveView("");
		setActiveViewReference("");
		// setLoading(false);
	};

	//Build the menu items
	let menuItems = [
		{
			id: "visual-rep-panel-generate-code",
			label: "Generate Code",
			icon: <CodeIcon />,
			onClick: generateCode,
		},
		...views.map((view, index) => ({
			id: "visual-rep-panel-different-views-" + index,
			label: view.name,
			icon: <FileEarmarkText />,
			onClick: () => {
				setActiveView(view.name);
				setActiveViewReference(view.ownerRef);
			},
		})),
		...extraMenuItems.current,
	];

	let viewHeight = "100%";

	let viewsDom = (
		<>
			<div
				style={{
					height: viewHeight,
					width: "100%",
					overflow: "auto",
					padding: "10px",
				}}
				className={activeView !== "Generated Source Code" ? "d-none" : ""}
			>
				{code ? (
					<Iframe content={code} style={{ height: "100%", width: "100%" }} />
				) : (
					<div style={{ color: "#626262", fontWeight: "200" }}>No code found.</div>
				)}
			</div>
			<div
				style={{
					height: viewHeight,
					width: "100%",
					overflow: "auto",
					padding: "10px",
				}}
				className={activeView !== "Generated Object Hierarchy" ? "d-none" : ""}
			>
				{includeObjectHierarchy && (
					<ObjectHierarchyTree
						mfi={sharedState.contextMfi}
						objectHierarchy={sharedState.contextObjectHierarchy}
					/>
				)}
			</div>
			{views.length > 0
				? views.map((view, index) => {
						// Add one to the index because in the iframe component we check if
						// the id exists and 0 is false
						return (
							<div
								key={view.ref}
								style={{
									height: viewHeight,
									width: "100%",
									padding: "10px",
								}}
								className={activeView !== view.name ? "d-none" : ""}
							>
								<Iframe
									id={uuidv4()}
									style={{ height: "100%", width: "100%" }}
									key={index + 1}
									name={index + 1}
									content={view.code}
									className={"presentation-iframe"}
								/>
							</div>
						);
				  })
				: !activeView && <EmptyPanelMessage message={`No views found for ${panelContentTitle}.`} />}
		</>
	);

	return (
		<>
			{loading ? (
				<OtherLoader
					loaderStyles={{
						position: "relative",
						top: "50%",
						left: "50%",
					}}
				/>
			) : (
				""
			)}
			<div
				style={
					loading
						? {
								height: "100%",
						  }
						: { height: "100%" }
				}
			>
				{surroundWithPanel ? (
					<Panel
						panelTitle={panelTitle ? panelTitle : "Visual Presentation Views"}
						panelContentTitle={activeView}
						panelStockNumber={<ObjectHeader reference={activeViewReference} referenceType={"PKT"} />}
						menuItems={menuItems}
						panelId={panelId}
						panelGroup={panelGroup}
						{...other}
						bodyStyles={
							loading
								? {
										...other.style,
										height: "100%",
										opacity: ".2",
										pointerEvents: "none",
										filter: "grayscale(100%)",
								  }
								: {
										height: "100%",
										...(other.style || {}),
								  }
						}
					>
						{viewsDom}
					</Panel>
				) : (
					<div
						style={{
							height: "100%",
							paddingLeft: "5px",
						}}
					>
						<TopCenterHeader text={panelTitle} className={panelGroup} />
						{menuItems.length > 0 ? (
							<VerticalMenu
								menuItems={menuItems}
								style={{
									cursor: "pointer",
									float: "left",
									marginTop: "-14px",
									marginLeft: "-14px",
									height: "0px",
									width: "0px",
								}}
								subMenuStyle={{
									marginLeft: "22px",
									marginTop: "-10px",
									boxShadow: "0px 0px 15px #b5b5b5",
								}}
							/>
						) : (
							""
						)}
						<div style={{ height: "100%", paddingTop: "40px" }}>{viewsDom}</div>
					</div>
				)}
			</div>
		</>
	);
};

export default VisualRepPanel;

export const getChangedDataForSourceCodeGeneration = (sharedState) => {
	let idAndVersion;
	if (sharedState.contextAncestorObjects && sharedState.contextAncestorObjects.length > 0)
		idAndVersion = getObjectIdAndVersionUuid(sharedState.contextAncestorObjects[0]);
	else idAndVersion = getObjectIdAndVersionUuid(sharedState.contextTop);

	//Get the changed rows to send back to the source code generation method to build updated code
	let changedData =
		sharedState.changedData?.[sharedState.openView.title]?.[sharedState.standardObjectTitle]?.[idAndVersion]
			?.objects;
	let changed = {
		deleted: [],
		mfi: [],
	};

	if (changedData) {
		Object.keys(changedData).forEach((subObjectKey) => {
			let { mfi, deleted } = changedData[subObjectKey];
			Object.keys(mfi).forEach((key) => changed.mfi.push(mfi[key]));
			Object.keys(deleted).forEach((key) => changed.deleted.push(deleted[key]));
		});
	}

	if (sharedState.destinationUuid) changed.dataWarehouseUuid = sharedState.destinationUuid;
	return changed;
};

export const TopCenterHeader = ({ text, className }) => {
	return (
		<h2
			style={{
				fontFamily: "Helvetica, Arial, sans-serif",
				fontSize: "1.3em",
				height: "40px",
				color: "#374948",
				fontWeight: "600",
				marginBottom: "0px",
				marginTop: "5px",
				textAlign: "center",
			}}
			className={className}
		>
			<span className="panelTitle">{text}</span>
		</h2>
	);
};
