/**
 * Renders a label and an edit svg icon
 * @param {label, onClick}
 * @constructor
 */
const LabelWithEditButton = ({ label, value, onClick, rowStyle }) => {
	return (
		<div className={"row"} style={{ ...rowStyle }}>
			<div className={"col"} style={{ lineHeight: "30px", textAlign: "left", display: "flex" }}>
				<label className="form-label" style={{ paddingRight: "10px" }}>
					{label}:
				</label>
				{value ? (
					<span style={{ textDecoration: "underline" }}>{`${value}`}</span>
				) : (
					<span style={{ textDecoration: "underline" }}>{`Not Set`}</span>
				)}
			</div>
			<div className={"col col-1"} style={{ lineHeight: "30px" }}>
				<span className="float-end">
					<span className="p-2">
						<a
							className={``}
							href="#"
							onClick={onClick}
							style={{ textDecoration: "none", color: "#808080" }}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-pencil-square"
								viewBox="0 0 16 16"
							>
								<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
								<path
									fillRule="evenodd"
									d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
								/>
							</svg>
						</a>
					</span>
				</span>
			</div>
		</div>
	);
};

export default LabelWithEditButton;
