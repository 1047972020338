import React, { useEffect, useRef, useState } from "react";
import DraggableDialog from "./DraggableDialog/Dialog";

import "./ChangeRequestSelectionDialog.scss";
import BasicTreeWithSearch from "../Tree/BasicTreeWithSearch";
import HorizontalTabs from "../ReactGridComponents/HorizontalTabs/HorizontalTabs";
import RecursiveTreeView from "../Tree/Tree";
import { convertListToMap, convertListToRealJsMap } from "../../utils/StandardObject";
import DiffTree from "../Tree/DiffTree";
import { InfoIcon } from "../BootstrapComponents/Icons/Icons";
import Tooltip from "@mui/material/Tooltip";

/**
 * This Dialog will be used to transfer things from one object to another. This is how we will attach an object and put different pieces in different places
 * This will probably evolve into our harvest dialog, but to start with it will only be for attaching packets to each other.
 * @param treeData1
 * @param treeData2
 * @param onTree2Drop
 * @param _open
 * @returns {JSX.Element}
 * @constructor
 */
export const TwoTreeDialog = ({
	treeData1,
	treeData2,
	saveChanges,
	header,
	cancel,
	open: _open,
	dialogButtons,
	getChanges,
	saveButton = true,
	saveButtonText,
	allAreRequired = false,
	//If this is true we are adding new entries from the tree on the left to the tree on the right.
	//If it's false we are updating rows in the tree on the right
	allowObjectUpdatesToRightTree = false,
	rightTreeButtons,
	tooltipHelpInstructions,
	editableLeftTree = false,
	updateRow,
	...other
}) => {
	const [open, setOpen] = useState(false);
	const [disableSave, setDisableSave] = useState(true);
	const [alreadyDraggedRows, setAlreadyDraggedRows] = useState([]);
	const [rightTreeDiff, setRightTreeDiff] = useState();

	const rightTreeRef = useRef([]);
	const selectedRow = useRef({});
	const changes = useRef([]);

	useEffect(() => {
		if (treeData2.length > 0) {
			if (
				rightTreeRef.current.length < 1 ||
				(rightTreeRef.current[0].uuid === treeData2[0].uuid &&
					(rightTreeRef.current[0].versionUuid !== treeData2[0].versionUuid ||
						rightTreeRef.current.uuid !== treeData2[0].uuid))
			) {
				rightTreeRef.current = [];
				treeData2.forEach((row) => rightTreeRef.current.push({ ...row }));
			}
		}
	}, [treeData2[0]?.uuid, treeData2[0]?.versionUuid]);

	//On mount, grab the Data Warehouse MFI
	useEffect(() => {
		setOpen(_open);
	}, [_open]);

	const reset = () => {
		setAlreadyDraggedRows([]);
		changes.current = [];
		selectedRow.current = {};
		setOpen(false);
		setRightTreeDiff({});
	};

	const handleClose = () => {
		reset();
		cancel();
	};

	const handleSave = () => {
		//Call the save passed in, it should accept the rows being added / changed in the second MFI
		saveChanges(convertListToRealJsMap(changes.current));
		reset();
		rightTreeRef.current = treeData2;
	};

	const addChangedRows = (rows) => {
		//Add the dropped row to the already dragged rows so we don't drag it again
		let tree1Uuids = treeData1.map((item) => item.uuid);
		let newlyDroppedMatch = rows.find(
			(item) =>
				tree1Uuids.includes(item.standardObjectUuid) && !alreadyDraggedRows.includes(item.standardObjectUuid)
		);

		setAlreadyDraggedRows([...alreadyDraggedRows, newlyDroppedMatch.standardObjectUuid]);

		changes.current = [...changes.current, ...rows];
		if (getChanges) getChanges(convertListToRealJsMap(changes.current));
		let diff = DiffTree(treeData2, rightTreeRef.current);
		setRightTreeDiff(diff);
	};

	const leftTreeSelected = (row) => {
		selectedRow.current = row;
	};

	// const updatedRow = (mfiRow) => {
	// 	//Add the selected object to the alreadyDraggedIds so it will gray it out
	// 	setAlreadyDraggedRows([...alreadyDraggedRows, selectedRow.current.uuid]);
	//
	// 	//Add the mfiRow to changedMfiRows
	// 	updatedMfiRows.current.push(mfiRow);
	//
	// 	//trigger the button onClick?
	// 	if (button.onClick) button.onClick(selectedRow.current, mfiRow);
	// 	selectedRow.current = {};
	// };

	return (
		<>
			<DraggableDialog
				dialogHeight={"800px"}
				dialogWidth={"1200px"}
				// style={{ minHeight: "900px", minWidth: "1200px" }}
				// PaperProps={{ style: { minWidth: "1200px", width: "75%", minHeight: "700px" } }}
				header={
					header ? (
						<>
							{header}
							<div style={{ padding: "0px 10px", cursor: "default" }} title={tooltipHelpInstructions}>
								<InfoIcon color={"#0dcaf0"} />
							</div>
						</>
					) : (
						`Attach ${treeData1[0]?.title} to ${treeData2[0]?.title}`
					)
				}
				showDialog={open}
				handleClose={handleClose}
				handleSave={handleSave}
				saveButtonText={saveButtonText || "Save"}
				saveButton={saveButton}
				additionalActions={dialogButtons}
				disableSave={allAreRequired && alreadyDraggedRows.length !== treeData1.length}
				{...other}
			>
				<div className={"row"}>
					{treeData1.filter((row) => row.dropType === "update").length > 0 ? (
						<div className={"col"}>
							<div className={"row"}>
								<h5 style={{ paddingBottom: "10px", textAlign: "center" }}>Object Updates:</h5>
								<RecursiveTreeView
									data={treeData1.filter((row) => row.dropType === "update")}
									topNode={treeData1[0]}
									topNodeId={treeData1[0]?.uuid}
									treeTitle={"Tree 1"}
									droppable={false}
									draggable={true}
									editable={editableLeftTree}
									updateRow={updateRow}
									nonDraggableIds={alreadyDraggedRows}
									getObjMfiOnDrop={false}
									origin={"harvest-left"}
									treeHeight={250}
									showVersions={true}
									rowSelected={leftTreeSelected}
									treeDivStyles={{
										boxShadow:
											"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
										padding: "15px",
									}}
								/>
							</div>
							<div className={"row"}>
								<h5 style={{ padding: "10px 0px", textAlign: "center" }}>New Objects:</h5>
								<RecursiveTreeView
									data={treeData1.filter((row) => row.dropType !== "update")}
									topNode={treeData1[0]}
									topNodeId={treeData1[0]?.uuid}
									treeTitle={"Tree 1"}
									droppable={false}
									draggable={true}
									nonDraggableIds={alreadyDraggedRows}
									getObjMfiOnDrop={false}
									noDropOnUbmObjects={true}
									origin={"harvest-left"}
									treeHeight={250}
									showVersions={true}
									rowSelected={leftTreeSelected}
									treeDivStyles={{
										boxShadow:
											"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
										padding: "15px",
									}}
								/>
							</div>
						</div>
					) : (
						<div className={"col"}>
							<h5 style={{ padding: "0px 0px 10px 0px", textAlign: "center" }}>New Objects:</h5>
							<RecursiveTreeView
								data={treeData1}
								topNode={treeData1[0]}
								topNodeId={treeData1[0]?.uuid}
								treeTitle={"Tree 1"}
								droppable={false}
								draggable={true}
								editable={editableLeftTree}
								updateRow={updateRow}
								nonDraggableIds={alreadyDraggedRows}
								getObjMfiOnDrop={false}
								origin={"harvest-left"}
								treeHeight={570}
								showVersions={true}
								rowSelected={leftTreeSelected}
								treeDivStyles={{
									boxShadow:
										"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
									padding: "15px",
									height: "calc(100% - 30px)",
								}}
							/>
						</div>
					)}
					<div className={"col"}>
						<h5 style={{ paddingBottom: "10px", textAlign: "center" }}>Destination Model:</h5>
						<RecursiveTreeView
							data={treeData2}
							topNode={treeData2[0]}
							topNodeId={treeData2[0]?.uuid}
							diff={rightTreeDiff}
							treeTitle={"Tree 2"}
							droppable={true}
							showVersions={true}
							draggableIds={treeData1.map((row) => row.uuid)}
							getObjMfiOnDrop={false}
							addChangedRows={addChangedRows}
							origin={"harvest-right"}
							treeHeight={570}
							treeDivStyles={{
								boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
								padding: "15px",
								height: "calc(100% - 30px)",
							}}
							allowObjectUpdateOnDropRatherThanAddNewEntry={allowObjectUpdatesToRightTree}
						/>
					</div>
				</div>
			</DraggableDialog>
		</>
	);
};
