import { checkIfValidUUID } from "./StringUtils";

export const fileServiceUrl = () => {
	let file_service_url = process.env.REACT_APP_FILE_SERVICE_URL;

	const scheme = window.location.origin.startsWith("https") ? "https" : "http";

	if (!file_service_url) {
		throw new Error("File upload service address is required");
	}
	if (!file_service_url.startsWith("http")) {
		throw new Error("You must specify the protocol scheme (http|https) for the server address.");
	}
	if (!file_service_url.startsWith(scheme + "://")) {
		throw new Error("File upload service address scheme does not match the current page url scheme");
	}

	return file_service_url.endsWith("/") ? file_service_url.slice(0, -1) : file_service_url;
};

export const duplicateFile = async (fileIdentifier) => {
	let url;
	if (checkIfValidUUID(fileIdentifier)) {
		url = `${fileServiceUrl()}/duplicate/${fileIdentifier}`;
	} else {
		// This could use some error handling if nothing matches TOOD
		let uuid = /((\w{4,12}-?)){5}/.exec(fileIdentifier)[0];
		url = `${fileServiceUrl()}/duplicate/${uuid}`;
	}
	let results = await fetch(url, { method: "POST" }).catch((err) => {
		console.error("Failed to duplicate file:", err);
		return "Failed to duplicate";
	});
	let result = await results.json();
	return result.id;
};
